import { addToQueue } from "./addToQueue";
import { moveTrack } from "./moveTrack";
import { store } from "global";
import type { PlayQueueDragAddToQueueAction } from "global/actions/actionTypes";
import { ContentType } from "models/ModelType";

export function dropTrack({ playable, lane, atEnd, queueTrack, action, playableContext, context }: PlayQueueDragAddToQueueAction["payload"]) {
    // todo this payload could be simplified and should just recieve an insert position
    const { queue } = store.getState();

    const index = queueTrack ? queue.playQueue.findIndex((item) => item.queueId === queueTrack.queueId) : null;
    if (action === "move" && playable.contentType === ContentType.QueueTrack) {
        moveTrack(playable, lane, atEnd, index, context);
    } else if (action === "add") {
        // todo this function should implement move to index
        addToQueue(playable, index, lane, atEnd, playableContext, context);
    }
}
