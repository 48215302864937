import { useEffect, Inputs, useRef } from "preact/hooks";
import { CancellationToken } from "./cancellationToken";

export const useCancellingEffect = <T>(effect: (token: CancellationToken) => Promise<T>, inputs?: Inputs): void => {
    const ref = useRef<CancellationToken>(new CancellationToken());

    useEffect(() => {
        ref.current.cancel();
        const token = (ref.current = new CancellationToken());
        effect(token);
        return () => token.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, inputs);
};
