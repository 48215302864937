import { useState } from "preact/hooks";

export function useReloadToken(): [token: number, reload: () => void] {
    const [token, setToken] = useState(0);

    function increment() {
        setToken(token+1);
    }

    return [token, increment];
}