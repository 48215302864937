import { ApolloProvider } from "@apollo/client";
import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import type { RouterOnChangeArgs } from "preact-router";
import Router, { Route } from "preact-router";
import { Provider } from "react-redux";
import "./App.scss";
import { useTheme, useLocalization, useToggleNoConnectionBanner } from "./hooks";
import { useAppLayerFocus } from "./hooks/useAppLayerFocus";
import {
    AlbumPage,
    FrontPage,
    LayoutPage,
    MyMusicAlbumsPage,
    MyMusicArtistsPage,
    MyMusicPlaylistsPage,
    MyMusicTracksPage,
    PlaylistPage,
    SearchPage,
    ArtistRadioPage,
    TrackRadioPage,
    AppPromptPage,
    ProfilesPage,
    EditProfilePage
} from "components/pages";
import { DefaultTemplate } from "components/templates";
import { store } from "global";
import { PrivateRoute, UnhandledRoute } from "global/config";
import { getOSName, OperatingSystemName } from "services/app";
import { graphqlClient } from "services/backend";
import { formatUrlToUTF8 } from "services/navigation";
import { useLoginState } from "services/user";
import { LoginState } from "models/app";
import { ContentType } from "models/ModelType";
import { useActiveAppLayer, useNativeMobileAppOpen } from "components/shared/hooks";
import { useAppMessages } from "components/shared/hooks/useAppMessages";
import { useShowNativeMobileAppPrompt } from "components/shared/hooks/useShowNativeMobileAppPrompt";
import "components/shared/variables.scss";
import { DragLabel } from "components/atoms/dragLabel/DragLabel";
import { PageLoadSpinner } from "components/atoms/spinner";
import { AppBanner, useBottomBanner } from "components/molecules/banner";
import { AppContextMenu } from "components/organisms/contextMenu/AppContextMenu";
import { SessionFrame } from "components/organisms/login/sessionFrame/sessionFrame";
import { AppModal } from "components/organisms/modal";
import { AppToast } from "components/organisms/toast";
import { ArtistPage } from "components/pages/artistPage/ArtistPage";
import { ChartPage } from "components/pages/chartPage/ChartPage";
import { NotFoundPage } from "components/pages/errorPages/NotFoundPage";
import { LiveRadioPage } from "components/pages/liveRadio/LiveRadioPage";
import { LiveRadioCategoryPage } from "components/pages/liveRadioCategory/LiveRadioCategoryPage";
import { MixRadioCategoryPage } from "components/pages/mixRadioCategory/MixRadioCategoryPage";
import { MixRadiosPage } from "components/pages/mixRadios/MixRadiosPage";
import { PlaylistCategoryPage } from "components/pages/playlistCategory/PlaylistCategoryPage";
import { RadioPage } from "components/pages/radioPage/RadioPage";
import { RecommendationPage } from "components/pages/recommendationPage/RecommendationPage";
import { SettingsPage } from "components/pages/settingsPage/SettingsPage";
import { AlbumArtistAlbumsShowAllPage } from "components/pages/showAllPages/AlbumArtistAlbumsShowAllPage";
import { ArtistAlbumsShowAllPage } from "components/pages/showAllPages/ArtistAlbumsShowAllPage";
import { ArtistAppearsOnShowAllPage } from "components/pages/showAllPages/ArtistAppearsOnShowAllPage";
import { ArtistAllTracksShowAllPage, ArtistPopularTracksShowAllPage } from "components/pages/showAllPages/ArtistPopularTracksShowAllPage";
import { ArtistSimilarArtistsShowAllPage } from "components/pages/showAllPages/ArtistSimilarArtistsShowAllPage";
import { ArtistSinglesShowAllPage } from "components/pages/showAllPages/ArtistSinglesShowAllPage";
import { FrontPageItemShowAllPage } from "components/pages/showAllPages/FrontPageItemShowAllPage";
import { LayoutPageItemShowAllPage } from "components/pages/showAllPages/LayoutPageItemShowAllPage";
import { RadioPageItemShowAllPage } from "components/pages/showAllPages/RadioPageItemShowAllPage";
import { SearchAlbumResultsShowAllPage } from "components/pages/showAllPages/SearchAlbumResultsShowAllPage";
import { SearchArtistResultsShowAllPage } from "components/pages/showAllPages/SearchArtistResultsShowAllPage";
import { SearchPlaylistResultsShowAllPage } from "components/pages/showAllPages/SearchPlaylistResultsShowAllPage";
import { SearchTrackResultsShowAllPage } from "components/pages/showAllPages/SearchTrackResultsShowAllPage";
import { UserHistoryPage } from "components/pages/userHistory/UserHistoryPage";
import { UserRecommendationsPage } from "components/pages/userRecommendations/UserRecommendationsPage";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).store = store;

const _App = () => {
    useNativeMobileAppOpen();
    useAppMessages();
    useToggleNoConnectionBanner();

    const theme = useTheme();
    const local = useLocalization();
    const focus = useActiveAppLayer();
    const hasBottomBanner = useBottomBanner() != null;
    const loginStateIsKnown = useLoginState() !== LoginState.Unknown;

    const [mobileHeader, setMobileHeader] = useState(false);
    const [loginFallback, setLoginFallback] = useState<string>("/");
    const { pagesRef, pageRef, modalRef, contextMenuRef } = useAppLayerFocus();
    const isOsWindows = getOSName() === OperatingSystemName.Windows;

    const handleRouteChange = useCallback(
        async ({ url, previous }: RouterOnChangeArgs) => {
            const isFrontPage = url === "/"; // todo change this to use type safe front page check
            if (isFrontPage !== mobileHeader) setMobileHeader(isFrontPage);

            if (previous && !previous.startsWith("/min-musik")) {
                setLoginFallback(previous);
            } else {
                setLoginFallback("/");
            }
        },
        [mobileHeader]
    );

    const isMobile = useShowNativeMobileAppPrompt();

    return (
        <div className={`app USk --theme-${theme} --local-${local} --focus-${focus}  --hasBottomBanner-${hasBottomBanner} --isOsWindows-${isOsWindows}`}>
            <div className="pages" ref={pagesRef}>
                {isMobile ? (
                    <AppPromptPage />
                ) : (
                    <DefaultTemplate pageRef={pageRef} mobileHeader={mobileHeader}>
                        {
                            loginStateIsKnown &&
                            <Router onChange={handleRouteChange}>
                                <PrivateRoute path="/min-musik/album" component={MyMusicAlbumsPage} previous={loginFallback} />
                                <PrivateRoute path="/min-musik/kunstnere" component={MyMusicArtistsPage} previous={loginFallback} />
                                <PrivateRoute path="/min-musik/playlister" component={MyMusicPlaylistsPage} previous={loginFallback} />
                                <PrivateRoute path="/min-musik/sange" component={MyMusicTracksPage} previous={loginFallback} />
                                <Route path="/indstillinger" component={SettingsPage} />
                                <PrivateRoute path="/profiler/:profileId" component={EditProfilePage} previous={loginFallback} />
                                <PrivateRoute path="/profiler" component={ProfilesPage} previous={loginFallback} />
                                <Route path="/" component={FrontPage} />
                                <Route path="/album/:albumId" component={AlbumPage} />
                                <Route path="/album/:albumId/sang/:trackId" component={AlbumPage} />
                                <Route path="/album/:albumId/vis-alle/kunstner-album" component={AlbumArtistAlbumsShowAllPage} />
                                <Route path="/anbefaling" component={UserRecommendationsPage} />
                                <Route path="/anbefaling/:recommendationId" component={RecommendationPage} />
                                <Route path="/forside/vis-alle/:itemId" component={FrontPageItemShowAllPage} />
                                <Route path="/forside/vis-alle/:itemId/:resourceId?" component={FrontPageItemShowAllPage} />
                                <Route path="/historik" component={UserHistoryPage} />
                                <Route path="/historik/:recommendationId" component={RecommendationPage} />
                                <Route path="/hitliste/:chartId" component={ChartPage} />
                                <Route path="/kunstner-radio/:artistId?" component={ArtistRadioPage} />
                                <Route path="/kunstner/:artistId" component={ArtistPage} />
                                <Route path="/kunstner/:artistId/vis-alle/album" component={ArtistAlbumsShowAllPage} />
                                <Route path="/kunstner/:artistId/vis-alle/lignende-kunstnere" component={ArtistSimilarArtistsShowAllPage} />
                                <Route path="/kunstner/:artistId/vis-alle/singler-og-ep" component={ArtistSinglesShowAllPage} />
                                <Route path="/kunstner/:artistId/vis-alle/alle-sange" component={ArtistAllTracksShowAllPage} />
                                <Route path="/live-radio-kategori/:liveRadioCategoryId" component={LiveRadioCategoryPage} />
                                <Route path="/live-radio/:liveRadioId" component={LiveRadioPage} />
                                <Route path="/more-music-kategori/:mixRadioCategoryId/:mixRadioId?" component={MixRadioCategoryPage} />
                                <Route path="/more-music/:mixRadioId?" component={MixRadiosPage} />
                                <Route path="/playliste-kategori/:playlistCategoryId" component={PlaylistCategoryPage} />
                                <Route path="/playliste/:playlistId" component={PlaylistPage} />
                                <Route path="/radio" component={RadioPage} />
                                <Route path="/stationer" component={RadioPage} />
                                <Route path="/radio/vis-alle/:itemId/:resourceId?" component={RadioPageItemShowAllPage} />
                                <Route path="/stationer/vis-alle/:itemId/:resourceId?" component={RadioPageItemShowAllPage} />
                                <Route path="/sang-radio/:trackId?" component={TrackRadioPage} />
                                <Route path="/sang/:trackId" component={AlbumPage} />
                                <Route path="/side/:pageId" component={LayoutPage} />
                                <Route path="/side/:pageId/vis-alle/:itemId/:resourceId?" component={LayoutPageItemShowAllPage} pageType={ContentType.ThemePageShowAll} />
                                <Route path="/udforsk" component={SearchPage} />
                                <Route path={formatUrlToUTF8("/søgning/album/vis-alle/:criterion")} component={SearchAlbumResultsShowAllPage} />
                                <Route path={formatUrlToUTF8("/søgning/kunstnere/vis-alle/:criterion")} component={SearchArtistResultsShowAllPage} />
                                <Route path={formatUrlToUTF8("/søgning/playlister/vis-alle/:criterion")} component={SearchPlaylistResultsShowAllPage} />
                                <Route path={formatUrlToUTF8("/søgning/sange/vis-alle/:criterion")} component={SearchTrackResultsShowAllPage} />
                                <Route path={formatUrlToUTF8(`/kunstner/:artistId/vis-alle/populære-sange`)} component={ArtistPopularTracksShowAllPage} />
                                <Route path={formatUrlToUTF8(`/kunstner/:artistId/vis-alle/optræder-på`)} component={ArtistAppearsOnShowAllPage} />
                                <UnhandledRoute default component={NotFoundPage} />
                            </Router>
                        }
                        {
                            !loginStateIsKnown &&
                            <PageLoadSpinner />
                        }
                    </DefaultTemplate>
                )}
                <AppBanner />
            </div>
            <AppToast className="toasts" />
            <AppModal ref={modalRef} className="modals" />
            <AppContextMenu ref={contextMenuRef} className="contextMenus" />
            <DragLabel />
            <SessionFrame />
        </div>
    );
};

export const App = () => {
    return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <ApolloProvider client={graphqlClient as any}>
            <Provider store={store}>
                <_App />
            </Provider>
        </ApolloProvider>
    );
};
