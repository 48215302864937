import { updatePlayQueue } from "./updatePlayQueue";
import { getCurrentLaneIndexFromState, getMixRadioQueueTracks } from "../helpers";
import { store } from "global";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { QueueMode } from "models/app/player/input";
import type { PlayQueueModel } from "models/app/player/input";

export let guard = false;
export async function addMixRadioTracksToQueue(context: AudioContextModel) {
    const mode = store.getState().queue.mode;
    if (mode !== QueueMode.MixRadio) return;

    if (guard) return;
    guard = true;

    const queue = store.getState().queue;
    const currentIndex = getCurrentLaneIndexFromState();
    const result = await getMixRadioQueueTracks(queue, currentIndex);

    if (!result) {
        guard = false;
        return;
    }

    const newQueue: PlayQueueModel = { ...queue, playQueue: result.queueTracks };
    await updatePlayQueue(newQueue, context, false, result.queueAddData);
    guard = false;
}
