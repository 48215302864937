import type { Action } from "../actions";
import {
    PLAYLIST_TRACK_REORDERED,
    PLAYQUEUE_PRIORITY_LANE_WARNING_TOGGLE,
    PLAYQUEUE_PLAY_TRACK,
    PLAYQUEUE_HISTORY_TOGGLE,
    LIVERADIO_SET_AND_PLAY,
    RECENT_TRACKS_ADD_ID,
    RECENT_TRACKS_UPDATE,
    PLAYQUEUE_ENDLESSPLAY_TOGGLE,
    PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE,
    PLAYQUEUE_SET_AND_PLAY,
    PLAYQUEUE_UPDATE,
    PLAYQUEUE_UPDATE_MIXRADIO_SESSION,
    REHYDRATE,
    SET_DESKTOP_QUEUE_OPEN
} from "../actions";
import { AudioInputType } from "models/app/player";
import type { PlayQueueModel } from "models/app/player/input";
import { QueueMode } from "models/app/player/input";
import { ContentType } from "models/ModelType";

const initialState: PlayQueueModel = {
    type: AudioInputType.PlayQueue,
    playQueue: [],
    endlessPlay: [],
    isEndlessPlayOn: true,
    endlessPlayTrackId: null,
    context: null,
    trackParent: null,
    trackParents: [],
    mixRadioSession: null,
    mode: QueueMode.Default,
    firstQueueSession: true,
    recentTracksLastUpdated: 0,
    recentTrackIds: [],
    version: 1,
    historyOpen: false,
    priorityLaneWarning: true
};

export function playQueueReducer(state: PlayQueueModel = initialState, action: Action): PlayQueueModel {
    switch (action.type) {
        case RECENT_TRACKS_ADD_ID: {
            const { id } = action.payload;

            const recentTrackIdsSet = new Set(state.recentTrackIds);
            recentTrackIdsSet.delete(id);
            recentTrackIdsSet.add(id);

            return { ...state, recentTrackIds: [...recentTrackIdsSet] };
        }
        case PLAYLIST_TRACK_REORDERED: {
            const { trackId, fromPosition, toPosition } = action.payload;
            const playQueue = state.playQueue.map((item) => {
                const isTrackEqual = item.track.id === trackId && item.track.position === fromPosition;
                if (isTrackEqual) {
                    item.track = { ...item.track, position: toPosition };
                }
                return item;
            });
            return { ...state, playQueue };
        }
        case PLAYQUEUE_PRIORITY_LANE_WARNING_TOGGLE: {
            return { ...state, priorityLaneWarning: action.payload.on };
        }
        case RECENT_TRACKS_UPDATE: {
            const { recentTrackIds, lastUpdated } = action.payload;

            return { ...state, recentTracksLastUpdated: lastUpdated, recentTrackIds };
        }
        case PLAYQUEUE_SET_AND_PLAY: {
            const { playable } = action.payload;

            if (playable.contentType === ContentType.MixRadio) {
                return { ...state, mode: QueueMode.MixRadio };
            }
            return { ...state, mode: QueueMode.Default, mixRadioSession: null };
        }
        case LIVERADIO_SET_AND_PLAY: {
            return { ...state, mode: QueueMode.Default };
        }
        case SET_DESKTOP_QUEUE_OPEN: {
            const { autoOpen } = action.payload;
            if (!autoOpen) return { ...state, historyOpen: false };
            return { ...state, firstQueueSession: false, historyOpen: false };
        }
        case PLAYQUEUE_UPDATE: {
            const { playQueue, endlessPlay, trackParent, trackParents } = action.payload;
            return { ...state, playQueue, endlessPlay, trackParent, trackParents };
        }
        case PLAYQUEUE_PLAY_TRACK: {
            if (state.historyOpen) return state;
            return { ...state, historyOpen: false };
        }
        case PLAYQUEUE_HISTORY_TOGGLE: {
            return { ...state, historyOpen: !state.historyOpen };
        }
        case PLAYQUEUE_ENDLESSPLAY_TOGGLE: {
            return { ...state, isEndlessPlayOn: action.payload.on };
        }
        case PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE: {
            const { trackId } = action.payload;
            return { ...state, endlessPlayTrackId: trackId };
        }
        case PLAYQUEUE_UPDATE_MIXRADIO_SESSION: {
            const { mixRadioSession } = action.payload;
            return { ...state, mixRadioSession };
        }
        case REHYDRATE: {
            const queue = action.payload?.queue ?? null;
            if (queue == null) return initialState;
            if (queue.version === undefined) {
                return {
                    ...initialState,
                    firstQueueSession: queue.firstQueueSession
                };
            }
            return { ...initialState, ...queue, historyOpen: false };
        }

        default:
            return state;
    }
}
