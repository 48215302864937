import type { StateUpdater } from "preact/hooks";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";

export const useDebouncedState = <T>(initialState: T, ms: number): [T, StateUpdater<T>, StateUpdater<T>] => {
    const [state, setStateInstant] = useState<T>(initialState);
    const [setState, teardown] = useMemo(() => debounce(setStateInstant, ms), [setStateInstant, ms]);
    const externalSetStateInstant = useCallback(
        (val: T | ((prevState: T) => T)) => {
            setState(val);
            setStateInstant(val);
        },
        [setStateInstant, setState]
    );
    useEffect(() => () => teardown(), [teardown]);
    return [state, setState, externalSetStateInstant];
};

export const useDebounce = <A = unknown, R = void>(fn: (args: A) => R, ms: number): ((args: A) => Promise<R>) => {
    const [debouncedFun, teardown] = useMemo(() => debounce<A, R>(fn, ms), [fn, ms]);
    useEffect(() => () => teardown(), [teardown]);
    return debouncedFun;
};

// copied from https://dev.to/bwca/create-a-debounce-function-from-scratch-in-typescript-560m
export const debounce = <A = unknown, R = void>(fn: (args: A) => R, ms: number): [(args: A) => Promise<R>, () => void] => {
    let timer: NodeJS.Timeout;

    const debouncedFunc = (args: A): Promise<R> =>
        new Promise((resolve) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                resolve(fn(args));
            }, ms);
        });

    const teardown = () => clearTimeout(timer);

    return [debouncedFunc, teardown];
};
