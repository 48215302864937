import type { PlayQueuePlayableModelInterface, ConnectionModel, TrackPreviewModel } from ".";
import { createMultiselectTrackPreviewConnectionModel } from ".";
import { DomainModelType, ContentType } from "models/ModelType";

export type MultiselectionTracksModel = MultiselectionTracksPlayModel;
export type MultiselectionTracksPlayModel = { type: DomainModelType.Play } & MultiselectionTracks;

interface MultiselectionTracks extends PlayQueuePlayableModelInterface {
    readonly title: string;
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
    readonly contentType: ContentType.TrackSelection;
}

export function createMultiselectionTracksPlayModel(tracks: TrackPreviewModel[], title = ""): MultiselectionTracksPlayModel {
    return {
        type: DomainModelType.Play,
        graphType: "",
        id: "trackSelection",
        title,
        cover: null,
        trackCount: tracks.length,
        contentType: ContentType.TrackSelection,
        tracks: createMultiselectTrackPreviewConnectionModel(tracks)
    };
}
