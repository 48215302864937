import type { ProductPageType } from "./resourceProductPageService";
import { translate, translateWithReplacement } from "global/config";
import { environment } from "services/environment";
import { toLocaleStringUsingLocalization } from "services/formatters";
import { getLongTimeSpanFromSecondsHHMM, getShortTimeSpanFromSecondsHHMMSS, getNotificationsDateOrTimeAgo, getYearYYYY } from "services/formatters/timeFormatters";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { navigation } from "services/navigation";
import type { QueueTrackModel } from "models/app/player/input";
import type { ContextResourceModel, LinkContextModel, PageContextModel, PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import { MixRadioLinkParentType, NotificationType } from "models/domain";
import type {
    LiveRadioModel,
    LiveRadioTrackModel,
    SearchAlbumResultsModel,
    SearchArtistResultsModel,
    SearchPlaylistResultsModel,
    SearchTrackResultsModel,
    ArtistModel,
    ArtistSimilarArtistsModel,
    ArtistTracksModel,
    AlbumArtistAlbumsModel,
    AlbumPageModel,
    AlbumModel,
    ArtistAlbumsModel,
    ArtistAlbumsAppearsOnModel,
    ArtistRadioModel,
    ArtistRadioPageModel,
    ArtistSinglesModel,
    ChartModel,
    ChartPageModel,
    LiveRadioCategoryModel,
    LiveRadioPlayableModel,
    MixRadioModel,
    MixedContentModel,
    MixedContentPreviewModel,
    MixedContentTrackModel,
    PlaylistCategoryModel,
    PlaylistModel,
    PlaylistPageModel,
    RecommendationModel,
    LocationContentType,
    ResourceModel,
    ResourcePageModel,
    SearchTrackResultModel,
    TrackPlayableModel,
    TrackRadioModel,
    TrackRadioPageModel,
    MixRadioCategoryPreviewModel
} from "models/domain";
import type { BannerPreviewModel } from "models/domain/BannerModel";
import type { LayoutPageModel, LayoutItemModel } from "models/domain/layoutPage";
import type { MultiselectionTracksPlayModel } from "models/domain/MultiselectionTracksModel";
import { ContentType, DomainModelType } from "models/ModelType";
import { PreviewDisplayType } from "components/molecules/preview";

const getMixedContentSubtitleText = (resource: MixedContentModel | null) => {
    if (resource?.mixedContentType === ContentType.MyMusicTracks) return text(translate("Tracks"));
    return text(resource?.subtitle ?? undefined);
};

const getRecommendationSubtitleText = (resource: RecommendationModel | null) => text(resource?.subtitle);

const getYearText = (year?: number) => text(year?.toFixed());

const getFollowersText = (followers?: number) => text(followers != undefined ? `${translate("PlaylistFollowers")} ${toLocaleStringUsingLocalization(followers)}` : undefined);

const getDurationShortTimeSpanText = (duration: number | null | undefined) => text(duration ? getShortTimeSpanFromSecondsHHMMSS(duration) : undefined);

const getDurationLongTimeSpanText = (duration?: number) => text(duration != undefined ? getLongTimeSpanFromSecondsHHMM(duration) : undefined);

const getTimeAgoTimeSpanText = (duration?: number) => text(duration != undefined ? getNotificationsDateOrTimeAgo(duration) : undefined);

const getNotInSubscriptionText = () => text("Ikke tilgængeligt længere");

const getReleaseYearText = (releaseDate: Date | null | undefined) => text(releaseDate ? getYearYYYY(releaseDate) : undefined);

const getTrackCountText = (trackCount: number | null | undefined) =>
    text(trackCount !== undefined && trackCount !== null ? `${trackCount.toFixed()} ${(trackCount === 1 ? translate("Track") : translate("Tracks")).toLowerCase()}` : undefined); // add track/tracks as lower case

const getArtistRadioDescriptionText = (resource: ArtistRadioModel | null) => text(resource ? getArtistRadioSubtitle(resource) : undefined);

const getAlbumLink = (resource: AlbumModel | null | undefined) =>
    link(ContentType.Album, resource, resource?.title, resource ? navigation.templates.album(resource.id) : undefined);

const getAlbumArtistAlbumsLink = (resource: AlbumArtistAlbumsModel | null | undefined) =>
    link(ContentType.AlbumArtistAlbums, resource, getMoreFromTitle(resource?.artistName), resource ? navigation.templates.albumArtistAlbums(resource.id) : undefined);

const getArtistLink = (resource: ArtistModel | null | undefined) =>
    link(ContentType.Artist, resource, resource?.name, resource ? navigation.templates.artist(resource.id) : undefined);

const getArtistAndFeaturedArtistsLinks = (artist: ArtistModel | null | undefined, featuredArtists: ArtistModel[] | null | undefined) => {
    const links = [link(ContentType.Artist, artist, artist?.name, artist ? navigation.templates.artist(artist.id) : undefined)];
    featuredArtists?.forEach((fa) => {
        const item = link(ContentType.Artist, fa, fa.name, navigation.templates.artist(fa.id));
        links.push(item);
    });
    return links;
};

const getArtistTracksLink = (resource: ArtistTracksModel | null | undefined, section?: SectionContextModel) => {
    const getArtistPopularTracksTitle = () => {
        if (section?.type === ContentType.Queue) {
            if (resource?.type === DomainModelType.Page) {
                return resource.artist.name;
            }
        }
        return translate("PopularSongs");
    };

    return link(ContentType.ArtistTracks, resource, getArtistPopularTracksTitle(), resource ? navigation.templates.artistPopularTracks(resource.id) : undefined);
};

const getArtistAlbumsLink = (resource: ArtistAlbumsModel | null | undefined) =>
    link(ContentType.ArtistAlbums, resource, translate("Album"), resource ? navigation.templates.artistAlbums(resource.id) : undefined);

const getArtistAlbumsAppearsOnLink = (resource: ArtistAlbumsAppearsOnModel | null | undefined) =>
    link(ContentType.ArtistAppearsOn, resource, translate("AppearsOn"), resource ? navigation.templates.ArtistAlbumsAppearsOn(resource.id) : undefined);

const getArtistSimilarArtistsLink = (resource: ArtistSimilarArtistsModel | null | undefined) =>
    link(ContentType.ArtistSimilarArtists, resource, translate("SimilarArtists"), resource ? navigation.templates.artistSimilarArtists(resource.id) : undefined);

const getArtistSinglesLink = (resource: ArtistSinglesModel | null | undefined) =>
    link(ContentType.ArtistSingles, resource, translate("SinglesAndEps"), resource ? navigation.templates.artistSingles(resource.id) : undefined);

const getArtistRadioLink = (resource: ArtistRadioModel | null | undefined) =>
    link(ContentType.ArtistRadio, resource, resource ? getArtistRadioTitle(resource) : undefined, resource ? navigation.templates.artistRadio(resource.id) : undefined);

const getChartLink = (resource: ChartModel | null | undefined) =>
    link(ContentType.Chart, resource, resource?.title, resource ? navigation.templates.chart(resource.id) : undefined);

const getLiveRadioCategoryLink = (resource: LiveRadioCategoryModel | null | undefined) =>
    link(ContentType.LiveRadioCategory, resource, resource?.title, resource ? navigation.templates.liveRadioCategory(resource.id) : undefined);

const getLiveRadioPlayableLink = (resource: LiveRadioPlayableModel | null | undefined) =>
    link(ContentType.LiveRadioPlayable, resource, resource?.liveRadio.title, resource ? navigation.templates.liveRadio(resource.id) : undefined);

const getLiveRadioLink = (resource: LiveRadioModel | null | undefined) =>
    link(ContentType.LiveRadio, resource, resource?.title, resource ? navigation.templates.liveRadio(resource.id) : undefined);

const getMixRadiosLink = (title: string | null) => link(ContentType.MixRadios, null, title ?? translate("AllMixRadiosPageTitle"), navigation.templates.mixRadios());

const getMixRadioLink = (resource: MixRadioModel | null) => {
    const type = ContentType.MixRadio;
    switch (resource?.parent.type) {
        case MixRadioLinkParentType.LayoutPageItem:
            return link(type, resource, resource.title, navigation.templates.layoutPageItemResource(resource.parent.pageId, resource.parent.itemId, resource.id));
        case MixRadioLinkParentType.MixRadioCategory:
            return link(type, resource, resource.title, navigation.templates.mixRadioCategoryMixRadio(resource.parent.categoryId, resource.id));
        case MixRadioLinkParentType.MixRadios:
        case MixRadioLinkParentType.Notification:
            return link(type, resource, resource.title, navigation.templates.mixRadiosMixRadio(resource.id));
        case undefined:
            return link(type, resource, undefined, undefined);
    }
};

const getMixRadioCategoryLink = (resource: MixRadioCategoryPreviewModel | null) =>
    link(ContentType.MixRadioCategory, resource, resource?.title, resource ? navigation.templates.mixRadioCategory(resource.id) : undefined);

const getLayoutPageLink = (resource: LayoutPageModel | null) => {
    if (!resource) return link(ContentType.LayoutPage, undefined, undefined, undefined);
    if (resource && resource.id === environment.apiFrontPageId) return getFrontPageLink();
    if (resource && resource.id === environment.apiRadioPageId) return getRadioPageLink();
    return link(ContentType.LayoutItem, null, resource.title, navigation.templates.layoutPage(resource.id));
};

const getRadioPageLink = () => link(ContentType.Radio, null, translate("NavBarStations"), navigation.templates.radio());

const getFrontPageLink = () => link(ContentType.FrontPage, null, translate("NavBarFrontPage"), navigation.templates.frontPage());
const getSettingsPageLink = () => link(ContentType.SettingsPage, null, translate("SettingsTitle"), navigation.templates.settingsPage());
const getProfilesPageLink = () => link(ContentType.UserHistory, null, translate("ManageProfiles"), navigation.templates.profilesPage());

const getPlaylistLink = (resource: PlaylistModel | null) =>
    link(ContentType.Playlist, resource, resource?.title, resource ? navigation.templates.playlist(resource.id) : undefined);

const getPlaylistCategoryLink = (resource: PlaylistCategoryModel | null) =>
    link(ContentType.PlaylistCategory, resource, resource?.title, resource ? navigation.templates.playlistCategory(resource.id) : undefined);

const getQueueTrackPlayableLink = (resource: QueueTrackModel | null) =>
    link(ContentType.QueueTrack, resource, resource?.track.title, resource ? navigation.templates.track(resource.track.id) : undefined);

const getSearchTrackLink = (resource: SearchTrackResultModel | null) =>
    link(ContentType.SearchResultTrack, resource, resource?.track?.title, resource?.track ? navigation.templates.track(resource.track.id) : undefined);

const getTrackPlayableLink = (resource: TrackPlayableModel | null) =>
    link(ContentType.TrackPlayable, resource, resource?.track.title, resource ? navigation.templates.track(resource.track.id) : undefined);

const getLiveRadioTrackLink = (resource: LiveRadioTrackModel | null) =>
    link(ContentType.LiveRadioTrack, resource, resource?.track.title, resource ? navigation.templates.track(resource.track.id) : undefined);

const getMyMusicAlbumsLink = () => link(ContentType.MyMusicAlbums, null, translate("MyMusicAlbumsTitle"), navigation.templates.myMusicAlbums());

const getMyMusicArtistsLink = () => link(ContentType.MyMusicArtists, null, translate("MyMusicArtistTitle"), navigation.templates.myMusicArtists());

const getMyMusicPlaylistsLink = () => link(ContentType.MyMusicPlaylists, null, translate("MyMusicPlaylistsTitle"), navigation.templates.myMusicPlaylists());

const getMyMusicTracksLink = () => link(ContentType.MyMusicTracks, null, translate("MyMusicTracksTitle"), navigation.templates.myMusicTracks());

const getLayoutPageItemLink = (resource: LayoutItemModel | null) =>
    link(ContentType.LayoutItem, resource, resource?.name ?? undefined, resource ? navigation.templates.layoutPageItem(resource.pageId, resource.id) : undefined);

const getMixedContentLink = (resource: MixedContentPreviewModel | null): LinkModel => {
    if (resource == null) return text(undefined);

    const type = resource.mixedContentType;
    const id = resource.mixedContentId;
    let title = resource.title;

    switch (type) {
        case ContentType.MyMusicTracks:
            title = translate("MyMusicTitle");
            return link(ContentType.MyMusicTracks, resource, title, navigation.templates.myMusicTracks());
        case ContentType.UserRecommendations:
            return link(ContentType.Recommendation, resource, title, navigation.templates.userRecommendations());
        case ContentType.UserHistory:
            return link(ContentType.UserHistory, resource, title, navigation.templates.userHistory());
        case ContentType.YourMixesPage:
            return link(ContentType.TrackRecommendation, resource, title, navigation.templates.yourMixes());
    }

    if (id == null) {
        log.error({ code: "web-210216-1051", msg: "id is null", data: { contentType: resource.contentType } });
        return text(title);
    }

    switch (type) {
        case ContentType.Album:
            return link(ContentType.Album, resource, title, navigation.templates.album(id));
        case ContentType.Artist:
            return link(ContentType.Artist, resource, title, navigation.templates.artist(id));
        case ContentType.ArtistRadio:
            return link(ContentType.ArtistRadio, resource, title, navigation.templates.artistRadio(id));
        case ContentType.Playlist:
            return link(ContentType.Playlist, resource, title, navigation.templates.playlist(id));
        case ContentType.MixRadio:
            return link(ContentType.MixRadio, resource, title, navigation.templates.mixRadiosMixRadio(id));
        case ContentType.TrackPlayable:
            return link(ContentType.TrackPlayable, resource, title, navigation.templates.track(id));
        case ContentType.TrackChart:
            return link(ContentType.TrackChart, resource, title, navigation.templates.chart(id));
        case ContentType.TrackRadio:
            return link(ContentType.TrackRadio, resource, title, navigation.templates.trackRadio(id));
        case ContentType.LiveRadio:
            return link(ContentType.LiveRadio, resource, title, navigation.templates.liveRadio(id));
        case ContentType.LiveRadioCategory:
            return link(ContentType.LiveRadioCategory, resource, title, navigation.templates.liveRadioCategory(id));
    }
    type;
};

const getMixedContentTrackLink = (resource: MixedContentTrackModel | null) =>
    link(ContentType.MixedContentTrack, resource, resource?.track?.title, resource?.track ? navigation.templates.track(resource.track.id) : undefined);

const getRecommendationLink = (resource: RecommendationModel | null) => {
    const url = (() => {
        switch (resource?.owner) {
            case ContentType.UserHistory:
                return navigation.templates.userHistoryRecommendation(resource.id);
            case ContentType.UserRecommendations:
                return navigation.templates.userRecommendationsRecommendation(resource.id);
            case undefined:
                return undefined;
        }
    })();
    return link(resource?.contentType ?? ContentType.Recommendation, resource, resource?.title, url);
};

const getRecommendationsLink = () => link(ContentType.UserRecommendations, null, translate("GlobalUserRecommendationsTitle"), navigation.templates.userRecommendations());

const getYourHistoryLink = () => link(ContentType.UserHistory, null, translate("GlobalUserHistoryTitle"), navigation.templates.userHistory());

const getSearchLink = () => link(ContentType.Search, null, translate("SearchExploreTitle"), navigation.templates.search());

const getSearchAlbumResultsLink = (resource: SearchAlbumResultsModel | null) =>
    link(ContentType.SearchAlbumResults, resource, translate("Albums"), resource ? navigation.templates.searchAlbumResults(resource.criterion) : undefined);

const getSearchArtistResultsLink = (resource: SearchArtistResultsModel | null) =>
    link(ContentType.SearchArtistResults, resource, translate("Artists"), resource ? navigation.templates.searchArtistResults(resource.criterion) : undefined);

const getSearchPlaylistResultsLink = (resource: SearchPlaylistResultsModel | null) =>
    link(ContentType.SearchPlaylistResults, resource, translate("Playlists"), resource ? navigation.templates.searchPlaylistResults(resource.criterion) : undefined);

const getSearchTrackResultsLink = (resource: SearchTrackResultsModel | null) =>
    link(ContentType.SearchTrackResults, resource, translate("Tracks"), resource ? navigation.templates.searchTrackResults(resource.criterion) : undefined);

const getTrackRadioLink = (resource: TrackRadioModel | null) =>
    link(ContentType.TrackRadio, resource, resource ? getTrackRadioTitle(resource) : undefined, resource ? navigation.templates.trackRadio(resource.id) : undefined);

function linkContext(link: LinkModel, preview?: PreviewContextModel): LinkContextModel {
    return preview ? { ...link, preview } : link;
}

function link(type: LocationContentType, resource: ResourceModel | null | undefined, text: string | undefined, url: string | undefined): LinkModel {
    return { type, resource: resource ?? undefined, text, url };
}

function text(text: string | undefined): LinkModel {
    return { text };
}

export function getLinkFromResource(resource: ResourceModel | null, section?: SectionContextModel) {
    if (!resource) return null;
    return getLink(resource.contentType, resource, section);
}

export function getLinkFromContext(context: PreviewContextModel | SectionContextModel | PageContextModel) {
    return getLink(context.type, context.resource, "section" in context ? context.section : undefined);
}

export function getLink(type: LocationContentType, resource: ResourceModel | null | undefined, section?: SectionContextModel): LinkModel | null {
    if (!resource) resource = null;

    if (resource?.type) type = resource.contentType;

    switch (type) {
        case ContentType.Album:
            if (resource != null && resource.contentType !== ContentType.Album) throw Error();
            return getAlbumLink(resource);
        case ContentType.AlbumArtistAlbums:
            if (resource != null && resource.contentType !== ContentType.AlbumArtistAlbums) throw Error();
            return getAlbumArtistAlbumsLink(resource);
        case ContentType.Artist:
            if (resource != null && resource.contentType !== ContentType.Artist) throw Error();
            return getArtistLink(resource);
        case ContentType.ArtistAppearsOn:
            if (resource != null && resource.contentType !== ContentType.ArtistAppearsOn) throw Error();
            return getArtistAlbumsAppearsOnLink(resource);
        case ContentType.ArtistAlbums:
            if (resource != null && resource.contentType !== ContentType.ArtistAlbums) throw Error();
            return getArtistAlbumsLink(resource);
        case ContentType.ArtistSimilarArtists:
            if (resource != null && resource.contentType !== ContentType.ArtistSimilarArtists) throw Error();
            return getArtistSimilarArtistsLink(resource);
        case ContentType.ArtistSingles:
            if (resource != null && resource.contentType !== ContentType.ArtistSingles) throw Error();
            return getArtistSinglesLink(resource);
        case ContentType.ArtistTracks:
            if (resource != null && resource.contentType !== ContentType.ArtistTracks) throw Error();
            return getArtistTracksLink(resource, section);
        case ContentType.ArtistRadio:
            if (resource != null && resource.contentType !== ContentType.ArtistRadio) throw Error();
            return getArtistRadioLink(resource);
        case ContentType.ExternalLink:
            if (resource != null && resource.contentType !== ContentType.ExternalLink) throw Error();
            return { url: resource?.externalLink, target: resource?.target };
        case ContentType.Chart:
        case ContentType.AlbumChart:
        case ContentType.TrackChart:
            if (resource != null && resource.contentType !== ContentType.AlbumChart && resource.contentType !== ContentType.TrackChart) throw Error();
            return getChartLink(resource);
        case ContentType.TrackRadio:
            if (resource != null && resource.contentType !== ContentType.TrackRadio) throw Error();
            return getTrackRadioLink(resource);
        case ContentType.LiveRadio: {
            if (resource != null && resource.contentType !== ContentType.LiveRadio) throw Error();
            return getLiveRadioLink(resource);
        }
        case ContentType.LiveRadioCategory:
            if (resource != null && resource.contentType !== ContentType.LiveRadioCategory) throw Error();
            return getLiveRadioCategoryLink(resource);
        case ContentType.LiveRadioTrack:
            if (resource != null && resource.contentType !== ContentType.LiveRadioTrack) throw Error();
            return getLiveRadioTrackLink(resource);
        case ContentType.LiveRadioPlayable:
            if (resource != null && resource.contentType !== ContentType.LiveRadioPlayable) throw Error();
            return getLiveRadioPlayableLink(resource);
        case ContentType.MixRadio:
            if (resource != null && resource.contentType !== ContentType.MixRadio) throw Error();
            return getMixRadioLink(resource);
        case ContentType.MixRadios:
            if (resource != null && resource.contentType !== ContentType.MixRadios) throw Error();
            return getMixRadiosLink(resource?.title ?? null);
        case ContentType.MixRadioCategory:
            if (resource != null && resource.contentType !== ContentType.MixRadioCategory) throw Error();
            return getMixRadioCategoryLink(resource);
        case ContentType.TrackSelection:
            return text((resource as MultiselectionTracksPlayModel).title);
        case ContentType.Playlist:
            if (resource != null && resource.contentType !== ContentType.Playlist) throw Error();
            return getPlaylistLink(resource);
        case ContentType.PlaylistCategory:
            if (resource != null && resource.contentType !== ContentType.PlaylistCategory) throw Error();
            return getPlaylistCategoryLink(resource);
        case ContentType.TrackPlayable:
            if (resource != null && resource.contentType !== ContentType.TrackPlayable) throw Error();
            return getTrackPlayableLink(resource);
        case ContentType.QueueTrack:
            if (resource != null && resource.contentType !== ContentType.QueueTrack) throw Error();
            return getQueueTrackPlayableLink(resource);
        case ContentType.SearchResultTrack:
            if (resource != null && resource.contentType !== ContentType.SearchResultTrack) throw Error();
            return getSearchTrackLink(resource);
        case ContentType.MixedContentTrack:
            if (resource != null && resource.contentType !== ContentType.MixedContentTrack) throw Error();
            return getMixedContentTrackLink(resource);
        case ContentType.MyMusicPlaylists:
            return getMyMusicPlaylistsLink();
        case ContentType.MyMusicAlbums:
            return getMyMusicAlbumsLink();
        case ContentType.MyMusicArtists:
            return getMyMusicArtistsLink();
        case ContentType.MyMusicTracks:
            return getMyMusicTracksLink();
        case ContentType.Notification: {
            if (resource != null && resource.contentType !== ContentType.Notification) throw Error();
            if (resource?.playable) {
                const link = getLink(resource.playable.contentType, resource.playable, section);
                if (!link) return null;
                //link.text = resource.title;
                return link;
            }
            return text(resource?.title);
        }
        case ContentType.AlbumRecommendation:
        case ContentType.PlaylistRecommendation:
        case ContentType.TrackRecommendation:
        case ContentType.Recommendation:
            if (
                resource != null &&
                resource.contentType !== ContentType.AlbumRecommendation &&
                resource.contentType !== ContentType.PlaylistRecommendation &&
                resource.contentType !== ContentType.TrackRecommendation
            )
                throw Error();
            return getRecommendationLink(resource);
        case ContentType.UserRecommendations:
            return getRecommendationsLink();
        case ContentType.UserHistory:
            return getYourHistoryLink();
        case ContentType.PlaylistCategories:
        case ContentType.Search:
            return getSearchLink();

        case ContentType.MixedContent: {
            if (resource != null && resource.contentType !== ContentType.MixedContent) throw Error();
            return getMixedContentLink(resource);
        }
        case ContentType.LayoutItem: {
            if (resource != null && resource.contentType !== ContentType.LayoutItem) throw Error();

            return getLayoutPageItemLink(resource);
        }
        case ContentType.FrontPageShowAll:
        case ContentType.RadioPageShowAll:
        case ContentType.ThemePageShowAll:
            return text(undefined);
        case ContentType.SearchAlbumResults:
            if (resource != null && resource.contentType !== ContentType.SearchAlbumResults) throw Error();
            return getSearchAlbumResultsLink(resource);
        case ContentType.SearchArtistResults:
            if (resource != null && resource.contentType !== ContentType.SearchArtistResults) throw Error();
            return getSearchArtistResultsLink(resource);
        case ContentType.SearchPlaylistResults:
            if (resource != null && resource.contentType !== ContentType.SearchPlaylistResults) throw Error();
            return getSearchPlaylistResultsLink(resource);
        case ContentType.SearchTrackResults:
            if (resource != null && resource.contentType !== ContentType.SearchTrackResults) throw Error();
            return getSearchTrackResultsLink(resource);
        case ContentType.LayoutPage:
            if (resource != null && resource.contentType !== ContentType.LayoutPage) throw Error();
            return getLayoutPageLink(resource);
        case ContentType.Radio:
            return getRadioPageLink();
        case ContentType.FrontPage:
            return getFrontPageLink();
        case ContentType.ProfilesPage:
            return getProfilesPageLink();
        case ContentType.SettingsPage:
            return getSettingsPageLink();
        case ContentType.SideBar:
            return text("Side Bar");
        case ContentType.SearchMixedResults:
            return text(translate("BestResults"));
        case ContentType.SearchRecentResults:
            return text(translate("RecentSearches"));
        case ContentType.EndlessPlay:
            return text(translate("SettingsEndlessPlayTitle"));
        case ContentType.Skeleton:
        case ContentType.Player:
        case ContentType.PageLoadError:
        case ContentType.NotFound:
        case ContentType.NavBar:
            return text(undefined);
        case ContentType.NotificationFeed:
            return text(translate("NotificationFeedTitle"));
        case ContentType.InstallPWA:
        case ContentType.Queue:
        case ContentType.SearchResult: {
            log.error({ code: "web-210510-1818", msg: DefaultLogMessage.NotImplemented, data: { type } });
            return null;
        }
        case ContentType.BannerLayoutItemContent:
            if ((resource as BannerPreviewModel).target) {
                return getLink(type, (resource as BannerPreviewModel).target, section);
            }
            return text(undefined);
        case ContentType.Lyrics:
            return text(undefined);
        case ContentType.YourMixesPage:
            return link(ContentType.TrackRecommendation, resource, undefined, navigation.templates.yourMixes());
    }
    type;
}

export interface LinkModel {
    text?: string;
    url?: string;
    resource?: ResourceModel;
    type?: LocationContentType;
    target?: string;
}

export function createLinkContexts(
    type: LocationContentType,
    resource: ContextResourceModel | null,
    context?: PreviewContextModel,
    displayType?: PreviewDisplayType
): LinkContextModel[][] {
    const links = getLinks(type, resource, context?.section, displayType);
    const contexts = links.map((group) => group.map((link) => linkContext(link, context)));
    return contexts;
}

export function getLinks(type: LocationContentType, resource: ContextResourceModel | null, context?: SectionContextModel, displayType?: PreviewDisplayType): LinkModel[][] {
    const sectionType = context?.type ?? null;
    const pageType = context?.page?.type ?? null;

    type = resource?.contentType ?? type;

    switch (type) {
        case ContentType.Album: {
            if (resource != null && resource.contentType !== ContentType.Album) throw Error();

            const available = resource?.availableInSubscription ?? true;

            if (displayType === PreviewDisplayType.Row || displayType === PreviewDisplayType.Square) {
                if (pageType == ContentType.Search) return [[getAlbumLink(resource)], [text(translate("Album")), getArtistLink(resource?.artist)]];
                switch (sectionType) {
                    case ContentType.MyMusicAlbums:
                    case ContentType.AlbumArtistAlbums:
                    case ContentType.ArtistSingles:
                    case ContentType.ArtistAppearsOn:
                    case ContentType.ArtistAlbums:
                        if (available)
                            return [
                                [getAlbumLink(resource)],
                                [getArtistLink(resource?.artist)],
                                [getReleaseYearText(resource?.releaseDate), getTrackCountText(resource?.trackCount)]
                            ];
                        else return [[getAlbumLink(resource)], [getArtistLink(resource?.artist)], [getNotInSubscriptionText()]];
                }
            }

            if (available) return [[getAlbumLink(resource)], [getArtistLink(resource?.artist)]];
            else return [[getAlbumLink(resource)], [getArtistLink(resource?.artist)], [getNotInSubscriptionText()]];
        }
        case ContentType.Artist: {
            if (resource != null && resource.contentType !== ContentType.Artist) throw Error();

            if (pageType === ContentType.Search) return [[getArtistLink(resource)], [text(translate("Artist"))]];
            else return [[getArtistLink(resource)]];
        }
        case ContentType.ArtistRadio: {
            if (resource != null && resource.contentType !== ContentType.ArtistRadio) throw Error();

            if (displayType === PreviewDisplayType.Single) return [[getArtistRadioLink(resource)], [getArtistRadioDescriptionText(resource)]];
            else return [[getArtistRadioLink(resource)]];
        }
        case ContentType.Chart:
        case ContentType.AlbumChart:
        case ContentType.TrackChart: {
            if (resource != null && resource.contentType !== ContentType.AlbumChart && resource.contentType !== ContentType.TrackChart) throw Error();
            return [[getChartLink(resource)]];
        }
        case ContentType.LiveRadioCategory: {
            if (resource != null && resource.contentType !== ContentType.LiveRadioCategory) throw Error();
            return [[getLiveRadioCategoryLink(resource)], [text(resource?.description)]];
        }
        case ContentType.LiveRadioTrack:
            if (resource != null && resource.contentType !== ContentType.LiveRadioTrack) throw Error();
            return [[getLiveRadioTrackLink(resource)], [getArtistLink(resource?.track.artist)]];
        case ContentType.LiveRadio: {
            if (resource != null && resource.contentType !== ContentType.LiveRadio) throw Error();

            const title = resource?.title;
            const description = resource?.description ?? "Live radio";

            if (displayType === PreviewDisplayType.Headline) return [[text(title)]];
            if (sectionType === ContentType.Queue) {
                if (resource?.tracks?.nowPlaying?.track) {
                    return [[getLiveRadioTrackLink(resource.tracks.nowPlaying)], [getArtistLink(resource.tracks.nowPlaying?.track.artist)]];
                }
                return [[text(title)], [text(description)]];
            }
            if (sectionType === ContentType.Player) {
                if (resource?.tracks?.nowPlaying?.track) {
                    return [[text(title)], [getLiveRadioTrackLink(resource.tracks.nowPlaying), getArtistLink(resource.tracks.nowPlaying?.track.artist)]];
                }
                return [[text(title)], [text(description)]];
            }

            return [[text(title)], [text(description)]];
        }
        case ContentType.LiveRadioPlayable: {
            if (resource != null && resource.contentType !== ContentType.LiveRadioPlayable) throw Error();

            return getLinks(ContentType.LiveRadio, resource?.liveRadio ?? null, context, displayType);

            // return [[text(resource?.liveRadio.title)], [text(resource?.liveRadio.description ?? undefined)]];
        }
        case ContentType.MixRadio: {
            if (resource != null && resource.contentType !== ContentType.MixRadio) throw Error();
            return [[text(resource?.title)]];
        }
        case ContentType.MixRadioCategory: {
            if (resource != null && resource.contentType !== ContentType.MixRadioCategory) throw Error();
            return [[getMixRadioCategoryLink(resource)]];
        }
        case ContentType.MixRadios:
            if (resource != null && resource.contentType !== ContentType.MixRadios) throw Error();
            return [[getMixRadiosLink(null)]];
        case ContentType.PlaylistCategory: {
            if (resource != null && resource.contentType !== ContentType.PlaylistCategory) throw Error();
            switch (displayType) {
                case PreviewDisplayType.Square: {
                    const noTitle = pageType === ContentType.PlaylistCategories || pageType === ContentType.Search;
                    if (noTitle) return [];
                    else return [[getPlaylistCategoryLink(resource)]];
                }
                default:
                    return [[getPlaylistCategoryLink(resource)]];
            }
        }

        case ContentType.Playlist: {
            if (resource != null && resource.contentType !== ContentType.Playlist) throw Error();

            if (resource?.owner)
                return [
                    [getPlaylistLink(resource)],
                    !resource ? [] : [getTrackCountText(resource?.trackCount), getDurationLongTimeSpanText(resource?.duration ?? undefined)],
                    [text(resource?.owner ? translate("PlaylistCreatedByMe") : "")]
                ];

            if (pageType === ContentType.Search) return [[getPlaylistLink(resource)], [text(translate("GlobalPlaylistName"))]];

            if (sectionType === ContentType.Playlist) return [[getPlaylistLink(resource)]];
            if (pageType === ContentType.FrontPage) return [[getPlaylistLink(resource)]];
            return [[getPlaylistLink(resource)], [getTrackCountText(resource?.trackCount), getDurationLongTimeSpanText(resource?.duration ?? undefined)]];
        }
        case ContentType.TrackPlayable: {
            if (resource != null && resource.contentType !== ContentType.TrackPlayable) throw Error();

            if (pageType === ContentType.Search)
                return [
                    [getTrackPlayableLink(resource)],
                    [text(translate("GlobalTrackName")), ...getArtistAndFeaturedArtistsLinks(resource?.track?.artist, resource?.track?.featuredArtists)]
                ];

            switch (sectionType) {
                case ContentType.Album:
                    switch (displayType) {
                        case PreviewDisplayType.Row:
                            return [
                                [text(resource?.track.title)],
                                getArtistAndFeaturedArtistsLinks(resource?.track?.artist, resource?.track?.featuredArtists),
                                [getDurationShortTimeSpanText(resource?.track?.duration)]
                            ];
                        default:
                            return [[getTrackPlayableLink(resource)], [getArtistLink(resource?.track?.artist)]];
                    }
                case ContentType.Queue: // temporary until featuring artist render correctly in queue
                    switch (displayType) {
                        case PreviewDisplayType.Row:
                            return [
                                [getTrackPlayableLink(resource)],
                                [getArtistLink(resource?.track?.artist)],
                                [getAlbumLink(resource?.track?.album)],
                                [getDurationShortTimeSpanText(resource?.track?.duration)]
                            ];
                        default:
                            return [[getTrackPlayableLink(resource)], [getArtistLink(resource?.track?.artist)]];
                    }
                default:
                    switch (displayType) {
                        case PreviewDisplayType.Row:
                            return [
                                [getTrackPlayableLink(resource)],
                                getArtistAndFeaturedArtistsLinks(resource?.track?.artist, resource?.track?.featuredArtists),
                                [getAlbumLink(resource?.track?.album)],
                                [getDurationShortTimeSpanText(resource?.track?.duration)]
                            ];
                        default:
                            return [[getTrackPlayableLink(resource)], [getArtistLink(resource?.track?.artist)]];
                    }
            }
        }
        case ContentType.MyMusicAlbums: {
            return [[getMyMusicAlbumsLink()]];
        }
        case ContentType.MyMusicArtists: {
            return [[getMyMusicArtistsLink()]];
        }
        case ContentType.MyMusicPlaylists: {
            return [[getMyMusicPlaylistsLink()]];
        }
        case ContentType.MyMusicTracks: {
            return [[getMyMusicTracksLink()]];
        }
        case ContentType.Recommendation:
        case ContentType.AlbumRecommendation:
        case ContentType.PlaylistRecommendation:
        case ContentType.TrackRecommendation: {
            if (
                resource != null &&
                resource.contentType !== ContentType.AlbumRecommendation &&
                resource.contentType !== ContentType.PlaylistRecommendation &&
                resource.contentType !== ContentType.TrackRecommendation
            )
                throw Error();
            return [[getRecommendationLink(resource)], [getRecommendationSubtitleText(resource)]];
        }
        case ContentType.TrackRadio: {
            if (resource != null && resource.contentType !== ContentType.TrackRadio) throw Error();
            return [[getTrackRadioLink(resource)]];
        }
        case ContentType.Search: {
            return [[getSearchLink()]];
        }
        case ContentType.MixedContent: {
            if (resource != null && resource.contentType !== ContentType.MixedContent) throw Error();
            return [[getMixedContentLink(resource)], [getMixedContentSubtitleText(resource)]];
        }
        case ContentType.Skeleton: {
            if (resource == null) {
                log.error({ code: "web-211103-2233", msg: DefaultLogMessage.UnexpectedNull, data: { type } });
                return [];
            }
            if (resource.contentType !== ContentType.Skeleton) throw Error();
            return getLinks(resource.estimatedType, null, context, displayType);
        }
        case ContentType.Notification: {
            if (resource != null && resource.contentType !== ContentType.Notification) throw Error();

            const link = getLink(type, resource, context);
            if (!link) return [];

            link.text = resource?.title ?? link.text;

            const showSubtitle = !!link && !!resource?.subtitle && resource.subtitle != resource.title && resource.notificationType !== NotificationType.PlaylistUpdated;
            return showSubtitle
                ? [[link], [text(resource.subtitle)], [getTimeAgoTimeSpanText(resource.availableBegin)]]
                : [[text(resource?.title)], [getTimeAgoTimeSpanText(resource?.availableBegin)]];
        }
        default: {
            const link = getLink(type, resource, context);
            if (link) return [[link]];
            log.error({ code: "web-210510-1819", msg: DefaultLogMessage.NotImplemented, data: { type } });
            return [];
        }
    }
    type;
}

export function getShowRowNumber(resourceType: LocationContentType, context: PreviewContextModel): boolean {
    const pageContext = context.section.page;

    if (resourceType === ContentType.TrackPlayable) {
        if (pageContext?.type === ContentType.Album) return true;
        if (pageContext?.type === ContentType.Artist) return true;
        if (pageContext?.type === ContentType.ArtistTracks) return false;
        if (pageContext?.type === ContentType.Chart) return true;
        if (pageContext?.type === ContentType.TrackChart) return true;
    }
    if (resourceType === ContentType.Album) {
        if (pageContext?.type === ContentType.AlbumChart) return true;
        if (pageContext?.type === ContentType.Chart) return true;
    }
    return false;
}

export function getShowRowCover(preview: PreviewContextModel): boolean {
    if (preview.type === ContentType.TrackPlayable && preview.section?.page?.type === ContentType.Album) return false;
    return true;
}

export function getArtistRadioTitle(artistRadio: ArtistRadioModel): string {
    return translateWithReplacement("ArtistRadioTitle", "ArtistName", artistRadio.title);
}

export function getArtistRadioSubtitle(artistRadio: ArtistRadioModel): string {
    return translateWithReplacement("ArtistRadioSubTitle", "ArtistName", artistRadio.title);
}

export function getTrackRadioTitle(trackRadio: TrackRadioModel): string {
    return translateWithReplacement("TrackRadioTitle", "TrackName", trackRadio.title);
}

export function getMoreFromTitle(artistName: string | null | undefined): string {
    if (artistName == null) return "";
    return translateWithReplacement("MoreBy", "ArtistName", artistName);
}

export function getProductPageTexts(productPageType: ProductPageType, resource: ResourcePageModel | null): LinkModel[][] {
    switch (productPageType) {
        case ContentType.Album: {
            const album = resource as AlbumPageModel | undefined;
            return [[getYearText(album?.releaseDate?.getFullYear()), getDurationLongTimeSpanText(album?.duration ?? undefined)]];
        }
        case ContentType.Artist: {
            return [];
        }
        case ContentType.Playlist: {
            const playlist = resource as PlaylistPageModel | undefined;
            return !playlist
                ? []
                : [[getTrackCountText(playlist?.trackCount), getDurationLongTimeSpanText(playlist?.duration ?? undefined)], [getFollowersText(playlist?.followersCount)]];
        }
        case ContentType.Recommendation:
        case ContentType.AlbumRecommendation:
        case ContentType.PlaylistRecommendation:
        case ContentType.TrackRecommendation: {
            return [];
        }
        case ContentType.Chart:
        case ContentType.AlbumChart:
        case ContentType.TrackChart: {
            const chart = resource as ChartPageModel | undefined;
            return [[text(chart?.description ?? undefined)]];
        }
        case ContentType.ArtistRadio:
        case ContentType.TrackRadio: {
            const radio = resource as ArtistRadioPageModel | TrackRadioPageModel | undefined;
            return [[getTrackCountText(radio?.trackCount), getDurationLongTimeSpanText(radio?.duration)]];
        }
    }
}
