import { dispatch, messageBus } from "global";
import {
    AUDIO_OUTPUT_AUDIO_PLAY_CHANGE,
    PLAYER_NEXT,
    PLAYER_PREVIOUS,
    PLAYER_REWIND,
    PLAYER_SEEK,
    PLAYER_SET_PLAY,
    PLAYER_STARTED_BY_USER,
    PLAYQUEUE_PLAY_TRACK,
    PLAYQUEUE_SET_AND_PLAY
} from "global/actions";
import { PlayState } from "models/app/player";
import { getAudioPropertiesFromState } from "components/shared/hooks";

// Convenience event for when playback was started by a user-interaction
export function initPlayerStartedByUserEvent() {
    messageBus.subscribeEvery(PLAYQUEUE_PLAY_TRACK, onPlaybackTriggered);
    messageBus.subscribeEvery(PLAYER_REWIND, onPlaybackTriggered);
    messageBus.subscribeEvery(PLAYQUEUE_SET_AND_PLAY, onPlaybackTriggered);
    messageBus.subscribeEvery(PLAYER_NEXT, onPlaybackTriggeredIfPlaying);
    messageBus.subscribeEvery(PLAYER_PREVIOUS, onPlaybackTriggeredIfPlaying);
    messageBus.subscribeEvery(PLAYER_SEEK, onPlaybackTriggeredIfPlaying);

    messageBus.subscribeEvery(PLAYER_SET_PLAY, async (msg) => {
        if (msg.payload.play) {
            onPlaybackTriggered();
        } else {
            currentlyPlaying = false;
            clearThrottle();
        }
    });

    // this catches when the lowlevel player is started (or stopped) by mediakeys.
    // unfortunately it also catches when tracks finish playing and playback is briefly stopped and then restarted on the new track.
    // BUT we're supposed to claim a playback ticket in that case too, so this case does what we need although in a bad way...so I'm keeping it for now.
    // It would be preferable to have that case raised as a some kind of PLAYER_SWITCHED_TRACK event instead, so lets get back to it later.
    messageBus.subscribeEvery(AUDIO_OUTPUT_AUDIO_PLAY_CHANGE, (msg) => {
        if (!currentlyPlaying && msg.payload.playState === PlayState.Playing) {
            currentlyPlaying = true;
            onPlaybackTriggered();
        } else {
            currentlyPlaying = false;
        }
    });
}

function onPlaybackTriggeredIfPlaying() {
    const isPlaying = getAudioPropertiesFromState().playState === PlayState.Playing;
    if (isPlaying) {
        onPlaybackTriggered();
    }
}

async function onPlaybackTriggered() {
    if (throttled) {
        return;
    }
    setThrottle();

    dispatch({
        type: PLAYER_STARTED_BY_USER
    });
}

function clearThrottle() {
    if (timeoutId) {
        window.clearTimeout(timeoutId);
    }
    throttled = false;
}
function setThrottle(time = 1000) {
    clearThrottle();
    timeoutId = window.setTimeout(() => {
        throttled = false;
        timeoutId = null;
    }, time);
}
let timeoutId: number | null;
let currentlyPlaying = false;
let throttled = false;
