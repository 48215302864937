import { useEffect, useLayoutEffect, useState } from "preact/hooks";
import { useDesktopQueueOpen, useResizeObserver } from "components/shared/hooks";

export enum DesktopTemplateWidth {
    Step1 = "step1",
    Step2 = "step2",
    Step3 = "step3"
}

export function useDesktopProperties(element: HTMLElement | null) {
    const [templateWidth, setTemplateWidth] = useState<DesktopTemplateWidth | null>(null);
    const queueOpen = useDesktopQueueOpen();
    const queueLoaded = useDesktopQueueLoaded(queueOpen);

    const resize = useResizeObserver(element);
    const { width, height } = resize ?? { width: null, height: null };

    useLayoutEffect(() => {
        if (width == null || height == null) return;

        const minSidebarWidth = 201;
        const minPageContentWidth = 400;
        const minQueueWidth = 301;
        const min = minSidebarWidth + minPageContentWidth + minQueueWidth;

        let w: DesktopTemplateWidth;

        if (width >= 1024) w = DesktopTemplateWidth.Step3;
        else if (width >= min) w = DesktopTemplateWidth.Step2;
        else w = DesktopTemplateWidth.Step1;

        setTemplateWidth(w);
    }, [width, height]);

    return { templateWidth, queueOpen, queueLoaded };
}

function useDesktopQueueLoaded(queueOpen: boolean) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (queueOpen) {
            setLoaded(true);
            return;
        } else {
            let timeoutId: number | null = window.setTimeout(() => {
                timeoutId = null;
                setLoaded(false);
            }, 10_000);
            return () => {
                timeoutId && clearTimeout(timeoutId);
            };
        }
    }, [queueOpen]);

    return loaded;
}
