import { h } from "preact";
import "./actionrow.scss";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { Icon, IconName } from "components/atoms/icon";

interface Props {
    icon: IconName;
    text: string;
    onClick: () => void;
}

export const ActionRow = ({ icon, text, onClick }: Props) => {
    return (
        <div className={`actionRow cXp previewRow weG --type-custom --columns-1`}>
            <Button activated={false} design={ButtonDesign.PlayableRow} onClick={onClick}>
                <figure>
                    <div className="actionRowIcon">
                        <Icon icon={icon} />
                    </div>
                    <figcaption>
                        <div className="text">{text}</div>
                    </figcaption>
                </figure>
            </Button>
        </div>
    );
};
