import { api } from "./api";

export const pagination = {
    paginationDefaultInit: 50,
    paginationDefaultMore: 50,
    paginationBufferHeight: 1,
    paginationOn: true,
    eagerLoadingTrackCount: 200
};

export const getPaginationInit = (max?: number) => {
    let initCount = !pagination.paginationOn ? api.graphqlMaxItemsFetchFallback : pagination.paginationDefaultInit;
    if (max != null && max < initCount) initCount = max;

    return initCount;
};
