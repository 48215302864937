import { BrowserAudioItemPreloadSize } from "models/app/player/output/browserAudio/BrowserAudioItemModel";

export const playerConfig = {
    rewindLimit: 2,
    endlessPlayLength: 20,
    lastPositionUpdateIntervalMs: 100,
    playbackReportChunkUpdateIntervalMs: 1000,
    playerMultiSkipTimeoutMs: 600,
    hlsRetryTimeoutMs: 1000,
    smallPreloadDurationSec: 5,
    maxPreloadDuratioSec: 30 * 60,
    expectedHlsStreamValidSec: 30 * 60,
    chromecastQueueMaxCount: 100,
    chromecastQueueMaxLookbackCount: 19
};

export const preloadOrderConfig = [
    { index: 0, order: 0, size: BrowserAudioItemPreloadSize.Full },
    //{ index: 1, order: 1, size: BrowserAudioItemPreloadSize.Full }
];

export const playQueueShuffleCoefficients = {
    relativeWeightMax: 0.8,
    relativeWeightMin: 0.2
};
