import { OperationSource, tryOperation, syncPlayStateFromRemote, waitOne } from "../../actions";
import { log, LogTag } from "services/logger";
import { AudioContextAction } from "models/app/player/AudioContext";

export async function onPlayerStateChanged({ value: state }: cast.framework.RemotePlayerChangedEvent<chrome.cast.media.PlayerState>) {
    await waitOne();
   
    log.debug([LogTag.Chromecast], () => ({ code: "web-211029-1312", msg: `onPlayerStateChanged: ${state}` }));

    tryOperation(() => syncPlayStateFromRemote({ action: AudioContextAction.ChromecastPlayerStateChange, trace: null }), OperationSource.Chromecast);
}
