export enum KeyCode {
    KeyM = "KeyM",
    Space = "Space",
    KeyQ = "KeyQ"
}
export function handleKeyPress(keyCodes: KeyCode[], callback: (keyCode: KeyCode) => void): void {
    function isActiveElementInput() {
        const activeElement = document.activeElement;
        const inputs = ["input", "textarea"];

        if (!activeElement) return false;
        return inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1;
    }

    const handler = (e: KeyboardEvent) => {
        if (isActiveElementInput()) return;
        const code = e.code as KeyCode;
        if (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey) return;
        if (keyCodes.includes(code)) {
            e.preventDefault();
            callback(code);
        }
    };

    window.addEventListener("keydown", handler);
}
