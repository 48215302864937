import { useEffect, useMemo, useState } from "preact/hooks";
import { useLocalization } from "components/app/hooks";
import { dispatch } from "global";
import { NAVIGATION_PAGECONTEXT_UPDATE } from "global/actions";
import { updateActivePageContext } from "services/navigationStack/navigationStack";
import { createLinkContexts } from "services/resource";
import type { ContextResourceModel, CreatePageContextParams, PageContextModel, PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import { createLinkContext, createPageContext, createPreviewContext, createSectionContext } from "models/app/resourceContextModel";
import type { PageLoadError } from "models/app/ViewPageError";
import type { LocationContentType, ResourceDisplayType, ResourceModel } from "models/domain";
import type { PreviewDisplayType } from "components/molecules/preview";

function updatePageSectionsVisualRank(page: PageContextModel) {
    page.sections.sort((a, b) => (a.rank ?? Number.MAX_SAFE_INTEGER) - (b.rank ?? Number.MAX_SAFE_INTEGER));
    page.sections.forEach((section) => (section.visibleRank = null));

    const visible = page.sections.filter((section) => !section.isHidden);
    visible.forEach((section, i) => (section.visibleRank = i));

    updateActivePageContext(page);
}

function addSectionToPage(page: PageContextModel, section: SectionContextModel) {
    page.sections.push(section);

    updatePageSectionsVisualRank(page);
}

function removeSectionFromPage(page: PageContextModel, section: SectionContextModel) {
    page.sections = page.sections.filter((value) => value != section);
    updatePageSectionsVisualRank(page);
}

function updateSectionIsHidden(section: SectionContextModel, isHidden: boolean) {
    section.isHidden = isHidden;
    section.page && updatePageSectionsVisualRank(section.page);
}

export const usePageContext = ({ type, pageType, resource: page, root, done, extraInfo }: CreatePageContextParams) => {
    const pageContext = useFakePageContext({ type, pageType, resource: page, root, done, extraInfo });
    const local = useLocalization();
    useEffect(() => {
        dispatch({ type: NAVIGATION_PAGECONTEXT_UPDATE, payload: { context: pageContext, done } });
    }, [pageContext, done, local]);

    return pageContext;
};

export const useFakePageContext = ({ type, pageType, resource: page, root, done, extraInfo }: CreatePageContextParams) => {
    return useMemo(() => createPageContext({ type, pageType, resource: page, root, done, extraInfo }), [type, page, pageType, root, done, extraInfo]);
};

interface UseErrorPageContextProps {
    page: PageContextModel | null;
    error: PageLoadError;
}

export function useErrorPageContext({ page, error }: UseErrorPageContextProps) {
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (!page || done) return;
        setDone(true);
        dispatch({ type: NAVIGATION_PAGECONTEXT_UPDATE, payload: { context: page, done: true, error } });
    }, [page, error, done]);
}

export const useSectionContext = (
    type: LocationContentType,
    context: ContextResourceModel | null,
    page: PageContextModel | null,
    rank: number | null,
    displayType: ResourceDisplayType | null,
    alternateScrollId?: string
) => {
    const section = useMemo(() => createSectionContext(type, context, page, rank, displayType, alternateScrollId), [type, context, page, rank, displayType, alternateScrollId]);

    useEffect(() => {
        if (!page) return;
        addSectionToPage(page, section);
        return () => removeSectionFromPage(page, section);
    }, [page, section]);

    return section;
};

export const useSectionContextFromPage = (page: PageContextModel, displayType: ResourceDisplayType | null) => {
    const section = useSectionContext(page.type, page.resource, page, 0, displayType);
    return section;
};

export const useSectionIsHidden = (section: SectionContextModel, isHidden: boolean) => {
    useEffect(() => {
        updateSectionIsHidden(section, isHidden);
    }, [section, isHidden]);
};

export const usePreviewContext = (type: LocationContentType, context: ContextResourceModel | null, section: SectionContextModel, rank: number | null) => {
    const local = useLocalization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => createPreviewContext(type, context, section, rank), [type, context, section, rank, local]);
};

export const useLinkContextFromPreview = (preview: PreviewContextModel) => {
    const local = useLocalization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => createLinkContext(preview.type, preview.resource, preview), [preview, local]);
};

export const useLinkContext = (type: LocationContentType, context: ResourceModel | null, preview: PreviewContextModel) => {
    const local = useLocalization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => createLinkContext(type, context, preview), [type, context, preview, local]);
};

export const useOptionalLinkContext = (type: LocationContentType | null, context: ResourceModel | null, preview: PreviewContextModel) => {
    const local = useLocalization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => (!type ? null : createLinkContext(type, context, preview)), [type, context, preview, local]);
};

export const useLinkAndPreviewContext = (type: LocationContentType, context: ContextResourceModel | null, section: SectionContextModel, rank: number | null) => {
    const preview = usePreviewContext(type, context, section, rank);
    const link = useLinkContext(type, context, preview);

    return link;
};

export const useLinkContextsFromPreview = (preview: PreviewContextModel, displayType?: PreviewDisplayType) => {
    const local = useLocalization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => createLinkContexts(preview.type, preview.resource, preview, displayType), [preview, displayType, local]);
};
