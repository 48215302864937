import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import type { RoutableProps, RouteProps } from "preact-router";
import { getCurrentUrl, route, Route } from "preact-router";
import { dispatch } from "global";
import { ROUTER_REDIRECTING } from "global/actions";
import { getRedirectUrl } from "services/backend";
import { UrlTransformVersion } from "generated/graphql-types";
import { NotFoundPage } from "components/pages/errorPages/NotFoundPage";

export const UnhandledRoute = (props: RouteProps<RoutableProps>) => {
    const [redirectUrl, setRedirectUrl] = useState<string | null>();
    const [hasResult, setHasResult] = useState(false);

    const fetchData = async () => {
        try {
            const url = getCurrentUrl();
            const path = await getRedirectedUrl(url);

            setRedirectUrl(path);
            setHasResult(true);
        } catch (e) {
            setHasResult(true);
            console.error(e);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (redirectUrl == undefined) return;
        if (redirectUrl.startsWith("http")) {
            window.location.href = redirectUrl;
        } else {
            const url = getCurrentUrl();
            dispatch({ type: ROUTER_REDIRECTING, payload: { from: url, to: redirectUrl } });
            route(redirectUrl);
        }
    }, [redirectUrl]);

    if (!hasResult || redirectUrl) return null;
    return <Route default component={NotFoundPage} />;
};

async function getRedirectedUrl(url: string) {
    const origin = window.location.origin;
    const wholeUrl = origin + url;

    const result = (await getRedirectUrl({ transform: { transformTo: UrlTransformVersion.V_2, url: wholeUrl } })).model;

    if (result == null) return;

    if (!result.startsWith(origin)) return result;
    const path = result.substring(origin.length);

    return path;
}
