import { useSelector } from "react-redux";
import { Feature, isFeatureEnabled, store } from "global/config";
import { getTrackLyricsId, useTrackLyricsId } from "services/backend";
import { useLoginState } from "services/user";
import type { RootModel } from "models/app";
import { LoginState } from "models/app";
import { AudioInputType } from "models/app/player";
import { getQueueCurrentLaneFromState, useCurrentLanePrimaryResourceFromState } from "components/shared/hooks";
import { useDesktopLyricsOpen } from "components/shared/hooks/useDesktopLyricsOpen";

export function useLyricsState() {
    const isPlayQueue = useSelector((root: RootModel) => root.player.input) === AudioInputType.PlayQueue;
    const currentQueueTrackId = useCurrentLanePrimaryResourceFromState()?.resource?.id;
    const currentQueueLyricsId = useTrackLyricsId({ id: currentQueueTrackId ?? "0" })?.model;
    const isLyricsEnabled = (useLoginState() === LoginState.LoggedIn) && isFeatureEnabled(Feature.Lyrics);
    const isLyricsAvailable = isPlayQueue && !!currentQueueLyricsId;
    const isLyricsOpen = useDesktopLyricsOpen();
    return {
        isLyricsEnabled,
        isLyricsAvailable,
        isLyricsOpen,
        isPlayQueue,
        currentQueueLyricsId,
        currentQueueTrackId
    }
}

export async function getLyricsState() {
    const state = store.getState();
    const isPlayQueue = state.player.input === AudioInputType.PlayQueue;
    const currentQueueTrackId = getQueueCurrentLaneFromState()?.track?.id;
    const currentQueueLyricsId = (await getTrackLyricsId({ id: currentQueueTrackId ?? "0" }))?.model;
    const isLyricsEnabled = (state.user.state === LoginState.LoggedIn) && isFeatureEnabled(Feature.Lyrics);
    const isLyricsAvailable = isPlayQueue && !!currentQueueLyricsId;
    const isLyricsOpen = state.ui.layout.lyricsOpen;
    return {
        isLyricsEnabled,
        isLyricsAvailable,
        isLyricsOpen,
        isPlayQueue,
        currentQueueLyricsId,
        currentQueueTrackId
    }
}
