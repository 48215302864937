import { h } from "preact";
import { Modal, modalButtonDismissCancel, modalButtonSubmitContinue, openModal } from "..";
import { translate, translateWithReplacement, useTranslations } from "global/config";
import { UserProfileModel } from "models/app";

export async function showDeleteProfileModal(profile: UserProfileModel) {
    return openModal(<DeleteProfileModal profile={profile} />);
}

export function DeleteProfileModal(props: Readonly<{ profile: UserProfileModel }>) {
    return <Modal
        title={translate("DeleteProfileTitle")}
        text={translateWithReplacement("DeleteProfileSubtitle", "profileName", props.profile.title)}
        buttons={[modalButtonDismissCancel(), modalButtonSubmitContinue()]}
    />;
}
