import { h } from "preact";
import { useState } from "preact/hooks";
import "./PlayerDesktop.scss";
import { dispatch } from "global";
import { PLAYER_SEEK } from "global/actions";
import { AudioContextAction } from "models/app/player/AudioContext";
import { PlayRange, RangeType } from "components/atoms/controls/playRange";
import { DesktopPlayerPrimaryControls } from "components/molecules/playerPrimaryControl";
import { DesktopPlayerSecondaryControls } from "components/molecules/playerSecondaryControl";
import { DesktopPlayerTrackControl } from "components/molecules/playerTrackControl";

interface Props {
    className?: string;
    showQueue: boolean;
    toggleQueue: () => void;
}
export const PlayerDesktop = ({ className, showQueue, toggleQueue }: Props) => {
    const [seekPosition, setSeekPosition] = useState<number | null>(null);
    const seekChange = (percent: number) => dispatch({ type: PLAYER_SEEK, payload: { percent, context: { action: AudioContextAction.UserPlayerSeek, trace: null } } });

    return (
        <div tabIndex={-1} className={`playerDesktop EmA ${className ?? ""}`}>
            <PlayRange type={RangeType.Desktop} onChange={seekChange} onSeekPositionChange={setSeekPosition} />
            <div className="controls">
                <DesktopPlayerTrackControl />
                <DesktopPlayerPrimaryControls />
                <DesktopPlayerSecondaryControls showQueue={showQueue} toggleQueue={toggleQueue} seekPosition={seekPosition} />
            </div>
        </div>
    );
};
