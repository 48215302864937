import type { PageContextModel } from "models/app/resourceContextModel";
import type { LocationContentType } from "models/domain";
import { ContentType } from "models/ModelType";

export function isPagePlayable(page: PageContextModel | null) {
    return !isPageMyMusic(page?.type) && page?.type !== ContentType.SettingsPage && page?.type !== ContentType.Search;
}

const isPageMyMusic = (type: LocationContentType | undefined) => {
    switch (type) {
        case ContentType.MyMusicAlbums:
        case ContentType.MyMusicArtists:
        case ContentType.MyMusicPlaylists:
        case ContentType.MyMusicTracks:
            return true;
        default:
            return false;
    }
};
