import { getCurrentInputAudioItemFromState } from "../helpers";
import { playerConfig, sessionStorageKeys } from "global/constants";
import { AudioInputType, PlayState } from "models/app/player";

interface LastPosition {
    audioId: string;
    pausePosition: number;
}

let timeoutRef: number | null = null;

export const updateLastPosition = () => {
    if (timeoutRef) {
        clearTimeout(timeoutRef);
        timeoutRef = null;
    }

    const item = getCurrentInputAudioItemFromState();

    const audioId = item?.audioId;
    const playState = item?.playState;

    const playStartMs = item?.input === AudioInputType.PlayQueue && playState === PlayState.Playing ? item?.position.playStartMs : null;
    const pausePosition = playStartMs != null ? (new Date().getTime() - playStartMs) / 1000 : null;

    if (!audioId || !pausePosition) {
        remove();
        return;
    }

    save({ audioId, pausePosition });
    timeoutRef = window.setTimeout(() => updateLastPosition(), playerConfig.lastPositionUpdateIntervalMs);
};

export function getLastPositionFromSessionStorage(): LastPosition | null {
    const position = sessionStorage.getItem(sessionStorageKeys.lastPosition);
    if (position == null) return null;

    const lastPosition = JSON.parse(position);
    return lastPosition;
}

function save(position: LastPosition) {
    const value = JSON.stringify(position);
    sessionStorage.setItem(sessionStorageKeys.lastPosition, value);
}

function remove() {
    sessionStorage.removeItem(sessionStorageKeys.lastPosition);
}
