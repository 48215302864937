import { dispatch, store } from "global";
import { NOTIFICATION_FEED_NOTIFICATIONS_SEEN } from "global/actions";
import { mutateMarkNotificationsAsSeen } from "services/backend";
import { log, LogTag } from "services/logger";
import { getAnalyticsCurrentNotifications } from "services/logger/analytics/properties/event";
import type { AppNotificationFeedModel } from "models/app";
import type { NotificationModel, NotificationPreviewModel } from "models/domain/NotificationModel";

export function isNotificationFeedEnabled(): boolean {
    return store.getState().controlPanel.enableNotificationFeed === true;
}

export function isNotificationFeedTestModeEnabled(): boolean {
    return store.getState().controlPanel.enableNotificationFeedTestMode === true;
}

export enum NotificationPeriod {
    New = "New",
    LastWeek = "LastWeek",
    ThisMonth = "ThisMonth",
    LastMonth = "LastMonth",
    Previous = "Previous"
}

export function getNotificationFeedFromState(log = true) {
    return store.getState().notificationFeed;
}

export function getNotificationsNewestFirst(notifications: NotificationPreviewModel[]) {
    return [...notifications];
}

function getTimestamps(date: Date) {
    const now = date.getTime();

    const today = new Date(new Date(date).setHours(0, 0, 0));
    const dayOfWeek = (today.getDay() + 6) % 7; // mon-sun = 0-6

    const startOfThisWeek = today.getTime() - (dayOfWeek * 24 * 60 * 60 * 1000);
    const startOfLastWeek = startOfThisWeek - (7 * 24 * 60 * 60 * 1000);
    const startOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1).getTime();
    const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1).getTime();

    return {
        now,
        startOfThisWeek,
        startOfLastWeek,
        startOfThisMonth,
        startOfLastMonth
    };
}

export function getNotificationsByPeriod(notifications: NotificationPreviewModel[], period: NotificationPeriod): NotificationPreviewModel[] {
    const { now, startOfThisWeek, startOfLastWeek, startOfThisMonth, startOfLastMonth } = getTimestamps(new Date());

    switch (period) {
        case NotificationPeriod.New: {
            return notifications.filter((notification) => notification.availableBegin >= startOfThisWeek && (!notification.availableEnd || notification.availableEnd >= now));
        }
        case NotificationPeriod.LastWeek: {
            const ns = notifications.filter((notification) => notification.availableBegin < startOfThisWeek && notification.availableBegin >= startOfLastWeek && (!notification.availableEnd || notification.availableEnd >= now));
            return ns;
        }
        case NotificationPeriod.ThisMonth: {
            const ns = notifications.filter((notification) => notification.availableBegin < startOfLastWeek && notification.availableBegin >= startOfThisMonth && (!notification.availableEnd || notification.availableEnd >= now));
            return ns;
        }
        case NotificationPeriod.LastMonth: {
            const ns = notifications.filter((notification) => notification.availableBegin < startOfThisMonth && notification.availableBegin >= startOfLastMonth && notification.availableBegin < startOfLastWeek && (!notification.availableEnd || notification.availableEnd >= now));
            return ns;
        }
        case NotificationPeriod.Previous: {
            const ns = notifications.filter((notification) => notification.availableBegin < startOfLastMonth && (!notification.availableEnd || notification.availableEnd >= now));
            return ns;
        }
    }
}

export function getCurrentNotifications() {
    const notifications = getNotificationFeedFromState().notifications;
    const allNotifications = getNotificationsNewestFirst(notifications);
    const newNotifications = getNotificationsByPeriod(allNotifications, NotificationPeriod.New);
    const thisWeeksNotifications = getNotificationsByPeriod(allNotifications, NotificationPeriod.LastWeek);
    const thisMonthsNotifications = getNotificationsByPeriod(allNotifications, NotificationPeriod.ThisMonth);
    const lastMonthsNotifications = getNotificationsByPeriod(allNotifications, NotificationPeriod.LastMonth);
    const previousNotifications = getNotificationsByPeriod(allNotifications, NotificationPeriod.Previous);
    const currentNotifications = [...newNotifications, ...thisWeeksNotifications, ...thisMonthsNotifications, ...lastMonthsNotifications];

    return {
        allNotifications,
        currentNotifications,
        newNotifications,
        thisWeeksNotifications,
        thisMonthsNotifications,
        lastMonthsNotifications,
        previousNotifications
    };
}

export function getNotificationRank(feed: AppNotificationFeedModel, notification: NotificationModel): number | null {
    const index = feed.notifications.findIndex((n) => n.id === notification.id);
    if (index === -1) return null;

    const count = feed.notifications.length;
    return count - index - 1;
}

export function getNotificationIndex(feed: AppNotificationFeedModel, notification: NotificationModel): number | null {
    const index = feed.notifications.findIndex((n) => n.id === notification.id);
    if (index === -1) return null;

    return index;
}

export function markNotificationFeedAsSeen() {
    const feed = getNotificationFeedFromState();
    if (!feed.notificationState) return;

    const unseen = feed.notifications.filter((n) => !n.seen);
    if (unseen.length === 0) return;

    const current = getAnalyticsCurrentNotifications();

    log.info({ code: "web-220926-1539", msg: `notifications seen: ${unseen.length}`, tags: [LogTag.Notifications] });

    dispatch({ type: NOTIFICATION_FEED_NOTIFICATIONS_SEEN, payload: { current, seen: unseen } });

    if (feed.notificationState) {
        mutateMarkNotificationsAsSeen({ notificationsState: feed.notificationState });
    }
}

export function getNotificationsCounts(notifications: NotificationPreviewModel[]) {
    const seen = notifications.filter((n) => n.seen);
    const converted = notifications.filter((n) => n.converted);

    return {
        total: notifications.length,
        seen: seen.length,
        converted: converted.length
    };
}
