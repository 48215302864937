import { h } from "preact";
import { useSelector } from "react-redux";
import { Modal, modalButtonSubmitMaintenance, openModal } from "..";
import type { RootModel } from "models/app";
import { Button, ButtonDesign } from "components/atoms/controls/button";

export async function showMaintenanceModal() {
    return openModal(<MaintenanceModal />);
}

const MaintenanceModal = () => {
    const AppMaintenanceLinkTitle = useSelector((root: RootModel) => root.notifications.maintenanceBannerData?.AppMaintenanceLinkTitle);
    const AppMaintenanceLinkUrl = useSelector((root: RootModel) => root.notifications.maintenanceBannerData?.AppMaintenanceLinkUrl);
    const AppMaintenancePopupText = useSelector((root: RootModel) => root.notifications.maintenanceBannerData?.AppMaintenancePopupText);
    const AppMaintenancePopupTitle = useSelector((root: RootModel) => root.notifications.maintenanceBannerData?.AppMaintenancePopupTitle);
    const submit = async () => true;
    const showButton = AppMaintenanceLinkUrl != null && AppMaintenanceLinkUrl.trim().length > 0;

    return (
        <Modal buttons={[modalButtonSubmitMaintenance(submit)]} onRequestClose={submit} title={AppMaintenancePopupTitle}>
            <div className="text">
                <p>{AppMaintenancePopupText}</p>
                {showButton && (
                    <p>
                        <Button design={ButtonDesign.TextBrandInline} externalUrl={AppMaintenanceLinkUrl}>
                            {AppMaintenanceLinkTitle}
                        </Button>
                    </p>
                )}
            </div>
        </Modal>
    );
};
