import { useSelector } from "react-redux";
import { store } from "global";
import { getPausePosition } from "services/player/helpers";
import type { RootModel } from "models/app";
import { AudioInputType } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";

export const useCurrentInputAudioItemFromState = () => {
    const audios = useSelector((root: RootModel) => root.audioInput.audios);
    return audios.find((audio) => audio.index === 0) ?? null;
};

export const getCurrentInputAudioItemFromState = () => {
    const audios = store.getState().audioInput.audios;
    return audios.find((audio) => audio.index === 0) ?? null;
};

export const getCurrentAudioIndex = (): number | null => {
    const audios = store.getState().audioInput.audios;
    const index = audios.findIndex((audio) => audio.index === 0);
    if (index === -1) return null;
    return index;
};

export function getCurrentAudio(audios: AudioInputItemModel[]): { currentAudio: AudioInputItemModel | null; currentIndex: number | null } {
    const index = audios.findIndex((audio) => audio.index === 0);
    if (index === -1) return { currentAudio: null, currentIndex: null };

    const audio = audios[index];
    return { currentAudio: audio, currentIndex: index };
}

export const getCurrentInputAudioNearEndFromState = () => {
    const audio = getCurrentInputAudioItemFromState();
    if (!audio) return { audio: null, nearEnd: false };

    const position = audio?.input === AudioInputType.PlayQueue ? getPausePosition(audio.position).pausePosition : null;
    const duration = audio?.input === AudioInputType.PlayQueue ? audio?.duration : null;

    const nearEnd = position != null && duration != null && position + 3 >= duration;

    return { audio, nearEnd };
};
