import { useState, useLayoutEffect } from "preact/hooks";
import { ScrollerNavigatePropeties } from "../..";
import { ScrollerPurpose } from "../../options";
import { ScrollState } from "components/shared/hooks";

export const useScrollerNavigateProperties = (
    purpose: ScrollerPurpose,
    selectedIndex: number,
    maxIndex: number,
    scrollState: ScrollState,
    onNavigatePropertiesChange?: (properties: ScrollerNavigatePropeties) => void
) => {
    const [navigationProperties, setNavigationProperties] = useState<ScrollerNavigatePropeties | null>(null);
    const left = navigationProperties?.left;
    const right = navigationProperties?.right;

    useLayoutEffect(() => {
        if (onNavigatePropertiesChange == undefined) return;

        let newLeft: boolean;
        let newRight: boolean;

        if (purpose === ScrollerPurpose.Scroll) {
            newLeft = scrollState === ScrollState.InBetween || scrollState === ScrollState.End;
            newRight = scrollState === ScrollState.InBetween || scrollState === ScrollState.Start;
        } else {
            newLeft = selectedIndex > 0;
            newRight = selectedIndex < maxIndex;
        }

        if (left === newLeft && right === newRight) return;

        const newNavProps: ScrollerNavigatePropeties = { left: newLeft, right: newRight };
        setNavigationProperties(newNavProps);
        onNavigatePropertiesChange(newNavProps);
    }, [onNavigatePropertiesChange, maxIndex, selectedIndex, left, right, purpose, scrollState]);
};
