import type { ConnectionModel } from "./ConnectionModel";
import { createTrackPlayablePreviewConnectionModelFromSearchTrackResult } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import type { TrackPlayablePreviewModel } from "./TrackPlayableModel";
import { getNextUUID } from "services/utils";
import type { SearchTracksPaginationQuery } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

export type SearchTrackResultsModel = SearchTrackResultsSectionModel;
export type SearchTrackResultsSectionModel = ({ type: DomainModelType.Section } & SearchTrackResults) | SearchTrackResultsPageModel;
export type SearchTrackResultsPageModel = { type: DomainModelType.Page } & SearchTrackResults;

type SearchResultData = SearchTracksPaginationQuery["search"];

interface SearchTrackResults extends ResourceModelInterface {
    contentType: ContentType.SearchTrackResults;
    criterion: string;
    tracks: ConnectionModel<TrackPlayablePreviewModel> | null;
}

function createSearchTrackResults(data: SearchResultData, criterion: string): SearchTrackResults {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        contentType: ContentType.SearchTrackResults,
        criterion,
        tracks: data.tracks ? createTrackPlayablePreviewConnectionModelFromSearchTrackResult(data.tracks) : null
    };
}
export function createSearchTrackResultsSectionModel(data: SearchResultData, criterion: string): SearchTrackResultsSectionModel {
    return {
        ...createSearchTrackResults(data, criterion),
        type: DomainModelType.Section
    };
}

export function createSearchTrackResultsPageModel(data: SearchResultData, criterion: string): SearchTrackResultsPageModel {
    return {
        ...createSearchTrackResults(data, criterion),
        type: DomainModelType.Page
    };
}
