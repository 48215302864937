import type { AnalyticsPageProperties, AnalyticsRootPageProperties, AnalyticsRootSectionProperties } from ".";
import {
    convertPageProperties,
    convertRootPageProperties,
    convertRootSectionProperties,
    getAnalyticsCurrentPageProperties,
    getAnalyticsRootPageProperties,
    getAnalyticsRootSectionProperties
} from ".";
import type { AnalyticsData } from "../../analyticsData";
import { combineAnalyticsData } from "../../analyticsData";
import type { PreviewContextModel } from "models/app/resourceContextModel";

export const getAndConvertAnalyticsNavigationProperties = (preview?: PreviewContextModel): Promise<AnalyticsData> => {
    return combineAnalyticsData(
        convertPageProperties(getAnalyticsCurrentPageProperties()),
        convertRootPageProperties(getAnalyticsRootPageProperties()),
        convertRootSectionProperties(getAnalyticsRootSectionProperties(preview))
    );
};

export const getAnalyticsNavigationProperties = (page: AnalyticsPageProperties, root: AnalyticsRootPageProperties, section: AnalyticsRootSectionProperties): Promise<AnalyticsData> => {
    return combineAnalyticsData(convertPageProperties(page), convertRootPageProperties(root), convertRootSectionProperties(section));
};
