import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { Accordion } from "./Accordion";
import { Setting } from "./Setting";
import { dispatch } from "global";
import { PLAYQUEUE_ENDLESSPLAY_TOGGLE, PLAYQUEUE_PRIORITY_LANE_WARNING_TOGGLE } from "global/actions";
import { useTranslations } from "global/config";
import { EndlessPlaySwitchTestLocator } from "global/constants";
import type { RootModel } from "models/app";
import { Switch } from "components/atoms/controls/switch";
import { IconName } from "components/atoms/icon";

export const PlaybackSettings = () => {
    const translations = useTranslations();
    const isEndlessPlayOn = useSelector((root: RootModel) => root.queue.isEndlessPlayOn);
    const priorityLaneWarning = useSelector((root: RootModel) => root.queue.priorityLaneWarning);

    const handleToggleEndlessPlay = useCallback(() => dispatch({ type: PLAYQUEUE_ENDLESSPLAY_TOGGLE, payload: { on: !isEndlessPlayOn } }), [isEndlessPlayOn]);
    const handleTogglePriorityLaneWarning = useCallback(
        () => dispatch({ type: PLAYQUEUE_PRIORITY_LANE_WARNING_TOGGLE, payload: { on: !priorityLaneWarning } }),
        [priorityLaneWarning]
    );
    const testLocator = useMemo(() => EndlessPlaySwitchTestLocator(isEndlessPlayOn), [isEndlessPlayOn]);

    const button = <Switch checked={isEndlessPlayOn} onClick={handleToggleEndlessPlay} testLocator={testLocator} />;
    const priorityLaneWarningButton = <Switch checked={priorityLaneWarning} onClick={handleTogglePriorityLaneWarning} testLocator={testLocator} />;

    return (
        <Accordion icon={IconName.Playback} title={translations.SettingsPlayer}>
            <Setting title={translations.SettingsEndlessPlayTitle} subtitle={translations.SettingsEndlessPlaySubTitle} button={button} />
            <Setting title={translations.PriorityQueueWarningSettingsTitle} subtitle={translations.PriorityQueueWarningSettingsSubTitle} button={priorityLaneWarningButton} />
        </Accordion>
    );
};
