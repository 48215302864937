import { createLinkContexts } from ".";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { isLiveRadioParent, isTrackParent } from "services/playable";
import type { LinkContextModel, PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourceContentType, ResourceModel, ResourcePageModel, ResourcePreviewModel, TrackPreviewModel, LocationContentType, LiveRadioPreviewModel } from "models/domain";
import {
    createLiveRadioPreviewModelFromMixedContent,
    getComparableTrackPlayableId,
    ResourceDisplayType,
    getComparableTrackId,
    createTrackPlayablePreview,
    createMixRadioPreviewModelFromMixedContent
} from "models/domain";
import { createLiveRadioPlayablePreview } from "models/domain/LiveRadioPlayableModel";
import type { MixedContentDomainType } from "models/domain/MixedContentModel";
import type { NotificationContentModel, NotificationPreviewModel } from "models/domain/NotificationModel";
import { ContentType, DomainModelType } from "models/ModelType";
import { IconName } from "components/atoms/icon";
import { PreviewAction } from "components/molecules/preview";

export function getTitles(resourceType: LocationContentType, resource: ResourcePreviewModel | ResourcePageModel | null, context?: PreviewContextModel) {
    const links = createLinkContexts(resourceType, resource, context);
    const groupText = (link: LinkContextModel[] | null) => link?.map((group) => group.text).join("·") ?? undefined;

    return { title: groupText(links[0]) ?? "", subtitle: groupText(links[1]) };
}

export function getAltText(resource: ResourceModel): string | null {
    return ""; // todo
}

export function getToolTip(resource: ResourceModel): string | null {
    return ""; // todo
}

export function getResourceCoverTemplate(preview: PreviewContextModel): string | null {
    const resource = preview.resource;
    const sectionType = preview.section.type;
    if (resource?.contentType === ContentType.LiveRadio && resource.type === DomainModelType.Play && sectionType === ContentType.Queue) {
        return resource.tracks?.nowPlaying?.cover ?? resource.cover;
    }
    return resource?.cover ?? null;
}

export function getIcon(type: LocationContentType): IconName | null {
    switch (type) {
        case ContentType.Album:
        case ContentType.ArtistAppearsOn:
        case ContentType.MyMusicAlbums:
            return IconName.Albums;

        case ContentType.Artist:
        case ContentType.MyMusicArtists:
            return IconName.Artist;

        case ContentType.ArtistRadio:
        case ContentType.LiveRadioPlayable:
        case ContentType.Radio:
        case ContentType.TrackRadio:
            return IconName.Radio;

        case ContentType.MyMusicTracks:
        case ContentType.SearchResultTrack:
        case ContentType.TrackPlayable:
            return IconName.Songs;

        case ContentType.MyMusicPlaylists:
        case ContentType.Playlist:
            return IconName.Playlist;

        case ContentType.FrontPage:
            return IconName.Home;

        case ContentType.Search:
            return IconName.Search;

        default:
            log.error({ code: "web-210519-1148", msg: DefaultLogMessage.NotImplemented, data: { type } });
            return null;
    }
}

export function getResourceDisplayCount(displayType: ResourceDisplayType): number {
    switch (displayType) {
        case ResourceDisplayType.Grid:
            return 4;
        case ResourceDisplayType.List:
        case ResourceDisplayType.ListMedium:
        case ResourceDisplayType.ListLarge:
            return 5;
        case ResourceDisplayType.Scroller:
        case ResourceDisplayType.LargeScroller:
            return 20;
        case ResourceDisplayType.Single:
        case ResourceDisplayType.LargeSingle:
        case ResourceDisplayType.PromoSingle:
        case ResourceDisplayType.Top:
            return 1;
        case ResourceDisplayType.Deeplink:
        case ResourceDisplayType.Unknown:
            log.error({ code: "web-210301-1449", msg: DefaultLogMessage.NotImplemented, data: { displayType } });
            return 0;
    }
}

export function getHasContextMenu(type: LocationContentType, resource: ResourceModel | null): boolean {
    const hasMenu = (type: LocationContentType | MixedContentDomainType | undefined): boolean => {
        switch (type) {
            case ContentType.Album:
            case ContentType.Artist:
            case ContentType.LiveRadio:
            case ContentType.LiveRadioPlayable:
            case ContentType.LiveRadioTrack:
            case ContentType.MixRadio:
            case ContentType.Playlist:
            case ContentType.TrackChart:
            case ContentType.TrackPlayable:
            case ContentType.TrackRecommendation:
                return true;
            default:
                return false;
        }
    };

    if (hasMenu(type)) return true;
    if (resource?.contentType === ContentType.MixedContent && hasMenu(resource.mixedContentType)) return true;
    if (resource?.contentType === ContentType.Notification && hasMenu(resource?.playable?.contentType)) return true;

    return false;
}

export type ExpandResourceModel = NotificationPreviewModel & { content: NotificationContentModel };
export function getIsExpandResource(resource: ResourceModel): resource is ExpandResourceModel {
    if (resource.contentType === ContentType.Notification && resource.content) return true;
    return false;
}

export function getIsCoverRounded(type: LocationContentType, resource: ResourceModel | null): boolean {
    const isRounded = (type: ContentType) => {
        if (type === ContentType.Artist || type === ContentType.ArtistRadio) return true;
        return false;
    };

    if (isRounded(type)) return true;
    if (resource?.contentType === ContentType.MixedContent && isRounded(resource.mixedContentType)) return true;
    return false;
}

export function getComparableId(resource: ResourceModel | TrackPreviewModel): string {
    switch (resource.contentType) {
        case ContentType.Track:
            return getComparableTrackId(resource);
        case ContentType.TrackPlayable:
            return getComparableTrackPlayableId(resource.track, resource.parent);
    }
    return `${resource.contentType}_${resource.id}`;
}

function normalizePlayable(playable: TrackPreviewModel | LiveRadioPreviewModel | ResourcePreviewModel): ResourcePreviewModel | TrackPreviewModel | null {
    if (playable.contentType === ContentType.MixedContent) {
        if (playable.mixedContentType === ContentType.MixRadio) {
            const mixRadio = createMixRadioPreviewModelFromMixedContent(playable);
            if (mixRadio == null) {
                log.error({ code: "web-211027-1855", msg: DefaultLogMessage.UnexpectedNull });
                return null;
            }
            playable = mixRadio;
        } else if (playable.mixedContentType === ContentType.LiveRadio) {
            const liveRadio = createLiveRadioPreviewModelFromMixedContent(playable);
            if (liveRadio == null) {
                log.error({ code: "web-211027-1855", msg: DefaultLogMessage.UnexpectedNull });
                return null;
            }
            playable = liveRadio;
        }
    }
    return playable;
}

export function getNormalizedResourceFromUnion(model: TrackPreviewModel | LiveRadioPreviewModel | ResourcePreviewModel, parent: ResourceModel): ResourcePreviewModel | null {
    const normalized = normalizePlayable(model);
    if (!normalized) return null;

    const resource = getResourceFromUnion(normalized, parent);
    if (!resource) return null;

    return resource;
}

export function getResourceFromUnion(model: TrackPreviewModel | LiveRadioPreviewModel | ResourcePreviewModel, parent: ResourceModel): ResourcePreviewModel | null {
    if (model.contentType === ContentType.Track) {
        if (!isTrackParent(parent)) {
            log.error({ code: "web-210313-1506", msg: DefaultLogMessage.UnexpectedValue });
            return null;
        }
        return createTrackPlayablePreview(model, parent);
    } else if (model.contentType === ContentType.LiveRadio) {
        if (!isLiveRadioParent(parent)) {
            log.error({ code: "web-210929-2228", msg: DefaultLogMessage.UnexpectedValue });
            return null;
        }
        return createLiveRadioPlayablePreview(model, parent);
    }
    return model;
}

export function getResourcesFromUnions(
    playableOrTracks: (TrackPreviewModel | LiveRadioPreviewModel | ResourcePreviewModel)[] | null,
    parent: ResourceModel | null
): ResourcePreviewModel[] | null {
    if (!playableOrTracks || !parent) return null;

    const playables: ResourcePreviewModel[] = [];
    playableOrTracks.forEach((value) => {
        const playable = getNormalizedResourceFromUnion(value, parent);
        if (playable) playables.push(playable);
    });
    return playables;
}

export function isResourceEqual(a: ResourceModel | TrackPreviewModel, b: ResourceModel | TrackPreviewModel): boolean {
    if (getComparableId(a) !== getComparableId(b)) return false;
    return true;
}

export function getResourceMainAction(contentType: ResourceContentType, resource: ResourcePreviewModel | null): PreviewAction {
    const isExpand = resource && getIsExpandResource(resource);
    if (isExpand) return PreviewAction.Expand;

    const isPlay =
        contentType === ContentType.LiveRadio ||
        contentType === ContentType.LiveRadioPlayable ||
        contentType === ContentType.LiveRadioTrack ||
        contentType === ContentType.MixRadio ||
        contentType === ContentType.TrackPlayable;

    if (!isPlay) return PreviewAction.Navigate;

    const avaialable = getResourceAvailableInSubscription(resource);
    if (!avaialable) return PreviewAction.None;

    return PreviewAction.Play;
}

export const getCoverSrc = (template: string | null, size: number): string | null => {
    if (template == null) return null;
    const { success, src } = getCoverSrcFromTemplate(template, size, size);
    if (!success) return null;
    return src;
};
export function getCoverSrcFromTemplate(template: string, width: number, height: number): { success: boolean; src: string } {
    const src = template.replace("{scheme}", "https").replace("{width}", `${width}`).replace("{height}", `${height}`);
    return { success: src !== template, src };
}

export function getResourceAvailableInSubscription(resource: ResourcePreviewModel | null): boolean {
    switch (resource?.contentType) {
        case ContentType.Album:
            return resource.availableInSubscription;
        case ContentType.TrackPlayable:
            return resource.track.availableInSubscription;
    }
    return true;
}

export function getResourceEditable(resource: ResourcePageModel | null): boolean {
    switch (resource?.contentType) {
        case ContentType.Playlist:
            return resource.owner;
        case ContentType.MyMusicTracks:
            return true;
        case ContentType.MyMusicAlbums:
            return true;
    }
    return false;
}
