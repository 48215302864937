import { h } from "preact";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { ResourceMenuTop } from "./tops";
import { ContextMenuMode, ContextMenuManagerContext, Sheet, Link } from "..";
import { PlaylistLink } from "../links";
import { CreatePlaylistLink } from "../links/CreatePlaylistLink";
import { dispatch } from "global";
import { CONTEXT_MENU_REPOSITION } from "global/actions";
import { useUserPlaylists } from "services/playlist";
import { useDisplayLoggedIn } from "services/user";
import { CombinedPlaylistsSorting } from "generated/graphql-types";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlaylistModel } from "models/domain/PlaylistModel";
import { ModalOpenResult } from "models/view/AppModalModel";
import { ContentLoadSpinner } from "components/atoms/spinner";
import { promptLoginIfRequired } from "components/organisms/login";
import { showCreatePlaylistModal } from "components/organisms/modal/modals/CreatePlaylistModal";

interface Props {
    onAdd: (playlistId: string) => Promise<void>;
    context: PreviewContextModel;
}

export const AddToPlaylistContextMenu = ({ onAdd, context }: Props) => {
    const playlists = useUserPlaylists(CombinedPlaylistsSorting.ModifiedDate);

    useEffect(() => {
        // wait one iteration so context menu is rendered before this
        setTimeout(() => {
            dispatch({ type: CONTEXT_MENU_REPOSITION });
        });
    }, [playlists]);

    const create = useMemo(
        () => async () => {
            if (await promptLoginIfRequired()) {
                const newPlaylistResult = await showCreatePlaylistModal();
                if (newPlaylistResult.result === ModalOpenResult.Submit && !!newPlaylistResult.playlistId) {
                    await onAdd(newPlaylistResult.playlistId);
                }
            }
        },
        [onAdd]
    );

    const add = useMemo(
        () => async (playlist: PlaylistModel) => {
            if (await promptLoginIfRequired()) {
                await onAdd(playlist.id);
            }
        },
        [onAdd]
    );

    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    const loggedIn = useDisplayLoggedIn();

    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <CreatePlaylistLink onClick={create} showIcon={showIcon} />
            <hr />
            {playlists?.map((playlist) => (
                <PlaylistLink key={playlist.id} playlist={playlist} onClick={() => add(playlist)} showIcon={showIcon} />
            ))}
            {loggedIn && !playlists && <ContentLoadSpinner />}
            {!loggedIn && (
                <Link disabled={true} icon={undefined}>
                    Log ind for at se playlister
                </Link>
            )}
        </Sheet>
    );
};
