import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import type { AnalyticsPlayableLoadProperties, AnalyticsPlayableProperties, AnalyticsPlayableStopProperties } from "../properties/event";
import {
    convertResourceParentProperties,
    getAnalyticsNavigationProperties,
    convertPlayableLoadProperties,
    convertPlayableProperties,
    convertPlayableStopProperties
} from "../properties/event";
import type { AnalyticsQueueAddedPropertiesGroup } from "../properties/groups/queueAddedPropertiesGroup";
import { getAnalyticsUserProperties, getAnalyticsUserPlayProperties } from "../properties/user";

export async function getAnalyticsPlayedContentEvent(
    playableData: AnalyticsPlayableProperties,
    queueData: AnalyticsQueueAddedPropertiesGroup,
    loadData: AnalyticsPlayableLoadProperties,
    stopData: AnalyticsPlayableStopProperties
): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertPlayableProperties(playableData),
        convertPlayableLoadProperties(loadData),
        convertPlayableStopProperties(stopData),
        convertResourceParentProperties(queueData.resourceParentProperties),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsNavigationProperties(queueData.pageProperties, queueData.rootPageProperties, queueData.rootSectionProperties),
        getAnalyticsUserProperties(),
        getAnalyticsUserPlayProperties()
    );
    return data;
}
