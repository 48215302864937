import type { Ref } from "preact/hooks";
import { useCallback, useEffect } from "preact/hooks";
import { PAGE_CLICK } from "global/actions";
import { useMessageBus } from "global/hooks";

export function usePageClick(callbackFn: () => void, ref: Ref<HTMLDivElement | null>) {
    const click = useCallback(
        (ev: Event) => {
            if (ref?.current == null) return;
            if (ref.current.contains(ev.target as Node)) return;
            callbackFn();
        },
        [ref, callbackFn]
    );

    useEffect(() => {
        window.addEventListener("click", click, { passive: true });
        return () => window.removeEventListener("click", click);
    }, [click]);

    useMessageBus(PAGE_CLICK, (message) => click(message.payload.ev));
}
