import { getClearedQueue } from "./clearQueue";
import { showPriorityQueueActionModal } from "./PriorityQueueActionModal";
import { updatePlayQueue } from "./updatePlayQueue";
import { addTracksToQueueTracks, getCurrentLane, shufflePlayQueue } from "../helpers";
import { store, dispatch } from "global";
import { PLAYER_SET_PLAY, SET_DESKTOP_QUEUE_OPEN, PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE } from "global/actions";
import { DefaultLogMessage, log } from "services/logger";
import { getAnalyticsQueueIntentPropertiesGroup, createQueueAddedAnalyticsData } from "services/logger/analytics/properties/groups";
import { createNormalizedData } from "services/normalizeData";
import { getTrackAndParentFromPlayable, updateSessionAndGetTracks } from "services/playable";
import { putAttributePlayUserTrace, DesktopQueueOpen } from "models/app";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemModel, PlayQueueModel, QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { removePageSectionsFromPreviewContext } from "models/app/resourceContextModel";
import type { PlayQueuePlayableModel, TrackPlayablePreviewModel } from "models/domain";
import { createTrackPlayablePreview, getComparableTrackPlayableId } from "models/domain";
import { ContentType } from "models/ModelType";
import { ShuffleState } from "models/view";
import { getAppMediaSize, MediaSize } from "components/shared/hooks";

interface ReplaceQueueAndPlayProps {
    shuffle?: ShuffleState | undefined;
    playable: PlayQueuePlayableModel;
    playableContext: PreviewContextModel;
    context: AudioContextModel;
}

export async function replaceQueueAndPlayTrack({ shuffle, playableContext, playable, context }: ReplaceQueueAndPlayProps) {
    const { player, queue, audioInput } = store.getState();

    const show = showPriorityQueuePopup(queue);
    if (show) showPriorityQueueActionModal(context, queue.playQueue);

    if (context.trace) putAttributePlayUserTrace(context.trace, { type: "clickOnType", value: playable.contentType });

    const shuffleOn = (shuffle != null ? shuffle : player.shuffle) !== ShuffleState.Off;
    const playerPlay = (context: AudioContextModel) => dispatch({ type: PLAYER_SET_PLAY, payload: { play: true, context } });
    await _replaceQueueAndLoadTrack(queue, audioInput.audios, playableContext, playable, shuffleOn, context, playerPlay);

    if (getAppMediaSize() === MediaSize.Mobile) return;

    const firstPlayQueueSession = store.getState().queue.firstQueueSession;
    if (firstPlayQueueSession) dispatch({ type: SET_DESKTOP_QUEUE_OPEN, payload: { open: DesktopQueueOpen.Open, autoOpen: true } });
}

const showPriorityQueuePopup = (queue: PlayQueueModel) => {
    const emptyPriorityLane = isPriorityLaneEmpty(queue);
    const isPriorityQueueWarningOn = store.getState().queue.priorityLaneWarning;
    return !emptyPriorityLane && isPriorityQueueWarningOn;
};

const isPriorityLaneEmpty = (queue: PlayQueueModel) => {
    const anyPriorityLaneItem = queue.playQueue.find((item) => item.lane === QueueLane.Priority);
    if (anyPriorityLaneItem) return false;
    return true;
};

async function _replaceQueueAndLoadTrack(
    queue: PlayQueueModel,
    audios: AudioInputItemModel[],
    previewContext: PreviewContextModel,
    playable: PlayQueuePlayableModel,
    shuffleOn: boolean,
    context: AudioContextModel,
    readyToPlay: (context: AudioContextModel) => void
) {
    queue = getClearedQueue(queue, true);

    const firePlay = (playQueue: QueueTrackModel[]) => {
        const track = getCurrentLane(playQueue)?.track;
        const { trace } = context;
        if (track && trace) putAttributePlayUserTrace(trace, { type: "title_trackId", value: `${track.title} | ${track.id}` });
        else {
            log.info({
                code: "web-210611-1457",
                msg: DefaultLogMessage.UnexpectedNull,
                data: {
                    track,
                    trace
                }
            });
        }
        readyToPlay(context);
    };

    const parent = getTrackAndParentFromPlayable(playable).parent;
    const queueIntentData = getAnalyticsQueueIntentPropertiesGroup(previewContext);

    queue.context = removePageSectionsFromPreviewContext(previewContext);
    queue.trackParent = parent;

    let playQueue = queue.playQueue;
    let play = true;

    const addData = createQueueAddedAnalyticsData(queueIntentData, parent);
    const queueAddedData = createNormalizedData(addData); // normalizeAnalyticsQueueAdded(addData);

    if (playable.contentType === ContentType.TrackPlayable || playable.contentType === ContentType.LiveRadioTrack) {
        const track = playable.contentType === ContentType.LiveRadioTrack ? createTrackPlayablePreview(playable.track, parent) : playable;
        playQueue = addTracksToQueueTracks(playQueue, [track], QueueLane.Current, true, null, true, shuffleOn, queueAddedData.referenceId, "web-210716-1553");

        queue = { ...queue, playQueue, endlessPlay: [] };
        dispatch({ type: PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE, payload: { trackId: null } });
        queue = await updatePlayQueue(queue, context, true, queueAddedData, [playable]);
        playQueue = queue.playQueue;
        play = false;
        firePlay(playQueue);
    } else {
        queue = { ...queue, playQueue };
        queue = await updatePlayQueue(queue, context, false, queueAddedData, [playable]);
        playQueue = queue.playQueue;
    }

    playQueue = await replaceQueue_addPlayableAndTracks(playQueue, playable, shuffleOn, previewContext, queueAddedData.referenceId);

    queue = { ...queue, playQueue };
    queue = await updatePlayQueue(queue, context, false, queueAddedData, [playable]);

    if (play) firePlay(queue.playQueue);
}

export async function replaceQueue_addPlayableAndTracks(
    playQueue: QueueTrackModel[],
    playable: PlayQueuePlayableModel,
    shuffleOn: boolean,
    context: PreviewContextModel,
    analyticsQueueAddedReferenceId: string
) {
    const tracks = await updateSessionAndGetTracks(playable, context);

    if (tracks.length === 0) {
        log.error({ code: "web-220217-1120", msg: DefaultLogMessage.UnexpectedValue });
        return playQueue;
    }

    const currentLane = playQueue.find((queueTrack) => queueTrack.lane === QueueLane.Current) ?? null;

    const currentLaneOriginalIndex = getNewCurrentLaneIndex(currentLane, tracks, shuffleOn);

    const memoryLaneTracks: TrackPlayablePreviewModel[] = [];
    const currentLaneTracks: TrackPlayablePreviewModel[] = [];
    const flowLaneTracks: TrackPlayablePreviewModel[] = [];

    tracks.forEach((track, index) => {
        if (index < currentLaneOriginalIndex) {
            memoryLaneTracks.push(track);
        } else if (index === currentLaneOriginalIndex) {
            currentLaneTracks.push(track);
        } else {
            flowLaneTracks.push(track);
        }
    });

    if (memoryLaneTracks.length > 0) {
        playQueue = addTracksToQueueTracks(playQueue, memoryLaneTracks, QueueLane.Memory, true, null, false, false, analyticsQueueAddedReferenceId, "web-210716-1559");
    }

    if (currentLane == null) {
        if (currentLaneTracks.length > 0) {
            playQueue = addTracksToQueueTracks(playQueue, currentLaneTracks, QueueLane.Current, true, null, false, false, analyticsQueueAddedReferenceId, "web-210716-1559");
        } else {
            log.error({ code: "web-220705-1120", msg: DefaultLogMessage.UnexpectedNull });
        }
    }

    if (flowLaneTracks.length > 0) {
        playQueue = addTracksToQueueTracks(playQueue, flowLaneTracks, QueueLane.Flow, true, null, false, false, analyticsQueueAddedReferenceId, "web-210716-1600");
    }
    if (shuffleOn) {
        playQueue = shufflePlayQueue(playQueue);
    }

    return playQueue;
}

function getNewCurrentLaneIndex(currentLane: QueueTrackModel | null, tracks: TrackPlayablePreviewModel[], shuffleOn: boolean): number {
    if (currentLane == null) {
        const getRandomIndex = (max: number) => Math.floor(Math.random() * max);
        return shuffleOn ? getRandomIndex(tracks.length) : 0;
    }

    const compareableTrackId = currentLane.compareableTrackId;
    const currentLaneOriginalIndex = tracks.findIndex((track) => getComparableTrackPlayableId(track.track, track.parent) === compareableTrackId);

    if (currentLaneOriginalIndex === -1) {
        log.error({ code: "web-210313-1401", msg: "can't find match", data: { tracksList: tracks, currentLane } });
        return -1;
    }
    return currentLaneOriginalIndex;
}
