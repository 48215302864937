import { h } from "preact";
import "./RadioButton.scss";

interface Props {
    className?: string;
    checked?: boolean;
    onChange?: () => void;
    name: string;
}

export const RadioButton = ({ className, checked, onChange, name }: Props) => {
    return <input checked={checked} className={`radioButton Y0w ${className ?? ""}`} type="radio" name={name} onChange={onChange} />;
};
