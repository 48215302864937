import { h } from "preact";
import { useCallback } from "preact/hooks";
import { IconMenuTop } from "./tops";
import { Sheet } from "..";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import type { SortOptionsModel } from "models/app/SortOption";
import { IconName } from "components/atoms/icon";
import { ControlStyle } from "components/molecules/controlGroup/Control";
import { SortControl } from "components/molecules/sortControl/SortControl";

interface Props {
    sortOptions: SortOptionsModel;
}

export const SortContextMenu = ({ sortOptions }: Props) => {
    const translations = useTranslations();
    const onChange = useCallback(() => {
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    }, []);

    return (
        <Sheet top={<IconMenuTop icon={IconName.Sort} title={translations.SortTitle} />}>
            <SortControl onChange={onChange} sortOptions={sortOptions} style={ControlStyle.ContextMenu} />
        </Sheet>
    );
};
