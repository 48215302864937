import { h } from "preact";
import { useRef } from "preact/hooks";
import type { ButtonInline } from "../Button";
import { Button, ButtonDesign } from "../Button";
import type { TestLocator } from "global/constants";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { getHasContextMenu } from "services/resource";
import { getIsLoggedInFromState } from "services/user";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import { useContextMenu } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";
import { ResourceContextMenu } from "components/organisms/contextMenu/menus";

interface Props {
    closeContextMenuOnNavigation?: boolean;
    context: PreviewContextModel;
    design?: ButtonDesign;
    disabled?: boolean;
    icon?: IconName;
    inline?: ButtonInline;
    onOpen?: (open: boolean) => void;
    resource: ResourcePreviewModel | null;
    testLocator?: TestLocator;
}

export const ButtonMore = ({
    closeContextMenuOnNavigation,
    context,
    design = ButtonDesign.LightBig,
    disabled: disabledProp,
    icon = IconName.Meatballs,
    inline,
    onOpen,
    resource,
    testLocator
}: Props) => {
    const ref = useRef<HTMLButtonElement>(null);
    const disabled = !getHasContextMenu(context.type, context.resource) || disabledProp == true;
    const menu = useContextMenu(!disabled ? ResourceContextMenu({ resource, context, resourceType: context.type }) : null, closeContextMenuOnNavigation, undefined, context);

    const click = async () => {
        if (!ref.current) {
            log.error({ code: "web-210517-1409", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        await getIsLoggedInFromState(); // dont open context menus until we know if we are logged in
        if (onOpen) onOpen(true);
        await menu.open(ref.current);
        if (onOpen) onOpen(false);
    };

    return <Button disabled={disabled} onClick={click} ref={ref} icon={icon} design={design} inline={inline} activated={menu.isOpen} testLocator={testLocator} />;
};
