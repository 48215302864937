import { h } from "preact";
import { log, DefaultLogMessage } from "services/logger";
import { ContentType } from "models/ModelType";
import { useCurrentAudioSecondaryResourceFromState, useLinkContextsFromPreview, usePreviewContext, useSectionContext } from "components/shared/hooks";
import { ItemGroup } from "components/molecules/itemGroup/ItemGroup";
import { PreviewDisplayType } from "components/molecules/preview";
import { ResourceLink, ResourceTextDesign } from "components/molecules/resource";

export const LiveRadioQueueTopBar = () => {
    const sectionContext = useSectionContext(ContentType.Queue, null, null, null, null);
    const { resource, resourceType } = useCurrentAudioSecondaryResourceFromState();

    const context = usePreviewContext(resourceType, resource, sectionContext, null);
    const linkModels = useLinkContextsFromPreview(context, PreviewDisplayType.Headline);

    if (linkModels.length === 0) log.error({ code: "web-220223-1412", msg: DefaultLogMessage.UnexpectedValue, data: { resourceType } });

    const links = linkModels.map((group, groupIndex) => <ResourceLink links={group} key={groupIndex} design={ResourceTextDesign.PrimaryBig} testLocatorName={groupIndex} />);

    return (
        <div className="queueActions">
            <ItemGroup title={links[0]} scrollingText={true} />
        </div>
    );
};
