import type { RepeatStatus } from "models/view/RepeatStatus";
import type { ShuffleState } from "models/view/ShuffleState";

export enum AudioControllerType {
    Local,
    Chromecast
}

export enum AudioInputType {
    PlayQueue,
    LiveRadio
}

export enum PlayerOutputType {
    Browser,
    Chromecast
}

export interface PlayerModel {
    controller: AudioControllerType;
    input: AudioInputType;
    output: PlayerOutputType;
    repeat: RepeatStatus;
    volume: number;
    restoreVolume: number | null;
    shuffle: ShuffleState;
    muted: boolean;
    version: 1 | null;
}
