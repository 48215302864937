import { h } from "preact";
import { useRef } from "preact/hooks";
import { Button, ButtonDesign } from "../Button";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import type { SortOptionsModel } from "models/app/SortOption";
import { useContextMenu } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";
import { SortContextMenu } from "components/organisms/contextMenu/menus";

interface Props {
    sortOptions: SortOptionsModel;
    disabled?: boolean;
}

export const ButtonSort = ({ sortOptions, disabled }: Props) => {
    const ref = useRef<HTMLButtonElement>(null);
    const menu = useContextMenu(<SortContextMenu sortOptions={sortOptions} />);

    const click = () => {
        if (!ref.current) {
            log.error({ code: "web-210210-1800", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        menu.open(ref.current);
    };

    return <Button className="sort" ref={ref} icon={IconName.Sort} design={ButtonDesign.DefaultBig} onClick={click} disabled={disabled} />;
};
