import { h } from "preact";
import { LayoutPage } from "..";
import { environment } from "services/environment";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";

export const RadioPage = () => {
    const pageId = environment.apiRadioPageId;
    return <LayoutPage key={pageId} pageId={pageId} type={ContentType.Radio} root={NavigationPageRootName.Radio} />;
};
