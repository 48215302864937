import { Fragment, h } from "preact";
import { useCallback, useRef, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { dispatch } from "global";
import { FAVORITES_UPDATED, PLAYLIST_COLLECTION_ADDED, PLAYLIST_CREATED, PLAYLIST_DELETED, PLAYLIST_MODIFIED, PLAYLIST_TRACK_ADDED, PLAYLIST_TRACKS_REMOVED, SET_MY_PLAYLISTS_SELECTION, USER_LOGGED_IN } from "global/actions";
import { messageBus, translate, useTranslations } from "global/config";
import { getPaginationInit } from "global/constants/pagination";
import { useMessageBus } from "global/hooks";
import { getCombinedPlaylistsPagination, getPlaylistPreview, useFavoritePlaylistsPage } from "services/backend";
import { log, LogTag } from "services/logger";
import { useDisplayLoggedIn } from "services/user";
import { CombinedPlaylistsSorting } from "generated/graphql-types";
import type { NuuApps } from "generated/localization";
import type { RootModel } from "models/app";
import type { PlaylistPreviewModel, PlayQueuePlayablePreviewModel, ResourcePreviewModel, TrackPreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useCoalesced } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { ActionRow } from "components/molecules/actionRow/actionrow";
import { MyPlaylistsSortingContextMenu } from "components/organisms/contextMenu/menus";
import { showCreatePlaylistModal } from "components/organisms/modal/modals/CreatePlaylistModal";
import { MyMusicPageTemplate } from "components/templates/myMusicPage";

const playableViewModels: PlayQueuePlayablePreviewModel[] = [];

export const MyMusicPlaylistsPage = () => {
    const translations = useTranslations();

    const appSize = useAppMediaSize();
    const displayType = appSize === MediaSize.Mobile ? ResourceDisplayType.ListLarge : ResourceDisplayType.Grid;

    const orderBy = useSelector((root: RootModel) => root.ui.playlistsSorting) || CombinedPlaylistsSorting.ModifiedDate;
    const [isOwned, setIsOwned] = useState<boolean | undefined>(undefined);
    const [criterion, setCriterion] = useState<string | null>(null);

    const skip = !useDisplayLoggedIn();
    const query = useFavoritePlaylistsPage({ first: getPaginationInit(), orderBy, criterion, isOwned }, skip);
    const product = useCoalesced(query.model);
    const fetchFn = useCallback(
        (first: number, after: string | null) => (product ? getCombinedPlaylistsPagination({ first, after, orderBy, criterion }) : null),
        [criterion, product, orderBy]
    );

    messageBus.subscribeEvery(USER_LOGGED_IN, (msg) => {
        if (!msg.payload.isNewUser) return;
        query.refetch(false);
    });

    const modifyRef = useRef<(fkt: (items: (ResourcePreviewModel | TrackPreviewModel)[]) => (ResourcePreviewModel | TrackPreviewModel)[]) => void>(null);

    async function updateMetadata(playlistId: string, playlistFromChange: PlaylistPreviewModel | null) {
        try {
            if (modifyRef?.current) {
                // todo: find out if we must wait a second before the backend has recalculated the duration?
                const playlist = playlistFromChange ?? (await getPlaylistPreview({ id: playlistId }))?.model;
                if (playlist) {
                    modifyRef.current((items) => {
                        const index = items.findIndex((n) => n.id === playlist.id);
                        if (index === -1) return items;
                        items = [...items];
                        items[index] = {
                            ...items[index],
                            trackCount: playlist.trackCount,
                            duration: playlist.duration
                        } as PlaylistPreviewModel;
                        return items;
                    });
                }
            } else {
                query.refetch(false);
            }
        } catch (e) {
            log.error({ code: "web-211123-1505", msg: "Error fetching and updating tracklist metadata" });
        }
    }

    const onToggleMyPlaylists = useCallback(() => {
        const val = isOwned ? undefined : true;
        setIsOwned(val);
        dispatch({
            type: SET_MY_PLAYLISTS_SELECTION,
            payload: {
                isOwned: val
            }
        });
    }, [isOwned]);

    const onToggleOtherPlaylists = useCallback(() => {
        const val = isOwned === false ? undefined : false;
        setIsOwned(val);
        dispatch({
            type: SET_MY_PLAYLISTS_SELECTION,
            payload: {
                isOwned: val
            }
        });
    }, [isOwned]);

    useMessageBus(PLAYLIST_COLLECTION_ADDED, (msg) => updateMetadata(msg.payload.playlistId, msg.payload.playlist));
    useMessageBus(PLAYLIST_TRACK_ADDED, (msg) => updateMetadata(msg.payload.playlistId, msg.payload.playlist));
    useMessageBus(PLAYLIST_TRACKS_REMOVED, (msg) => updateMetadata(msg.payload.playlistId, msg.payload.playlist));

    useMessageBus(FAVORITES_UPDATED, (msg) => {
        if (msg?.payload?.changes.playables.find((n) => n.contentType === ContentType.Playlist)) {
            // todo: consider editing-inplace as an optimization if this was an item being removed
            query.refetch(false);
        }
    });

    // todo: subscribe to FAVORITES_UPDATE
    useMessageBus(PLAYLIST_CREATED, () => {
        // todo: this can be improved later by loading the individual playlist and inserting at the correct sorted position
        query.refetch(false);
    });
    useMessageBus(PLAYLIST_DELETED, (msg) => {
        try {
            if (modifyRef?.current) {
                modifyRef.current((items) => {
                    const index = items.findIndex((n) => n.id === msg.payload.playlistId);
                    if (index === -1) return items;
                    return items.filter((n) => n.id !== msg.payload.playlistId);
                });
            } else {
                query.refetch(false);
            }
        } catch (e) {
            log.error({ code: "web-220926-1501", msg: "ERROR UPDATING PLAYLISTS", tags: [LogTag.Playlist], error: e });
        }
    });
    useMessageBus(PLAYLIST_MODIFIED, (msg) => {
        try {
            if (msg.payload.playlist && modifyRef?.current) {
                modifyRef.current((items) => {
                    const index = items.findIndex((n) => n.id === msg.payload.playlistId);
                    if (index === -1) return items;
                    const newItems = [...items] as PlaylistPreviewModel[];
                    newItems[index] = {
                        ...newItems[index],
                        title: msg.payload.playlist?.title ?? newItems[index].title,
                        visibility: msg.payload.playlist?.visibility ?? newItems[index].visibility
                    };
                    return newItems;
                });
            } else {
                query.refetch(false);
            }
        } catch (e) {
            log.error({ code: "web-220926-1501", msg: "ERROR UPDATING PLAYLISTS", tags: [LogTag.Playlist], error: e });
        }
    });

    if (product?.playlists) {
        //  product.playlists.items = [];
    }

    // todo: if content was added to a playlist, and sorting is "last modified", then move it to the top of the list

    const placeholders = getPlaceholders(translations, isOwned);

    return playableViewModels ? (
        <MyMusicPageTemplate
            onFilterChanged={setCriterion}
            placeholderTitle={placeholders.placeholderTitle}
            placeholderSubtitle={placeholders.placeholderSubtitle}
            placeholderButton={
                <Button icon={IconName.Plus16} design={ButtonDesign.SecondarySmall} onClick={showCreatePlaylistModal}>
                    {translations.CreatePlaylist}
                </Button>
            }
            placeholderFilter={placeholders.placeholderFilter}
            placeholderFilterNoResult={placeholders.placeholderFilterNoResult}
            placeholderFilterNoResultSubtitle={placeholders.placeholderFilterNoResultSubtitle}
            topItem={
                displayType === ResourceDisplayType.ListLarge && <ActionRow icon={IconName.AddToPlaylist} text={translations.CreatePlaylist} onClick={showCreatePlaylistModal} />
            }
            topItem2={
                <div className={displayType === ResourceDisplayType.ListLarge ? "tabs tabs-small" : "tabs"}>
                    <Button design={ButtonDesign.LightMicro} toggled={isOwned === true} onClick={onToggleMyPlaylists}>{translate("FilterMyPlaylist")}</Button>
                    <Button design={ButtonDesign.LightMicro} toggled={isOwned === false} onClick={onToggleOtherPlaylists}>{translate("FilterFollowedPlaylists")}</Button>
                </div>
            }
            query={query}
            productType={ContentType.MyMusicPlaylists}
            resources={product?.playlists ?? null}
            resourcesFetchFn={fetchFn}
            displayType={displayType}
            sortingMenu={<MyPlaylistsSortingContextMenu target="playlistsSorting" />}
            modifyRef={modifyRef}
            criterion={criterion}
        />
    ) : null;
};

function getPlaceholders(translations: NuuApps, isOwned: boolean | undefined) {
    if (isOwned === true) {
        return {
            placeholderTitle: translations.FilterNoOwnPlaylists,
            placeholderSubtitle: translations.SubtitleFilterNoOwnPlaylists,
            placeholderFilter: translations.FilterPlaylists,
            placeholderFilterNoResult: translations.FilterNoPlaylistsFound,
            placeholderFilterNoResultSubtitle: translations.SearchNoResultHint,
        }
    }
    if (isOwned === false) {
        return {
            placeholderTitle: translations.FilterNoFollowedPlaylists,
            placeholderSubtitle: translations.SubtitleFilterNoFollowedPlaylists,
            placeholderFilter: translations.FilterPlaylists,
            placeholderFilterNoResult: translations.FilterNoPlaylistsFound,
            placeholderFilterNoResultSubtitle: translations.SearchNoResultHint,
        }
    }
    return {
        placeholderTitle: translations.MyMusicPlaylistPlaceholderTitle,
        placeholderSubtitle: translations.MyMusicPlaylistPlaceholderSubtitle,
        placeholderFilter: translations.FilterPlaylists,
        placeholderFilterNoResult: translations.FilterNoPlaylistsFound,
        placeholderFilterNoResultSubtitle: translations.SearchNoResultHint,
    }
}