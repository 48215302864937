import { messageBus } from "global";
import { FAVORITES_UPDATED } from "global/actions";
import { getFavoritesStatistics } from "services/backend";
import { log } from "services/logger";
import { ConcurrentEntryGuard } from "services/utils";

const expirationInSeconds = 10 * 60;
let cache: FavoritesStatistics | null = null;
let isExpired = true;
let timer = 0;
const entryGuard = new ConcurrentEntryGuard<void>();

export type FavoritesStatistics = {
    playlistCount: number;
    artistCount: number;
    albumCount: number;
    trackCount: number;
};

export async function getFavoritesStatisticsFromCache(): Promise<FavoritesStatistics | null> {
    if (isExpired || !cache) {
        await tryUpdateCache();
    }
    return cache;
}

async function tryUpdateCache() {
    await entryGuard.call(async () => {
        clearTimeout(timer);
        const result = await getFavoritesStatistics();
        if (result.success && result.model) {
            cache = {
                playlistCount: result.model.playlists.totalCount ?? 0,
                artistCount: result.model.artists.totalCount ?? 0,
                albumCount: result.model.albums.totalCount ?? 0,
                trackCount: result.model.tracks.totalCount ?? 0
            }
            isExpired = false;
            timer = window.setTimeout(expire, expirationInSeconds * 1000);
        } else {
            log.error({ code: "web-220626-1810", msg: "Couldn't load favorites statistics" });
        }
    });
}

export function initializeCachedFavoritesStatistics() {
    messageBus.subscribeEvery(FAVORITES_UPDATED, expire);
}

function expire() {
    isExpired = true;
    clearTimeout(timer);
}