import { Delay, useDelayValue } from "./useDelayValue";

interface DelayProps {
    delay: Delay;
    reset?: unknown;
}

export const useDelay = ({ delay, reset }: DelayProps) => {
    return useDelayValue({ delay, value: true, initialValue: false, reset });
};
