import { log, LogTag } from "services/logger";
import type { BrowserAudioItemModel } from "models/app/player/output";

export function logBrowserAudios(message: string, browserAudios: BrowserAudioItemModel[]) {
    const browserAudiosLog = getBrowserAudiosLog(browserAudios);

    log.debug([LogTag.Playback], () => ({
        code: "web-221205-1101",
        msg: message,
        data: {
            browserAudios: browserAudiosLog
        }
    }));
}

export function getBrowserAudiosLog(browserAudios: BrowserAudioItemModel[]) {
    return browserAudios.map((browserAudio) => getBrowserAudioLog(browserAudio));
}

export function getBrowserAudioLog(browserAudio: BrowserAudioItemModel) {
    return {
        audioId: browserAudio.audioId,
        audioPlayState: browserAudio.playState,
        logTitle: browserAudio.logTitle,
        playState: browserAudio.intendedPlayState,
        preload: browserAudio.preload,
        url: browserAudio.url
    };
}
