import { Fragment, h } from "preact";
import type { RefObject } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { showErrorTryAgainModal, showInAppFeedbackModal, showInAppFeedbackSubmitModal } from "../modal/modals";
import { InAppFeedbackResponseRequest, isFeedbackResponseEnabled } from "./InAppFeedbackResponseRequest";
import { useTranslations } from "global/config";
import { FeedbackCategory, submitInAppFeedback } from "services/inAppFeedback";
import { log } from "services/logger/initLoggerService";
import { FeedbackTextValidation, formatInputText, InputEmailValidation, validateEmail, validateFeedbackText } from "services/validation";
import { ModalOpenResult } from "models/view/AppModalModel";
import { FormState } from "components/atoms/controls/form";
import type { SelectOption } from "components/atoms/controls/select";
import { Select } from "components/atoms/controls/select";
import { TextArea } from "components/atoms/controls/textArea";
import { TextInputState } from "components/atoms/controls/textInput";
import { InputSection } from "components/molecules/inputSection";

const minMessageLength = 1;
const maxMessageLength = 30_000;
const feedbackDraftExpirationMs = 1_000 * 60 * 15;

interface FeedbackDraft {
    category: FeedbackCategory;
    message: string;
    closed: number;
    response: boolean;
    responseEmail: string;
}

let feedbackDraft: FeedbackDraft | null;

interface Props {
    submit: RefObject<() => Promise<boolean>>;
    dismiss: RefObject<() => Promise<boolean>>;
    onFromStateChange: (state: FormState) => void;
}

export const InAppFeedback = ({ submit, dismiss, onFromStateChange }: Props) => {
    const [formState, setFormState] = useState(FormState.Default);
    const translations = useTranslations();
    const disabled = formState === FormState.Submiting;

    const responseEnabled = isFeedbackResponseEnabled();
    const [response, setResponse] = useState(false);

    const formStateChange = (state: FormState) => {
        setFormState(state);
        onFromStateChange(state);
    };

    const [category, setCategory] = useState(FeedbackCategory.Default);
    const [categoryState, setCategoryState] = useState(TextInputState.Default);
    const [categoryLabel, setCategoryLabel] = useState("");
    const options = useSelectOptions(category);

    const [message, setMessage] = useState("");
    const [messageState, setMessageState] = useState(TextInputState.Default);
    const [messageLabel, setMessageLabel] = useState("");

    const [email, setEmail] = useState("");
    const [emailState, setEmailState] = useState(TextInputState.Default);
    const [emailLabel, setEmailLabel] = useState("");

    const onCategoryFocus = useCallback(() => {
        setCategoryState(TextInputState.Default);
        setCategoryLabel("");
    }, []);

    const onMessageFocusOrBlur = useCallback(() => {
        setMessageState(TextInputState.Default);
        setMessageLabel("");
        setMessage(formatInputText(message));
    }, [message, setMessage]);

    const onEmailFocusOrBlur = useCallback(() => {
        setEmailState(TextInputState.Default);
        setEmailLabel("");
        setEmail(formatInputText(email));
    }, [email]);

    const saveFeedbackDraft = useCallback(() => {
        feedbackDraft = null;
        if (category !== FeedbackCategory.Default || message !== "" || response || email != "")
            feedbackDraft = { category, message, response, responseEmail: email, closed: new Date().getTime() };
    }, [category, message, response, email]);

    submit.current = async () => {
        if (formState === FormState.Submiting) {
            log.error({ code: "web-210730-1907", msg: "already submitting" });
            return false;
        }

        const setCategoryError = (label: string) => {
            setCategoryState(TextInputState.Error);
            setCategoryLabel(label);
        };

        const setMessageError = (label: string) => {
            setMessageState(TextInputState.Error);
            setMessageLabel(label);
        };

        const setEmailError = (label: string) => {
            setEmailState(TextInputState.Error);
            setEmailLabel(label);
        };

        const messageVal = validateFeedbackText(message, minMessageLength, maxMessageLength);
        if (messageVal === FeedbackTextValidation.TooLong) {
            log.error({ code: "web-210729-1552", msg: "text too long, should not happen" });
        }

        const emailVal = response ? validateEmail(email) : null;

        const categoryOK = category !== FeedbackCategory.Default;
        const messageOK = messageVal === FeedbackTextValidation.OK;
        const emailOK = !response || emailVal === InputEmailValidation.OK;

        if (!categoryOK) {
            setCategoryError(translations.FeedbackModalTopicValidationEmptyWeb);
        }
        if (!messageOK) {
            setMessageError(translations.FeedbackModalMessageValidationEmptyWeb);
        }

        if (!emailOK) {
            switch (emailVal) {
                case InputEmailValidation.Empty:
                    setEmailError(translations.FeedbackModalEmailValidationEmptyWeb);
                    break;
                case InputEmailValidation.Invalid:
                    setEmailError(translations.FeedbackModalEmailValidationInvalidWeb);
                    break;
            }
        }

        if (!categoryOK || !messageOK || !emailOK) return false;

        formStateChange(FormState.Submiting);
        const success = await submitInAppFeedback(category, message, response ? email : null);
        formStateChange(FormState.Default);

        if (success) {
            feedbackDraft = null;
            await showInAppFeedbackSubmitModal();
        } else {
            saveFeedbackDraft();
            const retry = await showErrorTryAgainModal();
            if (retry === ModalOpenResult.Submit) await showInAppFeedbackModal();
        }

        return false;
    };

    dismiss.current = async () => {
        saveFeedbackDraft();
        return true;
    };

    useEffect(() => {
        if (feedbackDraft != null) {
            const now = new Date().getTime();
            if (now > feedbackDraft.closed + feedbackDraftExpirationMs) {
                feedbackDraft = null;
                return;
            }

            setCategory(feedbackDraft.category);
            setMessage(feedbackDraft.message);
            setResponse(!responseEnabled ? false : feedbackDraft.response);
            setEmail(!responseEnabled ? "" : feedbackDraft.responseEmail);

            feedbackDraft.closed = now;
        }
    }, [responseEnabled]);

    return (
        <Fragment>
            <InputSection title={translations.FeedbackModalTopicTitleWeb} state={categoryState} label={categoryLabel}>
                <Select disabled={disabled} onChange={setCategory} onFocus={onCategoryFocus} options={options} state={categoryState} />
            </InputSection>
            <InputSection title={translations.FeedbackModalMessageTitleWeb} state={messageState} label={messageLabel}>
                <TextArea
                    disabled={disabled}
                    maxLength={maxMessageLength}
                    onBlur={onMessageFocusOrBlur}
                    onChange={setMessage}
                    onFocus={onMessageFocusOrBlur}
                    placeholder={translations.FeedbackModalMessagePlaceholderWeb}
                    state={messageState}
                    value={message}
                />
            </InputSection>
            <InAppFeedbackResponseRequest
                disabled={disabled}
                label={emailLabel}
                onBlur={onEmailFocusOrBlur}
                onChange={setEmail}
                onFocus={onEmailFocusOrBlur}
                state={emailState}
                value={email}
                open={response}
                onOpenChange={setResponse}
            />
        </Fragment>
    );
};

function useSelectOptions(selected: FeedbackCategory): SelectOption<FeedbackCategory>[] {
    const getSelectOption = (category: FeedbackCategory, text: string): SelectOption<FeedbackCategory> => ({ value: category, id: category, text, seleced: category === selected });
    const translations = useTranslations();
    return [
        getSelectOption(FeedbackCategory.Default, translations.SettingsFeedbackTopicDefaultOptionWeb),
        getSelectOption(FeedbackCategory.Function, translations.SettingsFeedbackTopicFunction),
        getSelectOption(FeedbackCategory.Stability, translations.SettingsFeedbackTopicStability),
        getSelectOption(FeedbackCategory.Content, translations.SettingsFeedbackTopicContent),
        getSelectOption(FeedbackCategory.Other, translations.SettingsFeedbackTopicOther)
    ];
}

interface Props {
    submit: RefObject<() => Promise<boolean>>;
    dismiss: RefObject<() => Promise<boolean>>;
    onFromStateChange: (state: FormState) => void;
}
