import { isNotificationFeedTestModeEnabled } from "./helpers";

export interface NotificationFeedConfiguration {
    generel: {
        playback_minPlayedDuration: number;
        playbacks_max: 10000;
        collections_max: 10000;

        show_max: number;

        update_interval: number;
        update_collectionCount: number;
    };
}

let _configuration: NotificationFeedConfiguration | null;
let _configurationTestMode: NotificationFeedConfiguration | null;

export function getNotificationFeedConfiguration(): NotificationFeedConfiguration {
    const testMode = isNotificationFeedTestModeEnabled();

    if (!testMode) {
        if (!_configuration) _configuration = getConfiguration();
        return _configuration;
    } else {
        if (!_configurationTestMode) _configurationTestMode = getConfigurationTestMode();
        return _configurationTestMode;
    }
}

function getConfiguration(): NotificationFeedConfiguration {
    const min = 1000 * 60;
    const hour = min * 60;

    return {
        generel: {
            show_max: 3,
            playback_minPlayedDuration: 30,
            update_interval: hour,

            collections_max: 10000,
            playbacks_max: 10000,
            update_collectionCount: 20
        }
    };
}

function getConfigurationTestMode(): NotificationFeedConfiguration {
    const tenSec = 1000 * 10;

    return {
        generel: {
            show_max: 3,
            playback_minPlayedDuration: 0,
            update_interval: tenSec,

            collections_max: 10000,
            playbacks_max: 10000,
            update_collectionCount: 20
        }
    };
}
