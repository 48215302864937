import type { RefObject } from "preact";
import { useMemo } from "preact/hooks";
import { useRefUpdate } from ".";
import type { ConnectionFetchFn } from "./useConnectionHandler";
import { useConnectionHandler } from "./useConnectionHandler";
import { pagination } from "global/constants/pagination";
import { isPlayableContentType } from "services/playable";
import type { ConnectionModel, ConnectionState, ResourceModel } from "models/domain";

interface Props<T> {
    resource: ResourceModel | null;
    connection: ConnectionModel<T> | null;
    fetchFn?: ConnectionFetchFn<T>;
    max?: number;
}

export interface Pagination<T> {
    state: ConnectionState;
    connection: ConnectionModel<T> | null;
    scrollRef: RefObject<HTMLElement>;
    scroll: HTMLElement | null;
    max?: number;
    more: ((value?: number) => void) | null;
    modify: ((convert: (item: T[]) => T[]) => void) | null;
}

export function usePagination<T>({ resource, connection: connctionProp, fetchFn, max }: Props<T>): Pagination<T> {
    const paginationOn = pagination.paginationOn;
    const isTrack = resource != null && isPlayableContentType(resource.contentType);
    const eagerLoadingCount = useMemo(() => (paginationOn && isTrack ? pagination.eagerLoadingTrackCount : 0), [paginationOn, isTrack]);

    const { connection, state, more, modify } = useConnectionHandler({ connection: connctionProp, fetchFn, max, eagerLoadingCount });

    const scrollRef = useRefUpdate<HTMLElement>();
    const scroll = scrollRef.current ?? null;

    return useMemo(() => ({ state, connection, scrollRef, scroll, max, more, modify }), [state, connection, scrollRef, scroll, max, more, modify]);
}
