import { h } from "preact";
import { PreviewAction } from "./PreviewProps";
import { dispatch } from "global";
import { PLAYER_SET_PLAY, AUDIO_INPUT_SET_AND_PLAY } from "global/actions";
import { TestLocator } from "global/constants";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { isPlayable } from "services/playable";
import { createAndStartUserPlayTraceModel, TraceName } from "models/app";
import { PlayState } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlayablePreviewModel, ResourcePreviewModel, PlayableModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { getAudioPropertiesFromState, getPlayablePropertiesFromState, usePlayablePropertiesFromState } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";

interface PlayButtonProps {
    playable: PlayablePreviewModel;
    context: PreviewContextModel;
    onAction: (action: PreviewAction) => void;
}

export const SmallPreviewPlayButton = ({ playable, onAction }: PlayButtonProps) => {
    const { isPlayingOrBuffering } = usePlayablePropertiesFromState(playable);
    const playPauseIconPlayQueue = isPlayingOrBuffering ? IconName.Pause16 : IconName.Play16;
    const playPauseIconLiveRadio = isPlayingOrBuffering ? IconName.Stop16 : IconName.Play16;
    const playPauseIcon = playable.contentType === ContentType.LiveRadioPlayable ? playPauseIconLiveRadio : playPauseIconPlayQueue;

    return (
        <Button
            className="play"
            onClick={() => onAction(PreviewAction.Play)}
            design={ButtonDesign.LightBrandSmall}
            icon={playPauseIcon}
            testLocator={TestLocator.PreviewPlayButton}
        />
    );
};

export function playPlayablePreview(resource: ResourcePreviewModel, context: PreviewContextModel): void {
    if (!isPlayable(resource)) {
        log.error({ code: "web-210504-1823", msg: DefaultLogMessage.UnexpectedValue });
        return;
    }

    const startPlay = async (playable: PlayableModel) => {
        const trace = await createAndStartUserPlayTraceModel(TraceName.ClickToSound);
        dispatch({ type: AUDIO_INPUT_SET_AND_PLAY, payload: { playable, playableContext: context, context: { action: AudioContextAction.UserPreviewSetAndPlay, trace } } });
    };

    const togglePlay = () => {
        const { playState } = getAudioPropertiesFromState();
        const play = playState === PlayState.Paused || playState === PlayState.Stopped || playState === PlayState.Error;
        dispatch({ type: PLAYER_SET_PLAY, payload: { play, context: { action: AudioContextAction.UserPreviewTogglePlay, trace: null } } });
    };

    const isLoaded = getPlayablePropertiesFromState(resource).isLoaded;

    if (isLoaded) togglePlay();
    else startPlay(resource);
}
