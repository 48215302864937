import { convertResourceParentProperties, getAnalyticsResourceParentProperties } from ".";
import type { AnalyticsResourceParentProperties } from ".";
import type { AnalyticsPlayableProperties } from "./playableProperties";
import { getAnalyticsPlayableProperties, convertPlayableProperties } from "./playableProperties";
import type { LiveRadioPlayableModel, TrackPlayableModel } from "models/domain";

export function getAndConvertPlayableAndParentProperties(preview: TrackPlayableModel | LiveRadioPlayableModel) {
    return {
        ...convertPlayableProperties(getAnalyticsPlayableProperties(preview)),
        ...convertResourceParentProperties(getAnalyticsResourceParentProperties(preview.parent))
    };
}
export function convertPlayableAndParentProperties(playable: AnalyticsPlayableProperties, parent: AnalyticsResourceParentProperties) {
    return {
        ...convertPlayableProperties(playable),
        ...convertResourceParentProperties(parent)
    };
}
