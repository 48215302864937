import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import "./MaxiPlayerSecondaryControl.scss";
import { dispatch } from "global";
import { PLAYER_REPEAT, PLAYER_SHUFFLE, TOGGLE_MAXIPLAYER_QUEUE_OPEN } from "global/actions";
import { Feature, isFeatureEnabled } from "global/config";
import { TestLocator } from "global/constants";
import { getShuffleIcon, getRepeatIcon } from "services/player/controllers/service/helpers/icons";
import { isShuffleDisabled, isRepeatDisabled, isQueueViewDisabled } from "services/player/inputs/inputs/playQueue/helpers";
import { type RootModel } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { RepeatStatus } from "models/view/RepeatStatus";
import { ShuffleState } from "models/view/ShuffleState";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { toggleLyrics, useLyricsState } from "components/organisms/lyrics";

interface Props {
    showQueue: boolean;
}
export const MaxiPlayerSecondaryControl = ({ showQueue }: Props) => {
    const repeat = useSelector((root: RootModel) => root.player.repeat);
    const shuffle = useSelector((root: RootModel) => root.player.shuffle);

    const { isLyricsEnabled, isLyricsAvailable, isLyricsOpen } = useLyricsState();

    const toggleQueue = useCallback(() => dispatch({ type: TOGGLE_MAXIPLAYER_QUEUE_OPEN }), []);
    const playerShuffle = useCallback(() => dispatch({ type: PLAYER_SHUFFLE, payload: { context: { action: AudioContextAction.UserPlayerShuffle, trace: null } } }), []);
    const playerRepeat = useCallback(() => dispatch({ type: PLAYER_REPEAT, payload: {} }), []);
    const onToggleLyrics = useCallback(() => toggleLyrics("MaxiPlayer"), [])

    return (
        <div className={`MaxiPlayerSecondaryControl U22`}>
            <Button
                design={ButtonDesign.PlayerSecondary}
                disabled={isShuffleDisabled()}
                inline={{ left: true, bottom: true, top: true }}
                toggled={shuffle !== ShuffleState.Off}
                onClick={playerShuffle}
                icon={getShuffleIcon()}
                testLocator={TestLocator.PlayerShuffleButton}
            />
            <Button
                design={ButtonDesign.PlayerSecondary}
                toggled={repeat !== RepeatStatus.None}
                inline={{ bottom: true, top: true }}
                disabled={isRepeatDisabled()}
                onClick={playerRepeat}
                icon={getRepeatIcon(repeat)}
                className={`--repeatStatus-${repeat}`}
                testLocator={TestLocator.PlayerRepeatButton}
            />
            <div />
            {
                isLyricsEnabled && (isLyricsAvailable || isFeatureEnabled(Feature.LyricsIconWhenNotAvailable)) &&
                <Button
                    design={ButtonDesign.PlayerSecondary}
                    inline={{ right: true, bottom: true, top: true }}
                    onClick={onToggleLyrics}
                    disabled={!isLyricsAvailable && !isLyricsOpen}
                    toggled={isLyricsOpen}
                    icon={IconName.Lyrics}
                    testLocator={TestLocator.PlayerLyricsToggleButton}
                />
            }
            <Button
                disabled={isQueueViewDisabled()}
                inline={{ right: true, bottom: true, top: true }}
                toggled={showQueue}
                onClick={toggleQueue}
                icon={IconName.Queue}
                testLocator={TestLocator.PlayerQueueToggleButton}
            />
        </div>
    );
};
