import { Environment } from "./remoteConfigService";

export function getAppMaintenanceEnvKey(env: Environment | null) {
    if (env == null) return null;
    switch (env) {
        case Environment.Local:
            return "WebLocalMaintenanceEnabled";
        case Environment.Stage:
            return "WebStageMaintenanceEnabled";
        case Environment.Test:
            return "WebTestMaintenanceEnabled";
        case Environment.Dev:
            return "WebDevMaintenanceEnabled";
        case Environment.Prod:
            return "WebProdMaintenanceEnabled";
    }
}
