import { onCastStateChanged, onSessionStateChange } from "../castEvents/context";
import { log, LogTag } from "services/logger";

let hasCastContext = false;

export const addCastContextListeners = () => {
    log.debug([LogTag.Chromecast], () => ({ code: "web-230105-1440", msg: "add cast context listeners" }));

    if (hasCastContext) {
        log.error({ code: "web-230105-1441", msg: "unexpected remove of cast context listeners" });
        removeCastContextListeners();
    }
    hasCastContext = true;

    cast.framework.CastContext.getInstance().addEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, onCastStateChanged);
    cast.framework.CastContext.getInstance().addEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, onSessionStateChange);
};

export const removeCastContextListeners = () => {
    if (!hasCastContext) return;
    hasCastContext = false;

    log.debug([LogTag.Chromecast], () => ({ code: "web-230105-1441", msg: "remove cast context listeners" }));

    cast.framework.CastContext.getInstance().removeEventListener(cast.framework.CastContextEventType.CAST_STATE_CHANGED, onCastStateChanged);
    cast.framework.CastContext.getInstance().removeEventListener(cast.framework.CastContextEventType.SESSION_STATE_CHANGED, onSessionStateChange);
};
