import { useState } from "preact/hooks";
import { search } from "global/constants";
import { getLimitedSearchResultMain, getLimitedSearchResultPlaylists } from "services/backend";
import { useCancellingEffect } from "services/cancellation/useCancellingEffect";
import { log } from "services/logger";
import { isEmptyResult } from "services/search";
import type { SearchResultModel } from "models/domain/SearchResultModel";
import { mergeSearchResultModel } from "models/domain/SearchResultModel";
import { SearchState } from "models/view";

export function useSearch(criterion: string) {
    const [state, setState] = useState<SearchState>(SearchState.None);
    const [result, setResult] = useState<SearchResultModel | null>(null);

    criterion = criterion.trim();

    useCancellingEffect(
        async (cancellationToken) => {
            if (!criterion) {
                setResult(null);
                setState(SearchState.None);
                return;
            }

            const results = {
                main: null as SearchResultModel | null,
                playlists: null as SearchResultModel | null
            };

            try {
                const promise1 = getLimitedSearchResultMain({ criterion }).then((result) => {
                    results.main = result.model;
                });
                const promise2 = getLimitedSearchResultPlaylists({ criterion }).then((result) => {
                    results.playlists = result.model;
                });

                // we give the main search a chance to return first, since its on top
                await Promise.any([promise1, delayTask(search.initialDelayBeforeSpinnerMs)]);
                if (cancellationToken.isCancelled) return;

                if (results.main && !isEmptyResult(results.main)) {
                    setResult(results.main);
                    setState(SearchState.Success);
                } else {
                    // but we go to a loading-state if we didnt get a quick result
                    setState(SearchState.Fetching);

                    // and resume the wait for the main search for a longer duration
                    await Promise.any([promise1, delayTask(search.mainSearchPriorityMs)]);
                    if (cancellationToken.isCancelled) return;

                    if (results.main && !isEmptyResult(results.main)) {
                        setResult(results.main);
                        setState(SearchState.Success);
                    }
                }

                // then we wait for either result
                await Promise.any([promise1, promise2]);
                if (cancellationToken.isCancelled) return;
                setResult(mergeSearchResultModel(results.main, results.playlists));
                const hasEitherResults = !!results.main || !!results.playlists;
                setState(hasEitherResults ? SearchState.Success : SearchState.Failed);

                // then we wait for both results
                await Promise.all([promise1, promise2]);
                if (cancellationToken.isCancelled) return;
                setResult(mergeSearchResultModel(results.main, results.playlists));
                const hasBothResults = !!results.main && !!results.playlists;
                setState(hasBothResults ? SearchState.Success : SearchState.Failed);
            } catch (e) {
                log.error({ code: "web-220303-1530", msg: "Error during search", error: e });
                if (cancellationToken.isCancelled) return;
                setResult(null);
                setState(SearchState.Failed);
            }
        },
        [criterion]
    );

    return !criterion ? { result: null, state: SearchState.None } : { result, state };
}

const delayTask = (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms));
