import type { LayoutItemSectionConnectionModel } from "../ConnectionModel";
import { createLayoutItemConnectionModel } from "../ConnectionModel";
import type { ResourceModelInterface } from "../ResourceModel";
import { getNextUUID } from "services/utils";
import type { LayoutPageFragment } from "generated/graphql-types";

export interface LayoutItemGroupModel extends ResourceModelInterface {
    items: LayoutItemSectionConnectionModel | null;
}

export function createLayoutItemGroupModel(data: NonNullable<LayoutPageFragment["groups"]["items"]>[number], pageId: string): LayoutItemGroupModel {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        items: data.items ? createLayoutItemConnectionModel(data.items, pageId) : null
    };
}
