import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { showCreatePlaylistModal, showCustomCreatePlaylistModal } from "../modal/modals/CreatePlaylistModal";
import { useTranslations } from "global/config";
import { addPlayableToPlaylist } from "services/playlist";
import { getLinkFromResource } from "services/resource";
import type { DraggablePlayableModel } from "models/app";
import { ButtonDesign, Button } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { DropZone } from "components/molecules/dropZone";

export const CreatePlaylistButton = ({ minimal }: { minimal: boolean }) => {
    const translations = useTranslations();
    const [isHovering, setIsHovering] = useState(false);

    const onDrop = useCallback(async (model: DraggablePlayableModel) => {
        const title = getLinkFromResource(model.playable)?.text ?? model.title;
        const result = await showCustomCreatePlaylistModal(null, null, title);
        if (result.playlistId) {
            addPlayableToPlaylist(result.playlistId, model.playable);
        }
    }, []);

    return (
        <section className="myMusicPlaylistsAction">
            <Button
                className={isHovering ? "--hover-true" : ""}
                icon={minimal ? IconName.AddToPlaylist : IconName.Plus16}
                design={minimal ? ButtonDesign.SideBarLinkBigMinimal : ButtonDesign.SecondarySmall}
                onClick={showCreatePlaylistModal}>
                {translations.CreatePlaylist}
                <DropZone onDropFn={onDrop} onHoverChange={setIsHovering} customClass="--drop-fill --drop-invisible --drop-round" />
            </Button>
        </section>
    );
};
