import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Button, ButtonDesign } from "../Button";
import { dispatch } from "global";
import { PLAYER_TOGGLE_FAVORITE } from "global/actions";
import type { TestLocator } from "global/constants";
import type { FavoritePageModel, FavoritePreviewModel } from "services/favorites";
import { useFavoriteStatus } from "services/favorites";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { IconName } from "components/atoms/icon";
import { useTranslations } from "global/config";

interface Props {
    context: PreviewContextModel;
    design?: ButtonDesign;
    disabled?: boolean;
    favorite: FavoritePreviewModel | FavoritePageModel | null;
    testLocator?: TestLocator;
}

export const ButtonFavorite = ({ context, favorite, testLocator, disabled, design = ButtonDesign.LightBig }: Props) => {
    const isFavorite = useFavoriteStatus(favorite);
    const translations = useTranslations();
    const icon = isFavorite ? IconName.FavoritesActive : IconName.Favorites;
    const { section } = context;
    const toggle = useCallback(() => {
        dispatch({
            type: PLAYER_TOGGLE_FAVORITE,
            payload: { section, playables: favorite ? [favorite] : null, operation: isFavorite ? "remove" : "add" }
        });
    }, [section, favorite, isFavorite]);

    return (
        <Button
            ariaLabel={isFavorite ? translations.VoiceOverRemoveFromMyMusic : translations.VoiceOverAddToMyMusic}
            className={`buttonFavorite IME`}
            toggled={isFavorite || false}
            icon={icon}
            design={design}
            onClick={toggle}
            testLocator={testLocator}
            disabled={disabled}
        />
    );
};
