import type { ConnectionModel } from "./ConnectionModel";
import { createSearchMixedResultPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import type { SearchMixedResultPreviewModel } from "./SearchMixedResultModel";
import { getNextUUID } from "services/utils";
import type { SearchMixedSectionsFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type SearchResultData = SearchMixedSectionsFragment;

export type SearchMixedResultsModel = SearchMixedResultsSectionModel;
export type SearchMixedResultsSectionModel = { type: DomainModelType.Section } & SearchResultMixedResultsPreview;

interface SearchResultMixedResultsPreview extends ResourceModelInterface {
    contentType: ContentType.SearchMixedResults;
    mixedResults: ConnectionModel<SearchMixedResultPreviewModel> | null;
}

export function createSearchMixedResultsSectionModel(data: SearchResultData): SearchMixedResultsSectionModel {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        contentType: ContentType.SearchMixedResults,
        type: DomainModelType.Section,
        mixedResults: data.mixed ? createSearchMixedResultPreviewConnectionModel(data.mixed) : null
    };
}
