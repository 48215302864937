import { Fragment, h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import "./DropZone.scss";
import { DropPin } from "./DropPin";
import { dispatch, store } from "global";
import { SET_USER_DRAGGING } from "global/actions";
import { showDropZone } from "services/controlPanel/initControlPanelService";
import type { DraggableItemModel, RootModel } from "models/app";

interface Props {
    allowDrop?: (item: DraggableItemModel) => boolean;
    onHoverChange?: (isHovered: boolean) => void;
    onDropFn: (item: DraggableItemModel) => void;
    customClass?: string;
}
export const DropZone = ({ allowDrop, onDropFn, onHoverChange, customClass }: Props) => {
    const [dragHover, setDragHover] = useState(false);
    const userDragging = useSelector((root: RootModel) => root.ui.userDragging);

    const dragOver = useCallback(
        (e: DragEvent) => {
            if (allowDrop) {
                const item = store.getState().ui.userDraggingItem;
                if (!item || !allowDrop(item)) {
                    return false;
                }
            }
            e.preventDefault();
            e.stopPropagation();
            return false;
        },
        [allowDrop]
    );

    const dragEnter = useCallback(() => {
        if (allowDrop) {
            const item = store.getState().ui.userDraggingItem;
            if (!item || !allowDrop(item)) {
                return false;
            }
        }
        setDragHover(true);
        if (onHoverChange) {
            onHoverChange(true);
        }
    }, [allowDrop, onHoverChange]);

    const dragLeave = useCallback(() => {
        setDragHover(false);
        if (onHoverChange) {
            onHoverChange(false);
        }
    }, [onHoverChange]);

    const drop = useCallback(
        (e: DragEvent) => {
            setDragHover(false);
            e.preventDefault();
            e.stopPropagation();
            if (onHoverChange) {
                onHoverChange(false);
            }

            const data = e.dataTransfer?.getData("dragItem");
            if (data == null) return;
            const item: DraggableItemModel = JSON.parse(data);

            if (allowDrop && !allowDrop(item)) {
                return;
            }

            onDropFn(item);
            dispatch({ type: SET_USER_DRAGGING, payload: { item: null } });
        },
        [onHoverChange, onDropFn, allowDrop]
    );

    return (
        <Fragment>
            <DropPin customClass={customClass} hover={userDragging && dragHover} />
            <div
                className={`dropZone Ase --userDrag-${userDragging} --showDropZone-${!!showDropZone} ${customClass ?? ""}`}
                onDragOver={dragOver}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                onDrop={drop}
            />
        </Fragment>
    );
};
