import { h } from "preact";
import { Modal, modalButtonDismissCancel, modalButtonSubmitContinue, openModal } from "..";
import { useTranslations } from "global/config";

export async function showClearQueueModal() {
    return openModal(<ClearQueueModal />);
}

export function ClearQueueModal() {
    const translations = useTranslations();
    return <Modal title={translations.QueueClearPrompTitle} text={translations.QueueClearPrompSubtitle} buttons={[modalButtonDismissCancel(), modalButtonSubmitContinue()]} />;
}
