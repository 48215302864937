import type { DraggablePlayableModel } from "./DraggableItemModel";
import type { SortOption } from "./SortOption";
import type { FavoriteAlbumsSorting, CombinedPlaylistsSorting, FavoriteTracksSorting, FavoriteArtistsSorting, OwnPlaylistsSorting, PlaylistTracksSorting } from "generated/graphql-types";
import type { ThemeSetting } from "models/view";
import type { LanguageSetting, Language, SoundQualitySetting } from "models/view/Localization";

export enum MobileMaxiPlayerOpen {
    Closed,
    Open
}

export enum DesktopQueueOpen {
    Default,
    Open,
    Closed
}

export interface UiModel {
    albumsSorting: FavoriteAlbumsSorting;
    artistsSorting: FavoriteArtistsSorting;
    tracksSorting: FavoriteTracksSorting;
    playlistsSorting: CombinedPlaylistsSorting;
    ownPlaylistsSorting: OwnPlaylistsSorting;
    ownPlaylistTracksSorting: SortOption;
    sidebarPlaylistsSorting: CombinedPlaylistsSorting;
    theme: ThemeSetting;
    localizationSetting: LanguageSetting;
    soundQualitySetting: SoundQualitySetting;
    browserLanguage: Language;
    layout: {
        desktopQueueOpen: DesktopQueueOpen;
        mobileMaxiPlayerOpen: MobileMaxiPlayerOpen;
        lyricsOpen: boolean;
        lyricsId: string | undefined,
        lyricsTrackId: string | undefined;
        lyricsStart: "ContextMenu" | "MaxiPlayer" | "DesktopPlayer" | undefined
    };
    userDragging: boolean;
    userDraggingItem: DraggablePlayableModel | null;
    version: 1 | null;
}
