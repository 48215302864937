import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import { DefaultLogMessage, log } from "services/logger";
import type { MixedContentPreviewFragment } from "generated/graphql-types";
import { MixedContentType } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type MixedContentPreviewData = MixedContentPreviewFragment;

export type MixedContentModel = MixedContentPreviewModel;
export type MixedContentPreviewModel = { type: DomainModelType.Preview; contentType: ContentType.MixedContent } & MixedContentPreview;

export type MixedContentDomainType =
    | ContentType.Album
    | ContentType.Artist
    | ContentType.ArtistRadio
    | ContentType.LiveRadio
    | ContentType.LiveRadioCategory
    | ContentType.MixRadio
    | ContentType.MyMusicTracks
    | ContentType.Playlist
    | ContentType.TrackChart
    | ContentType.TrackPlayable
    | ContentType.TrackRadio
    | ContentType.UserHistory
    | ContentType.UserRecommendations
    | ContentType.YourMixesPage;

interface MixedContentPreview extends PlayQueuePlayableModelInterface {
    mixedContentId: string | null;
    mixedContentType: MixedContentDomainType;
    name: string;
    owner?: boolean;
    subtitle: string | null;
    title: string;
}

export function createMixedContentPreviewModel(data: MixedContentPreviewData): MixedContentPreviewModel | null {
    const contextType = data.type ? getMixedContentDomainType(data.type) : null;
    if (contextType == null) return null;

    const contextId = data.id ?? null;
    const id = `${contextType}_${contextId ?? "null"}`;

    return {
        id,
        graphType: data.__typename,
        type: DomainModelType.Preview,
        contentType: ContentType.MixedContent,
        mixedContentId: contextId,
        name: data.title,
        subtitle: data.subtitle ?? null,
        title: data.title ?? null,
        mixedContentType: contextType,
        cover: data.cover ?? null,
        trackCount: null,
        owner: data.type === MixedContentType.PrivatePlaylist ? true : undefined
    };
}

function getMixedContentDomainType(type: MixedContentType): MixedContentDomainType | null {
    switch (type) {
        case MixedContentType.Album:
            return ContentType.Album;
        case MixedContentType.Artist:
            return ContentType.Artist;
        case MixedContentType.ArtistRadio:
            return ContentType.ArtistRadio;
        case MixedContentType.FavoriteTracks:
            return ContentType.MyMusicTracks;
        case MixedContentType.Genre:
            return null;
        case MixedContentType.PrivatePlaylist:
        case MixedContentType.PublicPlaylist:
            return ContentType.Playlist;
        case MixedContentType.MixRadio:
            return ContentType.MixRadio;
        case MixedContentType.Recommendations:
            return ContentType.UserRecommendations;
        case MixedContentType.Track:
            return ContentType.TrackPlayable;
        case MixedContentType.TrackChart:
            return ContentType.TrackChart;
        case MixedContentType.TrackRadio:
            return ContentType.TrackRadio;
        case MixedContentType.YourHistory:
            return ContentType.UserHistory;
        case MixedContentType.LiveRadio:
            return ContentType.LiveRadio;
        case MixedContentType.LiveRadioCategory:
            return ContentType.LiveRadioCategory;
        case MixedContentType.YourMixes:
            return ContentType.YourMixesPage;
    }

    log.error({ code: "web-211027-1433", msg: DefaultLogMessage.NotImplemented, data: { type } });
    type;
    return null;
}
