import { SetAnalyticsProp, SetFirstAnalyticsProp, IncrementAnalyticsProp, createAnalyticsData } from "../../analyticsData";

export function getAnalyticsUserPlayErrorProperties() {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstPlayedContentError", new Date());
    SetAnalyticsProp(data, "user", "LastPlayedContentError", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfPlayedContentError");

    return data;
}
