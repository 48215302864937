import { h } from "preact";
import { Link } from "..";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL, PLAYQUEUE_SET_AND_PLAY } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import type { Multiselect } from "services/selection";
import { getMultiselectPlayModel } from "services/selection";
import { createAndStartUserPlayTraceModel, TraceName } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { IconName } from "components/atoms/icon";

interface Props {
    multiselect: Multiselect;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const AddMultipleToQueueReplaceLink = ({ multiselect, context, showIcon }: Props) => {
    const translations = useTranslations();
    const addToQueue = async () => {
        const playable = getMultiselectPlayModel(multiselect);
        const trace = await createAndStartUserPlayTraceModel(TraceName.ClickToSound);
        dispatch({ type: PLAYQUEUE_SET_AND_PLAY, payload: { playable, playableContext: context, context: { action: AudioContextAction.UserContextMenuAddToQueue, trace } } });
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    };
    return (
        <Link icon={showIcon ? IconName.PlayAsLast : undefined} onClick={addToQueue}>
            {translations.Play}
        </Link>
    );
};
