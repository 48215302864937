import { translate } from "global/config";
import { getLinkFromResource } from "services/resource";
import type { PlayQueuePlayableModel } from "models/domain";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getDragTitle(resource: PlayQueuePlayableModel) {
    const trackCount = resource.trackCount;
    if (trackCount != null && trackCount > 1) return `${trackCount} ${translate("Tracks").toLowerCase()}`;
    return getLinkFromResource(resource)?.text ?? "";
}
