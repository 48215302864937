import { useSelector } from "react-redux";
import { dispatch, messageBus, store } from "global";
import {
    PLAYLIST_COLLECTION_ADDED,
    PLAYLIST_CREATED,
    PLAYLIST_DELETED,
    PLAYLIST_MODIFIED,
    PLAYLIST_TRACK_ADDED,
    PLAYLIST_TRACKS_ADDED,
    PLAYLIST_TRACKS_REMOVED,
    PLAYLIST_TRACK_REORDERED,
    UPDATE_CACHED_PLAYLISTS,
    USER_CHANGED,
    USER_LOGGED_IN,
    USER_PROFILES_CHANGED
} from "global/actions";
import type { PlaylistCreatedAction } from "global/actions/actionTypes";
import { locale } from "global/constants";
import { getOwnPlaylists, getPlaylistPreview } from "services/backend";
import { log } from "services/logger";
import type { PlaylistVisibility } from "generated/graphql-types";
import { CombinedPlaylistsSorting } from "generated/graphql-types";
import type { RootModel } from "models/app";
import { LoginState } from "models/app";
import type { PlayableModel, PlaylistPreviewModel } from "models/domain";
import { useArrayMemo } from "components/shared/hooks";

const enableMoveToTop = true;

export function useCachedUserPlaylistPreview(playlistId: string) {
    const previews = useSelector((root: RootModel) => root.cache.userPlaylistPreviews);
    return previews?.find((n) => n.id == playlistId) ?? null;
}

export async function getCachedUserPlaylistPreview(playlistId: string): Promise<PlaylistPreviewModel | null> {
    return store.getState().cache.userPlaylistPreviews?.find((n) => n.id == playlistId) ?? null;
}

export function useUserPlaylists(sorting: CombinedPlaylistsSorting) {
    const data = useSelector((root: RootModel) => root.cache.userPlaylistPreviews);
    const memo = useArrayMemo(() => {
        if (!data) return [];
        switch (sorting) {
            case CombinedPlaylistsSorting.CreatedDate:
                return [...data].sort((a, b) => (b?.createdAt?.getTime() ?? 0) - (a?.createdAt?.getTime() ?? 0));
            case CombinedPlaylistsSorting.Title:
                return [...data].sort((a, b) => (a?.title ?? "").localeCompare(b.title, locale));
            default:
                return data;
        }
    }, [data]);
    return data ? memo : null;
}

export function initCachedUserPlaylists() {
    messageBus.subscribeEvery(USER_LOGGED_IN, load);
    messageBus.subscribeEvery(USER_CHANGED, load);
    messageBus.subscribeEvery(USER_PROFILES_CHANGED, (evt) => {
        if (evt.payload.currentProfileChanged) {
            load();
        }
    });
    messageBus.subscribeEvery(PLAYLIST_CREATED, insert);
    messageBus.subscribeEvery(PLAYLIST_DELETED, (msg) => removeFromCache(msg.payload.playlistId));
    if (enableMoveToTop) {
        messageBus.subscribeEvery(PLAYLIST_MODIFIED, (msg) =>
            moveToTopOfCacheAndOptionallyUpdate(msg.payload.playlistId, msg.payload.playlist?.title, null, msg.payload.playlist?.visibility)
        );
        messageBus.subscribeEvery(PLAYLIST_COLLECTION_ADDED, (msg) => onCollectionAdded(msg.payload.playlistId, msg.payload.playable, msg.payload.trackCount));
        messageBus.subscribeEvery(PLAYLIST_TRACK_ADDED, (msg) => moveToTopOfCacheAndOptionallyUpdate(msg.payload.playlistId));
        messageBus.subscribeEvery(PLAYLIST_TRACKS_ADDED, (msg) => moveToTopOfCacheAndOptionallyUpdate(msg.payload.playlistId));
        messageBus.subscribeEvery(PLAYLIST_TRACKS_REMOVED, (msg) => onTracksRemoved(msg.payload.playlistId));
        messageBus.subscribeEvery(PLAYLIST_TRACK_REORDERED, (msg) => moveToTopOfCacheAndOptionallyUpdate(msg.payload.playlistId));
    }

    try {
        load();
    } catch (e) {
        log.error({ code: "web-220221-1329", msg: "error in update cached user playlists service", error: e });
    }
}

function onCollectionAdded(id: string, playable: PlayableModel | null, trackCount: number) {
    const playlist = store.getState().cache.userPlaylistPreviews?.find((n) => n.id == id);
    if (playlist) {
        moveToTopOfCacheAndOptionallyUpdate(id, null, playlist.trackCount == null ? null : (playlist.trackCount + trackCount));
    }
}

function onTracksRemoved(id: string) {
    const playlist = store.getState().cache.userPlaylistPreviews?.find((n) => n.id == id);
    if (playlist) {
        moveToTopOfCacheAndOptionallyUpdate(id, null, playlist.trackCount == null ? null : Math.max(0, playlist.trackCount - 1));
    }
}

function moveToTopOfCacheAndOptionallyUpdate(id: string, title: string | null = null, trackCount: number | null = null, visibility: PlaylistVisibility | null = null) {
    let playlists = store.getState().cache.userPlaylistPreviews;
    if (playlists) {
        const index = playlists.findIndex((n) => n.id == id);
        if (index != -1) {
            playlists = [playlists[index], ...playlists.filter((n) => n.id !== id)];
            if (
                (title !== null && title !== playlists[0].title) ||
                (trackCount !== null && trackCount !== playlists[0].trackCount) ||
                (visibility !== null && visibility !== playlists[0].visibility)
            ) {
                playlists[0] = {
                    ...playlists[0],
                    title: title ?? playlists[0].title,
                    trackCount: trackCount ?? playlists[0].trackCount,
                    visibility: visibility ?? playlists[0].visibility
                };
            }
            dispatch({
                type: UPDATE_CACHED_PLAYLISTS,
                payload: {
                    playlists
                }
            });
        }
    }
}

function removeFromCache(id: string) {
    let playlists = store.getState().cache.userPlaylistPreviews;
    if (playlists) {
        const index = playlists.findIndex((n) => n.id == id);
        if (index != -1) {
            playlists = playlists.filter((n) => n.id !== id);
            dispatch({
                type: UPDATE_CACHED_PLAYLISTS,
                payload: {
                    playlists
                }
            });
        }
    }
}

async function insert(msg: PlaylistCreatedAction) {
    const playlist = msg.payload.playlist ?? (await getPlaylistPreview({ id: msg.payload.playlistId }))?.model;
    if (playlist) {
        const playlists = [playlist, ...(store.getState().cache.userPlaylistPreviews ?? [])];
        dispatch({
            type: UPDATE_CACHED_PLAYLISTS,
            payload: {
                playlists
            }
        });
    }
}

function load() {
    const isLoggedIn = store.getState().user.state === LoginState.LoggedIn;
    if (isLoggedIn) {
        getOwnPlaylists().then((result) => {
            dispatch({
                type: UPDATE_CACHED_PLAYLISTS,
                payload: {
                    playlists: result.model?.items ?? []
                }
            });
        });
    } else {
        dispatch({
            type: UPDATE_CACHED_PLAYLISTS,
            payload: {
                playlists: null
            }
        });
    }
}
