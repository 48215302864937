import { getCurrentInputAudioItemFromState } from "../helpers";
import { log } from "services/logger";
import { playChange } from "services/player/inputs/service/actions";
import type { PausePositionModel } from "models/app/player";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";

export const playerSeek = (percent: number, context: AudioContextModel) => {
    const loaded = getCurrentInputAudioItemFromState();
    if (!loaded) return;
    const { playState } = loaded;
    if (loaded.input === AudioInputType.LiveRadio) {
        log.error({ code: "web-21103-1213", msg: "can't seek in live radio" });
        return;
    }

    if (playState === PlayState.Error) {
        log.error({ code: "web-210118-1520", msg: "unexpected playState", data: { playState } });
        return;
    }

    const playing = playState === PlayState.Buffering || playState === PlayState.Playing;
    
    const newPlayState = playing ? PlayState.Buffering : loaded.playState !== PlayState.Paused ? PlayState.Paused : null;
    const newPosition: PausePositionModel = { pausePosition: (loaded.duration * percent) / 100 };

    playChange(loaded, newPlayState, newPosition, context);
};
