import { serviceWorkerConfig } from "../../../../../../../shared/services";
import { store } from "global";
import { playerConfig } from "global/constants";
import { log, LogTag } from "services/logger";
import { AudioInputType } from "models/app/player";
import type { AudioInputItemModel, StreamUrl } from "models/app/player/input";
import type { BrowserAudioItemModel } from "models/app/player/output";

export function getCacheId(audio: AudioInputItemModel): string {
    const cacheId = (() => {
        switch (audio.input) {
            case AudioInputType.LiveRadio: {
                const radioId = audio.radioId;
                return `radio_${radioId}`.toLowerCase();
            }
            case AudioInputType.PlayQueue: {
                const trackId = audio.trackId;
                const sample = audio.sample;
                return `track_${trackId}_${sample ? "sample" : "full"}`.toLowerCase();
            }
        }
    })();

    log.debug([LogTag.Playback], () => ({
        code: "web-221213-1552",
        msg: `created new cache id: ${audio.logTitle}`,
        data: {
            cacheId,
            logTitle: audio.logTitle
        }
    }));

    return cacheId;
}

export async function updateUrlInCache(browserAudio: BrowserAudioItemModel) {
    if (!isCachePoolEnabled()) return;

    const url = browserAudio.url;
    const key = getUrlKey(browserAudio.audioCacheId);

    if (url) {
        log.debug([LogTag.Playback], () => ({
            code: "web-230102-1256",
            msg: `save url in cache: ${browserAudio.logTitle}`
        }));

        await saveJsonToCache(key, url);
    } else {
        log.debug([LogTag.Playback], () => ({
            code: "web-230102-1257",
            msg: `delete url from cache: ${browserAudio.logTitle}`
        }));

        await deleteFromCache(key);
    }
}

export async function setTrackCacheBegin(browserAudio: BrowserAudioItemModel) {
    if (!isCachePoolEnabled()) return;

    log.debug([LogTag.Playback], () => ({
        code: "web-230102-1255",
        msg: `begin saving track as cached: ${browserAudio.logTitle}`
    }));

    const cacheId = browserAudio.audioCacheId;

    const deleteEnd = deleteFromCache(getCachedEndKey(cacheId));
    const saveBegin = saveToCache(getCachedBeginKey(cacheId));

    await Promise.all([deleteEnd, saveBegin]);
}

export async function setTrackFullyCached(browserAudio: BrowserAudioItemModel, checkKeys: string[]): Promise<boolean> {
    if (!isCachePoolEnabled()) return false;

    log.debug([LogTag.Playback], () => ({
        code: "web-230102-1259",
        msg: `saving track as fully cached: ${browserAudio.logTitle}`
    }));

    const valid = checkDataValid(checkKeys);
    if (!valid) return false;

    await saveToCache(getCachedEndKey(browserAudio.audioCacheId));
    return true;
}

// TODO consider disabling this check
async function checkDataValid(checkKeys: string[]): Promise<boolean> {
    const checks = checkKeys.map(async (key) => {
        try {
            const response = await readFromCache(key);
            if (!response) return false;

            const body = await response.text();
            return body != null && body !== "";
        } catch (e) {
            log.error({ code: "web-230103-1129", msg: "cached data not valid", data: { key } });
            return false;
        }
    });

    const ok: boolean = (await Promise.all(checks)).every((value) => value);
    return ok;
}

export type TrackUrlResult = {
    url: StreamUrl | null;
    message: string;
};

export async function getValidTrackUrl(browserAudio: BrowserAudioItemModel): Promise<TrackUrlResult> {
    if (!isCachePoolEnabled()) return { url: null, message: "cache pool disabled" };

    const cacheId = browserAudio.audioCacheId;

    const url = await readJsonFromCache<StreamUrl>(getUrlKey(cacheId));
    if (!url) return { url: null, message: "not cached" };

    const validDuration = playerConfig.expectedHlsStreamValidSec;
    const now = new Date().getTime();
    const validByTime = now < url.fetchedTime + validDuration;
    if (validByTime) {
        log.debug([LogTag.Playback], () => ({
            code: "web-230102-1135",
            msg: `found cached url (url valid by time): ${browserAudio.logTitle}`
        }));

        return { url, message: "found cached url (url valid by time)" };
    }

    const [begin, end] = await Promise.all([existInCache(getCachedBeginKey(cacheId)), existInCache(getCachedEndKey(cacheId))]);
    const trackCahed = begin === true && end === true;
    if (trackCahed) {
        log.debug([LogTag.Playback], () => ({
            code: "web-230102-1136",
            msg: `found cached url (url valid by track cached): ${browserAudio.logTitle}`
        }));

        return { url, message: "found cached url (url valid by track cached)" };
    }

    return { url: null, message: "not found" };
}

export async function deleteTrackCache(browserAudio: BrowserAudioItemModel) {
    if (!isCachePoolEnabled()) return null;

    const cacheId = browserAudio.audioCacheId;

    const deleteUrl = deleteFromCache(getUrlKey(cacheId));
    const deleteBegin = deleteFromCache(getCachedBeginKey(cacheId));
    const deleteEnd = deleteFromCache(getCachedEndKey(cacheId));

    await Promise.all([deleteUrl, deleteBegin, deleteEnd]);
}

function isCachePoolEnabled() {
    return store.getState().controlPanel.enableAudioCachePool === true;
}

function getUrlKey(cacheId: string): string {
    return `url_${cacheId}`;
}

function getCachedBeginKey(cacheId: string): string {
    return `cached_begin_${cacheId}`;
}

function getCachedEndKey(cacheId: string): string {
    return `cached_end_${cacheId}`;
}

async function saveToCache(key: string, value?: string) {
    const cache = await caches.open(serviceWorkerConfig.cacheNameHls);
    await cache.put(key, new Response(value));
}

async function existInCache(key: string): Promise<boolean> {
    const cache = await caches.open(serviceWorkerConfig.cacheNameHls);
    const exist = (await cache.match(key)) != undefined;
    return exist;
}

async function saveJsonToCache<T>(key: string, value: T) {
    const cache = await caches.open(serviceWorkerConfig.cacheNameHls);
    await cache.put(key, new Response(JSON.stringify(value), { headers: { "content-type": "application/json" } }));
}

async function readJsonFromCache<T>(key: string): Promise<T | null> {
    const cache = await caches.open(serviceWorkerConfig.cacheNameHls);
    const response = await cache.match(key);
    if (!response) return null;

    const value = await response.json();
    if (value == null) return null;

    return value;
}

async function readFromCache(key: string): Promise<Response | undefined> {
    const cache = await caches.open(serviceWorkerConfig.cacheNameHls);
    const response = await cache.match(key);
    return response;
}

async function deleteFromCache(key: string) {
    const cache = await caches.open(serviceWorkerConfig.cacheNameHls);
    await cache.delete(key);
}
