import { getTrackIndexInQueueLane } from "./queueLanes";
import { DefaultLogMessage, log } from "services/logger";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import { getAnalyticsQueueIntentPropertiesGroup, createQueueAddedAnalyticsData } from "services/logger/analytics/properties/groups";
import type { NormalizedData } from "services/normalizeData";
import { createNormalizedData } from "services/normalizeData";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane, createQueueTrackModel } from "models/app/player/input";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { TrackPlayablePreviewModel } from "models/domain";

export const addTracksToQueueTracks = (
    playQueue: QueueTrackModel[],
    tracks: TrackPlayablePreviewModel[],
    lane: QueueLane.Memory | QueueLane.Current | QueueLane.Priority | QueueLane.Flow,
    atEnd: boolean,
    insert: number | null,
    setCurrentLane: boolean,
    shuffleOn: boolean,
    analyticsQueueAddedReferenceId: string,
    code: `web-${number}-${number}`
): QueueTrackModel[] => {
    if (tracks.length === 0) {
        log.error({ code, msg: DefaultLogMessage.UnexpectedValue });
        return playQueue;
    }

    if (lane === QueueLane.Current && tracks.length !== 1) {
        log.error({ code: "web-210313-1149", msg: DefaultLogMessage.UnexpectedValue });
        return playQueue;
    }

    let currentLaneIndex = playQueue.findIndex((item) => item.lane === QueueLane.Current);
    if (currentLaneIndex === -1) currentLaneIndex = 0;
    const currentLaneOriginalIndex = playQueue.find((item) => item.lane === QueueLane.Current)?.originalIndex ?? null;

    insert = insert ?? getTrackIndexInQueueLane(playQueue, lane, atEnd);
    let index = insert;
    const newQueueTracks: QueueTrackModel[] = [];

    const createOriginalIndex = (i: number) => {
        if (!shuffleOn) return null;
        if (currentLaneOriginalIndex == null) return null;
        return currentLaneOriginalIndex + i;
    };

    tracks.forEach((track, i) => {
        const newLane = setCurrentLane && currentLaneIndex === index ? QueueLane.Current : lane;

        const originalIndex = createOriginalIndex(i + 1);

        const queueTrack = createQueueTrackModel(track, newLane, originalIndex, analyticsQueueAddedReferenceId);
        newQueueTracks.push(queueTrack);
        index++;
    });

    playQueue = playQueue.map((queueTrack) => {
        if (lane === QueueLane.Current && queueTrack.lane === QueueLane.Current) {
            queueTrack.lane = QueueLane.Memory;
        }
        queueTrack.originalIndex = updateOriginalIndex(queueTrack.originalIndex, currentLaneOriginalIndex, tracks.length);
        return queueTrack;
    });
    playQueue.splice(insert, 0, ...newQueueTracks);
    return playQueue;
};

function updateOriginalIndex(originalIndex: number | null, from: number | null, withNumber: number) {
    if (from == null) return null;
    if (originalIndex != null) {
        if (originalIndex > from) {
            return withNumber + originalIndex;
        }
    }
    return originalIndex;
}

export const getQueueTracksForChromecast = (
    playQueue: QueueTrackModel[],
    tracks: TrackPlayablePreviewModel[],
    lane: QueueLane.Memory | QueueLane.Current | QueueLane.Priority | QueueLane.Flow,
    insert: number | null,
    setCurrentLane: boolean,
    shuffleOn: boolean,
    context: PreviewContextModel
): { playQueue: QueueTrackModel[]; queueAddData: NormalizedData<AnalyticsQueueAddedPropertiesGroup>; insert: number; newQueueTracks: QueueTrackModel[] } | null => {
    if (tracks.length === 0) {
        log.error({ code: "web-210528-1511", msg: DefaultLogMessage.UnexpectedValue });
        return null;
    }

    let currentLaneIndex = playQueue.findIndex((item) => item.lane === QueueLane.Current);
    if (currentLaneIndex === -1) currentLaneIndex = 0;

    insert = insert ?? getTrackIndexInQueueLane(playQueue, lane, true);
    let index = insert;
    const newQueueTracks: QueueTrackModel[] = [];

    const queueIntentData = getAnalyticsQueueIntentPropertiesGroup(context);
    const addData = createQueueAddedAnalyticsData(queueIntentData, tracks[0].parent);
    const queueAddData = createNormalizedData(addData);

    tracks.forEach((track, i) => {
        let newLane: QueueLane = lane;
        if (lane === QueueLane.Current) {
            newLane = currentLaneIndex === index ? QueueLane.Current : QueueLane.Flow;
        } else if (setCurrentLane && currentLaneIndex === index) {
            newLane = QueueLane.Current;
        }
        const originalIndex = shuffleOn ? playQueue.length + i : null;
        const queueTrack = createQueueTrackModel(track, newLane, originalIndex, queueAddData.referenceId);
        newQueueTracks.push(queueTrack);
        index++;
    });

    playQueue = [...playQueue];
    playQueue = playQueue.map((queueTrack) => {
        if (lane === QueueLane.Current && queueTrack.lane === QueueLane.Current) {
            queueTrack.lane = QueueLane.Memory;
        }
        return queueTrack;
    });
    playQueue.splice(insert, 0, ...newQueueTracks);
    return { playQueue, queueAddData, insert, newQueueTracks };
};
