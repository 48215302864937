import { getLocalCastQueue } from "../actions/localCastQueue";
import type { AudioInputItemModel } from "models/app/player/input";

export function isLocalCastQueueEmpty(): boolean {
    const empty = getLocalCastQueue().audios.length === 0;
    return empty;
}

export function isCurrentAudioLastInQueue(): { last: boolean; currentAudio: AudioInputItemModel | null } {
    const { audios, currentIndex, currentAudio } = getLocalCastQueue();

    if (audios.length == 0) return { last: false, currentAudio };
    if (currentIndex == null) return { last: false, currentAudio };

    const last = currentIndex === audios.length - 1;
    return { last, currentAudio };
}
