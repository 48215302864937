import { updateLiveRadioTracksSync } from "./service";
import { setAndPlay } from "./service/setAndPlay";
import { messageBus, dispatch } from "global";
import { AUDIO_INPUT_TYPE_CHANGE, LIVERADIO_SET_AND_PLAY, LIVERADIO_UPDATE } from "global/actions";
import { AudioInputType } from "models/app/player";

export const initLiveRadioAudioInputService = () => {
    updateLiveRadioTracksSync();

    messageBus.subscribeEvery(LIVERADIO_SET_AND_PLAY, async (msg) => {
        const { radio, radioContext, context } = msg.payload;
        setAndPlay(radio, radioContext, context);
    });

    messageBus.subscribeEvery(LIVERADIO_UPDATE, () => {
        updateLiveRadioTracksSync();
    });

    messageBus.subscribeEvery(AUDIO_INPUT_TYPE_CHANGE, (msg) => {
        if (msg.payload.input !== AudioInputType.LiveRadio) {
            dispatch({ type: LIVERADIO_UPDATE, payload: { radio: null, radioContext: null, analytics: null, context: msg.payload.context } });
        }
    });
};
