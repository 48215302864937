import type { Action } from "../actions";
import { MODAL_OPEN, MODAL_CLOSE } from "global/actions";
import { AppModalModel } from "models/view/AppModalModel";

const initialState: AppModalModel = { open: null };

export const modalReducer: (state: AppModalModel | undefined, action: Action) => AppModalModel = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_OPEN:
            return { ...state, open: action.payload.modal };
        case MODAL_CLOSE:
            return { ...state, open: null };
    }
    return state;
};
