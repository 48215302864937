import { ScrollerSize, ScrollerAlign } from "../../options";

export const getAlignOffset = (size: ScrollerSize | number, align: ScrollerAlign) => {
    //const childSize = getItemSize(size);
    switch (align) {
        case ScrollerAlign.Center:
            return 0;
        case ScrollerAlign.Left:
            return 0;
    }
};
