import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import "./MaxiPlayerPrimaryControl.scss";
import { dispatch } from "global";
import { PLAYER_NEXT, PLAYER_PREVIOUS_OR_REWIND, PLAYER_TOGGLE_PLAY } from "global/actions";
import { TestLocator } from "global/constants";
import { getPlayIcon } from "services/player/controllers/service/helpers/icons";
import { isPreviousDisabled, isNextDisabled } from "services/player/inputs/inputs/playQueue/helpers";
import { useLoginState } from "services/user";
import { LoginState, type RootModel } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { useAudioProperties } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";

interface Props {
    showQueue: boolean;
}

export const MaxiPlayerPrimaryControl = ({ showQueue }: Props) => {
    const { playState, canPlay, canPause } = useAudioProperties();
    const loginStateIsUnknown = useLoginState() === LoginState.Unknown;

    const input = useSelector((root: RootModel) => root.player.input);

    const rewindOrPlayPrevious = useCallback(
        () => dispatch({ type: PLAYER_PREVIOUS_OR_REWIND, payload: { context: { action: AudioContextAction.UserPlayerPreviousOrRewind, trace: null } } }),
        []
    );
    const playerTogglePlay = useCallback(() => dispatch({ type: PLAYER_TOGGLE_PLAY, payload: { context: { action: AudioContextAction.UserPlayerTogglePlay, trace: null } } }), []);
    const playerNext = useCallback(() => dispatch({ type: PLAYER_NEXT, payload: { context: { action: AudioContextAction.UserPlayerNext, trace: null } } }), []);

    return (
        <div className={`MaxiPlayerPrimaryControl f3j --showQueue-${showQueue}`}>
            <Button
                design={ButtonDesign.PlayerSecondary}
                onClick={rewindOrPlayPrevious}
                icon={IconName.Previous}
                disabled={isPreviousDisabled() || loginStateIsUnknown}
                testLocator={TestLocator.PlayerPreviousButton}
            />
            <Button
                design={ButtonDesign.PlayerPrimary}
                className={"playPause"}
                onClick={playerTogglePlay}
                icon={getPlayIcon(playState, input)}
                disabled={(!canPlay && !canPause) || loginStateIsUnknown}
                testLocator={TestLocator.PlayerPlayPauseButton}
            />
            <Button
                design={ButtonDesign.PlayerSecondary}
                onClick={playerNext}
                icon={IconName.Next}
                disabled={isNextDisabled() || loginStateIsUnknown}
                testLocator={TestLocator.PlayerNextButton} />
        </div>
    );
};
