import { h } from "preact";
import { OpenMenuResult } from "./open";
import { getNextUUID } from "services/utils";
import {
    ContextMenuPosition,
    ContextMenuItemType,
    ContextMenuAlign,
    ContextMenuItemModel,
    ContextMenuSheetModel,
    ContextMenuTreeModel,
    ContextMenuLinkModel,
    ContextMenuSheetWidth,
    ContextMenuLayerModel
} from "models/view/contextMenu";

interface CreateContextMenuLinkParams {
    layer: ContextMenuLayerModel;
    parent?: ContextMenuItemModel;
    position: ContextMenuPosition | null;
    tree: ContextMenuTreeModel;
}

export function createContextMenuLink({ parent, tree, layer, position }: CreateContextMenuLinkParams): ContextMenuLinkModel {
    const link: ContextMenuLinkModel = {
        children: [],
        id: getNextUUID(),
        layer,
        parent: parent ?? null,
        position,
        tree,
        type: ContextMenuItemType.Link
    };
    if (parent) parent.children = [...parent.children, link];
    return link;
}

interface CreateContextMenuSheetParams {
    align: ContextMenuAlign;
    gap?: boolean;
    layer: ContextMenuLayerModel;
    menu: h.JSX.Element;
    onClose?: (result: OpenMenuResult) => void;
    parent: ContextMenuItemModel;
    tree: ContextMenuTreeModel;
    width: ContextMenuSheetWidth;
}

export function createContextMenuSheet({ tree, layer, parent, menu: content, align, width, gap = false }: CreateContextMenuSheetParams): ContextMenuSheetModel {
    const sheet: ContextMenuSheetModel = {
        align,
        children: [],
        content,
        gap,
        id: getNextUUID(),
        layer,
        parent,
        position: null,
        tree,
        type: ContextMenuItemType.Sheet,
        width
    };
    parent.children = [...parent.children, sheet];
    return sheet;
}
