import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { useTranslations } from "global/config";
import { removeTracksFromPlaylist } from "services/playlist";
import type { Multiselect } from "services/selection";
import { getMultiselectPlayModel, clearSelection } from "services/selection";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { TrackPlayableModel, TrackPreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { IconName } from "components/atoms/icon";

interface Props {
    multiselect: Multiselect;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const RemoveMultipleFromPlaylistLink = ({ multiselect, context, showIcon }: Props) => {
    const icon = showIcon ? IconName.Trash : undefined;
    const translations = useTranslations();

    const onRemoveTracks = useCallback(async () => {
        if (multiselect.state.selectedItems.length > 0 && multiselect.state.context) {
            const playlistId = multiselect.state.context.resource?.id;
            if (!playlistId) {
                // todo: log error
                return;
            }
            const tracks = getMultiselectPlayModel(multiselect).tracks?.items;
            if (tracks) {
                clearSelection(multiselect);
                removeTracksFromPlaylist(playlistId, tracks);
            }
        }
    }, [multiselect])

    if (context.section.resource?.contentType !== ContentType.Playlist || !context.section.resource.owner)
        return null;

    return (
        <Link icon={icon} closeOnClick onClick={onRemoveTracks}>
            {translations.ContextOptionRemoveFromPlaylist}
        </Link>
    );
};
