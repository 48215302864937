import { dispatch, store } from "global";
import { SET_DESKTOP_QUEUE_OPEN } from "global/actions";
import { DesktopQueueOpen } from "models/app";
import { AudioInputType } from "models/app/player";
import { QueueMode } from "models/app/player/input";
import { getQueueProperties } from "components/shared/hooks/useQueueProperties";

export const toggleDesktopPlayQueue = () => {
    const { ui, queue, player } = store.getState();
    const { desktopQueueOpen } = ui.layout;

    const isQueueModeMixRadio = queue.mode === QueueMode.MixRadio;
    const isInputLiveRadio = player.input === AudioInputType.LiveRadio;

    if (isQueueModeMixRadio || isInputLiveRadio) return;

    const { hasItems } = getQueueProperties();
    const queueOpen = desktopQueueOpen === DesktopQueueOpen.Open || (desktopQueueOpen === DesktopQueueOpen.Default && hasItems);
    dispatch({ type: SET_DESKTOP_QUEUE_OPEN, payload: { open: queueOpen ? DesktopQueueOpen.Closed : DesktopQueueOpen.Open } });
};
