import { h } from "preact";
import "./AppPromptPage.scss";
import { useTranslations } from "global/config";
import { DownloadAppButton } from "components/atoms/downloadAppButton";
import { Logo } from "components/atoms/logo";

export const AppPromptPage = () => {
    const translations = useTranslations();
    return (
        <div className={`AppPromptPage jDl`}>
            <Logo />
            <div className="content">
                <div className="text">{translations.AppStoreWebTitle}</div>
                <DownloadAppButton />
            </div>
        </div>
    );
};
