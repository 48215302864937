import type { ConnectionModel } from "./ConnectionModel";
import { createTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { TrackPreviewModel } from "./TrackModel";
import { getNextUUID } from "services/utils";
import type { TrackPreviewConnectionFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type EndlessPlayData = TrackPreviewConnectionFragment;

export interface EndlessPlayModel extends PlayQueuePlayableModelInterface {
    readonly type: DomainModelType.Play;
    readonly contentType: ContentType.EndlessPlay;
    readonly tracks: ConnectionModel<TrackPreviewModel>;
}

export function createEndlessPlayModel(data: EndlessPlayData): EndlessPlayModel {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        type: DomainModelType.Play,
        contentType: ContentType.EndlessPlay,
        tracks: createTrackPreviewConnectionModel(data, 0),
        cover: null,
        trackCount: null
    };
}
