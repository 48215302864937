import type { RecommendationPreviewModel, ResourceModelInterface } from ".";
import { createRecommendationPreviewConnectionModel } from ".";
import type { ConnectionModel } from "./ConnectionModel";
import { getNextUUID } from "services/utils";
import type { DiscoverRecommendationsPageFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type DiscoverRecommendationsPageData = DiscoverRecommendationsPageFragment;

export interface DiscoverRecommendationsPageModel extends ResourceModelInterface {
    type: DomainModelType.Page;
    contentType: ContentType.UserRecommendations;
    collections: ConnectionModel<RecommendationPreviewModel> | null;
}

export function createDiscoverRecommendationsPage(data: DiscoverRecommendationsPageData): DiscoverRecommendationsPageModel {
    return {
        type: DomainModelType.Page,
        contentType: ContentType.UserRecommendations,
        id: getNextUUID(),
        graphType: data.__typename,
        cover: null,
        collections: data.recommendations ? createRecommendationPreviewConnectionModel(data.recommendations, ContentType.UserRecommendations) : null
    };
}
