import { getLocalCastQueue, updateLocalCastQueue } from "./localCastQueue";

let _queue: number[] = [];

export function getRemoteCastQueue() {
    return _queue;
}

export function setRemoteCastQueue(queue: number[]) {
    _queue = queue;
}

export function resetRemoteCastQueue() {
    setRemoteCastQueue([]);
}

export function assumeRemoteCastQueueSynched() {
    updateLocalCastQueue();
    const ids = [...getLocalCastQueue().audiosCastIds];
    setRemoteCastQueue(ids);
}
