import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { dispatch } from "global";
import { SET_MAXIPLAYER_OPEN } from "global/actions";
import { useTranslations } from "global/config";
import { MobileMaxiPlayerOpen } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ArtistRadioModel } from "models/domain/ArtistRadioModel";
import { ContentType } from "models/ModelType";
import { useLinkContext } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";

interface Props {
    artistRadio: ArtistRadioModel | null;
    showIcon: boolean;
    context: PreviewContextModel;
}

export const GoToArtistRadioLink = ({ showIcon, artistRadio, context }: Props) => {
    const link = useLinkContext(ContentType.ArtistRadio, artistRadio, context);
    const translations = useTranslations();

    const click = useCallback(() => {
        dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });
    }, []);

    return (
        <Link closeOnClick icon={showIcon ? IconName.Sound : undefined} onClick={click} link={link}>
            {translations.ContextOptionGoToArtistRadioAction}
        </Link>
    );
};
