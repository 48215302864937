import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castPlay(): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castPlay", new chrome.cast.media.PlayRequest(), (request, success, error) => {
        media.play(request, success, error);
    });
}
