import { h } from "preact";
import { Modal, modalButtonDismissCancel, modalButtonSubmitContinue, openModal } from "..";
import { useTranslations } from "global/config";

export async function showDeletePlaylistModal() {
    return openModal(<DeletePlaylistModal />);
}

export function DeletePlaylistModal() {
    const translations = useTranslations();
    return (
        <Modal
            title={translations.MyMusicDeletePlaylistTitle}
            text={translations.MyMusicDeletePlaylistSubtitle}
            buttons={[modalButtonDismissCancel(), modalButtonSubmitContinue()]}
        />
    );
}
