import type { AnalyticsPlayableProperties } from "services/logger/analytics/properties/event";
import { getAnalyticsPlayableProperties } from "services/logger/analytics/properties/event";
import { getComparableId } from "services/resource";
import { getNextUUID } from "services/utils";
import type { PlayQueuePlayableModelInterface, TrackPlayableModel, TrackPreviewModel } from "models/domain";
import { getComparableTrackPlayableId } from "models/domain";
import { ContentType, DomainModelType } from "models/ModelType";

export enum QueueLane {
    Memory = "memory",
    Current = "current",
    Priority = "priority",
    Flow = "flow",
    Preview = "preview"
}

export interface QueueTrackModel extends PlayQueuePlayableModelInterface {
    type: DomainModelType.Preview;
    compareableTrackId: string;
    contentType: ContentType.QueueTrack;
    lane: QueueLane;
    originalIndex: number | null;
    parentId: string;
    track: TrackPreviewModel;
    queueId: string;
    analytics2?: {
        playableData: AnalyticsPlayableProperties;
        addedDataId: string;
    };
}

export const createQueueTrackModel = (track: TrackPlayableModel, lane: QueueLane, originalIndex: number | null, addedDataId: string): QueueTrackModel => {
    const playableData = getAnalyticsPlayableProperties(track);

    return {
        graphType: undefined,
        type: DomainModelType.Preview,
        analytics2: { playableData, addedDataId },
        queueId: getNextUUID(),
        compareableTrackId: getComparableTrackPlayableId(track.track, track.parent),
        contentType: ContentType.QueueTrack,
        lane,
        originalIndex,
        parentId: getComparableId(track.parent),
        track: track.track,
        cover: track.cover,
        trackCount: 1,
        id: track.id
    };
};
