import { getCurrentLaneIndexFromState } from "../helpers";
import { store, dispatch } from "global";
import { PLAYER_REWIND, PLAYER_PREVIOUS } from "global/actions";
import { playerConfig } from "global/constants";
import { log } from "services/logger";
import { getSecondsPlayed } from "services/player/helpers";
import { getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import { AudioInputType } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { RepeatStatus } from "models/view";

export const rewindOrPlayPrevious = (context: AudioContextModel) => {
    const index = getCurrentLaneIndexFromState();
    const loaded = getCurrentInputAudioItemFromState();

    const { repeat } = store.getState().player;
    const repeatAll = repeat === RepeatStatus.All;
    if (!loaded) {
        log.error({ code: "web-210519-1130", msg: "can't rewind when loaded is null" });
        return;
    }

    if (loaded.input === AudioInputType.LiveRadio) {
        log.error({ code: "web-210519-1130", msg: "loaded is live radio" });
        return;
    }

    if (index === 0 && !repeatAll) {
        dispatch({ type: PLAYER_REWIND, payload: { context } });
    } else {
        const { seconds } = getSecondsPlayed(loaded);
        if (seconds >= playerConfig.rewindLimit) {
            dispatch({ type: PLAYER_REWIND, payload: { context } });
        } else {
            dispatch({ type: PLAYER_PREVIOUS, payload: { context } });
        }
    }
};
