import type { QueueLane } from ".";
import type { AnalyticsPlayableLoadProperties, AnalyticsPlayableProperties } from "services/logger/analytics/properties/event";
import type { PlayState, PlayPausePositionModel, AudioInputType } from "models/app/player";

export type AudioInputItemModel = AudioInputItemPlayQueueModel | AudioInputItemLiveRadioModel;

export enum StreamUrlType {
    Hls = "Hls",
    Mp3 = "Mp3"
}

export interface StreamUrl {
    url: string;
    urlType: StreamUrlType;
    fetchedTime: number;
}
export function createStreamUrl(url: string, urlType: StreamUrlType): StreamUrl {
    return {
        url,
        urlType,
        fetchedTime: new Date().getTime()
    };
}

export function getMimeTypeFromStreamUrlType(type: StreamUrlType): string {
    switch (type) {
        case StreamUrlType.Hls:
            return "audio/mp4";
        case StreamUrlType.Mp3:
            return "audio/mpeg";
    }
}

interface AudioInputItem {
    analytics2?: {
        playableData: AnalyticsPlayableProperties | null;
        loadData: AnalyticsPlayableLoadProperties | null;
        addedDataId: string | null;
    };
    audioId: string;
    chromecastId: number;
    errorCount: number;
    index: number;
    input: AudioInputType;
    logTitle: string;
    playState: PlayState;
    playbackContext: string | null;
    reportId: string;
    sendReport: boolean;
    url2: StreamUrl | null;
}

export interface AudioInputItemPlayQueueModel extends AudioInputItem {
    duration: number;
    input: AudioInputType.PlayQueue;
    position: PlayPausePositionModel;
    previousQueueLane: QueueLane;
    queueId: string;
    queueLane: QueueLane;
    sample: boolean;
    trackId: string;
}
export interface AudioInputItemLiveRadioModel extends AudioInputItem {
    input: AudioInputType.LiveRadio;
    radioId: string;
}
