import { h } from "preact";
import { Link } from "../Link";
import { useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { UserProfileModel } from "models/app";
import { Avatar } from "components/atoms/controls/avatar";
import { Button } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import "./TopHeaderLink.scss";

interface Props {
    title: string;
    icon: IconName;
    testLocator?: TestLocator;
}

export const TopHeaderLink = ({ title, icon, testLocator }: Props) => {
    const translations = useTranslations();

    return (
        <Link closeOnClick className="topheaderlink --hover-false" disabled={false}>
            <span>
                {title}
                <Button
                    inline={{ right: true }}
                    activated={true}
                    icon={icon}
                    testLocator={testLocator}
                />
            </span>
        </Link>
    );
};
