import type { ResourcePreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";

export function filterItem(item: ResourcePreviewModel, filter: string) {
    if (!filter) return true;
    filter = stripCasing(filter.trim());

    switch (item.contentType) {
        case ContentType.TrackPlayable:
            return (
                -1 != stripCasing(item.track.title).indexOf(filter) ||
                -1 != stripCasing(item.track.album.title).indexOf(filter) ||
                -1 != stripCasing(item.track.artist.name).indexOf(filter)
            );
        case ContentType.Album:
            return -1 != stripCasing(item.title).indexOf(filter) || -1 != stripCasing(item.artist?.name).indexOf(filter);
        case ContentType.Artist:
            return -1 != stripCasing(item.name).indexOf(filter);
        case ContentType.Playlist:
            return -1 != stripCasing(item.title).indexOf(filter);
        case ContentType.MixedContent:
            return -1 != stripCasing(item.title).indexOf(filter) || (item.subtitle && -1 != stripCasing(item.subtitle).indexOf(filter));
        default:
            console.log(item.contentType);
            return true;
    }
}

const _cache: { [key: string]: string } = {};

function stripCasing(text: string | undefined): string {
    if (!text) return "";
    let res = _cache[text];
    if (!res) {
        res = _cache[text] = text.toUpperCase();
    }
    return res;
}
