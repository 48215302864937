import { SetAnalyticsProp, createAnalyticsData, IncrementAnalyticsProp, SetFirstAnalyticsProp } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { QueryError } from "services/backend/backendService";
import { getActivePageContext } from "services/navigationStack/navigationStack";
import { ContentPlaylistType } from "models/app/resourceContextModel";
import type { PageLoadError, PageLoadErrorType } from "models/app/ViewPageError";
import { ErrorPageType, PageLoadAppErrorType } from "models/app/ViewPageError";
import { getErrorPageType } from "components/pages/errorPages/ErrorPage";

export type AnalyticsViewPageProperties = {
    contentPlaylistType?: ContentPlaylistType;
    errorType?: PageLoadErrorType;
    error?: boolean;
    errorText?: string;
    errorPage?: ErrorPageType;
    preDeeplinkUrl?: string;
};

export function getAnalyticsViewPageProperties(error: PageLoadError | null, preDeeplinkUrl: string | null): AnalyticsViewPageProperties {
    const page = getActivePageContext();
    if (!page) return {};

    const properties: AnalyticsViewPageProperties = {
        contentPlaylistType: page.extraInfo?.contentPlaylistType,
        preDeeplinkUrl: preDeeplinkUrl ?? undefined
    };

    if (error) {
        properties.error = true;
        properties.errorType = error.type;
        properties.errorText = error.message ?? undefined;
        properties.errorPage = getErrorPageType(error.type);
    }

    return properties;
}
export function convertAnalyticsViewPageProperties(properties: AnalyticsViewPageProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "ContentPlaylistType", getContentPlaylistType(properties.contentPlaylistType), true);
    SetFirstAnalyticsProp(data, "user", "FirstViewedPage", new Date());
    SetAnalyticsProp(data, "user", "LastViewedPage", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfViewedPage");
    SetAnalyticsProp(data, "event", "DeeplinkUrl", window.location.href as AnalyticsKey);
    if (properties.preDeeplinkUrl) SetAnalyticsProp(data, "event", "PreDeeplinkUrl", properties.preDeeplinkUrl as AnalyticsKey);

    SetAnalyticsProp(data, "event", "Error", properties.error ?? false);
    if (properties.error) {
        SetAnalyticsProp(data, "event", "ErrorType", getErrorType(properties.errorType));
        SetAnalyticsProp(data, "event", "ErrorText", properties.errorText as AnalyticsKey);
        SetAnalyticsProp(data, "event", "ErrorPage", getErrorPage(properties.errorPage));
    }
    return data;
}

function getContentPlaylistType(playlistType: ContentPlaylistType | undefined): AnalyticsKey | null {
    if (!playlistType) return null;

    switch (playlistType) {
        case ContentPlaylistType.Follow:
            return "Follow";
        case ContentPlaylistType.Own:
            return "Own";
        case ContentPlaylistType.Public:
            return "Public";
    }
}

function getErrorType(type: PageLoadErrorType | undefined): AnalyticsKey | null {
    if (!type) return null;

    switch (type) {
        case PageLoadAppErrorType.UrlSchemaNotRecognizedError:
            return "UrlSchemaNotRecognizedError";
        case QueryError.BrowserOfflineError:
            return "OfflineError";
        case QueryError.ContentNotFoundError:
            return "ContentNotFoundError";
        case QueryError.MultipleErrors:
            return "MultipleErrors";
        case QueryError.NetworkClientError:
            return "NetworkClientError";
        case QueryError.NetworkServerDownError:
            return "NetworkServerDownError";
        case QueryError.NetworkServerError:
            return "NetworkServerError";
        case QueryError.NotAvailableInSubscriptionError:
            return "NotAvailableInSubscriptionError";
        case QueryError.NetworkUnknownError:
            return "NetworkUnknownError";
        case QueryError.MapError:
            return "MapError";
        case QueryError.UnknownError:
            return "UnknownError";
    }
    type;
}

function getErrorPage(type: ErrorPageType | undefined): AnalyticsKey | null {
    if (!type) return null;

    switch (type) {
        case ErrorPageType.BackendDownErrorPage:
            return "BackendDownErrorPage";
        case ErrorPageType.DefaultErrorPage:
            return "DefaultErrorPage";
        case ErrorPageType.ContentNotFoundErrorPage:
            return "ContentNotFoundErrorPage";
        case ErrorPageType.NoConnectionErrorPage:
            return "NoConnectionErrorPage";
    }
}
