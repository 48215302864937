import type { ArtistPreviewModel, ResourceModelInterface } from ".";
import type { ConnectionModel } from "./ConnectionModel";
import { createArtistPreviewConnectionModel } from "./ConnectionModel";
import { translate } from "global/config";
import type { FavoriteArtistsQuery } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type MyMusicArtistsPageData = (FavoriteArtistsQuery["me"] & Record<string, unknown>)["favorites"] & Record<string, unknown>;

export type MyMusicArtistsModel = MyMusicArtistsPageModel;
export type MyMusicArtistsPageModel = { type: DomainModelType.Page } & MyMusicArtists;

interface MyMusicArtists extends ResourceModelInterface {
    readonly title: string;
    readonly artists: ConnectionModel<ArtistPreviewModel> | null;
    readonly contentType: ContentType.MyMusicArtists;
}

export function createMyMusicArtistsPageModel(data: MyMusicArtistsPageData): MyMusicArtistsPageModel {
    return {
        graphType: data.__typename,
        id: "myMusicArtists",
        title: translate("Artists"),
        cover: null,
        type: DomainModelType.Page,
        contentType: ContentType.MyMusicArtists,
        artists: data.artists ? createArtistPreviewConnectionModel(data.artists) : null // todo map favorite artist onto artist until refactor on graphql
    };
}
