import { h } from "preact";
import { useState, useEffect, useLayoutEffect, useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { Banner } from "./Banner";
import { useTranslations } from "global/config";
import type { RootModel } from "models/app";
import { BannerWarning } from "models/app";
import { DelayType, useDelay } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { showMaintenanceModal } from "components/organisms/modal/modals/MaintenanceModal";

export const AppBanner = () => {
    const banner = useBottomBanner();
    const latest = useLatestBanner(banner);
    const { render, open } = useBannerOpen(banner);

    if (!render || !latest) return null;

    switch (latest) {
        case BannerWarning.MaintenanceBanner:
            return <MaintenanceBanner open={open} />;
        case BannerWarning.NoConnection:
            return <NoConnectionBanner open={open} />;
    }
};

interface Props {
    open: boolean;
}

const MaintenanceBanner = ({ open }: Props) => {
    const message = useSelector((root: RootModel) => root.notifications.maintenanceBannerData?.AppMaintenanceBannerTitle) ?? "";
    const translations = useTranslations();
    const button = (
        <Button design={ButtonDesign.TextInlineMedium} onClick={showMaintenanceModal}>
            {translations.MaintenanceBannerReadMore}
        </Button>
    );
    return <Banner message={message} open={open} button={button} />;
};

const NoConnectionBanner = ({ open }: Props) => {
    const translations = useTranslations();
    return <Banner message={translations.ErrorBannerNoNetworkExternal} open={open} />;
};

function bannerWarningPriority(warning: BannerWarning): number {
    switch (warning) {
        case BannerWarning.MaintenanceBanner:
            return 0;
        case BannerWarning.NoConnection:
            return 1;
    }
}

export function useBottomBanner(): BannerWarning | null {
    const banners = useSelector((root: RootModel) => root.notifications.banners);
    const sorted = useMemo(() => banners.sort((a, b) => bannerWarningPriority(a) - bannerWarningPriority(b)), [banners]);
    return sorted[0] ?? null;
}

function useLatestBanner(warning: BannerWarning | null) {
    const [latest, setLatest] = useState<BannerWarning | null>(null);

    useEffect(() => {
        if (warning != null) setLatest(warning);
    }, [warning]);

    return latest;
}

function useBannerOpen(warning: BannerWarning | null) {
    const [hasWarning, setHasWarning] = useState(false);
    const [closedDelay, setClosedDelay] = useState(false);

    useLayoutEffect(() => {
        setHasWarning(!!warning);
    }, [warning]);

    useEffect(() => {
        setClosedDelay(true);
        if (hasWarning) return;
        const timeOutId = window.setTimeout(() => setClosedDelay(false), 5000);
        return () => window.clearTimeout(timeOutId);
    }, [hasWarning]);

    const render = closedDelay || hasWarning;
    const open = useDelay({ delay: { type: DelayType.TimeOut, msec: 0 }, reset: render }) && hasWarning;

    return { render, open };
}
