import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castGetStatus(): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castGetStatus", new chrome.cast.media.GetStatusRequest(), (request, success, error) => {
        media.getStatus(request, success, error);
    });
}
