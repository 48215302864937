import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { dispatch } from "global";
import { SET_MAXIPLAYER_OPEN } from "global/actions";
import { useTranslations } from "global/config";
import { MobileMaxiPlayerOpen } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { TrackRadioModel } from "models/domain/TrackRadioModel";
import { ContentType } from "models/ModelType";
import { useLinkContext } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";

interface Props {
    trackRadio: TrackRadioModel;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const GoToTrackRadioLink = ({ showIcon, trackRadio, context }: Props) => {
    const translations = useTranslations();
    const link = useLinkContext(ContentType.TrackRadio, trackRadio, context);
    const click = useCallback(() => {
        dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });
    }, []);
    return (
        <Link closeOnClick link={link} icon={showIcon ? IconName.Sound : undefined} onClick={click}>
            {translations.ContextOptionGoToTrackRadioAction}
        </Link>
    );
};
