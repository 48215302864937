import { logQueueActionEvent } from "./logQueueActionEvent";
import { getRepeatStatusAnalyticsKey } from "../properties/event/queueActionProperties";
import type { QueueActionType } from "../properties/event/queueActionProperties";
import { store } from "global";
import { debounce } from "services/cancellation/debounce";

const debounceMs = 200;

function logRepeatChangeEvent() {
    const { repeat } = store.getState().player;
    const type: QueueActionType = getRepeatStatusAnalyticsKey(repeat);
    logQueueActionEvent(type);
}

export const tryLogRepeatChangeEvent = debounce(logRepeatChangeEvent, debounceMs)[0];
