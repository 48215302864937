import type { FirebaseApp } from "firebase/app";
import type { RemoteConfig as FirebaseRemoteConfig, Value as FirebaseValue } from "firebase/remote-config";
import { getAppMaintenanceEnvKey } from "./getAppMaintenanceEnvKey";
import { getEmptyMaintenanceConfig } from "./getEmptyMaintenanceConfig";
import { dispatch } from "global";
import { NOTIFICATION_BANNER_TOGGLE, NOTIFICATION_MAINTENANCE_DATA_UPDATE } from "global/actions";
import { replaceAllBackwardsCompatible } from "services/formatters";
import { getOrCreateFbApp } from "services/logger/initFBLoggerService";
import { LogTag, DefaultLogMessage, log } from "services/logger/initLoggerService";
import { BannerWarning } from "models/app";

export enum Environment {
    Local = "Local",
    Dev = "Dev",
    Test = "Test",
    Stage = "Stage",
    Prod = "Prod"
}
export interface MaintenanceData {
    AppMaintenanceBannerTitle: string;
    AppMaintenanceLinkTitle: string;
    AppMaintenanceLinkUrl: string;
    AppMaintenancePopupText: string;
    AppMaintenancePopupTitle: string;
}
export interface MaintenanceConfig extends MaintenanceData {
    WebMaintenanceEnabled: boolean;
}

type FirebaseGetRemoteConfig = (app?: FirebaseApp) => FirebaseRemoteConfig;
type FirebaseGetValue = (remoteConfig: FirebaseRemoteConfig, key: string) => FirebaseValue;
type FirebaseFetchAndActivate = (remoteConfig: FirebaseRemoteConfig) => Promise<boolean>;

const getFbRemoteConfig: () => FirebaseRemoteConfig | null = () => {
    const app = getOrCreateFbApp();
    if (!app) {
        log.warn({ code: "web-210902-1351", msg: "blocked" });
        return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fb_GetRemoteConfig: FirebaseGetRemoteConfig | null = (<any>window).trackingFbase?.getRemoteConfig ?? null;

    if (!fb_GetRemoteConfig) {
        log.warn({ code: "web-220429-1651", msg: "blocked" });
        return null;
    }

    const fbRemoteConfig = fb_GetRemoteConfig(app);
    if (!fbRemoteConfig) {
        log.error({ code: "web-230427-1537", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    return fbRemoteConfig;
};

const remoteConfigUpdateIntervalMs = 5 * 60 * 1000;

function getEnvironment(fbRemoteConfig: FirebaseRemoteConfig): Environment | null {
    const host = window.location.host;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fb_GetValue: FirebaseGetValue | null = (<any>window).trackingFbase?.getValue ?? null;
    if (!fb_GetValue) {
        log.warn({ code: "web-220429-1654", msg: "blocked" });
        return null;
    }

    const match = (key: string) => {
        let domainStr = fb_GetValue(fbRemoteConfig, key).asString();
        if (domainStr === "") {
            log.warn({ code: "web-220927-1103", msg: "no domain - empty string", data: { key } });
            return false;
        }
        if (domainStr == null) {
            log.warn({ code: "web-220927-1104", msg: "no domain - null", data: { key } });
            return false;
        }

        domainStr = replaceAllBackwardsCompatible(domainStr, "http://", "");
        domainStr = replaceAllBackwardsCompatible(domainStr, "https://", "");

        const match = host == domainStr;

        log.debug([LogTag.RemoteConfig], () => ({
            code: "web-220804-1230",
            msg: `match`,
            data: {
                key,
                domainStr,
                host
            }
        }));

        return match;
    };

    if (match("WebLocalDomain")) return Environment.Local;
    if (match("WebDevDomain")) return Environment.Dev;
    if (match("WebTestDomain")) return Environment.Test;
    if (match("WebStageDomain")) return Environment.Stage;
    if (match("WebProdDomain")) return Environment.Prod;

    return null;
}

function getMaintenanceConfig(fbRemoteconfig: FirebaseRemoteConfig): MaintenanceConfig {
    if (!fbRemoteconfig) return getEmptyMaintenanceConfig();

    const environment = getEnvironment(fbRemoteconfig);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fb_GetValue: FirebaseGetValue | null = (<any>window).trackingFbase?.getValue ?? null;
    if (fb_GetValue == null) {
        log.warn({ code: "web-230427-1541", msg: "blocked" });
        return getEmptyMaintenanceConfig();
    }
    const MaintenanceEnabledKey = getAppMaintenanceEnvKey(environment);

    log.debug([LogTag.RemoteConfig], () => ({
        code: "web-220804-1232",
        msg: `get maintenance banner enabled key`,
        data: { key: MaintenanceEnabledKey }
    }));

    return {
        WebMaintenanceEnabled: MaintenanceEnabledKey == null ? false : fb_GetValue(fbRemoteconfig, MaintenanceEnabledKey).asBoolean(),
        AppMaintenanceBannerTitle: fb_GetValue(fbRemoteconfig, "AppMaintenanceBannerTitle").asString(),
        AppMaintenanceLinkTitle: fb_GetValue(fbRemoteconfig, "AppMaintenanceLinkTitle").asString(),
        AppMaintenanceLinkUrl: fb_GetValue(fbRemoteconfig, "AppMaintenanceLinkUrl").asString(),
        AppMaintenancePopupText: fb_GetValue(fbRemoteconfig, "AppMaintenancePopupText").asString(),
        AppMaintenancePopupTitle: fb_GetValue(fbRemoteconfig, "AppMaintenancePopupTitle").asString()
    };
}
const setMaintenanceConfig = (maintenanceConfig: MaintenanceConfig) => {
    log.debug([LogTag.RemoteConfig], () => ({ code: "web-220804-1233", msg: `config`, data: { config: maintenanceConfig } }));

    const data: MaintenanceData = {
        AppMaintenanceBannerTitle: maintenanceConfig.AppMaintenanceBannerTitle,
        AppMaintenanceLinkTitle: maintenanceConfig.AppMaintenanceLinkTitle,
        AppMaintenanceLinkUrl: maintenanceConfig.AppMaintenanceLinkUrl,
        AppMaintenancePopupText: maintenanceConfig.AppMaintenancePopupText,
        AppMaintenancePopupTitle: maintenanceConfig.AppMaintenancePopupTitle
    };

    dispatch({ type: NOTIFICATION_MAINTENANCE_DATA_UPDATE, payload: { data } });
    dispatch({
        type: NOTIFICATION_BANNER_TOGGLE,
        payload: {
            show: maintenanceConfig.WebMaintenanceEnabled,
            warning: BannerWarning.MaintenanceBanner
        }
    });
};

async function fetchGeneralConfigAndRun() {
    const fbRemoteConfig = getFbRemoteConfig();

    if (!fbRemoteConfig) return null;

    fbRemoteConfig.settings.minimumFetchIntervalMillis = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchAndActivate: FirebaseFetchAndActivate | null = (<any>window).trackingFbase?.fetchAndActivate ?? null;

    if (!fetchAndActivate) {
        log.warn({ code: "web-220429-1652", msg: "blocked" });
        return null;
    }

    try {
        await fetchAndActivate(fbRemoteConfig);

        const maintenanceConfig = getMaintenanceConfig(fbRemoteConfig);
        setMaintenanceConfig(maintenanceConfig);
    } catch (e) {
        if (navigator.onLine) log.error({ code: "web-210903-1135", msg: "error when fetching config", error: e });
        return null;
    }
}

export function initRemoteConfigService() {
    try {
        const run = () => {
            fetchGeneralConfigAndRun();
            setTimeout(run, remoteConfigUpdateIntervalMs);
        };
        run();
    } catch (e) {
        log.error({ code: "web-210903-1130", msg: "error in switch", error: e });
    }
}
