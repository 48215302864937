import { h } from "preact";
import { Modal, modalButtonSubmitClose, openModal } from "..";
import { dispatch } from "global";
import { NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING } from "global/actions";
import { useTranslations } from "global/config";

export async function showPwaInstallMessageModal() {
    return openModal(<PwaInstallMessageModal />);
}

export function PwaInstallMessageModal() {
    const translations = useTranslations();
    const submit = async () => {
        dispatch({ type: NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING, payload: { waiting: false } });
        return true;
    };

    return (
        <Modal buttons={[modalButtonSubmitClose(submit)]} className="pwaInstallMessageModal" onRequestClose={submit} title={translations.MessagesPwaInstallModalTitleWeb}>
            <p>{translations.MessagesPwaInstallModalTextWeb}</p>
        </Modal>
    );
}
