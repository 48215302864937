import { h } from "preact";
import "./PlayerTrackControl.scss";
import { ResourceCard } from "../resource";
import { TestLocator } from "global/constants";
import { useLoginState } from "services/user";
import { LoginState } from "models/app";
import { ContentType } from "models/ModelType";
import { useCurrentLanePrimaryResourceFromState, useSectionContext, useCurrentAudioSecondaryResourceFromState, useOnRightClickContextMenu } from "components/shared/hooks";
import { usePreviewContext } from "components/shared/hooks/useContext";
import { ButtonFavorite } from "components/atoms/controls/button/buttons/ButtonFavorite";
import { ButtonMore } from "components/atoms/controls/button/buttons/ButtonMore";
import { CoverRounding, CoverSize } from "components/atoms/cover";
import { PlayableLoadSpinner } from "components/atoms/spinner";

export const DesktopPlayerTrackControl = () => {
    const { resource, resourceType } = useCurrentAudioSecondaryResourceFromState();
    const { resource: imageResource, type: imageType } = useCurrentLanePrimaryResourceFromState();

    const sectionContext = useSectionContext(ContentType.Player, null, null, null, null);
    const context = usePreviewContext(resourceType, resource, sectionContext, null);
    const imageContext = usePreviewContext(imageType, imageResource, sectionContext, null);

    const { onContextMenu } = useOnRightClickContextMenu(context, resource, sectionContext);
    const loginStateIsKnown = useLoginState() !== LoginState.Unknown;

    return (
        <div className="playerTrackControl rmB">
            <ResourceCard
                disableLazyLoad
                size={CoverSize.Size96}
                rounding={CoverRounding.None}
                imageContext={imageContext}
                context={context}
                onContextMenu={onContextMenu}
                scrollingText={true}>
                <PlayableLoadSpinner />
            </ResourceCard>
            {resource && resource.contentType !== ContentType.LiveRadioPlayable && (
                <ButtonFavorite favorite={resource} context={context} testLocator={TestLocator.PlayerFavoriteButton} disabled={!loginStateIsKnown} />
            )}
            {resource && <ButtonMore resource={resource} context={context} testLocator={TestLocator.PlayerMoreButton} />}
        </div>
    );
};
