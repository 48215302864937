/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, h } from "preact";
import { useContext } from "preact/hooks";
import { ResourceMenuTop } from "./tops";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import {
    AddMultipleToLastQueueLink,
    AddMultipleToQueueReplaceLink,
    AddMultipleToMyMusicLink,
    AddMultipleToPlaylistLink,
    AddMultipleToQueueLink,
    RemoveMultipleFromPlaylistLink
} from "../links";
import type { Multiselect } from "services/selection";
import type { PreviewContextModel } from "models/app/resourceContextModel";

interface Props {
    multiselect: Multiselect;
    context: PreviewContextModel;
}

export const TracksContextMenu = ({ multiselect, context }: Props) => {
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    const pageContext = context.section.page;
    const page = pageContext?.resource;
    //const contentEditorMode = environment.contentEditorMode;

    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <AddMultipleToMyMusicLink multiselect={multiselect} context={context} showIcon={showIcon} />
            <RemoveMultipleFromPlaylistLink multiselect={multiselect} context={context} showIcon={showIcon} />
            <AddMultipleToPlaylistLink multiselect={multiselect} context={context} showIcon={showIcon} />
            <AddMultipleToQueueLink multiselect={multiselect} context={context} showIcon={showIcon} />
            <AddMultipleToLastQueueLink multiselect={multiselect} context={context} showIcon={showIcon} />
            <AddMultipleToQueueReplaceLink multiselect={multiselect} context={context} showIcon={showIcon} />
        </Sheet>
    );
};
