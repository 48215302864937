import { h } from "preact";
import { ResourceLinkTestLocator } from "global/constants";

export enum ResourceTextDesign {
    PrimarySmall = "primarySmall",
    PrimarySmall2Lines = "primarySmall2Lines",
    SecondarySmall = "secondarySmall",
    SecondarySmall2Lines = "secondarySmall2Lines",
    PrimaryBig = "primaryBig",
    SecondaryBig = "secondaryBig",
    MaxiPlayerPrimary = "maxiPlayerPrimary",
    MaxiPlayerSecondary = "maxiPlayerSecondary"
}

interface Props {
    children: string | undefined;
    className?: string;
    design?: ResourceTextDesign;
    testLocatorName?: number;
    disabled?: boolean;
}

export const ResourceTextPart = ({ children, className, design, testLocatorName, disabled = false }: Props) => {
    if (children == null) return null;
    return (
        <div
            className={`resourceTextPart orh ${className ?? ""} --design-${design ?? ""} --disabled-${disabled}`}
            data-cy={testLocatorName ? ResourceLinkTestLocator(testLocatorName) : undefined}>
            {children}
        </div>
    );
};
