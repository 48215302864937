import type { PlayQueuePlayableModelInterface, ConnectionModel, TrackPreviewModel } from ".";
import { createMyMusicTrackPreviewConnectionModel } from ".";
import { translate } from "global/config";
import type { FavoriteTracksQuery, FavoriteTracksSorting } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type MyMusicTracksData = FavoriteTracksQuery["me"]["favorites"];

export type MyMusicTracksModel = MyMusicTracksPlayModel;
export type MyMusicTracksPlayModel = ({ type: DomainModelType.Play } & MyMusicTracks) | MyMusicTracksPageModel;
export type MyMusicTracksPageModel = { type: DomainModelType.Page } & MyMusicTracks;

interface MyMusicTracks extends PlayQueuePlayableModelInterface {
    readonly title: string;
    readonly orderBy: FavoriteTracksSorting;
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
    readonly contentType: ContentType.MyMusicTracks; //TODO: rename?
}

function createMyMusicTracks(data: MyMusicTracksData, orderBy: FavoriteTracksSorting): MyMusicTracks {
    return {
        graphType: data.__typename,
        id: "myMusicTracks",
        title: translate("MyMusicTracksTitle"), //todo: rename?
        cover: null,
        orderBy,
        trackCount: null,
        contentType: ContentType.MyMusicTracks,
        tracks: data.tracks ? createMyMusicTrackPreviewConnectionModel(data.tracks) : null
    };
}
//TODO: look into merging this
export function createMyMusicTracksPlayModel(data: MyMusicTracksData, orderBy: FavoriteTracksSorting): MyMusicTracksPlayModel {
    return {
        ...createMyMusicTracks(data, orderBy),
        type: DomainModelType.Play
    };
}

export function createMyMusicTracksPageModel(data: MyMusicTracksData, orderBy: FavoriteTracksSorting): MyMusicTracksPageModel {
    return {
        ...createMyMusicTracks(data, orderBy),
        type: DomainModelType.Page
    };
}
