import { fetchTrackUrl } from ".";
import { trackStreamError } from "../error";
import { getValidTrackUrl } from "../hls";
import { inSkipDebounce } from "../skipDebounce";
import { dispatch } from "global";
import { AUDIO_INPUT_AUDIO_SRC_CHANGE } from "global/actions";
import { DefaultLogMessage, log, LogTag } from "services/logger";
import { getAudioInputItemByPredicateFromState } from "services/player/inputs/service/helpers";
import { AudioInputType } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { AudioInputItemModel, StreamUrl } from "models/app/player/input";
import type { BrowserAudioItemModel } from "models/app/player/output";
import { BrowserAudioLoadState } from "models/app/player/output";

export const loadUrl = async (browserAudio: BrowserAudioItemModel) => {
    if (inSkipDebounce) return;
    if (browserAudio.disposed) return;
    if (!browserAudio.loadNow) return;

    if (browserAudio.urlLock) return;
    browserAudio.urlLock = true;

    await _loadUrl(browserAudio);

    browserAudio.urlLock = false;
};

async function _loadUrl(browserAudio: BrowserAudioItemModel) {
    if (browserAudio.url) return;

    const audio = getAudioInputItemByPredicateFromState((item) => item.audioId === browserAudio.audioId);
    if (audio == null) {
        log.error({ code: "web-210120-1408", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }
    if (audio.input !== AudioInputType.PlayQueue) return;

    browserAudio.state = BrowserAudioLoadState.LoadUrl;

    log.debug([LogTag.Playback], () => ({
        code: "web-211216-1358",
        msg: `browserAudio state: LoadUrl, title: ${browserAudio.logTitle}`
    }));

    const validTrackUrlResult = await getValidTrackUrl(browserAudio);

    const fetchTrackUrlResults = validTrackUrlResult.url ? null : await fetchTrackUrl(audio.trackId, audio.sample, browserAudio.logTitle);
    const resultUrl = validTrackUrlResult.url ?? fetchTrackUrlResults?.url ?? null;
    if (resultUrl) await dispathUrl(audio, resultUrl);
    else await trackStreamError(browserAudio, validTrackUrlResult.message, fetchTrackUrlResults?.message ?? "");
}

async function dispathUrl(audio: AudioInputItemModel, url: StreamUrl | null) {
    audio.url2 = url;

    await dispatch({
        type: AUDIO_INPUT_AUDIO_SRC_CHANGE,
        payload: {
            audio,
            context: {
                action: AudioContextAction.AudioLoadTrackStream,
                trace: null
            }
        }
    });
}
