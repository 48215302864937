import { logPlayerActionEvent } from "./logPlayerActionEvent";
import { PlayState } from "models/app/player";
import { getAudioPropertiesFromState } from "components/shared/hooks";

export function logPlayPauseToEvent() {
    const { playState } = getAudioPropertiesFromState();

    switch (playState) {
        case PlayState.Playing:
        case PlayState.Buffering: {
            logPlayerActionEvent("Pause");
            break;
        }
        case PlayState.Error:
        case PlayState.Stopped:
        case PlayState.Paused: {
            logPlayerActionEvent("Play");
            break;
        }
    }
}
