import { hasCastSession } from "../setup";
import { stopCastSession } from "./stopCastSession";
import { log, LogTag } from "services/logger";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";
import { AudioContextAction } from "models/app/player/AudioContext";

export function resumeTimeoutCheck() {
    if (!isControllerChromecastFromState()) return;
    if (hasCastSession()) return;

    setTimeout(() => {
        if (!isControllerChromecastFromState()) return;
        if (hasCastSession()) return;

        log.warn({ tags: [LogTag.Chromecast], code: "web-211029-1319", msg: `session not resumed in time` });

        stopCastSession({ action: AudioContextAction.ChromecastResumeTimeoutOnPageLoad, trace: null });
    }, 5000);
}
