import { h } from "preact";
import { Icon, IconName, IconSize } from "components/atoms/icon";
import "./CheckBoxButton.scss";

interface Props {
    className?: string;
    checked?: boolean;
    onChange?: () => void;
    name?: string;
}

export const CheckBoxButton = ({ className, checked, onChange, name }: Props) => {
    return (
        <div className={`checkBoxButton NAx ${className ?? ""} --checked-${checked}`}>
            <input checked={checked} type="checkbox" name={name} onChange={onChange} />
            <Icon size={IconSize.Small} icon={IconName.Checkmark16} />
        </div>
    );
};
