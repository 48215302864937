import { getAnalyticsPlayerActionEvent } from "../playerAction";
import type { PlayerActionType } from "../properties/event/playerActionProperties";
import { dispatch } from "global";
import { LOGGING_EVENT_ANALYTICS } from "global/actions";
import { getDesktopQueueOpen, getSecondsPlayedFromState } from "components/shared/hooks";

export async function logPlayerActionEvent(type: PlayerActionType, queueOpen?: boolean, volumeLevel?: number) {
    if (queueOpen == undefined) queueOpen = getDesktopQueueOpen();

    const { secondsPlayed, duration } = getSecondsPlayedFromState();

    const data = await getAnalyticsPlayerActionEvent({
        actionType: type,
        actionSource: "DesktopPlayer",
        IsViewingQueue: queueOpen,
        trackDuration: duration,
        trackProgress: secondsPlayed,
        volumeLevel
    });
    dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "PlayerAction", data } });
}
