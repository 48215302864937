import { onCustomCastMessageAuthenticate } from "../castEvents/session/onCustomCastMessageAuthenticate";
import { onCastMediaSession } from "../castEvents/session/onMediaSession";
import { SessionMessage } from "../models";
import { addCastMediaSessionListeners } from "./castMediaSessionListeners";
import { addPlayerControllerListeners, removePlayerControllerListeners } from "./playerControllerListeners";
import { log, LogTag } from "services/logger/initLoggerService";

let _hasSession = false;

export function hasCastSession() {
    return _hasSession;
}

export function tryGetCastSession() {
    if (!_hasSession) return null;
    return cast.framework.CastContext.getInstance().getCurrentSession();
}

export function getCastSession() {
    const session = tryGetCastSession();
    if (!session) log.error({ code: "web-230106-1622", msg: "cc session is null" });
    return session;
}

export function hasCastMediaSession() {
    return !!tryGetCastMediaSession();
}

export function tryGetCastMediaSession() {
    if (!_hasSession) return null;

    const media = getCastSession()?.getMediaSession() ?? null;
    return media;
}

export function getCastMediaSession() {
    const media = tryGetCastMediaSession();
    if (!media) log.error({ code: "web-230106-1622", msg: "cc media session is null" });
    return media;
}

export async function addCastSession() {
    log.debug([LogTag.Chromecast], () => ({ code: "web-210714-1150", msg: `add cast session listeners` }));

    if (_hasSession) {
        log.error({ code: "web-20230105-1352", msg: "session already exist" });
        return;
    }

    _hasSession = true;

    addSessionListeners();
    addPlayerControllerListeners();
}

export function removeCastSession() {
    log.debug([LogTag.Chromecast], () => ({ code: "web-210714-1150", msg: `remove cast session listeners` }));

    removeSessionListeners();
    removePlayerControllerListeners();

    _hasSession = false;
}

function addSessionListeners() {
    const session = getCastSession();
    if (!session) return;

    session.addMessageListener(SessionMessage.Authenticate, onCustomCastMessageAuthenticate);
    session.addEventListener(cast.framework.SessionEventType.MEDIA_SESSION, onCastMediaSession);

    const media = tryGetCastMediaSession();
    if (media) addCastMediaSessionListeners(media);
}

function removeSessionListeners() {
    const session = getCastSession();
    if (!session) return;

    session.removeMessageListener(SessionMessage.Authenticate, onCustomCastMessageAuthenticate);
    session.removeEventListener(cast.framework.SessionEventType.MEDIA_SESSION, onCastMediaSession);
}
