import { h } from "preact";
import type { StateUpdater } from "preact/hooks";
import { getLinks, getComparableId } from "services/resource";
import type { DropProps, DragProps } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import { createPreviewContext } from "models/app/resourceContextModel";
import type { NotificationConversion, ResourcePreviewModel } from "models/domain";
import { CoverSize } from "components/atoms/cover";
import type { PreviewAction } from "components/molecules/preview";
import { PreviewDisplayType } from "components/molecules/preview";
import { ResourcePreviewSquare } from "components/molecules/preview/squares";

export interface RowSharedProps {
    context: SectionContextModel;
    labelCount: number;
    isHighlight?: (id: string, index: number) => boolean;
    setLabelCount: StateUpdater<number>;
    getDropProps?: (model: ResourcePreviewModel, index: number) => DropProps;
    columns: number;
    onAction: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    dragProps: DragProps;
}

interface Props {
    shared: RowSharedProps;
    rowIndex: number;
    resources: ResourcePreviewModel[] | null;
}
export function GridRow({ shared, resources, rowIndex }: Props): h.JSX.Element {
    const { context, isHighlight, setLabelCount, dragProps, getDropProps, onAction, columns } = shared;

    const mapper = (resource: ResourcePreviewModel, i: number): h.JSX.Element => {
        const previewContext = createPreviewContext(resource.contentType, resource, context, i);
        const links = getLinks(resource.contentType, resource, previewContext.section, PreviewDisplayType.Square); // todo: temporary solution, preview context should be moved up one level and links set directly on it
        if (links.length > shared.labelCount) {
            shared.labelCount = links.length;
            setLabelCount(links.length);
        }

        const itemIndex = rowIndex * columns + i;
        const highlight = isHighlight?.(resource.id, itemIndex) ?? false;

        return (
            <ResourcePreviewSquare
                context={context}
                dragProps={dragProps}
                dropProps={getDropProps?.(resource, i) ?? null}
                dynamicSize
                highlight={highlight}
                key={getComparableId(resource)}
                onAction={onAction}
                resource={resource}
                size={CoverSize.Size320}
                rank={itemIndex}
                disableLazyLoad
            />
        );
    };

    return <div className="row">{resources?.map(mapper)}</div>;
}
