import { h } from "preact";
import { useCallback, useRef, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import "./VolumeExpandButton.scss";
import { dispatch } from "global";
import { PLAYER_SET_VOLUME, PLAYER_TOGGLE_VOLUME_EXPAND } from "global/actions";
import { getVolumeIcon } from "services/player/controllers/service/helpers/icons";
import type { RootModel } from "models/app";
import { usePageClick } from "components/shared/hooks/usePageClick";
import { BasicRange } from "components/atoms/controls/basicRange";
import { Button } from "components/atoms/controls/button";

export enum ExpandDirection {
    Up = "up",
    Down = "down"
}

interface Props {
    direction: ExpandDirection;
}

export const VolumeExpandButton = ({ direction }: Props) => {
    const volume = useSelector((root: RootModel) => root.player.volume);
    const muted = useSelector((root: RootModel) => root.player.muted);
    const volumeLevel = muted ? 0 : volume * 100;

    const [open, setOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    usePageClick(() => open && setOpen(false), ref);

    const toggleOpen = useCallback(() => {
        setOpen(!open);
        dispatch({ type: PLAYER_TOGGLE_VOLUME_EXPAND });
    }, [open]);
    const setVolume = useCallback((percent: number) => dispatch({ type: PLAYER_SET_VOLUME, payload: { volume: percent / 100 } }), []);

    return (
        <div className={`volumeExpandButton YlS --open-${open}`}>
            <div className={`rangeContainer --direction-${direction}`} ref={ref}>
                <div className="background" />
                <BasicRange value={volumeLevel} onChange={setVolume} />
            </div>
            <Button onClick={toggleOpen} icon={getVolumeIcon(volume, muted)} round />
        </div>
    );
};
