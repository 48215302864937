import { h } from "preact";
import { useRef } from "preact/compat";
import { useEffect, useState } from "preact/hooks";
import "./BannerSingle.scss";
import { usePreviewScrollToHighlight } from "..";
import { getThemeFromState } from "components/app/hooks";
import { PreviewSingleTestLocator } from "global/constants";
import type { SectionContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel, ResourceContentType } from "models/domain";
import { useLinkContext, useOnRightClickContextMenu, usePreviewContext } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { CoverSize, ResourceCover, getPlaceholderPath } from "components/atoms/cover";

interface Props {
    children: ResourcePreviewModel | null;
    resourceType: ResourceContentType;
    highlight: boolean;
    section: SectionContextModel;
}

export const BannerSingle = ({ children, resourceType, section, highlight }: Props) => {
    const preview = usePreviewContext(resourceType, children, section, 0);

    const ref = useRef<HTMLDivElement>(null);
    usePreviewScrollToHighlight({ ref, highlight });

    const { isLoaded } = useImagePreload(preview.resource?.cover ?? null);

    const image = isLoaded
        ? <ResourceCover disabled={false} size={CoverSize.Size80} context={preview} disabledAction={true} disableLazyLoad={false} dynamicSize />
        : <div className="bannerPreview" style={{ backgroundImage: `url(${getPlaceholderPath(getThemeFromState())})` }}>
            <br />
        </div>;

    const primaryLink = useLinkContext(resourceType, children, preview);

    if (preview.resource?.contentType && preview.resource?.contentType !== "BannerLayoutItemContent") return null;

    return (
        <div class="bannerSingle k49">
            <Button
                ref={ref}
                link={primaryLink ?? undefined}
                design={ButtonDesign.PlayableRow}
                disabled={false}
                className={`--type-${resourceType} --highlight-${highlight} --loaded-${isLoaded}`}
                data-cy={PreviewSingleTestLocator(resourceType)}>
                <figure>
                    {image}
                </figure>
            </Button>
        </div>
    );
};

function useImagePreload(url: string | null) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (url) {
            const img = document.createElement("img");
            img.onload = () => setIsLoaded(true);
            img.onerror = () => setIsError(true);
            img.src = url;
        }
    }, [url])

    return { isLoaded, isError };
}