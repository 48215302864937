import { useSelector } from "react-redux";
import { getSimulateTemporaryTokenRefreshError } from "./debug";
import { dispatch, messageBus, store, waitForState } from "global";
import { headerNames, getAppVersionString } from "shared/services";
import { USER_CHANGED, USER_HAS_BAD_TOKENS, USER_LOGGED_IN, USER_LOGGED_OUT, USER_LOGGING_OUT, USER_PROFILES_CHANGED } from "global/actions";
import { getInstallationId } from "services/app";
import { ensureOwnTokenSession } from "services/appSession";
import { getTokensNow, getTokens, removeTokens, setTokenRefreshLock, saveTokens } from "services/appSession/operations/sessionTokens";
import { getSharedTokens, removeSharedTokens } from "services/appSession/operations/sharedTokens";
import type { UserIdModel } from "services/backend";
import { getUser, getBackendBaseUrl, apiTokenPath, getUserProfiles } from "services/backend";
import { addEnvironmentVariablesToUrl } from "services/environment";
import { log, LogTag, trySendPlaybackReports } from "services/logger";
import { LogoutTrigger } from "services/logger/analytics/properties/event";
import { tryStopPlayAll } from "services/player/inputs/service/actions";
import { ConcurrentEntryGuard, getUrlParameter } from "services/utils";
import type { RootModel } from "models/app";
import { LoginState } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { showErrorModal2, showLoginErrorModal } from "components/organisms/modal/modals";
import equal from "fast-deep-equal";
import { addQueryParameterToUrl } from "services/urlHelper";
import { Feature, isFeatureEnabled, translate } from "global/config";
import { openToast } from "components/organisms/toast";
import { ProfileChangedToast } from "components/organisms/toast/toasts";

const entryGuardForRefreshToken = new ConcurrentEntryGuard<RefreshTokenResult>();

//#region Helpers

export function getUserIdFromState(): string | null {
    return store.getState().user.id;
}

export async function getIsLoggedInFromState(): Promise<boolean> {
    await waitForState((store) => store.getState().user.state !== LoginState.Unknown);
    return store.getState().user.state === LoginState.LoggedIn;
}

export function getIsLoggedInFromStateImmediate(): boolean {
    return store.getState().user.state === LoginState.LoggedIn;
}

export const shouldDisplayAsLoggedIn = (state: LoginState): boolean => {
    switch (state) {
        case LoginState.LoggedIn:
        case LoginState.Unknown:
        case LoginState.TemporaryError:
            return true;
        case LoginState.LoggingOut:
        case LoginState.LoggedOut:
            return false;
    }
};

export function useLoginState() {
    return useSelector((root: RootModel) => root.user.state);
}

export function useDisplayLoggedIn() {
    const loggedIn = useSelector((root: RootModel) => root.user.state);
    return shouldDisplayAsLoggedIn(loggedIn);
}

export function getPermissionsFromToken() {
    const { accessToken } = getTokensNow();
    if (accessToken) {
        const perm = getUrlParameter("perm", accessToken);
        if (perm) {
            return perm.split(";");
        }
    }
    return [];
}

export function hasPermissionFromToken(perm: "cd" | "prof") {
    return getPermissionsFromToken().includes(perm);
}

export function getCurrentProfile() {
    return store.getState().user.profiles?.find(n => n.current) ?? null;
}

export function getCurrentProfileId() {
    return store.getState().user.profiles?.find(n => n.current)?.id ?? null;
}

//#endregion

enum TokenStatusCode {
    Ok = 0,
    CantGetUrl = 1,
    CantExchangeCode = 2,
    NoPlayRights = 3,
    FatalError = 4,
    TemporaryError = 5
}

interface RefreshTokenResult {
    ok: boolean;
    accessToken: string | null;
}

type FetchRefreshTokenResult = {
    tokenResult?: {
        access_token: string;
        refresh_token: string;
        expires_in?: number;
    };
    status: TokenStatusCode;
};

type FetchRefreshTokenResultExt = FetchRefreshTokenResult & {
    correlationId: string | null;
};

async function reestablishSessionOnPageload() {
    const user = store.getState().user;
    const tokens = await getTokens();

    let userResult: UserIdModel | null = null;

    // we dont have tokens, so go to logged-out state
    if (!tokens.refreshToken) {
        if (user.id) {
            // if we had a user, but something maliciously deleted the tokens, we log out fully
            log.error({ code: "web-220801-0830", msg: "no tokens on pageload" });
            logout(LogoutTrigger.Automatic);
        } else {
            // otherwise we just note that we aren't logged in
            dispatch({ type: USER_LOGGED_OUT });
        }
        return;
    }

    // we have tokens so lets validate them
    if (tokens.accessToken) {
        userResult = (await getUser()).model;
        if (userResult) {
            setUserStateIfChanged(userResult);
            return;
        }
    }

    await tryRefreshToken();
}

export async function establishSessionFromLogin(refreshToken: string, accessToken: string) {
    ensureOwnTokenSession();
    saveTokens(refreshToken, accessToken, "login");

    const userResult = (await getUser()).model;
    if (!userResult?.id) {
        logout(LogoutTrigger.Automatic);
        return;
    }

    dispatch({
        type: USER_LOGGED_IN,
        payload: {
            id: userResult.id, // todo: followup and make graphql give these as nonnull
            trackingId: userResult.trackingId ?? "",
            username: userResult.username ?? null,
            profiles: userResult.profiles,
            profileDefaultColors: userResult.profileDefaultColors,
            profilesNumberLimit: userResult.profilesNumberLimit,
            isNewUser: true
        }
    });
}

export async function onGqlUnauthenticated(): Promise<{ ok: boolean; accessToken: string | null }> {
    const tokens = await getTokens();
    const hasUserId = !!getUserIdFromState();

    if (!tokens.refreshToken) {
        log.error({ code: "web-220731-2001", msg: "user has no refreshToken but requesting private resources" });
        logout(LogoutTrigger.Automatic);
        return { ok: false, accessToken: null };
    }

    if (!hasUserId) {
        log.error({ code: "web-220731-1959", msg: "user not logged in but requesting private resources" });
    }

    return await tryRefreshToken();
}

export function tryRefreshToken(forceValidate = false): Promise<RefreshTokenResult> {
    return entryGuardForRefreshToken.call(async () => {
        let result = await getRefreshTokenResultFromOtherSession();
        if (!result) result = await getRefreshTokenResultFromBackend();
        const handleOK = await handleTokenResult(result, forceValidate);

        return handleOK ?? { ok: false, accessToken: null };
    });
}

async function handleTokenResult(result: FetchRefreshTokenResultExt, forceValidate = false): Promise<RefreshTokenResult | null> {
    try {
        const oldTokens = getTokensNow();
        const { status, tokenResult, correlationId } = result;
        const user = store.getState().user;
        const oldProfileId = getCurrentProfileId();
        const validateProfile = hasPermissionFromToken("prof") && isFeatureEnabled(Feature.Profiles) && oldProfileId;

        switch (result.status) {
            case TokenStatusCode.Ok: {
                const accessToken = tokenResult?.access_token;
                if (!accessToken) {
                    log.error({ code: "web-220819-1419", msg: "no access token user after successful refresh" });
                    break;
                }

                const tokenUserId = getUrlParameter("user_id", accessToken);
                const userChanged = tokenUserId !== user.id;

                if (userChanged || forceValidate || !user.id) {
                    const userResult = (await getUser()).model;
                    if (!userResult) {
                        log.error({ code: "web-220819-1455", msg: "no user after successful refresh" });
                        break;
                    }
                    await setUserStateIfChanged(userResult);
                } else {
                    await dispatch({
                        type: USER_LOGGED_IN,
                        payload: {
                            id: user.id,
                            username: user.username ?? null,
                            trackingId: user.trackingId ?? "",
                            profiles: user.profiles,
                            profileDefaultColors: user.profileDefaultColors,
                            profilesNumberLimit: user.profilesNumberLimit,
                            isNewUser: false
                        }
                    });
                }

                const currentProfile = getCurrentProfile();
                if (validateProfile && currentProfile && oldProfileId != currentProfile?.id) {
                    openToast(ProfileChangedToast(currentProfile.title));
                }

                log.info({ code: "web-220819-1455", msg: "successful refresh of token" });
                return { ok: true, accessToken };
            }
            case TokenStatusCode.TemporaryError: {
                log.info({ code: "web-030711-1258", msg: "temporary error while trying to refresh token" });
                dispatch({ type: USER_HAS_BAD_TOKENS });
                break;
            }
            case TokenStatusCode.FatalError: {
                logoutNow(LogoutTrigger.Automatic);
                break;
            }
            case TokenStatusCode.NoPlayRights: {
                // todo: show a "your subscription has ended" modal
                logoutNow(LogoutTrigger.NoMusicRights);
                break;
            }
            default: {
                // our tokens are permanently bad, i.e. we'll never log in with them
                log.info({ code: "web-030711-1259", msg: "fatal error while trying to refresh of token" });
                logoutNow(LogoutTrigger.Automatic);
                break;
            }
        }

        log.error({
            code: "web-220112-1230",
            msg: "unable to refresh token",

            data: {
                refreshToken: oldTokens.refreshToken ?? "",
                accessToken: oldTokens.accessToken ?? "",
                status: status && TokenStatusCode[status]
            },

            correlationId: correlationId ?? undefined
        });
    } catch (e) {
        log.error({ code: "web-220819-1515", msg: "error in _tryRefreshToken", error: e });
    }

    return { ok: false, accessToken: null };
}

async function getRefreshTokenResultFromOtherSession(): Promise<FetchRefreshTokenResultExt | null> {
    const { accessToken: oldAccessToken } = getTokensNow();
    const { refreshToken: newRefreshToken, accessToken: newAccessToken } = await getTokens();

    if (newAccessToken && newRefreshToken && oldAccessToken !== newAccessToken) {
        log.info({ code: "web-220824-1122", msg: "other tab refreshed tokens succesfully" });
        return {
            tokenResult: {
                access_token: newAccessToken,
                refresh_token: newRefreshToken
            },
            correlationId: null,
            status: TokenStatusCode.Ok
        };
    }
    return null;
}

async function getRefreshTokenResultFromBackend(profileId?: string): Promise<FetchRefreshTokenResultExt> {
    const tokens = await getTokens();
    if (!tokens.refreshToken) {
        log.error({ code: "web-220819-1455", msg: "no refresh token before fething tokens from backend" });
        return { status: TokenStatusCode.TemporaryError, correlationId: null };
    }

    setTokenRefreshLock(true);
    const result = await fetchRefreshedTokenFromBackend(profileId ?? getUserIdFromState() ?? undefined);
    if (result.status === TokenStatusCode.Ok) {
        const tokenResult = result.tokenResult;
        if (tokenResult) {
            saveTokens(tokenResult.refresh_token, tokenResult.access_token, "refresh");
        } else {
            log.error({ code: "web-220819-1455", msg: "recieved no tokens after successful refresh" });
            result.status = TokenStatusCode.TemporaryError;
        }
    }

    setTokenRefreshLock(false);
    return result;
}

async function fetchRefreshedTokenFromBackend(profileId?: string): Promise<FetchRefreshTokenResultExt> {
    if (getSimulateTemporaryTokenRefreshError()) return { status: TokenStatusCode.TemporaryError, correlationId: null };

    const { refreshToken } = getTokensNow();
    let correlationId: string | null = null;

    let url = addEnvironmentVariablesToUrl(getBackendBaseUrl(apiTokenPath));
    if (profileId && hasPermissionFromToken("prof") && isFeatureEnabled(Feature.Profiles)) {
        url = addQueryParameterToUrl(url, "profileId", profileId);
    }

    try {
        const res = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                [headerNames.contentType]: "application/json",
                [headerNames.appVersion]: getAppVersionString(),
                [headerNames.installationId]: getInstallationId()
            },
            body: JSON.stringify({
                refresh_token: refreshToken
            })
        });

        correlationId = res.headers.get("Correlation-Id") ?? null; // todo: why isnt this x-correlation-id? its what backend sends though

        if (res.ok) {
            const data = (await res.json()) as FetchRefreshTokenResult;
            return {
                ...data,
                correlationId
            };
        }
    } catch (error) {
        log.error({ code: "web-220819-1524", msg: "error when fetching refresh token from backend", error });
    }

    return {
        correlationId,
        status: TokenStatusCode.TemporaryError
    };
}

export function logout(trigger: LogoutTrigger) {
    const user = store.getState().user;
    if (user.state === LoginState.LoggingOut || user.state === LoginState.LoggedOut) {
        log.error({ code: "web-220825-1251", msg: "already logged out" });
        return;
    }

    log.info({ code: "web-220825-1452", msg: "log out" });

    const { refreshTokenDate } = getTokensNow();

    dispatch({
        type: USER_LOGGING_OUT,
        payload: {
            id: user.id ?? "",
            trackingId: user.trackingId ?? "",
            trigger,
            refreshTokenDate
        }
    });
}

export function logoutNow(trigger: LogoutTrigger) {
    ensureOwnTokenSession();
    removeTokens();
    logout(trigger);
}

async function setUserStateIfChanged(userResult: UserIdModel | null) {
    const user = store.getState().user;

    const newId = userResult?.id;
    const oldId = user.id;
    const oldState = user.state;

    if (!newId && (oldId || oldState !== LoginState.LoggedOut)) {
        logout(LogoutTrigger.Automatic);
        return;
    }

    if (newId && (newId !== oldId || userResult.username !== user.username || oldState !== LoginState.LoggedIn)) {
        await dispatch({
            type: USER_LOGGED_IN,
            payload: {
                id: newId,
                trackingId: userResult.trackingId ?? "",
                username: userResult.username ?? null,
                profiles: userResult.profiles,
                profileDefaultColors: userResult.profileDefaultColors,
                profilesNumberLimit: userResult.profilesNumberLimit,
                isNewUser: userResult.id !== getUserIdFromState()
            }
        });
    }
    else if (newId && !equal(user.profiles, userResult.profiles)) {
        await dispatch({
            type: USER_PROFILES_CHANGED,
            payload: {
                profiles: userResult.profiles,
                profileDefaultColors: userResult.profileDefaultColors,
                profilesNumberLimit: userResult.profilesNumberLimit,
                currentProfileChanged: user.profiles.find(n => n.current)?.id !== userResult.profiles.find(n => n.current)?.id
            }
        });
    }
}

export async function changeUserProfile(profileId: string) {

    // todo: implement locking and multi-session handling etc

    const user = store.getState().user;
    const res = await getRefreshTokenResultFromBackend(profileId);
    const ok = !!res?.tokenResult?.access_token;
    if (ok) {
        const result = await getUser();
        const activeProfile = result.model?.profiles?.find(n => n.current);

        if (result.success && result.model?.id && activeProfile) {
            if (activeProfile.id === profileId) {
                openToast(ProfileChangedToast(activeProfile.title));
            } else {
                console.log(result.model)
                const profiles = await getUserProfiles();
                const exists = profiles.model?.profiles.some(n => n.id == profileId);
                if (exists) {
                    showErrorModal2(translate("SwitchProfileFailsTitle"), translate("SwitchProfileFailsSubtitle"));
                } else {
                    showErrorModal2(translate("SwitchProfileFailsTitle"), translate("SwitchProfileDeletedSubtitle"));
                }
            }

            if (result.model.id !== user.id) {
                dispatch({
                    type: USER_LOGGED_IN,
                    payload: {
                        id: result.model.id,
                        trackingId: result.model.trackingId ?? "",
                        username: result.model.username ?? null,
                        profiles: result.model.profiles,
                        profileDefaultColors: result.model.profileDefaultColors,
                        profilesNumberLimit: result.model.profilesNumberLimit,
                        isNewUser: true
                    }
                });
            }
            else {
                await dispatch({
                    type: USER_PROFILES_CHANGED,
                    payload: {
                        profiles: result.model.profiles,
                        profileDefaultColors: result.model.profileDefaultColors,
                        profilesNumberLimit: result.model.profilesNumberLimit,
                        currentProfileChanged: user.profiles.find(n => n.current)?.id !== result.model.profiles.find(n => n.current)?.id
                    }
                });
            }
            return true;
        }
    }

    showErrorModal2(translate("SwitchProfileFailsTitle"), translate("SwitchProfileFailsSubtitle"));
    return false;
}

export async function reloadProfiles() {
    const user = store.getState().user;
    const result = await getUser();
    if (result.success && result.model) {
        await dispatch({
            type: USER_PROFILES_CHANGED,
            payload: {
                profiles: result.model.profiles,
                profileDefaultColors: result.model.profileDefaultColors,
                profilesNumberLimit: result.model.profilesNumberLimit,
                currentProfileChanged: user.profiles.find(n => n.current)?.id !== result.model.profiles.find(n => n.current)?.id
            }
        });
    }
}

export const initUserService = async () => {
    try {
        messageBus.subscribeEvery(USER_LOGGING_OUT, async () => {
            tryStopPlayAll({ action: AudioContextAction.UserLogOut, trace: null });
            await trySendPlaybackReports();
            log.info({ code: "web-220927-0958", msg: "--- logging out", tags: [LogTag.User] });
            setTimeout(() => {
                // todo: technical debt.
                // the reason this is in a timeout is because analytics for logout has some async stuff that isnt easily synchronized with this.
                // lets find a clean way to make one wait for the other; ideally by awaiting some dispatch and having this sync on the messagebus,
                // but it could so be having the analytics fetch some of its data in two steps,
                // or a mechanism so we can register promises after USER_LOGGING_OUT that have to resolve before this USER_LOGGING_OUT handler continues
                ensureOwnTokenSession();
                removeTokens();
                dispatch({ type: USER_LOGGED_OUT });
            }, 500);
        });
    } catch (e) {
        log.error({ code: "web-220222-1322", msg: "error in initiating user service", error: e });
    }

    await reestablishSessionOnPageload();
    setupUserChangedEvent();

    const loginErrorEode = getUrlParameter("loginerrorcode");
    if (loginErrorEode) {
        // wait until the rest of the page has had time to initialize
        setTimeout(() => {
            showLoginErrorModal(parseInt(loginErrorEode, 10));
        }, 500);
    }
};

// Executed early to retrieve tokens that were given to us on the querystring or in shared localstorage during login.
export async function initLoginFromPageLoad() {
    const initLoginFromQueryString = async () => {
        const refresh = getUrlParameter("refresh");
        const access = getUrlParameter("access");
        if (refresh && access) {
            await establishSessionFromLogin(refresh, access);
            document.location.href = "/";
        }
    };

    const initLoginFromSharedLocalStorage = async () => {
        const { refreshToken, accessToken } = getSharedTokens();
        if (refreshToken && accessToken) await establishSessionFromLogin(refreshToken, accessToken);
        removeSharedTokens();
    };

    try {
        await initLoginFromQueryString();
        await initLoginFromSharedLocalStorage();
    } catch (e) {
        log.error({ code: "web-220218-1425", msg: "init login from page load", error: e });
    }
}

// Convenience event for when the user changes. This will not be raised when the application is reloaded and the user already is logged in.
const setupUserChangedEvent = async () => {
    await waitForState((store) => store.getState().user.state !== LoginState.Unknown);
    lastLoginState = store.getState().user.state;
    lastUserId = store.getState().user.id;

    messageBus.subscribeEvery(USER_LOGGED_IN, async (msg) => {
        if (lastLoginState !== LoginState.Unknown && msg.payload.id !== lastUserId) {
            dispatch({
                type: USER_CHANGED,
                payload: {
                    isLoggedIn: true,
                    wasLoggedIn: lastLoginState === LoginState.LoggedIn,
                    newId: msg.payload.id,
                    oldId: lastUserId
                }
            });
        }
        lastLoginState = LoginState.LoggedIn;
        lastUserId = msg.payload.id;
    });

    messageBus.subscribeEvery(USER_LOGGED_OUT, async () => {
        if (lastLoginState !== LoginState.LoggedOut && lastLoginState !== LoginState.LoggingOut) {
            dispatch({
                type: USER_CHANGED,
                payload: {
                    isLoggedIn: false,
                    wasLoggedIn: true,
                    newId: null,
                    oldId: lastUserId
                }
            });
            lastLoginState = LoginState.LoggedOut;
            lastUserId = null;
        }
    });
};

let lastLoginState: LoginState = "unknown" as LoginState;
let lastUserId: string | null = null;
