import { ContentType, DomainModelType } from "models/ModelType";
import { ResourceModelLinkInterface } from "./ResourceModel";

export interface ExternalLinkModel extends ResourceModelLinkInterface {
    readonly contentType: ContentType.ExternalLink;
    type: DomainModelType.Preview;
    externalLink: string;
    cover?: string;
    target?: string;
}

export function createExternalLinkModel(externalLink: string, target: string | undefined = "_blank"): ExternalLinkModel {
    return {
        id: externalLink,
        type: DomainModelType.Preview,
        contentType: ContentType.ExternalLink,
        externalLink,
        target
    };
}
