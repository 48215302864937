import { getAnalyticsSettingChangedEvent } from "../events";
import { convertThemeSetting } from "../properties/app/convertThemeSetting";
import { getAnalyticsChangeSettingProperties } from "../properties/event";
import type { SetThemeSettingAction } from "global/actions";
import type { AnalyticsKey } from "global/config";

export async function getChangeSettingThemeSettingData(msg: SetThemeSettingAction) {
    const { theme, shortcut } = msg.payload;
    const value: AnalyticsKey = convertThemeSetting(theme);
    const themeSettingData = getAnalyticsChangeSettingProperties("ThemeSetting", value, shortcut);
    const data = await getAnalyticsSettingChangedEvent(themeSettingData);
    return data;
}
