import { useSelector } from "react-redux";
import { useContextMenus } from "components/app/hooks";
import { RootModel } from "models/app";

export enum AppLayer {
    Page = "Page",
    ContextMenu = "ContextMenu",
    Modal = "Modal"
}

export function useActiveAppLayer(): AppLayer {
    const isContextMenuOpen = useContextMenus().isOpen;
    const isModalOpen = useSelector((root: RootModel) => root.modal.open !== null);

    const active = isContextMenuOpen ? AppLayer.ContextMenu : isModalOpen ? AppLayer.Modal : AppLayer.Page;
    return active;
}
