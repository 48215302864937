import { stopCastSession } from "../../actions";
import { castSessionResumed } from "../../actions/castSessionResumed";
import { castSessionStarted } from "../../actions/castSessionStarted";
import { sessionStartFailed } from "../../error";
import { isUnloading } from "global/config";
import { log, LogTag } from "services/logger";
import { AudioContextAction } from "models/app/player/AudioContext";

export function onSessionStateChange(data: cast.framework.SessionStateEventData) {
    if (isUnloading) return;

    const state = data.sessionState;

    log.debug([LogTag.Chromecast], () => ({ code: "web-230105-1442", msg: `onSessionStateChange: ${state}` }));

    switch (state) {
        case cast.framework.SessionState.NO_SESSION: {
            stopCastSession({ action: AudioContextAction.ChromecastNoSession, trace: null });
            return null;
        }
        case cast.framework.SessionState.SESSION_STARTING: {
            return null;
        }
        case cast.framework.SessionState.SESSION_STARTED: {
            castSessionStarted({ action: AudioContextAction.ChromecastSessionStarted, trace: null });
            return null;
        }
        case cast.framework.SessionState.SESSION_START_FAILED: {
            stopCastSession({ action: AudioContextAction.ChromecastSessionStartFailed, trace: null });
            sessionStartFailed();
            return null;
        }
        case cast.framework.SessionState.SESSION_ENDING:
            stopCastSession({ action: AudioContextAction.ChromecastSessionSessionEnding, trace: null });
            return null;
        case cast.framework.SessionState.SESSION_ENDED:
            stopCastSession({ action: AudioContextAction.ChromecastSessionSessionEnded, trace: null });
            return null;
        case cast.framework.SessionState.SESSION_RESUMED: {
            castSessionResumed({ action: AudioContextAction.ChromecastSessionResumed, trace: null });
            return null;
        }
    }
    state;
}
