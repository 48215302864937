import { getNotificationFeedFromState, markNotificationFeedAsSeen } from "./helpers";
import { dispatch } from "global";
import { NOTIFICATION_FEED_SET_NOTIFICATIONS } from "global/actions";
import { getNotifications, getNotificationsStatus } from "services/backend";
import { DefaultLogMessage, log } from "services/logger";
import { getIsLoggedInFromState } from "services/user";
import type { NotificationPreviewModel } from "models/domain/NotificationModel";
import { createEmptyNotificationsModel, fetchPlayableForNotification } from "models/domain/NotificationModel";

let lock = false;

export async function pollNotificationsState() {
    if (!await getIsLoggedInFromState()) {
        return;
    }

    if (lock) {
        log.error({ code: "web-220613-1544", msg: "trying to update notifications multiple times at once" });
        return;
    }
    lock = true;

    try {
        const feed = getNotificationFeedFromState(false);
        if (!feed) return;

        const statusResult = await getNotificationsStatus();
        if (!statusResult.model) {
            return;
        }

        await dispatch({
            type: NOTIFICATION_FEED_SET_NOTIFICATIONS,
            payload: {
                notSeenCount: statusResult.model.notSeenCount,
                notifications: null,
                notificationState: null
            }
        });
    } catch (e) {
        log.error({ code: "web-220915-1335", msg: "error in updateNotifications", error: e });
    }
    lock = false;
}

export async function fetchAndMarkNotifications() {
    const result = (await getNotifications({ first: 50, after: null }));
    if (!result.model) return;

    const items = result.model.notifications?.items;
    if (!items || result.error) {
        log.error({ code: "web-220613-1506", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    if (items.length === 0) {
        await dispatch({
            type: NOTIFICATION_FEED_SET_NOTIFICATIONS,
            payload: {
                notifications: [createEmptyNotificationsModel()],
                notificationState: result.model.notificationState || null,
                notSeenCount: 0
            }
        });
        return;
    }

    const withPlayables =
        (await Promise.all(items.map(fetchPlayableForNotification)))
            .filter((item): item is NotificationPreviewModel => !!item);

    await dispatch({
        type: NOTIFICATION_FEED_SET_NOTIFICATIONS,
        payload: {
            notifications: withPlayables,
            notificationState: result.model.notificationState || null,
            notSeenCount: 0
        }
    });

    if (result.model.notSeenCount) {
        markNotificationFeedAsSeen();
    }
}