import * as idbKeyVal from "idb-keyval";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";

const get = async (key: IDBValidKey, store: idbKeyVal.UseStore) => {
    return idbKeyVal.get(key, store).catch((e: unknown) => log.error({ code: "web-091221-1147", msg: DefaultLogMessage.UnexpectedValue, error: e }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getMany = async (keys: IDBValidKey[], store: idbKeyVal.UseStore): Promise<unknown[]> => {
    return idbKeyVal.getMany(keys, store).catch((e: unknown) => {
        log.error({ code: "web-091221-1148", msg: DefaultLogMessage.UnexpectedValue, error: e });
        return [];
    });
};

const set = async (key: IDBValidKey, data: unknown, store: idbKeyVal.UseStore) => {
    return idbKeyVal.set(key, data, store).catch((e: unknown) => log.error({ code: "web-091221-1149", msg: DefaultLogMessage.UnexpectedValue, error: e }));
};

const setMany = async (entries: [IDBValidKey, unknown][], store: idbKeyVal.UseStore) => {
    return idbKeyVal.setMany(entries, store).catch((e: unknown) => {
        log.error({ code: "web-091221-1150", msg: "error setting entries in db", data: { entries }, error: e });
    });
};

const getAll = async (store: idbKeyVal.UseStore): Promise<[IDBValidKey, unknown][]> => {
    try {
        const keys = await idbKeyVal.keys(store);
        const values = await getMany(keys, store);

        const keyValues: [IDBValidKey, unknown][] = [];
        values.forEach((data, index) => {
            const key = keys[index];
            keyValues.push([key, data]);
        });

        return keyValues;
    } catch (e) {
        log.error({ code: "web-211210-1705", msg: "error in getAll", error: e });
        return [];
    }
};

export const getConfiguredCache = (databaseName: string) => {
    const { store, database } = createDatabaseAndStore(databaseName, "state");

    return {
        get: (key: IDBValidKey) => get(key, store),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        set: (key: IDBValidKey, val: any) => set(key, val, store),
        setMany: (entries: [IDBValidKey, unknown][]) => setMany(entries, store),
        getAll: () => getAll(store),
        del: (key: IDBValidKey) => idbKeyVal.del(key, store),
        clear: () => idbKeyVal.clear(store),
        keys: () => idbKeyVal.keys(store),
        deleteDatabase: async () => closeAndDeleteDatabase(await database),
        database
    };
};

async function closeAndDeleteDatabase(database: IDBDatabase): Promise<void> {
    database.close();
    await deleteDatabase(database.name);
}

async function deleteDatabase(name: string): Promise<void> {
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(name);
        request.onsuccess = () => resolve();
        request.onerror = () => reject();
    });
}

function createDatabaseAndStore(dbName: string, storeName: string) {
    const request = indexedDB.open(dbName);
    request.onupgradeneeded = () => request.result.createObjectStore(storeName);
    const dbp = idbKeyVal.promisifyRequest(request) as Promise<IDBDatabase>;

    const store = (txMode: IDBTransactionMode, callback: (store: IDBObjectStore) => void) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dbp.then((db) => callback(db.transaction(storeName, txMode).objectStore(storeName))) as any;

    return { store, database: dbp };
}

// function databaseExists(dbname: string): Promise<boolean> {
//     return new Promise((resolve) => {
//         const req = indexedDB.open(dbname);
//         let existed = true;
//         req.onsuccess = () => {
//             req.result.close();
//             if (!existed) indexedDB.deleteDatabase(dbname);
//             resolve(existed);
//         };
//         req.onupgradeneeded = () => {
//             existed = false;
//         };
//     });
// }

// setTimeout(async () => {
//     testCreateAndDeleteDatabase();
// }, 5000);

// async function testCreateAndDeleteDatabase() {
//     const cache = getConfiguredCache("test4");

//     await cache.set("hej", "hej");
//     await cache.del("hej");

//     alert("created");

//     setTimeout(async () => {
//         await cache.deleteDatabase();

//         alert("test db deleted");
//         alert("check: " + (await databaseExists("test4")));
//     }, 5000);
// }
