import type { LayoutItemSectionModel } from "./LayoutItemModel";
import type { ResourceModelInterface } from "..";
import type { LayoutItemGroupConnectionModel } from "../ConnectionModel";
import { createLayoutPageGroupConnectionModel } from "../ConnectionModel";
import { DefaultLogMessage, log } from "services/logger";
import type { LayoutPageFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

export interface LayoutPageModel extends ResourceModelInterface {
    type: DomainModelType.Page;
    contentType: ContentType.LayoutPage;
    groups: LayoutItemGroupConnectionModel | null;
    title: string;
}

export function createLayoutPageModel(data: LayoutPageFragment): LayoutPageModel {
    return {
        graphType: data.__typename,
        type: DomainModelType.Page,
        contentType: ContentType.LayoutPage,
        id: data.id,
        cover: null,
        groups: data.groups ? createLayoutPageGroupConnectionModel(data.groups, data.id) : null,
        title: data.title
    };
}

export function createLayoutPagePreviewModel(id: string, title: string): LayoutPageModel {
    return {
        graphType: "Layout",
        type: DomainModelType.Page,
        contentType: ContentType.LayoutPage,
        id,
        cover: null,
        groups: null,
        title
    };
}

export function fillLayoutPageWithItemSection(page: LayoutPageModel, item: LayoutItemSectionModel) {
    if (item.id == null) {
        log.error({ code: "web-211018-1554", msg: DefaultLogMessage.UnexpectedNull });
        return page;
    }

    if (page.groups?.items == null) {
        log.error({ code: "web-211018-1551", msg: DefaultLogMessage.UnexpectedNull });
        return page;
    }

    const index = getGroupAndItemIndex(page, item.id);
    if (index == null) {
        log.error({ code: "web-211018-1556", msg: DefaultLogMessage.UnexpectedNull });
        return page;
    }

    const newPage = { ...page };

    if (newPage.groups?.items == null) {
        log.error({ code: "web-211018-1559", msg: DefaultLogMessage.UnexpectedNull });
        return page;
    }

    newPage.groups = { ...newPage.groups };
    newPage.groups.items = [...newPage.groups.items];
    const newGroup = newPage.groups.items[index.groupIndex];

    if (newGroup.items?.items == null) {
        log.error({ code: "web-211018-1559", msg: DefaultLogMessage.UnexpectedNull });
        return page;
    }

    newGroup.items = { ...newGroup.items };
    newGroup.items.items = [...newGroup.items.items];
    newGroup.items.items[index.itemIndex] = item;
    return newPage;
}

function getGroupAndItemIndex(page: LayoutPageModel, itemId: string): { groupIndex: number; itemIndex: number } | null {
    const groups = page.groups?.items;
    if (!groups) return null;

    let groupIndex = 0;
    for (const group of groups) {
        const items = group.items?.items;
        if (items) {
            let itemIndex = 0;
            for (const item of items) {
                if (item.id === itemId) {
                    return { groupIndex, itemIndex };
                }
                itemIndex++;
            }
        }
        groupIndex++;
    }
    return null;
}
