import { h } from "preact";
import { useEffect } from "preact/hooks";
import "./Soundbars.scss";
import type { PlayablePreviewModel } from "models/domain";
import { usePlayablePropertiesFromState, useRefUpdate, useTabIsActive } from "components/shared/hooks";

export enum SoundbarState {
    Playing,
    Paused
}

export enum SoundbarStyle {
    CoverOverlay = "coverOverlay",
    Default = "default"
}

interface SoundbarsProps {
    state: SoundbarState;
    style: SoundbarStyle;
}

export const Soundbars = ({ state, style }: SoundbarsProps) => {
    const ref = useRefUpdate<SVGSVGElement>();

    const isTabActive = useTabIsActive();

    useEffect(() => {
        const svg = ref.current;
        if (svg != null) {
            state === SoundbarState.Playing ? svg.unpauseAnimations() : svg.pauseAnimations();
        }
    }, [state, ref, isTabActive]);

    return (
        <div className={`soundbars kh6 --style-${style} --animation-${state === SoundbarState.Playing}`}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ref={ref}>
                <line x1="5" x2="5" y1="8" y2="20" stroke="currentColor" strokeWidth="2" strokeLinecap="round">
                    <animate attributeName="y1" values="8;4;8;4;8" dur="1.2s" repeatCount="indefinite" />
                </line>
                <line x1="12" x2="12" y1="4" y2="20" stroke="currentColor" strokeWidth="2" strokeLinecap="round">
                    <animate attributeName="y1" values="4;12;4;8;4" dur="1.2s" repeatCount="indefinite" />
                </line>
                <line x1="19" x2="19" y1="8" y2="20" stroke="currentColor" strokeWidth="2" strokeLinecap="round">
                    <animate attributeName="y1" values="8;4;12;7;8" dur="1.2s" repeatCount="indefinite" />
                </line>
            </svg>
        </div>
    );
};

export interface PlayableSoundbarsProps {
    playable: PlayablePreviewModel;
    style: SoundbarStyle;
}

export const PlayableSoundbars = ({ playable, style }: PlayableSoundbarsProps) => {
    const { isPlaying } = usePlayablePropertiesFromState(playable);
    const state = isPlaying ? SoundbarState.Playing : SoundbarState.Paused;
    return <Soundbars state={state} style={style} />;
};
