import { updatePlayQueue } from "./updatePlayQueue";
import { dispatch, store } from "global";
import { PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE, PLAYQUEUE_ENDLESSPLAY_UPDATE } from "global/actions";
import { playerConfig } from "global/constants";
import { getMeEndlessPlay } from "services/backend";
import { getAnalyticsQueueIntentPropertiesGroup, createQueueAddedAnalyticsData } from "services/logger/analytics/properties/groups";
import { createNormalizedData } from "services/normalizeData";
import { getTrackPlayablePreviews } from "services/playable";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { QueueMode, QueueTrackModel } from "models/app/player/input";
import { createQueueTrackModel, QueueLane } from "models/app/player/input";
import { createPageContext, createSectionContext, createPreviewContext } from "models/app/resourceContextModel";
import { ResourceDisplayType } from "models/domain";
import type { EndlessPlayModel } from "models/domain/EndlessPlayModel";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";

export async function updateEndlessPlay(context: AudioContextModel) {
    const queue = store.getState().queue;
    if (queue.mode === QueueMode.MixRadio) return;

    const tracks = queue.playQueue;
    const existingTrackId = queue.endlessPlayTrackId;
    const newTrackId = tracks[tracks.length - 1]?.track.id ?? null;

    if (newTrackId === existingTrackId) return;

    dispatch({ type: PLAYQUEUE_ENDLESSPLAY_TRACK_ID_UPDATE, payload: { trackId: newTrackId } });
    if (newTrackId == null) {
        dispatch({ type: PLAYQUEUE_ENDLESSPLAY_UPDATE, payload: { endlessPlay: null, context } });
        return;
    }

    const stateTrackId = store.getState().queue.endlessPlayTrackId;
    if (newTrackId !== stateTrackId) return;
    const endlessPlay = (await getMeEndlessPlay({ trackId: newTrackId, first: playerConfig.endlessPlayLength })).model;
    if (endlessPlay?.tracks.items.length === 0) {
        // TODO: sometimes endless play is empty from backend, but UI does not handle this
        return;
    }

    dispatch({ type: PLAYQUEUE_ENDLESSPLAY_UPDATE, payload: { endlessPlay, context } });
}

interface SetEndlessPlayProps {
    endlessPlay: EndlessPlayModel | null;
    context: AudioContextModel;
}

export async function addEndlessPlayToQueue({ endlessPlay, context }: SetEndlessPlayProps) {
    let { queue } = store.getState();

    const pageContext = createPageContext({ type: ContentType.EndlessPlay, resource: endlessPlay, root: NavigationPageRootName.EndlessPlay, done: true });
    const sectionContext = createSectionContext(ContentType.EndlessPlay, endlessPlay, pageContext, 0, ResourceDisplayType.List);
    const previewContext = createPreviewContext(ContentType.EndlessPlay, endlessPlay, sectionContext, null);

    if (endlessPlay) {
        const tracks = getTrackPlayablePreviews(endlessPlay.tracks.items, endlessPlay);
        const queueIntentData = getAnalyticsQueueIntentPropertiesGroup(previewContext);
        const addData = createQueueAddedAnalyticsData(queueIntentData, endlessPlay);
        const queueAddData = createNormalizedData(addData);

        const newEndlessPlay: QueueTrackModel[] = tracks.map((track) => createQueueTrackModel(track, QueueLane.Preview, null, queueAddData.referenceId));

        queue = { ...queue, endlessPlay: newEndlessPlay };
        await updatePlayQueue(queue, context, false, queueAddData, [endlessPlay]);
    } else {
        queue = { ...queue, endlessPlay: [] };
        await updatePlayQueue(queue, context, false, null);
    }
}

export async function moveEndlessPlayToFlowLane(context: AudioContextModel) {
    if (!isControllerChromecastFromState()) return;
    const queue = store.getState().queue;
    if (queue.mode === QueueMode.MixRadio) return;

    const endlessPlayOn = queue.isEndlessPlayOn;
    if (!endlessPlayOn) return;

    const tracks = queue.playQueue;
    if (!tracks.length) return;

    const endlessPlay = queue.endlessPlay;
    if (!endlessPlay.length) return;

    const currentIndex = queue.playQueue.findIndex((track) => track.lane === QueueLane.Current);
    if (currentIndex === -1) return;

    const isEnd = currentIndex >= queue.playQueue.length - 1;
    if (!isEnd) return;

    const add = endlessPlay.map((item) => ({ ...item, lane: QueueLane.Flow }));
    const newTracks = [...tracks, ...add];

    const newQueue = { ...queue, playQueue: newTracks, endlessPlay: [] };

    await updatePlayQueue(newQueue, context, false, null);
}
