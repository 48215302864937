import { useState, useEffect, useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { store } from "global";
import type { RootModel } from "models/app";
import { Theme, ThemeSetting } from "models/view/Theme";

function useOsTheme(): Theme {
    const [theme, setTheme] = useState(Theme.Light);

    const darkChange = () => {
        const newTheme = getOsTheme();
        setTheme(newTheme);
    };

    useEffect(() => {
        darkChange();

        const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
        darkQuery.addEventListener("change", darkChange, { passive: true });

        return () => darkQuery.removeEventListener("change", darkChange);
    }, []);

    return theme;
}

export function useTheme(): Theme {
    const setting = useSelector((root: RootModel) => root.ui.theme);
    const os = useOsTheme();

    return useMemo(() => getTheme(setting, os), [setting, os]);
}

export function getOsTheme(): Theme {
    const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const theme = darkQuery.matches ? Theme.Dark : Theme.Light;
    return theme;
}

export function getThemeSettingFromState(): ThemeSetting {
    const setting = store.getState().ui.theme;
    return setting;
}

export function getTheme(setting: ThemeSetting, osTheme: Theme): Theme {
    switch (setting) {
        case ThemeSetting.Light:
            return Theme.Light;
        case ThemeSetting.Dark:
            return Theme.Dark;
        case ThemeSetting.Auto:
            return osTheme;
    }
}

export function getThemeFromState(): Theme {
    const setting = getThemeSettingFromState();
    const os = getOsTheme();

    return getTheme(setting, os);
}
