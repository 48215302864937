import type { Action } from "../actions";
import {
    CONTEXT_MENU_CLOSE_ALL,
    CONTEXT_MENU_OPEN,
    CONTEXT_MENU_CLOSE,
    CONTEXT_MENU_UPDATE_POSITION,
    CONTEXT_MENU_HOVER,
    CONTEXT_MENU_SET_VISIBLE,
    NAVIGATION_NAVIGATED
} from "global/actions";
import { OpenMenuResultAction } from "services/contextMenus/open";
import { closeAllContextMenus, openContextMenu, closeContextMenuSheet, onNavigated } from "services/contextMenus/reducers";
import { hoverContextMenuItem } from "services/contextMenus/reducers/hoverContextMenuItem";
import { setVisibleContextMenu } from "services/contextMenus/reducers/setVisibleContextMenu";
import { updateContextMenuPosition } from "services/contextMenus/reducers/updateContextMenuPosition";
import type { AppContextMenuModel } from "models/view/contextMenu";

const initialState: AppContextMenuModel = { open: null, visible: [], hovers: [], closeOnNavigation: true };

export const contextMenuReducer: (state: AppContextMenuModel | undefined, action: Action) => AppContextMenuModel = (state = initialState, action) => {
    switch (action.type) {
        case CONTEXT_MENU_OPEN:
            return openContextMenu(state, action.payload.sheet, action.payload.closeOnNavigation);
        case CONTEXT_MENU_CLOSE:
            return closeContextMenuSheet(state, action.payload.sheet, action.payload.result);
        case CONTEXT_MENU_CLOSE_ALL:
            return closeAllContextMenus(state, { action: OpenMenuResultAction.Cancel });
        case CONTEXT_MENU_UPDATE_POSITION:
            return updateContextMenuPosition(state, action.payload.item, action.payload.position);
        case CONTEXT_MENU_HOVER:
            return hoverContextMenuItem(state, action.payload.itemId, action.payload.on);
        case CONTEXT_MENU_SET_VISIBLE:
            return setVisibleContextMenu(state, action.payload.sheet, action.payload.visible);
        case NAVIGATION_NAVIGATED:
            return onNavigated(state);
    }
    return state;
};
