export enum SortOption
{
    Name = "Name",
    ReleaseDate = "ReleaseDate",
    Popularity = "Popularity",
    UserDefined = "UserDefined",
    ArtistName = "ArtistName",
    LatestAdded = "LatestAdded"
}

export interface SortOptionsModel
{
    options: SortOption[];
    selected: SortOption;
    onChange: (option: SortOption) => void;
}

