import type { Action } from "../actions";
import { PLAYER_PREVIOUS } from "../actions";
import {
    AUDIO_INPUT_TYPE_CHANGE,
    LIVERADIO_SET_AND_PLAY,
    PLAYER_REPEAT,
    PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE,
    PLAYER_SET_MUTE,
    PLAYER_SET_VOLUME,
    PLAYER_SHUFFLE,
    PLAYQUEUE_ENDLESSPLAY_TOGGLE,
    PLAYQUEUE_SET_AND_PLAY,
    REHYDRATE
} from "global/actions";
import { getNextRepeatStatus, getNextShuffleState, getShuffleStateWhenSetAndPlay } from "services/player/inputs/inputs/playQueue/helpers";
import type { PlayerModel } from "models/app/player";
import { AudioInputType, PlayerOutputType, AudioControllerType } from "models/app/player";
import { ContentType } from "models/ModelType";
import { RepeatStatus, ShuffleState } from "models/view";

const initialState: PlayerModel = {
    repeat: RepeatStatus.None,
    volume: 1,
    muted: false,
    shuffle: ShuffleState.Off,
    controller: AudioControllerType.Local,
    input: AudioInputType.PlayQueue,
    output: PlayerOutputType.Browser,
    restoreVolume: null,
    version: 1
};

export function playerReducer(state: PlayerModel = initialState, action: Action): PlayerModel {
    switch (action.type) {
        case PLAYER_SET_VOLUME: {
            const { volume } = action.payload;
            return { ...state, volume };
        }
        case PLAYER_SET_MUTE: {
            const muted = action.payload?.mute ?? !state.muted;
            return { ...state, muted };
        }
        case PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE: {
            const { controller, output } = action.payload;

            let volume = state.volume;
            let restoreVolume = state.restoreVolume;

            if (output != state.output) {
                if (output !== PlayerOutputType.Browser) restoreVolume = volume;
                if (output === PlayerOutputType.Browser && restoreVolume != null) volume = restoreVolume;
            }

            return { ...state, controller, output, volume, restoreVolume };
        }
        case PLAYQUEUE_ENDLESSPLAY_TOGGLE: {
            if (action.payload.on) {
                return { ...state, repeat: RepeatStatus.None };
            }
            return state;
        }
        case PLAYER_PREVIOUS: {
            const repeat = state.repeat === RepeatStatus.One ? RepeatStatus.None : state.repeat;
            return { ...state, repeat };
        }
        case PLAYER_REPEAT: {
            const { repeat: _repeat } = action.payload;
            const newRepeat = _repeat ?? getNextRepeatStatus(state.repeat);
            return { ...state, repeat: newRepeat };
        }
        case AUDIO_INPUT_TYPE_CHANGE: {
            return { ...state, input: action.payload.input };
        }
        case PLAYQUEUE_SET_AND_PLAY: {
            const { shuffle, playable } = action.payload;

            if (playable.contentType === ContentType.MixRadio) {
                return { ...state, shuffle: ShuffleState.Off, repeat: RepeatStatus.None };
            }

            const newShuffleState = getShuffleStateWhenSetAndPlay(state.shuffle, shuffle ?? null);
            const repeat = state.repeat === RepeatStatus.One ? RepeatStatus.None : state.repeat;

            return { ...state, shuffle: newShuffleState, repeat };
        }
        case LIVERADIO_SET_AND_PLAY: {
            return { ...state, shuffle: ShuffleState.Off, repeat: RepeatStatus.None };
        }
        case PLAYER_SHUFFLE: {
            const newShuffleState = getNextShuffleState(state.shuffle);
            return { ...state, shuffle: newShuffleState };
        }
        case REHYDRATE: {
            const player = action.payload?.player ?? null;
            if (player == null) return initialState;
            if (player.version === undefined) {
                return { ...initialState, repeat: player.repeat, volume: player.volume, muted: player.muted };
            }

            return {
                ...player,
                restoreVolume: player.restoreVolume ?? null
            };
        }
        default:
            return state;
    }
}
