import { getBrowserAudios } from ".";
import { inSkipDebounce } from "../skipDebounce";
import type { BrowserAudioItemModel } from "models/app/player/output";
import { BrowserAudioLoadState } from "models/app/player/output";

export function updateLoadNow(browserAudio: BrowserAudioItemModel) {
    if (inSkipDebounce) return;
    if (browserAudio.disposed) return;

    browserAudio.loadNow = isPreviousDone(browserAudio);
}

function isPreviousDone(browserAudio: BrowserAudioItemModel): boolean {
    const browserAudios = getBrowserAudios();

    for (const other of browserAudios) {
        const isPrevious = other.preload.order < browserAudio.preload.order;
        if (!isPrevious) continue;

        const isDone = other.state === BrowserAudioLoadState.PartialDone || other.state === BrowserAudioLoadState.Done;
        if (!isDone) return false;
    }

    return true;
}
