import { convertThemeSetting, convertTheme } from "./convertThemeSetting";
import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import { getThemeSettingFromState, getThemeFromState, getLangFromState, getLanguageSettingFromState } from "components/app/hooks";
import { WebAppBrand } from "shared/models";
import { getAppVersionNumber } from "shared/services";
import { Platform, getWindowInnerSizeString, getBrand, getPlatform } from "services/app";
import type { AnalyticsKeys } from "generated/analyticsKeys";
import type { ThemeSetting, Theme } from "models/view";
import type { Language, LanguageSetting } from "models/view/Localization";

interface AnalyticsAppProperties {
    AppVersion: number;
    Brand: WebAppBrand;
    ThemeSetting: ThemeSetting;
    Theme: Theme;
    LanguageSetting: LanguageSetting;
    Language: Language;
    Platform: Platform;
    WindowWidthWeb: number;
    WindowHeightWeb: number;
}

export function getAnalyticsAppProperties(): AnalyticsAppProperties {
    const size = getWindowInnerSizeString();

    return {
        AppVersion: getAppVersionNumber(),
        Brand: getBrand(),
        ThemeSetting: getThemeSettingFromState(),
        Theme: getThemeFromState(),
        Platform: getPlatform(),
        WindowWidthWeb: size[0],
        WindowHeightWeb: size[1],
        Language: getLangFromState(),
        LanguageSetting: getLanguageSettingFromState()
    };
}

export function convertAnalyticsAppProperties(properties: AnalyticsAppProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "app", "AppVersion", properties.AppVersion);
    SetAnalyticsProp(data, "app", "Brand", convertBrandValue(properties.Brand));
    SetAnalyticsProp(data, "app", "ThemeSetting", convertThemeSetting(properties.ThemeSetting));
    SetAnalyticsProp(data, "app", "Theme", convertTheme(properties.Theme));
    SetAnalyticsProp(data, "app", "LanguageSetting", properties.LanguageSetting);
    SetAnalyticsProp(data, "app", "Language", properties.Language);
    SetAnalyticsProp(data, "app", "Platform", convertPlatformValue(properties.Platform));
    SetAnalyticsProp(data, "app", "WindowWidthWeb", properties.WindowWidthWeb);
    SetAnalyticsProp(data, "app", "WindowHeightWeb", properties.WindowHeightWeb);

    return data;
}

type AnalyticsBrandName = keyof Pick<AnalyticsKeys, "Telmore" | "YouSee">;
function convertBrandValue(brand: WebAppBrand): AnalyticsBrandName | null {
    switch (brand) {
        case WebAppBrand.Telmore:
            return "Telmore";
        case WebAppBrand.YouSee:
            return "YouSee";
        case null:
            return null;
    }
}
type AnalyticsPlatformValue = keyof Pick<AnalyticsKeys, "Desktop" | "Web">;
function convertPlatformValue(platform: Platform): AnalyticsPlatformValue | null {
    switch (platform) {
        case Platform.Desktop:
            return "Desktop";
        case Platform.Web:
            return "Web";
    }
}
