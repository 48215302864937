import { dispatch, store } from "global";
import { PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE } from "global/actions";
import { AudioControllerType, PlayerOutputType } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";

export function setPlayerModeLocal(context: AudioContextModel) {
    set(AudioControllerType.Local, PlayerOutputType.Browser, context);
}

export function setPlayerModeChromecast(context: AudioContextModel) {
    set(AudioControllerType.Chromecast, PlayerOutputType.Chromecast, context);
}

function set(controller: AudioControllerType, output: PlayerOutputType, context: AudioContextModel) {
    const player = store.getState().player;
    if (player.controller === controller && player.output === output) return;

    dispatch({
        type: PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE,
        payload: {
            controller,
            output,
            context
        }
    });
}
