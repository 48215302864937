import type { PlayQueuePlayableModelInterface } from ".";
import { MixRadioLinkParentType } from ".";
import { getAlbumPreview, getTrackRecommendationPreview } from "./../../services/backend/backend";
import type { PlayablePreviewModel } from "./PlayableModel";
import type { ResourceModel } from "./ResourceModel";
import { translate } from "global/config";
import { getChartPreview, getMixRadioPreview, getPlaylistPreview } from "services/backend";
import { replaceAllBackwardsCompatible } from "services/formatters";
import { DefaultLogMessage, log } from "services/logger";
import { getNextUUID } from "services/utils";
import type { NotificationPreviewFragment } from "generated/graphql-types";
import { NotificationType as GraphNotificationType } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type NotificationPreviewData = NotificationPreviewFragment;

export type NotificationModel = NotificationPreviewModel;
export type NotificationPlayModel = NotificationPreviewModel;

export enum NotificationType {
    InstallPWA = "InstallPWA",
    PlaylistUpdated = "PlaylistUpdated",
    TrackRecommendationUpdated = "TrackRecommendationUpdated",
    TrackChartUpdated = "TrackChartUpdated",
    WelcomeToWeb = "WelcomeToWeb",
    TryMixRadio = "TryMixRadio",
    RecommendedTracks = "RecommendedTracks",
    Radio = "Radio",
    Album = "Album",
    Empty = "EMPTY"
}

export enum NotificationKind {
    Social = "Social",
    Feature = "Feature",
    Content = "Content"
}

export type NotificationConversionResource = ContentType.InstallPWA | ContentType.FrontPage | ContentType.Radio | ContentType.MixRadios;

export enum NotificationConversion {
    InstallPWA = "InstallPWA",
    NavigateToFrontPage = "NavigateToFrontPage",
    NavigateToRadio = "NavigateToRadio",
    NavigateToMixRadios = "NavigateToMixRadios",
    Play = "Play",
    Navigate = "Navigate"
}

export interface NotificationPreviewModel extends PlayQueuePlayableModelInterface {
    type: DomainModelType.Preview;
    contentType: ContentType.Notification;
    availableBegin: number;
    availableEnd: number | null;
    notificationType: NotificationType;
    notificationKind: NotificationKind;
    eligibilityReasons?: string[];
    eventType: string | null;
    score?: number;
    seen: boolean;
    converted: boolean;
    title: string;
    subtitle?: string;
    playContextId?: string;

    playable?: PlayablePreviewModel;
    trackCount: number | null;

    content?: NotificationContentModel;
}

export type NotificationContentModel = NotificationContentWrapperModel | Text;
export type NotificationContentWrapperModel = BulletGroup | Paragraph | Bullet | Link | Headline | Section;

interface Wrapper {
    children: NotificationContentModel[];
}

export interface Paragraph extends Wrapper {
    type: "Paragraph";
}

export interface BulletGroup extends Wrapper {
    type: "BulletGroup";
}

export interface Bullet extends Wrapper {
    type: "Bullet";
}

export interface Link extends Wrapper {
    type: "Link";
    action: NotificationConversion;
    style: "inline" | "light-micro";
}

export interface Headline extends Wrapper {
    type: "Headline";
}

export interface Section extends Wrapper {
    type: "Section";
}

export interface Text {
    type: "Text";
    text: string;
}

export function getNavigationConversionResourceType(conversion: NotificationConversion): NotificationConversionResource | null {
    switch (conversion) {
        case NotificationConversion.InstallPWA:
            return ContentType.InstallPWA;
        case NotificationConversion.NavigateToFrontPage:
            return ContentType.FrontPage;
        case NotificationConversion.NavigateToRadio:
            return ContentType.Radio;
        case NotificationConversion.NavigateToMixRadios:
            return ContentType.MixRadios;
        case NotificationConversion.Play:
            return null;
        case NotificationConversion.Navigate:
            return null;
    }
}

export function isNotificationPlayable(resource: ResourceModel | null) {
    return resource?.contentType === ContentType.Notification && resource.playable != null;
}

export function isNotificationExpand(resource: ResourceModel | null) {
    return resource?.contentType === ContentType.Notification && resource.content != null;
}

function getNotificationType(notificationType?: GraphNotificationType | null): NotificationType | null {
    switch (notificationType) {
        case GraphNotificationType.Album:
            return NotificationType.Album;
        case GraphNotificationType.Radio:
            return NotificationType.Radio;
        case GraphNotificationType.RecommendedTracks:
            return NotificationType.RecommendedTracks;
        case GraphNotificationType.PublicPlaylist:
            return NotificationType.PlaylistUpdated;
    }
    log.error({ code: "web-220613-1255", msg: DefaultLogMessage.NotImplemented, data: { type: notificationType } });
    return null;
}

function getNotificationKind(type: NotificationType): NotificationKind {
    switch (type) {
        case NotificationType.InstallPWA:
        case NotificationType.TryMixRadio:
        case NotificationType.Empty:
            return NotificationKind.Feature;
        case NotificationType.Album:
        case NotificationType.Radio:
        case NotificationType.PlaylistUpdated:
        case NotificationType.TrackRecommendationUpdated:
        case NotificationType.TrackChartUpdated:
        case NotificationType.RecommendedTracks:
            return NotificationKind.Content;
        case NotificationType.WelcomeToWeb:
            return NotificationKind.Social;
    }
    type;
}

export function createNotificationPreviewModel(data: NotificationPreviewData): NotificationPreviewModel | null {
    const notificationType = getNotificationType(data.type);
    if (!notificationType) {
        // console.log(`Unhandled notification type: ${data.type}`, data);
        return null;
    }

    return {
        type: DomainModelType.Preview,
        contentType: ContentType.Notification,
        id: data.id,
        availableBegin: new Date(data.availableBegin).getTime(),
        availableEnd: data.availableEnd ? new Date(data.availableEnd).getTime() : null,
        eligibilityReasons: data.eligibilityReasons as string[],
        converted: false,
        cover: null,
        graphType: data.__typename,
        notificationKind: getNotificationKind(notificationType),
        notificationType,
        eventType: data.eventKey || null,
        seen: data.seenAt,
        subtitle: data.subTitle ?? undefined,
        title: data.title,
        trackCount: null,
        playContextId: data.contextId ?? undefined
    };
}

async function getPlayable(notification: NotificationPreviewModel) {
    if (notification?.playContextId) {
        try {
            switch (notification.notificationType) {
                case NotificationType.Album: {
                    const album = (await getAlbumPreview({ id: notification.playContextId })).model;
                    return album;
                }
                case NotificationType.Radio: {
                    const radio = (await getMixRadioPreview({ id: notification.playContextId }, { type: MixRadioLinkParentType.Notification })).model;
                    return radio;
                }
                case NotificationType.RecommendedTracks: {
                    const recommendation = (await getTrackRecommendationPreview({ id: notification.playContextId })).model;
                    if (recommendation?.contentType !== ContentType.TrackRecommendation) return null;
                    return recommendation;
                }
                case NotificationType.PlaylistUpdated: {
                    const playlist = (await getPlaylistPreview({ id: notification.playContextId })).model;
                    return playlist;
                }
                default:
                    log.error({ code: "web-220613-1438", msg: DefaultLogMessage.NotImplemented, data: { type: notification.notificationType } });
            }
        } catch (e) {
            log.error({ code: "web-230418-1516", msg: "Unable to fetch playable for notification.", data: { notificationId: notification.id, error: e } });
        }
        return null;
    }
}

export async function fetchPlayableForNotification(notification: NotificationPreviewModel): Promise<NotificationPreviewModel | null> {
    if (!notification.playContextId) {
        log.error({ code: "web-220613-1438", msg: DefaultLogMessage.UnexpectedNull, data: { type: notification.notificationType } });
        return null;
    }

    const playable = await getPlayable(notification);
    if (!playable) return null;

    const cover = notification.cover ?? playable.cover;

    return { ...notification, subtitle: notification.subtitle ?? playable.title, playable, cover };
}

interface CreateNotificationPreviewModelLocallyProps {
    content?: NotificationContentModel;
    cover: string | null;
    notificationType: NotificationType;
    playable?: PlayablePreviewModel;
    subtitle?: string;
    title: string;
    score: number;
    trackCount: number | null;
}

export function createNotificationPreviewModelLocally(data: CreateNotificationPreviewModelLocallyProps): NotificationPreviewModel {
    return {
        type: DomainModelType.Preview,
        contentType: ContentType.Notification,
        id: getNextUUID(),
        availableBegin: new Date().getTime(),
        availableEnd: null,
        converted: false,
        graphType: "NotificationPreviewModel",
        seen: false,
        notificationKind: getNotificationKind(data.notificationType),
        eventType: null,
        ...data
    };
}

export async function createNotificationPlaylistUpdated(playlistId: string, score: number): Promise<NotificationPreviewModel | null> {
    const playlist = (await getPlaylistPreview({ id: playlistId })).model ?? null;
    if (!playlist) return null;

    const notification = createNotificationPreviewModelLocally({
        cover: playlist.cover,
        notificationType: NotificationType.PlaylistUpdated,
        title: `${playlist.title}`,
        subtitle: "Playlisten er opdateret med ny musik",
        playable: playlist,
        trackCount: playlist.trackCount,
        score
    });

    return notification;
}

export async function createNotificationTrackRecommendationUpdated(recommendationId: string, score: number): Promise<NotificationPreviewModel | null> {
    const recommendation = (await getTrackRecommendationPreview({ id: recommendationId })).model ?? null;
    if (!recommendation) return null;
    if (recommendation.contentType !== ContentType.TrackRecommendation) return null;

    const id = replaceAllBackwardsCompatible(recommendation.id, "\u00E5", "å").toLowerCase();
    let title: string;

    if (id === "opdag-ny-musik1" || id === "opdag-musik") {
        title = "Opdag musik";
    } else if (id === "fordi-du-lyttede-til" || id === "fordi-du-også-lyttede-til") {
        title = `Fordi du lyttede til ${recommendation.subtitle}`;
    } else {
        title = recommendation.title;
    }

    const notification = createNotificationPreviewModelLocally({
        cover: recommendation.cover,
        notificationType: NotificationType.TrackRecommendationUpdated,
        title: `${title}`,
        subtitle: "Nye personlige anbefalinger til dig",
        playable: recommendation,
        trackCount: recommendation.trackCount,
        score
    });

    return notification;
}

export async function createNotificationTrackChartUpdated(trackChartId: string, score: number): Promise<NotificationPreviewModel | null> {
    const chart = (await getChartPreview({ id: trackChartId })).model ?? null;
    if (!chart) return null;
    if (chart.contentType !== ContentType.TrackChart) return null;

    const notification = createNotificationPreviewModelLocally({
        cover: chart.cover,
        notificationType: NotificationType.TrackChartUpdated,
        title: `${chart.title}`,
        subtitle: "Hitlisten er opdateret med ny musik",
        playable: chart,
        trackCount: chart.trackCount,
        score
    });

    return notification;
}

export function createNotificationFeatureWelcomeToWebModel(score: number) {
    const p: Paragraph = {
        type: "Paragraph",
        children: [{ type: "Text", text: `Vi håber du bliver glad for ${translate("AppName")}! Her er et par tips:` }]
    };

    const b1: Bullet = {
        type: "Bullet",
        children: [
            { type: "Text", text: "Tjek " },
            { type: "Link", children: [{ type: "Text", text: "forsiden" }], action: NotificationConversion.NavigateToFrontPage, style: "inline" },
            { type: "Text", text: ", hvor du finder ny musik, personlige anbefalinger og hitlister." }
        ]
    };

    const b2: Bullet = {
        type: "Bullet",
        children: [{ type: "Text", text: "Find den musik, du har tilføjet til Min Musik, i menuen til venstre. Her finder du også de playlister, som du selv har lavet." }]
    };

    const b3: Bullet = {
        type: "Bullet",
        children: [
            { type: "Text", text: "Lyt til live FM-radio under " },
            { type: "Link", children: [{ type: "Text", text: "Radio" }], action: NotificationConversion.NavigateToRadio, style: "inline" },
            { type: "Text", text: "." }
        ]
    };

    const bg: BulletGroup = { type: "BulletGroup", children: [b1, b2, b3] };
    const section: Section = { type: "Section", children: [p, bg] };

    const notification = createNotificationPreviewModelLocally({
        cover: "/assets/notification_welcome.svg",
        notificationType: NotificationType.WelcomeToWeb,
        title: `Velkommen til ${translate("AppName")} på web`,
        content: section,
        trackCount: null,
        score
    });
    return notification;
}

export function createNotificationFeatureInstallPWAModel(score: number) {
    const p: Paragraph = {
        type: "Paragraph",
        children: [
            {
                type: "Text",
                text: `Hent ${translate("AppName")} som app på din computer og få nem adgang til al den musik, du elsker.`
            }
        ]
    };

    const link: Link = { type: "Link", children: [{ type: "Text", text: "Hent som desktop-app" }], action: NotificationConversion.InstallPWA, style: "inline" };
    const section: Section = { type: "Section", children: [p, link] };

    const notification = createNotificationPreviewModelLocally({
        cover: "/assets/notification_install.svg",
        notificationType: NotificationType.InstallPWA,
        title: `Hent ${translate("AppName")} som desktop-app`,
        content: section,
        trackCount: null,
        score
    });

    return notification;
}

export function createNotificationFeatureTryMixRadioModel(score: number) {
    const t1: Paragraph = {
        type: "Paragraph",
        children: [
            {
                type: "Text",
                text: "More Music stationerne kombinerer det bedste fra radio og streaming."
            }
        ]
    };

    const t2: Paragraph = {
        type: "Paragraph",
        children: [
            {
                type: "Text",
                text: "Du får den variation, du kender fra radio, men med muligheden for at skippe sange."
            }
        ]
    };

    const t3: Paragraph = {
        type: "Paragraph",
        children: [
            {
                type: "Text",
                text: "Vi sørger for en ny musikoplevelse hver gang – helt uden snak og reklamer."
            }
        ]
    };

    const link: Link = { type: "Link", children: [{ type: "Text", text: "Se alle More Music stationer" }], action: NotificationConversion.NavigateToMixRadios, style: "inline" };
    const section: Section = { type: "Section", children: [t1, t2, t3, link] };

    const notification = createNotificationPreviewModelLocally({
        cover: "/assets/notification_trymoremusic.svg",
        notificationType: NotificationType.TryMixRadio,
        title: "Har du prøvet More Music stationer?",
        content: section,
        trackCount: null,
        score
    });
    return notification;
}

export function createEmptyNotificationsModel() {
    const content: Paragraph = {
        type: "Paragraph",
        children: [
            {
                type: "Text",
                text: translate("NotificationEmptyState")
            }
        ]
    };

    const notification = createNotificationPreviewModelLocally({
        cover: "/assets/app_icon.svg",
        notificationType: NotificationType.Empty,
        title: translate("NotificationEmptyTitle"),
        content,
        trackCount: null,
        score: 0
    });

    return notification;
}
