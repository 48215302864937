import { h } from "preact";
import { useContext, useEffect, useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { ContextMenuMode, ContextMenuManagerContext, Sheet, Link } from "..";
import { PlaylistLink } from "../links";
import { CreatePlaylistLink } from "../links/CreatePlaylistLink";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus/open";
import { addQueueToPlaylist } from "services/playlist";
import { useDisplayLoggedIn } from "services/user";
import type { RootModel } from "models/app";
import type { PlaylistModel } from "models/domain/PlaylistModel";
import { ModalOpenResult } from "models/view/AppModalModel";
import { ContentLoadSpinner } from "components/atoms/spinner";
import { promptLoginIfRequired } from "components/organisms/login";
import { showCustomCreatePlaylistModal } from "components/organisms/modal/modals/CreatePlaylistModal";

export const AddQueueToPlaylistContextMenu = () => {
    const translations = useTranslations();
    const playlists = useSelector((root: RootModel) => root.cache.userPlaylistPreviews);

    useEffect(() => {
        setTimeout(() => {
            // context menu needs to be rendered first
            dispatch({ type: "CONTEXT_MENU_REPOSITION" });
        });
    }, [playlists]);

    const create = useMemo(
        () => async () => {
            dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
            if (await promptLoginIfRequired()) {
                const newPlaylistResult = await showCustomCreatePlaylistModal(translations.SaveQueueAsPlaylist2, null, null);
                if (newPlaylistResult.result === ModalOpenResult.Submit && !!newPlaylistResult.playlistId) {
                    await addQueueToPlaylist(newPlaylistResult.playlistId);
                }
            }
        },
        [translations.SaveQueueAsPlaylist2]
    );

    const add = useMemo(
        () => async (playlist: PlaylistModel) => {
            dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
            if (await promptLoginIfRequired()) {
                await addQueueToPlaylist(playlist.id);
            }
        },
        []
    );

    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    const loggedIn = useDisplayLoggedIn();

    return (
        <Sheet top={<div />}>
            <CreatePlaylistLink onClick={create} showIcon={showIcon} />
            <hr />
            {playlists?.map((playlist) => (
                <PlaylistLink key={playlist.id} playlist={playlist} onClick={() => add(playlist)} showIcon={showIcon} />
            ))}
            {!playlists && <ContentLoadSpinner />}
            {!loggedIn && (
                <Link disabled={true} icon={undefined}>
                    Log ind for at se playlister
                </Link>
            )}
        </Sheet>
    );
};
