import { addToStringWithSeperator } from "./stringFormatters";
import { getLangFromState } from "components/app/hooks";
import { translate } from "global/config";
import { getLanguageCodeFromState } from "services/app";
import { Language } from "models/view/Localization";

export const getShortTimeSpanFromSecondsHHMMSS = (time: number): string => {
    const hours = Math.floor(time / 3600);
    time %= 3600;
    const minutes = Math.floor(time / 60);
    time %= 60;
    const seconds = Math.floor(time);

    let str = "";
    if (hours > 0) str = addToStringWithSeperator(str, hours.toString().padStart(2, "0"), ":");
    str = addToStringWithSeperator(str, minutes.toString().padStart(2, "0"), ":");
    str = addToStringWithSeperator(str, seconds.toString().padStart(2, "0"), ":");
    return str;
};

export const getLongTimeSpanFromSecondsHHMM = (time: number): string => {
    const hours = Math.floor(time / 3600);
    time %= 3600;
    const minutes = Math.floor(time / 60);

    const hourUnit = (value: number) => translate(value === 1 ? "GlobalHourUnit" : "GlobalHoursUnit");
    const minuteUnit = (value: number) => translate(value === 1 ? "GlobalMinuteUnit" : "GlobalMinutesUnit");

    let str = "";
    if (hours > 0) str = addToStringWithSeperator(str, `${hours} ${hourUnit(hours)}`, " ");
    if (hours == 0 || minutes > 0) str = addToStringWithSeperator(str, `${minutes} ${minuteUnit(hours)}`, " ");
    return str;
};

export const getShortDateDDMMYYYY = (date: Date): string => {
    const yearStr = date.getFullYear().toString().padStart(4, "0");
    const monthStr = (date.getMonth() + 1).toString().padStart(1, "0");
    const dateStr = date.getDate().toString().padStart(1, "0");

    return `${dateStr}.${monthStr}.${yearStr}`;
};

const monthNamesDa = ["jan.", "feb.", "mar.", "apr.", "maj", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."];
const monthNamesEn = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
export const getAppDateDDMMMM = (date: Date): string => {
    switch (getLangFromState()) {
        case Language.Danish: return `${date.getDate()}. ${monthNamesDa[date.getMonth()]}`;
        case Language.English: return `${date.getDate()}. ${monthNamesEn[date.getMonth()]}`;
    }
};

export const getYearYYYY = (date: Date) => {
    const yearStr = date.getFullYear().toString().padStart(4, "0");
    return yearStr;
};

export const getShortTimeSpanFromSecondsTIMEAGO = (time: number): string => {
    const local = getLanguageCodeFromState();
    const long = new Intl.RelativeTimeFormat(local, { style: "short" });

    const relativeTime = time - new Date().getTime();
    const neg = relativeTime < 0;

    const seconds = Math.floor(Math.abs(relativeTime) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    let val: number;
    let unit: Intl.RelativeTimeFormatUnit;

    if (years >= 1) {
        val = years;
        unit = "years";
    } else if (months >= 1) {
        val = months;
        unit = "months";
    } else if (days >= 1) {
        val = days;
        unit = "days";
    } else if (hours >= 1) {
        val = hours;
        unit = "hours";
    } else if (minutes >= 1) {
        val = minutes;
        unit = "minutes";
    } else {
        val = seconds;
        unit = "seconds";
    }

    if (neg) val *= -1;

    return long.format(val, unit);
};

export const getNotificationsDateOrTimeAgo = (time: number): string => {
    const now = new Date();
    const cphTimezoneAndSummertimeFudgeFactor = (1000 * 60 * 60 * 3);
    const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())).getTime() - cphTimezoneAndSummertimeFudgeFactor;

    if (time >= today) return translate("NotificationFeedTodayTitle");
    const yesterday = today - (1000 * 60 * 60 * 24);
    if (time >= yesterday) return translate("NotificationFeedYesterdayTitle");
    const date = new Date(time);
    //return getShortDateDDMMYYYY(date);
    return getAppDateDDMMMM(date);
}