import { useEffect, useState } from "preact/hooks";
import { isEqual } from "services/arrayHelper";

export function useArrayMemo<T>(fn: () => T[], dependencies: unknown[]): T[] {
    const [compare, setCompare] = useState<T[]>([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const array = fn();

        if (!isEqual(array, compare)) {
            setCompare(array);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencies]);

    return compare;
}
