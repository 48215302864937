import { h } from "preact";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import {
    getAlbumTracksPagination,
    getAlbumHeaderSection,
    getAlbumTracksSection,
    getAlbumArtistAlbumsSection,
    getAlbumArtistAlbumsByTrackSection,
    getAlbumHeaderSectionByTrack,
    getAlbumTracksSectionByTrack
} from "services/backend";
import type { CombinedQueryParams, QueryParams } from "services/backend/backendService";
import { QueryError, useCombinedQuery } from "services/backend/backendService";
import { DefaultLogMessage, log } from "services/logger";
import type { AlbumPageModel, PlayableContentType, PlayableModel, TrackPlayableModel, TrackPreviewModel } from "models/domain";
import {
    createEmptyAlbumPageModel,
    fillAlbumPageModelWithAlbumArtistAlbumsSectionData,
    fillAlbumPageModelWithBasicSection,
    fillAlbumPageModelWithTracksSection,
    ResourceDisplayType
} from "models/domain";
import { ContentType } from "models/ModelType";
import { usePageContext, useSectionContext, useTrackPlayables, useAutoPlay } from "components/shared/hooks";
import { usePagination } from "components/shared/hooks/usePagination";
import { Line } from "components/atoms/line";
import { ResourceSection } from "components/organisms/resourceSection";
import { ProductPageTemplate } from "components/templates/productPage";
import { FooterText } from "components/templates/productPage/FooterText";

interface Props {
    albumId: string | null;
    trackId: string | null;
}

export const AlbumPage = ({ albumId, trackId }: Props) => {
    const combined: CombinedQueryParams<AlbumPageModel> | null = useMemo(() => {
        if (albumId == null && trackId == null) {
            log.error({ code: "web-211027-2251", msg: DefaultLogMessage.UnexpectedNull });
            return null;
        }

        return {
            model: createEmptyAlbumPageModel(),
            params: albumId != null ? getCombinedQueryParams(albumId) : getCombinedQueryParamsByTrack(trackId as string)
        };
    }, [albumId, trackId]);

    const { query } = useCombinedQuery(combined);
    if (query.model?.availableInSubscription === false) {
        query.model = null;
        query.error = QueryError.NotAvailableInSubscriptionError;
    }

    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => product && getAlbumTracksPagination({ first, after, id: product.id }, product), [product]);

    const page = usePageContext({ type: ContentType.Album, resource: product, root: null, done: query.success });

    const top = useSectionContext(ContentType.Album, product, page, 0, ResourceDisplayType.Top);
    const tracks = useSectionContext(ContentType.Album, product, page, 1, ResourceDisplayType.List);
    const albums = useSectionContext(ContentType.AlbumArtistAlbums, product?.artistAlbums ?? null, page, 2, ResourceDisplayType.LargeScroller);

    const pagination = usePagination<TrackPreviewModel>({ resource: product, connection: product?.tracks ?? null, fetchFn });
    const playables = useTrackPlayables(pagination.connection?.items ?? null, product);
    const showLabel = product == null || product.label.name != null;

    const { type, playable } = useAlbumPageAutoPlay({ albumId, trackId, tracks: playables, album: product });
    useAutoPlay({ page, type, resource: playable, loading: query.loading });

    return (
        <ProductPageTemplate
            // onScroll={handleScroll}
            key={albumId}
            query={query}
            type={ContentType.Album}
            section={top}
            isUserOwned={false}
            scrollRef={pagination.scrollRef}>
            <ResourceSection resources={playables} pagination={pagination} context={tracks} highlightedId={trackId ?? undefined} />
            <ResourceSection showTitle resources={product?.artistAlbums?.albums?.items ?? null} context={albums} showAll={product?.artistAlbums} />
            {showLabel && <Line />}
            {showLabel && <FooterText>{product?.label.name}</FooterText>}
        </ProductPageTemplate>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCombinedQueryParams(albumId: string): QueryParams<AlbumPageModel, any>[] {
    return [
        { fetch: () => getAlbumHeaderSection({ id: albumId }), map: fillAlbumPageModelWithBasicSection, essential: true },
        { fetch: () => getAlbumTracksSection({ id: albumId, first: getPaginationInit() }), map: fillAlbumPageModelWithTracksSection, essential: true },
        { fetch: () => getAlbumArtistAlbumsSection({ id: albumId }), map: fillAlbumPageModelWithAlbumArtistAlbumsSectionData }
    ];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getCombinedQueryParamsByTrack(trackId: string): QueryParams<AlbumPageModel, any>[] {
    return [
        { fetch: () => getAlbumHeaderSectionByTrack({ id: trackId }), map: fillAlbumPageModelWithBasicSection, essential: true },
        { fetch: () => getAlbumTracksSectionByTrack({ id: trackId, first: getPaginationInit() }), map: fillAlbumPageModelWithTracksSection, essential: true },
        { fetch: () => getAlbumArtistAlbumsByTrackSection({ id: trackId }), map: fillAlbumPageModelWithAlbumArtistAlbumsSectionData }
    ];
}

interface AlbumPageAutoPlayProps {
    trackId: string | null;
    albumId: string | null;
    tracks: TrackPlayableModel[] | null;
    album: AlbumPageModel | null;
}

function useAlbumPageAutoPlay({ albumId, trackId, tracks, album }: AlbumPageAutoPlayProps) {
    const [playable, setPlayable] = useState<PlayableModel | null>(null);
    const [type, setType] = useState<PlayableContentType>(ContentType.Album);

    useEffect(() => {
        if (trackId != null) {
            const track = tracks ? tracks.find((track) => track.track.id === trackId) ?? null : null;
            setType(ContentType.TrackPlayable);
            setPlayable(track);
        } else if (albumId != null) {
            setType(ContentType.Album);
            setPlayable(album);
        }
    }, [trackId, albumId, tracks, album]);

    return { type, playable };
}
