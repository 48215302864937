import { getRemoteCastQueue } from "../actions";
import { getLocalCastQueue } from "../actions/localCastQueue";

export function isOperationAddingToQueue(): boolean {
    const idsLocal = getLocalCastQueue().audiosCastIds;
    const idsRemote = getRemoteCastQueue();

    const isAdd = idsLocal.some((localId) => idsRemote.indexOf(localId) === -1);
    return isAdd;
}

export function isOperationModifyingQueue(): boolean {
    const idsLocal = getLocalCastQueue().audiosCastIds;
    const idsRemote = getRemoteCastQueue();

    if (idsLocal.length !== idsRemote.length) return true;

    for (let i = 0; i < idsLocal.length; i++) {
        if (idsLocal[i] !== idsRemote[i]) return true;
    }

    return false;
}
