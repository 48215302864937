export function parseIntAsNormalPositiveNumberOrNull(value: string): number | null {
    if (!/\d+/.test(value)) return null;

    const num = Number(value);

    if (isNaN(num)) return null;
    if (num == null) return null;
    if (num === Infinity) return null;

    return num;
}
