import { useState, useLayoutEffect } from "preact/hooks";
import { useResizeObserver } from "./useResizeObserver";
import { ScrollAxis, useScrollPosition } from "./useScrollPosition";

export enum ScrollState {
    Start = "start",
    InBetween = "inBetween",
    End = "end",
    NoScroll = "noScroll"
}

export const useScrollState = (container: HTMLElement | null, content: HTMLElement | null, axis: ScrollAxis) => {
    const scrollPosition = useScrollPosition(container, axis);
    const [scrollState, setScrollState] = useState(ScrollState.NoScroll);

    const contentSizeObs = useResizeObserver(content);
    const contentSize = axis === ScrollAxis.x ? contentSizeObs?.width : contentSizeObs?.height ?? null;

    useLayoutEffect(() => {
        if (!container) return;
        setScrollState(getScrollState(container, axis));
    }, [scrollPosition, container, axis, contentSize]);

    return scrollState;
};

export function getScrollState(element: HTMLElement, axis: ScrollAxis) {
    const scrollPosition = axis === ScrollAxis.x ? element.scrollLeft : element.scrollTop;
    const scrollSize = axis === ScrollAxis.x ? element.scrollWidth : element.scrollHeight;
    const elementSize = axis === ScrollAxis.x ? element.clientWidth : element.clientHeight;

    if (elementSize === 0) return ScrollState.NoScroll;
    if (scrollSize === 0) return ScrollState.NoScroll;

    const isStart = scrollPosition <= 0;
    const isEnd = scrollSize - scrollPosition <= elementSize;

    const state = isStart && isEnd ? ScrollState.NoScroll : isStart ? ScrollState.Start : isEnd ? ScrollState.End : ScrollState.InBetween;

    return state;
}
