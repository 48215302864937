import { requestCastSession } from "../castRequests/context";
import { stopCastSession } from "./stopCastSession";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";
import { AudioContextAction } from "models/app/player/AudioContext";

export async function userToggleCastSession(): Promise<boolean> {
    const isCast = isControllerChromecastFromState();

    if (!isCast) return requestCastSession();
    stopCastSession({ action: AudioContextAction.UserEndChromeCast, trace: null });
    return true;
}
