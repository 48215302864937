import { store } from "global";
import { PlayerOutputType } from "models/app/player";

export function getPlayerOutputTypeFromState() {
    return store.getState().player.output;
}

export const isOutputChromecast = (output: PlayerOutputType) => output === PlayerOutputType.Chromecast;
export const isOutputChromecastFromState = () => isOutputChromecast(store.getState().player.output);

export const isOutputBrowser = (output: PlayerOutputType) => output === PlayerOutputType.Browser;
export const isOutputBrowserFromState = () => isOutputBrowser(store.getState().player.output);
