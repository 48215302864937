import { useCallback } from "preact/hooks";
import { getHasContextMenu, getResourceAvailableInSubscription, getResourceEditable } from "services/resource";
import type { Multiselect } from "services/selection";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import { useContextMenu } from "components/shared/hooks";
import { ResourceContextMenu } from "components/organisms/contextMenu/menus";

export function useOnRightClickContextMenu(context: PreviewContextModel, resource: ResourcePreviewModel | null, sectionContext: SectionContextModel, moreButtonIsOpen?: boolean, multiselect?: Multiselect) {
    const hasContextMenu = getHasContextMenu(context.type, context.resource);
    const menuRightClick = useContextMenu(hasContextMenu && resource ? ResourceContextMenu({ resource, context, resourceType: resource.contentType, multiselect }) : null);

    const moreOpen = moreButtonIsOpen || menuRightClick.isOpen;
    const available = getResourceAvailableInSubscription(resource);
    const editable = getResourceEditable(sectionContext.page?.resource ?? null);
    const disableContextMenu = !available && !editable;
    const enableOnContextMenu = hasContextMenu && !disableContextMenu;

    const rightClick = useCallback((ev: MouseEvent) => menuRightClick.openByMouse(ev), [menuRightClick]);
    return { hasContextMenu, disableContextMenu, editable, available, moreOpen, onContextMenu: enableOnContextMenu ? rightClick : undefined };
}
