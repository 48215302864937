import { getData, isMySession, parseData, saveData } from "../helpers";
import { getPendingLyricsReportsStorageKey } from "../storageKeys";
import type { DisplayType } from "generated/graphql-types";

// domain model matching ReportLyricsDisplayInput from graphql-types
export type LyricReport = {
    displayType: DisplayType;
    displayedAt: string;
    lyricsId: string;
    trackId: string;
};

let pendingReports: LyricReport[] | null;

export function getPendingLyricReports(storageId: string) {
    const mySession = isMySession(storageId);
    if (mySession && pendingReports != null) return pendingReports;

    const key = getPendingLyricsReportsStorageKey(storageId);
    const reports = parseData<LyricReport[]>(getData(key));

    if (mySession) pendingReports = reports;
    return reports ?? [];
}

export function savePendingLyricReports(storageId: string, reports: LyricReport[]) {
    const mySession = isMySession(storageId);
    const key = getPendingLyricsReportsStorageKey(storageId);

    saveData(key, JSON.stringify(reports));
    if (mySession) pendingReports = reports;
}
