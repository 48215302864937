import { h } from "preact";
import "./SideBar.scss";
import { CreatePlaylistButton } from "./CreatePlaylistButton";
import { Main } from "./Main";
import { MyMusic } from "./MyMusic";
import { MyMusicPlaylists } from "./MyMusicPlaylists";
import { ScrollerWithFade } from "../scrollerWithFade";
import { useDisplayLoggedIn } from "services/user";
import { ContentType } from "models/ModelType";
import { useSectionContext } from "components/shared/hooks";
import { Logo } from "components/atoms/logo";

interface Props {
    className?: string;
    minimal: boolean;
}

export const SideBar = ({ className, minimal }: Props) => {
    const section = useSectionContext(ContentType.SideBar, null, null, null, null);
    const loggedIn = useDisplayLoggedIn();

    return (
        <nav tabIndex={-1} className={`sideBar d7c ${className} --minimal-${minimal}`}>
            <Logo />
            <ScrollerWithFade disableFade={minimal}>
                <Main context={section} minimal={minimal} />
                {minimal && <hr />}
                <MyMusic section={section} minimal={minimal} />
                {loggedIn && minimal && <hr />}
                {loggedIn && !minimal && <MyMusicPlaylists context={section} />}
            </ScrollerWithFade>

            {loggedIn && <CreatePlaylistButton minimal={minimal} />}
        </nav>
    );
};
