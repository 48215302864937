import { h } from "preact";
import { useCallback } from "preact/hooks";
import "./InAppFeedbackResponseRequest.scss";
import { store } from "global";
import { WebAppBrand } from "shared/models";
import { useTranslations } from "global/config";
import { environment } from "services/environment";
import { CheckBoxButton } from "components/atoms/controls/checkBoxButton";
import type { TextInputState } from "components/atoms/controls/textInput";
import { TextInput } from "components/atoms/controls/textInput";
import { Control } from "components/molecules/controlGroup/Control";
import { InputSection } from "components/molecules/inputSection";

interface InAppFeedbackResponseRequestProps {
    disabled: boolean;
    label: string;
    onBlur: () => void;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onChange: (value: string) => void;
    onFocus: () => void;
    state: TextInputState;
    value: string;
}

export const InAppFeedbackResponseRequest = ({ state, label, disabled, onChange, onFocus, onBlur, value, onOpenChange, open }: InAppFeedbackResponseRequestProps) => {
    const translations = useTranslations();
    const check = useCallback(() => {
        onFocus();
        onOpenChange(!open);
    }, [open, onFocus, onOpenChange]);

    const onBlurFn = useCallback(() => {
        onBlur();
        return true;
    }, [onBlur]);

    if (!isFeedbackResponseEnabled()) return null;

    return (
        <div className={`inAppFeedbackResponseRequest lRT --open-${open}`}>
            <div className="top">
                <Control title={translations.FeedbackModalEmailTitleWeb} left={<CheckBoxButton checked={open} onChange={check} />} />
            </div>
            {open && (
                <div className="content">
                    <div className="text">{translations.FeedbackModalEmailTextWeb}</div>
                    <InputSection state={state} label={label}>
                        <TextInput
                            autoComplete="email"
                            disabled={disabled}
                            onBlur={onBlurFn}
                            onChange={onChange}
                            onFocus={onFocus}
                            placeholder={translations.FeedbackModalEmailPlaceholderWeb}
                            state={state}
                            type="email"
                            value={value}
                        />
                    </InputSection>
                </div>
            )}
        </div>
    );
};

export function isFeedbackResponseEnabled(): boolean {
    if (store.getState().controlPanel.enableFeedbackResponse !== true) return false;
    switch (environment.webAppBrand) {
        case WebAppBrand.Telmore:
            return false;
        case WebAppBrand.YouSee:
            return true;
    }
}
