import { h } from "preact";
import { useCallback } from "preact/hooks";
import { isDraggableCurrentLane } from "./isDraggableCurrentLane";
import type { LaneProps } from "./LaneProps";
import { useTranslations } from "global/config";
import { getLinkFromResource } from "services/resource";
import type { DraggablePlayableModel } from "models/app";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import { useQueueLaneTrackParentFromState } from "components/shared/hooks";
import { DropZone } from "components/molecules/dropZone";
import { QueueTrackRow } from "components/molecules/preview/rows";
import { QueueListView } from "components/organisms/recyclerView";

interface Props extends LaneProps {
    isPriorityLaneEmpty: boolean;
}
export const FlowLane = ({ items, dragProps, sectionContext, dragSourceId, onDrop, isPriorityLaneEmpty, scrollOnTrackClickFn }: Props) => {
    const trackParent = useQueueLaneTrackParentFromState(items[0]?.parentId ?? null);
    const flowLaneTitle = getLinkFromResource(trackParent, sectionContext)?.text;
    const translations = useTranslations();
    const allowDrop = useCallback((item: DraggablePlayableModel) => !isDraggableCurrentLane(item), []);

    const mapper = useCallback(
        (track: QueueTrackModel) => (
            <QueueTrackRow
                allowDrop={allowDrop}
                scrollOnTrackClickFn={scrollOnTrackClickFn}
                dragProps={dragProps}
                dropProps={{ dragSourceId, onDrop: (item) => onDrop(item, track.lane, false, track) }}
                key={track.queueId}
                queueTrack={track}
                sectionContext={sectionContext}
            />
        ),
        [allowDrop, dragProps, dragSourceId, onDrop, scrollOnTrackClickFn, sectionContext]
    );

    return (
        <section className={"flowLane"}>
            <div className="top">
                {isPriorityLaneEmpty && <DropZone allowDrop={allowDrop} customClass="first" onDropFn={(item) => onDrop(item, QueueLane.Priority, true)} />}
                {flowLaneTitle && <h1 className="headline">{`${translations.QueueNextFrom} ${flowLaneTitle}`}</h1>}
                <DropZone allowDrop={allowDrop} customClass="second" onDropFn={(item) => onDrop(item, QueueLane.Flow, false)} />
            </div>
            <QueueListView resources={items} mapper={mapper} />
            <div className="bottom">
                <DropZone allowDrop={allowDrop} onDropFn={(item) => onDrop(item, QueueLane.Flow, true)} />
            </div>
        </section>
    );
};
