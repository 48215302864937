import { getBrowserAudio } from ".";
import { setPlayState, setPlayStateAndPosition } from "../element";
import { getAudioByIdFromState } from "services/player/inputs/service/helpers";
import { AudioInputType } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";
import type { BrowserAudioItemModel } from "models/app/player/output";

export function updatePlayStateFromAudio(audio: AudioInputItemModel) {
    const browserAudio = getBrowserAudio(audio.audioId);
    if (!browserAudio) return;

    _updatePlayState(audio, browserAudio);
}

export function updatePlayState(browserAudio: BrowserAudioItemModel) {
    const audio = getAudioByIdFromState(browserAudio.audioId);
    if (!audio) return;

    _updatePlayState(audio, browserAudio);
}

function _updatePlayState(audio: AudioInputItemModel, browserAudio: BrowserAudioItemModel) {
    if (audio.input === AudioInputType.PlayQueue) setPlayStateAndPosition(browserAudio, audio.playState, audio.position);
    else setPlayState(browserAudio, audio.playState);
}
