import type { ArtistLinkModel } from "./ArtistModel";
import { createArtistLinkModel } from "./ArtistModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { ResourceModelLinkInterface } from "./ResourceModel";
import type { TrackPreviewModel } from "./TrackModel";
import type { ArtistTracksPageFragment, ArtistPopularTracksSectionFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type ArtistTracksSectionData = ArtistPopularTracksSectionFragment;
type ArtistTracksPageData = ArtistTracksPageFragment;

export type ArtistTracksModel = ArtistTracksSectionModel;
export type ArtistTracksSectionModel = ({ type: DomainModelType.Section } & ArtistTracksSection) | ArtistTracksPageModel;
export type ArtistTracksPageModel = { type: DomainModelType.Page } & ArtistTracksPage;

interface ArtistTracksSection extends ResourceModelLinkInterface, PlayQueuePlayableModelInterface {
    readonly contentType: ContentType.ArtistTracks;
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

interface ArtistTracksPage extends ArtistTracksSection {
    readonly artist: ArtistLinkModel;
}

export function createArtistTracksSectionModel(data: ArtistTracksSectionData, artistId: string): ArtistTracksSectionModel {
    return {
        contentType: ContentType.ArtistTracks,
        id: artistId,
        graphType: data.__typename,
        type: DomainModelType.Section,
        cover: null,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0, 5) : null, //TODO: ugly hack until backend gives correct totalCount
        trackCount: null
    };
}

export function createArtistTracksPageModel(data: ArtistTracksPageData): ArtistTracksPageModel {
    return {
        ...createArtistTracksSectionModel(data, data.id),
        type: DomainModelType.Page,
        artist: createArtistLinkModel(data)
    };
}
