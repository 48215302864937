import type { Action } from "../actions";
import { getCurrentAppVersion } from "shared/services/appSettings";
import { UPDATE_APP_VERSION, REHYDRATE } from "global/actions";
import { getNewInstallationId } from "services/app/appService";
import type { AppModel } from "models/app/AppModel";

const initialState: AppModel = {
    version: getCurrentAppVersion(),
    installationId: getNewInstallationId(),
    dbVersion: 1
};

export function appReducer(state: AppModel = initialState, action: Action): AppModel {
    switch (action.type) {
        case UPDATE_APP_VERSION:
            return { ...state, version: action.payload.version };
        case REHYDRATE: {
            const app = action.payload?.app;

            let result: AppModel;
            if (app == null) result = initialState;
            else if (app.dbVersion == undefined) result = { ...state, dbVersion: initialState.dbVersion };
            else result = app;

            // let us specify the it as part of the querystring, to facilitate CIAM testing
            const params = new URLSearchParams(window.location.search);
            const newXClientId = params.get("x-client-id");
            if (newXClientId) {
                result.installationId = newXClientId;
            }

            return result;
        }
        default:
            return state;
    }
}
