import { getCurrentLaneIndex } from "../../inputs/playQueue/helpers";
import { isInputPlayQueueFromState, getAudioInputReportId, getAudioSendPlaybackReport } from "../helpers";
import { updateAudios } from "./updateAudios";
import { store } from "global";
import { DefaultLogMessage, log, LogTag } from "services/logger";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import type { NormalizedData } from "services/normalizeData";
import { getNewCastItemId } from "services/player/controllers/chromeCast/service/helpers";
import { isAudioLoaded } from "services/player/helpers";
import { getPlayerOutputTypeFromState } from "services/player/outputs/service";
import { LoginState } from "models/app";
import type { PausePositionModel, PlayPausePositionModel } from "models/app/player";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemPlayQueueModel, QueueTrackModel } from "models/app/player/input";
import { getIsEndlessPlayOnFromState } from "components/shared/hooks";

export function updateAudiosFromPlayQueue(queueAdded: NormalizedData<AnalyticsQueueAddedPropertiesGroup> | null, context: AudioContextModel) {
    if (!isInputPlayQueueFromState()) {
        log.error({ code: "web-211003-1841", msg: DefaultLogMessage.UnexpectedValue });
        return;
    }

    const queueTracks = getAllQueueTracks();
    const currentLaneIndex = getCurrentLaneIndex(queueTracks);
    const newAudios = queueTracks.map((queueTrack, i) => createOrUpdateAudioFromQueueTrack(queueTrack, i, currentLaneIndex));

    // TODO remove this when playback is verified on Chromecast
    // const isEqual = () => {
    //     let i = 0;
    //     for (;;) {
    //         const a = oldAudios[i];
    //         const b = newAudios[i];
    //         if (a == undefined && b == undefined) return true;
    //         if (a?.queueId !== b?.queueId) return false;
    //         if (a?.index !== b?.index) return false;
    //         if (a?.previousQueueLane !== b?.previousQueueLane) return false;
    //         if (a?.queueLane !== b?.queueLane) return false;
    //         if (a?.reportId !== b?.reportId) return false;
    //         if (a?.sample !== b?.sample) return false;
    //         if (a?.playState !== b?.playState) return false;
    //         if (a?.sendReport !== b?.sendReport) return false;
    //         if (a?.errorCount !== b?.errorCount) return false;
    //         if (a?.url !== b?.url) return false;
    //         i++;
    //     }
    // };

    // if (isEqual()) return;

    log.debug([LogTag.Playback], () => ({
        code: "web-210212-1634",
        msg: "audios has been updated",
        data: { audios: newAudios }
    }));

    updateAudios(newAudios, queueAdded, context);
}

function getAllQueueTracks(): QueueTrackModel[] {
    const { playQueue, endlessPlay } = store.getState().queue;
    const tracks = getIsEndlessPlayOnFromState() ? [...playQueue, ...endlessPlay] : playQueue;

    return tracks;
}

function createOrUpdateAudioFromQueueTrack(queueTrack: QueueTrackModel, queueTrackIndex: number, currentLaneIndex: number): AudioInputItemPlayQueueModel {
    const audioId = getAudioId(queueTrack);
    const oldAudios = store.getState().audioInput.audios;

    const audio = oldAudios.find((audio) => audio.audioId === audioId) as AudioInputItemPlayQueueModel | undefined;
    if (audio) return updateAudio(audio, queueTrack, queueTrackIndex, currentLaneIndex);
    else return createNewAudioFromQueueTrack(audioId, queueTrack, queueTrackIndex, currentLaneIndex);
}

function createNewAudioFromQueueTrack(audioId: string, queueTrack: QueueTrackModel, queueTrackIndex: number, currentLaneIndex: number): AudioInputItemPlayQueueModel {
    const { sample, index, queueLane, reportId, sendReport } = getAudioProperties(audioId, queueTrack, queueTrackIndex, currentLaneIndex);

    let analytics2: AudioInputItemPlayQueueModel["analytics2"];

    if (queueTrack.analytics2) {
        analytics2 = { ...queueTrack.analytics2, loadData: null };
    } else {
        log.info({ code: "web-221111-1232", msg: "analytics missing on queueTrack" });
        analytics2 = { playableData: null, loadData: null, addedDataId: null };
    }

    const newPosition: PausePositionModel = {
        pausePosition: 0
    };

    return {
        logTitle: queueTrack.track.title,
        queueLane: queueTrack.lane,
        analytics2,
        audioId,
        chromecastId: getNewCastItemId(),
        duration: !sample ? queueTrack.track.duration : 30,
        index,
        input: AudioInputType.PlayQueue,
        errorCount: 0,
        playState: PlayState.Stopped,
        playbackContext: queueTrack.track.playbackReportContext,
        position: newPosition,
        previousQueueLane: queueLane,
        reportId,
        sample,
        sendReport,
        queueId: queueTrack.queueId,
        trackId: queueTrack.track.id,
        url2: null
    };
}

function updateAudio(audio: AudioInputItemPlayQueueModel, queueTrack: QueueTrackModel, queueTrackIndex: number, currentLaneIndex: number): AudioInputItemPlayQueueModel {
    const { sample, index, queueLane, reportId, sendReport } = getAudioProperties(audio.audioId, queueTrack, queueTrackIndex, currentLaneIndex);

    const previousQueueLane = queueLane !== audio.queueLane ? audio.queueLane : audio.previousQueueLane;
    const playState = index === 0 ? audio.playState : PlayState.Stopped;

    const loaded = isAudioLoaded(playState);
    const position: PlayPausePositionModel = loaded ? audio.position : { pausePosition: 0 };

    

    return {
        ...audio,
        index,
        playState,
        position,
        previousQueueLane,
        queueLane,
        reportId,
        sample,
        sendReport
    };
}

function getAudioId(queueTrack: QueueTrackModel) {
    const sample = store.getState().user.state !== LoginState.LoggedIn;
    const audioId = queueTrack.queueId + (sample ? "_sample" : "_full");

    return audioId;
}

function getAudioProperties(audioId: string, queueTrack: QueueTrackModel, queueTrackIndex: number, currentLaneIndex: number) {
    const loginState = store.getState().user.state;
    const output = getPlayerOutputTypeFromState();

    const sample = loginState !== LoginState.LoggedIn;
    const reportId = getAudioInputReportId(audioId, output);
    const index = queueTrackIndex - currentLaneIndex;
    const queueLane = queueTrack.lane;
    const sendReport = getAudioSendPlaybackReport(AudioInputType.PlayQueue, output, sample);

    return { sample, reportId, index, queueLane, sendReport };
}
