import { getBrowserNameAndVersion, BrowserName } from "services/app";

export enum BrowserCompatibility {
    Compatible = "Compatible",
    Crawler = "Crawler",
    TooOld = "TooOld",
    Unknown = "Unknown"
}

export function getBrowserCompatibility(): BrowserCompatibility {
    const { name, version } = getBrowserNameAndVersion();
    switch (name) {
        case BrowserName.Crawler:
            return BrowserCompatibility.Crawler;
        case BrowserName.Unknown:
        case BrowserName.InternetExplorer:
        case BrowserName.Opera:
            return BrowserCompatibility.Unknown;
        case BrowserName.Chrome:
        case BrowserName.Chromium:
        case BrowserName.Firefox:
        case BrowserName.Edge:
            if (version != null) return version >= 85 ? BrowserCompatibility.Compatible : BrowserCompatibility.TooOld;
            return BrowserCompatibility.Unknown;
        case BrowserName.Safari:
            if (version != null) return version >= 14 ? BrowserCompatibility.Compatible : BrowserCompatibility.TooOld;
            return BrowserCompatibility.Unknown;
    }
    name;
}
