import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { getNavigationRootPreview } from "services/navigationStack/navigationStack";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { LocationContentType } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { NavigationPageRootName } from "models/view/navigationStack";

export type AnalyticsRootSectionProperties = {
    rootSectionName?: string;
    rootSectionId?: string;
    rootSectionRank?: number;
    rootSectionDisplay?: ResourceDisplayType;
    rootSectionType?: LocationContentType;
};

export function getAnalyticsRootSectionProperties(preview?: PreviewContextModel): AnalyticsRootSectionProperties {
    const root = getNavigationRootPreview(preview);
    if (!root) return {};

    if (root.page.root === NavigationPageRootName.Deeplink) return {};
    if (root.page.root === NavigationPageRootName.EndlessPlay) return {};

    let rootSectionName: string | undefined;
    let rootSectionId: string | undefined;
    let rootSectionRank: number | undefined;
    let rootSectionDisplay: ResourceDisplayType | undefined;
    let rootSectionType: LocationContentType | undefined;

    if (root.page.root === NavigationPageRootName.Explore) {
        rootSectionName = root.preview.name ?? undefined;
        rootSectionId = root.preview.id ?? undefined;
        rootSectionRank = root.preview.rank ?? undefined;
        rootSectionDisplay = root.section.displayType ?? undefined;
        rootSectionType = root.section.type;
    } else {
        rootSectionName = root.section.name ?? undefined;
        rootSectionId = root.section.id ?? undefined;
        rootSectionRank = root.section.visibleRank ?? undefined;
        rootSectionDisplay = root.section.displayType ?? undefined;
        rootSectionType = root.section.type;
    }

    return {
        rootSectionName,
        rootSectionId,
        rootSectionRank,
        rootSectionDisplay,
        rootSectionType
    };
}

export function convertRootSectionProperties(properties: AnalyticsRootSectionProperties) {
    const data = createAnalyticsData();

    const rank = properties.rootSectionRank != null ? properties.rootSectionRank + 1 : undefined;

    SetAnalyticsProp(data, "event", "RootSectionName", properties.rootSectionName as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "RootSectionID", properties.rootSectionId as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "RootSectionRank", rank, true);
    SetAnalyticsProp(data, "event", "RootSectionDisplayType", convertRootSectionDisplayType(properties.rootSectionName, properties.rootSectionDisplay ?? null), true);

    return data;
}

function convertRootSectionDisplayType(rootSectionName: string | undefined, display: ResourceDisplayType | null): AnalyticsKey | null {
    if (display == null) return null;
    switch (display) {
        case ResourceDisplayType.Grid:
            return "Grid";
        case ResourceDisplayType.List:
            return "List";
        case ResourceDisplayType.ListLarge:
            return "LargeList";
        case ResourceDisplayType.Scroller:
            return "Scroller";
        case ResourceDisplayType.LargeScroller:
            return "LargeScroller";
        case ResourceDisplayType.Single:
            return "Single";
        case ResourceDisplayType.LargeSingle:
            return "LargeSingle";
        case ResourceDisplayType.PromoSingle:
            return "PromoSingle";
        case ResourceDisplayType.Deeplink:
        case ResourceDisplayType.ListMedium:
        case ResourceDisplayType.Top:
        case ResourceDisplayType.Unknown:
            log.error({ code: "web-211004-2247", msg: DefaultLogMessage.UnexpectedValue, data: { display, rootSectionName } });
            return null;
    }
    display;
}
