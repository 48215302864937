import { ComponentChild, h } from "preact";
import "./EmptyState.scss";

interface Props {
    title: string;
    subtitle: string;
    button?: ComponentChild;
}

export const EmptyState = ({ title, subtitle, button }: Props) => (
    <div className="emptyState fpo">
        <h2>{title}</h2>
        <p>{subtitle}</p>
        {
            button
        }
    </div>
);
