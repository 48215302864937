import { h } from "preact";
import { useMemo } from "preact/hooks";
import "./Logo.scss";
import { Button, ButtonDesign } from "../controls/button";
import { useTheme } from "components/app/hooks";
import { assetPaths } from "global/constants";
import { ContentType } from "models/ModelType";
import { Theme } from "models/view/Theme";
import { useLinkAndPreviewContext, useSectionContext } from "components/shared/hooks";

export function Logo() {
    const theme = useTheme();
    const url = useLogoUrl(theme);

    const section = useSectionContext(ContentType.SideBar, null, null, null, null);
    const link = useLinkAndPreviewContext(ContentType.FrontPage, null, section, null);

    return (
        <Button className={`logo y4m`} link={link} design={ButtonDesign.None}>
            <img src={url} />
        </Button>
    );
}

function useLogoUrl(theme: Theme): string {
    return useMemo(() => {
        switch (theme) {
            case Theme.Light:
                return assetPaths.logoLight;
            case Theme.Dark:
                return assetPaths.logoDark;
        }
    }, [theme]);
}
