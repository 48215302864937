import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getLiveRadioCategoryLiveRadiosPagination, useLiveRadioCategoryPage } from "services/backend/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    liveRadioCategoryId: string;
}

export const LiveRadioCategoryPage = ({ liveRadioCategoryId }: Props) => {
    const query = useLiveRadioCategoryPage({ id: liveRadioCategoryId, first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => getLiveRadioCategoryLiveRadiosPagination({ first, after, id: liveRadioCategoryId }), [liveRadioCategoryId]);

    return <ShowAllPageTemplate query={query} type={ContentType.LiveRadioCategory} items={product?.radios ?? null} fetchFn={fetchFn} display={ResourceDisplayType.Grid} />;
};
