import { updateLocalCastQueue } from "./localCastQueue";
import { isUnloading } from "global/config";
import { log, LogTag } from "services/logger";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";

let current: OperationSource | undefined;
const waiting: (() => void)[] = [];

export enum OperationSource {
    Local = "Local",
    Chromecast = "Chromecast"
}

export function inOperation(): boolean {
    return !!current;
}

export function resetOperation() {
    current = undefined;
}

export async function tryOperation(fn: () => Promise<boolean>, operation: OperationSource): Promise<boolean> {
    if (!isControllerChromecastFromState()) return false;
    if (isUnloading) return false;

    if (current) {
        if (current === OperationSource.Local && operation == OperationSource.Local) {
            log.info({ tags: [LogTag.Chromecast], code: "web-230214-0909", msg: "queuing local operation" });

            return new Promise<boolean>((resolve) => {
                const waitingFn = async () => {
                    const result = await tryOperation(fn, operation);
                    resolve(result);
                };
                waiting.push(waitingFn);
            });
        }

        log.info({ tags: [LogTag.Chromecast], code: "web-230214-0909", msg: `prevented operation: ${operation}` });
        return false;
    }

    current = operation;
    if(current === OperationSource.Local) updateLocalCastQueue();

    let result: boolean;

    try {
        result = await fn();
    } catch (e) {
        result = false;
    }

    current = undefined;

    const next = waiting.shift();
    next && next();

    return result;
}

export async function waitOne(): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        });
    });
}
