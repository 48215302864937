import { updatePlayQueue } from "./updatePlayQueue";
import { store } from "global";
import type { AudioContextModel } from "models/app/player/AudioContext";

export const removeItemFromQueue = async (queueId: string, isPreviewLane: boolean, context: AudioContextModel) => {
    let queue = store.getState().queue;

    if (isPreviewLane) {
        queue = {
            ...queue,
            endlessPlay: queue.endlessPlay.filter((item) => item.queueId !== queueId)
        };
    } else {
        queue = {
            ...queue,
            playQueue: queue.playQueue.filter((item) => item.queueId !== queueId)
        };
    }
    await updatePlayQueue(queue, context, false, null);
};
