import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { useTranslations } from "global/config";
import { removeTrackFromPlaylist } from "services/playlist";
import type { PlaylistModel, TrackPreviewModel } from "models/domain";
import { IconName } from "components/atoms/icon";

interface Props {
    track: TrackPreviewModel | null;
    playlist: PlaylistModel;
    showIcon: boolean;
}

export const PlaylistRemoveTrackLink = ({ showIcon, track, playlist }: Props) => {
    const icon = showIcon ? IconName.Trash : undefined;
    const translations = useTranslations();

    const onRemoveTrack = useCallback(async () => {
        await removeTrackFromPlaylist(track, playlist);
    }, [track, playlist]);

    return (
        <Link icon={icon} closeOnClick disabled={!track} onClick={onRemoveTrack}>
            {translations.ContextOptionRemoveFromPlaylist}
        </Link>
    );
};
