export interface HlsPreloadAudioModel {
    manifest: HlsPreloadManifestModel | null;
    lock: boolean;
    status: PreloadFetchResultStatus;
    fullyLoaded: boolean;
}

export interface HlsPreloadManifestModel {
    chunks: HlsPreloadAudioChunkModel[];
    duration: number;
    encryption: HlsPreloadAudioEncryptionKeyModel;
    indexUrl: string;
}

export interface HlsPreloadAudioEncryptionKeyModel {
    url: string;
    loaded: boolean;
}

export interface HlsPreloadAudioChunkModel {
    url: string;
    position: number;
    duration: number;
    loaded: boolean;
}

export interface PreloadFetchResult<T> {
    value?: T;
    status: PreloadFetchResultStatus;
}

export enum PreloadFetchResultStatus {
    OK = "OK",
    NetworkError = "NetworkError",
    UnknownError = "UnknownError"
}
