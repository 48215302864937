import { MessageBus } from ".";
import type { Message, MessageListener, Unsubscriber } from "./messageBus";

export class TypedMessageBus<TAction extends { type: string }> extends MessageBus {
    subscribeOne<K extends TAction["type"]>(messageType: K, listener: (payload: Extract<TAction, { type: K }>) => (void | Promise<void>)): Unsubscriber;

    subscribeOne(messageType: string, listener: MessageListener): Unsubscriber;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribeOne(messageType: any, listener: any): Unsubscriber {
        if (typeof this.listeners[messageType] !== "object") {
            this.listeners[messageType] = [];
        }

        const emitAndUnsubscribe = (message: Message) => {
            this.unsubscribeListener(messageType, emitAndUnsubscribe);
            listener(message);
        };

        this.listeners[messageType].push(emitAndUnsubscribe);
        return () => this.unsubscribeListener(messageType, emitAndUnsubscribe);
    }

    subscribeEvery<K extends TAction["type"]>(messageType: K, listener: (payload: Extract<TAction, { type: K }>) => (void | Promise<void>)): Unsubscriber;
    subscribeEvery(messageType: string, listener: MessageListener): Unsubscriber;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subscribeEvery(messageType: any, listener: any): Unsubscriber {
        if (typeof this.listeners[messageType] !== "object") {
            this.listeners[messageType] = [];
        }
        this.listeners[messageType].push(listener);
        return () => this.unsubscribeListener(messageType, listener);
    }
}
