import { h } from "preact";
import { useContext } from "preact/hooks";
import { ResourceMenuTop } from "./tops";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import { AddToLastQueueLink, AddToPlaylistLink, AddToMyMusicLink, AddToQueueLink, ShareLink, PlaylistDeleteLink, PlaylistRenameLink, PlaylistVisibilityLink } from "../links";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlaylistPageModel, PlaylistPreviewModel } from "models/domain";

interface Props {
    playlist: PlaylistPreviewModel | PlaylistPageModel | null;
    preview: PreviewContextModel;
    assumedFavorite?: boolean;
}

export const PlaylistContextMenu = ({ playlist, preview, assumedFavorite }: Props) => {
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    const isOwned = playlist ? playlist.owner : true;
    const isPlaylistEmpty = !playlist ? true : playlist.duration === 0;

    return (
        <Sheet top={<ResourceMenuTop context={preview} />}>
            {!isOwned && <AddToMyMusicLink context={preview} playable={playlist} showIcon={showIcon} assumedFavorite={assumedFavorite} />}
            <AddToPlaylistLink playable={playlist} context={preview} showIcon={showIcon} />
            {isOwned && <PlaylistVisibilityLink playable={playlist} showIcon={showIcon} />}
            {isOwned && <PlaylistRenameLink playable={playlist} showIcon={showIcon} />}
            {!isPlaylistEmpty && <AddToQueueLink playable={playlist} context={preview} showIcon={showIcon} />}
            {!isPlaylistEmpty && <AddToLastQueueLink playable={playlist} context={preview} showIcon={showIcon} />}
            <ShareLink context={preview} showIcon={showIcon} />
            {isOwned && <PlaylistDeleteLink playable={playlist} preview={preview} showIcon={showIcon} />}
        </Sheet>
    );
};
