import { Fragment, h } from "preact";
import { IconMenuTop } from "./tops";
import { AddProfileLink, CurrentProfileLink, GoToProfilesLink, ProfileLink } from "../links";
import { GetDesktopAppLink } from "../links/GetDesktopAppLink";
import { GiveFeedbackLink } from "../links/GiveFeedbackLink";
import { GoToSettingsLink } from "../links/GoToSettingsLink";
import { LogInOutLink } from "../links/LogInOutLink";
import { Sheet } from "../Sheet";
import { Feature, isFeatureEnabled, useTranslations } from "global/config";
import { hasPermissionFromToken, useDisplayLoggedIn } from "services/user";
import { IconName } from "components/atoms/icon";
import { useSelector } from "react-redux";
import { RootModel } from "models/app";

export function AccountContextMenu() {
    const loggedIn = useDisplayLoggedIn();
    const translations = useTranslations();
    const profiles = useSelector((root: RootModel) => root.user.profiles);
    const profilesLimit = useSelector((root: RootModel) => root.user.profilesNumberLimit) ?? 0;
    const showProfiles = loggedIn && profiles.length > 0 && hasPermissionFromToken("prof") && isFeatureEnabled(Feature.Profiles);

    return (
        <Sheet top={<IconMenuTop icon={IconName.Profile} title={translations.AccountMenuTitleWeb} />}>
            {
                showProfiles && <Fragment>
                    {
                        profiles.filter(n => n.current).map(profile => (
                            <CurrentProfileLink profile={profile} />
                        ))
                    }
                    {
                        profiles.filter(n => !n.current).map(profile => (
                            <ProfileLink profile={profile} />
                        ))
                    }
                    {
                        profiles.length < profilesLimit &&
                        <AddProfileLink />
                    }
                    {showProfiles && <GoToProfilesLink showIcon={false} />}
                    <br />
                </Fragment>
            }
            {
                !showProfiles &&
                <CurrentProfileLink />
            }
            <GoToSettingsLink showIcon={false} />
            {loggedIn && <GiveFeedbackLink showIcon={false} />}
            <GetDesktopAppLink showIcon={false} />
            <br />
            <LogInOutLink showIcon={false} />
        </Sheet>
    );
}
