import { h } from "preact";
import { Link } from "../Link";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import type { ArtistLinkModel } from "models/domain";
import { IconName } from "components/atoms/icon";

interface Props {
    artist: ArtistLinkModel | null;
    showIcon: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FollowArtistLink = ({ showIcon, artist }: Props) => {
    const translations = useTranslations();
    const click = () => {
        log.error({ code: "web-210519-1229", msg: DefaultLogMessage.NotImplemented });
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    };
    return (
        <Link icon={showIcon ? IconName.Radio : undefined} onClick={click}>
            {translations.ContextOptionFollowArtist}
        </Link>
    );
};
