import { getCastPlayer, getCastPlayerController } from "../../setup";
import { log, LogTag } from "services/logger";

export let castSetVolumeTimeStamp = 0;

export function castSetVolume(volume: number) {
    const player = getCastPlayer();
    if (!player) return;

    const playerController = getCastPlayerController();
    if (!playerController) return;

    if (volume === player.volumeLevel) return;
    castSetVolumeTimeStamp = new Date().getTime();

    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1205", msg: `castSetVolume: ${volume}` });

    player.volumeLevel = volume;
    playerController.setVolumeLevel();
}
