import { h } from "preact";
import "./CheckMark.scss";
import { Icon, IconName } from "components/atoms/icon";

interface Props {
    className?: string;
    checked?: boolean;
    onChange?: () => void;
    name: string;
    type: "checkbox" | "radio";
}

export const CheckMark = ({ className, checked, onChange, name, type }: Props) => {
    return (
        <div className={`checkMark M0e ${className ?? ""} --checked-${checked}`}>
            <Icon icon={IconName.Checkmark} />
            <input checked={checked} type={type} name={name} onChange={onChange} />
        </div>
    );
};
