import { useEffect, useMemo, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { useIsAppRunAsPWA } from "./useAppRunAsPWA";
import { useShowNativeMobileAppPrompt } from "./useShowNativeMobileAppPrompt";
import { BrowserCompatibility, getBrowserCompatibility } from "services/compatibility/compatibilityService";
import { RootModel } from "models/app";
import { showBrowserCompatibilityModal, showBrowserTooOldModal } from "components/organisms/modal/modals/BrowserCompatibilityModal";
import { showPwaInstallMessageModal } from "components/organisms/modal/modals/PwaInstallMessageModal";

export function useAppMessages() {
    let open = false;

    open = useBrowserCompatibilityMessage(open);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    open = usePWAInstallMessage(open);
}

function useBrowserCompatibilityMessage(skip: boolean) {
    const [show, setOpen] = useState(false);
    const nativeAppMobileAppPrompt = useShowNativeMobileAppPrompt();

    useEffect(() => {
        if (skip || nativeAppMobileAppPrompt) return;

        const compatibility = getBrowserCompatibility();
        switch (compatibility) {
            case BrowserCompatibility.Compatible:
                setOpen(false);
                return;
            case BrowserCompatibility.TooOld:
                setOpen(true);
                showBrowserTooOldModal().then(() => {
                    setOpen(false);
                });
                return;
            case BrowserCompatibility.Unknown:
                setOpen(true);
                showBrowserCompatibilityModal().then(() => {
                    setOpen(false);
                });
                return;
        }
    }, [skip, nativeAppMobileAppPrompt]);

    return show;
}

function usePWAInstallMessage(skip: boolean) {
    const waiting = useSelector((root: RootModel) => root.notifications.pwaInstallMessageWaiting);
    const isPWA = useIsAppRunAsPWA();
    const show = useMemo(() => waiting && isPWA, [waiting, isPWA]);

    useEffect(() => {
        if (skip) return;
        if (show) showPwaInstallMessageModal();
    }, [show, skip]);

    return show;
}
