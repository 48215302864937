import { ContextMenuLinkModel } from "./ContextMenuLinkModel";
import { ContextMenuSheetModel } from "./ContextMenuSheetModel";
import { OpenMenuResult } from "services/contextMenus";

export enum ContextMenuAlign {
    Below,
    Right,
    UpperRight
}

export interface ContextMenuScreenPosition {
    x: number;
    y: number;
}

export enum ContextMenuPositionType {
    Anchor,
    ScreenPosition,
    ScreenCorner,
}

export enum ContextMenuItemType {
    Link,
    Sheet
}

export type ContextMenuPosition =
    | { type: ContextMenuPositionType.Anchor; element: HTMLElement }
    | { type: ContextMenuPositionType.ScreenPosition; screenPosition: ContextMenuScreenPosition }
    | { type: ContextMenuPositionType.ScreenCorner; screenPosition: ContextMenuScreenPosition };

export type ContextMenuItemModel = ContextMenuLinkModel | ContextMenuSheetModel;

export interface ContextMenuItemModelBase {
    children: ContextMenuItemModel[];
    id: string;
    layer: ContextMenuLayerModel;
    parent: ContextMenuItemModel | null;
    position: ContextMenuPosition | null;
    tree: ContextMenuTreeModel;
}

export interface ContextMenuTreeModel {
    layers: ContextMenuLayerModel[];
}

export interface ContextMenuLayerModel {
    onClose?: (result: OpenMenuResult) => void;
}
