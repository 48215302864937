import { log } from "../initLoggerService";
import type { AnalyticsKey } from "global/config";
import { analyticsKeys } from "global/config";
import { flattenArrayOfPromisesOrData } from "services/utils";

export type AnalyticsProp = AnalyticsPropSet | AnalyticsPropIncrement | AnalyticsPropFirst;

type Target = "event" | "user" | "app";

interface AnalyticsPropSet {
    target: Target;
    mode: "set";
    data: AnalyticsKey | number | boolean | Date | string[];
}

interface AnalyticsPropIncrement {
    target: Target;
    mode: "increment";
    data: number | undefined;
}

interface AnalyticsPropFirst {
    target: Target;
    mode: "first";
    data: AnalyticsKey | number | boolean | Date;
}
export interface AnalyticsData {
    data: [string, AnalyticsProp][];
}

export function createAnalyticsData(): AnalyticsData {
    return { data: [] };
}

export async function combineAnalyticsData(...maps: (AnalyticsData | null | Promise<AnalyticsData | null>)[]): Promise<AnalyticsData> {
    const data = (await flattenArrayOfPromisesOrData(maps))
        .filter((map): map is AnalyticsData => !!map)
        .map((map) => [...map.data])
        .flat();
    const map: AnalyticsData = { data };
    return map;
}

export function SetAnalyticsProp(map: AnalyticsData, target: Target, key: AnalyticsKey, data: AnalyticsPropSet["data"] | null | undefined, allowNull?: true) {
    if (data === null || data === undefined) {
        if (!allowNull) log.warn({ code: "web-220927-1105", msg: `analytics key missing: ${key}` });
        return;
    }

    if (typeof data == "number") data = Math.floor(data);

    // eslint-disable-next-line no-prototype-builtins
    if (typeof data == "string" && (analyticsKeys as NonNullable<unknown>).hasOwnProperty(data)) data = analyticsKeys[data] as AnalyticsKey;

    const newKey = analyticsKeys[key];
    map.data.push([newKey, { target, mode: "set", data }]);
}

export function IncrementAnalyticsProp(map: AnalyticsData, target: "user", key: AnalyticsKey) {
    const newKey = analyticsKeys[key];
    map.data.push([newKey, { target, mode: "increment", data: 1 }]);
}

export function SetFirstAnalyticsProp(map: AnalyticsData, target: "app" | "user", key: AnalyticsKey, data: AnalyticsPropFirst["data"]) {
    const newKey = analyticsKeys[key];
    map.data.push([newKey, { target, mode: "first", data }]);
}
