import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { getAnalyticsSingleNotificationConverted, getAnalyticsSingleNotificationSeen } from "../properties/event";
import type { AnalyticsFeedCurrentNotifications } from "../properties/event";
import { getAnalyticsUserProperties } from "../properties/user";
import { convertNotificationFeedProperties, getAnalyticsNotificationFeedProperties } from "../properties/user/userNotificationFeedProperties";
import type { NotificationConversion, NotificationModel } from "models/domain/NotificationModel";

export async function getAnalyticsSingleNotificationSeenEvent(current: AnalyticsFeedCurrentNotifications, seen: NotificationModel): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        getAnalyticsSingleNotificationSeen(seen),
        convertNotificationFeedProperties(getAnalyticsNotificationFeedProperties()),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties()
    );
    return data;
}

export async function getAnalyticsSingleNotificationConvertedEvent(converted: NotificationModel, conversion: NotificationConversion): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        getAnalyticsSingleNotificationConverted(converted, conversion),
        convertNotificationFeedProperties(getAnalyticsNotificationFeedProperties()),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties()
    );
    return data;
}
