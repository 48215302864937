import { log, DefaultLogMessage } from "services/logger";
import { getCurrentLaneQueueId } from "services/player/inputs/inputs/playQueue/helpers";
import { playChange } from "services/player/inputs/service/actions";
import { getAudioByInputItemIdFromState } from "services/player/inputs/service/helpers";
import type { PausePositionModel } from "models/app/player";
import { PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { getAudioPropertiesFromState } from "components/shared/hooks";

export const rewind = (context: AudioContextModel) => {
    const playState = getAudioPropertiesFromState().playState;
    const play = playState === PlayState.Buffering || playState === PlayState.Playing;

    const queueId = getCurrentLaneQueueId();
    if (queueId == null) {
        log.error({ code: "web-210520-1622", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }
    rewindPlay(queueId, context, play);
};

function rewindPlay(queueId: string, context: AudioContextModel, play: boolean) {
    const item = getAudioByInputItemIdFromState(queueId);
    if (item == null) {
        log.error({ code: "web-210519-1146", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    const playState = play ? PlayState.Buffering : PlayState.Paused;
    const position: PausePositionModel = { pausePosition: 0 };

    playChange(item, playState, position, context);
}
