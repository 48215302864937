import { updateQueueLanes } from "./queueLanes";
import { store } from "global";
import { DefaultLogMessage, log } from "services/logger";
import { AudioInputType } from "models/app/player";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane, QueueMode } from "models/app/player/input";
import { ShuffleState } from "models/view";

export function getShuffleOn(shuffle: ShuffleState): boolean {
    return shuffle !== ShuffleState.Off;
}

export const isShuffleDisabled = () => {
    const { queue, player } = store.getState();
    const isModeMixRadio = queue.mode === QueueMode.MixRadio;
    const isInputLiveRadio = player.input === AudioInputType.LiveRadio;
    return isModeMixRadio || isInputLiveRadio;
};

export const getNextShuffleState = (currentShuffle: ShuffleState): ShuffleState => {
    switch (currentShuffle) {
        case ShuffleState.Heavy:
            return ShuffleState.Off;
        case ShuffleState.Light:
            return ShuffleState.Off;
        case ShuffleState.Off:
            return ShuffleState.Heavy;
    }
};

export const getShuffleStateWhenSetAndPlay = (currentShuffle: ShuffleState, payloadShuffle: ShuffleState | null): ShuffleState => {
    switch (currentShuffle) {
        case ShuffleState.Off:
        case ShuffleState.Light:
            if (payloadShuffle != null) return payloadShuffle;
            return ShuffleState.Off;
        case ShuffleState.Heavy:
            if (payloadShuffle === ShuffleState.Off) return payloadShuffle;
            return ShuffleState.Heavy;
    }
};

function shuffleByFisherYates<T>(array: T[]): T[] {
    let m = array.length;
    let tempItem;
    let currentItem;

    // While there remain items to shuffle…
    while (m) {
        // Pick a remaining item
        const originalCurrentItem: number | undefined = currentItem;
        currentItem = Math.floor(Math.random() * m);
        if (originalCurrentItem) {
            if (currentItem === originalCurrentItem - 1 || currentItem === originalCurrentItem + 1) {
                currentItem = Math.floor(Math.random() * m);
            }
        }
        m--;
        // And swap it with the current item.
        tempItem = array[m];
        array[m] = array[currentItem];
        array[currentItem] = tempItem;
    }

    return array;
}

export const updateOriginIndex = (playQueue: QueueTrackModel[], insertedAtIndex: number, amountOfTracksAdded: number) => {
    return playQueue.map((track) => {
        if (track.originalIndex == null) {
            log.error({ code: "web-210519-1153", msg: DefaultLogMessage.UnexpectedNull });
            return track;
        } else if (track.originalIndex >= insertedAtIndex) {
            return { ...track, originalIndex: track.originalIndex + amountOfTracksAdded };
        } else return track;
    });
};

export const shufflePlayQueue = (playQueue: QueueTrackModel[]): QueueTrackModel[] => {
    function shouldBeShuffled(item: QueueTrackModel) {
        return item.lane === QueueLane.Flow || item.lane === QueueLane.Memory;
    }

    const updateLane = (item: QueueTrackModel): QueueLane => {
        if (item.lane === QueueLane.Flow) return QueueLane.Flow;
        if (item.lane === QueueLane.Memory) return QueueLane.Flow;
        return item.lane;
    };

    const originalPlayQueue: QueueTrackModel[] = playQueue.map((item, i) => ({
        ...item,
        originalIndex: i,
        lane: updateLane(item)
    }));

    const prioritylane = originalPlayQueue.filter((item) => item.lane === QueueLane.Priority);
    const currentlane = originalPlayQueue.filter((item) => item.lane === QueueLane.Current);
    const lanesToShuffle = originalPlayQueue.filter((item) => shouldBeShuffled(item));

    const shuffledFlowlane = shuffleByFisherYates(lanesToShuffle);
    const newPlayQueue = currentlane.concat(prioritylane, shuffledFlowlane);

    return newPlayQueue;
};

const sortByOriginalIndex = (a: QueueTrackModel, b: QueueTrackModel) => {
    if (a.originalIndex == null || b.originalIndex == null) {
        log.error({ code: "web-210519-1152", msg: DefaultLogMessage.UnexpectedNull });
        return 0;
    }
    return a.originalIndex - b.originalIndex;
};

const clearQueueTracksOriginIndex = (playQueue: QueueTrackModel[]): QueueTrackModel[] => playQueue.map((item) => ({ ...item, originalIndex: null }));

export const unshufflePlayQueue = (playQueue: QueueTrackModel[]): QueueTrackModel[] => {
    const prioritylane = playQueue.filter((item) => item.lane === QueueLane.Priority);
    const playQueueToUnshuffle = playQueue.filter((item) => item.lane !== QueueLane.Priority);
    let newPlayQueue = playQueueToUnshuffle.sort(sortByOriginalIndex);
    const newCurrentIndex = newPlayQueue.findIndex((item) => item.lane === QueueLane.Current);

    if (newCurrentIndex === -1) {
        log.error({ code: "web-220629-1502", msg: DefaultLogMessage.UnexpectedNull });
        return playQueue;
    }
    newPlayQueue = updateQueueLanes(newPlayQueue, newCurrentIndex);
    newPlayQueue.splice(newCurrentIndex, 0, ...prioritylane);
    const withoutOriginIndex = clearQueueTracksOriginIndex(newPlayQueue);
    return withoutOriginIndex;
};
