import type { ComponentChild, ComponentChildren } from "preact";
import { Fragment, h } from "preact";
import type { MutableRef } from "preact/hooks";
import "./MyMusicPageTemplate.scss";
import { getProductPageSize } from "../productPage";
import type { UseDomainQueryResult } from "services/backend/backendService";
import { useFilter } from "services/filter";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import type { Multiselect } from "services/selection";
import type { SectionContextModel } from "models/app/resourceContextModel";
import type {
    ConnectionModel,
    MyMusicAlbumsPageModel,
    MyMusicArtistsPageModel,
    MyMusicPlaylistsPageModel,
    MyMusicTracksPageModel,
    ResourceContentType,
    ResourcePreviewModel,
    TrackPreviewModel
} from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { getMyMusicRoot } from "models/view/navigationStack";
import {
    useRefUpdate,
    useResizeObserver,
    useContextMenu,
    usePreviewContext,
    useSectionContext,
    usePageContext,
    usePageLoadState,
    useAutoPlay,
    useAppMediaSize
} from "components/shared/hooks";
import type { ConnectionFetchFn } from "components/shared/hooks/useConnectionHandler";
import { usePagination } from "components/shared/hooks/usePagination";
import { useResourcesFromUnions } from "components/shared/hooks/useResources";
import { usePageScrollRestoration } from "components/shared/hooks/useScrollRestoration";
import { Button } from "components/atoms/controls/button";
import { ButtonPlayBig } from "components/atoms/controls/button/buttons/ButtonPlayBig";
import { ButtonPlayShuffleBig } from "components/atoms/controls/button/buttons/ButtonPlayShuffleBig";
import { IconName } from "components/atoms/icon";
import { EmptyState } from "components/molecules/emptyState/EmptyState";
import { PageTopBar } from "components/molecules/pageTopBar";
import { FilterUi } from "components/organisms/filter";
import { ResourceSection } from "components/organisms/resourceSection";

export type MyMusicPageContentType = ContentType.MyMusicTracks | ContentType.MyMusicAlbums | ContentType.MyMusicArtists | ContentType.MyMusicPlaylists;
export type MyMusicPageModel = MyMusicTracksPageModel | MyMusicArtistsPageModel | MyMusicPlaylistsPageModel | MyMusicAlbumsPageModel;

interface Props {
    onFilterChanged?: (filter: string) => void;
    placeholderTitle: string;
    placeholderSubtitle: string;
    placeholderButton?: ComponentChild;
    placeholderFilter?: string;
    placeholderFilterNoResult?: string;
    placeholderFilterNoResultSubtitle?: string;
    className?: string;
    query: UseDomainQueryResult<MyMusicPageModel>;
    productType: MyMusicPageContentType;
    resources: ConnectionModel<ResourcePreviewModel | TrackPreviewModel> | null;
    resourcesFetchFn: ConnectionFetchFn<ResourcePreviewModel | TrackPreviewModel>;
    displayType: ResourceDisplayType;
    topItem?: ComponentChildren;
    topItem2?: ComponentChildren;
    children?: ComponentChildren;
    sortingMenu: h.JSX.Element;
    modifyRef?: MutableRef<((fkt: (items: (ResourcePreviewModel | TrackPreviewModel)[]) => (ResourcePreviewModel | TrackPreviewModel)[]) => void) | null>;
    multiselect?: Multiselect;
    criterion: null | string;
}

export const MyMusicPageTemplate = ({
    onFilterChanged,
    topItem,
    topItem2,
    children,
    displayType,
    modifyRef,
    placeholderButton,
    placeholderSubtitle,
    placeholderTitle,
    placeholderFilter,
    placeholderFilterNoResult,
    productType,
    query,
    resources,
    resourcesFetchFn,
    sortingMenu,
    multiselect,
    criterion,
    placeholderFilterNoResultSubtitle
}: Props) => {
    const product = query.model;
    const showTop = productType === ContentType.MyMusicTracks;

    const page = usePageContext({ type: productType, resource: product, root: getMyMusicRoot(productType), done: query.success });
    const top = useSectionContext(productType, product, page, 0, ResourceDisplayType.Top);
    const section = useSectionContext(productType, product, page, showTop ? 0 : 1, displayType);

    const pagination = usePagination({ resource: product, connection: resources, fetchFn: resourcesFetchFn });
    if (modifyRef) modifyRef.current = pagination.modify;
    const restoration = usePageScrollRestoration({ page });

    // todo: behavior that knows if the query has fully loaded (without a filter) so filtering can be done completely clientside
    const unfilteredItems = useResourcesFromUnions(pagination.connection?.items ?? null, product);
    const { filteredItems: items, setFilter, filterIsExpanded, setFilterIsExpanded } = useFilter(unfilteredItems);

    const setFilter2 = (value: string) => {
        if (onFilterChanged) {
            onFilterChanged(value);
        } else {
            setFilter(value);
        }
    };

    const buttonRef = useRefUpdate<HTMLButtonElement>();
    const sortingMenuHook = useContextMenu(sortingMenu);

    const ref = useRefUpdate<HTMLDivElement>();
    const width = useResizeObserver(ref.current)?.width ?? null;
    const appSize = useAppMediaSize();
    const size = getProductPageSize(width, appSize);

    const sort = () => {
        if (!buttonRef.current) {
            log.error({ code: "web-210519-1218", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        sortingMenuHook.open(buttonRef.current);
    };

    const { error, spinner, showContent } = usePageLoadState(page, query);
    useAutoPlay({ page, type: productType, resource: product, loading: query.loading, error: error != null });

    if (error) return error;

    const hasEmptyCollection = unfilteredItems?.length === 0;
    const hasItems = (unfilteredItems?.length ?? 0) > 0;
    const hasFilteredItems = (items?.length ?? 0) > 0;

    return (
        <div
            tabIndex={-1}
            className={`myMusicPageTemplate xhj --size-${size}`}
            ref={(r) => {
                ref.current = r;
                pagination.scrollRef.current = r;
                restoration.scrollRef.current = r;
            }}>
            <div
                className="content"
                ref={(r) => {
                    restoration.contentRef.current = r;
                }}>
                <PageTopBar
                    page={page}
                    back={true}
                    disableMobileTitle={filterIsExpanded}
                    right={
                        <Fragment>
                            <FilterUi onChange={setFilter2} onExpandedChanged={setFilterIsExpanded} placeholder={placeholderFilter} />
                            <Button icon={IconName.Sort} onClick={sort} ref={buttonRef} />
                        </Fragment>
                    }
                />
                {topItem2}
                {!criterion && hasEmptyCollection && <EmptyState title={placeholderTitle} subtitle={placeholderSubtitle} button={placeholderButton} />}
                {criterion && !hasFilteredItems && <EmptyState title={placeholderFilterNoResult ?? ""} subtitle={placeholderFilterNoResultSubtitle ?? ""} button={undefined} />}
                {!hasEmptyCollection && showTop && (
                    <div className="top">
                        <Controls product={product} productType={productType} context={top} />
                    </div>
                )}
                {spinner
                    ? spinner
                    : (hasItems || showContent) && (
                        <Fragment>
                            {topItem}
                            <ResourceSection multiselect={multiselect} resources={items} context={section} pagination={pagination} />
                            {children}
                        </Fragment>
                    )}
            </div>
        </div>
    );
};

interface ControlProps {
    context: SectionContextModel;
    product: MyMusicPageModel | null;
    productType: ResourceContentType;
}

function Controls({ product, productType, context: sectionContext }: ControlProps) {
    const preview = usePreviewContext(productType, product, sectionContext, null);

    if (product != null && product.contentType !== ContentType.MyMusicTracks) {
        log.error({ code: "web-210207-1349", msg: "wrong type" });
        return null;
    }

    return !product ? null : (
        <div className={`controls`}>
            <div className="menu">
                <ButtonPlayBig playable={product} context={preview} />
                <ButtonPlayShuffleBig playable={product} context={preview} />
            </div>
        </div>
    );
}
