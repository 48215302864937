import type { AppSessionHandler } from "../appSessionService";
import { getData, parseData, saveData } from "../helpers";
import { getAppSessionHandlerKey } from "../storageKeys";

export function getAppSessionHandler() {
    const key = getAppSessionHandlerKey();
    const data = getData(key);
    return parseData<AppSessionHandler>(data);
}

export function saveAppSessionHandler(handler: AppSessionHandler) {
    const key = getAppSessionHandlerKey();
    saveData(key, JSON.stringify(handler));
}
