import { h } from "preact";
import { Button, ButtonDesign } from "../Button";
import { dispatch } from "global";
import { AUDIO_INPUT_SET_AND_PLAY } from "global/actions";
import { useTranslations } from "global/config";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { createAndStartUserPlayTraceModel, TraceName } from "models/app/TraceModel";
import type { RadioPreviewModel } from "models/domain";
import { ShuffleState } from "models/view/ShuffleState";
import { IconName } from "components/atoms/icon";

interface Props {
    radio: RadioPreviewModel | null;
    context: PreviewContextModel;
}

export const ButtonPlayRadio = ({ radio, context }: Props) => {
    const translations = useTranslations();
    const click = async () => {
        if (!radio) {
            log.error({ code: "web-210223-1626", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }

        const trace = await createAndStartUserPlayTraceModel(TraceName.ClickToSound);
        dispatch({
            type: AUDIO_INPUT_SET_AND_PLAY,
            payload: {
                shuffle: ShuffleState.Off, // todo is this correct?
                playable: radio,
                playableContext: context,
                context: { action: AudioContextAction.UserPageSetAndPlay, trace }
            }
        });
    };

    return (
        <Button onClick={click} icon={IconName.Sound} design={ButtonDesign.PrimaryBig}>
            {translations.StartRadio}
        </Button>
    );
};
