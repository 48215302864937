import { h } from "preact";
import { Section } from "./Section";
import { TestLocator } from "global/constants";
import type { SectionContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";
import { useLinkAndPreviewContext } from "components/shared/hooks";
import { ButtonDesign, Button } from "components/atoms/controls/button";

export const Main = ({ context, minimal }: { context: SectionContextModel; minimal: boolean }) => {
    const design = minimal ? ButtonDesign.SideBarLinkBigMinimal : ButtonDesign.SideBarLinkBig;

    return (
        <Section className="main">
            <Button link={useLinkAndPreviewContext(ContentType.FrontPage, null, context, null)} useText useIcon design={design} testLocator={TestLocator.NavigationHomeButton} />
            <Button link={useLinkAndPreviewContext(ContentType.Search, null, context, null)} useText useIcon design={design} testLocator={TestLocator.NavigationSearchButton} />
            <Button link={useLinkAndPreviewContext(ContentType.Radio, null, context, null)} useText useIcon design={design} testLocator={TestLocator.NavigationRadioButton} />
        </Section>
    );
};
