import { useState, useMemo, useEffect } from "preact/hooks";
import { DelayType, Delay } from "./useDelayValue";

interface ArrayDelayProps<T> {
    delay: Delay;
    current: T[];
}

interface DelayEntry<T> {
    value: T;
    removeTime: number | null;
}

export const useArrayDelay = <T>({ delay, current }: ArrayDelayProps<T>): T[] => {
    const msec = (delay.type === DelayType.TimeOut && delay.msec) || 0;
    const [entries, setEntries] = useState<DelayEntry<T>[]>([]);
    const values = useMemo(() => entries.map((entry) => entry.value), [entries]);

    useEffect(() => {
        const run = () => {
            const now = new Date().getTime();
            let newEntries = [...entries];

            newEntries.forEach((entry) => {
                if (current.indexOf(entry.value) === -1 && entry.removeTime == null) {
                    entry.removeTime = now + msec;
                }
            });

            current.forEach((value) => {
                let entry = (newEntries.find((entry) => entry.value === value) ?? null) as DelayEntry<T> | null;
                if (entry) {
                    entry.removeTime = null;
                } else {
                    entry = { value, removeTime: null };
                    newEntries = [...newEntries, entry];
                }
            });

            newEntries = newEntries.filter((entry) => entry.removeTime == null || entry.removeTime > now);

            const next = (newEntries.filter((entry) => entry.removeTime != null).sort((a, b) => (a.removeTime as number) - (b.removeTime as number))[0]?.removeTime ?? null) as
                | number
                | null;
            if (next != null) {
                if (delay.type === DelayType.AnimationFrame) {
                    const frameId = requestAnimationFrame(run);
                    return () => cancelAnimationFrame(frameId);
                } else if (delay.type === DelayType.TimeOut) {
                    const timeoutId = setTimeout(run, next - now);
                    return () => clearTimeout(timeoutId);
                }
            }
            setEntries(newEntries);
        };
        return run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current, delay.type, msec]);
    return values;
};
