import { h } from "preact";
import type { ModalButton } from "../modal";
import { dismissModal, Modal, openModal } from "../modal";
import { dispatch, messageBus } from "global";
import { PLAYER_SET_PLAY, PLAYER_STARTED_BY_USER } from "global/actions";
import { useTranslations } from "global/config";
import { AudioContextAction } from "models/app/player/AudioContext";
import { ButtonDesign } from "components/atoms/controls/button";

export function showConcurrencyModal(deviceId: string, deviceName: string) {
    unsubscribe = messageBus.subscribeEvery(PLAYER_STARTED_BY_USER, dismiss);

    return openModal(<ConcurrencyModal deviceId={deviceId} deviceName={deviceName} />);
}
export function ConcurrencyModal({ deviceId, deviceName }: { deviceId: string; deviceName: string }) {
    const translations = useTranslations();
    const buttons: ModalButton[] = [
        {
            title: "ConcurrencyModalCancel",
            action: () => dismissModal("ConcurrencyModalCancel"),
            design: ButtonDesign.TextBrandMedium
        },
        {
            title: "ConcurrencyModalContinue",
            action: resume,
            design: ButtonDesign.PrimarySmall
        }
    ];

    const subtitle = !deviceId || deviceId === "legacy" ? translations.ConcurrencyModalSubTitleLegacy : translations.ConcurrencyModalSubTitle.replace("${DeviceName}", deviceName);

    return <Modal hasXCloseButton title={translations.ConcurrencyModalTitle} text={subtitle} buttons={buttons} />;
}

let unsubscribe: (() => void) | null = null;

function dismiss() {
    if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
    }
    dismissModal("ConcurrencyModal.dismiss");
}

function resume() {
    if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
    }
    dispatch({
        type: PLAYER_SET_PLAY,
        payload: {
            play: true,
            context: { action: AudioContextAction.ConcurrencyResume, trace: null }
        }
    });
    dismissModal("ConcurrencyModal.resume");
}
