import type { ComponentChildren, RefObject } from "preact";
import { Fragment, h } from "preact";
import { useCallback, useMemo, useRef } from "preact/hooks";
import "./ProductPageTemplate.scss";
import { PlayableActions } from "./PlayableActions";
import { TestLocator } from "global/constants";
import type { UseDomainQueryResult } from "services/backend/backendService";
import type { FavoritePageModel } from "services/favorites";
import { isFavoriteContentType } from "services/favorites";
import { isPlayable, isPlayableContentType, isRadioContentType } from "services/playable";
import type { ProductPageModel, ProductPageType } from "services/resource";
import { getCanPlay, getHasContextMenu, getProductPageTexts } from "services/resource";
import { getNextUUID } from "services/utils";
import type { SortOptionsModel, DragProps } from "models/app";
import { createDraggablePlayableModel } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import { MediaSize, useResizeObserver, usePreviewContext, usePageLoadState, useLinkContextsFromPreview } from "components/shared/hooks";
import { useAppMediaSize } from "components/shared/hooks/useAppMediaSize";
import { usePageScrollRestoration } from "components/shared/hooks/useScrollRestoration";
import { ButtonSort } from "components/atoms/controls/button/buttons";
import { ButtonFavorite } from "components/atoms/controls/button/buttons/ButtonFavorite";
import { ButtonMore } from "components/atoms/controls/button/buttons/ButtonMore";
import { CoverSize, ResourceCover } from "components/atoms/cover";
import { Line } from "components/atoms/line";
import { PageLoadSpinner } from "components/atoms/spinner";
import { ScrollingText } from "components/atoms/text";
import { MobileTopBar } from "components/molecules/pageTopBar/mobile";
import { ResourceLink } from "components/molecules/resource";
import { ResourceText } from "components/molecules/resource/ResourceText";

export enum ProductPageSize {
    Unknown = "unknown",
    Small = "small",
    Medium = "medium",
    Large = "large",
    VeryLarge = "veryLarge"
}



// the new component, to be used with the experimental product loader
interface Props2 extends Omit<Props, "error" | "spinner" | "showContent"> {
    loading?: boolean;
}
export const ProductPageTemplate2 = (props: Props2) => {
    const error = null; // todo: get errorstate from props
    const spinner = (props.loading || !props.product) ? PageLoadSpinner() : null;
    const showContent = !!props.product;
    return ProductPageTemplateBase({ ...props, error, spinner, showContent });
}


// the legacy component, to be used with the UseDomainQueryResult
interface LegacyProps extends Omit<Props, "product" | "error" | "spinner" | "showContent"> {
    query: UseDomainQueryResult<ProductPageModel>;
}
export const ProductPageTemplate = (props: LegacyProps) => {
    const page = props.section.page;
    const product = props.query.model;
    const { error, spinner, showContent } = usePageLoadState(page, props.query);
    return ProductPageTemplateBase({ ...props, product, error, spinner, showContent });
};











interface Props {
    children?: ComponentChildren;
    className?: string;
    section: SectionContextModel;
    isUserOwned: boolean;
    product: ProductPageModel | null;
    scrollRef?: RefObject<HTMLElement>;
    sortOptions?: SortOptionsModel;
    type: ProductPageType;
    filter?: h.JSX.Element;
    error: h.JSX.Element | null;
    spinner: h.JSX.Element | null;
    showContent: boolean;
}

export const ProductPageTemplateBase = ({ product, type, children, section, isUserOwned, scrollRef, sortOptions, filter, error, spinner, showContent }: Props) => {
    const page = section.page;

    const context = usePreviewContext(type, product, section, null);

    const ref = useRef<HTMLDivElement | null>(null);
    const width = useResizeObserver(ref.current)?.width ?? null;
    const appSize = useAppMediaSize();
    const size = getProductPageSize(width, appSize);
    const restoration = usePageScrollRestoration({ page });

    const dragProps: DragProps = useMemo(() => ({ dragSourceId: getNextUUID() }), []);
    const draggableItem = useCallback(() => createDraggablePlayableModel(product, dragProps.dragSourceId, context), [product, dragProps.dragSourceId, context]);

    if (error) return error;
    if (size == ProductPageSize.Unknown) return <div className={`productPageTemplate ycy`} ref={ref} />;

    const playable = isPlayableContentType(type) && product != null && isPlayable(product);
    const favorite = !isUserOwned && isFavoriteContentType(type);
    const hasMoreBtn = getHasContextMenu(type, product);
    const hasMenu = favorite || hasMoreBtn;
    const isRadio = isRadioContentType(type);
    const canPlay = getCanPlay(product);
    const sortButton = sortOptions ? <ButtonSort sortOptions={sortOptions} /> : null;

    //console.log(`ppt render${spinner ? " spinner" : ""}${showContent ? " showContent" : ""}${product ? " hasdata" : " nodata"}`);

    return (
        <div
            tabIndex={-1}
            className={`productPageTemplate ycy --size-${size} --playable-${playable} --isRadio-${isRadio}  --hasMenu2-${hasMenu}`}
            ref={(r) => {
                ref.current = r;
                restoration.scrollRef.current = r;
                if (scrollRef) scrollRef.current = r;
            }}>
            <div
                className="content"
                ref={(r) => {
                    restoration.contentRef.current = r;
                }}>
                {size === ProductPageSize.Small && <MobileTopBar back={true} right={sortButton} />}
                {(!product && spinner)
                    ? spinner
                    : (product || showContent) && (
                        <Fragment>
                            <div className="top">
                                <ResourceCover
                                    size={CoverSize.Size320}
                                    dynamicSize
                                    rounding={undefined}
                                    context={context}
                                    draggableItem={draggableItem}
                                    disabledAction={true}
                                    disableLazyLoad
                                />
                                <div className={`controls`}>
                                    <Texts preview={context} product={product} type={type} />
                                    {hasMenu && <ResourceContextActions preview={context} hasMoreBtn={hasMoreBtn} isFavorite={favorite} product={product} />}
                                </div>
                            </div>
                            <Line />
                            <PlayableActions
                                product={product}
                                previewContext={context}
                                isPlayable={playable}
                                isRadio={isRadio}
                                canPlay={canPlay}
                                sort={size !== ProductPageSize.Small ? sortButton ?? undefined : undefined}
                                filter={filter ?? <div className="spacer" />}
                            />
                            {children}
                        </Fragment>
                    )}
            </div>
        </div>
    );
};





interface ControlTextProps {
    preview: PreviewContextModel;
    product: ProductPageModel | null;
    type: ProductPageType;
}
export const Texts = ({ product, type: productType, preview }: ControlTextProps) => {
    const [h1Link, h2Link] = useLinkContextsFromPreview(preview);
    const [text1Link, text2Link] = useMemo(() => getProductPageTexts(productType, product), [product, productType]);

    const h1 = h1Link && <ResourceText links={h1Link} />;
    const h2 = h2Link && <ResourceLink links={h2Link} />;
    const text3 = text1Link && <ResourceLink links={text1Link} />;
    const text4 = text2Link && <ResourceLink links={text2Link} />;

    const hasInfos = text3 != null || text4 != null;

    return (
        <div className={`texts`}>
            <div className="titles">
                {h1 && (
                    <ScrollingText>
                        <h1>{h1}</h1>
                    </ScrollingText>
                )}
                {h2 && (
                    <ScrollingText>
                        <h2>{h2}</h2>
                    </ScrollingText>
                )}
            </div>
            {hasInfos && (
                <div className="infos">
                    {text3 != null && <p>{text3}</p>}
                    {text4 != null && <p>{text4}</p>}
                </div>
            )}
        </div>
    );
};
interface ResourceContextActionsProps {
    preview: PreviewContextModel;
    hasMoreBtn: boolean;
    isFavorite: boolean;
    product: ProductPageModel | null;
}
const ResourceContextActions = ({ product, preview, isFavorite, hasMoreBtn }: ResourceContextActionsProps) => {
    return (
        <div className={`menu`}>
            {isFavorite && <ButtonFavorite favorite={product as FavoritePageModel} context={preview} testLocator={TestLocator.ProductPageFavoriteButton} />}
            {hasMoreBtn && <ButtonMore resource={product} context={preview} testLocator={TestLocator.ProductPageMoreButton} />}
        </div>
    );
};

export function getProductPageSize(width: number | null, appSize: MediaSize): ProductPageSize {
    if (appSize === MediaSize.Mobile) return ProductPageSize.Small;
    if (appSize === MediaSize.Tablet) return ProductPageSize.Medium;

    if (width == null) return ProductPageSize.Unknown;
    if (width < 360) return ProductPageSize.Small;
    if (width < 2 * 320 + 24) return ProductPageSize.Medium;
    if (width < 320 + 64 + 415 + 32) return ProductPageSize.Large;
    return ProductPageSize.VeryLarge;
}
