import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import type { RoutableProps, RouteProps } from "preact-router";
import { route } from "preact-router";
import { useSelector } from "react-redux";
import type { RootModel } from "models/app";
import { LoginState } from "models/app/LoginState";
import { showLoginUi } from "components/organisms/login";

interface Props extends RouteProps<RoutableProps> {
    previous: string;
}
export const PrivateRoute = (props: Props) => {
    const { component: Component, path, previous } = props;
    const [wasLoggedIn, setWasLoggedIn] = useState(false);
    const loginState = useSelector((root: RootModel) => root.user.state);
    const showContent = loginState === LoginState.LoggedIn;

    useEffect(() => {
        if (loginState === LoginState.LoggedIn) {
            setWasLoggedIn(true);
        } else if (loginState === LoginState.LoggedOut) {
            if (wasLoggedIn) {
                route("/", true);
            } else {
                showLoginUi()
                    .then(() => setWasLoggedIn(true))
                    .catch(() => route(previous, true));
            }
        }
        return;
    }, [wasLoggedIn, loginState, previous]);

    return showContent ? <Component {...props} /> : null;
};
