import { h } from "preact";
import { useCallback } from "preact/hooks";
import { LinkWithSubmenu } from "../Link";
import { AddToPlaylistContextMenu } from "../menus";
import { useTranslations } from "global/config";
import { addTracksToPlaylist } from "services/playlist";
import type { Multiselect } from "services/selection";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { TrackPreviewModel } from "models/domain";
import { IconName } from "components/atoms/icon";

interface Props {
    multiselect: Multiselect;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const AddMultipleToPlaylistLink = ({ multiselect, context, showIcon }: Props) => {
    const translations = useTranslations();

    const onAdd = useCallback(async (playlistId: string) => {
        if (multiselect.state.selectedItems.length > 0) {
            addTracksToPlaylist(playlistId, multiselect.state.selectedItems as TrackPreviewModel[]);
        }
    }, [multiselect])

    return (
        <LinkWithSubmenu
            disabled={multiselect.state.selectedItems.length == 0}
            icon={showIcon ? IconName.AddToPlaylist : undefined}
            submenu={<AddToPlaylistContextMenu onAdd={onAdd} context={context} />}>
            {translations.AddToPlaylist}
        </LinkWithSubmenu>
    );
};
