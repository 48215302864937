import { parseData, getData, saveData } from "../helpers";
import { getSharedFinishedPlaybackReportsStorageKey } from "../storageKeys";
import type { FinishedPlaybackReport } from "models/app/playbackReport";

export function getSharedFinishedPlaybackReports() {
    const key = getSharedFinishedPlaybackReportsStorageKey();
    const reports = parseData<FinishedPlaybackReport[]>(getData(key));
    return reports;
}

export function saveSharedFinishedPlaybackReports(reports: FinishedPlaybackReport[]) {
    const key = getSharedFinishedPlaybackReportsStorageKey();
    saveData(key, JSON.stringify(reports));
}
