import { dispatch, store } from "global";
import { AUDIO_INPUT_AUDIOS_CHANGE } from "global/actions";
import { getAnalyticsPlayableLoadProperties } from "services/logger/analytics/properties/event";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import type { NormalizedData } from "services/normalizeData";
import { isAudioLoaded } from "services/player/helpers";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";

export async function updateAudios(audios: AudioInputItemModel[], queueAddData: NormalizedData<AnalyticsQueueAddedPropertiesGroup> | null, context: AudioContextModel) {
    audios = tryAddLoadDataToAudios(audios);
    const old = store.getState().audioInput.audios;

    dispatch({ type: AUDIO_INPUT_AUDIOS_CHANGE, payload: { audios, old, queueAddData, context } });
}

function tryAddLoadDataToAudios(audios: AudioInputItemModel[]): AudioInputItemModel[] {
    return audios.map((audio) => tryAddLoadDataToAudio(audio));
}

export function tryAddLoadDataToAudio(audio: AudioInputItemModel): AudioInputItemModel {
    const loaded = isAudioLoaded(audio.playState);
    const hasLoadData = !!audio.analytics2?.loadData;
    if (hasLoadData === loaded) return audio;

    const analytics = audio.analytics2 ?? { playableData: null, addedDataId: null, loadData: null };
    if (loaded) analytics.loadData = getAnalyticsPlayableLoadProperties(audio);
    else analytics.loadData = null;

    return {
        ...audio,
        analytics2: analytics
    };
}
