import { updateQueueLanes } from "../helpers";
import { updatePlayQueue } from "./updatePlayQueue";
import { store } from "global";
import { log } from "services/logger";
import { AudioInputType } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { QueueLane } from "models/app/player/input";
import type { AudioInputItemModel, AudioInputItemPlayQueueModel } from "models/app/player/input";

export const playFromAudioChange = (audio: AudioInputItemModel, context: AudioContextModel): void => {
    if (audio.input !== AudioInputType.PlayQueue) {
        log.error({ code: "web-210223-1428", msg: "audio change is only supported for play queue audios" });
        return;
    }

    playFromPlayQueueAudioChange(audio, context);
};

function playFromPlayQueueAudioChange(audio: AudioInputItemPlayQueueModel, context: AudioContextModel) {
    const queue = store.getState().queue;
    let tracks = queue.playQueue;

    if (tracks.length === 0) {
        log.error({ code: "web-210620-1657", msg: "no tracks in queue" });
        return;
    }

    const queueId = audio.queueId;
    const queueTrackIndex = tracks.findIndex((item) => item.queueId === queueId);
    const queueTrack = tracks[queueTrackIndex];

    if (queueTrackIndex === -1 || !queueTrack) {
        log.error({ code: "web-210620-1658", msg: "cant find queue track to play", data: { audioTitle: audio.logTitle, queueTracks: tracks, audio } });
        return;
    }

    if (queueTrack.lane === QueueLane.Current) {
        log.error({ code: "web-210620-1659", msg: "queue track already in current lane" });
    }

    tracks = updateQueueLanes(tracks, queueTrackIndex);
    const newQueue = { ...queue, playQueue: tracks };

    updatePlayQueue(newQueue, context, false, null);
}
