import { h } from "preact";
import "./ProfilesPage.scss";
import { useLinkAndPreviewContext, usePageContext, useSectionContext } from "components/shared/hooks";
import { PageTopBar } from "components/molecules/pageTopBar";
import { ContentType } from "models/ModelType";
import { IconName } from "components/atoms/icon";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { isFeatureEnabled, Feature, useTranslations } from "global/config";
import { RootModel, UserProfileModel } from "models/app";
import { useSelector } from "react-redux";
import { hasPermissionFromToken, useDisplayLoggedIn } from "services/user";
import { navigation } from "services/navigation";
import { route } from "preact-router";
import { setProfileEntrypoint } from "./profilePageEntry";

export const ProfilesPage = () => {
    const page = usePageContext({ type: ContentType.ProfilesPage, resource: null, root: null, done: true });

    const translations = useTranslations();

    const section = useSectionContext(ContentType.ProfilesPage, null, null, null, null);
    const addProfileLink = useLinkAndPreviewContext(ContentType.ProfilesPage, null, section, null);
    addProfileLink.url = navigation.templates.addProfilesPage(); // todo

    const loggedIn = useDisplayLoggedIn();
    const profiles = useSelector((root: RootModel) => root.user.profiles);
    const profilesLimit = useSelector((root: RootModel) => root.user.profilesNumberLimit) ?? 0;
    const showProfiles = loggedIn && profiles.length > 0 && hasPermissionFromToken("prof") && isFeatureEnabled(Feature.Profiles);
    if (!showProfiles) {
        route("/");
    }

    const onEditProfile = (model: UserProfileModel) => {
        route(navigation.templates.profilesPage(model.id));
    };

    const onAddProfile = () => {
        setProfileEntrypoint("Administer");
    };

    return (
        <div className="profilesPage cl9">
            <PageTopBar page={page} back={true} />
            <div className="scroll">
                <section className={`section profiles`}>
                    {
                        profiles.map(profile => (
                            <div style={{ "--colorHighlight": profile.color }}>
                                <Button icon={IconName.EditProfile40} design={ButtonDesign.ProfileGiant} onClick={() => onEditProfile(profile)}></Button>
                                {profile.title}
                            </div>
                        ))
                    }
                    {
                        profiles.length < profilesLimit &&
                        <div>
                            <Button icon={IconName.Plus40} design={ButtonDesign.ProfileGiant} link={addProfileLink} onClick={onAddProfile}></Button>
                            {translations.AddProfile}
                        </div>
                    }
                </section>
            </div>
        </div>
    );
};
