import type { ConnectionModel } from "./ConnectionModel";
import { createTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { ResourceModelLinkInterface } from "./ResourceModel";
import type { TrackPreviewModel } from "./TrackModel";
import { pageContent } from "global/constants/pageContent";
import { getDurationFromTracks } from "services/playable";
import type { ArtistRadioLinkFragment, ArtistRadioPageFragment, ArtistRadioPlayFragment, ArtistRadioPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type ArtistRadioLinkData = ArtistRadioLinkFragment;
type ArtistRadioSectionData = ArtistRadioPreviewFragment;
type ArtistRadioPlayData = ArtistRadioPlayFragment;
type ArtistRadioPageData = ArtistRadioPageFragment;

export type ArtistRadioModel = ArtistRadioLinkModel;
export type ArtistRadioLinkModel = ({ type: DomainModelType.Link } & ArtistRadioLink) | ArtistRadioPreviewModel;
export type ArtistRadioPreviewModel = ({ type: DomainModelType.Preview } & ArtistRadioPreview) | ArtistRadioPlayModel;
export type ArtistRadioPlayModel = ({ type: DomainModelType.Play } & ArtistRadioPlay) | ArtistRadioPageModel;
export type ArtistRadioPageModel = { type: DomainModelType.Page } & ArtistRadioPage;

interface ArtistRadioLink extends ResourceModelLinkInterface {
    readonly contentType: ContentType.ArtistRadio;
    readonly title: string;
}

type ArtistRadioPreview = ArtistRadioLink & PlayQueuePlayableModelInterface;

interface ArtistRadioPlay extends ArtistRadioPreview {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}
interface ArtistRadioPage extends ArtistRadioPlay {
    readonly duration: number;
}

export function createArtistRadioLinkModel(data: ArtistRadioLinkData): ArtistRadioLinkModel {
    return {
        id: data.id,
        title: data.title,
        type: DomainModelType.Link,
        contentType: ContentType.ArtistRadio
    };
}

export function createArtistRadioPreviewModel(data: ArtistRadioSectionData): ArtistRadioPreviewModel {
    return {
        ...createArtistRadioLinkModel(data),
        graphType: data.__typename,
        cover: data.cover ?? null,
        trackCount: pageContent.artistRadioPageItemsLimit,
        type: DomainModelType.Preview
    };
}

export function createArtistRadioPlayModel(data: ArtistRadioPlayData): ArtistRadioPlayModel {
    return {
        ...createArtistRadioPreviewModel(data),
        type: DomainModelType.Play,
        tracks: data.artistRadio ? createTrackPreviewConnectionModel(data.artistRadio, 0) : null
    };
}

export function createArtistRadioPageModel(data: ArtistRadioPageData): ArtistRadioPageModel {
    const radioTracks = data.artistRadio ? createTrackPreviewConnectionModel(data.artistRadio, 0) : null;
    return {
        ...createArtistRadioPreviewModel(data),
        type: DomainModelType.Page,
        tracks: radioTracks,
        duration: radioTracks ? getDurationFromTracks(radioTracks.items) : 0
    };
}
