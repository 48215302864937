import { h } from "preact";
import { ItemGroup } from "./ItemGroup";
import { Badge } from "components/atoms/badge/Badge";
import { Icon, IconName } from "components/atoms/icon";

interface Props {
    icon: IconName;
    title: string;
    subtitle?: string;
}

export const IconGroup = ({ icon, title, subtitle }: Props) => {
    return (
        <ItemGroup title={title} subtitle={subtitle}>
            <Badge>
                <Icon icon={icon} />
            </Badge>
        </ItemGroup>
    );
};
