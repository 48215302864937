import { h } from "preact";
import { dispatch } from "global";
import { AUDIO_OUTPUT_AUDIO_ENDED, PLAYQUEUE_CLEAR_PRIORITY_LANE, PLAYQUEUE_KEEP_PRIORITY_LANE } from "global/actions";
import { messageBus, useTranslations } from "global/config";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import { ButtonDesign } from "components/atoms/controls/button";
import type { ModalButton } from "components/organisms/modal";
import { Modal, dismissModal, openModal } from "components/organisms/modal";

export function showPriorityQueueActionModal(context: AudioContextModel, playQueue: QueueTrackModel[]) {
    return openModal(<PriorityQueueActionModal context={context} playQueue={playQueue} />);
}

export function PriorityQueueActionModal({ context, playQueue }: { context: AudioContextModel; playQueue: QueueTrackModel[] }) {
    const priorityQueue = playQueue.filter((item) => item.lane === QueueLane.Priority);
    const translations = useTranslations();
    const buttons: ModalButton[] = [
        {
            title: "PriorityQueueWarningDeleteOption",
            action: () => priorityQueueActionClear(context),
            design: ButtonDesign.TextBrandMedium
        },
        {
            title: "PriorityQueueWarningKeepOption",
            action: () => priorityQueueActionKeep(context),
            design: ButtonDesign.PrimarySmall
        }
    ];

    const firstTrack = priorityQueue[0] ?? null;

    messageBus.subscribeOne(AUDIO_OUTPUT_AUDIO_ENDED, () => {
        priorityQueueActionKeepNoActionTaken(context);
    });

    const NextTrackTitle = firstTrack ? firstTrack.track.title : "";
    const NextTrackArtist = firstTrack ? firstTrack.track.artist.name : "";
    const ExtraCounts = `${priorityQueue.length - 1}`;

    const singletrack = translations.PriorityQueueWarningSubTitleSingleTrack;
    const multipetrack = translations.PriorityQueueWarningSubTitleMultiple;
    const translation = priorityQueue.length > 1 ? multipetrack : singletrack;

    const text = translation
        .replaceAll(/\\n/g, "\n")
        .replace("${NextTrackTitle}", NextTrackTitle)
        .replace("${NextTrackArtist}", NextTrackArtist)
        .replace("${ExtraCounts}", ExtraCounts);

    return <Modal title={translations.PriorityQueueWarningTitle} text={text} buttons={buttons} />;
}

function priorityQueueActionKeep(context: AudioContextModel) {
    dispatch({ type: PLAYQUEUE_KEEP_PRIORITY_LANE, payload: { context, noActionTaken: false } });
    dismissModal("PriorityQueueWarningKeepOption");
}
function priorityQueueActionKeepNoActionTaken(context: AudioContextModel) {
    dispatch({ type: PLAYQUEUE_KEEP_PRIORITY_LANE, payload: { context, noActionTaken: true } });
    dismissModal("PriorityQueueWarningKeepOption");
}

function priorityQueueActionClear(context: AudioContextModel) {
    dispatch({ type: PLAYQUEUE_CLEAR_PRIORITY_LANE, payload: { context } });
    dismissModal("PriorityQueueWarningDeleteOption");
}
