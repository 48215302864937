export function createdIntervalCallbackGroup() {
    const callbacks = new Map<() => void, IntervalCallback>();
    let started = false;

    const begin = () => {
        started = true;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [...callbacks].forEach(([_, callback]) => callback.begin());
    };

    const pause = () => {
        started = false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [...callbacks].forEach(([_, callback]) => callback.pause());
    };

    const resume = () => {
        started = true;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [...callbacks].forEach(([_, callback]) => callback.resume());
    };

    const removeAll = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [...callbacks].forEach(([_, callback]) => callback.pause());
        callbacks.clear();
    };

    const add = (fn: () => void, begin = false) => {
        const timedCallback = createdIntervalCallback(fn);
        callbacks.set(fn, timedCallback);

        if (begin && started) timedCallback.begin();

        return timedCallback;
    };

    return {
        begin,
        pause,
        resume,
        add,
        removeAll
    };
}

interface IntervalCallback {
    begin: () => void;
    pause: () => void;
    resume: () => void;
    updateInterval: (intervalMs: number | null) => void;
}

function createdIntervalCallback(fn: () => void): IntervalCallback {
    let timeoutId: number | undefined;
    let lastRun = 0;
    let interval: number | null = null;
    let started = false;

    const execute = () => {
        clearTimeout();

        if (!started) return;

        lastRun = new Date().getTime();
        fn();

        if (interval != null) {
            clearTimeout();
            timeoutId = window.setTimeout(() => execute(), interval);
        }
    };

    const schedule = () => {
        clearTimeout();

        if (!started) return;
        if (interval == null) return;

        const next = interval - (new Date().getTime() - lastRun);

        if (next < 0) execute();
        else timeoutId = window.setTimeout(() => execute(), next);
    };

    const begin = () => {
        started = true;
        execute();
    };

    const resume = () => {
        started = true;
        schedule();
    };

    const pause = () => {
        started = false;
        clearTimeout();
    };

    const updateInterval = (intervalMs: number | null) => {
        interval = intervalMs;
        schedule();
    };

    const clearTimeout = () => {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
            timeoutId = undefined;
        }
    };

    return { begin, resume, pause, updateInterval };
}
