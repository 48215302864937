export function getNextUUID(): string {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toFixed(); // todo
}

export function getRandomUUID(): string {
    return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toFixed();
}
export function lerp(from: number, to: number, fraction: number) {
    return fraction * (to - from) + from;
}

export function getUrlParameter(name: string, source: string = location.search) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    const results = regex.exec(source);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cloneWithDefaultValues(currentValues: any, defaultValues: any) {
    if (!currentValues) return defaultValues;
    const result = { ...currentValues };
    for (const key in defaultValues) {
        const isCurrentObject = typeof result[key] === "object" && !!result[key] && !Array.isArray(result[key]);
        const isDefaultObject = typeof defaultValues[key] === "object" && !!defaultValues[key] && !Array.isArray(defaultValues[key]);
        if ((isCurrentObject !== isDefaultObject && !!defaultValues[key]) || result[key] === undefined) {
            result[key] = defaultValues[key];
        } else if (isDefaultObject) {
            result[key] = cloneWithDefaultValues(result[key], defaultValues[key]);
        }
    }
    return result;
}

export class ConcurrentEntryGuard<T> {
    promise: Promise<T> | null = null;
    call = async (callback: () => Promise<T>) => {
        if (this.promise) return await this.promise;
        this.promise = callback();
        try {
            return await this.promise;
        } finally {
            this.promise = null;
        }
    };
}

export function getUnique<T>(items: T[], getKey: (item: T) => string): T[] {
    return items.reduce(
        (state, item) => {
            const key = getKey(item);
            if (!state.lookup[key]) {
                state.lookup[key] = true;
                state.items.push(item);
            }
            return state;
        },
        {
            lookup: {} as { [index: string]: boolean },
            items: [] as T[]
        }
    ).items;
}

export async function flattenArrayOfPromisesOrData<T>(arr: (T | Promise<T>)[]): Promise<T[]> {
    const result: T[] = [];
    for (let i = 0; i < arr.length; i++) {
        const elem = arr[i];
        const resolved = elem ? (await Promise.resolve(elem)) : (elem as T);
        result.push(resolved);
    }
    return result;
}