import type { AnalyticsPlayablePlayEndData } from "./playableStopProperties";
import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { log } from "services/logger/initLoggerService";
import { AudioContextAction } from "models/app/player/AudioContext";

export type AnalyticsPlayableErrorProperties = {
    playDuration: number;
    playedMoreThan30sec: boolean;
    endReason: AudioContextAction;
};

export function getAnalyticsPlayableErrorProperties({ playDuration, endReason }: AnalyticsPlayablePlayEndData): AnalyticsPlayableErrorProperties {
    return {
        playDuration,
        playedMoreThan30sec: playDuration >= 30,
        endReason
    };
}

export function convertPlayableErrorProperties(properties: AnalyticsPlayableErrorProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "PlayDuration", properties.playDuration);
    SetAnalyticsProp(data, "event", "PlayedMoreThan30sec", properties.playDuration >= 30);
    SetAnalyticsProp(data, "event", "ErrorType", convertAnalyticsErrorTypeFromAction(properties.endReason));

    return data;
}

function convertAnalyticsErrorTypeFromAction(action: AudioContextAction): AnalyticsKey | null {
    switch (action) {
        case AudioContextAction.AudioTrackStreamError:
            return "FetchTrackStreamError";
        case AudioContextAction.AudioHtmlElementError:
        case AudioContextAction.AudioHlsError:
            return "MediaError";
            action;
    }

    log.error({ code: "web-220712-1519", msg: "action is not a valid error type", data: { action } });
    return null;
}
