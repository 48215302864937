import { store } from "global";
import { AudioInputType } from "models/app/player";
import { QueueMode } from "models/app/player/input";
import { RepeatStatus } from "models/view";

export const isRepeatDisabled = () => {
    const { queue, player } = store.getState();
    if (queue.mode === QueueMode.MixRadio) return true;
    if (player.input === AudioInputType.LiveRadio) return true;
    return false;
};

export const getNextRepeatStatus = (repeat: RepeatStatus): RepeatStatus => {
    switch (repeat) {
        case RepeatStatus.None:
            return RepeatStatus.All;
        case RepeatStatus.All:
            return RepeatStatus.One;
        case RepeatStatus.One:
            return RepeatStatus.None;
    }
};
