import { h } from "preact";
import { Modal, modalButtonSubmitContinueAnyways, openModal } from "..";
import { useTranslations } from "global/config";
import { Button, ButtonDesign } from "components/atoms/controls/button";

export async function showBrowserCompatibilityModal() {
    return openModal(<BrowserCompatibilityModal />);
}

export function BrowserCompatibilityModal() {
    const submit = async () => {
        return true;
    };
    const translations = useTranslations();

    return (
        <Modal buttons={[modalButtonSubmitContinueAnyways(submit)]} onRequestClose={submit} title={translations.MessagesBrowserCompatibilityModalTitleWeb}>
            <div className="text">
                <p>{translations.MessagesBrowserCompatibilityModalText1Web}</p>
                <p>{translations.MessagesBrowserCompatibilityModalText2Web}</p>
                <p>
                    <Button design={ButtonDesign.TextBrandInline} externalUrl={translations.SettingsHelpUrl}>
                        {translations.MessagesBrowserCompatibilityModalHelpButtonTextWeb}
                    </Button>
                </p>
            </div>
        </Modal>
    );
}

export async function showBrowserTooOldModal() {
    return openModal(<BrowserTooOldModal />);
}

export function BrowserTooOldModal() {
    const submit = async () => {
        return true;
    };
    const translations = useTranslations();
    return (
        <Modal buttons={[modalButtonSubmitContinueAnyways(submit)]} onRequestClose={submit} title={translations.MessagesBrowserTooOldModalTitleWeb}>
            <div className="text">
                <p>{translations.MessagesBrowserTooOldModalText1Web}</p>
                <p>{translations.MessagesBrowserTooOldModalText2Web}</p>
                <p>
                    <Button design={ButtonDesign.TextBrandInline} externalUrl={translations.SettingsHelpUrl}>
                        {translations.MessagesBrowserTooOldModalHelpButtonTextWeb}
                    </Button>
                </p>
            </div>
        </Modal>
    );
}
