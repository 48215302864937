import { h } from "preact";
import { useState } from "preact/hooks";
import "./Select.scss";
import { TextInputState } from "../textInput";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { Icon, IconName } from "components/atoms/icon";

export interface SelectOption<T> {
    value: T;
    text: string;
    id: string;
    seleced?: boolean;
}

interface Props<T> {
    className?: string;
    disabled: boolean;
    onChange: (value: T) => void;
    onFocus: () => void;
    options: SelectOption<T>[];
    state?: TextInputState;
}

export const Select = <T,>({ className, options, state = TextInputState.Default, disabled, onChange, onFocus }: Props<T>) => {
    const input = (value: string) => {
        const selected = options.find((option) => option.id === value);
        if (!selected) {
            log.error({ code: "web-210729-1343", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        onChange(selected.value);
    };

    const [hasFocus, setHasFocus] = useState(false);

    const onFocusFn = () => {
        setHasFocus(true);
        onFocus();
    };

    const onBlurFn = () => {
        setHasFocus(false);
    };

    return (
        <div className={`select ${className ?? ""} --state-${state} --focus-${hasFocus} --disabled-${disabled}`}>
            <select
                disabled={disabled}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onInput={(ev) => input((ev as any).target.value)}
                onFocus={onFocusFn}
                onBlur={onBlurFn}>
                {options.map((option) => (
                    <option key={option.id} selected={option.seleced} value={option.id}>
                        {option.text}
                    </option>
                ))}
            </select>
            <Icon icon={IconName.ArrowDown} />
        </div>
    );
};
