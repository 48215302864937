import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useTranslations } from "global/config";
import { LogoutTrigger } from "services/logger/analytics/properties/event";
import { logout } from "services/user";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";

export const SignOutSettings = () => {
    const translations = useTranslations();
    const logoutFn = useCallback(() => logout(LogoutTrigger.Manual), []);
    return (
        <div className="settingContainer signout">
            <Button icon={IconName.SignOut} design={ButtonDesign.SecondaryBig} onClick={logoutFn}>
                {translations.LogOut}
            </Button>
        </div>
    );
};
