import { getAppSession } from "./appSessionService";
import { getAppSessionHandler, saveAppSessionHandler } from "./operations/appSessionHandler";
import { getAccessTokenStorageKey, getFinishedPlaybackReportsStorageKey, getLoadedPlaybackReportsStorageKey, getRefreshTokenStorageKey } from "./storageKeys";
import { deleteSessionDbState } from "global/config";

export function initDebug() {
    const sessionDebug = { simulateRestoreSession: () => simulateRestoreSessionFn() };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).sessionDebug = sessionDebug;
}

function simulateRestoreSessionFn() {
    const session = getAppSession();

    const key1 = getAccessTokenStorageKey(session.storageId);
    const key2 = getRefreshTokenStorageKey(session.storageId);
    const key3 = getLoadedPlaybackReportsStorageKey(session.storageId);
    const key4 = getFinishedPlaybackReportsStorageKey(session.storageId);

    localStorage.removeItem(key1);
    localStorage.removeItem(key2);
    localStorage.removeItem(key3);
    localStorage.removeItem(key4);

    const handler = getAppSessionHandler();
    if (!handler) {
        console.error({ code: "web-220927-1042", msg: "no handler" });
        return;
    }

    handler.sessions = handler.sessions.filter((n) => n.id !== session.id);
    saveAppSessionHandler(handler);

    deleteSessionDbState();
}
