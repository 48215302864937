import type { AnalyticsKey } from "global/config";

let entrypoint: Extract<AnalyticsKey, "Settings"> | Extract<AnalyticsKey, "Administer"> | undefined = undefined;

export function setProfileEntrypoint(name: typeof entrypoint): void {
    entrypoint = name;
}

export function getAndClearProfileEntrypoint(): typeof entrypoint {
    const tmp = entrypoint;
    entrypoint = undefined;
    return tmp;
}
