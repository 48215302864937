import { useLayoutEffect } from "preact/hooks";

interface Props {
    callback: (value: IntersectionObserverEntry) => void;
    scroll: HTMLElement | null;
    target: HTMLElement | null;
    margin: number;
}

export function useIntersectionObserver({ scroll, target, callback, margin }: Props) {
    useLayoutEffect(() => {
        if (!scroll) return;
        if (!target) return;

        const options: IntersectionObserverInit = {
            root: scroll,
            rootMargin: `${margin}%`,
            threshold: 0
        };
        const callbackFn: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                callback(entry);
            });
        };
        const observer = new IntersectionObserver(callbackFn, options);
        observer.observe(target);

        return () => observer.disconnect();
    }, [scroll, target, callback, margin]);
}
