import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { isDraggableCurrentLane } from "./isDraggableCurrentLane";
import type { LaneProps } from "./LaneProps";
import { dispatch } from "global";
import { PLAYQUEUE_ENDLESSPLAY_TOGGLE } from "global/actions";
import { useTranslations } from "global/config";
import { EndlessPlaySwitchTestLocator } from "global/constants";
import type { DraggablePlayableModel } from "models/app";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import { useIsEndlessPlayOnFromState } from "components/shared/hooks";
import { Switch } from "components/atoms/controls/switch";
import { DropZone } from "components/molecules/dropZone";
import { QueueTrackRow } from "components/molecules/preview/rows";
import { QueueListView } from "components/organisms/recyclerView";

interface Props extends Omit<LaneProps, "dragSourceId"> {
    expandDropZone: boolean;
}
export const PreviewLane = ({ dragProps, sectionContext, onDrop, expandDropZone, items, scrollOnTrackClickFn }: Props) => {
    const isEndlessPlayOn = useIsEndlessPlayOnFromState();
    const handleToggleEndlessPlay = useCallback(() => dispatch({ type: PLAYQUEUE_ENDLESSPLAY_TOGGLE, payload: { on: !isEndlessPlayOn } }), [isEndlessPlayOn]);
    const testLocator = useMemo(() => EndlessPlaySwitchTestLocator(isEndlessPlayOn), [isEndlessPlayOn]);
    const translations = useTranslations();
    const allowDrop = useCallback((item: DraggablePlayableModel) => !isDraggableCurrentLane(item), []);

    const mapper = useCallback(
        (track: QueueTrackModel) => (
            <QueueTrackRow
                scrollOnTrackClickFn={scrollOnTrackClickFn}
                dragProps={dragProps}
                dropProps={null}
                key={track.queueId}
                queueTrack={track}
                sectionContext={sectionContext}
                disabled={!isEndlessPlayOn}
            />
        ),
        [dragProps, isEndlessPlayOn, scrollOnTrackClickFn, sectionContext]
    );
    return (
        <section className={"previewLane"}>
            <div className="top">
                <DropZone allowDrop={allowDrop} customClass={`--size-${expandDropZone ? "big" : "small"}`} onDropFn={(item) => onDrop(item, QueueLane.Flow, true)} />
                <h1 className="headline">{translations.SettingsEndlessPlayTitle}</h1>
                <Switch checked={isEndlessPlayOn} onClick={handleToggleEndlessPlay} testLocator={testLocator} />
            </div>
            <QueueListView resources={items} mapper={mapper} />
        </section>
    );
};
