import { h } from "preact";
import { Sheet } from "..";
import { FavoritesSortingLink } from "../links";
import { SortingMenuTop } from "./tops";
import { useTranslations } from "global/config";
import { FavoriteAlbumsSorting } from "models/domain";

export const MyMusicAlbumsSortingContextMenu = () => {
    const translations = useTranslations();

    return (
        <Sheet top={<SortingMenuTop />}>
            <FavoritesSortingLink title={translations.SortByLastAdded} type="albumsSorting" sorting={FavoriteAlbumsSorting.LatestAdded} />
            <FavoritesSortingLink title={translations.SortByNameTitle} type="albumsSorting" sorting={FavoriteAlbumsSorting.Alphabetically} />
            <FavoritesSortingLink title={translations.SortByArtist} type="albumsSorting" sorting={FavoriteAlbumsSorting.ArtistName} />
            <FavoritesSortingLink title={translations.SortByReleaseYear} type="albumsSorting" sorting={FavoriteAlbumsSorting.ReleaseDate} />
        </Sheet>
    );
};
