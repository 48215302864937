import { castSetVolume } from "../castRequests/playerController";
import { store } from "global";
import { throttle2 } from "services/cancellation/throttle";

const throtle = throttle2();

export async function playerSetVolume() {
    await throtle(50);
    const volume = store.getState().player.volume;

    castSetVolume(volume);
}
