import type { MaintenanceData } from "services/remoteConfig";

export enum BannerWarning {
    NoConnection = "NoConnection",
    MaintenanceBanner = "MaintenanceBanner"
}

export interface NotificationsModel {
    banners: BannerWarning[];
    welcomeMessageShown: boolean;
    pwaInstallMessageWaiting: boolean;
    maintenanceBannerData: MaintenanceData | null;
}
