import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getArtistSimilarArtistsPagination, useArtistSimilarArtistsPage } from "services/backend/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    artistId: string;
}

export const ArtistSimilarArtistsShowAllPage = ({ artistId }: Props) => {
    const query = useArtistSimilarArtistsPage({ id: artistId, first: getPaginationInit() });
    const resource = query.model;

    const fetchFn = useCallback((first: number, after: string) => getArtistSimilarArtistsPagination({ first, after, id: artistId }), [artistId]);

    return <ShowAllPageTemplate query={query} type={ContentType.ArtistSimilarArtists} items={resource?.artists ?? null} fetchFn={fetchFn} display={ResourceDisplayType.Grid} />;
};
