import { getCurrentLaneFromState } from "./currentLane";
import { store } from "global";
import { getComparableId } from "services/resource";
import type { TrackParentModel } from "models/domain";

export const getTrackParent = (parentId: string, trackParents: TrackParentModel[]): TrackParentModel | null => {
    return trackParents.find((parent) => getComparableId(parent) === parentId) ?? null;
};

export const getCurrentLaneTrackParentFromState = (): TrackParentModel | null => {
    const parentId = getCurrentLaneFromState()?.parentId;
    if (parentId == null) return null;

    const trackParents = store.getState().queue.trackParents;
    return getTrackParent(parentId, trackParents);
};

export const getQueueLaneTrackParentFromState = (parentId: string | null): TrackParentModel | null => {
    if (parentId == null) return null;

    const trackParents = store.getState().queue.trackParents;
    return getTrackParent(parentId, trackParents);
};
