import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { Accordion } from "./Accordion";
import { Setting } from "./Setting";
import { dispatch } from "global";
import { SET_THEME_SETTING } from "global/actions";
import { useTranslations } from "global/config";
import type { RootModel } from "models/app";
import { ThemeSetting } from "models/view";
import {} from "models/view/Localization";
import { RadioButton } from "components/atoms/controls/radioButton";
import { IconName } from "components/atoms/icon";

export const ThemeSettings = () => {
    const translations = useTranslations();

    const buttonDarkTheme = <ThemeButton setting={ThemeSetting.Dark} />;
    const buttonLightTheme = <ThemeButton setting={ThemeSetting.Light} />;
    const buttonBrowserTheme = <ThemeButton setting={ThemeSetting.Auto} />;

    return (
        <Accordion icon={IconName.Appearance} title={translations.SettingsAppearanceTitle}>
            <Setting title={translations.SettingsAppearanceLight} button={buttonLightTheme} />
            <Setting title={translations.SettingsAppearanceDark} button={buttonDarkTheme} />
            <Setting title={translations.SettingsAppearanceAutomaticTitle} subtitle={translations.SettingsAppearanceAutomaticSubTitle} button={buttonBrowserTheme} />
        </Accordion>
    );
};

const ThemeButton = ({ setting }: { setting: ThemeSetting }) => {
    const theme = useSelector((root: RootModel) => root.ui.theme);
    const change = useCallback(() => dispatch({ type: SET_THEME_SETTING, payload: { theme: setting, shortcut: true } }), [setting]);

    return <RadioButton checked={theme === setting} onChange={change} name={setting} />;
};
