import { h } from "preact";
import { useCallback } from "preact/hooks";
import "./SearchPage.scss";
import { MobileSearchPageView } from "./MobileSearchPageView";
import { getPaginationInit } from "global/constants/pagination";
import { getPlaylistCategoriesPagination, usePlaylistCategoriesPage } from "services/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";
import { useAppMediaSize, MediaSize, usePageContext, useSectionContext, useResourcesFromUnions } from "components/shared/hooks";
import { usePagination } from "components/shared/hooks/usePagination";
import { ResourceSection } from "components/organisms/resourceSection";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

export const SearchPage = () => {
    const size = useAppMediaSize();
    if (size === MediaSize.Mobile) return <MobileSearchPage />;
    else return <DesktopSearchPage />;
};

const MobileSearchPage = () => {
    const query = usePlaylistCategoriesPage({ first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => getPlaylistCategoriesPagination({ first, after }), []);

    const pageContext = usePageContext({ type: ContentType.Search, resource: null, root: NavigationPageRootName.Search, done: true });
    const playlistCategoriesContext = useSectionContext(ContentType.PlaylistCategories, product, pageContext, 0, ResourceDisplayType.Grid);

    const pagination = usePagination({ resource: product, connection: product?.playlistCategories ?? null, fetchFn });
    const items = useResourcesFromUnions(pagination.connection?.items ?? null, product);

    return (
        <MobileSearchPageView context={pageContext} scrollRef={pagination.scrollRef}>
            <ResourceSection resources={items} context={playlistCategoriesContext} pagination={pagination} />
        </MobileSearchPageView>
    );
};

export const DesktopSearchPage = () => {
    const query = usePlaylistCategoriesPage({ first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => getPlaylistCategoriesPagination({ first, after }), []);

    return (
        <ShowAllPageTemplate
            query={query}
            type={ContentType.PlaylistCategories}
            items={product?.playlistCategories ?? null}
            fetchFn={fetchFn}
            display={ResourceDisplayType.Grid}
            root={NavigationPageRootName.Explore}
            disableFiltering
        />
    );
};
