import { guard } from "./mixRadio";
import { updatePlayQueue } from "./updatePlayQueue";
import {
    getCurrentLane,
    getCurrentLaneFromState,
    getCurrentLaneIndexFromState,
    getCurrentLaneQueueId,
    getMixRadioQueueTracks,
    movePreviewLaneToFlowLane,
    updateQueueLanes
} from "../helpers";
import { dispatch, messageBus, store } from "global";
import { PLAYER_REPEAT } from "global/actions";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import type { NormalizedData } from "services/normalizeData";
import { startPlay, tryStopPlayAll } from "services/player/inputs/service/actions";
import { getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import { PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { PlayQueueModel } from "models/app/player/input";
import { QueueMode } from "models/app/player/input";
import { ShuffleState, RepeatStatus } from "models/view";

export enum PlayNextSource {
    SongEnded = "SongEnded",
    UserClick = "UserClick",
    SkipOnFail = "SkipOnFail"
}

interface PlayNextProps {
    source: PlayNextSource;
    context: AudioContextModel;
}

export const playNext = async (play: boolean | undefined, source: PlayNextSource, context: AudioContextModel) => {
    if (source === PlayNextSource.UserClick) {
        const playQueue = store.getState().queue.playQueue;
        const currentLaneIndex = getCurrentLaneIndexFromState();

        if (guard && playQueue.length - 1 === currentLaneIndex) {
            log.warn({ code: "web-210906-1439", msg: "clicking next on empty mixradio queue" });
            return;
        }
    }

    const current = getCurrentInputAudioItemFromState();

    if (play == undefined) play = current?.playState === PlayState.Buffering || current?.playState === PlayState.Playing;

    tryStopPlayAll(context);

    const { queue, continuePlay } = await playNextTrack({ source, context });

    if (!continuePlay) play = false;

    if (source === PlayNextSource.SkipOnFail) {
        if (!continuePlay) return;

        const oldCurrentLane = getCurrentLaneFromState();
        const newCurrentLane = getCurrentLane(queue.playQueue);

        if (oldCurrentLane?.id === newCurrentLane?.id) return;
    }

    updatePlayQueue(queue, context, false, null);
    await messageBus.sync();

    const queueId = getCurrentLaneQueueId();

    if (queueId == null) {
        log.error({ code: "web-210520-1621", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    if (play) {
        startPlay(queueId, context);
    }
};

const playNextTrack = async ({ source, context }: PlayNextProps): Promise<{ queue: PlayQueueModel; continuePlay: boolean }> => {
    const { queue, player } = store.getState();

    if (queue.playQueue.length === 0) {
        log.error({ code: "web-210519-1157", msg: DefaultLogMessage.UnexpectedValue });
        return { queue, continuePlay: false };
    }

    const currentIndex = getCurrentLaneIndexFromState();
    if (currentIndex === -1) {
        log.error({ code: "web-210519-1156", msg: DefaultLogMessage.UnexpectedValue });
        return { queue, continuePlay: false };
    }

    const isPlayerModeMixRadio = queue.mode === QueueMode.MixRadio;
    if (isPlayerModeMixRadio) {
        return playNextTrackMixRadioMode({ queue, currentIndex, shuffleOn: player.shuffle !== ShuffleState.Off });
    }
    return playNextTrackDefaultMode({ source, repeat: player.repeat, queue, currentIndex, context });
};

interface DefaultModeProps extends PlayNextProps {
    queue: PlayQueueModel;
    repeat: RepeatStatus;
    currentIndex: number;
}

const playNextTrackDefaultMode = ({ source, repeat, queue, currentIndex }: DefaultModeProps): { queue: PlayQueueModel; continuePlay: boolean } => {
    let continuePlay: boolean;
    let newCurrentIndex: number;
    let playQueue = queue.playQueue;
    const isEnd = currentIndex >= queue.playQueue.length - 1;

    switch (repeat) {
        case RepeatStatus.None: {
            if (queue.isEndlessPlayOn) {
                if (isEnd) {
                    if (queue.endlessPlay.length === 0) {
                        continuePlay = false;
                        newCurrentIndex = 0;
                    } else {
                        playQueue = movePreviewLaneToFlowLane(queue);
                        continuePlay = true;
                        newCurrentIndex = currentIndex + 1;
                    }
                } else {
                    continuePlay = true;
                    newCurrentIndex = currentIndex + 1;
                }
            } else {
                continuePlay = !isEnd;
                newCurrentIndex = !isEnd ? currentIndex + 1 : 0;
            }
            break;
        }
        case RepeatStatus.One: {
            continuePlay = true;
            if (source === PlayNextSource.UserClick) {
                if (isEnd) {
                    newCurrentIndex = 0;
                    dispatch({ type: PLAYER_REPEAT, payload: { repeat: RepeatStatus.None } });
                } else {
                    newCurrentIndex = currentIndex + 1;
                }
            } else {
                newCurrentIndex = currentIndex;
            }
            break;
        }
        case RepeatStatus.All: {
            continuePlay = true;
            newCurrentIndex = !isEnd ? currentIndex + 1 : 0;
            break;
        }
    }

    playQueue = updateQueueLanes(playQueue, newCurrentIndex);

    const newQueue: PlayQueueModel = { ...queue, playQueue };
    return { queue: newQueue, continuePlay };
};

interface MixRadioModeProps {
    queue: PlayQueueModel;
    currentIndex: number;
    shuffleOn: boolean;
}

const playNextTrackMixRadioMode = async ({
    queue,
    currentIndex
}: MixRadioModeProps): Promise<{ queue: PlayQueueModel; queueAddData: NormalizedData<AnalyticsQueueAddedPropertiesGroup> | null; continuePlay: boolean }> => {
    let playQueue = queue.playQueue;
    let queueAddData: NormalizedData<AnalyticsQueueAddedPropertiesGroup> | null = null;

    const remaining = playQueue.length - currentIndex - 1;

    if (remaining === 0) {
        const result = await getMixRadioQueueTracks(queue, currentIndex);
        if (result) {
            playQueue = result.queueTracks;
            queueAddData = result.queueAddData;
        }
    }

    const newCurrentIndex = currentIndex + 1;

    playQueue = updateQueueLanes(playQueue, newCurrentIndex);
    const newQueue: PlayQueueModel = { ...queue, playQueue };
    return { queue: newQueue, queueAddData, continuePlay: true };
};
