import { h } from "preact";
import { Link } from "..";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL, PLAYQUEUE_ADD_TO_LAST } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { createAndStartUserPlayTraceModel, TraceName } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlayQueuePlayableModel } from "models/domain/PlayQueuePlayableModel";
import { IconName } from "components/atoms/icon";

interface Props {
    playable: PlayQueuePlayableModel | null;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const AddToLastQueueLink = ({ playable, context, showIcon }: Props) => {
    const translations = useTranslations();
    const addToQueue = async () => {
        if (!playable) return;
        const trace = await createAndStartUserPlayTraceModel(TraceName.ClickToSound);
        dispatch({ type: PLAYQUEUE_ADD_TO_LAST, payload: { playable, playableContext: context, context: { action: AudioContextAction.UserContextMenuAddToQueue, trace } } });
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    };
    return (
        <Link disabled={!playable} icon={showIcon ? IconName.PlayAsLast : undefined} onClick={addToQueue}>
            {translations.QueuePlayAsLast}
        </Link>
    );
};
