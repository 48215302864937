import { h } from "preact";
import { useEffect } from "preact/hooks";
import { useTranslations } from "global/config";
import { QueryError } from "services/backend/backendService";
import { log } from "services/logger";
import type { PageContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { PageLoadError, PageLoadErrorType } from "models/app/ViewPageError";
import { ErrorPageType, PageLoadAppErrorType } from "models/app/ViewPageError";
import { ContentType } from "models/ModelType";
import { useErrorPageContext, useLinkAndPreviewContext, useSectionContext } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { MessagePageTemplate } from "components/templates/messagePage";

interface Props {
    page: PageContextModel | null;
    error: PageLoadError;
    refetch?: () => void;
}

export const ErrorPage = (props: Props): h.JSX.Element => {
    useEffect(
        () =>
            log.error({
                code: "web-211016-1200",
                msg: "page load error",
                data: { url: window.location.href }
            }),
        []
    );

    const { page, error } = props;

    useErrorPageContext({ page, error });
    const section = useSectionContext(ContentType.PageLoadError, null, props.page, null, null);
    const errorPageType = getErrorPageType(error.type);

    switch (errorPageType) {
        case ErrorPageType.ContentNotFoundErrorPage:
            return <ContentNotFoundPage section={section} />;
        case ErrorPageType.NoConnectionErrorPage:
            return <NoConnectionErrorPage {...props} section={section} />;
        case ErrorPageType.BackendDownErrorPage:
            return <BackendDownErrorPage />;
        case ErrorPageType.DefaultErrorPage:
            return <DefaultErrorPage {...props} section={section} />;
    }
};

export function getErrorPageType(type: PageLoadErrorType): ErrorPageType {
    switch (type) {
        case PageLoadAppErrorType.UrlSchemaNotRecognizedError:
        case QueryError.ContentNotFoundError:
        case QueryError.NotAvailableInSubscriptionError:
            return ErrorPageType.ContentNotFoundErrorPage;
        case QueryError.BrowserOfflineError:
            return ErrorPageType.NoConnectionErrorPage;
        case QueryError.NetworkServerDownError:
            return ErrorPageType.BackendDownErrorPage;
        case QueryError.NetworkClientError:
        case QueryError.NetworkServerError:
        case QueryError.NetworkUnknownError:
        case QueryError.MapError:
        case QueryError.MultipleErrors:
        case QueryError.UnknownError:
            return ErrorPageType.DefaultErrorPage;
    }
    type;
}

interface PageProps {
    section: SectionContextModel;
    refetch?: () => void;
}

const RefetchButton = ({ refetch }: { refetch?: () => void }) => {
    const translations = useTranslations();
    return (
        <Button onClick={refetch} design={ButtonDesign.SecondarySmall}>
            {translations.TryAgain}
        </Button>
    );
};
const NoConnectionErrorPage = ({ refetch }: PageProps) => {
    const translations = useTranslations();
    return <MessagePageTemplate className="NoConnectionErrorPage" title={translations.ErrorStatePageNoConnectionTitle} buttons={<RefetchButton refetch={refetch} />} />;
};

const BackendDownErrorPage = () => {
    const translations = useTranslations();
    return <MessagePageTemplate className="BackendDownErrorPage" title={translations.ErrorStatePageBackendDownTitle} text={translations.ErrorStatePageBackendDownText} />;
};

const DefaultErrorPage = ({ refetch }: PageProps) => {
    const translations = useTranslations();
    return <MessagePageTemplate className="DefaultErrorPage" title={translations.ErrorStatePageDefaultTitle} buttons={<RefetchButton refetch={refetch} />} />;
};

const ContentNotFoundPage = ({ section }: PageProps) => {
    useEffect(() => log.error({ code: "web-211012-2023", msg: "page not found (404)", data: { url: window.location.href } }), []);

    const frontPageLink = useLinkAndPreviewContext(ContentType.FrontPage, null, section, null);
    const translations = useTranslations();
    const buttons = (
        <Button link={frontPageLink} design={ButtonDesign.SecondarySmall}>
            {translations.GlobalGoToFrontPageAction}
        </Button>
    );

    return <MessagePageTemplate className="NotFoundPage" title={translations.ErrorStatePageNotFoundTitle} buttons={buttons} />;
};
