import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { Accordion } from "./Accordion";
import { Setting } from "./Setting";
import { dispatch } from "global";
import { SET_LOCALIZATION_SETTING } from "global/actions";
import { useTranslations } from "global/config";
import type { RootModel } from "models/app";
import { LanguageSetting } from "models/view/Localization";
import { RadioButton } from "components/atoms/controls/radioButton";
import { IconName } from "components/atoms/icon";

export const LanguageSettings = () => {
    const translations = useTranslations();

    const buttonDanish = <LanguageButton setting={LanguageSetting.Danish} />;
    const buttonEnglish = <LanguageButton setting={LanguageSetting.English} />;
    const buttonAuto = <LanguageButton setting={LanguageSetting.Auto} />;
    return (
        <Accordion icon={IconName.Language} title={translations.SettingsLanguage}>
            <Setting title={translations.SettingsLanguageDanish} button={buttonDanish} />
            <Setting title={translations.SettingsLanguageEnglish} button={buttonEnglish} />
            <Setting title={translations.SettingsLanguageSystem} subtitle={translations.SettingsAppearanceAutomaticSubTitle} button={buttonAuto} />
        </Accordion>
    );
};

const LanguageButton = ({ setting }: { setting: LanguageSetting }) => {
    const localizationSetting = useSelector((root: RootModel) => root.ui.localizationSetting);
    const change = useCallback(() => {
        dispatch({ type: SET_LOCALIZATION_SETTING, payload: { localizationSetting: setting } });
    }, [setting]);

    return <RadioButton checked={localizationSetting === setting} onChange={change} name={setting} />;
};
