import { h } from "preact";
import { Link } from "../Link";
import { useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { UserProfileModel } from "models/app";
import { Avatar } from "components/atoms/controls/avatar";
import "./CurrentProfileLink.scss";
import { Button } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { TopHeaderLink } from "./TopHeaderLink";

interface Props {
    profile?: UserProfileModel;
}

export const CurrentProfileLink = ({ profile }: Props) => {
    const translations = useTranslations();

    if (!profile) {
        return <TopHeaderLink title={translations.ProfileDefaultTitle} icon={IconName.Profile} testLocator={TestLocator.PageHeaderAccountButton} />
    }

    return (
        <Link closeOnClick className="currentprofilelink --hover-false" tooltip={profile ? undefined : translations.NavBarLogin} testLocator={TestLocator.AccountMenuProfileButton}>
            <span>
                {profile.title}
                <Avatar text={profile.title} color={profile.color} />
            </span>
        </Link>
    );
};
