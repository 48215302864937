import { h } from "preact";
import { TextInputState } from "../textInput";
import "./InputLabel.scss";

interface Props {
    children?: string;
    state: TextInputState;
}

export const InputLabel = ({ children, state }: Props) => {
    return <label className={`inputLabel --state-${state}`}>{children ?? ""}</label>;
};
