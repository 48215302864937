import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castQueueReorderItems(request: chrome.cast.media.QueueReorderItemsRequest): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castQueueReorderItems", request, (request, success, error) => {
        media.queueReorderItems(request, success, error);
    });
}
