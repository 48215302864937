import { createAnalyticsData, IncrementAnalyticsProp, SetAnalyticsProp, SetFirstAnalyticsProp } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";

export async function getAnalyticsAddToPlaylistProperties(duplicateTracksAdded: null | "all" | "unique" | "cancel") {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstAddToPlaylist", new Date());
    SetAnalyticsProp(data, "user", "LastAddToPlaylist", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfAddedToPlaylist");

    SetAnalyticsProp(data, "event", "DuplicatedTrackDialog", !!duplicateTracksAdded);
    if (duplicateTracksAdded) {
        const val = duplicateTracksAdded[0].toUpperCase() + duplicateTracksAdded.substring(1);
        SetAnalyticsProp(data, "event", "DuplicatedTrackAdded", val as AnalyticsKey);
    }

    return data;
}
