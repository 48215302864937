import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getDiscoverRecommendationsPagination, useDiscoverRecommendationsPage } from "services/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

export const UserRecommendationsPage = () => {
    const query = useDiscoverRecommendationsPage({ first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => getDiscoverRecommendationsPagination({ first, after }), []);

    return <ShowAllPageTemplate query={query} type={ContentType.UserRecommendations} items={product?.collections ?? null} fetchFn={fetchFn} display={ResourceDisplayType.Grid} />;
};
