import { castQueueInsertItems } from "../castRequests/media/queueInsertItems";
import { castQueueRemoveItems } from "../castRequests/media/queueRemoveItems";
import { castQueueReorderItems } from "../castRequests/media/queueReorderItems";
import type { MediaInfoCustomData } from "../models/MediaInfoCustomData";
import { getCastMediaSession } from "../setup";
import { getLocalCastQueue } from "./localCastQueue";
import { getRemoteCastQueue, setRemoteCastQueue } from "./remoteCastQueue";
import { log, LogTag } from "services/logger";
import { getLiveRadioPlayableFromAudio } from "services/player/inputs/inputs/liveRadio/service";
import { AudioInputType } from "models/app/player";
import type { AudioInputItemLiveRadioModel, AudioInputItemModel, AudioInputItemPlayQueueModel } from "models/app/player/input";
import { getMimeTypeFromStreamUrlType } from "models/app/player/input";

export async function syncExistingQueueFromLocal(): Promise<boolean> {
    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `syncExistingQueueFromLocal...` });

    const media = getCastMediaSession();
    if (!media) return false;

    const { audios, audiosCastIds: ids } = getLocalCastQueue();

    const removeOK = await remove(ids);
    if (!removeOK) return false;

    const addOK = await add(audios);
    if (!addOK) return false;

    const reorderOK = await reorder(ids);
    if (!reorderOK) return false;

    return true;
}

async function remove(ids: number[]): Promise<boolean> {
    const remoteIds = getRemoteCastQueue();
    const removeIds = [...remoteIds].filter((id) => ids.indexOf(id) === -1);
    if (removeIds.length === 0) return true;

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `remove items: ${removeIds}` });

    const send = await castQueueRemoveItems(removeIds);
    if (!send) return false;

    const newRemoteIds = remoteIds.filter((id) => removeIds.indexOf(id) === -1);
    setRemoteCastQueue(newRemoteIds);

    return true;
}

async function add(audios: AudioInputItemModel[]): Promise<boolean> {
    const remoteIds = getRemoteCastQueue();

    const missing = audios.filter((audio) => remoteIds.indexOf(audio.chromecastId) === -1);
    const addItems = getQueueItemsFromAudios(missing);
    if (addItems.length === 0) return true;

    // we must sync remote ids before we can use this function
    throw Error("not implemented");

    const addIds = addItems.map((item) => item.itemId);

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `adding items: ${addIds}` });

    const request = new chrome.cast.media.QueueInsertItemsRequest(addItems);

    const send = await castQueueInsertItems(request);
    if (!send) return false;

    const newRemoteIds = [...remoteIds, ...addIds];
    setRemoteCastQueue(newRemoteIds);

    return true;
}

async function reorder(ids: number[]): Promise<boolean> {
    const remoteIds = getRemoteCastQueue();

    if (ids.length !== remoteIds.length) {
        log.error({ code: "web-230110-1354", msg: "cc order error", data: { ids, ccIds: remoteIds } });
        return false;
    }

    let reorder = false;
    for (let i = 0; i < ids.length; i++) {
        const id1 = ids[i];
        const id2 = remoteIds[i];

        if (id1 !== id2) {
            reorder = true;
            break;
        }
    }

    if (!reorder) return true;

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `reordering items: ${ids}` });

    const send = await castQueueReorderItems(new chrome.cast.media.QueueReorderItemsRequest(ids));
    if (!send) return false;

    setRemoteCastQueue([...ids]);

    return true;
}

export function getQueueItemsFromAudios(audios: AudioInputItemModel[]): chrome.cast.media.QueueItem[] {
    const items = audios.map((audio) => getQueueItemFromAudio(audio)).filter((item) => item) as chrome.cast.media.QueueItem[];
    return items;
}

function getQueueItemFromAudio(audio: AudioInputItemModel): chrome.cast.media.QueueItem | null {
    const mediaInfo = getMediaInfoFromAudio(audio);
    if (!mediaInfo) return null;

    Object.keys(mediaInfo).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((mediaInfo as any)[key] == undefined || (mediaInfo as any)[key] == "") delete (mediaInfo as any)[key];
    });

    const item = new chrome.cast.media.QueueItem(mediaInfo);
    item.itemId = audio.chromecastId;

    Object.keys(item).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((item as any)[key] == undefined) delete (item as any)[key];
    });

    return item;
}

function getMediaInfoFromAudio(audio: AudioInputItemModel): chrome.cast.media.MediaInfo | null {
    switch (audio.input) {
        case AudioInputType.PlayQueue:
            return getMediaInfoFromPlayQueueAudio(audio);
        case AudioInputType.LiveRadio:
            return getMediaInfoFromLiveRadioAudio(audio);
    }
}

function getMediaInfoFromPlayQueueAudio(audio: AudioInputItemPlayQueueModel): chrome.cast.media.MediaInfo | null {
    const playbackContext = audio.playbackContext;
    if (!playbackContext) {
        log.error({ tags: [LogTag.Chromecast], code: "web-230109-1439", msg: "no playbackContext on playQueue audio" });
        return null;
    }

    const mediaInfo = new chrome.cast.media.MediaInfo("", "");

    const customData: MediaInfoCustomData = {
        Id: audio.trackId,
        C: playbackContext
    };
    mediaInfo.customData = customData;

    return mediaInfo;
}

function getMediaInfoFromLiveRadioAudio(audio: AudioInputItemLiveRadioModel): chrome.cast.media.MediaInfo | null {
    const url = audio.url2;
    if (!url) {
        log.error({ tags: [LogTag.Chromecast], code: "web-230109-1439", msg: "no url on radio audio" });
        return null;
    }

    const playbackContext = audio.playbackContext;
    if (!playbackContext) {
        log.error({ tags: [LogTag.Chromecast], code: "web-230109-1439", msg: "no playbackContext on liveradio audio" });
        return null;
    }

    const mime = getMimeTypeFromStreamUrlType(url.urlType);

    const mediaInfo = new chrome.cast.media.MediaInfo(url.url, mime);
    mediaInfo.streamType = chrome.cast.media.StreamType.LIVE;

    const customData: MediaInfoCustomData = {
        Id: audio.radioId,
        IsLiveStream: true,
        C: playbackContext
    };
    mediaInfo.customData = customData;

    const radio = getLiveRadioPlayableFromAudio(audio)?.liveRadio;
    const title = radio?.title;
    const cover = radio?.cover;

    if (!title) log.error({ tags: [LogTag.Chromecast], code: "web-230109-1411", msg: "no title in radio in cc" });
    if (!cover) log.error({ tags: [LogTag.Chromecast], code: "web-230109-1455", msg: "no cover in radio in cc" });

    const metadata = new chrome.cast.media.GenericMediaMetadata();
    mediaInfo.metadata = metadata;
    if (title) metadata.title = title;
    if (cover) metadata.images = [new chrome.cast.Image(cover)];

    return mediaInfo;
}
