import { currentPlaybackStreamTicket } from "./setActivePlayback";
import { dispatch, messageBus, store } from "global";
import { AUDIO_OUTPUT_AUDIO_ENDED, PLAYER_SET_PLAY, PLAYER_STARTED_BY_USER } from "global/actions";
import { mutateGetPlaybackStream } from "services/backend";
import { environment } from "services/environment";
import { getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import { AudioControllerType, PlayState } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import { showConcurrencyModal } from "components/organisms/concurrencyModal";

const pollingIntervalInSeconds = 8;
const pollingMinIntervalInSeconds = 5; // how much of time to allow since last poll, without triggering a poll at start-of-track

let lastPoll = secondsSinceEpoch() - pollingIntervalInSeconds;

export async function initConcurrencyPolling() {
    if (!environment.enableConcurrencyPolling) return;

    setInterval(() => {
        try {
            if (!isPollThrottled(pollingIntervalInSeconds)) {
                pollIfPlaying();
            } else {
                //console.log(`-=-= throttled (${ Math.floor(secondsSinceEpoch() - lastPoll) } seconds since last poll` );
            }
        } catch (e) {
            //
        }
    }, 2000);

    // there's no reason to check right after the user started playback and set the active device
    messageBus.subscribeEvery(PLAYER_STARTED_BY_USER, () => {
        lastPoll = secondsSinceEpoch();
    });

    // hack to poll when a new track starts playing.
    // we don't have an event for that, but this fires when a track ends, so we can just wait a bit
    messageBus.subscribeEvery(AUDIO_OUTPUT_AUDIO_ENDED, () => {
        setTimeout(() => {
            if (!isPollThrottled(pollingMinIntervalInSeconds)) {
                pollIfPlaying();
            }
        }, 2000);
    });
}

async function pollIfPlaying() {
    const state = getCurrentInputAudioItemFromState();
    const isCasting = store.getState().player.controller === AudioControllerType.Chromecast;
    if (!isCasting && state?.playState === PlayState.Playing) {
        poll();
    }
}

async function poll() {
    //console.log("polling");
    lastPoll = secondsSinceEpoch();
    const ticket = currentPlaybackStreamTicket();

    if (ticket != null) {
        const isStillActive = await mutateGetPlaybackStream({ id: ticket });
        if (isStillActive === false) {
            dispatch({
                type: PLAYER_SET_PLAY,
                payload: {
                    play: false,
                    context: { action: AudioContextAction.ConcurrencyLost, trace: null }
                }
            });

            // setConcurrencyHasBeenAway(true);
            showConcurrencyModal("legacy", "");
        }
    } else {
        // todo: consider logging
        //console.log("concurrency check doesn't have a stream id");
    }
}

function isPollThrottled(minSecondsSinceLast = 0) {
    return lastPoll + minSecondsSinceLast > secondsSinceEpoch();
}
function secondsSinceEpoch() {
    return new Date().getTime() / 1000;
}
