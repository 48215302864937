import { h } from "preact";
import { useContext } from "preact/hooks";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import { AddToLastQueueLink, AddToPlaylistLink, AddToQueueLink } from "../links";
import { ResourceMenuTop } from "./tops";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { TrackChartPreviewModel } from "models/domain/ChartModel";

interface Props {
    trackChart: TrackChartPreviewModel | null;
    context: PreviewContextModel;
}

export const TrackChartContextMenu = ({ context, trackChart }: Props) => {
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <AddToPlaylistLink playable={trackChart} context={context} showIcon={showIcon} />
            <AddToQueueLink playable={trackChart} context={context} showIcon={showIcon} />
            <AddToLastQueueLink playable={trackChart} context={context} showIcon={showIcon} />
        </Sheet>
    );
};
