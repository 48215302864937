import type { LiveRadioCategoryPageModel, LiveRadioPlayModel, LiveRadioPreviewModel, PlayQueuePlayableModelInterface } from ".";
import type { LayoutItemModel } from "./layoutPage";
import { ContentType, DomainModelType } from "models/ModelType";

export type LiveRadioParentModel = LiveRadioCategoryPageModel | LayoutItemModel;
export type LiveRadioParentContentType = LiveRadioParentModel["contentType"];

export type LiveRadioPlayableContentType = LiveRadioPlayableModel["contentType"];

export type LiveRadioPlayableModel = LiveRadioPlayablePreviewModel;
export type LiveRadioPlayablePreviewModel = ({ type: DomainModelType.Preview } & LiveRadioPlayablePreview) | LiveRadioPlayablePlayModel;
export type LiveRadioPlayablePlayModel = { type: DomainModelType.Play } & LiveRadioPlayablePlay;

interface LiveRadioPlayablePreview extends PlayQueuePlayableModelInterface {
    readonly id: string;
    readonly contentType: ContentType.LiveRadioPlayable;
    readonly liveRadio: LiveRadioPreviewModel;
    readonly parent: LiveRadioParentModel;
}

interface LiveRadioPlayablePlay extends LiveRadioPlayablePreview {
    readonly id: string;
    readonly liveRadio: LiveRadioPlayModel;
    readonly parent: LiveRadioParentModel;
}

export function createLiveRadioPlayablePreview(liveRadio: LiveRadioPreviewModel, parent: LiveRadioParentModel): LiveRadioPlayablePreviewModel {
    return {
        graphType: liveRadio.graphType,
        id: liveRadio.id,
        type: DomainModelType.Preview,
        cover: liveRadio.cover,
        contentType: ContentType.LiveRadioPlayable,
        liveRadio,
        parent,
        trackCount: null
    };
}

export function createLiveRadioPlayablePlay(liveRadio: LiveRadioPlayModel, parent: LiveRadioParentModel): LiveRadioPlayablePreviewModel {
    return {
        graphType: liveRadio.graphType,
        id: liveRadio.id,
        type: DomainModelType.Play,
        cover: liveRadio.cover,
        contentType: ContentType.LiveRadioPlayable,
        liveRadio,
        parent,
        trackCount: null
    };
}

export function createLiveRadioPlayablePlayModel(liveRadioPreview: LiveRadioPlayablePreviewModel, liveRadio: LiveRadioPlayModel): LiveRadioPlayablePlayModel {
    return {
        ...liveRadioPreview,
        type: DomainModelType.Play,
        liveRadio
    };
}
