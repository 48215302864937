import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { store } from "global";
import type { RootModel } from "models/app";
import { LanguageSetting, Language } from "models/view/Localization";

export function getLocalization(setting: LanguageSetting, browserLang: Language): Language {
    switch (setting) {
        case LanguageSetting.Danish:
            return Language.Danish;
        case LanguageSetting.English:
            return Language.English;
        case LanguageSetting.Auto:
            return browserLang;
    }
}

export function useLocalization(): Language {
    const setting = useSelector((root: RootModel) => root.ui.localizationSetting);
    const os = useSelector((root: RootModel) => root.ui.browserLanguage);

    return useMemo(() => getLocalization(setting, os), [setting, os]);
}
const getBrowserLang = () => store.getState().ui.browserLanguage;

export function getLang(setting: LanguageSetting): Language {
    const os = getBrowserLang();
    return getLocalization(setting, os);
}
export function getLangFromState(): Language {
    const setting = getLanguageSettingFromState();
    const os = getBrowserLang();

    return getLocalization(setting, os);
}
export function getLanguageSettingFromState() {
    return store.getState().ui.localizationSetting;
}
