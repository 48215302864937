import type { FirebasePerformance, PerformanceTrace } from "firebase/performance";
import { getFbPerformance } from "./getFbPerformance";
import { getOrCreateFbApp } from "./initFBLoggerService";
import { DefaultLogMessage, log } from "./initLoggerService";
import { messageBus } from "global";
import { TRACING_NEW_TRACE } from "global/actions";
import { fbaseConfig } from "global/constants";

type FirebaseTrace = (performance: FirebasePerformance, name: string) => PerformanceTrace;

export const initFbPerformance = () => {
    const fbApp = getOrCreateFbApp();

    if (!fbApp) {
        log.warn({ code: "web-210831-1649", msg: "blocked" });
        return null;
    }
    try {
        const performance = getFbPerformance(fbApp);
        if (!performance) {
            log.warn({ code: "web-210519-1611", msg: "blocked" });
            return;
        }

        performance.instrumentationEnabled = false;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fbTrace: FirebaseTrace | null = (<any>window).trackingFbase?.trace;
        if (!fbTrace) {
            log.warn({ code: "web-220502-1140", msg: "blocked" });
            return;
        }

        messageBus.subscribeEvery(TRACING_NEW_TRACE, (msg) => {
            const { trace } = msg.payload;

            const performanceTrace = fbTrace(performance, trace.name);

            trace.startCallbacks.push(() => performanceTrace.start());

            trace.putAttributeCallbacks.push((attribute) => {
                const value = attribute.value.toString().substring(0, fbaseConfig.MaxTraceValueLength);
                if (value.length === 0) {
                    log.error({ code: "web-220426-1601", msg: DefaultLogMessage.UnexpectedEmptyString });
                    return;
                }
                performanceTrace.putAttribute(attribute.type, value);
            });

            trace.stopCallbacks.push(() => performanceTrace.stop());
        });
    } catch (e) {
        log.error({ code: "web-211026-1611", msg: DefaultLogMessage.UnexpectedNull, error: e });
    }
};
