import { h } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import "./LayoutPage.scss";
import { useLocalization } from "components/app/hooks";
import { getLayoutItemPage, useLayoutPageSkeleton } from "services/backend";
import type { CombinedQueryParams } from "services/backend/backendService";
import { useCombinedQuery, useCombinedQueryResult } from "services/backend/backendService";
import { getResourceDisplayCount } from "services/resource";
import type { RootModel } from "models/app";
import type { LocationContentType } from "models/domain";
import type { LayoutItemSectionModel, LayoutPageModel } from "models/domain/layoutPage";
import { fillLayoutPageWithItemSection } from "models/domain/layoutPage";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";
import { useAppMediaSize, usePageContext, usePageLoadState, usePageScrollRestoration, useTabIsActive } from "components/shared/hooks";
import { LayoutPageGroup } from "components/organisms/layoutPage";

interface Props {
    pageId: string;
    type: LocationContentType;
    root: NavigationPageRootName;
}

export const LayoutPage = ({ pageId, type, root }: Props) => {
    const personalized = root === NavigationPageRootName.FrontPage;

    const query = usePageQuery(pageId, personalized);
    const product = query?.model ?? null;

    const page = usePageContext({ type: type ?? ContentType.LayoutPage, resource: product, root, done: query?.success === true });
    const restoration = usePageScrollRestoration({ page });
    const size = useAppMediaSize();

    const { error, spinner } = usePageLoadState(page, query);
    if (error) return error;
    if (spinner) return spinner;

    return (
        <div
            tabIndex={-1}
            className={`layoutPage Ta2 --size-${size}`}
            ref={(ref) => {
                restoration.scrollRef.current = ref;
            }}>
            <div
                className="content"
                ref={(ref) => {
                    restoration.contentRef.current = ref;
                }}>
                {product?.groups && product.groups.items.map((group) => <LayoutPageGroup key={group.id} group={group} context={page} />)}
            </div>
        </div>
    );
};

const usePageQuery = (pageId: string, personalized?: boolean) => {
    const [reloadGuard, setReloadGuard] = useState("");
    const userId = useSelector((root: RootModel) => root.user.id);
    const local = useLocalization();
    const pageQuery = useLayoutPageSkeleton({ pageId });

    const page = pageQuery.model;
    const pageLoading = pageQuery.loading || pageQuery.refetching || pageQuery.model == null;
    const pageError = pageQuery.error;

    useEffect(() => {
        const guard = `${userId}${personalized}${local}`;
        setReloadGuard(guard);
        if (!reloadGuard || reloadGuard == guard) return;
        if (!personalized) return;
        pageQuery.refetch(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, personalized, local, reloadGuard]);

    const sectionParams: CombinedQueryParams<LayoutPageModel> | null = useMemo(() => {
        if (page == null || pageLoading || pageError) return null;

        if (page?.groups?.items == null) {
            return {
                model: page,
                params: []
            };
        }

        const items = page.groups.items
            .map((group) => group.items?.items)
            .flat()
            .filter((item) => item != null) as LayoutItemSectionModel[];

        const params = items.map((item) => ({
            fetch: () => getLayoutItemPage({ pageId: page.id, itemId: item.id, first: getResourceDisplayCount(item.displayType), after: null }, item.displayType),
            map: fillLayoutPageWithItemSection,
            next: item.refreshFrequency != null ? item.refreshFrequency * 1000 : undefined
        }));

        return {
            model: page,
            params
        };
    }, [page, pageLoading, pageError]);

    const { query: sectionsQuery, setAutoUpdate } = useCombinedQuery<LayoutPageModel>(sectionParams);

    const tabActive = useTabIsActive();
    useEffect(() => setAutoUpdate(tabActive), [setAutoUpdate, tabActive]);

    const combinedParams = useMemo(() => [pageQuery, sectionsQuery], [pageQuery, sectionsQuery]);
    const combined = useCombinedQueryResult(sectionsQuery.model, combinedParams);

    const anyOK = pageQuery.error == null && sectionsQuery.queries.some((query) => query.error == null);

    combined.loading = pageQuery.loading;
    combined.error = anyOK ? null : pageQuery.error ?? combined.error;
    combined.errorReason = [pageQuery, combined]
        .filter((n) => !!n.error)
        .map((x) => x.errorReason)
        .join(", ");

    return combined;
};
