import { h } from "preact";
import "./Spinner.scss";
import { useLayoutEffect, useState } from "preact/hooks";
import { SpinnerSize } from ".";
import { SpinnerState, useBufferingSpinner } from "./useBufferingSpinner";
import { animations } from "global/constants";
import { DelayType, useDelay } from "components/shared/hooks";

interface SpinnerProps {
    state: SpinnerState.In | SpinnerState.Out;
    color?: "White" | "Brand";
    size?: SpinnerSize;
}

const Spinner = ({ state, color = "White", size = SpinnerSize.Size24 }: SpinnerProps) => {
    const delay = useDelay({ delay: { type: DelayType.AnimationFrame }, reset: state });
    if (!delay && state === SpinnerState.In) state = SpinnerState.Out;

    let s: number;
    let c: number;
    let r: number;

    switch (size) {
        case SpinnerSize.Size24: {
            s = 24;
            c = 12;
            r = 9;
            break;
        }
        case SpinnerSize.Size48: {
            s = 48;
            c = 24;
            r = 19;
            break;
        }
    }

    return (
        <div className={`spinner HsO --animate-${state} --color-${color} --size-${size}`}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width={`${s}px`}
                height={`${s}px`}
                viewBox={`0 0 ${s} ${s}`}
                xmlSpace="preserve">
                <circle className="circle" cx={c} cy={c} r={r} fill="transparent" stroke="currentColor" strokeWidth="2" />
            </svg>
        </div>
    );
};

export const PlayableLoadSpinner = () => {
    const state = useBufferingSpinner();
    if (state == SpinnerState.None) return null;

    return <Spinner state={state} />;
};

export const ContentLoadSpinner = () => {
    const [visible, setVisible] = useState(false);

    useLayoutEffect(() => {
        setVisible(false);
        const timeoutId = window.setTimeout(() => setVisible(true), animations.spinnerPageLoad);
        return () => clearTimeout(timeoutId);
    }, []);

    return visible ? <Spinner state={SpinnerState.In} color="Brand" size={SpinnerSize.Size24} /> : null;
};

export const PageLoadSpinner = () => {
    return <Spinner state={SpinnerState.In} color="Brand" size={SpinnerSize.Size48} />;
};
