import type { AnalyticsData } from "../analyticsData";
import { SetAnalyticsProp, combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { getAnalyticsPlayableIdProperties, convertPlayableProperties, getAnalyticsPlayableProperties, getAndConvertAnalyticsNavigationProperties } from "../properties/event";
import { getAnalyticsMyMusicAddedProperties, getAnalyticsMyMusicRemovedProperties, getAnalyticsUserProperties } from "../properties/user";
import type { PlaylistPreviewModel, PlayQueuePlayableModel } from "models/domain";

export async function getAnalyticsMyMusicChangedEvent(operation: "add" | "remove", playable: PlayQueuePlayableModel): Promise<AnalyticsData> {
    const map = await combineAnalyticsData(
        operation === "add" ? getAnalyticsMyMusicAddedProperties() : getAnalyticsMyMusicRemovedProperties(),
        convertPlayableProperties(getAnalyticsPlayableProperties(playable)),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getAndConvertAnalyticsNavigationProperties()
    );

    return map;
}
