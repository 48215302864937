// import { h } from "preact";
// import { Sheet } from "../..";
// import { ResourceTypeMenuTop } from "./tops/ResourceTypeMenuTop";
// import { ContentType } from "models/ModelType";

// todo must have context before it can be implemented
export const MyMusicPlaylistsContextMenu = () => {
    
    // const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    return null;
    // return (
    //     <Sheet top={<ResourceTypeMenuTop resourceType={ ContentType.MyMusicPlaylists} />}>not implemented</Sheet>
    // );
};
