import { useCurrentInputAudioItemFromState, getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import type { PlayPausePositionModel } from "models/app/player";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";

export interface PlayProperties {
    loaded: boolean;
    canPlay: boolean;
    canPause: boolean;
    playState: PlayState;
    duration: number;
    position: PlayPausePositionModel;
}

export function useAudioProperties(): PlayProperties {
    const loaded = useCurrentInputAudioItemFromState();
    return getAudioProperties(loaded);
}

export function getAudioPropertiesFromState(): PlayProperties {
    const loaded = getCurrentInputAudioItemFromState();
    return getAudioProperties(loaded);
}

export function getCurrentAudioPlayState() {
    const loaded = getCurrentInputAudioItemFromState();
    return loaded?.playState ?? null;
}

function getAudioProperties(loaded: AudioInputItemModel | null): PlayProperties {
    const canPlay = loaded ? loaded.playState === PlayState.Stopped || loaded.playState === PlayState.Paused || loaded.playState === PlayState.Error : false;
    const duration = loaded?.input === AudioInputType.PlayQueue ? loaded.duration : 0;
    const position = loaded?.input === AudioInputType.PlayQueue ? loaded.position : { pausePosition: 0 };
    return loaded
        ? { ...loaded, duration, loaded: true, canPlay, canPause: !canPlay, position }
        : {
              loaded: false,
              canPlay: false,
              canPause: false,
              playState: PlayState.Stopped,
              duration: 0,
              position // todo: is this correct?
          };
}
