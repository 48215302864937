import { OperationSource, tryOperation, syncPlayStateFromRemote, waitOne } from "../../actions";
import { log, LogTag } from "services/logger";
import { AudioContextAction } from "models/app/player/AudioContext";

export async function currentTimeChanged({ value }: cast.framework.RemotePlayerChangedEvent<number>) {
    await waitOne();

    log.debug([LogTag.Chromecast], () => ({ code: "web-211029-1312", msg: `currentTimeChange: ${value}` }));

    tryOperation(() => syncPlayStateFromRemote({ action: AudioContextAction.ChromecastCurrentTimeChange, trace: null }), OperationSource.Chromecast);
}
