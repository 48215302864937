import type { ComponentChildren } from "preact";
import { h, Fragment } from "preact";
import { DesktopTopBar } from "./desktop";
import { MobileTopBar } from "./mobile";
import type { QueryError } from "services/backend/backendService";
import type { LinkContextModel, PageContextModel } from "models/app/resourceContextModel";
import { useAppMediaSize, MediaSize } from "components/shared/hooks/useAppMediaSize";

export interface PageTopBarProps {
    error?: QueryError | null;
    page?: PageContextModel | null;
    middle?: ComponentChildren;
    back?: boolean;
    up?: LinkContextModel;
    disableMobileTitle?: boolean;
    onClose?: () => void;
    right?: ComponentChildren;
}

export const PageTopBar = (props: PageTopBarProps) => {
    const appSize = useAppMediaSize();
    const isMobile = appSize === MediaSize.Mobile;
    return (
        <Fragment>
            {isMobile && <MobileTopBar {...props} />}
            <DesktopTopBar {...props} showRight={!isMobile} />
        </Fragment>
    );
};
