import { useEffect, useMemo, useState } from "preact/hooks";
import { log } from "./logger";

export function isEqual<T>(array1: T[], array2: T[]): boolean {
    if (array1 === array2) return true;
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) return false;
    }
    return true;
}

export function isEqualByPredicate<T>(array1: T[], array2: T[], predicate: (a: T, b: T) => boolean): boolean {
    if (array1 === array2) return true;
    if (array1.length !== array2.length) return false;
    for (let i = 0; i < array1.length; i++) {
        if (!predicate(array1[i], array2[i])) return false;
    }
    return true;
}
export function arrayDifference<T>(values: T[], remove: T[]): T[] {
    return remove.filter((x) => !values.includes(x));
}

export function distinct<T>(array: T[], predicate?: (a: T, b: T) => boolean): T[] {
    const newArray: T[] = [];
    array.forEach((a) => {
        if (predicate && newArray.some((b) => predicate(a, b))) return;
        if (!predicate && newArray.indexOf(a) !== -1) return;
        newArray.push(a);
    });
    return newArray;
}

export function remove<T>(values: T[], remove: T): boolean {
    const removeIndex = values.indexOf(remove);

    if (removeIndex !== -1) {
        values.splice(removeIndex, 1);
        return true;
    } else {
        return false;
    }
}

export function splitIntoChunks<T>(array: T[], chunkSize: number): Array<T[]> {
    const newArray: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        newArray.push(array.slice(i, i + chunkSize));
    }
    return newArray;
}

export function combineAndFilterValues<T>(values: (T | T[] | null | undefined)[]): T[] {
    const v = values.flat();
    const d = v.filter((value) => value != null) as T[];
    return d;
}

function _updateArray<T>(array: T[], findFn: (value: T) => boolean, changeFn: (value: T) => T, _log: boolean, code: `web-${number}-${number}`): T[] {
    const arrayCopy = [...array];
    const value = arrayCopy.find((value) => findFn(value));
    if (!value) {
        if (_log) window.setTimeout(() => log.error({ code, msg: "value doesn't exist in array", data: { array, value, findFn } }));

        return arrayCopy;
    }
    arrayCopy[arrayCopy.indexOf(value)] = changeFn(value);
    return arrayCopy;
}

export function updateArray<T>(array: T[], findFn: (value: T) => boolean, changeFn: (value: T) => T, code: `web-${number}-${number}`): T[] {
    return _updateArray(array, findFn, changeFn, true, code);
}

export function tryUpdateArray<T>(array: T[], findFn: (value: T) => boolean, changeFn: (value: T) => T, code: `web-${number}-${number}`): T[] {
    return _updateArray(array, findFn, changeFn, false, code);
}

export function addOrUpdateItemInArray<T>(array: T[], findFn: (value: T) => boolean, changeFn: (value: T | null) => T): T[] {
    array = [...array];
    const index = array.findIndex((value) => findFn(value));

    if (index === -1) {
        array.push(changeFn(null));
    } else {
        const value = array[index];
        array[index] = changeFn(value);
    }

    return array;
}

export function findLast<T>(array: T[], predicate: (value: T) => boolean): T | undefined {
    for (let i = array.length - 1; i >= 0; --i) {
        const x = array[i];
        if (predicate(x)) {
            return x;
        }
    }
}

export function findLastIndex<T>(array: T[], predicate: (value: T) => boolean): number {
    for (let i = array.length - 1; i >= 0; --i) {
        const x = array[i];
        if (predicate(x)) {
            return i;
        }
    }
    return -1;
}

export function split<T>(arr: T[], index: number): { before: T[]; value: T | null; after: T[] } {
    const before = arr.slice(0, index);
    const value = (arr[index] ?? null) as T | null;
    const after = arr.slice(index + 1);
    return { before, value, after };
}

export function moveItemToFrontOfArray<T>(array: T[], index: number): T[] {
    if (index === -1) {
        log.error({ code: "web-210519-1216", msg: "the item is not in this array" });
        return array;
    }
    array.unshift(array.splice(index, 1)[0]);
    return array;
}

export function move<T>(array: T[], from: number, to: number, on = 1) {
    return (array = array.slice()), array.splice(to, 0, ...array.splice(from, on)), array;
}

export function splitArrayIntoChunks<T>(array: T[], size: number) {
    const results: T[][] = [];

    while (array.length) {
        results.push(array.splice(0, size));
    }

    return results;
}
export function splitArrayIntoChunksWithReuse<T>(array: T[], size: number, arrayOldChunks: T[][]) {
    const results: T[][] = [];
    let isChanged = false;

    while (array.length) {
        const newChunk = array.splice(0, size);
        const oldChunk = arrayOldChunks[results.length];
        const isSame = !!oldChunk && oldChunk.length === newChunk.length && newChunk.every((value, i) => value === oldChunk[i]);
        isChanged ||= !isSame;
        results.push(isSame ? oldChunk : newChunk);
    }

    return isChanged ? results : arrayOldChunks;
}

export function useSplitArrayIntoChunksWithReuse<T>(array: T[], size: number) {
    const [arrayOldChunks, setArrayOldChunks] = useState<T[][]>([]);

    const chunks = useMemo(() => {
        return splitArrayIntoChunksWithReuse([...array], size, arrayOldChunks);
    }, [array, arrayOldChunks, size]);

    setArrayOldChunks(chunks);
    return chunks;
}
