import { parseData, getData, saveData } from "../helpers";
import { getSharedLoadedPlaybackReportsStorageKey } from "../storageKeys";
import type { LoadedPlaybackReport } from "models/app/playbackReport";

export function getSharedLoadedPlaybackReports() {
    const key = getSharedLoadedPlaybackReportsStorageKey();
    const reports = parseData<LoadedPlaybackReport[]>(getData(key));
    return reports;
}

export function saveSharedLoadedPlaybackReports(reports: LoadedPlaybackReport[]) {
    const key = getSharedLoadedPlaybackReportsStorageKey();
    saveData(key, JSON.stringify(reports));
}
