import type { ResourceModelLinkInterface, TrackPreviewModel } from ".";
import type { ConnectionModel } from "./ConnectionModel";
import { createTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import { pageContent } from "global/constants/pageContent";
import { getDurationFromTracks } from "services/playable";
import type { TrackRadioLinkFragment, TrackRadioPageFragment, TrackRadioPlayFragment, TrackRadioPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type TrackRadioLinkData = TrackRadioLinkFragment;
type TrackRadioPreviewData = TrackRadioPreviewFragment;
type TrackRadioPlayData = TrackRadioPlayFragment;
type TrackRadioPageData = TrackRadioPageFragment;

export type TrackRadioModel = TrackRadioLinkModel;
export type TrackRadioLinkModel = ({ type: DomainModelType.Link } & TrackRadioLink) | TrackRadioPlayModel;
export type TrackRadioPlayModel = ({ type: DomainModelType.Play } & TrackRadioPlay) | TrackRadioPageModel;
export type TrackRadioPageModel = { type: DomainModelType.Page } & TrackRadioPage;

interface TrackRadioLink extends ResourceModelLinkInterface {
    readonly contentType: ContentType.TrackRadio;
    readonly title: string;
}
type TrackRadio = TrackRadioLink & PlayQueuePlayableModelInterface;
type TrackRadioPreview = TrackRadio;

interface TrackRadioPlay extends TrackRadioPreview {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}
interface TrackRadioPage extends TrackRadioPlay {
    readonly duration: number;
}

export function createTrackRadioLinkModel(data: TrackRadioLinkData): TrackRadioLinkModel {
    return {
        id: data.id,
        title: data.title,
        type: DomainModelType.Link,
        contentType: ContentType.TrackRadio
    };
}
function createTrackRadio(data: TrackRadioPreviewData): TrackRadio {
    return {
        ...createTrackRadioLinkModel(data),
        graphType: data.__typename,
        cover: data.cover ?? null,
        trackCount: pageContent.trackRadioPageItemsLimit
    };
}

export function createTrackRadioPlayModel(data: TrackRadioPlayData): TrackRadioPlayModel {
    return {
        ...createTrackRadio(data),
        type: DomainModelType.Play,
        tracks: data.trackRadio ? createTrackPreviewConnectionModel(data.trackRadio, 0) : null
    };
}

export function createTrackRadioPageModel(data: TrackRadioPageData): TrackRadioPageModel {
    const similarRadioTracks = data.trackRadio ? createTrackPreviewConnectionModel(data.trackRadio, 0) : null;
    return {
        ...createTrackRadio(data),
        type: DomainModelType.Page,
        tracks: similarRadioTracks,
        duration: similarRadioTracks ? getDurationFromTracks(similarRadioTracks.items) : 0
    };
}
export function createTrackRadioPageModelFromPlayModel(model: TrackRadioPlayModel): TrackRadioPageModel {
    const tracks = model.tracks;
    return {
        ...model,
        type: DomainModelType.Page,
        tracks,
        duration: tracks ? getDurationFromTracks(tracks.items) : 0
    };
}
