import { tryOperation, syncMediaLoadedFromRemote, OperationSource, waitOne } from "../../actions";
import { log, LogTag } from "services/logger";
import { AudioContextAction } from "models/app/player/AudioContext";

export async function isMediaLoadedChanged({ value }: cast.framework.RemotePlayerChangedEvent<boolean>) {
    await waitOne();
    
    log.info({ tags: [LogTag.Chromecast], code: "web-211029-1312", msg: `isMediaLoadedChanged: value: ${value}` });

    tryOperation(() => syncMediaLoadedFromRemote({ action: AudioContextAction.ChromecastMediaLoadedChange, trace: null }), OperationSource.Chromecast);
}
