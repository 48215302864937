/* eslint-disable import/no-named-as-default-member */

import type { Config, OverridedMixpanel } from "mixpanel-browser";
import { allowUserAnalytics, getMessageLogFields, LogLevel } from ".";
import type { AnalyticsProp } from "./analytics/analyticsData";
import { DefaultLogMessage, log } from "./initLoggerService";
import { messageBus, store } from "global";
import { WebAppEnvironment } from "shared/models";
import { LOGGING_EVENT_ANALYTICS, LOGGING_EVENT_MESSAGE } from "global/actions";
import { analyticsKeys } from "global/config";
import { mpanelConfigDevelopment, mpanelConfigProduction } from "global/constants/mpanel";
import { environment } from "services/environment";

let currentIdentifyId: string | null = null;

const initMpanel = (key: string) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const trackingMpanel: any | null = (<any>window).trackingMpanel ?? null;
        if (!trackingMpanel) {
            console.error({ code: "web-220603-1221", msg: "blocked" });
            return undefined;
        }
        const mpanel: OverridedMixpanel | null = trackingMpanel.mp ?? null;
        if (!mpanel) {
            console.error({ code: "web-220502-1547", msg: DefaultLogMessage.UnexpectedNull });
            return undefined;
        }

        mpanel.init(key, getConfig());
        return mpanel;
    } catch (e) {
        console.error({ code: "web-220217-1322", msg: "could not initiate", e });
        return null;
    }
};
export const initMpLoggerService = () => {
    const key = getKey();
    const mpanel = initMpanel(key);
    if (mpanel === undefined) return;

    messageBus.subscribeEvery(LOGGING_EVENT_MESSAGE, (msg) => {
        const logToMpanel = () => {
            const { message } = msg.payload;
            if (message.level === LogLevel.Debug || message.level === LogLevel.Info) return;

            const logName = (() => {
                switch (message.level) {
                    case LogLevel.Warning:
                        return "Web Warning";
                    case LogLevel.Error:
                        return "Web Error";
                }
            })();
            if (!mpanel) {
                log.error({ code: "web-220502-1548", msg: DefaultLogMessage.UnexpectedNull });
                return;
            }

            try {
                const fields = getMessageLogFields(message, "Web Log Field");
                mpanel?.track(logName, fields);
            } catch (e) {
                log.error({ code: "web-220217-1324", msg: "couldn't log", error: e });
            }
        };

        setTimeout(() => logToMpanel(), 0);
    });

    messageBus.subscribeEvery(LOGGING_EVENT_ANALYTICS, (msg) => {
        if (!allowUserAnalytics()) return;
        if (!mpanel) {
            log.error({ code: "web-220502-1549", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }

        try {
            const data = msg.payload.data.data;
            const eventName = msg.payload.name;

            if (msg.payload.name !== "LogOut") {
                const trackingId = store.getState().user.trackingId ?? null;
                if (trackingId == null) {
                    log.error({ code: "web-211003-1755", msg: "no trackingId" });
                    return;
                }

                if (trackingId != currentIdentifyId) {
                    currentIdentifyId = trackingId;
                    mpanel.identify(trackingId);
                }
            }

            const convert = (target: AnalyticsProp["target"], mode: AnalyticsProp["mode"]) => {
                const use = data.filter((item) => item[1].target == target && item[1].mode === mode);
                use.forEach((used) => data.splice(data.indexOf(used), 1));
                const arr = use.map((item) => [item[0], item[1].data]) as [string, AnalyticsProp["data"]][];
                const obj = Object.fromEntries(arr);

                return obj;
            };

            mpanel.register(convert("app", "set"));
            mpanel.register_once(convert("app", "first"));
            mpanel.people.set(convert("user", "set"));
            mpanel.people.set_once(convert("user", "first"));
            mpanel.people.increment(convert("user", "increment"));
            mpanel.track(analyticsKeys[eventName], convert("event", "set"));

            if (data.length !== 0) log.error({ code: "web-211004-1553", msg: "props not sent", data: { props: data } });
        } catch (e) {
            log.error({ code: "web-211029-1119", msg: "mp error", error: e });
        }
    });
};

function getKey(): string {
    switch (environment.runtimeEnvironment) {
        case WebAppEnvironment.Development:
            return mpanelConfigDevelopment.apiKey;
        case WebAppEnvironment.Production:
            return mpanelConfigProduction.apiKey;
    }
}

function getConfig(): Partial<Config> {
    return {
        cross_subdomain_cookie: false,
        disable_persistence: true,
        ignore_dnt: true,
        debug: true
    };
}
