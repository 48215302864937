import { castQueueSetRepeatMode } from "../castRequests/media";
import { convertToRepeatMode, convertToRepeatStatus } from "../helpers";
import { getCastMediaSession, getCastPlayer, hasCastMediaSession } from "../setup";
import { dispatch, store } from "global";
import { PLAYER_SET_MUTE, PLAYER_SET_VOLUME } from "global/actions";

export async function syncResumeSettingsFromRemote(): Promise<boolean> {
    syncVolumeFromRemote();
    syncIsMutedFromRemote();
    if (!hasCastMediaSession()) return true;

    const ok = syncRepeatFromLocal();
    return ok;
}

export function syncVolumeFromRemote() {
    const player = getCastPlayer();
    if (!player) return;

    const volumeRemote = player.volumeLevel;
    if (volumeRemote == null) return;

    const volumeLocal = store.getState().player.volume;
    if (volumeRemote === volumeLocal) return;

    dispatch({ type: PLAYER_SET_VOLUME, payload: { volume: volumeRemote } });
}

export function syncIsMutedFromRemote() {
    const player = getCastPlayer();
    if (!player) return;

    const mutedRemote = player.isMuted;
    if (mutedRemote == null) return;

    const mutedLocal = store.getState().player.muted;
    if (mutedRemote === mutedLocal) return;

    dispatch({ type: PLAYER_SET_MUTE, payload: { mute: mutedRemote } });
}

async function syncRepeatFromLocal(): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    const repeatRemote = convertToRepeatStatus(media.repeatMode);
    if (repeatRemote == null) return false;

    const repeatLocal = store.getState().player.repeat;
    if (repeatRemote === repeatLocal) return false;

    const repeatMode = convertToRepeatMode(repeatLocal);
    const ok = await castQueueSetRepeatMode(repeatMode);

    return ok;
}
