import { AudioContextAction } from "../../../models/app/player/AudioContext";
import { dispatch, messageBus } from "global";
import { AUDIO_INPUT_AUDIOS_CHANGE, AUDIO_OUTPUT_AUDIO_PLAY_CHANGE, PLAYER_NEXT, PLAYER_PREVIOUS_OR_REWIND, PLAYER_SET_PLAY } from "global/actions";
import { log } from "services/logger/initLoggerService";
import { getCoverSrcFromTemplate } from "services/resource";
import { ContentType } from "models/ModelType";
import type { CurrentAudioLoadedPrimaryPreview, CurrentAudioLoadedSecondaryPreview } from "components/shared/hooks";
import { getCurrentAudioPrimaryResourceFromState, getCurrentAudioSecondaryResourceFromState } from "components/shared/hooks";

export async function initMediaSessionService() {
    if (!("mediaSession" in navigator)) return;

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIOS_CHANGE, updateMediaSession);
    messageBus.subscribeEvery(AUDIO_OUTPUT_AUDIO_PLAY_CHANGE, updateMediaSession);
}

let currentPrimary: CurrentAudioLoadedPrimaryPreview | null = null;
let currentSecondary: CurrentAudioLoadedPrimaryPreview | null = null;

function updateMediaSession() {
    const mediaSession = navigator.mediaSession;

    if (!mediaSession) {
        log.warn({ code: "web-211105-1104", msg: "mediaSession is null" });
        return;
    }

    const primary = getCurrentAudioPrimaryResourceFromState().resource;
    const secondary = getCurrentAudioSecondaryResourceFromState().resource;

    if (primary?.id == currentPrimary?.id && secondary?.id === currentSecondary?.id) return;

    currentPrimary = primary;
    currentSecondary = secondary;

    const mediaInfo = getMediaInfo(primary, secondary);
    const artwork = getArtwork(primary);

    const metadata: MediaMetadataInit | undefined = mediaInfo != null ? { ...mediaInfo, artwork } : undefined;
    mediaSession.metadata = metadata ? new MediaMetadata(metadata) : null;

    const playFn = () => {
        if (secondary) dispatch({ type: PLAYER_SET_PLAY, payload: { play: true, context: { action: AudioContextAction.UserMediaControlsPlay, trace: null } } });
    };
    const pauseFn = () => {
        if (secondary) dispatch({ type: PLAYER_SET_PLAY, payload: { play: false, context: { action: AudioContextAction.UserMediaControlsPause, trace: null } } });
    };
    const previousFn = () => {
        if (secondary) dispatch({ type: PLAYER_PREVIOUS_OR_REWIND, payload: { context: { action: AudioContextAction.UserMediaControlsPrevious, trace: null } } });
    };
    const nextFn = () => {
        if (secondary) dispatch({ type: PLAYER_NEXT, payload: { context: { action: AudioContextAction.UserMediaControlsNext, trace: null } } });
    };

    mediaSession.setActionHandler("play", () => playFn());
    mediaSession.setActionHandler("pause", () => pauseFn());
    mediaSession.setActionHandler("previoustrack", () => previousFn());
    mediaSession.setActionHandler("nexttrack", () => nextFn());
}

function getArtwork(primary: CurrentAudioLoadedPrimaryPreview | null): MediaImage[] | undefined {
    const template = primary?.cover;
    if (template == null) return undefined;

    const images: MediaImage[] = [];

    const add = (size: number, forceAdd?: boolean) => {
        const { success, src } = getCoverSrcFromTemplate(template, size, size);
        if (!success && !forceAdd) return;
        images.push({ src, sizes: `${size}x${size}` });
    };

    add(64);
    add(128);
    add(256);
    add(512, true);
    add(1024);

    return images;
}

function getMediaInfo(
    primary: CurrentAudioLoadedPrimaryPreview | null,
    secondary: CurrentAudioLoadedSecondaryPreview | null
): { title: string; album?: string; artist?: string } | null {
    if (!primary) return null;

    switch (primary.contentType) {
        case ContentType.TrackPlayable: {
            return {
                title: primary.track.title,
                album: primary.track.album.title,
                artist: primary.track.artist.name
            };
        }
        case ContentType.MixRadio: {
            const track = secondary?.contentType === ContentType.TrackPlayable ? secondary.track : null;
            const title = track ? `${primary.title} - ${track.title}` : primary.title;
            return {
                title,
                album: track?.album.title ?? undefined,
                artist: track?.artist.name ?? undefined
            };
        }
        case ContentType.LiveRadioPlayable: {
            return { title: primary.liveRadio.title };
        }
    }
}
