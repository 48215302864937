import { h } from "preact";
import "./PlayerTrackControl.scss";
import { ResourceCardMaxiPlayer } from "../resource/ResourceCardMaxiPlayer";
import { TestLocator } from "global/constants";
import { useLoginState } from "services/user";
import { LoginState } from "models/app";
import { ContentType } from "models/ModelType";
import { useSectionContext, usePreviewContext, useCurrentAudioSecondaryResourceFromState } from "components/shared/hooks";
import { ButtonFavorite } from "components/atoms/controls/button/buttons/ButtonFavorite";
import { ButtonMore } from "components/atoms/controls/button/buttons/ButtonMore";

export const MaxiPlayerTrackControl = () => {
    const { resource, resourceType } = useCurrentAudioSecondaryResourceFromState();

    const sectionContext = useSectionContext(ContentType.Player, null, null, null, null);
    const context = usePreviewContext(resourceType, resource, sectionContext, null);
    const loginStateIsKnown = useLoginState() !== LoginState.Unknown;

    return (
        <div className="playerTrackControl rmB">
            <ResourceCardMaxiPlayer resource={resource} resourceType={resourceType} context={context} />
            {resource && resource.contentType !== ContentType.LiveRadioPlayable && (
                <ButtonFavorite favorite={resource} context={context} testLocator={TestLocator.PlayerFavoriteButton} disabled={!loginStateIsKnown} />
            )}
            {resource && <ButtonMore resource={resource} context={context} testLocator={TestLocator.PlayerMoreButton} />}
        </div>
    );
};
