import { h } from "preact";
import { Button, ButtonDesign } from "../Button";
import { dispatch } from "global";
import { USER_CLEAR_SEARCH_HISTORY } from "global/actions";
import { useTranslations } from "global/config";
import { TestLocator } from "global/constants";

export const ButtonClearSearchHistory = () => {
    const clear = () => {
        dispatch({ type: USER_CLEAR_SEARCH_HISTORY });
    };
    const translations = useTranslations();
    return (
        <Button className={`buttonClearSearchHistory`} design={ButtonDesign.LightMicro} onClick={clear} testLocator={TestLocator.SearchClearHistory}>
            {translations.ClearSearchHistory}
        </Button>
    );
};
