import { playNext, PlayNextSource } from "./playNext";
import { log } from "services/logger";
import { isPlayStatePlayIntent } from "services/player/helpers";
import { getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import { PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";

export function skipOnFail(audio: AudioInputItemModel, playStateBefore: PlayState) {
    if (audio.index !== 0) return;

    switch (audio.playState) {
        case PlayState.Error: {
            const playIntent = isPlayStatePlayIntent(playStateBefore);
            error(playIntent);
            break;
        }
        case PlayState.Playing:
            reset();
            break;
    }
}

let skipCount = 0;
let skipCountTotal = 0;

function reset() {
    skipCount = 0;
}

function error(playIntent: boolean) {
    if (skipCountTotal > 100) {
        log.error({ code: "web-220706-0950", msg: "total skip count > 100" });
        reset();
        return;
    }

    if (!playIntent || skipCount > 2 || skipCountTotal > 100) {
        reset();
        return;
    }

    skipCount++;
    skipCountTotal++;
    tryPlayNext();
}

async function tryPlayNext() {
    const current = getCurrentInputAudioItemFromState();
    log.warn({ code: "web-220627-1700", msg: "trying to skip to next song", data: { "currrent audio logTitle": current?.logTitle } });

    const context: AudioContextModel = { action: AudioContextAction.SkipOnFail, trace: null };
    playNext(true, PlayNextSource.SkipOnFail, context);
}
