import { h } from "preact";
import "./EmptyQueue.scss";
import { useTranslations } from "global/config";
import type { DraggableItemModel } from "models/app";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import { DropZone } from "components/molecules/dropZone";

interface Props {
    onDrop: (item: DraggableItemModel, lane: QueueLane, end: boolean, queueTrack?: QueueTrackModel) => Promise<void>;
}
export function EmptyQueue({ onDrop }: Props) {
    const translations = useTranslations();
    return (
        <div className="empty-queue wke">
            <div className="text">
                <p className="title">{translations.WebEmptyQueueTitle}</p>
                <p className="subtitle">{translations.WebEmptyQueueSubtext}</p>
            </div>
            <DropZone onDropFn={(item) => onDrop(item, QueueLane.Flow, true)} />
        </div>
    );
}
