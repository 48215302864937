import { useLayoutEffect } from "preact/hooks";
import { ScrollerAlign, ScrollerState, ScrollerSize } from "../../options";
import { getActiveItemScrollLeft } from "./getActiveItemScrollLeft";

export const useScrollTo = (
    container: HTMLElement | null,
    index: number,
    state: ScrollerState,
    jsSnap: boolean,
    align: ScrollerAlign,
    size: ScrollerSize | number,
    ajust: number,
    gap: number,
    onScroll: () => void
) => {
    useLayoutEffect(() => {
        if (!container) return;
        if (
            !(
                state === ScrollerState.ButtonClick ||
                state === ScrollerState.ComponentLoad ||
                (jsSnap && state === ScrollerState.UserScrollStop) ||
                state === ScrollerState.ExternalChange
            )
        )
            return;

        const behavior = (() => {
            switch (document.visibilityState) {
                case "visible":
                    return "smooth";
                case "hidden":
                    return "auto";
            }
        })();

        const left = getActiveItemScrollLeft(Math.round(index), size, align, ajust, gap);
        container.scroll({ left, behavior });

        onScroll();
    });
};
