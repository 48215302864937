import { useRef, useMemo, useState, useCallback, useLayoutEffect } from "preact/hooks";
import { scrollingTextGroup, ScrollingTextItem } from "services/text/scrollingTextService";
import { useResizeObserver } from "components/shared/hooks";

export const useScrollingText = () => {
    const scrollingGroup = scrollingTextGroup;

    const ref = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const size = useResizeObserver(ref.current)?.width ?? 0;
    const contentSize = useResizeObserver(contentRef.current)?.width ?? 0;

    const scroll = useMemo(() => contentSize > size, [contentSize, size]);

    const [duration1, setDuration1] = useState<number | null>(null);
    const [duration2, setDuration2] = useState<number | null>(null);

    const animationFn = useCallback(() => {
        const speed = 40; // px per second
        const duration1 = (contentSize + 100) / speed;
        const duration2 = (contentSize + 50) / speed;
        return { duration1, duration2 };
    }, [contentSize]);

    const start = useCallback(() => {
        const { duration1, duration2 } = animationFn();

        const running = new Date(new Date().getTime() + duration1 * 1000);
        setDuration1(duration1);
        setDuration2(duration2);

        return running;
    }, [animationFn]);

    const stop = useCallback(() => {
        setDuration1(null);
        setDuration2(null);
    }, []);

    const entry = useMemo<ScrollingTextItem | null>(() => {
        return scroll ? { start, stop, running: false, stopTimeoutId: null } : null;
    }, [scroll, start, stop]);

    useLayoutEffect(() => {
        if (!entry) return;
        scrollingGroup.add(entry);

        return () => {
            scrollingGroup.remove(entry);
        };
    }, [entry, stop, scrollingGroup]);

    return { ref, contentRef, scroll, running: duration1 != null, duration1, duration2 };
};
