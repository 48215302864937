import type { Action } from "../actions";
import { LIVERADIO_NOW_AND_RECENTLY_PLAYED_UPDATE, LIVERADIO_UPDATE, REHYDRATE } from "global/actions";
import { AudioInputType } from "models/app/player";
import type { LiveRadioAudioInputModel } from "models/app/player/input";
import type { LiveRadioTrackPreviewModel, ConnectionModel } from "models/domain";
import { DomainModelType } from "models/ModelType";

const initialState: LiveRadioAudioInputModel = {
    type: AudioInputType.LiveRadio,
    currentRadio: null,
    currentRadioContext: null,
    currentRadioAnalytics: null,
    version: 1
};

export function liveRadioReducer(state: LiveRadioAudioInputModel = initialState, action: Action): LiveRadioAudioInputModel {
    switch (action.type) {
        case LIVERADIO_NOW_AND_RECENTLY_PLAYED_UPDATE: {
            const { tracks } = action.payload;
            if (!state.currentRadio) return state;
            const stateCurrentRadio = { ...state.currentRadio };
            stateCurrentRadio.liveRadio = { ...stateCurrentRadio.liveRadio };
            if (tracks != null) {
                stateCurrentRadio.liveRadio.tracks = {
                    nowPlaying: tracks?.nowPlaying ?? null,
                    recentlyPlayedTracks: tracks?.recentlyPlayedTracks ?? null
                };

                return { ...state, currentRadio: stateCurrentRadio };
            }
            const currentLiveRadioTrack = stateCurrentRadio.liveRadio.tracks?.nowPlaying;
            const recentlyPlayedLiveRadioTracks = stateCurrentRadio.liveRadio.tracks?.recentlyPlayedTracks;
            if (currentLiveRadioTrack == null) return state;

            let recentlyPlayedTracks: ConnectionModel<LiveRadioTrackPreviewModel> | null = null;
            if (recentlyPlayedLiveRadioTracks != null) {
                const items = [currentLiveRadioTrack, ...recentlyPlayedLiveRadioTracks.items];
                recentlyPlayedTracks = { ...recentlyPlayedLiveRadioTracks, items };
            } else {
                recentlyPlayedTracks = {
                    totalCount: stateCurrentRadio.trackCount,
                    items: [currentLiveRadioTrack],
                    type: DomainModelType.Preview,
                    pageInfo: {
                        endCursor: null,
                        hasNextPage: false,
                        endCursorIndex: null
                    }
                };
            }
            stateCurrentRadio.liveRadio.tracks = {
                nowPlaying: null,
                recentlyPlayedTracks
            };
            return { ...state, currentRadio: stateCurrentRadio };
        }
        case LIVERADIO_UPDATE: {
            const { radio, radioContext, analytics } = action.payload;
            return { ...state, currentRadio: radio, currentRadioContext: radioContext, currentRadioAnalytics: analytics };
        }
        case REHYDRATE: {
            const liveRadio = action.payload?.liveRadio ?? null;
            if (liveRadio == null) return initialState;
            if (liveRadio.version === undefined) return initialState;
            return liveRadio;
        }
        default:
            return state;
    }
}
