import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { Control, ControlStyle } from "../controlGroup/Control";
import { ControlGroup } from "../controlGroup/ControlGroup";
import { dispatch } from "global";
import { SET_LOCALIZATION_SETTING } from "global/actions";
import { useTranslations } from "global/config";
import type { RootModel } from "models/app";
import { LanguageSetting } from "models/view/Localization";
import { CheckMark } from "components/atoms/controls/checkMark";

interface Props {
    className?: string;
}

export const LocalizationSettingControl = ({ className }: Props) => {
    const localizationSetting = useSelector((root: RootModel) => root.ui.localizationSetting);

    const change = useCallback((setting: LanguageSetting) => {
        dispatch({ type: SET_LOCALIZATION_SETTING, payload: { localizationSetting: setting } });
    }, []);

    const translations = useTranslations();
    const settingsLanguageDanish = translations.SettingsLanguageDanish;
    const settingsLanguageEnglish = translations.SettingsLanguageEnglish;
    const settingsLanguageSystem = translations.SettingsLanguageSystem;

    return (
        <ControlGroup className={`localizationSettingControl ${className ?? ""}`}>
            <Control
                title={settingsLanguageDanish}
                onClick={() => change(LanguageSetting.Danish)}
                left={
                    <CheckMark
                        type="radio"
                        checked={localizationSetting === LanguageSetting.Danish}
                        onChange={() => change(LanguageSetting.Danish)}
                        name={settingsLanguageDanish}
                    />
                }
                style={ControlStyle.ContextMenu}
            />
            <Control
                title={settingsLanguageEnglish}
                onClick={() => change(LanguageSetting.English)}
                left={
                    <CheckMark
                        type="radio"
                        checked={localizationSetting === LanguageSetting.English}
                        onChange={() => change(LanguageSetting.English)}
                        name={settingsLanguageEnglish}
                    />
                }
                style={ControlStyle.ContextMenu}
            />
            <Control
                title={settingsLanguageSystem}
                onClick={() => change(LanguageSetting.Auto)}
                text={translations.SettingsLanguageSystemDescription}
                left={<CheckMark type="radio" checked={localizationSetting === LanguageSetting.Auto} onChange={() => change(LanguageSetting.Auto)} name={settingsLanguageSystem} />}
                style={ControlStyle.ContextMenu}
            />
        </ControlGroup>
    );
};
