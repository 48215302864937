import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { changeUserProfile, useDisplayLoggedIn } from "services/user";
import { UserProfileModel } from "models/app";
import { Avatar } from "components/atoms/controls/avatar";
import "./ProfileLink.scss";

interface Props {
    profile: UserProfileModel;
}

export const ProfileLink = ({ profile }: Props) => {
    const loggedIn = useDisplayLoggedIn();
    const translations = useTranslations();

    const onSwitchProfile = useCallback(async () => {
        // todo: dispatch event?
        if (!profile.current) {
            const ok = await changeUserProfile(profile.id)
        }
    }, []);

    if (!loggedIn) return null;

    return (
        <Link closeOnClick className="profilelink" onClick={onSwitchProfile} tooltip={translations.NavBarLogin} testLocator={TestLocator.AccountMenuProfileButton} disabled={profile.current}>
            <span>
                <Avatar text={profile.title} color={profile.color} />
                {profile.title}
            </span>
        </Link>
    );
};
