import type { ResourceModelInterface } from ".";
import type { NotificationPreviewModel } from "./NotificationModel";
import { getNextUUID } from "services/utils";
import { ContentType, DomainModelType } from "models/ModelType";

interface NotificationFeedPage {
    notifications: NotificationPreviewModel[];
}
export interface NotificationFeedPageModel extends ResourceModelInterface, NotificationFeedPage {
    type: DomainModelType.Page;
    contentType: ContentType.NotificationFeed;
}

//TODO: remove?? this isn't used anywehre
export function createNotificationFeedPageModel(data: NotificationFeedPage): NotificationFeedPageModel {
    return {
        type: DomainModelType.Page,
        contentType: ContentType.NotificationFeed,
        id: getNextUUID(),
        graphType: undefined,
        cover: null,
        notifications: data.notifications
    };
}
