import { useState, useEffect, useMemo } from "preact/hooks";
import { log } from "services/logger/initLoggerService";

export enum MediaSize {
    Mobile = "mobile",
    Tablet = "tablet",
    Desktop = "desktop"
}

export const breakpointMobileTablet = 760; //old 600;
export const breakpointTabletDesktop = 1024;

const mobileQuery = `(max-width: ${breakpointMobileTablet}px)`;
const tabletQuery = `(min-width: ${breakpointMobileTablet}px) and (max-width: ${breakpointTabletDesktop}px)`;
const desktopQuery = `(min-width: ${breakpointTabletDesktop}px)`;

export const useAppMediaSize = (): MediaSize => {
    const mobile = useMemo(() => window.matchMedia(mobileQuery), []);
    const tablet = useMemo(() => window.matchMedia(tabletQuery), []);
    const desktop = useMemo(() => window.matchMedia(desktopQuery), []);

    const [size, setSize] = useState(getAppMediaSize());

    useEffect(() => {
        const onMobile = () => setSize(getAppMediaSize());
        const onTablet = () => setSize(getAppMediaSize());
        const onDesktop = () => setSize(getAppMediaSize());

        mobile.addEventListener("change", onMobile, { passive: true });
        tablet.addEventListener("change", onTablet, { passive: true });
        desktop.addEventListener("change", onDesktop, { passive: true });

        return () => {
            mobile.removeEventListener("change", onMobile);
            tablet.removeEventListener("change", onTablet);
            desktop.removeEventListener("change", onDesktop);
        };
    }, [mobile, tablet, desktop]);
    return size;
};

export const getAppMediaSize = (): MediaSize => {
    const mobile = window.matchMedia(mobileQuery);
    const tablet = window.matchMedia(tabletQuery);
    const desktop = window.matchMedia(desktopQuery);

    const getSize = () => {
        if (mobile.matches) return MediaSize.Mobile;
        else if (tablet.matches) return MediaSize.Tablet;
        else if (desktop.matches) return MediaSize.Desktop;

        log.error({ code: "web-210519-1219", msg: "no media match" });
        return MediaSize.Mobile;
    };
    return getSize();
};
