import { useEffect, useState } from "preact/hooks";
import { getTrackLyrics, getTrackLyricsPagination } from "services/backend";
import type { SynchronizationType, TrackLyricsPageQuery } from "generated/graphql-types";

export type LyricLine = {
    fromMs?: number;
    toMs?: number;
    text: string;
}

export type LyricsResult = {
    lyricsId: string | null;
    lines: LyricLine[] | null;
    loading: boolean;
    author: string | null;
    publisher: string | null;
    provider: string | null;
    synchronizationType: SynchronizationType | null;
}

type GqlLyricLine = NonNullable<NonNullable<NonNullable<TrackLyricsPageQuery["catalog"]["track"]>["lyrics"]>["lrc"]["items"]>[0];
function mapLyric(line: GqlLyricLine) {
    return ({
        fromMs: (line.startInMs ?? 0) / 10,
        toMs: ((line.startInMs ?? 0) + (line.durationInMs ?? 0)) / 10,
        text: line.line
    });
}

export function useLyrics(id: string | undefined): LyricsResult {
    const [internalState] = useState({
        id: undefined as string | undefined
    });
    const [result, setResult] = useState<LyricsResult>({
        lyricsId: null,
        loading: false,
        lines: [],
        author: null,
        publisher: null,
        provider: null,
        synchronizationType: null
    })

    useEffect(() => {
        internalState.id = id;
        setResult({
            lyricsId: id ?? null,
            loading: true,
            lines: null,
            author: null,
            publisher: null,
            provider: null,
            synchronizationType: null
        })
        if (!id) return;

        // load the first few lines to get a quick result
        getTrackLyrics({ id, first: 100 })
            .then(result => {
                if (internalState.id === id && result.success && result.model) {
                    const lines = result.model?.lrc.items?.map(mapLyric) ?? null;
                    const firstResult = {
                        lyricsId: result.model.id,
                        loading: result.loading || !lines,
                        lines,
                        author: result.model.credits?.writer ?? null,
                        publisher: result.model.credits?.publisher ?? null,
                        provider: result.model.credits?.provider ?? null,
                        synchronizationType: result.model.synchronizationType ?? null
                    };
                    setResult(firstResult)

                    // then load and appent any remaining lines
                    if (result.model?.lrc.pageInfo.hasNextPage && result.model.lrc.pageInfo.endCursor) {
                        getTrackLyricsPagination({ id, first: 1000, after: result.model.lrc.pageInfo.endCursor })
                            .then(result => {
                                if (internalState.id === id && result.success) {
                                    const lines = result.model?.lrc.items?.map(mapLyric) ?? null;
                                    if (lines && lines.length > 0) {
                                        setResult({
                                            ...firstResult,
                                            lines: [...(firstResult.lines ?? []), ...lines]
                                        })
                                    }
                                }
                            });
                    }
                }
            });

    }, [id, internalState])

    return result
}

