import { currentTimeChanged, isMediaLoadedChanged, onIsConnectedChanged, onPlayerStateChanged, onVolumeLevelChanged } from "../castEvents/playerController";
import { onIsMutedChanged } from "../castEvents/playerController/isMutedChanged";
import { log } from "services/logger";

let player: cast.framework.RemotePlayer | null = null;
let playerController: cast.framework.RemotePlayerController | null = null;

export function getCastPlayer() {
    if (!player) log.error({ code: "web-230106-1622", msg: "cc player is null" });
    return player;
}

export function getCastPlayerController() {
    if (!playerController) log.error({ code: "web-230106-1622", msg: "cc playerController is null" });
    return playerController;
}

export function addPlayerControllerListeners() {
    removePlayerControllerListeners();

    player = new cast.framework.RemotePlayer();
    playerController = new cast.framework.RemotePlayerController(player);

    // log.debug([LogTag.Chromecast], () => ({ code: "web-210714-1149", msg: `add player controlller listeners` }));

    // playerController.addEventListener(window.cast.framework.RemotePlayerEventType.ANY_CHANGE, onAnyChange);
    playerController.addEventListener(window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, currentTimeChanged);
    playerController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, onIsConnectedChanged);
    playerController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED, isMediaLoadedChanged);
    playerController.addEventListener(window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, onIsMutedChanged);
    playerController.addEventListener(window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, onPlayerStateChanged);
    playerController.addEventListener(window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, onVolumeLevelChanged);
}

export function removePlayerControllerListeners() {
    if (!playerController) return;

    // log.debug([LogTag.Chromecast], () => ({ code: "web-230106-1336", msg: `remove player controlller listeners` }));

    // playerController.removeEventListener(window.cast.framework.RemotePlayerEventType.ANY_CHANGE, onAnyChange);
    playerController.removeEventListener(window.cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED, currentTimeChanged);
    playerController.removeEventListener(window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED, onIsConnectedChanged);
    playerController.removeEventListener(window.cast.framework.RemotePlayerEventType.IS_MEDIA_LOADED_CHANGED, isMediaLoadedChanged);
    playerController.removeEventListener(window.cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED, onIsMutedChanged);
    playerController.removeEventListener(window.cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED, onPlayerStateChanged);
    playerController.removeEventListener(window.cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED, onVolumeLevelChanged);

    player = null;
    playerController = null;
}
