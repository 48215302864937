import type { PlayPausePositionModel } from "models/app/player";
import { AudioInputType , PlayState } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";

export function tryGetAudioPosition(audio: AudioInputItemModel): PlayPausePositionModel | null {
    switch (audio.input) {
        case AudioInputType.LiveRadio:
            return null;
        case AudioInputType.PlayQueue:
            return audio.position;
    }
}

export const getAudioItemPositionSeconds = (playState: PlayState, position: PlayPausePositionModel | null) => {
    if (position == null) return 0;
    switch (playState) {
        case PlayState.Stopped:
        case PlayState.Buffering:
        case PlayState.Error:
        case PlayState.Paused: {
            if (position.pausePosition == null) return 0;
            return position.pausePosition;
        }
        case PlayState.Playing: {
            if (position.playStartMs == null) return 0;
            const now = new Date().getTime();
            return (now - position.playStartMs) / 1000;
        }
    }
};

export const getAudioItemPositionPercent = (playState: PlayState, position: PlayPausePositionModel, duration: number) => {
    if (duration === 0) return 0;
    switch (playState) {
        case PlayState.Stopped:
        case PlayState.Buffering:
        case PlayState.Error:
        case PlayState.Paused: {
            if (position.pausePosition == null) return 0;
            return (position.pausePosition / duration) * 100;
        }
        case PlayState.Playing: {
            if (position.playStartMs == null) return 0;
            const now = new Date().getTime();
            return ((now - position.playStartMs) / 1000 / duration) * 100;
        }
    }
};

export const getAudioItemPositionDelay = (playState: PlayState, position: PlayPausePositionModel, duration: number) => {
    if (duration === 0) return 0;
    switch (playState) {
        case PlayState.Stopped:
        case PlayState.Buffering:
        case PlayState.Error:
        case PlayState.Paused: {
            if (position.pausePosition == null) return 0;
            return position.pausePosition * -1;
        }
        case PlayState.Playing: {
            if (position.playStartMs == null) return 0;
            const now = new Date().getTime();
            return (position.playStartMs - now) / 1000;
        }
    }
};
