import { useEffect, useState } from "preact/hooks";

export enum BreakPoint {
    MobileTablet = 760,
    TabletDesktop = 1024
}
interface Props {
    minWidth?: BreakPoint | number;
    maxWidth: BreakPoint | number;
}
interface Props2 {
    minWidth: BreakPoint | number;
    maxWidth?: BreakPoint | number;
}
export const useMatchMedia = ({ minWidth, maxWidth }: Props | Props2): boolean => {
    let matchMedia: string;
    if (minWidth && maxWidth) {
        matchMedia = `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
    } else if (minWidth) {
        matchMedia = `(min-width: ${minWidth}px)`;
    } else if (maxWidth) {
        matchMedia = `(max-width: ${maxWidth}px)`;
    } else throw Error("you need to select a minimum or a maximum screen width");
    const [media] = useState(window.matchMedia(matchMedia));

    const [size, setSize] = useState(media.matches);

    useEffect(() => {
        const onMediaChange = (ev: MediaQueryListEvent) => setSize(ev.matches);
        media.addEventListener("change", onMediaChange, { passive: true });

        return () => {
            media.removeEventListener("change", onMediaChange);
        };
    }, [media]);
    return size;
};
