import type { Action } from "../actions";
import {
    NOTIFICATION_FEED_SET_NOTIFICATIONS,
    NOTIFICATION_FEED_NOTIFICATIONS_SEEN,
    REHYDRATE,
    USER_LOGGED_IN
} from "../actions";
import type { AppNotificationFeedModel } from "models/app/AppNotificationFeedModel";

const initialState: AppNotificationFeedModel = {
    notSeenCount: undefined,
    notifications: [],
    notificationState: undefined,
    version: 1
};

export function notificationFeedReducer(state: AppNotificationFeedModel = initialState, action: Action): AppNotificationFeedModel {
    switch (action.type) {
        case USER_LOGGED_IN: {
            return action.payload.isNewUser
                ? { ...state, notSeenCount: undefined }
                : state;
        }
        case NOTIFICATION_FEED_SET_NOTIFICATIONS: {
            const { notSeenCount, notificationState, notifications } = action.payload;
            return {
                ...state,
                notSeenCount,
                notificationState: notificationState ?? state.notificationState,
                notifications: notifications ?? state.notifications
            };
        }
        case NOTIFICATION_FEED_NOTIFICATIONS_SEEN: {
            return { ...state, notSeenCount: 0 };
        }
        // case NOTIFICATION_FEED_NOTIFICATION_CONVERTED: {
        //     const { feed, converted } = action.payload;

        //     const feeds = tryUpdateArray(
        //         state.feeds,
        //         (n) => n.userId === feed.userId,
        //         (n) => markNotificationsConverted(n, converted),
        //         "web-220504-2231"
        //     );

        //     return { ...state, feeds };
        // }

        case REHYDRATE: {
            let newState = action.payload?.notificationFeed;
            if (!newState) return initialState;

            newState = updateFeedVersion(newState);
            return newState;
        }
        default:
            return state;
    }
}

function updateFeedVersion(appFeed: AppNotificationFeedModel): AppNotificationFeedModel {
    return appFeed.version !== undefined ? appFeed : initialState;
}
