import { h } from "preact";
import { useState, useCallback, useRef } from "preact/hooks";
import { dismissModal, Modal, modalButtonDismissCancel, modalButtonEditPlaylistSave, openModal } from "..";
import { dispatch } from "global";
import { PLAYLIST_MODIFIED } from "global/actions";
import { useTranslations } from "global/config";
import { catalog } from "global/constants/catalog";
import { mutateModifyPlaylist } from "services/backend";
import type { PlaylistPageModel, PlaylistPreviewModel } from "models/domain";
import { FormState } from "components/atoms/controls/form";
import { TextInput, TextInputState } from "components/atoms/controls/textInput";
import { openToast } from "components/organisms/toast";
import { PlaylistNotSavedToast, PlaylistSavedToast } from "components/organisms/toast/toasts";

export async function showRenamePlaylistModal(playlist: PlaylistPreviewModel | PlaylistPageModel) {
    return openModal(<RenamePlaylistModal playlist={playlist} />);
}

interface Props {
    playlist: PlaylistPreviewModel | PlaylistPageModel;
}

export function RenamePlaylistModal(props: Props) {
    const originalTitle = props.playlist.title;
    const translations = useTranslations();
    const ref = useRef<HTMLInputElement>(null);
    const [name, setName] = useState(originalTitle);
    const [formState, setFormState] = useState(FormState.Default);
    const [errorMessage, setErrorMessage] = useState("");

    const doRenamePlaylist = useCallback(async () => {
        if (name.trim().length === 0) {
            setErrorMessage(translations.CreatePlaylistAlertText);
            ref.current?.focus();
            return false;
        }

        if (name === originalTitle) {
            return true;
        }

        setFormState(FormState.Submiting);
        setErrorMessage("");
        try {
            const id = await mutateModifyPlaylist({
                id: props.playlist.id,
                input: {
                    title: name
                }
            });

            if (id) {
                dispatch({
                    type: PLAYLIST_MODIFIED,
                    payload: {
                        playlistId: id,
                        playlist: {
                            title: name
                        }
                    }
                });
                openToast(PlaylistSavedToast());
                return true;
            }
        } catch {
            /**/
        }

        setFormState(FormState.Default);
        openToast(PlaylistNotSavedToast());
        return false;
    }, [name, props.playlist, originalTitle, translations.CreatePlaylistAlertText]);

    const onKeyDown = useCallback(
        async (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                if (await doRenamePlaylist()) {
                    dismissModal("RenamePlaylistModal.onKeyDown");
                }
            }
        },
        [doRenamePlaylist]
    );

    const onChange = useCallback((e: string) => {
        setName(e);
        if (e.trim()) {
            setErrorMessage("");
        }
    }, []);

    return (
        <Modal
            formState={formState}
            title={translations.MyMusicRenamePlaylistTitle}
            text={translations.MyMusicRenamePlaylistSubtitle}
            buttons={[modalButtonDismissCancel(), modalButtonEditPlaylistSave(doRenamePlaylist)]}>
            <p onKeyDown={onKeyDown}>
                <TextInput
                    type="text"
                    maxLength={catalog.playlistTitleMaxLength}
                    ref={ref}
                    value={name}
                    placeholder={translations.MyMusicRenamePlaylistHint}
                    autoComplete="off"
                    state={errorMessage ? TextInputState.Error : TextInputState.Default}
                    message={errorMessage}
                    onChange={onChange}
                    autoFocus
                    onFocus={() => {
                        /**/
                    }}
                />
            </p>
        </Modal>
    );
}
