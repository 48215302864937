import { h } from "preact";
import { LayoutPageItem } from "./LayoutPageItem";
import { getComparableId } from "services/resource";
import type { PageContextModel } from "models/app/resourceContextModel";
import { ResourceDisplayType } from "models/domain";
import type { LayoutItemGroupModel } from "models/domain/layoutPage";

interface GroupProps {
    group: LayoutItemGroupModel;
    context: PageContextModel;
}

export const LayoutPageGroup = ({ group, context }: GroupProps) => (
    <section className="layoutPageGroup jLY">
        <div className="items">
            {group.items?.items.filter(n => n.displayType !== ResourceDisplayType.Unknown).map((item, index) => (
                <LayoutPageItem key={getComparableId(item)} item={item} page={context} rank={index} />
            ))}
        </div>
    </section>
);
