import { useMemo, useState, useCallback } from "preact/hooks";
import { filterItem } from "./filterItem";
import { search } from "global/constants";
import { useDebouncedState } from "services/cancellation/debounce";
import type { ResourcePreviewModel } from "models/domain";
import type { Pagination } from "components/shared/hooks/usePagination";

export function useFilteredItems(items: ResourcePreviewModel[] | null, filter: string) {
    filter = filter.toLocaleLowerCase().trim();
    return useMemo(() => {
        if (!items || !filter) return items;
        //console.time(`filtering benchmark for ${items.length} items`);
        const filteredItems = items.filter((item) => filterItem(item, filter));
        //console.timeEnd(`filtering benchmark for ${items.length} items`);
        return filteredItems;
    }, [items, filter]);
}

export function useFilter<T>(items: ResourcePreviewModel[] | null, pagination?: Pagination<T>) {
    const [filterIsExpanded, setFilterIsExpanded] = useState(false);
    const [hasLoadedOnce, setHasLoadedOnce] = useState(!!items);
    if (items) {
        setHasLoadedOnce(true);
    }
    const [filter, setFilterDebounced, setFilterInstant] = useDebouncedState("", search.filterDebounceMs);
    const setFilter = useCallback((value: string) => (value ? setFilterDebounced(value) : setFilterInstant("")), [setFilterDebounced, setFilterInstant]);
    const filteredItems = useFilteredItems(items, filter);

    !!filter && pagination?.more && pagination.more(10000);

    return { filteredItems, filter, setFilter, filterIsExpanded, setFilterIsExpanded, hasLoadedOnce };
}
