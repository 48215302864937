import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { Link } from "../Link";
import { dispatch } from "global";
import { PLAYLIST_MODIFIED } from "global/actions";
import { useTranslations } from "global/config";
import { mutateModifyPlaylist } from "services/backend";
import { PlaylistVisibility } from "generated/graphql-types";
import type { PlaylistPageModel, PlaylistPreviewModel } from "models/domain";
import { isPlaylistPublic } from "models/domain";
import { IconName } from "components/atoms/icon";
import { openToast } from "components/organisms/toast";
import { PlaylistErrorStateChangeToast, PlaylistMadePrivateToast, PlaylistMadePublicToast } from "components/organisms/toast/toasts";

interface Props {
    playable: PlaylistPreviewModel | PlaylistPageModel | null;
    showIcon: boolean;
}

export const PlaylistVisibilityLink = ({ showIcon, playable }: Props) => {
    const translations = useTranslations();
    const isPublic = isPlaylistPublic(playable);

    const icon = useMemo(() => {
        if (!showIcon) return undefined;
        if (isPublic) return IconName.Private;
        return IconName.Public;
    }, [isPublic, showIcon]);

    const doToggleVisibility = useCallback(async () => {
        if (!playable) return;
        try {
            const id = await mutateModifyPlaylist({
                id: playable.id,
                input: {
                    visibility: isPublic ? PlaylistVisibility.Private : PlaylistVisibility.Public
                }
            });

            if (id) {
                dispatch({
                    type: PLAYLIST_MODIFIED,
                    payload: {
                        playlistId: id,
                        playlist: {
                            visibility: isPublic ? PlaylistVisibility.Private : PlaylistVisibility.Public
                        }
                    }
                });
                openToast(isPublic ? PlaylistMadePrivateToast() : PlaylistMadePublicToast());
                return true;
            }
        } catch {
            /**/
        }

        openToast(PlaylistErrorStateChangeToast());
        return false;
    }, [playable, isPublic]);

    if (!playable) return <Link>{""}</Link>;
    if (!playable.owner) return null;

    return (
        <Link icon={icon} closeOnClick onClick={doToggleVisibility}>
            {isPublic ? translations.ContextOptionMakePrivate : translations.ContextOptionMakePlaylistPublic}
        </Link>
    );
};
