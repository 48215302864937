import { h } from "preact";
import { Sheet } from "..";
import { FavoritesSortingLink } from "../links";
import { SortingMenuTop } from "./tops";
import { useTranslations } from "global/config";
import { FavoriteArtistsSorting } from "models/domain";

export const MyMusicArtistsSortingContextMenu = () => {
    const translations = useTranslations();
    return (
        <Sheet top={<SortingMenuTop />}>
            <FavoritesSortingLink title={translations.SortByLastAdded} type="artistsSorting" sorting={FavoriteArtistsSorting.LatestAdded} />
            <FavoritesSortingLink title={translations.SortAlphabetically} type="artistsSorting" sorting={FavoriteArtistsSorting.Alphabetically} />
        </Sheet>
    );
};
