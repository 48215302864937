import type { StateUpdater } from "preact/hooks";
import { useEffect, useState } from "preact/hooks";
import { Feature, isFeatureEnabled, translate } from "global/config";
import { distinct } from "services/arrayHelper";
import type { PageContextModel } from "models/app/resourceContextModel";
import type { ResourceModel, TrackPreviewModel } from "models/domain";
import { createMultiselectionTracksPlayModel } from "models/domain/MultiselectionTracksModel";
import type { ContentType } from "models/ModelType";

export type MultiselectItem = TrackPreviewModel | ResourceModel;

export type MultiselectState = {
    context: PageContextModel | null;
    productType: ContentType;
    selectedItems: MultiselectItem[];
    activeItem: MultiselectItem | null;
    playables: MultiselectItem[] | null;
};

export type Multiselect = {
    state: MultiselectState;
    update: StateUpdater<MultiselectState>;
}

export function useMultiselect(playables: MultiselectItem[] | null, productType: ContentType, context: PageContextModel | null = null): Multiselect | undefined {
    const [state, update] = useState<MultiselectState>({
        selectedItems: [],
        activeItem: null,
        playables,
        productType,
        context
    });
    useEffect(() => {
        let missing = false;
        if (state.selectedItems.length > 0) {
            if (!playables || !state.playables) {
                // clear the selection if the collection was emptied
                missing = true;
            }
            else {
                // verify that all the items are still there, otherwise clear the selection
                for (let i = 0; i < state.selectedItems.length && !missing; i++) {
                    const item = state.selectedItems[i];
                    const index = _hackNormalizedIndex(state.playables, item);
                    missing = index === -1 || playables.length <= index || playables[index].id !== item.id;
                }
            }
        }
        const selectedItems = missing ? [] : state.selectedItems;
        const activeItem = missing ? null : state.activeItem;

        if (state.selectedItems !== selectedItems || state.activeItem !== activeItem || state.playables !== playables || state.productType !== productType || state.context !== context) {
            // slightly unintuitive update-logic in order to only update the selection if it changed relative to the version in the closure
            // (to handle the multiple sequential updates when a collection loads multiple pages during pageload)
            // this could also have been handled by having different useState() for each field in MultiselectState.
            update(prevState => ({
                selectedItems: selectedItems === state.selectedItems ? prevState.selectedItems : selectedItems,
                activeItem: activeItem === state.activeItem ? prevState.activeItem : activeItem,
                playables,
                productType,
                context
            }));
        }
    }, [state, playables, productType, context])
    return isFeatureEnabled(Feature.Multiselect) ? { state, update } : undefined;
}

export function isSelected(multiselect: Multiselect | undefined, item: MultiselectItem) {
    // item = _hackNormalizeItem(multiselect, item);
    // return !multiselect ? false : -1 !== multiselect.state.selectedItems.indexOf(item);

    return !multiselect ? false : -1 !== _hackNormalizedIndex(multiselect.state.selectedItems, item);
}

function toggleSelection(multiselect: Multiselect, item: MultiselectItem) {
    const selectedItems = isSelected(multiselect, item)
        ? multiselect.state.selectedItems.filter(n => n != item)
        : [...multiselect.state.selectedItems, item];

    setTimeout(() => {
        multiselect.update({
            selectedItems,
            activeItem: item,
            playables: multiselect.state.playables,
            productType: multiselect.state.productType,
            context: multiselect.state.context
        });
    });
}

function setSelection(multiselect: Multiselect, item: MultiselectItem | null) {
    multiselect.update({
        selectedItems: item ? [item] : [],
        activeItem: item,
        playables: multiselect.state.playables,
        productType: multiselect.state.productType,
        context: multiselect.state.context
    });
}

export function clearSelection(multiselect: Multiselect) {
    setSelection(multiselect, null);
}

export function unSelect(multiselect: Multiselect, items: MultiselectItem[]) {
    const selectedItems = multiselect.state.selectedItems.filter(n => -1 === items.indexOf(n)); // todo
    const activeItem = (multiselect.state.activeItem == null || -1 == selectedItems.indexOf(multiselect.state.activeItem)) ? null : multiselect.state.activeItem;
    multiselect.update({
        selectedItems,
        activeItem,
        playables: multiselect.state.playables,
        productType: multiselect.state.productType,
        context: multiselect.state.context
    });
}

function selectRange(multiselect: Multiselect, fromItem: MultiselectItem | null, toItem: MultiselectItem, append = false) {
    if (!multiselect.state.playables) return;

    const fromIndex = fromItem ? _hackNormalizedIndex(multiselect.state.playables, fromItem) : -1;
    const toIndex = _hackNormalizedIndex(multiselect.state.playables, toItem);

    if (fromIndex !== -1 && toIndex !== -1) {
        const rangeItems = multiselect.state.playables.slice(fromIndex <= toIndex ? fromIndex : toIndex, (fromIndex <= toIndex ? toIndex : fromIndex) + 1);
        const selectedItems = append ? distinct([...multiselect.state.selectedItems, ...rangeItems]) : rangeItems;
        multiselect.update({
            selectedItems,
            activeItem: multiselect.state.activeItem,
            playables: multiselect.state.playables,
            productType: multiselect.state.productType,
            context: multiselect.state.context
        });
    } else {
        // todo: log warning
    }
}

export function updateSelection(multiselect: Multiselect, item: MultiselectItem, ctrlKey: boolean, shiftKey: boolean) {
    item = _hackNormalizeItem(item);
    if (shiftKey && multiselect.state.activeItem) {
        selectRange(multiselect, multiselect.state.activeItem, item, ctrlKey);
    } else if (ctrlKey) {
        toggleSelection(multiselect, item);
    }
    else {
        setSelection(multiselect, item);
    }
}

export function getMultiselectPlayModel(multiselect: Multiselect) {
    const tracks = multiselect.state.selectedItems.map(item => _hackNormalizeItem(item));
    const title = translate("Tracks"); // todo: get from multiselect.state.context
    const playable = createMultiselectionTracksPlayModel(tracks, title);
    return playable;
}

function _hackNormalizedIndex(collection: MultiselectItem[], item: MultiselectItem) {
    const _item = _hackNormalizeItem(item);
    return collection.findIndex(n => _hackNormalizeItem(n) == _item);
}

function _hackNormalizeItem(item: MultiselectItem): TrackPreviewModel {
    // todo: favoritetracks has a track collection but calls with individual items that are TrackPlayable... revisit this during/after the type refactoring.
    // that should be resolved and then this method can go away.  
    return (item.contentType === "TrackPlayable")
        ? item.track
        : item as TrackPreviewModel;
}