import type { AppVersionModel } from "./appSettings";

export enum PostMessageName {
    SwVersion = "SwVersion"
}

export interface SwVersion {
    type: PostMessageName.SwVersion;
    version: AppVersionModel;
    reloadDelayMs: number;
}

export type PostMessage = SwVersion;
