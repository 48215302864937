import type { LocationContentType, ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import type { MyMusicPageContentType } from "components/templates/myMusicPage";

export enum NavigationPageRootName {
    Deeplink = "Deeplink",
    FrontPage = "FrontPage",
    MyMusicTracks = "MyMusicTracks",
    MyMusicArtists = "MyMusicArtists",
    MyMusicPlaylists = "MyMusicPlaylists",
    MyMusicAlbums = "MyMusicAlbums",
    Radio = "Radio",
    Search = "Search",
    Explore = "Explore",
    NotificationFeed = "NotificationFeed",
    EndlessPlay = "EndlessPlay"
}
export const getMyMusicRoot = (type: MyMusicPageContentType): NavigationPageRootName => {
    switch (type) {
        case ContentType.MyMusicAlbums:
            return NavigationPageRootName.MyMusicAlbums;
        case ContentType.MyMusicArtists:
            return NavigationPageRootName.MyMusicArtists;
        case ContentType.MyMusicPlaylists:
            return NavigationPageRootName.MyMusicPlaylists;
        case ContentType.MyMusicTracks:
            return NavigationPageRootName.MyMusicTracks;
    }
};
export interface NavigationModel {
    page: NavigationPageModel | null;
    scrollStates?: Record<string, ScrollStateModel | null>;
    from: {
        link: NavigationLinkModel;
        preview: NavigationPreviewModel;
        section: NavigationSectionModel;
        page?: NavigationPageModel;
    } | null;
}

export interface ScrollStateModel {
    x: number | null;
    y: number | null;
}

interface NavigationBaseModel {
    id?: string | null;
    name: string | null;
    type?: LocationContentType;
}

export interface NavigationPageModel extends NavigationBaseModel {
    sections: NavigationSectionModel[];
    root: NavigationPageRootName | null;
    windowTitle: string | null;
}

export interface NavigationSectionModel extends NavigationBaseModel {
    rank: number | null;
    visibleRank: number | null;
    displayType: ResourceDisplayType | null;
    isHidden: boolean;
}

export interface NavigationPreviewModel extends NavigationBaseModel {
    rank: number | null;
}

export type NavigationLinkModel = NavigationBaseModel;
