import type { AnalyticsData } from "../../analyticsData";
import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import { getCurrentNotifications, getNotificationsCounts } from "services/notificationFeed";
import type { NotificationModel } from "models/domain/NotificationModel";

export interface AnalyticsFeedCurrentNotifications {
    current: number;
    currentSeen: number;
    currentConverted: number;
}

export function getAnalyticsCurrentNotifications(): AnalyticsFeedCurrentNotifications {
    const current = getNotificationsCounts(getCurrentNotifications().currentNotifications);

    return {
        current: current.total,
        currentSeen: current.seen,
        currentConverted: current.converted
    };
}

function addCurrentNotificationsProperties(data: AnalyticsData, current: AnalyticsFeedCurrentNotifications) {
    SetAnalyticsProp(data, "event", "Current", current.current);
    SetAnalyticsProp(data, "event", "CurrentSeen", current.currentSeen);
    SetAnalyticsProp(data, "event", "CurrentConverted", current.currentConverted);

    return data;
}

export function getAnalyticsNotificationsAdded(added: NotificationModel[], current: AnalyticsFeedCurrentNotifications) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "Added", added.length);
    addCurrentNotificationsProperties(data, current);

    return data;
}

export function getAnalyticsNotificationsSeen(seen: NotificationModel[], current: AnalyticsFeedCurrentNotifications) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "Seen", seen.length);
    addCurrentNotificationsProperties(data, current);

    return data;
}
