import type { AlbumPreviewModel } from "./AlbumModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import type { ArtistAppearsOnAlbumsSectionFragment, ArtistAppearsOnAlbumsPageFragment } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type ArtistAlbumsAppearsOnSectionData = ArtistAppearsOnAlbumsSectionFragment;
type ArtistAlbumsAppearsOnPageData = ArtistAppearsOnAlbumsPageFragment;

export type ArtistAlbumsAppearsOnModel = ArtistAlbumsAppearsOnSectionModel;
export type ArtistAlbumsAppearsOnSectionModel = ({ type: DomainModelType.Section } & ArtistAlbumsAppearsOnSection) | ArtistAlbumsAppearsOnPageModel;
export type ArtistAlbumsAppearsOnPageModel = { type: DomainModelType.Page } & ArtistAlbumsAppearsOnPage;

interface ArtistAlbumsAppearsOnSection extends ResourceModelInterface {
    readonly contentType: ContentType.ArtistAppearsOn;
    readonly albums: ConnectionModel<AlbumPreviewModel> | null;
}
type ArtistAlbumsAppearsOnPage = ArtistAlbumsAppearsOnSection;

export function createArtistAlbumsAppearsOnSectionModel(data: ArtistAlbumsAppearsOnSectionData, id: string): ArtistAlbumsAppearsOnSectionModel {
    return {
        graphType: data.__typename,
        type: DomainModelType.Section,
        contentType: ContentType.ArtistAppearsOn,
        id,
        cover: null,
        albums: createAlbumPreviewConnectionModel(data.appearsOnAlbums)
    };
}

export function createArtistAlbumsAppearsOnPageModel(data: ArtistAlbumsAppearsOnPageData, id: string): ArtistAlbumsAppearsOnPageModel {
    return {
        ...createArtistAlbumsAppearsOnSectionModel(data, id),
        type: DomainModelType.Page
    };
}
