import { replaceBrowserAudio } from "../flow/updateBrowserAudios";
import { updateUrlInCache } from "../hls";
import type { AudioInputItemModel } from "models/app/player/input";
import type { BrowserAudioItemModel } from "models/app/player/output";

export async function saveUrl(audio: AudioInputItemModel, browserAudio: BrowserAudioItemModel) {
    const oldUrl = browserAudio.url?.url;
    const newUrl = audio.url2?.url;

    const change = newUrl != oldUrl;
    const replace = change && !!oldUrl;

    if (replace) browserAudio = replaceBrowserAudio(browserAudio, audio);
    if (change) browserAudio.url = audio.url2;

    await updateUrlInCache(browserAudio);

    return browserAudio;
}
