import { h } from "preact";
import { useContext } from "preact/hooks";
import { ContextMenuManagerContext, ContextMenuMode, Sheet } from "..";
import { IconMenuTop } from "./tops/IconMenuTop";
import { useTranslations } from "global/config";
import { IconName } from "components/atoms/icon";
import { NotificationFeed } from "components/organisms/notificationFeed";
import { TopHeaderLink } from "../links/TopHeaderLink";

export const NotificationFeedContextMenu = () => {
    const showHeadline = useContext(ContextMenuManagerContext).mode !== ContextMenuMode.BottomSheet;
    const translations = useTranslations();
    return (
        <Sheet top={<IconMenuTop icon={IconName.Notification} title={translations.NotificationFeedTitle} />}>
            <TopHeaderLink title={translations.NotificationFeedTitle} icon={IconName.Notification} />
            <NotificationFeed showHeadline={showHeadline} />
        </Sheet>
    );
};
