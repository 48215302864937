import { h } from "preact";
import { Link } from "..";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { getIcon } from "services/resource";
import { getLinkFromResource } from "services/resource/resourceLinkService";
import type { PlaylistPreviewModel } from "models/domain";

interface Props {
    playlist: PlaylistPreviewModel;
    onClick: () => void;
    showIcon: boolean;
}

export const PlaylistLink = ({ showIcon, playlist, onClick }: Props) => {
    const icon = getIcon(playlist.contentType);
    if (icon == null) {
        log.error({ code: "web-210517-1418", msg: DefaultLogMessage.NotImplemented });
        return null;
    }

    const title = getLinkFromResource(playlist)?.text;

    return (
        <Link onClick={onClick} icon={showIcon ? icon : undefined} closeOnClick>
            {title}
        </Link>
    );
};
