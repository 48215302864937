import { h } from "preact";

export enum ModalOpenResult {
    Submit,
    Dismiss
}

export interface AppModalModel {
    open: h.JSX.Element | null;
}
