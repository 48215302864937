import { h } from "preact";
import { ResourceTextDesign } from ".";
import { ItemGroup } from "../itemGroup/ItemGroup";
import { ResourceLink } from "./ResourceLink";
import { dispatch } from "global";
import { SET_MAXIPLAYER_OPEN } from "global/actions";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { MobileMaxiPlayerOpen } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourceContentType, ResourcePageModel, ResourcePreviewModel } from "models/domain";
import { useLinkContextsFromPreview } from "components/shared/hooks";

interface Props {
    resource: ResourcePreviewModel | ResourcePageModel | null;
    resourceType: ResourceContentType;
    context: PreviewContextModel;
}

export const ResourceCardMaxiPlayer = ({ resource, resourceType, context }: Props) => {
    const linkModels = useLinkContextsFromPreview(context);

    if (linkModels.length !== 2) log.error({ code: "web-210304-1639", msg: DefaultLogMessage.UnexpectedValue, data: { resourceType, linkModels } });

    const closeMaxiPlayer = () => dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });

    const links = linkModels.map((group, groupIndex) => {
        const design = groupIndex === 0 ? ResourceTextDesign.MaxiPlayerPrimary : ResourceTextDesign.MaxiPlayerSecondary;
        return <ResourceLink key={groupIndex} testLocatorName={groupIndex} design={design} links={group} onNavigate={closeMaxiPlayer} />;
    });

    return <ItemGroup title={links[0]} subtitle={links[1]} scrollingText={true} />;
};
