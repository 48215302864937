import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { SortingMenuTop } from "./tops";
import { Sheet } from "..";
import { Link } from "../Link";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL, SET_MY_MUSIC_SORTING } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { CombinedPlaylistsSorting } from "generated/graphql-types";
import type { RootModel } from "models/app";
import { IconName } from "components/atoms/icon";

interface Props {
    target: "playlistsSorting" | "sidebarPlaylistsSorting";
}

export const MyPlaylistsSortingContextMenu = ({ target }: Props) => {
    const currentSorting = useSelector((root: RootModel) => root.ui[target]);
    const translations = useTranslations();
    const sortModifiedDate = useCallback(() => {
        dispatch({ type: SET_MY_MUSIC_SORTING, payload: { [target]: CombinedPlaylistsSorting.ModifiedDate } });
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    }, [target]);

    const sortCreatedDate = useCallback(() => {
        dispatch({ type: SET_MY_MUSIC_SORTING, payload: { [target]: CombinedPlaylistsSorting.CreatedDate } });
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    }, [target]);

    const sortAlphabetically = useCallback(() => {
        dispatch({ type: SET_MY_MUSIC_SORTING, payload: { [target]: CombinedPlaylistsSorting.Title } });
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    }, [target]);

    return (
        <Sheet top={<SortingMenuTop />}>
            <Link
                icon={currentSorting == CombinedPlaylistsSorting.ModifiedDate ? IconName.Checkmark : IconName.None}
                onClick={currentSorting !== CombinedPlaylistsSorting.ModifiedDate ? sortModifiedDate : undefined}>
                {translations.SortByLatestUpdated}
            </Link>
            <Link
                icon={currentSorting == CombinedPlaylistsSorting.CreatedDate ? IconName.Checkmark : IconName.None}
                onClick={currentSorting !== CombinedPlaylistsSorting.CreatedDate ? sortCreatedDate : undefined}>
                {translations.SortByLastAdded}
            </Link>
            <Link
                icon={currentSorting == CombinedPlaylistsSorting.Title ? IconName.Checkmark : IconName.None}
                onClick={currentSorting !== CombinedPlaylistsSorting.Title ? sortAlphabetically : undefined}>
                {translations.SortAlphabetically}
            </Link>
        </Sheet>
    );
};
