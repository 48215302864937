import { h } from "preact";
import "./MobileTopBar.scss";
import type { PageTopBarProps } from "..";
import { Button } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";

export const MobileTopBar = ({ error, middle, up, back, disableMobileTitle, onClose, right }: PageTopBarProps) => {
    if (error) {
        right = null;
        middle = null;
    } else {
        middle = typeof middle === "string" ? <h1>{middle}</h1> : middle;
    }

    const backButton = back && <Button className="up" icon={IconName.ArrowLeftAndroid} onClick={() => window.history.back()} />;
    const upButton = up && <Button className="up" icon={IconName.ArrowLeftAndroid} link={up} />;
    const closeButton = onClose && <Button className="close" icon={IconName.ArrowDown} onClick={onClose} />;

    return (
        <div className={`mobileTopBar KlM${disableMobileTitle ? " nomiddle" : ""}`}>
            <div className="left">{backButton ?? upButton ?? closeButton}</div>
            {!disableMobileTitle && <div className="middle">{middle && middle}</div>}
            <div className="right">{right}</div>
        </div>
    );
};
