import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { convertPlayableProperties, getAndConvertAnalyticsNavigationProperties } from "../properties/event";
import { isPagePlayable } from "../properties/event/isPagePlayable";
import { getAnalyticsPlayablePageProperties } from "../properties/event/playablePageProperties";
import { convertAnalyticsViewPageProperties, getAnalyticsViewPageProperties } from "../properties/event/viewPageProperties";
import { getAnalyticsUserProperties } from "../properties/user";
import { getActivePageContext } from "services/navigationStack/navigationStack";
import type { PageLoadError } from "models/app/ViewPageError";

export async function getAnalyticsViewPageEvent(error: PageLoadError | null, redirectFrom: string | null): Promise<AnalyticsData> {
    const page = getActivePageContext();

    const data = await combineAnalyticsData(
        getAndConvertAnalyticsNavigationProperties(),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        isPagePlayable(page) ? convertPlayableProperties(getAnalyticsPlayablePageProperties(page)) : null,
        convertAnalyticsViewPageProperties(getAnalyticsViewPageProperties(error, redirectFrom))
    );
    return data;
}
