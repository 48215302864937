import { useEffect, useState } from "preact/hooks";
import { getStack } from "./navigationStack";
import { NAVIGATION_NAVIGATED } from "global/actions";
import { useMessageBus } from "global/hooks";

export function useNavigation() {
    const [stack, setStack] = useState({
        hasBackward: false,
        hasForward: false
    });
    useEffect(() => {
        const { hasBackward, hasForward } = getStack();
        setStack({
            hasForward,
            hasBackward
        });
    }, [setStack]);
    useMessageBus(NAVIGATION_NAVIGATED, (msg) => {
        setStack(msg.payload);
    });
    return stack;
}
