import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { Link } from "../Link";
import { dispatch } from "global";
import { PLAYER_TOGGLE_FAVORITE } from "global/actions";
import { useTranslations } from "global/config";
import type { Multiselect } from "services/selection";
import { getMultiselectPlayModel, clearSelection } from "services/selection";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";
import { IconName } from "components/atoms/icon";

interface Props {
    multiselect: Multiselect;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const AddMultipleToMyMusicLink = ({ multiselect, context, showIcon }: Props) => {
    const translations = useTranslations();
    const { section } = context;
    const isFavorite = multiselect.state.productType === ContentType.MyMusicTracks;

    const icon = useMemo(() => {
        if (!showIcon) return undefined;
        if (isFavorite) return IconName.FavoritesActive;
        return IconName.Favorites;
    }, [isFavorite, showIcon]);

    const onRemoveFavorites = useCallback(async () => {
        const tracks = getMultiselectPlayModel(multiselect).tracks?.items ?? null;
        if (tracks) {
            if (isFavorite) {
                clearSelection(multiselect);
            }
            dispatch({ type: PLAYER_TOGGLE_FAVORITE, payload: { section, playables: tracks, operation: "remove" } });
        }
    }, [multiselect, section, isFavorite]);

    const onAddFavoritesOrLogin = useCallback(async () => {
        if (multiselect.state.activeItem) {
            const tracks = getMultiselectPlayModel(multiselect).tracks?.items;
            if (tracks) {
                dispatch({ type: PLAYER_TOGGLE_FAVORITE, payload: { section, playables: tracks ?? null, operation: "add" } });
            }
        }
    }, [multiselect, section]);

    return isFavorite ? (
        <Link icon={icon} toggled closeOnClick onClick={onRemoveFavorites}>
            {translations.ContextOptionRemoveFromMyMusic}
        </Link>
    ) : (
        <Link icon={icon} closeOnClick onClick={onAddFavoritesOrLogin}>
            {translations.ContextOptionAddToMyMusic}
        </Link>
    );
};
