import type { ArtistAlbumsSectionModel } from "./ArtistAlbums";
import { createArtistAlbumsSectionModel } from "./ArtistAlbums";
import type { ArtistAlbumsAppearsOnSectionModel } from "./ArtistAlbumsAppearsOn";
import { createArtistAlbumsAppearsOnSectionModel } from "./ArtistAlbumsAppearsOn";
import type { ArtistRadioModel, ArtistRadioPreviewModel } from "./ArtistRadioModel";
import { createArtistRadioLinkModel, createArtistRadioPreviewModel } from "./ArtistRadioModel";
import type { ArtistSimilarArtistsSectionModel } from "./ArtistSimilarArtistsModel";
import { createArtistSimilarArtistsSectionModel } from "./ArtistSimilarArtistsModel";
import type { ArtistSinglesSectionModel } from "./ArtistSingles";
import { createArtistSinglesSectionModel } from "./ArtistSingles";
import type { ArtistTracksSectionModel } from "./ArtistTracksModel";
import { createArtistTracksSectionModel } from "./ArtistTracksModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { ResourceModelLinkInterface } from "./ResourceModel";
import type { TrackWithoutAlbumPreviewData, TrackPreviewModel } from "./TrackModel";
import type {
    ArtistAlbumsSectionFragment,
    ArtistLinkFragment,
    ArtistHeaderSectionFragment,
    ArtistPlayFragment,
    ArtistPopularTracksSectionFragment,
    ArtistPreviewFragment,
    ArtistSimilarArtistsSectionFragment,
    ArtistSinglesSectionFragment,
    ArtistAppearsOnAlbumsSectionFragment
} from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type ArtistLinkData = ArtistLinkFragment;
type ArtistPreviewData = ArtistPreviewFragment;
type ArtistPlayData = ArtistPlayFragment;

type ArtistHeaderSectionData = ArtistHeaderSectionFragment;

export type ArtistModel = ArtistLinkModel;
export type ArtistLinkModel = ({ type: DomainModelType.Link } & ArtistLink) | ArtistPreviewModel;
export type ArtistPreviewModel = ({ type: DomainModelType.Preview } & ArtistPreview) | ArtistPlayModel;
export type ArtistPlayModel = ({ type: DomainModelType.Play } & ArtistPlay) | ArtistPageModel;
export type ArtistPageModel = { type: DomainModelType.Page } & ArtistPage;

interface ArtistLink extends ResourceModelLinkInterface {
    readonly contentType: ContentType.Artist;
    readonly name: string;
}

interface Artist extends ArtistLink, PlayQueuePlayableModelInterface {
    readonly sharingUrl: string | null;
    readonly artistRadio: ArtistRadioModel | null;
}
//TODO: get back to this
type ArtistPreview = Artist;

interface ArtistPlay extends Artist {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

interface ArtistPage extends ArtistPlay {
    readonly tracksSection: ArtistTracksSectionModel | null;
    readonly albums: ArtistAlbumsSectionModel | null;
    readonly singles: ArtistSinglesSectionModel | null;
    readonly artistRadio: ArtistRadioPreviewModel | null;
    readonly similarArtists: ArtistSimilarArtistsSectionModel | null;
    readonly appearsOn: ArtistAlbumsAppearsOnSectionModel | null;
}

function createArtist(data: ArtistPreviewData): Artist {
    return {
        contentType: ContentType.Artist,
        graphType: data.__typename,
        id: data.id,
        name: data.title,
        cover: data.cover ?? null,
        trackCount: null,
        sharingUrl: data.share ?? null,
        artistRadio: createArtistRadioLinkModel(data)
    };
}

export function createArtistLinkModel(data: ArtistLinkData): ArtistLinkModel {
    return {
        type: DomainModelType.Link,
        contentType: ContentType.Artist,
        id: data.id,
        name: data.title
    };
}

export function createFeaturedArtistOnTrackLinkModel(data: TrackWithoutAlbumPreviewData): ArtistLinkModel[] | null {
    const items = data.featuredArtists.items;
    if (!items) return null;
    if (items.length < 1) return null;
    return items.map((item) => createArtistLinkModel(item));
}

export function createArtistPreviewModel(data: ArtistPreviewData): ArtistPreviewModel {
    return {
        ...createArtist(data),
        type: DomainModelType.Preview
    };
}

export function createArtistPlayModel(data: ArtistPlayData, index: number): ArtistPlayModel {
    return {
        ...createArtist(data),
        type: DomainModelType.Play,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, index) : null
    };
}

export function createEmptyArtistPageModel(id: string): ArtistPageModel {
    return {
        graphType: undefined,
        type: DomainModelType.Page,
        contentType: ContentType.Artist,
        tracks: null,
        id,
        name: "",
        trackCount: null,
        cover: null,
        tracksSection: null,
        albums: null,
        singles: null,
        artistRadio: null,
        similarArtists: null,
        sharingUrl: null,
        appearsOn: null
    };
}

export function fillArtistPageModel_basicSectionAndRadio(model: ArtistPageModel, data: ArtistHeaderSectionData): ArtistPageModel {
    return {
        ...model,
        graphType: data.__typename,
        name: data.title,
        cover: data.cover ?? null,
        artistRadio: createArtistRadioPreviewModel(data)
    };
}

export function fillArtistPageModel_artistTracks(model: ArtistPageModel, data: ArtistPopularTracksSectionFragment): ArtistPageModel {
    return {
        ...model,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0) : null,
        tracksSection: createArtistTracksSectionModel(data, model.id)
    };
}

export function fillArtistPageModel_artistAlbums(model: ArtistPageModel, data: ArtistAlbumsSectionFragment): ArtistPageModel {
    return {
        ...model,
        albums: createArtistAlbumsSectionModel(data)
    };
}

export function fillArtistPageModel_artistSingles(model: ArtistPageModel, data: ArtistSinglesSectionFragment): ArtistPageModel {
    return {
        ...model,
        singles: createArtistSinglesSectionModel(data, model.id)
    };
}

export function fillArtistPageModel_similarArtists(model: ArtistPageModel, data: ArtistSimilarArtistsSectionFragment): ArtistPageModel {
    return {
        ...model,
        similarArtists: createArtistSimilarArtistsSectionModel(data, model.id)
    };
}
export function fillArtistPageModel_appearsOn(model: ArtistPageModel, data: ArtistAppearsOnAlbumsSectionFragment): ArtistPageModel {
    return {
        ...model,
        appearsOn: createArtistAlbumsAppearsOnSectionModel(data, model.id)
    };
}
