import { Fragment, h } from "preact";
import { TestLocator } from "global/constants";
import type { ProductPageModel } from "services/resource";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlayQueuePlayablePageModel, RadioPageModel } from "models/domain";
import { ButtonPlayRadio } from "components/atoms/controls/button/buttons";
import { ButtonPlayBig } from "components/atoms/controls/button/buttons/ButtonPlayBig";
import { ButtonPlayShuffleBig } from "components/atoms/controls/button/buttons/ButtonPlayShuffleBig";

interface PlayableActionsProps {
    previewContext: PreviewContextModel;
    canPlay: boolean;
    isPlayable: boolean;
    isRadio: boolean;
    product: ProductPageModel | null;
    filter?: h.JSX.Element;
    sort?: h.JSX.Element;
}
export const PlayableActions = ({ product, previewContext, isPlayable, isRadio, canPlay, filter, sort }: PlayableActionsProps) => {
    if (!isPlayable) return null;

    const playable = product as PlayQueuePlayablePageModel | null;
    const disabled = !canPlay;

    return (
        <div className={`menu1`}>
            {isRadio ? (
                <ButtonPlayRadio radio={playable as RadioPageModel} context={previewContext} />
            ) : (
                <Fragment>
                    <ButtonPlayBig playable={playable} context={previewContext} testLocator={TestLocator.ProductPagePlayButton} disabled={disabled} />
                    <ButtonPlayShuffleBig playable={playable} context={previewContext} testLocator={TestLocator.ProductPageShufflePlayButton} disabled={disabled} />
                    {filter}
                    {sort}
                </Fragment>
            )}
        </div>
    );
};
