import { logPlayerActionEvent } from "./logPlayerActionEvent";
import { store } from "global";

export function logMuteUnmuteEvent() {
    const { muted } = store.getState().player;
    if (muted) {
        logPlayerActionEvent("Mute");
    } else {
        logPlayerActionEvent("Unmute");
    }
}
