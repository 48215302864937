import type { ResourceModelInterface } from ".";
import type { SearchPlaylistsSectionFragment } from "./../../generated/graphql-types";
import type { SearchAlbumResultsModel } from "./SearchAlbumResultsModel";
import { createSearchAlbumResultsSectionModel } from "./SearchAlbumResultsModel";
import type { SearchArtistResultsModel } from "./SearchArtistResultsModel";
import { createSearchArtistResultsSectionModel } from "./SearchArtistResultsModel";
import type { SearchMixedResultsModel } from "./SearchMixedResultsModel";
import { createSearchMixedResultsSectionModel } from "./SearchMixedResultsModel";
import type { SearchPlaylistResultsModel } from "./SearchPlaylistResultsModel";
import { createSearchPlaylistResultsSectionModel } from "./SearchPlaylistResultsModel";
import type { SearchTrackResultsModel } from "./SearchTrackResultsModel";
import { createSearchTrackResultsSectionModel } from "./SearchTrackResultsModel";
import { getNextUUID } from "services/utils";
import type { SearchMixedSectionsFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type SearchLimitedMainData = SearchMixedSectionsFragment;
type SearchLimitedPlaylistData = SearchPlaylistsSectionFragment;

export type SearchResultModel = { type: DomainModelType.Page; contentType: ContentType.SearchResult } & SearchResult;

interface SearchResult extends ResourceModelInterface {
    mixedResults: SearchMixedResultsModel | null;
    tracks: SearchTrackResultsModel | null;
    albums: SearchAlbumResultsModel | null;
    artists: SearchArtistResultsModel | null;
    playlists: SearchPlaylistResultsModel | null;
}

export function createSearchLimitedMainModel(data: SearchLimitedMainData, criterion: string): SearchResultModel {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        type: DomainModelType.Page,
        contentType: ContentType.SearchResult,
        cover: null,
        mixedResults: data ? createSearchMixedResultsSectionModel(data) : null,
        tracks: data ? createSearchTrackResultsSectionModel(data, criterion) : null,
        albums: data.albums ? createSearchAlbumResultsSectionModel(data, criterion) : null,
        artists: data.artists ? createSearchArtistResultsSectionModel(data, criterion) : null,
        playlists: null
    };
}
export function createSearchLimitedPlaylistModel(data: SearchLimitedPlaylistData, criterion: string): SearchResultModel {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        type: DomainModelType.Page,
        contentType: ContentType.SearchResult,
        cover: null,
        mixedResults: null,
        tracks: null,
        albums: null,
        artists: null,
        playlists: createSearchPlaylistResultsSectionModel(data, criterion)
    };
}

export function mergeSearchResultModel(a: SearchResultModel | null, b: SearchResultModel | null): SearchResultModel | null {
    if (!a && !b) return null;
    return {
        graphType: a?.graphType ?? b?.graphType,
        id: getNextUUID(),
        type: DomainModelType.Page,
        contentType: ContentType.SearchResult,
        cover: null,
        mixedResults: a?.mixedResults ?? b?.mixedResults ?? null,
        tracks: a?.tracks ?? b?.tracks ?? null,
        albums: a?.albums ?? b?.albums ?? null,
        artists: a?.artists ?? b?.artists ?? null,
        playlists: a?.playlists ?? b?.playlists ?? null
    };
}
