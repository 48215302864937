import type { FirebaseApp } from "firebase/app";
import type { FirebasePerformance } from "firebase/performance";

type FirebaseGetPerformance = (app: FirebaseApp | null) => FirebasePerformance;
export function getFbPerformance(fbApp: FirebaseApp | null) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getPerformance: FirebaseGetPerformance | null = (<any>window).trackingFbase.getPerformance ?? null;

    const performance = getPerformance ? getPerformance(fbApp) : null;
    return performance;
}
