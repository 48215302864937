import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getPlaylistCategoryPlaylistsPagination, usePlaylistCategoryPage } from "services/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    playlistCategoryId: string;
}

export const PlaylistCategoryPage = ({ playlistCategoryId }: Props) => {
    const query = usePlaylistCategoryPage({ id: playlistCategoryId, first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string | null) => getPlaylistCategoryPlaylistsPagination({ first, after, id: playlistCategoryId }), [playlistCategoryId]);

    return <ShowAllPageTemplate query={query} type={ContentType.PlaylistCategory} items={product?.playlists ?? null} fetchFn={fetchFn} display={ResourceDisplayType.Grid} />;
};
