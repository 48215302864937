import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import type { AnalyticsFeedCurrentNotifications } from "../properties/event";
import { getAnalyticsNotificationsAdded, getAnalyticsNotificationsSeen } from "../properties/event";
import { getAnalyticsUserProperties, convertNotificationFeedProperties, getAnalyticsNotificationFeedProperties } from "../properties/user";
import type { NotificationModel } from "models/domain/NotificationModel";

export async function getAnalyticsNotificationsAddedEvent(current: AnalyticsFeedCurrentNotifications, added: NotificationModel[]): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        getAnalyticsNotificationsAdded(added, current),
        convertNotificationFeedProperties(getAnalyticsNotificationFeedProperties()),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties()
    );
    return data;
}

export async function getAnalyticsFeedNotificationsSeenEvent(current: AnalyticsFeedCurrentNotifications, seen: NotificationModel[]): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        getAnalyticsNotificationsSeen(seen, current),
        convertNotificationFeedProperties(getAnalyticsNotificationFeedProperties()),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties()
    );
    return data;
}
