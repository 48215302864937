import type { QueueTrackModel } from "./player/input";
import type { PreviewContextModel } from "./resourceContextModel";
import { removePageSectionsFromPreviewContext } from "./resourceContextModel";
import { getDragTitle } from "services/drag/dragService";
import { isPlayable, isDraggable } from "services/playable";
import type { PlayQueuePlayableModel, ResourcePreviewModel } from "models/domain";

export enum DragDropItemType {
    QueueTrack = "queueTrack",
    Playable = "playable"
}

export type DraggableItemModel = DraggablePlayableModel;

export interface DragProps {
    dragSourceId: string;
}

export interface DropProps {
    onDrop?: (item: DraggableItemModel) => void;
    dragSourceId: string;
}

export interface DraggablePlayableModel {
    playable: PlayQueuePlayableModel | QueueTrackModel;
    dragSourceId: string;
    title: string;
    context: PreviewContextModel;
}

export const createDraggablePlayableModel = (
    resource: ResourcePreviewModel | QueueTrackModel | null,
    dragSourceId: string,
    context: PreviewContextModel
): DraggableItemModel | null => {
    context = removePageSectionsFromPreviewContext(context);
    if (resource != null && isPlayable(resource) && isDraggable(resource)) {
        return {
            playable: resource,
            dragSourceId,
            title: getDragTitle(resource),
            context
        };
    }
    return null;
};
