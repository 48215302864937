import type { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { openMenuAtPosition, openMenuByElement, openMenuByMouse } from "services/contextMenus/open";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { ContextMenuSheetWidth } from "models/view/contextMenu";

export function useContextMenu(menu: h.JSX.Element | null, closeOnNavigation = true, width: ContextMenuSheetWidth = ContextMenuSheetWidth.Default, context?: PreviewContextModel) {
    const [isOpen, setIsOpen] = useState(false);

    const openByElement = useCallback(
        async (element: HTMLElement) => {
            if (!menu) return;
            setIsOpen(true);
            const result = await openMenuByElement(menu, element, closeOnNavigation, width, context);
            setIsOpen(false);
            return result;
        },
        [menu, closeOnNavigation, width, context]
    );

    const openByMouse = useCallback(
        async (event: MouseEvent) => {
            if (!menu) return;
            setIsOpen(true);
            const result = await openMenuByMouse(menu, event, closeOnNavigation, width, context);
            setIsOpen(false);
            return result;
        },
        [menu, closeOnNavigation, width, context]
    );

    const openAtPosition = useCallback(
        async (x: number, y: number) => {
            if (!menu) return;
            setIsOpen(true);
            const result = await openMenuAtPosition(menu, x, y, closeOnNavigation, width, context);
            setIsOpen(false);
            return result;
        },
        [menu, closeOnNavigation, width, context]
    );


    return { open: openByElement, openByMouse, openAtPosition, isOpen };
}
