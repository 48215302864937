import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { getPlayQueueCurrentLane, getPlayQueuePriorityLane, getPlayQueueMemoryLane, getPlayQueueFlowLane } from "services/player/inputs/inputs/playQueue/helpers";
import type { RootModel } from "models/app";

export function useQueueLanes() {
    const playQueue = useSelector((root: RootModel) => root.queue.playQueue);

    return useMemo(() => {
        const memory = getPlayQueueMemoryLane(playQueue);
        const isMemoryLaneEmpty = memory.length === 0;

        const current = getPlayQueueCurrentLane(playQueue);
        const isCurrentLaneEmpty = !current;

        const flow = getPlayQueueFlowLane(playQueue);
        const isFlowLaneEmpty = flow.length === 0;

        const priority = getPlayQueuePriorityLane(playQueue);
        const isPriorityLaneEmpty = priority.length === 0;

        const isQueueEmpty = playQueue.length === 0;

        return {
            memory,
            current,
            priority,
            flow,
            playQueue,
            isMemoryLaneEmpty,
            isCurrentLaneEmpty,
            isFlowLaneEmpty,
            isPriorityLaneEmpty,
            isQueueEmpty
        };
    }, [playQueue]);
}
