import { h } from "preact";
import { useContext } from "preact/hooks";
import { ResourceMenuTop } from "./tops";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import { ShareLink } from "../links";
import { AddToMyMusicLink } from "../links/AddToMyMusicLink";
import { GoToArtistRadioLink } from "../links/GoToArtistRadioLink";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ArtistPreviewModel } from "models/domain";

interface Props {
    artist: ArtistPreviewModel | null;
    context: PreviewContextModel;
}

export const ArtistContextMenu = ({ artist, context }: Props) => {
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    const radio = artist?.artistRadio ?? null;
    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <AddToMyMusicLink playable={artist} context={context} showIcon={showIcon} />
            <GoToArtistRadioLink artistRadio={radio} showIcon={showIcon} context={context} />
            <ShareLink context={context} showIcon={showIcon} />
        </Sheet>
    );
};
