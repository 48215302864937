import { dismissModal, Modal, openModal } from "components/organisms/modal";
import { ButtonDesign } from "components/atoms/controls/button";
import { h } from "preact";
import { useTranslations } from "global/config";

export const showYourYearModal = () => {
    return openModal(<YourYearModal />);
};

const YourYearModal = () => {

    const translations = useTranslations();
    return (
        <Modal buttons={[
            {
                title: "YourYearBannerPopupClose",
                action: () => dismissModal('YourYearModal'),
                design: ButtonDesign.PrimarySmall
            }
        ]} title={translations['YourYearBannerPopupTitle']}>
            <div className="text">
                <p>{translations['YourYearBannerPopupSubTitle']}</p>
            </div>
        </Modal>
    );
};

export default YourYearModal;