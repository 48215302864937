import { h } from "preact";
import "./Banner.scss";

interface BannerProps {
    message: string;
    open: boolean;
    button?: JSX.Element;
}
export const Banner = ({ button, message, open }: BannerProps) => {
    return (
        <div className={`banner Y6s --center-${button == null} --show-${open}`}>
            <div className="message">{message}</div>
            {button}
        </div>
    );
};
