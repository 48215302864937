import { playerShuffle } from "./actions";
import { playerPlayNext } from "./actions/playNext";
import { playerRewind } from "./actions/rewind";
import { playerRewindOrPlayPrevious } from "./actions/rewindOrPlayPrevious";
import { messageBus } from "global";
import { PLAYER_NEXT, PLAYER_PREVIOUS, PLAYER_PREVIOUS_OR_REWIND, PLAYER_REWIND, PLAYER_SEEK, PLAYER_SET_PLAY, PLAYER_SHUFFLE } from "global/actions";
import { playPrevious } from "services/player/inputs/inputs/playQueue/actions";
import { playerSeek, playPause } from "services/player/inputs/service/actions";

export const initLocalPlayerControllerService = () => {
    messageBus.subscribeEvery(PLAYER_SET_PLAY, (msg) => {
        const { play, context } = msg.payload;
        playPause(play, context);
    });

    messageBus.subscribeEvery(PLAYER_SEEK, (msg) => {
        const { percent, context } = msg.payload;
        playerSeek(percent, context);
    });

    messageBus.subscribeEvery(PLAYER_SHUFFLE, async (msg) => {
        playerShuffle(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYER_NEXT, (msg) => {
        playerPlayNext(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYER_PREVIOUS_OR_REWIND, async (msg) => {
        playerRewindOrPlayPrevious(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYER_REWIND, (msg) => {
        playerRewind(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYER_PREVIOUS, (msg) => {
        playPrevious(msg.payload.context);
    });
};
