export function getRows(current: HTMLDivElement | null, rowHeight: number, rows?: number) {
    const rowsCount = rows ?? Math.ceil(window.innerHeight / rowHeight);
    const visibleBuffer = 2;
    const visibleRows = rowsCount + visibleBuffer * 2;

    const fromTop = current?.getBoundingClientRect().top ?? 0;
    const startRow = Math.floor(-fromTop / rowHeight) - visibleBuffer;
    const firstRowInView = Math.max(startRow, 0);
    const lastRowInView = firstRowInView + visibleRows;

    return { firstRowInView, lastRowInView };
}
