import { log, DefaultLogMessage } from ".";
import { getAnalyticsPlayableStopProperties } from "./analytics/properties/event";
import { store, messageBus, dispatch } from "global";
import {
    LOGGING_EVENT_PLAYBACK_REPORT_FINISHED,
    AUDIO_INPUT_AUDIOS_CHANGE,
    AUDIO_INPUT_AUDIO_PLAY_CHANGE,
    AUDIO_INPUT_AUDIO_END_REASON,
    LOGGING_EVENT_PLAYBACK_REPORT_FORCE_FINISHED,
    LOGGING_EVENT_PLAYBACK_REPORT_SENT
} from "global/actions";
import { playerConfig } from "global/constants/player";
import type { AppSession } from "services/appSession";
import { getAndDeleteReports, getAndDeleteSharedReports } from "services/appSession";
import { addSessionFinishedReport, removeSessionFinishedReport } from "services/appSession/operations/finishedReports";
import { getSessionLoadedPlaybackReports, saveSessionLoadedPlaybackReports } from "services/appSession/operations/loadedReports";
import { tryUpdateArray } from "services/arrayHelper";
import { getNormalizedDataAnalyticsQueueAdded } from "services/normalizeData";
import { getLoadedInputAudio, getAudioInputItemByPredicateFromState } from "services/player/inputs/service/helpers";
import type { FinishedPlaybackReport, LoadedPlaybackReport } from "models/app/playbackReport";
import { PlayState } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";

let enableFinishReports = false;
const timers: Map<string, { intervalId: number; time: Date }> = new Map();

const getAudioByReportIdFromState = (reportId: string) => store.getState().audioInput.audios.find((audio) => audio.reportId === reportId);
const getLoadedPlaybackReport = (loaded: LoadedPlaybackReport[], reportId: string) => loaded.find((loaded) => loaded.reportId === reportId) ?? null;
export const getLoadedPlaybackReportFromStorage = (reportId: string) => getLoadedPlaybackReport(getSessionLoadedPlaybackReports() ?? [], reportId);

export async function tryTransferReportsFromSharedStorageAndFinishInCurrentSession() {
    const { loaded, finished } = getAndDeleteSharedReports();
    finishTransferedReports(loaded, finished);
}

export async function tryTransferAndFinishReportsInCurrentSession(session: AppSession) {
    const { loaded, finished } = getAndDeleteReports(session);
    finishTransferedReports(loaded, finished);
}

async function finishTransferedReports(loaded: LoadedPlaybackReport[], finished: FinishedPlaybackReport[]) {
    for (const report of loaded) {
        log.info({ code: "web-211101-1251", msg: "finish loaded playback report from another session", data: { report } });
        if (!report.analytics2) log.error({ code: "web-220329-2034", msg: "loaded report missing analytics in finishTransferedReports" });
        if (report.analytics2) report.analytics2.endReason = AudioContextAction.AppSessionEnded;
        await finishReport(report);
    }

    for (const report of finished) {
        log.info({ code: "web-211101-1420", msg: "report finished playback report from another session", data: { report } });
        if (!report.analytics2) log.error({ code: "web-220329-2037", msg: "finished report missing analytics in finishTransferedReports" });

        await dispatch({ type: LOGGING_EVENT_PLAYBACK_REPORT_FINISHED, payload: { report } });
    }
}

function updateLoadedPlaybackReportFromStorage(report: LoadedPlaybackReport) {
    let loaded = getSessionLoadedPlaybackReports();
    loaded = tryUpdateArray(
        loaded,
        (item) => item.reportId === report.reportId,
        () => report,
        "web-211020-1716"
    );
    saveSessionLoadedPlaybackReports(loaded);
}

async function loadReports() {
    await messageBus.sync();
    const reports = getSessionLoadedPlaybackReports();
    const audios = getLoadedInputAudio();
    const newAudios: AudioInputItemModel[] = audios.filter((item) => !reports.some((loadedItem) => item.reportId === loadedItem.reportId));

    const newReports: LoadedPlaybackReport[] = newAudios.map((item) => {
        const loadData = item.analytics2?.loadData;
        if (!loadData) log.error({ code: "web-220329-2048", msg: "analytics loadData missing when creating new report" });

        const playableData = item.analytics2?.playableData;
        if (!playableData) log.error({ code: "web-220329-2058", msg: "analytics playableData missing when creating new report" });

        const queueData = getNormalizedDataAnalyticsQueueAdded(item.analytics2?.addedDataId ?? null);
        if (!queueData)
            log.error({ code: "web-220329-2047", msg: "analytics queueData missing when creating new report", data: { addedDataId: item.analytics2?.addedDataId ?? null } });

        const analytics2: LoadedPlaybackReport["analytics2"] =
            queueData && loadData && playableData
                ? {
                      endReason: null,
                      loadData,
                      playableData,
                      queueData
                  }
                : undefined;

        return {
            analytics2,
            reportId: item.reportId,
            playbackContext: item.playbackContext,
            played: 0,
            sendReport: item.sendReport,
            url: item.url2?.url ?? null
        };
    });

    if (newReports.length == 0) return;

    const allReports = [...reports, ...newReports];
    saveSessionLoadedPlaybackReports(allReports);
}

async function updateReportUrls() {
    const reports = getSessionLoadedPlaybackReports();
    for (const report of reports) {
        const audio = getAudioInputItemByPredicateFromState((audio) => audio.reportId === report.reportId);
        if (!audio) continue;
        if (audio.url2 == null) continue;
        if (audio.url2.url != report.url) {
            if (!report.analytics2) log.error({ code: "web-220601-1513", msg: "report without analytics" });
            const newReport: LoadedPlaybackReport = { ...report, url: audio.url2.url };
            updateLoadedPlaybackReportFromStorage(newReport);
        }
    }
}

async function updateTimers() {
    await messageBus.sync();
    const reports = getSessionLoadedPlaybackReports();

    for (const report of reports) {
        await updateTimer(report.reportId);
    }
}

export function getPlayedDurationFromReport(report: LoadedPlaybackReport) {
    let duration = Math.floor(report.played);
    if (duration < 0) {
        log.error({ code: "web-220524-1111", msg: "report has negative duration", data: { duration } });
        duration = 0;
    }
    return duration;
}

async function finishReport(loaded: LoadedPlaybackReport) {
    const reports = getSessionLoadedPlaybackReports().filter((report) => report.reportId !== loaded.reportId);
    saveSessionLoadedPlaybackReports(reports);

    stopTimer(loaded.reportId);
    const duration = getPlayedDurationFromReport(loaded);
    if (duration === 0) return;

    if (loaded.sendReport && loaded.url == null) log.error({ code: "web-211010-1822", msg: "report missing url" });
    if (loaded.sendReport && loaded.playbackContext == null) log.error({ code: "web-211020-2125", msg: "report missing playbackContext" });
    // TODO re-enable this log later
    // if (loaded.analytics2 == null) log.error({code: "web-211020-2128", msg:"report missing analytics");
    if (loaded.analytics2 && loaded.analytics2?.endReason == null) log.error({ code: "web-211020-2126", msg: "report missing endReason", data: { analytics: loaded.analytics2 } });

    let analytics2: FinishedPlaybackReport["analytics2"] | undefined;
    if (loaded.analytics2) {
        const stopData = getAnalyticsPlayableStopProperties({
            playDuration: duration,
            endReason: loaded.analytics2.endReason ?? AudioContextAction.Unknown
        });
        analytics2 = {
            queueData: loaded.analytics2.queueData,
            loadData: loaded.analytics2.loadData,
            playableData: loaded.analytics2.playableData,
            stopData
        };
    }

    const report: FinishedPlaybackReport = {
        url: loaded.url,
        duration,
        sendReport: loaded.sendReport,
        playbackContext: loaded.playbackContext,
        playedAt: new Date().getTime(),
        reportId: loaded.reportId,
        analytics2
    };

    log.info({ code: "web-211010-1420", msg: "finish playback report", data: { report } });

    await dispatch({ type: LOGGING_EVENT_PLAYBACK_REPORT_FINISHED, payload: { report } });
}
async function finishReports() {
    if (!enableFinishReports) return;
    await messageBus.sync();

    const audios = getLoadedInputAudio();
    const reports = getSessionLoadedPlaybackReports();

    const stoppedReports: LoadedPlaybackReport[] = reports.filter((old) => !audios.some((loaded) => old.reportId === loaded.reportId));

    if (stoppedReports.length === 0) return;

    const loadedReports = reports.filter((report) => !stoppedReports.some((stopped) => report.reportId === stopped.reportId));
    saveSessionLoadedPlaybackReports(loadedReports);

    stoppedReports.forEach(async (stopped) => finishReport(stopped));
}

async function updateTimer(reportId: string) {
    await messageBus.sync();
    const report = getLoadedPlaybackReportFromStorage(reportId);
    const audio = getAudioByReportIdFromState(reportId);
    const playing = audio?.playState === PlayState.Playing;

    if (report == null) {
        if (playing) log.error({ code: "web-210506-1458", msg: DefaultLogMessage.UnexpectedNull });
        stopTimer(reportId);
        return;
    }

    if (playing) startTimer(reportId);
    else {
        await addTime(reportId);
        stopTimer(reportId);
    }
}

function startTimer(reportId: string) {
    const timer = timers.get(reportId) ?? null;
    if (timer != null) return;

    const run = () => {
        addTime(reportId);
    };

    const time = new Date();
    const intervalId = window.setInterval(run, playerConfig.playbackReportChunkUpdateIntervalMs);

    timers.set(reportId, { intervalId, time });
}

function stopTimer(reportId: string) {
    const intervalId = timers.get(reportId) ?? null;
    if (intervalId != null) {
        window.clearInterval(intervalId.intervalId);
        timers.delete(reportId);
    }
}

async function addTime(reportId: string) {
    const report = getLoadedPlaybackReportFromStorage(reportId);
    if (report == null) {
        stopTimer(reportId);
        log.error({ code: "web-211009-1352", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    const timer = timers.get(reportId) ?? null;
    if (timer == null) return;

    const newTime = new Date();
    const playedMs = newTime.getTime() - timer.time.getTime();
    timer.time = newTime;

    if (playedMs > playerConfig.playbackReportChunkUpdateIntervalMs + 5000) {
        // log.error({code: "web-211009-1351", "time unexpected long", [{ key: "time", value: playedMs }]);
        return;
    }
    if (playedMs < 0) {
        log.error({ code: "web-220424-1105", msg: "time is negative", data: { time: playedMs } });
        return;
    }

    report.played += playedMs / 1000;
    if (!report.analytics2) log.error({ code: "web-220601-1514", msg: "report without analytics", data: { report } });
    updateLoadedPlaybackReportFromStorage(report);
}

async function forceEndReport(reportId: string) {
    const loaded = getLoadedPlaybackReportFromStorage(reportId);
    if (loaded == null) {
        log.error({ code: "web-211026-1413", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }
    await finishReport(loaded);
    await loadReports();
}
async function updateReport(audio: AudioInputItemModel) {
    await loadReports();
    await updateReportUrls();
    await updateTimer(audio.reportId);
    await finishReports();
}

async function updateReports() {
    await loadReports();
    await updateReportUrls();
    await updateTimers();
    await finishReports();
}

async function updateEndReason(audio: AudioInputItemModel, endReason: AudioContextAction) {
    const report = getLoadedPlaybackReportFromStorage(audio.reportId);

    if (report == null) {
        // log.error({ code: "web-211003-1303", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    if (report.analytics2 == null) {
        log.error({ code: "web-211003-1304", msg: DefaultLogMessage.UnexpectedNull, data: { reportId: audio.reportId } });
        return;
    }

    if (report.analytics2.endReason == endReason) return;

    report.analytics2.endReason = endReason;
    updateLoadedPlaybackReportFromStorage(report);
}

export const initPlaybackReportService = async () => {
    messageBus.subscribeEvery(AUDIO_INPUT_AUDIOS_CHANGE, async () => {
        await updateReports();
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_PLAY_CHANGE, async (msg) => {
        await updateReport(msg.payload.audio);
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_END_REASON, async (msg) => {
        await updateEndReason(msg.payload.audio, msg.payload.context.action);
    });

    messageBus.subscribeEvery(LOGGING_EVENT_PLAYBACK_REPORT_FORCE_FINISHED, async (msg) => {
        await forceEndReport(msg.payload.reportId);
    });

    messageBus.subscribeEvery(LOGGING_EVENT_PLAYBACK_REPORT_FINISHED, async (msg) => {
        addSessionFinishedReport(msg.payload.report);
    });

    messageBus.subscribeEvery(LOGGING_EVENT_PLAYBACK_REPORT_SENT, async (msg) => {
        removeSessionFinishedReport(msg.payload.report);
    });
};

export const startPlaybackReporting = () => {
    enableFinishReports = true;
    try {
        tryTransferReportsFromSharedStorageAndFinishInCurrentSession();
        updateReports();
    } catch (e) {
        log.error({ code: "web-220221-1335", msg: "error in start playback reporting on init", error: e });
    }
};
