import { ComponentChildren, h } from "preact";
import { forwardRef } from "preact/compat";
import { RootModel } from "models/app";
import { useSelector } from "react-redux";
import "./PageTemplate.scss";

interface Props {
    children: ComponentChildren;
    className?: string;
}

export const PageTemplate = forwardRef<HTMLDivElement, Props>(({ children, className }, ref) => {
    const mobileMaxiPlayerOpen = useSelector((root: RootModel) => root.ui.layout.mobileMaxiPlayerOpen);
    return (
        <div ref={ref} className={`pageTemplate G37 ${className ?? ""}${mobileMaxiPlayerOpen ? "" : " --gradient-true"}`}>
            {children}
        </div>
    );
});
