import type { ConnectionModel } from "./ConnectionModel";
import { createMixRadioPreviewConnectionModel } from "./ConnectionModel";
import type { MixRadioPreviewModel } from "./MixRadioModel";
import { MixRadioLinkParentType } from "./MixRadioModel";
import type { ResourceModelInterface, ResourceModelLinkInterface } from "./ResourceModel";
import type { MixRadioCategoryPageFragment, MixRadioCategoryPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type MixRadioCategoryPageData = MixRadioCategoryPageFragment;

export type MixRadioCategoryPreviewModel = ({ type: DomainModelType.Preview } & MixRadioCategoryPreview) | MixRadioCategoryPageModel;

export type MixRadioCategoryModel = MixRadioCategoryPageModel;
export type MixRadioCategoryPageModel = { type: DomainModelType.Page } & MixRadioCategoryPage;

interface MixRadioCategory extends ResourceModelLinkInterface, ResourceModelInterface {
    readonly title: string;
    readonly contentType: ContentType.MixRadioCategory;
}

type MixRadioCategoryPreview = MixRadioCategory;

interface MixRadioCategoryPage extends MixRadioCategoryPreview {
    readonly radios: ConnectionModel<MixRadioPreviewModel> | null;
}

export function createMixRadioCategoryPageModel(data: MixRadioCategoryPageData): MixRadioCategoryPageModel {
    return {
        graphType: data.__typename,
        contentType: ContentType.MixRadioCategory,
        type: DomainModelType.Page,
        id: data.id,
        title: data.title,
        cover: data.cover ?? null,
        radios: data.radios ? createMixRadioPreviewConnectionModel(data.radios, { type: MixRadioLinkParentType.MixRadioCategory, categoryId: data.id }) : null
    };
}

export function createMixRadioCategoryPreviewModel(data: MixRadioCategoryPreviewFragment): MixRadioCategoryPreviewModel {
    return {
        graphType: data.__typename,
        contentType: ContentType.MixRadioCategory,
        type: DomainModelType.Preview,
        id: data.id,
        title: data.title,
        cover: data.cover ?? null,
    };
}
