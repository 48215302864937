import type { AnalyticsPageProperties } from "../event/pageProperties";
import { getAnalyticsCurrentPageProperties } from "../event/pageProperties";
import type { AnalyticsRootPageProperties } from "../event/rootPageProperties";
import { getAnalyticsRootPageProperties } from "../event/rootPageProperties";
import type { AnalyticsRootPreviewProperties } from "../event/rootPreviewProperties";
import { getAnalyticsRootPreviewProperties } from "../event/rootPreviewProperties";
import type { AnalyticsRootSectionProperties } from "../event/rootSectionProperties";
import { getAnalyticsRootSectionProperties } from "../event/rootSectionProperties";
import type { PreviewContextModel } from "models/app/resourceContextModel";

export interface AnalyticsQueueIntentPropertiesGroup {
    pageProperties: AnalyticsPageProperties;
    rootPageProperties: AnalyticsRootPageProperties;
    rootSectionProperties: AnalyticsRootSectionProperties;
    rootPreviewProperties: AnalyticsRootPreviewProperties;
}

export function getAnalyticsQueueIntentPropertiesGroup(context: PreviewContextModel): AnalyticsQueueIntentPropertiesGroup {
    const group = {
        pageProperties: getAnalyticsCurrentPageProperties(),
        rootPageProperties: getAnalyticsRootPageProperties(context),
        rootSectionProperties: getAnalyticsRootSectionProperties(context),
        rootPreviewProperties: getAnalyticsRootPreviewProperties(context)
    };

    return group;
}
