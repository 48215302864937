import type { BeforeInstallPromptEvent } from "./BeforeInstallPromptEvent";
import { dispatch } from "global";
import { appSettingsShared } from "shared/services";
import { NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING } from "global/actions";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";

let promptEvent: BeforeInstallPromptEvent | null = null;
let promptEventOpen = false;

const canPromptUserForPWAInstall = () => promptEvent != null;

export const isAppRunAsPWA = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isStandaloneNavigator = (navigator as any).standalone === true;
    const isStandaloneMedia = window.matchMedia("(display-mode: standalone)").matches;

    const isStandalone = isStandaloneNavigator || isStandaloneMedia;
    return isStandalone;
};

export function isAppRunAsPWAChange(callback: (value: boolean) => void): () => void {
    const media = window.matchMedia("(display-mode: standalone)");
    const listener = () => callback(isAppRunAsPWA());

    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
}

export function initPWAService() {
    if (!enablePWA()) return;

    window.addEventListener("beforeinstallprompt", (e: unknown) => {
        const prompt = e as BeforeInstallPromptEvent;
        promptEvent = prompt;
        prompt.preventDefault();
    });

    window.addEventListener("appinstalled", () => {
        dispatch({ type: NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING, payload: { waiting: true } });
    });
}

export enum PWAInstallOption {
    InstallAvailable = "InstallAvailable",
    InstallPromptOpen = "InstallPromptOpen",
    Installed = "Installed",
    None = "None"
}

export const getPWAInstallOption = (): PWAInstallOption => {
    if (isAppRunAsPWA()) return PWAInstallOption.Installed;
    if (promptEventOpen) return PWAInstallOption.InstallPromptOpen;
    if (!canPromptUserForPWAInstall()) return PWAInstallOption.None;

    return PWAInstallOption.InstallAvailable;
};

export const promptUserForPWAInstall = async () => {
    const prompt = promptEvent;
    if (prompt == null) {
        log.error({ code: "web-210304-2003", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    promptEvent = null;
    promptEventOpen = true;

    prompt.prompt();
    const userChoice = await prompt.userChoice;

    promptEventOpen = false;
    const accepted = userChoice.outcome === "accepted";

    if (accepted) {
        log.info({ code: "web-210305-1745", msg: "PWA install accepted. browser might close this window" });
    }
    return accepted;
};

let _enablePWA: boolean | null = null;

function enablePWA(): boolean {
    if (_enablePWA == null) _enablePWA = getEnablePWA();
    return _enablePWA;
}

function getEnablePWA(): boolean {
    if (!appSettingsShared.EnablePWA) return false;

    // let origin = window.location.origin.toLowerCase();
    // origin = origin.replace("http://", "");
    // origin = origin.replace("https://", "");

    // if (origin.includes("yousee.dk") && origin != "musik.yousee.dk") return false;
    // if (origin.includes("telmore.dk") && origin != "musik.telmore.dk") return false;

    return true;
}
