import { h } from "preact";
import { Link } from "..";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { IconName } from "components/atoms/icon";
import { showInAppFeedbackModal } from "components/organisms/modal/modals/InAppFeedbackModal";

interface Props {
    showIcon: boolean;
}

export const GiveFeedbackLink = ({ showIcon }: Props) => {
    const translations = useTranslations();
    const giveFeedback = async () => {
        showInAppFeedbackModal();
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    };
    return (
        <Link icon={showIcon ? IconName.Feedback : undefined} onClick={giveFeedback}>
            {translations.SettingsFeedbackTitle}
        </Link>
    );
};
