import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { useCurrentLiveRadioFromState } from "./useCurrentLiveRadio";
import { useQueueTrackPlayableFromState } from "./useQueueTrackPlayable";
import { store } from "global";
import { getCurrentLiveRadioFromState } from "services/player/inputs/inputs/liveRadio/service";
import { getCurrentLane, getQueueTrackPlayable, getTrackParent } from "services/player/inputs/inputs/playQueue/helpers";
import type { RootModel } from "models/app";
import { AudioInputType } from "models/app/player";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueMode } from "models/app/player/input";
import type { ResourceContentType, ResourcePreviewModel, TrackParentModel } from "models/domain";
import type { LiveRadioPlayableModel } from "models/domain/LiveRadioPlayableModel";
import type { MixRadioPreviewModel } from "models/domain/MixRadioModel";
import type { TrackPlayablePreviewModel } from "models/domain/TrackPlayableModel";
import { ContentType } from "models/ModelType";

// todo: refactor logic in this file to use audio with index = 0 as current loaded audio

export function useQueueCurrentLaneFromState(): QueueTrackModel | null {
    const playQueue = useSelector((root: RootModel) => root.queue.playQueue);
    const current = getCurrentLane(playQueue);
    return current;
}

export function getQueueCurrentLaneFromState(): QueueTrackModel | null {
    const playQueue = store.getState().queue.playQueue;
    const current = getCurrentLane(playQueue);
    return current;
}

export function useQueueMixRadio(): MixRadioPreviewModel | null {
    const mixRadio = useSelector((root: RootModel) => root.queue.mixRadioSession?.mixRadio);
    return mixRadio ?? null;
}

export function getQueueMixRadioFromState(): MixRadioPreviewModel | null {
    const mixRadio = store.getState().queue.mixRadioSession?.mixRadio;
    return mixRadio ?? null;
}

function useQueueCurrentLaneTrackFromState(): TrackPlayablePreviewModel | null {
    const currentLane = useQueueCurrentLaneFromState();
    const currentLaneTrack = useQueueTrackPlayableFromState(currentLane);

    return currentLaneTrack;
}

function getQueueCurrentLaneTrackFromState(): TrackPlayablePreviewModel | null {
    const currentLane = getQueueCurrentLaneFromState();
    const currentLaneTrack = currentLane ? getQueueTrackPlayable(currentLane) : null;

    return currentLaneTrack;
}

export function useCurrentLanePrimaryResourceFromState(): { resource: ResourcePreviewModel | null; type: ResourceContentType } {
    const input = useSelector((root: RootModel) => root.player.input);
    const queueMode = useSelector((root: RootModel) => root.queue.mode);

    const queueTrack = useQueueCurrentLaneTrackFromState();
    const mixRadio = useQueueMixRadio();
    const liveRadio = useCurrentLiveRadioFromState();

    return useMemo(() => {
        if (input === AudioInputType.LiveRadio) return { type: ContentType.LiveRadioPlayable, resource: liveRadio };
        switch (queueMode) {
            case QueueMode.MixRadio:
                return { type: ContentType.MixRadio, resource: mixRadio };
            case QueueMode.Default: {
                return { type: ContentType.TrackPlayable, resource: queueTrack };
            }
        }
    }, [queueMode, liveRadio, mixRadio, queueTrack, input]);
}

export type CurrentAudioLoadedPrimaryPreview = TrackPlayablePreviewModel | MixRadioPreviewModel | LiveRadioPlayableModel;
export type CurrentAudioLoadedSecondaryPreview = TrackPlayablePreviewModel | LiveRadioPlayableModel;

export function getCurrentAudioPrimaryResourceFromState(): { resource: CurrentAudioLoadedPrimaryPreview | null; type: ResourceContentType } {
    const input = store.getState().player.input;
    const queueMode = store.getState().queue.mode;

    const queueTrack = getQueueCurrentLaneTrackFromState();
    const mixRadio = getQueueMixRadioFromState();
    const liveRadio = getCurrentLiveRadioFromState();

    if (input === AudioInputType.LiveRadio) return { type: ContentType.LiveRadioPlayable, resource: liveRadio };
    switch (queueMode) {
        case QueueMode.MixRadio:
            return { type: ContentType.MixRadio, resource: mixRadio };
        case QueueMode.Default: {
            return { type: ContentType.TrackPlayable, resource: queueTrack };
        }
    }
}

export function useCurrentAudioSecondaryResourceFromState(): { resource: CurrentAudioLoadedSecondaryPreview | null; resourceType: ResourceContentType } {
    const input = useSelector((root: RootModel) => root.player.input);
    const liveRadio = useCurrentLiveRadioFromState();
    const queueTrack = useQueueCurrentLaneTrackFromState();

    return useMemo(() => getCurrentAudioSecondaryResource(input, liveRadio, queueTrack), [input, liveRadio, queueTrack]);
}

function getCurrentAudioSecondaryResource(
    input: AudioInputType,
    liveRadio: LiveRadioPlayableModel | null,
    queueTrack: TrackPlayablePreviewModel | null
): { resource: CurrentAudioLoadedSecondaryPreview | null; resourceType: ResourceContentType } {
    if (input === AudioInputType.LiveRadio)
        return {
            resource: liveRadio,
            resourceType: ContentType.LiveRadioPlayable
        };
    return {
        resource: queueTrack,
        resourceType: ContentType.TrackPlayable
    };
}

export function getCurrentAudioSecondaryResourceFromState(): { resource: CurrentAudioLoadedSecondaryPreview | null; resourceType: ResourceContentType } {
    const input = store.getState().player.input;
    const liveRadio = getCurrentLiveRadioFromState();
    const queueTrack = getQueueCurrentLaneTrackFromState();
    return getCurrentAudioSecondaryResource(input, liveRadio, queueTrack);
}

export function useQueueCurrentLaneParent(currentLane: QueueTrackModel | null): TrackParentModel | null {
    const trackParents = useSelector((root: RootModel) => root.queue.trackParents);
    return useMemo(() => (currentLane ? getTrackParent(currentLane.parentId, trackParents) : null), [currentLane, trackParents]);
}
