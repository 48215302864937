import { onNextAuthenticated } from "../castEvents/session/onCustomCastMessageAuthenticate";
import { addCastSession, hasCastSession } from "../setup";
import { resetOperation } from "./operation";
import { resetRemoteCastQueue } from "./remoteCastQueue";
import { setPlayerModeChromecast } from "./setPlayerMode";
import { syncCastFromLocalChange } from "./sync";
import { store } from "global";
import { log, LogTag } from "services/logger";
import { LoginState } from "models/app";
import type { AudioContextModel } from "models/app/player/AudioContext";

export async function castSessionStarted(context: AudioContextModel) {
    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1204", msg: `castSessionStarted` });

    if (hasCastSession()) {
        log.error({ tags: [LogTag.Chromecast], code: "web-230201-1600", msg: "there is already a session" });
        return;
    }

    setPlayerModeChromecast(context);
    resetOperation();
    resetRemoteCastQueue();

    await addCastSession();

    const loggedIn = store.getState().user.state === LoginState.LoggedIn;
    if (loggedIn) {
        const auth = await onNextAuthenticated(true);
        log.info({ tags: [LogTag.Chromecast], code: "web-230106-1610", msg: `castSessionStarted > auth: ${auth}` });
    }

    const sync = await syncCastFromLocalChange(context);
    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1529", msg: `castSessionStarted > sync: ${sync}` });
}
