import { calculatePreloadState, getLoadedDuration, updatePreloadState } from "../flow";
import { inSkipDebounce } from "../skipDebounce/skipDebounce";
import { setTrackFullyCached, setTrackCacheBegin } from "./hlsCachePool";
import { store } from "global";
import { CacheType, headerNames } from "shared/services/serviceWorkerConfig";
import { playerConfig } from "global/constants/player";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { serviceWorkerRegistered } from "services/serviceWorker/initServiceWorker";
import { StreamUrlType } from "models/app/player/input";
import type { BrowserAudioItemModel, HlsPreloadAudioChunkModel, HlsPreloadAudioEncryptionKeyModel, HlsPreloadManifestModel, PreloadFetchResult } from "models/app/player/output";
import { BrowserAudioLoadState, BrowserAudioItemPreloadSize, PreloadFetchResultStatus } from "models/app/player/output";

export function getHlsPreloadState(browserAudio: BrowserAudioItemModel): BrowserAudioLoadState | null {
    if (!isPreloadingEnabled()) return null;

    if (browserAudio.disposed) {
        log.error({ code: "web-211213-1249", msg: "item disposed" });
        return null;
    }

    if (browserAudio.url == null) {
        log.error({ code: "web-211215-1547", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    if (browserAudio.preload.size === BrowserAudioItemPreloadSize.Full) {
        if (browserAudio.preload.hlsPreload?.fullyLoaded) return BrowserAudioLoadState.Done;
        else return BrowserAudioLoadState.Buffering;
    }

    const manifest = browserAudio.preload.hlsPreload?.manifest;
    if (manifest == null) return BrowserAudioLoadState.Buffering;

    const cursor = browserAudio.element.currentTime;
    const loaded = getLoadedHlsDuration(manifest, cursor);
    if (loaded == null) {
        log.error({ code: "web-210413-1225", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    const duration = manifest.duration;

    const newState = calculatePreloadState(browserAudio, duration, loaded);
    return newState;
}

function getLoadedHlsDuration(manifest: HlsPreloadManifestModel, cursor: number): number | null {
    const duration = manifest.duration;
    let chunks = manifest.chunks;
    chunks = chunks.filter((item) => item.loaded);
    const loaded = getLoadedDuration(chunks, duration, cursor);
    return loaded;
}

export const preloadHls = async (browserAudio: BrowserAudioItemModel) => {
    if (!isPreloadingEnabled()) return;
    if (inSkipDebounce) return;
    if (browserAudio.disposed) return;
    if (!browserAudio.loadNow) return;
    if (!browserAudio.url) return;
    if (browserAudio.url.urlType !== StreamUrlType.Hls) return;
    if (!browserAudio.hls) return;
    if (!serviceWorkerRegistered) return;
    if (browserAudio.state !== BrowserAudioLoadState.Buffering) return;

    const preload = browserAudio.preload;
    if (preload.hlsPreload?.status === PreloadFetchResultStatus.UnknownError) return;

    browserAudio.preload.hlsPreload ??= {
        fullyLoaded: false,
        manifest: null,
        lock: false,
        status: PreloadFetchResultStatus.OK
    };

    const hlsPreload = browserAudio.preload.hlsPreload;

    if (hlsPreload.lock) return;
    hlsPreload.lock = true;

    await tryParseAndFetchNextItem(browserAudio);

    if (hlsPreload.status === PreloadFetchResultStatus.NetworkError) {
        updatePreloadState(browserAudio);

        setTimeout(async () => {
            hlsPreload.lock = false;
            updatePreloadState(browserAudio);
        }, playerConfig.hlsRetryTimeoutMs);
    } else {
        hlsPreload.lock = false;
        updatePreloadState(browserAudio);
    }
};

async function tryParseAndFetchNextItem(browserAudio: BrowserAudioItemModel) {
    if (browserAudio.disposed) return;

    const preload = browserAudio.preload.hlsPreload;
    if (preload == null) {
        log.error({ code: "web-220505-1414", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    if (preload.fullyLoaded) return;

    let manifest = preload.manifest;
    if (!manifest) {
        await setTrackCacheBegin(browserAudio);
        const result = await tryParseIndex(browserAudio);

        manifest = result.value ?? null;
        preload.manifest = manifest;
        preload.status = result.status;

        if (!manifest) return;
    }

    const { next, status } = await tryFetchNextItem(browserAudio);
    if (browserAudio.disposed) return;

    preload.status = status;
    preload.fullyLoaded = !next && status === PreloadFetchResultStatus.OK;

    if (preload.fullyLoaded) {
        const index = manifest.indexUrl;
        const chunk = manifest.chunks[manifest.chunks.length - 1]?.url;

        const ok = chunk && (await setTrackFullyCached(browserAudio, [index, chunk]));
        if (!ok) {
            preload.status = PreloadFetchResultStatus.UnknownError;
            preload.fullyLoaded = false;
        }
    }
}

async function tryFetchNextItem(browserAudio: BrowserAudioItemModel): Promise<{ next: boolean; status: PreloadFetchResultStatus }> {
    const chunk = getNextItem(browserAudio);
    if (!chunk) return { next: false, status: PreloadFetchResultStatus.OK };

    const status = await fetchNextItem(chunk);

    return { next: true, status };
}

async function fetchNextItem(chunk: HlsPreloadAudioChunkModel | HlsPreloadAudioEncryptionKeyModel): Promise<PreloadFetchResultStatus> {
    const headers = addHeaders(true);
    try {
        const ok = (await fetch(chunk.url, { headers })).ok;
        chunk.loaded = ok;

        if (ok) return PreloadFetchResultStatus.OK;
        return PreloadFetchResultStatus.UnknownError;
    } catch (e) {
        return PreloadFetchResultStatus.NetworkError;
    }
}

function getNextItem(browserAudio: BrowserAudioItemModel): HlsPreloadAudioChunkModel | HlsPreloadAudioEncryptionKeyModel | null {
    const manifest = browserAudio.preload.hlsPreload?.manifest ?? null;
    if (manifest == null) {
        log.error({ code: "web-220505-1414", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    const encryption = manifest.encryption;
    if (encryption == null) {
        log.error({ code: "web-220505-1414", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    if (!encryption.loaded) return encryption;

    const playCursor = browserAudio.element.currentTime;

    let chunks = manifest.chunks ?? null;
    if (chunks == null) {
        log.error({ code: "web-210412-1510", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    chunks = chunks.filter((item) => !item.loaded);
    for (const chunk of chunks) {
        const start = chunk.position;
        const end = chunk.position + chunk.duration;
        if (playCursor >= start && playCursor < end) return chunk;
    }
    for (const chunk of chunks) {
        const start = chunk.position;
        if (playCursor >= start) return chunk;
    }
    return chunks[0] ?? null;
}

function addHeaders(onlyForCache: boolean) {
    const headers: HeadersInit = {};
    headers[headerNames.cacheType] = CacheType.Hls;
    headers[headerNames.hlsCaller] = "PreloadService";
    if (onlyForCache) headers[headerNames.onlyForCache] = "True";

    return headers;
}

async function tryParseIndex(browserAudio: BrowserAudioItemModel): Promise<PreloadFetchResult<HlsPreloadManifestModel>> {
    try {
        return await parseIndex(browserAudio);
    } catch (e) {
        log.error({ code: "web-210914-1405", msg: "error", error: e });
        return { status: PreloadFetchResultStatus.UnknownError };
    }
}

const parseIndex = async (browserAudio: BrowserAudioItemModel): Promise<PreloadFetchResult<HlsPreloadManifestModel>> => {
    const MASTER_PLAYLIST_REGEX = new RegExp(
        [
            /#EXTINF:\s*(\d*(?:\.\d+)?)(?:,(.*)\s+)?/.source, // duration (#EXTINF:<duration>,<title>), group 1 => duration, group 2 => title
            /(?!#) *(\S[\S ]*)/.source, // segment URI, group 3 => the URI (note newline is not eaten)
            /#EXT-X-BYTERANGE:*(.+)/.source, // next segment's byterange, group 4 => range spec (x@y)
            /#EXT-X-PROGRAM-DATE-TIME:(.+)/.source, // next segment's program date/time group 5 => the datetime spec
            /#EXT-X-(KEY):(.+)/.source,
            /#.*/.source // All other non-segment oriented tags will match with all groups empty
        ].join("|"),
        "g"
    );

    const url = browserAudio.url;
    if (url == null) {
        log.error({ code: "web-210412-1314", msg: DefaultLogMessage.UnexpectedNull });
        return { status: PreloadFetchResultStatus.UnknownError };
    }

    if (url.urlType !== StreamUrlType.Hls) {
        log.error({ code: "web-210412-1314", msg: DefaultLogMessage.UnexpectedValue });
        return { status: PreloadFetchResultStatus.UnknownError };
    }

    const baseUrl = getHlsBaseUrl(url.url);
    if (baseUrl == null) {
        log.error({ code: "web-210412-1611", msg: DefaultLogMessage.UnexpectedNull });
        return { status: PreloadFetchResultStatus.UnknownError };
    }

    const headers = addHeaders(false);

    let response: Response;
    try {
        response = await fetch(url.url, { headers });
    } catch {
        return { status: PreloadFetchResultStatus.NetworkError };
    }

    if (!response.ok) {
        return { status: PreloadFetchResultStatus.UnknownError };
    }

    let result: RegExpExecArray | null;
    const text = await response.text();
    let position = 0;
    const chunks: HlsPreloadAudioChunkModel[] = [];
    let chunk: HlsPreloadAudioChunkModel | null = null;
    let encryptionUrl: string | null = null;
    while ((result = MASTER_PLAYLIST_REGEX.exec(text)) != null) {
        const durationStr = result[1];
        const segment = result[3];
        if (result[7]) {
            const encryption = result[7].match(/URI="(\S*)"/);
            if (encryption) encryptionUrl = encryption[1];
        }

        if (durationStr != null) {
            const duration = Number(durationStr);
            chunk = {
                duration,
                position,
                url: "",
                loaded: false
            };
            chunks.push(chunk);
            position += duration;
        } else if (segment != null) {
            if (!chunk) {
                log.error({ code: "web-210412-1451", msg: DefaultLogMessage.UnexpectedNull });
                return { status: PreloadFetchResultStatus.UnknownError };
            }
            chunk.url = baseUrl + segment;
        }
    }

    const duration = position;
    if (encryptionUrl == null) {
        log.error({ code: "web-210419-1247", msg: DefaultLogMessage.UnexpectedNull });
        return { status: PreloadFetchResultStatus.UnknownError };
    }

    const encryption: HlsPreloadAudioEncryptionKeyModel = {
        url: baseUrl + encryptionUrl,
        loaded: false
    };

    const item: HlsPreloadManifestModel = { duration, indexUrl: url.url, encryption, chunks };
    return { value: item, status: PreloadFetchResultStatus.OK };
};

function getHlsBaseUrl(indexUrl: string) {
    const baseUrl = indexUrl.split("index.m3u8")[0];
    if (baseUrl == null) {
        log.error({ code: "web-210412-1608", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    return baseUrl;
}

function isPreloadingEnabled() {
    return store.getState().controlPanel.enableAudioPreload;
}
