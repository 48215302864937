import { WebAppBrand } from "shared/models";
import { environment } from "services/environment";
import { DefaultLogMessage, log } from "services/logger";
import { getLink } from "services/resource";
import type { ContextResourceModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";
import { openToast } from "components/organisms/toast";
import { ShareUrlCopySuccessToast, ShareUrlCopyFailedToast } from "components/organisms/toast/toasts";

export async function copySharingLinkToClipboard(resource: ContextResourceModel) {
    const link = getShareUrlWithFallback(resource);
    if (!link) {
        log.error({ code: "web-220224-1322", msg: "no share url", data: { resource } });
        return;
    }

    try {
        await navigator.clipboard.writeText(link);
        openToast(ShareUrlCopySuccessToast());
    } catch (e) {
        log.error({ code: "web-220224-1423", msg: "link could not be copied", data: { resource }, error: e });
        openToast(ShareUrlCopyFailedToast());
    }
}

export function getShareOrigin(): string {
    switch (environment.webAppBrand) {
        case WebAppBrand.YouSee:
            return window.location.origin; // "https://musik.yousee.dk";
        case WebAppBrand.Telmore:
            return window.location.origin; // "https://musik.telmore.dk";
    }
}

export function getShareUrlWithFallback(resource: ContextResourceModel): string | null {
    let shareUrl = getShareUrl(resource);
    if (shareUrl != null) return shareUrl;

    shareUrl = getLink(resource.contentType, resource)?.url ?? null;
    if (shareUrl != null) return getShareOrigin() + shareUrl;

    return null;
}

export function getShareUrl(resource: ContextResourceModel): string | null {
    const type = resource.contentType;
    switch (type) {
        case ContentType.Album:
            return resource.sharingUrl;
        case ContentType.Artist:
            return resource.sharingUrl;
        case ContentType.LiveRadioPlayable:
            return resource.liveRadio.sharingUrl;
        case ContentType.MixRadio:
            return resource.sharingUrl;
        case ContentType.Playlist:
            return resource.sharingUrl;
        case ContentType.TrackPlayable:
            return resource.track.sharingUrl;
        default:
            log.error({ code: "web-220224-1332", msg: DefaultLogMessage.NotImplemented, data: { type } });
            return null;
    }
    type;
}
