import { h } from "preact";
import { Button, ButtonDesign } from "../Button";
import { useTranslations } from "global/config";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ArtistRadioModel } from "models/domain/ArtistRadioModel";
import { ContentType } from "models/ModelType";
import { useLinkContext } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";

interface Props {
    radio: ArtistRadioModel | null;
    context: PreviewContextModel;
}

export const ButtonGoToArtistRadio = ({ radio, context }: Props) => {
    const link = useLinkContext(ContentType.ArtistRadio, radio, context);
    const translations = useTranslations();
    return (
        <Button link={link} icon={IconName.Sound} design={ButtonDesign.PrimaryBig}>
            {translations.ContextOptionGoToArtistRadioAction}
        </Button>
    );
};
