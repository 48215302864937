import { useLayoutEffect, useState } from "preact/hooks";

export enum ScrollAxis {
    x = "x",
    y = "y"
}

export const useScrollPosition = (element: HTMLElement | null, axis: ScrollAxis) => {
    const [pos, setPos] = useState(0);
    useLayoutEffect(() => {
        if (!element) return;
        const change = () => {
            setPos(axis === ScrollAxis.x ? element.scrollLeft : element.scrollTop);
        };
        element.addEventListener("scroll", change, { passive: true });
        return () => {
            element.removeEventListener("scroll", change);
        };
    }, [element, axis]);

    return pos;
};
