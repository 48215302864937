import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { dispatch } from "global";
import { SET_MAXIPLAYER_OPEN } from "global/actions";
import { useTranslations } from "global/config";
import { MobileMaxiPlayerOpen } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ArtistLinkModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { useLinkContext } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";

interface Props {
    artist: ArtistLinkModel | null;
    showIcon: boolean;
    context: PreviewContextModel;
}

export const GoToArtistLink = ({ showIcon, artist, context }: Props) => {
    const link = useLinkContext(ContentType.Artist, artist, context);
    const translations = useTranslations();
    const click = useCallback(() => {
        dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });
    }, []);
    return (
        <Link closeOnClick disabled={!artist} icon={showIcon ? IconName.Artist : undefined} onClick={click} link={link}>
            {translations.ContextOptionGoToArtist}
        </Link>
    );
};
