import {
    playChangeFromOutput,
    updateLastPosition,
    setAndPlay,
    onControllerOrOutputChange,
    updateAudiosFromPlayQueue,
    loginChange,
    updateAudioEndReason,
    updateAudiosEndReason
} from "./service/actions";
import { onAudioError } from "./service/actions/error";
import { updateAudiosFromLiveRadio } from "./service/actions/updateAudiosFromLiveRadio";
import { isInputPlayQueueFromState } from "./service/helpers";
import { isControllerLocalFromState } from "../controllers/service/helpers";
import {
    AUDIO_INPUT_AUDIOS_CHANGE,
    AUDIO_INPUT_AUDIO_PLAY_CHANGE,
    AUDIO_INPUT_SET_AND_PLAY,
    AUDIO_OUTPUT_AUDIO_FAILED,
    AUDIO_OUTPUT_AUDIO_PLAY_CHANGE,
    LIVERADIO_UPDATE,
    PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE,
    PLAYQUEUE_ENDLESSPLAY_TOGGLE,
    PLAYQUEUE_UPDATE,
    USER_LOGGED_IN,
    USER_LOGGED_OUT
} from "global/actions";
import { messageBus } from "global/config/configureStore";
import { log } from "services/logger";
import { AudioContextAction } from "models/app/player/AudioContext";

export const initInputAudioService = () => {
    try {
        if (isInputPlayQueueFromState()) {
            updateAudiosFromPlayQueue(null, { action: AudioContextAction.AppStart, trace: null });
        }
    } catch (e) {
        log.error({ code: "web-220221-1331", msg: "error in update playqueue from audios in initPlayQueueAudioInputService", error: e });
    }

    messageBus.subscribeEvery(AUDIO_OUTPUT_AUDIO_PLAY_CHANGE, (msg) => {
        const { audio, allowPlayChange, playState, position, context } = msg.payload;
        playChangeFromOutput(audio, allowPlayChange, playState, position ?? null, context);
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_PLAY_CHANGE, async (msg) => {
        const { audio, playStateBefore, context } = msg.payload;

        updateLastPosition();
        updateAudioEndReason(audio, audio.playState, playStateBefore, context);
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIOS_CHANGE, async (msg) => {
        updateLastPosition();
        updateAudiosEndReason(msg.payload.audios, msg.payload.old, msg.payload.context);
    });

    messageBus.subscribeEvery(AUDIO_INPUT_SET_AND_PLAY, async (msg) => {
        setAndPlay(msg.payload);
    });

    messageBus.subscribeEvery(PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE, (msg) => {
        onControllerOrOutputChange(msg.payload.context);
    });

    messageBus.subscribeEvery(AUDIO_OUTPUT_AUDIO_FAILED, async ({ payload }) => {
        if (!isControllerLocalFromState()) return; // CC TODO how to we handle play fail in CC?
        onAudioError(payload.code, payload.audio, payload.context, payload.message, payload.trackUrlCacheError, payload.trackUrlFetchError);
    });

    messageBus.subscribeEvery(LIVERADIO_UPDATE, async (msg) => {
        updateAudiosFromLiveRadio(msg.payload.analytics?.queueAddData ?? null, msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_UPDATE, async (msg) => {
        updateAudiosFromPlayQueue(msg.payload.queueAdded, msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_ENDLESSPLAY_TOGGLE, () => {
        updateAudiosFromPlayQueue(null, { action: AudioContextAction.EndlessPlayUpdate, trace: null });
    });

    messageBus.subscribeEvery(USER_LOGGED_IN, async () => {
        // if (!isControllerLocalFromState()) return;
        await messageBus.sync();

        loginChange({ action: AudioContextAction.UserLogIn, trace: null });
    });

    messageBus.subscribeEvery(USER_LOGGED_OUT, async () => {
        // if (!isControllerLocalFromState()) return;
        await messageBus.sync();

        loginChange({ action: AudioContextAction.UserLogOut, trace: null });
    });
};
