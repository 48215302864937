import { hasCastSession } from "../../setup";
import { log, LogTag } from "services/logger";

export async function requestCastSession(): Promise<boolean> {
    const session = hasCastSession();
    if (session) {
        log.error({ code: "web-230109-1159", msg: "requesting session while existing session" });
        return false;
    }

    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1200", msg: `requestCastSession...` });

    let error: chrome.cast.ErrorCode | undefined;
    try {
        error = await cast.framework.CastContext.getInstance().requestSession();
    } catch (e) {
        log.warn({ tags: [LogTag.Chromecast], code: "web-230106-1249", msg: `...requestCastSession threw error`, error: e });
        return false;
    }
    if (error) log.warn({ tags: [LogTag.Chromecast], code: "web-230106-1250", msg: `...requestCastSession, error: ${error}` });

    return error === undefined;
}
