import { h } from "preact";
import { useRef } from "preact/hooks";
import "./ArtistRadioPreviewSingle.scss";
import { PreviewDisplayType } from "../PreviewDisplayType";
import { PreviewTexts } from "../PreviewLinks";
import { PreviewSingleTestLocator } from "global/constants";
import type { SectionContextModel } from "models/app/resourceContextModel";
import type { ArtistRadioPreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { usePreviewContext, useResizeObserver } from "components/shared/hooks";
import { ButtonGoToArtistRadio } from "components/atoms/controls/button/buttons";

interface ArtistRadioPreviewSingleProps {
    children: ArtistRadioPreviewModel | null;
    section: SectionContextModel;
}

export const ArtistRadioPreviewSingle = ({ children, section }: ArtistRadioPreviewSingleProps) => {
    const preview = usePreviewContext(ContentType.ArtistRadio, children, section, 0);

    const button = <ButtonGoToArtistRadio radio={children} context={preview} />;
    const links = PreviewTexts({
        resourceType: ContentType.ArtistRadio,
        resource: children,
        context: preview,
        displayType: PreviewDisplayType.ArtistRadio
    });

    const ref = useRef<HTMLDivElement | null>(null);
    const width = useResizeObserver(ref.current)?.width ?? 0;
    const size = width >= 520 ? "big" : "small";

    return (
        <div ref={ref} className={`artistRadioPreviewSingle JJp --size-${size}`} data-cy={PreviewSingleTestLocator(ContentType.ArtistRadio)}>
            <figure>
                <figcaption>{links}</figcaption>
                {button}
            </figure>
        </div>
    );
};
