import { useEffect, useState } from "preact/hooks";
import { ScrollerSize, ScrollerAlign } from "../../options";
import { getActiveItemIndex } from "./getActiveItemIndex";
import { useScrollPosition, ScrollAxis } from "components/shared/hooks";

export const useScrollIndex = (element: HTMLElement | null, size: ScrollerSize | number, align: ScrollerAlign, selectedIndex: number, ajust: number, gap: number) => {
    const scrollLeft = useScrollPosition(element, ScrollAxis.x);

    const [scrollIndex, setScrollIndex] = useState(selectedIndex);
    const [scrollIndexDecimal, setScrollIndexDecimal] = useState(selectedIndex);

    useEffect(() => {
        if (!element) return;

        const indexDecimal = getActiveItemIndex(element, size, align, ajust, gap);
        const index = Math.round(indexDecimal);

        setScrollIndex(index);
        setScrollIndexDecimal(indexDecimal);
    }, [element, scrollLeft, size, align, ajust, gap]);

    return { scrollIndex, scrollIndexDecimal };
};
