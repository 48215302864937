export enum CacheType {
    GraphQL = "GraphQL",
    Hls = "Hls",
    Navigate = "Navigate",
    External = "External",
    DevTool = "DevTool",
    Default = "Default",
    DelegatedLogin = "DelegatedLogin",
    GraphQLTrackStream = "GraphQLTrackStream"
}

export const serviceWorkerConfig = {
    enabled: true,
    cacheNameGraphql: "graphql-v9",
    cacheNameHls: "hls-v9",
    cacheNameInstallation: "installation-v9",
    cacheNameNavigate: "navigate-v9",
    maxCacheEntriesDefault: 1000,
    maxCacheEntriesGraphql: 1000,
    maxCacheEntriesHls: 100 * 60, // 100 songs x 240 sex / 4 secs per hls fragment
    maxCacheEntriesNavigate: 1000,
    enableInstallationCacheFirst: false
};

export const headerNames = {
    acceptLanguage: "Accept-Language",
    appVersion: "X-App-Version", // consider renaming to X-Client-Version, to match X-Client-Id
    authorization: "authorization",
    cacheType: "X-Cache-Type",
    contentType: "Content-Type",
    correlationId: "X-Correlation-Id",
    hlsCaller: "X-Hls-Caller",
    installationId: "X-Client-Id",
    onlyForCache: "X-Only-For-Cache",
    operationName: "X-Operation-Name",
    operationType: "X-Operation-Type",
    platform: "X-Platform",
    requestTime: "X-Request-Time",
    user: "X-User",
    userAgent: "User-Agent"
};

export const cacheNames = [
    serviceWorkerConfig.cacheNameGraphql,
    serviceWorkerConfig.cacheNameHls,
    serviceWorkerConfig.cacheNameInstallation,
    serviceWorkerConfig.cacheNameNavigate
];

export const appReloadServiceConfig = {
    pollUpdateIntervalMs: 5 * 60 * 1000, // 5 minutes
    reloadDelayMs: 24 * 60 * 60 * 1000, // 24 hours
    pollUpdateEnabled: true,
    enableAutoReload: true
};
