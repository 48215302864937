import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { Button } from "components/atoms/controls/button";
import { SearchTextInput } from "components/atoms/controls/textInput/inputs";
import { IconName } from "components/atoms/icon";

interface Props {
    initialValue?: string;
    placeholder?: string;
    onChange: (value: string) => void;
    onExpandedChanged?: (isExpanded: boolean) => void;
}

export const FilterUi = ({ initialValue, placeholder, onChange, onExpandedChanged }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentValue, setCurrentValue] = useState(initialValue ?? "");

    const innerOnChange = useCallback(
        (text: string) => {
            setCurrentValue(text);
            if (onChange) {
                onChange(text);
            }
        },
        [setCurrentValue, onChange]
    );

    const keyHandler = useCallback(
        (e: h.JSX.TargetedKeyboardEvent<HTMLInputElement>) => {
            if (e.key == "Escape" && !currentValue) {
                innerOnChange("");
                setIsExpanded(false);
                if (onExpandedChanged) {
                    onExpandedChanged(false);
                }
            }
        },
        [innerOnChange, currentValue, setIsExpanded, onExpandedChanged]
    );

    const clickHandler = useCallback(() => {
        setIsExpanded(true);
        if (onExpandedChanged) {
            onExpandedChanged(true);
        }
    }, [setIsExpanded, onExpandedChanged]);

    const blurHandler = useCallback(() => {
        if (!currentValue) {
            setIsExpanded(false);
            if (onExpandedChanged) {
                onExpandedChanged(false);
            }
        }
        return true;
    }, [setIsExpanded, onExpandedChanged, currentValue]);

    return isExpanded ? (
        <SearchTextInput
            placeholder={placeholder}
            onChange={innerOnChange}
            value={currentValue}
            onKeyDown={keyHandler}
            className="filter grow maxwidth"
            autoFocus
            onBlur={blurHandler}
        />
    ) : (
        <Button className={"filter"} icon={IconName.Search} onClick={clickHandler} />
    );
};
