import { dispatch } from "global";
import { PLAYER_SET_PLAY } from "global/actions";
import { PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { getAudioPropertiesFromState } from "components/shared/hooks";

export const togglePlay = (context: AudioContextModel) => {
    const { playState } = getAudioPropertiesFromState();
    const play = playState === PlayState.Paused || playState === PlayState.Stopped || playState === PlayState.Error;
    dispatch({ type: PLAYER_SET_PLAY, payload: { play, context } });
};
