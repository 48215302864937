import { h } from "preact";
import "./Switch.scss";

interface Props {
    checked: boolean;
    disabled?: boolean;
    onClick: () => void;
    testLocator?: string;
}
export const Switch = ({ checked, onClick, disabled, testLocator }: Props) => {
    return (
        <label className="switch k34">
            <input disabled={disabled} type="checkbox" checked={checked} onChange={onClick} data-cy={testLocator} />
            <span className="slider">
                <span className="thumb" />
            </span>
        </label>
    );
};
