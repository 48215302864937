import type { AlbumPreviewModel } from "./AlbumModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel, createTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { ResourceModelLinkInterface } from "./ResourceModel";
import type { TrackPreviewModel } from "./TrackModel";
import type { TrackPlayablePreviewModel } from "./TrackPlayableModel";
import { log } from "services/logger/initLoggerService";
import type {
    ChartItemsPaginationQuery,
    ChartPageFragment,
    ChartPageFragment_AlbumsChart,
    ChartPageFragment_TracksChart,
    ChartPreviewFragment,
    TrackChartPlayQuery
} from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type ChartPreviewData = ChartPreviewFragment;
type ChartPageData = ChartPageFragment;
type ChartItemsData = NonNullable<ChartItemsPaginationQuery["charts"]["chart"]>;

type AlbumChartPageData = ChartPageFragment_AlbumsChart;
type TrackChartPlayData = TrackChartPlayQuery["charts"]["chart"];
type TrackChartPageData = ChartPageFragment_TracksChart;

export type ChartModel = ChartPreviewModel;
export type ChartPreviewModel = AlbumChartPreviewModel | TrackChartPreviewModel | ChartPlayModel;
export type ChartPlayModel = TrackChartPlayModel | ChartPageModel;
export type ChartPageModel = AlbumChartPageModel | TrackChartPageModel;

export type AlbumChartPreviewModel = ({ type: DomainModelType.Preview; contentType: ContentType.AlbumChart } & ChartPreview) | AlbumChartPageModel;
export type AlbumChartPageModel = { type: DomainModelType.Page; contentType: ContentType.AlbumChart } & AlbumChartPage;

export type TrackChartPreviewModel = ({ type: DomainModelType.Preview; contentType: ContentType.TrackChart } & ChartPreview) | TrackChartPlayModel;
export type TrackChartPlayModel = ({ type: DomainModelType.Play; contentType: ContentType.TrackChart } & TrackChartPlay) | TrackChartPageModel;
export type TrackChartPageModel = { type: DomainModelType.Page; contentType: ContentType.TrackChart } & TrackChartPage;

export type ChartItemPreviewModel = AlbumPreviewModel | TrackPreviewModel;
export type ChartItemPlayablePreviewModel = AlbumPreviewModel | TrackPlayablePreviewModel;

interface Chart extends ResourceModelLinkInterface, PlayQueuePlayableModelInterface {
    readonly title: string;
}

type ChartPreview = Chart;

interface TrackChartPlay extends ChartPreview {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

interface ChartPage extends ChartPreview {
    readonly description: string | null;
}

interface TrackChartPage extends ChartPage {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

interface AlbumChartPage extends ChartPage {
    readonly albums: ConnectionModel<AlbumPreviewModel> | null;
}

function createChart(data: ChartPreviewData): Chart {
    return {
        cover: data.cover ?? null,
        graphType: data.__typename,
        id: data.id,
        title: data.title,
        trackCount: null
    };
}

function createAlbumChartPreviewModel(data: ChartPreviewData): AlbumChartPreviewModel {
    return {
        ...createChart(data),
        type: DomainModelType.Preview,
        contentType: ContentType.AlbumChart
    };
}

function createAlbumChartPageModel(data: AlbumChartPageData): AlbumChartPageModel {
    return {
        ...createChart(data),
        type: DomainModelType.Page,
        contentType: ContentType.AlbumChart,
        description: data.description ?? null,
        albums: data.albums ? createAlbumPreviewConnectionModel(data.albums) : null
    };
}

function createTrackChartPreviewModel(data: ChartPreviewData): TrackChartPreviewModel {
    return {
        ...createChart(data),
        type: DomainModelType.Preview,
        contentType: ContentType.TrackChart
    };
}

export function createTrackChartPlayModel(data: TrackChartPlayData): TrackChartPlayModel | null {
    if (data?.__typename !== "TracksChart") {
        log.error({ code: "web-210505-1204", msg: "wrong type", data: { type: data?.__typename } });
        return null;
    }
    return {
        ...createChart(data),
        type: DomainModelType.Play,
        contentType: ContentType.TrackChart,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0) : null
    };
}

export function createTrackChartPageModel(data: TrackChartPageData): TrackChartPageModel {
    return {
        ...createChart(data),
        type: DomainModelType.Page,
        contentType: ContentType.TrackChart,
        description: data.description ?? null,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0) : null
    };
}

export function createChartPreviewModel(data: ChartPreviewData): ChartPreviewModel {
    switch (data.__typename) {
        case "AlbumsChart":
            return createAlbumChartPreviewModel(data);
        case "TracksChart":
            return createTrackChartPreviewModel(data);
    }
}

export function createChartPageModel(data: ChartPageData): ChartPageModel {
    switch (data.__typename) {
        case "AlbumsChart":
            return createAlbumChartPageModel(data);
        case "TracksChart":
            return createTrackChartPageModel(data);
    }
}

export function createChartItemPreviewConnectionModel(data: ChartItemsData, index: number): ConnectionModel<ChartItemPreviewModel> {
    switch (data.__typename) {
        case "AlbumsChart":
            return createAlbumPreviewConnectionModel(data.albums);
        case "TracksChart":
            return createTrackPreviewConnectionModel(data.tracks, index);
    }
}

export function getChartModelItems(chart: ChartPageModel): ConnectionModel<AlbumPreviewModel | TrackPreviewModel> | null {
    switch (chart.contentType) {
        case ContentType.AlbumChart:
            return chart.albums;
        case ContentType.TrackChart:
            return chart.tracks;
    }
}
