import { h } from "preact";
import { PreviewDisplayType } from ".";
import { ResourceTextDesign } from "../resource";
import { ResourceLink } from "../resource/ResourceLink";
import { ResourceText } from "../resource/ResourceText";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { LocationContentType, ResourceModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { useLinkContextsFromPreview } from "components/shared/hooks";

export enum PreviewLinksDesign {
    Big,
    Small
}

interface PreviewLinksProps {
    resourceType: LocationContentType;
    resource: ResourceModel | null;
    context: PreviewContextModel;
    displayType: PreviewDisplayType;
    onNavigate?: () => void;
    disabled?: boolean;
}

export const PreviewLinks = ({ resource, context, displayType, onNavigate, disabled }: PreviewLinksProps) => {
    const primaryTextDesign = getPrimaryTextDesign(context, displayType);
    const secondaryTextDesign = getSecondaryTextDesign(displayType, resource);
    const links = useLinkContextsFromPreview(context, displayType);

    return links.map((group, groupIndex) => {
        const design = groupIndex == 0 ? primaryTextDesign : secondaryTextDesign;
        const nobreak = group.length > 1;
        return (
            <ResourceLink
                key={groupIndex}
                disabled={disabled}
                links={group}
                testLocatorName={groupIndex}
                onNavigate={onNavigate}
                design={design}
                className={nobreak ? "--nobreak" : ""}
            />
        );
    });
};

export const PreviewTexts = ({ context, resource, displayType }: PreviewLinksProps) => {
    const primaryTextDesign = getPrimaryTextDesign(context, displayType);
    const secondaryTextDesign = getSecondaryTextDesign(displayType, resource);
    const links = useLinkContextsFromPreview(context, displayType);

    return links.map((group, groupIndex) => {
        const design = groupIndex === 0 ? primaryTextDesign : secondaryTextDesign;
        return <ResourceText key={groupIndex} links={group} design={design} />;
    });
};

function getPrimaryTextDesign(
    preview: PreviewContextModel,
    displayType: PreviewDisplayType
): ResourceTextDesign.PrimarySmall | ResourceTextDesign.PrimarySmall2Lines | ResourceTextDesign.PrimaryBig {
    switch (displayType) {
        case PreviewDisplayType.ArtistRadio: {
            return ResourceTextDesign.PrimaryBig;
        }
        case PreviewDisplayType.Single: {
            return ResourceTextDesign.PrimarySmall;
        }
        default:
            if (preview.resource?.contentType === ContentType.Notification /*&& preview.resource.content != null*/) return ResourceTextDesign.PrimarySmall2Lines;
            return ResourceTextDesign.PrimarySmall;
    }
}

function getSecondaryTextDesign(displayType: PreviewDisplayType, resource: ResourceModel | null): ResourceTextDesign.SecondarySmall | ResourceTextDesign.SecondarySmall2Lines | ResourceTextDesign.SecondaryBig {
    if (resource?.contentType === ContentType.AlbumRecommendation || resource?.contentType === ContentType.TrackRecommendation)
        return ResourceTextDesign.SecondarySmall2Lines;
    switch (displayType) {
        case PreviewDisplayType.ArtistRadio: {
            return ResourceTextDesign.SecondaryBig;
        }
        case PreviewDisplayType.Single: {
            return ResourceTextDesign.SecondarySmall;
        }
        default:
            return ResourceTextDesign.SecondarySmall;
    }
}
