import { castQueueStop } from "../castRequests/media/stop";
import { resetRemoteCastQueue } from "./remoteCastQueue";

export async function stopQueueFromLocal(): Promise<boolean> {
    const send = await castQueueStop();
    if (!send) return false;

    resetRemoteCastQueue();
    return true;
}
