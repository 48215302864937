import { h } from "preact";
import { Modal, modalButtonDismissCancel, modalButtonSubmitAdd, modalButtonSubmitAddAll, modalButtonSubmitAddMissing, openModal2 } from "..";
import { useTranslations } from "global/config";
import { DuplicatesHandling } from "generated/graphql-types";

export async function showAddDuplicatesModal(count: number, isCollection: boolean, isContainedCollection: boolean) {
    return openModal2(<AddDuplicatesModal count={count} isCollection={isCollection} isContainedCollection={isContainedCollection} />);
}

function AddDuplicatesModal(props: { count: number, isCollection: boolean, isContainedCollection: boolean }) {
    const translations = useTranslations();
    const text =
        props.isContainedCollection
            ? translations["MyMusicDuplicateExplanationTextAllMultipleTracks"]
            : !props.isCollection
                ? translations["MyMusicDuplicateExplanationTextOneTrack"]
                : translations["MyMusicDuplicateExplanationTextMultipleTracks"].replace("${DuplicateTracks}", props.count.toString());
    const buttons = !props.isCollection || props.isContainedCollection
        ? [modalButtonDismissCancel(), modalButtonSubmitAdd(DuplicatesHandling.AllowDuplicates)]
        : [modalButtonDismissCancel(), modalButtonSubmitAddAll(DuplicatesHandling.AllowDuplicates), modalButtonSubmitAddMissing(DuplicatesHandling.SkipDuplicates)];
    return (
        <Modal
            title={translations.MyMusicDuplicateTrackPrompTitle}
            text={text}
            buttons={buttons}
        />
    );
}
