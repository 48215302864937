import { handleKeyPress, KeyCode } from "./handleKeyPress";
import { dispatch } from "global";
import { PLAYER_SET_MUTE, PLAYER_TOGGLE_PLAY, TOGGLE_MAXIPLAYER_QUEUE_OPEN } from "global/actions";
import { toggleDesktopPlayQueue } from "services/player/inputs/inputs/playQueue/actions/toggleQueueView";
import { AudioContextAction } from "models/app/player/AudioContext";
import { getAppMediaSize, MediaSize } from "components/shared/hooks";

export function initShortcutService() {
    handleKeyPress([KeyCode.Space, KeyCode.KeyQ, KeyCode.KeyM], (keyCode: KeyCode) => {
        switch (keyCode) {
            case KeyCode.Space: {
                dispatch({ type: PLAYER_TOGGLE_PLAY, payload: { context: { action: AudioContextAction.UserKeybordKeySpace, trace: null } } });
                return;
            }
            case KeyCode.KeyM: {
                dispatch({ type: PLAYER_SET_MUTE, payload: {} });
                return;
            }
            case KeyCode.KeyQ: {
                const media = getAppMediaSize();

                if (media === MediaSize.Desktop) toggleDesktopPlayQueue();
                else dispatch({ type: TOGGLE_MAXIPLAYER_QUEUE_OPEN });

                return;
            }
        }
    });
}
