import { useEffect, useState } from "preact/hooks";
import type { DomainQueryResult } from "./backendService";
import { useQueryDomainModel, queryDomainModel } from "./backendService";
import { mutateDomainModel, mutateDomainModelWithErrors } from "./mutate";
import { WebAppBrand } from "shared/models";
import { Feature, isFeatureEnabled } from "global/config";
import { environment } from "services/environment";
import { log } from "services/logger";
import {
    AddAlbumToFavoritesDocument,
    AddAlbumToPlaylistDocument,
    AddArtistToFavoritesDocument,
    AddPlaylistToFavoritesDocument,
    AddPlaylistToPlaylistDocument,
    AddTrackToFavoritesDocument,
    AddTracksToPlaylistDocument,
    AlbumArtistAlbumsByTrackSectionDocument,
    AlbumArtistAlbumsPageDocument,
    AlbumArtistAlbumsPaginationDocument,
    AlbumArtistAlbumsSectionDocument,
    AlbumHeaderSectionByTrackDocument,
    AlbumHeaderSectionDocument,
    AlbumPlayDocument,
    AlbumPreviewDocument,
    AlbumTracksPaginationDocument,
    AlbumTracksSectionByTrackDocument,
    AlbumTracksSectionDocument,
    ArtistAlbumsPageDocument,
    ArtistAlbumsPaginationDocument,
    ArtistAlbumsSectionDocument,
    ArtistAppearsOnAlbumsPageDocument,
    ArtistAppearsOnAlbumsPaginationDocument,
    ArtistAppearsOnAlbumsSectionDocument,
    ArtistPageHeaderSectionDocument,
    ArtistPlayDocument,
    ArtistPopularTracksSectionDocument,
    ArtistPreviewDocument,
    ArtistRadioPageDocument,
    ArtistSimilarArtistsPageDocument,
    ArtistSimilarArtistsPaginationDocument,
    ArtistSimilarArtistsSectionDocument,
    ArtistSinglesPageDocument,
    ArtistSinglesPaginationDocument,
    ArtistSinglesSectionDocument,
    ArtistTracksPageDocument,
    ArtistTracksPaginationDocument,
    ChartItemsPaginationDocument,
    ChartPageDocument,
    ChartPreviewDocument,
    ConcurrencyCanPlayDocument,
    ConcurrencyCreateTicketDocument,
    CreatePlaylistDocument,
    DeletePlaylistDocument,
    DiscoverRecommendationsPageDocument,
    DiscoverRecommendationsPaginationDocument,
    EndlessPlayDocument,
    FavoriteAlbumsDocument,
    FavoriteArtistsDocument,
    FavoriteTracksDocument,
    FavoriteTracksSorting,
    HistoryRecommendationsPageDocument,
    HistoryRecommendationsPaginationDocument,
    IsFavoriteDocument,
    LayoutItemContentPaginationDocument,
    LayoutItemPageDocument,
    LayoutItemPlayDocument,
    LayoutPageDocument,
    LiveRadioCategoryByLiveRadioLiveRadiosPaginationDocument,
    LiveRadioCategoryLiveRadiosPaginationDocument,
    LiveRadioCategoryPageByLiveRadioDocument,
    LiveRadioCategoryPageDocument,
    LiveRadioNowAndRecentlyPlayedDocument,
    LiveRadioPlayDocument,
    MixRadioCategoryPageDocument,
    MixRadioCategoryPaginationDocument,
    MixRadioPlayDocument,
    MixRadioPreviewDocument,
    MixRadioTracksPaginationDocument,
    MixRadiosPageDocument,
    ModifyTracksInPlaylistDocument,
    OwnPlaylistsDocument,
    PlaylistCategoriesPageDocument,
    PlaylistCategoryPageDocument,
    PlaylistCategoryPlaylistsPaginationDocument,
    PlaylistNotificationCheckDocument,
    PlaylistPageDocument,
    PlaylistPlayDocument,
    PlaylistPreviewDocument,
    PlaylistTracksPaginationDocument,
    RecentTracksDocument,
    RecommendationContentPaginationDocument,
    RecommendationPageDocument,
    RecommendationPlayDocument,
    RemoveAlbumFromFavoritesDocument,
    RemoveArtistFromFavoritesDocument,
    RemovePlaylistFromFavoritesDocument,
    RemoveTrackFromFavoritesDocument,
    ReportPlaybackDocument,
    SearchAlbumsPageDocument,
    SearchAlbumsPaginationDocument,
    SearchArtistsPageDocument,
    SearchArtistsPaginationDocument,
    SearchMixedSectionsDocument,
    SearchPlaylistsPageDocument,
    SearchPlaylistsPaginationDocument,
    SearchPlaylistsSectionDocument,
    SearchTracksPageDocument,
    SearchTracksPaginationDocument,
    SendFeedbackDocument,
    TrackChartNotificationCheckDocument,
    TrackChartPlayDocument,
    TrackPreviewDocument,
    TrackRadioPageDocument,
    TrackRadioPlayDocument,
    TrackRecommendationNotificationCheckDocument,
    TrackRecommendationPreviewDocument,
    UpdatePlaylistDocument,
    UserIdDocument,
    CombinedPlaylistsPaginationDocument,
    PlaylistCategoriesPaginationDocument,
    LiveRadioHeartbeatDocument,
    LiveRadioHeartbeatInSecondsDocument,
    MarkNotificationsAsSeenDocument,
    NotificationsDocument,
    NotificationsPollDocument,
    PlaybackFullDocument,
    PlaybackSampleDocument,
    FavoritesStatisticsDocument,
    UrlTransformDocument,
    AddTrackChartToPlaylistDocument,
    AddRecommendationToPlaylistDocument,
    TrackLyricsIdDocument,
    TrackLyricsPageDocument,
    TrackLyricsPaginationDocument,
    ReportLyricsDocument,
    UpdateProfileDocument,
    RemoveProfileDocument,
    UserId2Document,
    CreateProfileDocument,
    UserProfilesDocument
} from "generated/graphql-types";
import type {
    AddAlbumToFavoritesMutationVariables,
    AddAlbumToPlaylistMutationVariables,
    AddArtistToFavoritesMutationVariables,
    AddPlaylistToFavoritesMutationVariables,
    AddPlaylistToPlaylistMutationVariables,
    AddTrackToFavoritesMutationVariables,
    AddTracksToPlaylistMutationVariables,
    AlbumArtistAlbumsByTrackSectionQueryVariables,
    AlbumArtistAlbumsPageQueryVariables,
    AlbumArtistAlbumsPaginationQueryVariables,
    AlbumArtistAlbumsSectionQueryVariables,
    AlbumHeaderSectionByTrackQueryVariables,
    AlbumHeaderSectionFragment,
    AlbumHeaderSectionQueryVariables,
    AlbumPlayQueryVariables,
    AlbumPreviewQueryVariables,
    AlbumTracksPaginationQueryVariables,
    AlbumTracksSectionByTrackQueryVariables,
    AlbumTracksSectionQueryVariables,
    ArtistAlbumsPageQueryVariables,
    ArtistAlbumsPaginationQueryVariables,
    ArtistAlbumsSectionQueryVariables,
    ArtistAppearsOnAlbumsPageQueryVariables,
    ArtistAppearsOnAlbumsPaginationQueryVariables,
    ArtistAppearsOnAlbumsSectionQueryVariables,
    ArtistPageHeaderSectionQueryVariables,
    ArtistPlayQueryVariables,
    ArtistPopularTracksSectionQueryVariables,
    ArtistPreviewQueryVariables,
    ArtistRadioPageQueryVariables,
    ArtistSimilarArtistsPageQueryVariables,
    ArtistSimilarArtistsPaginationQueryVariables,
    ArtistSimilarArtistsSectionQueryVariables,
    ArtistSinglesPageQueryVariables,
    ArtistSinglesPaginationQueryVariables,
    ArtistSinglesSectionQueryVariables,
    ArtistTracksPageQueryVariables,
    ArtistTracksPaginationQueryVariables,
    ChartItemsPaginationQueryVariables,
    ChartPageQueryVariables,
    ChartPreviewQueryVariables,
    ConcurrencyCanPlayMutationVariables,
    CreatePlaylistMutationVariables,
    DeletePlaylistMutationVariables,
    DiscoverRecommendationsPageQueryVariables,
    DiscoverRecommendationsPaginationQueryVariables,
    EndlessPlayQueryVariables,
    FavoriteAlbumsQueryVariables,
    FavoriteArtistsQueryVariables,
    FavoriteTracksQueryVariables,
    HistoryRecommendationsPageQueryVariables,
    HistoryRecommendationsPaginationQueryVariables,
    IsFavoriteQueryVariables,
    LayoutItemContentPaginationQueryVariables,
    LayoutItemPageQueryVariables,
    LayoutItemPlayQueryVariables,
    LayoutPageQueryVariables,
    LiveRadioCategoryByLiveRadioLiveRadiosPaginationQueryVariables,
    LiveRadioCategoryLiveRadiosPaginationQueryVariables,
    LiveRadioCategoryPageByLiveRadioQueryVariables,
    LiveRadioCategoryPageQueryVariables,
    LiveRadioNowAndRecentlyPlayedQueryVariables,
    LiveRadioPlayQueryVariables,
    MixRadioCategoryPageQueryVariables,
    MixRadioCategoryPaginationQueryVariables,
    MixRadioPlayQueryVariables,
    MixRadioPreviewQueryVariables,
    MixRadioTracksPaginationQueryVariables,
    MixRadiosPageQueryVariables,
    ModifyTracksInPlaylistMutationVariables,
    PlaylistCategoriesPageQueryVariables,
    PlaylistCategoryPageQueryVariables,
    PlaylistCategoryPlaylistsPaginationQueryVariables,
    PlaylistNotificationCheckQueryVariables,
    PlaylistPageQueryVariables,
    PlaylistPlayQueryVariables,
    PlaylistPreviewQueryVariables,
    PlaylistTracksPaginationQueryVariables,
    RecentTracksQueryVariables,
    RecommendationContentPaginationQueryVariables,
    RecommendationPageQueryVariables,
    RecommendationPlayQueryVariables,
    RemoveAlbumFromFavoritesMutationVariables,
    RemoveArtistFromFavoritesMutationVariables,
    RemovePlaylistFromFavoritesMutationVariables,
    RemoveTrackFromFavoritesMutationVariables,
    ReportPlaybackMutationVariables,
    SearchAlbumsPageQueryVariables,
    SearchAlbumsPaginationQueryVariables,
    SearchArtistsPageQueryVariables,
    SearchArtistsPaginationQueryVariables,
    SearchMixedSectionsQueryVariables,
    SearchPlaylistsPageQueryVariables,
    SearchPlaylistsPaginationQueryVariables,
    SearchPlaylistsSectionQueryVariables,
    SearchTracksPageQueryVariables,
    SearchTracksPaginationQueryVariables,
    SendFeedbackMutationVariables,
    TrackChartNotificationCheckQueryVariables,
    TrackChartPlayQueryVariables,
    TrackPreviewQueryVariables,
    TrackRadioPageQueryVariables,
    TrackRadioPlayQueryVariables,
    TrackRecommendationNotificationCheckQueryVariables,
    TrackRecommendationPreviewQueryVariables,
    PlaybackSampleQueryVariables,
    UpdatePlaylistMutationVariables,
    UserIdQuery,
    CombinedPlaylistsPaginationQueryVariables,
    PlaylistCategoriesPaginationQueryVariables,
    LiveRadioHeartbeatMutationVariables,
    LiveRadioHeartbeatInSecondsQueryVariables,
    MarkNotificationsAsSeenMutationVariables,
    NotificationsQueryVariables,
    PlaybackFullQueryVariables,
    UrlTransformQueryVariables,
    AddTrackChartToPlaylistMutationVariables,
    AddRecommendationToPlaylistMutationVariables,
    TrackLyricsIdQueryVariables,
    TrackLyricsPageQueryVariables,
    TrackLyricsPaginationQueryVariables,
    ReportLyricsMutationVariables,
    UpdateProfileMutationVariables,
    RemoveProfileMutationVariables,
    CreateProfileMutationVariables
} from "generated/graphql-types";
import { createStreamUrl, StreamUrlType } from "models/app/player/input";
import type { RecommendationOwner, AlbumPreviewModel, MixRadioLinkParentModel, FavoriteContentType, ResourceDisplayType } from "models/domain";
import {
    MixRadioLinkParentType,
    createAlbumArtistAlbumsPageModel,
    createAlbumPlayModel,
    createAlbumPreviewConnectionModel,
    createAlbumPreviewModel,
    createArtistAlbumsAppearsOnPageModel,
    createArtistAlbumsPageModel,
    createArtistPlayModel,
    createArtistPreviewConnectionModel,
    createArtistPreviewModel,
    createArtistRadioPageModel,
    createArtistRadioPlayModel,
    createArtistSimilarArtistsPageModel,
    createArtistSinglesPageModel,
    createArtistTracksPageModel,
    createChartItemPreviewConnectionModel,
    createChartPageModel,
    createChartPreviewModel,
    createEndlessPlayModel,
    createMyMusicTrackPreviewConnectionModel,
    createHistoryRecommendationsPage,
    createLiveRadioCategoryPageModel,
    createLiveRadioPlayModel,
    createLiveRadioPlayTracks,
    createLiveRadioPreviewConnectionModel,
    createMixRadioCategoryPageModel,
    createMixRadioPlayModel,
    createMixRadioPreviewConnectionModel,
    createMixRadioPreviewModel,
    createMixRadiosPageModel,
    createMyMusicAlbumsPageModel,
    createMyMusicArtistsPageModel,
    createMyMusicPlaylistsPageModel,
    createMyMusicTracksPageModel,
    createMyMusicTracksPlayModel,
    createNotificationPreviewConnectionModel,
    createMixedContentTrackPlayModel,
    createPlaylistCategoriesPageModel,
    createPlaylistCategoryPageModel,
    createPlaylistCategoryPreviewConnectionModel,
    createPlaylistPageModel,
    createPlaylistPlayModel,
    createPlaylistPreviewConnectionModel,
    createPlaylistPreviewModel,
    createPlaylistTrackPreviewConnectionModel,
    createRecentTracksModel,
    createRecommendationItemPreviewConnectionModel,
    createRecommendationPageModel,
    createRecommendationPlayModel,
    createRecommendationPreviewConnectionModel,
    createRecommendationPreviewModel,
    createSearchAlbumResultsPageModel,
    createSearchArtistResultsPageModel,
    createSearchLimitedMainModel,
    createSearchLimitedPlaylistModel,
    createSearchPlaylistResultsPageModel,
    createSearchTrackResultsPageModel,
    createTrackChartPlayModel,
    createTrackNoAlbumPreviewConnectionModel,
    createTrackPlayablePreviewConnectionModelFromSearchTrackResult,
    createTrackPlayablePreviewModelFromMixedContentTrack,
    createTrackPreviewConnectionModel,
    createTrackRadioPageModel,
    createTrackRadioPlayModel,
    createDiscoverRecommendationsPage
} from "models/domain";
import { getLayoutPageItemConnectionModelItems } from "models/domain/helpers";
import { createLayoutItemContentModel, createLayoutItemPageModel, createLayoutPageModel, createTracksLayoutItemPlayModel } from "models/domain/layoutPage";
import { ContentType } from "models/ModelType";
import { UserProfileModel, createUserProfileModel } from "models/app";
import { hasPermissionFromToken } from "services/user";

export const useAlbumArtistAlbumsPage = (variables: AlbumArtistAlbumsPageQueryVariables) =>
    useQueryDomainModel(AlbumArtistAlbumsPageDocument, variables, (data) => data.catalog.album && createAlbumArtistAlbumsPageModel(data.catalog.album));

export const getAlbumArtistAlbumsPagination = (variables: AlbumArtistAlbumsPaginationQueryVariables) =>
    queryDomainModel(
        AlbumArtistAlbumsPaginationDocument,
        variables,
        (data) => data.catalog.album?.artist?.albums && createAlbumPreviewConnectionModel(data.catalog.album.artist.albums)
    );

export const useArtistAlbumsPage = (variables: ArtistAlbumsPageQueryVariables) =>
    useQueryDomainModel(ArtistAlbumsPageDocument, variables, (data) => data.catalog.artist && createArtistAlbumsPageModel(data.catalog.artist));

export const useArtistAppearsOnPage = (variables: ArtistAppearsOnAlbumsPageQueryVariables) =>
    useQueryDomainModel(ArtistAppearsOnAlbumsPageDocument, variables, (data) => data.catalog.artist && createArtistAlbumsAppearsOnPageModel(data.catalog.artist, variables.id)); //todo: v2 - spørg esben

export const getArtistAlbumsPagination = (variables: ArtistAlbumsPaginationQueryVariables) =>
    queryDomainModel(ArtistAlbumsPaginationDocument, variables, (data) => data.catalog.artist?.albums && createAlbumPreviewConnectionModel(data.catalog.artist.albums));

export const getArtistAppearsOnAlbumsPagination = (variables: ArtistAppearsOnAlbumsPaginationQueryVariables) =>
    queryDomainModel(ArtistAppearsOnAlbumsPaginationDocument, variables, (data) => data.catalog.artist?.albums && createAlbumPreviewConnectionModel(data.catalog.artist.albums));

export const useArtistSimilarArtistsPage = (variables: ArtistSimilarArtistsPageQueryVariables) =>
    useQueryDomainModel(ArtistSimilarArtistsPageDocument, variables, (data) => data.catalog.artist && createArtistSimilarArtistsPageModel(data.catalog.artist));

export const getArtistSimilarArtistsPagination = (variables: ArtistSimilarArtistsPaginationQueryVariables) =>
    queryDomainModel(
        ArtistSimilarArtistsPaginationDocument,
        variables,
        (data) => data.catalog.artist?.similarArtists && createArtistPreviewConnectionModel(data.catalog.artist.similarArtists)
    );

export const useArtistSinglesPage = (variables: ArtistSinglesPageQueryVariables) =>
    useQueryDomainModel(ArtistSinglesPageDocument, variables, (data) => data.catalog.artist && createArtistSinglesPageModel(data.catalog.artist));

export const getArtistSinglesPagination = (variables: ArtistSinglesPaginationQueryVariables) =>
    queryDomainModel(ArtistSinglesPaginationDocument, variables, (data) => data.catalog.artist?.singles && createAlbumPreviewConnectionModel(data.catalog.artist.singles));

export const useArtistTracksPage = (variables: ArtistTracksPageQueryVariables) =>
    useQueryDomainModel(ArtistTracksPageDocument, variables, (data) => data.catalog.artist && createArtistTracksPageModel(data.catalog.artist));

export const getArtistTracksPagination = (variables: ArtistTracksPaginationQueryVariables, index: number) =>
    queryDomainModel(ArtistTracksPaginationDocument, variables, (data) => data.catalog.artist?.tracks && createTrackPreviewConnectionModel(data.catalog.artist.tracks, index));

export const getLayoutItemContentPagination = (variables: LayoutItemContentPaginationQueryVariables) =>
    queryDomainModel(
        LayoutItemContentPaginationDocument,
        variables,
        (data) => data.layout?.item?.content && getLayoutPageItemConnectionModelItems(createLayoutItemContentModel(data.layout.item.content, variables.pageId, variables.itemId))
    );

export const useSearchAlbumsPage = (variables: SearchAlbumsPageQueryVariables, skip: boolean) =>
    useQueryDomainModel(SearchAlbumsPageDocument, variables, (data) => data.search && createSearchAlbumResultsPageModel(data.search, variables.criterion), skip);

export const getSearchAlbumsPagination = (variables: SearchAlbumsPaginationQueryVariables) =>
    queryDomainModel(SearchAlbumsPaginationDocument, variables, (data) => data.search.albums && createAlbumPreviewConnectionModel(data.search.albums));

export const useSearchArtistsPage = (variables: SearchArtistsPageQueryVariables, skip: boolean) =>
    useQueryDomainModel(SearchArtistsPageDocument, variables, (data) => data.search && createSearchArtistResultsPageModel(data.search, variables.criterion), skip);

export const getSearchArtistsPagination = (variables: SearchArtistsPaginationQueryVariables) =>
    queryDomainModel(SearchArtistsPaginationDocument, variables, (data) => data.search.artists && createArtistPreviewConnectionModel(data.search.artists));

export const useSearchPlaylistsPage = (variables: SearchPlaylistsPageQueryVariables, skip: boolean) =>
    useQueryDomainModel(SearchPlaylistsPageDocument, variables, (data) => data.search && createSearchPlaylistResultsPageModel(data.search, variables.criterion), skip);

export const getSearchPlaylistsPagination = (variables: SearchPlaylistsPaginationQueryVariables) =>
    queryDomainModel(SearchPlaylistsPaginationDocument, variables, (data) => data.search.playlists && createPlaylistPreviewConnectionModel(data.search.playlists));

export const useSearchTracksPage = (variables: SearchTracksPageQueryVariables, skip: boolean) =>
    useQueryDomainModel(SearchTracksPageDocument, variables, (data) => data.search && createSearchTrackResultsPageModel(data.search, variables.criterion), skip);

export const getSearchTracksPagination = (variables: SearchTracksPaginationQueryVariables) =>
    queryDomainModel(SearchTracksPaginationDocument, variables, (data) => data.search.tracks && createTrackPlayablePreviewConnectionModelFromSearchTrackResult(data.search.tracks));

export const useHistoryRecommendationsPage = (variables: HistoryRecommendationsPageQueryVariables) =>
    useQueryDomainModel(HistoryRecommendationsPageDocument, variables, (data) => data.me?.recommendations && createHistoryRecommendationsPage(data.me.recommendations));

export const getHistoryRecommendationsPagination = (variables: HistoryRecommendationsPaginationQueryVariables) =>
    queryDomainModel(
        HistoryRecommendationsPaginationDocument,
        variables,
        (data) => data.me?.recommendations.recommendations && createRecommendationPreviewConnectionModel(data.me.recommendations.recommendations, ContentType.UserHistory)
    );

export const getRedirectUrl = (variables: UrlTransformQueryVariables) => queryDomainModel(UrlTransformDocument, variables, (data) => data.system.urlTransform.url);

export const useDiscoverRecommendationsPage = (variables: DiscoverRecommendationsPageQueryVariables) =>
    useQueryDomainModel(DiscoverRecommendationsPageDocument, variables, (data) => data.me?.recommendations && createDiscoverRecommendationsPage(data.me.recommendations));

export const getDiscoverRecommendationsPagination = (variables: DiscoverRecommendationsPaginationQueryVariables) =>
    queryDomainModel(
        DiscoverRecommendationsPaginationDocument,
        variables,
        (data) => data.me?.recommendations.recommendations && createRecommendationPreviewConnectionModel(data.me.recommendations.recommendations, ContentType.UserRecommendations)
    );

//#endregion

//#region artist page
//TODO: rename or leave as is?
export const fillArtistPage_headerSection = (variables: ArtistPageHeaderSectionQueryVariables) =>
    queryDomainModel(ArtistPageHeaderSectionDocument, variables, (data) => data.catalog.artist);

export const fillArtistPage_artistTracks = (variables: ArtistPopularTracksSectionQueryVariables) =>
    queryDomainModel(ArtistPopularTracksSectionDocument, variables, (data) => data.catalog.artist);

export const fillArtistPage_artistAlbums = (variables: ArtistAlbumsSectionQueryVariables) =>
    queryDomainModel(ArtistAlbumsSectionDocument, variables, (data) => data.catalog.artist);

export const fillArtistPage_artistSingles = (variables: ArtistSinglesSectionQueryVariables) =>
    queryDomainModel(ArtistSinglesSectionDocument, variables, (data) => data.catalog.artist);

export const fillArtistPage_similarArtists = (variables: ArtistSimilarArtistsSectionQueryVariables) =>
    queryDomainModel(ArtistSimilarArtistsSectionDocument, variables, (data) => data.catalog.artist);

export const fillArtistPage_appearsOn = (variables: ArtistAppearsOnAlbumsSectionQueryVariables) =>
    queryDomainModel(ArtistAppearsOnAlbumsSectionDocument, variables, (data) => data.catalog.artist);

//#endregion

//#region product pages

export const useArtistRadioPage = (variables: ArtistRadioPageQueryVariables) =>
    useQueryDomainModel(ArtistRadioPageDocument, variables, (data) => data.catalog.artist && createArtistRadioPageModel(data.catalog.artist));

export const useChartPage = (variables: ChartPageQueryVariables) =>
    useQueryDomainModel(ChartPageDocument, variables, (data) => data.charts.chart && createChartPageModel(data.charts.chart));

export const getChartItems = (variables: ChartItemsPaginationQueryVariables, index: number) =>
    queryDomainModel(ChartItemsPaginationDocument, variables, (data) => data.charts.chart && createChartItemPreviewConnectionModel(data.charts.chart, index));

export const useLiveRadioCategoryPageByLiveRadio = (variables: LiveRadioCategoryPageByLiveRadioQueryVariables) =>
    useQueryDomainModel(
        LiveRadioCategoryPageByLiveRadioDocument,
        variables,
        (data) => data.liveRadios.radio?.category && createLiveRadioCategoryPageModel(data.liveRadios.radio.category)
    );

export const getLiveRadioCategoryByLiveRadioLiveRadiosPagination = (variables: LiveRadioCategoryByLiveRadioLiveRadiosPaginationQueryVariables) =>
    queryDomainModel(
        LiveRadioCategoryByLiveRadioLiveRadiosPaginationDocument,
        variables,
        (data) => data.liveRadios.radio?.category?.radios && createLiveRadioPreviewConnectionModel(data.liveRadios.radio.category.radios)
    );

export const useLiveRadioCategoryPage = (variables: LiveRadioCategoryPageQueryVariables) =>
    useQueryDomainModel(LiveRadioCategoryPageDocument, variables, (data) => data.liveRadios.category && createLiveRadioCategoryPageModel(data.liveRadios.category));

export const getLiveRadioCategoryLiveRadiosPagination = (variables: LiveRadioCategoryLiveRadiosPaginationQueryVariables) =>
    queryDomainModel(
        LiveRadioCategoryLiveRadiosPaginationDocument,
        variables,
        (data) => data.liveRadios.category?.radios && createLiveRadioPreviewConnectionModel(data.liveRadios.category.radios)
    );

export const useMixRadioCategoryPage = (variables: MixRadioCategoryPageQueryVariables) =>
    useQueryDomainModel(MixRadioCategoryPageDocument, variables, (data) => data.mixRadios.category && createMixRadioCategoryPageModel(data.mixRadios.category));

export const getMixRadioCategoryPagination = (variables: MixRadioCategoryPaginationQueryVariables) =>
    queryDomainModel(
        MixRadioCategoryPaginationDocument,
        variables,
        (data) =>
            data.mixRadios.category?.radios &&
            createMixRadioPreviewConnectionModel(data.mixRadios.category?.radios, { type: MixRadioLinkParentType.MixRadioCategory, categoryId: variables.id })
    );

export const useMixRadiosPage = (variables: MixRadiosPageQueryVariables, radioId?: string) =>
    useQueryDomainModel(MixRadiosPageDocument, variables, (data) => createMixRadiosPageModel(data.mixRadios, radioId));

export const usePlaylistCategoryPage = (variables: PlaylistCategoryPageQueryVariables) =>
    useQueryDomainModel(
        PlaylistCategoryPageDocument,
        variables,
        (data) => data.playlists.playlistCategories.playlistCategory && createPlaylistCategoryPageModel(data.playlists.playlistCategories.playlistCategory)
    );

export const getPlaylistCategoryPlaylistsPagination = (variables: PlaylistCategoryPlaylistsPaginationQueryVariables) =>
    queryDomainModel(
        PlaylistCategoryPlaylistsPaginationDocument,
        variables,
        (data) =>
            data.playlists.playlistCategories.playlistCategory?.playlists && createPlaylistPreviewConnectionModel(data.playlists.playlistCategories.playlistCategory.playlists)
    );

export const usePlaylistPage = (variables: PlaylistPageQueryVariables) =>
    useQueryDomainModel(PlaylistPageDocument, variables, (data) => data.playlists.playlist && createPlaylistPageModel(data.playlists.playlist), undefined, undefined, true);

export const getPlaylistPage = (variables: PlaylistPageQueryVariables) =>
    queryDomainModel(PlaylistPageDocument, variables, (data) => {
        //console.log(`mapping playlist with ${data.playlists?.playlist?.tracks?.items?.length} tracks`);
        return data.playlists.playlist && createPlaylistPageModel(data.playlists.playlist);
    });

export const getPlaylistTracksPagination = (variables: PlaylistTracksPaginationQueryVariables) =>
    queryDomainModel(
        PlaylistTracksPaginationDocument,
        variables,
        (data) => data.playlists.playlist?.tracks && createPlaylistTrackPreviewConnectionModel(data.playlists.playlist?.tracks)
    );

export const useRecommendationPage = (variables: RecommendationPageQueryVariables, owner: RecommendationOwner) =>
    useQueryDomainModel(
        RecommendationPageDocument,
        variables,
        (data) => data.me?.recommendations.recommendation && createRecommendationPageModel(data.me.recommendations.recommendation, owner),
        false,
        true
    );

export const getRecommendationContentPagination = (variables: RecommendationContentPaginationQueryVariables, index: number) =>
    queryDomainModel(
        RecommendationContentPaginationDocument,
        variables,
        (data) => data.me?.recommendations.recommendation && createRecommendationItemPreviewConnectionModel(data.me.recommendations.recommendation, index)
    );

export const usePlaylistCategoriesPage = (variables: PlaylistCategoriesPageQueryVariables) =>
    useQueryDomainModel(
        PlaylistCategoriesPageDocument,
        variables,
        (data) => data.playlists.playlistCategories && createPlaylistCategoriesPageModel(data.playlists.playlistCategories.playlistCategories)
    ); //TODO: reload when translations arrive from backend

export const getPlaylistCategoriesPagination = (variables: PlaylistCategoriesPaginationQueryVariables) =>
    queryDomainModel(
        PlaylistCategoriesPaginationDocument,
        variables,
        (data) => data.playlists.playlistCategories && createPlaylistCategoryPreviewConnectionModel(data.playlists.playlistCategories.playlistCategories)
    );

export const useTrackRadioPage = (variables: TrackRadioPageQueryVariables) =>
    useQueryDomainModel(TrackRadioPageDocument, variables, (data) => data.catalog.track && createTrackRadioPageModel(data.catalog.track));

//#endregion

//#region myMusic pages
export const useFavoriteAlbumsPage = (variables: FavoriteAlbumsQueryVariables, skip: boolean) =>
    useQueryDomainModel(FavoriteAlbumsDocument, variables, (data) => createMyMusicAlbumsPageModel(data.me.favorites), skip);

export const getFavoriteAlbums = (variables: FavoriteAlbumsQueryVariables) =>
    queryDomainModel(FavoriteAlbumsDocument, variables, (data) => createAlbumPreviewConnectionModel(data.me.favorites.albums));

export const useFavoriteArtistsPage = (variables: FavoriteArtistsQueryVariables, skip: boolean) =>
    useQueryDomainModel(FavoriteArtistsDocument, variables, (data) => createMyMusicArtistsPageModel(data.me.favorites), skip);

export const getFavoriteArtists = (variables: FavoriteArtistsQueryVariables) =>
    queryDomainModel(FavoriteArtistsDocument, variables, (data) => createArtistPreviewConnectionModel(data.me.favorites.artists));

export const useFavoritePlaylistsPage = (variables: CombinedPlaylistsPaginationQueryVariables, skip: boolean) =>
    useQueryDomainModel(CombinedPlaylistsPaginationDocument, variables, (data) => createMyMusicPlaylistsPageModel(data.me.playlists), skip);

export const getCombinedPlaylistsPagination = (variables: CombinedPlaylistsPaginationQueryVariables) =>
    queryDomainModel(CombinedPlaylistsPaginationDocument, variables, (data) => createPlaylistPreviewConnectionModel(data.me.playlists.combinedPlaylists));

export const useFavoriteTracksPage = (variables: FavoriteTracksQueryVariables, skip: boolean) =>
    useQueryDomainModel(FavoriteTracksDocument, variables, (data) => createMyMusicTracksPageModel(data.me.favorites, variables.orderBy), skip);

export const getFavoriteTracks = (variables: FavoriteTracksQueryVariables) =>
    queryDomainModel(FavoriteTracksDocument, variables, (data) => data.me.favorites.tracks && createMyMusicTrackPreviewConnectionModel(data.me.favorites.tracks));

export const getFavoritesStatistics = () => queryDomainModel(FavoritesStatisticsDocument, {}, (data) => data.me.favorites);

//#endregion

//#region album page

export const getAlbumHeaderSection = (variables: AlbumHeaderSectionQueryVariables): Promise<DomainQueryResult<AlbumHeaderSectionFragment>> =>
    queryDomainModel(AlbumHeaderSectionDocument, variables, (data) => data.catalog.album);

export const getAlbumHeaderSectionByTrack = (variables: AlbumHeaderSectionByTrackQueryVariables): Promise<DomainQueryResult<AlbumHeaderSectionFragment>> =>
    queryDomainModel(AlbumHeaderSectionByTrackDocument, variables, (data) => data.catalog.track?.album);

export const getAlbumTracksSection = (variables: AlbumTracksSectionQueryVariables) => queryDomainModel(AlbumTracksSectionDocument, variables, (data) => data.catalog.album);

export const getAlbumTracksSectionByTrack = (variables: AlbumTracksSectionByTrackQueryVariables) =>
    queryDomainModel(AlbumTracksSectionByTrackDocument, variables, (data) => data.catalog.track?.album);

export const getAlbumArtistAlbumsSection = (variables: AlbumArtistAlbumsSectionQueryVariables) =>
    queryDomainModel(AlbumArtistAlbumsSectionDocument, variables, (data) => data.catalog.album);

export const getAlbumArtistAlbumsByTrackSection = (variables: AlbumArtistAlbumsByTrackSectionQueryVariables) =>
    queryDomainModel(AlbumArtistAlbumsByTrackSectionDocument, variables, (data) => data.catalog.track?.album);

export const getAlbumTracksPagination = (variables: AlbumTracksPaginationQueryVariables, album: AlbumPreviewModel) =>
    queryDomainModel(
        AlbumTracksPaginationDocument,
        variables,
        (data) => data.catalog.album?.tracks && createTrackNoAlbumPreviewConnectionModel(data.catalog.album.tracks, album.cover, album)
    );

//#endregion

//#region layout page
//TODO: v2 - placeholder??
export const useLayoutPageSkeleton = (variables: LayoutPageQueryVariables) =>
    useQueryDomainModel(LayoutPageDocument, variables, (data) => data.layout && createLayoutPageModel(data.layout), false, true); //TODO: is this necessary?

export const useLayoutPage = (variables: LayoutPageQueryVariables) =>
    useQueryDomainModel(LayoutPageDocument, variables, (data) => data.layout && createLayoutPageModel(data.layout), false, true);

export const getLayoutItemPage = (variables: LayoutItemPageQueryVariables, displayType: ResourceDisplayType) =>
    queryDomainModel(LayoutItemPageDocument, variables, (data) => data.layout?.item && createLayoutItemPageModel(data.layout.item, variables.pageId, displayType));

export const useLayoutItemPage = (variables: LayoutItemPageQueryVariables) =>
    useQueryDomainModel(LayoutItemPageDocument, variables, (data) => data.layout?.item && createLayoutItemPageModel(data.layout.item, variables.pageId));

//#endregion

//#region play queries

export const getMixedContentTrackPlay = (variables: TrackPreviewQueryVariables) =>
    queryDomainModel(TrackPreviewDocument, variables, (data) => data.catalog.track && createMixedContentTrackPlayModel(data.catalog.track));

export const getRecommendationPlay = (variables: RecommendationPlayQueryVariables, owner: RecommendationOwner) =>
    queryDomainModel(
        RecommendationPlayDocument,
        variables,
        (data) => data.me?.recommendations.recommendation && createRecommendationPlayModel(data.me.recommendations.recommendation, owner)
    );

//#endregion

//#region MixedContent's context menu queries

export const getAlbumPreview = (variables: AlbumPreviewQueryVariables) =>
    queryDomainModel(AlbumPreviewDocument, variables, (data) => data.catalog.album && createAlbumPreviewModel(data.catalog.album));

export const getArtistPreview = (variables: ArtistPreviewQueryVariables) =>
    queryDomainModel(ArtistPreviewDocument, variables, (data) => data.catalog.artist && createArtistPreviewModel(data.catalog.artist));
//TODO: these seem wrong? it's the same but not realy?
export const getPlaylistPreview = (variables: PlaylistPreviewQueryVariables) =>
    queryDomainModel(PlaylistPreviewDocument, variables, (data) => data.playlists.playlist && createPlaylistPreviewModel(data.playlists.playlist));

//TODO: rename or leave as is?
export const getTrackChartPreview = (variables: ChartPreviewQueryVariables) =>
    queryDomainModel(ChartPreviewDocument, variables, (data) => data.charts.chart && createChartPreviewModel(data.charts.chart));

export const getTrackPreview = (variables: TrackPreviewQueryVariables) =>
    queryDomainModel(TrackPreviewDocument, variables, (data) => data.catalog.track && createTrackPlayablePreviewModelFromMixedContentTrack(data.catalog.track));

//#endregion

//#region Player queries

export const getPlaybackSample = (variables: PlaybackSampleQueryVariables) =>
    queryDomainModel(PlaybackSampleDocument, variables, (data) => data.playback.sample && createStreamUrl(data.playback.sample, StreamUrlType.Mp3));
export const getPlaybackFull = (variables: PlaybackFullQueryVariables) =>
    queryDomainModel(PlaybackFullDocument, variables, (data) => data.playback.full && createStreamUrl(data.playback.full, StreamUrlType.Hls));

//#endregion

//#region notification feed
export const getPlaylistNotificationCheck = (variables: PlaylistNotificationCheckQueryVariables) => queryDomainModel(PlaylistNotificationCheckDocument, variables, (data) => data);

export const getTrackRecommendationNotificationCheck = (variables: TrackRecommendationNotificationCheckQueryVariables) =>
    queryDomainModel(TrackRecommendationNotificationCheckDocument, variables, (data) => data);

export const getTrackChartNotificationCheck = (variables: TrackChartNotificationCheckQueryVariables) =>
    queryDomainModel(TrackChartNotificationCheckDocument, variables, (data) => data);

export const getTrackRecommendationPreview = (variables: TrackRecommendationPreviewQueryVariables) =>
    queryDomainModel(TrackRecommendationPreviewDocument, variables, (data) =>
        data.me?.recommendations.recommendation ? createRecommendationPreviewModel(data.me.recommendations.recommendation, ContentType.UserRecommendations) : null
    );

export const getChartPreview = (variables: ChartPreviewQueryVariables) =>
    queryDomainModel(ChartPreviewDocument, variables, (data) => (data.charts.chart ? createChartPreviewModel(data.charts.chart) : null));

export const getNotifications = (variables: NotificationsQueryVariables) =>
    queryDomainModel(NotificationsDocument, variables, (data) => ({
        notSeenCount: data.me.notifications.notSeenCount,
        notificationState: data.me.notifications.available.notificationsState,
        notifications: createNotificationPreviewConnectionModel(data.me.notifications.available.notifications)
    }));

export const getNotificationsStatus = () =>
    queryDomainModel(NotificationsPollDocument, {}, (data) => ({
        notSeenCount: data.me.notifications.notSeenCount
    }));

export const mutateMarkNotificationsAsSeen = async (variables: MarkNotificationsAsSeenMutationVariables) =>
    mutateDomainModel(MarkNotificationsAsSeenDocument, variables, (data) => data.notifications.markAsSeen.ok);

//#endregion

export const getAlbumPlay = (variables: AlbumPlayQueryVariables) =>
    queryDomainModel(AlbumPlayDocument, variables, (data) => data.catalog.album && createAlbumPlayModel(data.catalog.album));

export const getAlbumArtistAlbumsPage = (variables: AlbumArtistAlbumsPageQueryVariables) =>
    queryDomainModel(AlbumArtistAlbumsPageDocument, variables, (data) => data.catalog.album && createAlbumArtistAlbumsPageModel(data.catalog.album));

export const getArtistPlay = (variables: ArtistPlayQueryVariables, index: number) =>
    queryDomainModel(ArtistPlayDocument, variables, (data) => data.catalog.artist && createArtistPlayModel(data.catalog.artist, index));

export const getArtistRadioPage = (variables: ArtistRadioPageQueryVariables) =>
    queryDomainModel(ArtistRadioPageDocument, variables, (data) => data.catalog.artist && createArtistRadioPlayModel(data.catalog.artist));

export const getArtistSinglesPage = (variables: ArtistSinglesPageQueryVariables) =>
    queryDomainModel(ArtistSinglesPageDocument, variables, (data) => data.catalog.artist && createArtistSinglesPageModel(data.catalog.artist));

export const getTrackChartPlay = (variables: TrackChartPlayQueryVariables) =>
    queryDomainModel(TrackChartPlayDocument, variables, (data) => data.charts.chart && createTrackChartPlayModel(data.charts.chart));

export const getLayoutItemPlay = (variables: LayoutItemPlayQueryVariables) =>
    queryDomainModel(LayoutItemPlayDocument, variables, (data) => data.layout?.item && createTracksLayoutItemPlayModel(data.layout.item, variables.pageId));

export const getPlaylistPlay = (variables: PlaylistPlayQueryVariables) =>
    queryDomainModel(PlaylistPlayDocument, variables, (data) => data.playlists.playlist && createPlaylistPlayModel(data.playlists.playlist));

export const getMixRadioPlay = (variables: MixRadioPlayQueryVariables, parent: MixRadioLinkParentModel) =>
    queryDomainModel(MixRadioPlayDocument, variables, (data) => data.mixRadios.radio && createMixRadioPlayModel(data.mixRadios.radio, parent));

export const getMixRadioPreview = (variables: MixRadioPreviewQueryVariables, parent: MixRadioLinkParentModel) =>
    queryDomainModel(MixRadioPreviewDocument, variables, (data) => data.mixRadios.radio && createMixRadioPreviewModel(data.mixRadios.radio, parent));

export const getMixRadioTracksPagination = (variables: MixRadioTracksPaginationQueryVariables) =>
    queryDomainModel(MixRadioTracksPaginationDocument, variables, (data) => data.mixRadios.radio?.tracks && createTrackPreviewConnectionModel(data.mixRadios.radio.tracks, 0));

export const getLiveRadioPlay = (variables: LiveRadioPlayQueryVariables) =>
    queryDomainModel(LiveRadioPlayDocument, variables, (data) => data.liveRadios.radio && createLiveRadioPlayModel(data.liveRadios.radio));

export const getLiveRadioNowAndRecentlyPlayed = (variables: LiveRadioNowAndRecentlyPlayedQueryVariables) =>
    queryDomainModel(LiveRadioNowAndRecentlyPlayedDocument, variables, (data) => data.liveRadios.radio && createLiveRadioPlayTracks(data.liveRadios.radio));

export const getMyMusicTracksPlay = (variables: FavoriteTracksQueryVariables) => {
    return queryDomainModel(
        FavoriteTracksDocument,
        variables,
        (data) => createMyMusicTracksPlayModel(data.me.favorites, FavoriteTracksSorting.LatestAdded) // TODO: v2
    );
};

export const getRecentTracks = (variables: RecentTracksQueryVariables) => queryDomainModel(RecentTracksDocument, variables, (data) => createRecentTracksModel(data.me));

export const getMeEndlessPlay = (variables: EndlessPlayQueryVariables) =>
    queryDomainModel(EndlessPlayDocument, variables, (data) => (data.catalog.track?.endlessPlay ? createEndlessPlayModel(data.catalog.track.endlessPlay) : null));

export type UserIdModel = Awaited<ReturnType<typeof getUser>>["model"];
export const getUser = () => queryDomainModel(UserId2Document, { includeProfiles: hasPermissionFromToken("prof") }, (data) => ({
    id: data.me.user.id,
    trackingId: data.me.user.trackingId,
    username: data.me.user.username ?? null,
    profiles: (data.me.user.profiles?.profiles?.items?.map(createUserProfileModel) ?? []),
    profilesNumberLimit: data.me.user.profiles?.settings.profilesNumberLimit ?? 1,
    profileDefaultColors: data.me.user.profiles?.settings.defaultColors as string[] ?? []
}));

export const getUserProfiles = () => queryDomainModel(UserProfilesDocument, {}, (data) => ({
    profiles: (data.me.user.profiles?.profiles?.items?.map(createUserProfileModel) ?? []),
    profilesNumberLimit: data.me.user.profiles?.settings.profilesNumberLimit ?? 1,
    profileDefaultColors: data.me.user.profiles?.settings.defaultColors as string[] ?? []
}));

export const getLimitedSearchResultMain = (variables: SearchMixedSectionsQueryVariables) =>
    queryDomainModel(SearchMixedSectionsDocument, variables, (data) => createSearchLimitedMainModel(data.search, variables.criterion));

export const getLimitedSearchResultPlaylists = (variables: SearchPlaylistsSectionQueryVariables) =>
    queryDomainModel(SearchPlaylistsSectionDocument, variables, (data) => createSearchLimitedPlaylistModel(data.search, variables.criterion));

export const getTrackRadioPlay = (variables: TrackRadioPlayQueryVariables) =>
    queryDomainModel(TrackRadioPlayDocument, variables, (data) => data.catalog.track && createTrackRadioPlayModel(data.catalog.track));

export const getIsFavorite = (variables: IsFavoriteQueryVariables) => queryDomainModel(IsFavoriteDocument, variables, (data) => data.me.favorites.isFavorite?.isFavorite ?? null);

// todo: this caching needs to go away when we introduce the favorites delta service
const favorite_cache = new Map<string, boolean>();

export function clearTemporaryFavoriteCache() {
    favorite_cache.clear();
}

export async function getIsMeFavoriteWithCustomCache(type: FavoriteContentType, id: string) {
    let isFavorite: boolean | null = null;
    if (!id || !type) {
        return false;
    }
    const cacheId = `${type}_${id}`;
    isFavorite = favorite_cache.get(cacheId) ?? null;

    if (isFavorite != null) return isFavorite;
    isFavorite = await getIsMeFavorite(id, type);

    if (isFavorite != null) {
        favorite_cache.set(cacheId, isFavorite);
        setTimeout(() => favorite_cache.delete(cacheId), 10_000);
    }

    return isFavorite;
}

const getIsMeFavorite = async (id: string, type: FavoriteContentType) => {
    const favoritesStatus = await getIsFavorite({ id, type });
    if (favoritesStatus == null) {
        log.error({ code: "web-220216-1633", msg: "unable to retrieve favoritesStatus" });
        return null;
    }
    return favoritesStatus.model;
};

export const getOwnPlaylists = () =>
    queryDomainModel(OwnPlaylistsDocument, {}, (data) => data.me.playlists.ownPlaylists && createPlaylistPreviewConnectionModel(data.me.playlists.ownPlaylists));

export const mutateProvideFeedback = (variables: SendFeedbackMutationVariables) =>
    mutateDomainModel(SendFeedbackDocument, variables, (data) => data.feedbacks.send.ok);

export const mutateAddTrackToFavorites = async (variables: AddTrackToFavoritesMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddTrackToFavoritesDocument, variables);
    return {
        ok: result?.data?.favorites.addTrack.ok ?? false,
        limitExceeded: result?.errors?.some(n => n.extensions?.code === "FAVORITES_LIMIT_EXCEEDED") === true
    };
};


export const mutateAddProfile = async (input: CreateProfileMutationVariables["input"]) => {
    const result = await mutateDomainModelWithErrors(CreateProfileDocument, { input });
    return {
        ok: result?.data?.profiles.addProfile?.ok ?? false,
        profile: result?.data?.profiles.addProfile?.profile ? createUserProfileModel(result.data.profiles.addProfile.profile) : null
    };
};

export const mutateUpdateProfile = async (variables: UpdateProfileMutationVariables) => {
    const result = await mutateDomainModelWithErrors(UpdateProfileDocument, variables);
    return {
        ok: result?.data?.profiles.updateProfile?.ok ?? false,
    };
};

export const mutateRemoveProfile = async (variables: RemoveProfileMutationVariables) => {
    const result = await mutateDomainModelWithErrors(RemoveProfileDocument, variables);
    return {
        ok: result?.data?.profiles.removeProfile?.ok ?? false,
    };
};


const lyricsIdCache: { [id: string]: DomainQueryResult<string | null> } = {
    0: {
        model: null,
        error: null,
        errorReason: "",
        loading: false,
        success: false
    }
};

export const getTrackLyricsId = async (variables: TrackLyricsIdQueryVariables): Promise<DomainQueryResult<string | null>> => {
    if (lyricsIdCache[variables.id]) return lyricsIdCache[variables.id];
    const result = await queryDomainModel(TrackLyricsIdDocument, variables, (data) => data.catalog.track?.lyrics?.id ?? null);
    if (result.success) lyricsIdCache[variables.id] = result;
    return result;
};

export const useTrackLyricsId = (variables: TrackLyricsIdQueryVariables) => {
    const [result, setResult] = useState<DomainQueryResult<string | null> | null>(null);
    useEffect(() => {
        getTrackLyricsId({ id: variables.id }).then(setResult);
    }, [variables.id, setResult]);
    return result;
};

export const getTrackLyrics = (variables: TrackLyricsPageQueryVariables) =>
    queryDomainModel(TrackLyricsPageDocument, variables, (data) => data.catalog.track?.lyrics ?? null);

export const getTrackLyricsPagination = (variables: TrackLyricsPaginationQueryVariables) =>
    queryDomainModel(TrackLyricsPaginationDocument, variables, (data) => data.catalog.track?.lyrics ?? null);

export const mutateRemoveTrackFromFavorites = (variables: RemoveTrackFromFavoritesMutationVariables) =>
    mutateDomainModel(RemoveTrackFromFavoritesDocument, variables, (data) => data.favorites.removeTrack.ok);

export const mutateAddAlbumToFavorites = async (variables: AddAlbumToFavoritesMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddAlbumToFavoritesDocument, variables);
    return {
        ok: result?.data?.favorites.addAlbum.ok ?? false,
        limitExceeded: result?.errors?.some(n => n.extensions?.code === "FAVORITES_LIMIT_EXCEEDED") === true
    };
}

export const mutateRemoveAlbumFromFavorites = (variables: RemoveAlbumFromFavoritesMutationVariables) =>
    mutateDomainModel(RemoveAlbumFromFavoritesDocument, variables, (data) => data.favorites.removeAlbum.ok);

export const mutateAddArtistToFavorites = async (variables: AddArtistToFavoritesMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddArtistToFavoritesDocument, variables);
    return {
        ok: result?.data?.favorites.addArtist.ok ?? false,
        limitExceeded: result?.errors?.some(n => n.extensions?.code === "FAVORITES_LIMIT_EXCEEDED") === true
    };
}

export const mutateRemoveArtistFromFavorites = (variables: RemoveArtistFromFavoritesMutationVariables) =>
    mutateDomainModel(RemoveArtistFromFavoritesDocument, variables, (data) => data.favorites.removeArtist.ok);

export const mutateAddPlaylistToFavorites = async (variables: AddPlaylistToFavoritesMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddPlaylistToFavoritesDocument, variables);
    return {
        ok: result?.data?.favorites.addPlaylist.ok ?? false,
        limitExceeded: result?.errors?.some(n => n.extensions?.code === "FAVORITES_LIMIT_EXCEEDED") === true
    };
}

export const mutateRemovePlaylistFromFavorites = (variables: RemovePlaylistFromFavoritesMutationVariables) =>
    mutateDomainModel(RemovePlaylistFromFavoritesDocument, variables, (data) => data.favorites.removePlaylist.ok);

export const mutateReportLyrics = async (variables: ReportLyricsMutationVariables): Promise<boolean> =>
    (await mutateDomainModel(ReportLyricsDocument, variables, (data) => data.lyrics.reportLyricsDisplay.ok)) ?? false;

export const mutateReportPlayback = async (variables: ReportPlaybackMutationVariables): Promise<boolean> =>
    (await mutateDomainModel(ReportPlaybackDocument, variables, (data) => data.reportPlayback.ok)) ?? false;

export const mutateSetActivePlaybackStream = () => mutateDomainModel(ConcurrencyCreateTicketDocument, {}, (data) => data.playbackConcurrency.createTicket);

export const mutateGetPlaybackStream = (variables: ConcurrencyCanPlayMutationVariables) =>
    mutateDomainModel(ConcurrencyCanPlayDocument, variables, (data) => data.playbackConcurrency.canPlay.canPlay);

export const mutateCreatePlaylist = (variables: CreatePlaylistMutationVariables) =>
    mutateDomainModel(CreatePlaylistDocument, variables, (data) => data.playlists.create.playlist.id ?? null);

export const mutateDeletePlaylist = (variables: DeletePlaylistMutationVariables) => mutateDomainModel(DeletePlaylistDocument, variables, (data) => data.playlists.delete.ok);

export const mutateModifyPlaylist = (variables: UpdatePlaylistMutationVariables) =>
    mutateDomainModel(UpdatePlaylistDocument, variables, (data) => data.playlists.update.playlist.id ?? null);

export const mutateAddTracksToPlaylist = async (variables: AddTracksToPlaylistMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddTracksToPlaylistDocument, variables);
    return {
        wasAdded: result?.data ? result.data.playlists.addTracks.ok : false,
        addedCount: result?.data?.playlists.addTracks.addedCount ?? 0,
        duplicates: result?.errors?.some(n => n.extensions?.code === "TRACKS_DUPLICATION") === true,
        duplicatesCount: (result?.errors?.find(n => n.extensions?.code === "TRACKS_DUPLICATION")?.extensions.duplicatedTrackIds as string[] | undefined)?.length ?? 0
    }
}

export const mutateAddAlbumToPlaylist = async (variables: AddAlbumToPlaylistMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddAlbumToPlaylistDocument, variables);
    return {
        wasAdded: result?.data ? result.data.playlists.addAlbum.ok : false,
        addedCount: result?.data?.playlists.addAlbum.addedCount ?? 0,
        duplicates: result?.errors?.some(n => n.extensions?.code === "TRACKS_DUPLICATION") === true,
        duplicatesCount: (result?.errors?.find(n => n.extensions?.code === "TRACKS_DUPLICATION")?.extensions.duplicatedTrackIds as string[] | undefined)?.length ?? 0
    }
}

export const mutateAddPlaylistToPlaylist = async (variables: AddPlaylistToPlaylistMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddPlaylistToPlaylistDocument, variables);
    return {
        wasAdded: result?.data ? result.data.playlists.addPlaylist.ok : false,
        addedCount: result?.data?.playlists.addPlaylist.addedCount ?? 0,
        duplicates: result?.errors?.some(n => n.extensions?.code === "TRACKS_DUPLICATION") === true,
        duplicatesCount: (result?.errors?.find(n => n.extensions?.code === "TRACKS_DUPLICATION")?.extensions.duplicatedTrackIds as string[] | undefined)?.length ?? 0
    }
}

export const mutateAddTrackChartToPlaylist = async (variables: AddTrackChartToPlaylistMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddTrackChartToPlaylistDocument, variables);
    return {
        wasAdded: result?.data ? result.data.playlists.addChart.ok : false,
        addedCount: result?.data?.playlists.addChart.addedCount ?? 0,
        duplicates: result?.errors?.some(n => n.extensions?.code === "TRACKS_DUPLICATION") === true,
        duplicatesCount: (result?.errors?.find(n => n.extensions?.code === "TRACKS_DUPLICATION")?.extensions.duplicatedTrackIds as string[] | undefined)?.length ?? 0
    }
}

export const mutateAddRecommendationToPlaylist = async (variables: AddRecommendationToPlaylistMutationVariables) => {
    const result = await mutateDomainModelWithErrors(AddRecommendationToPlaylistDocument, variables);
    return {
        wasAdded: result?.data ? result.data.playlists.addRecommendation.ok : false,
        addedCount: result?.data?.playlists.addRecommendation.addedCount ?? 0,
        duplicates: result?.errors?.some(n => n.extensions?.code === "TRACKS_DUPLICATION") === true,
        duplicatesCount: (result?.errors?.find(n => n.extensions?.code === "TRACKS_DUPLICATION")?.extensions.duplicatedTrackIds as string[] | undefined)?.length ?? 0
    }
}

export const mutateModifyTracksInPlaylist = (variables: ModifyTracksInPlaylistMutationVariables) =>
    mutateDomainModel(ModifyTracksInPlaylistDocument, variables, (data) => ({
        ok: data.playlists.modifyTracks.ok,
        playlist: createPlaylistPreviewModel(data.playlists.modifyTracks.playlist)
    }));

export const getLiveRadioHeartbeatInSeconds = (variables: LiveRadioHeartbeatInSecondsQueryVariables) =>
    queryDomainModel(LiveRadioHeartbeatInSecondsDocument, variables, (data) => data.system.deviceSettings.liveRadioHeartbeatInSeconds);

export const mutateReportLiveRadioHeartbeat = (variables: LiveRadioHeartbeatMutationVariables) => {
    mutateDomainModel(LiveRadioHeartbeatDocument, variables, (data) => data.liveRadios.reportHeartbeat.ok);
};

export const getBackendBaseUrl = (path: string | undefined = undefined) => {
    if (isFeatureEnabled(Feature.ForceRemoteBackend)) {
        return `https://musik.${environment.webAppBrand === WebAppBrand.YouSee ? "yousee" : "telmore"}.dk${path}`;
    }

    const portPath = !document.location.port ? "" : document.location.port == "44303" ? ":44303" : ":5000";
    const url = document.location.hostname !== "localhost" ? "" : `https://${document.location.hostname}${portPath}`;
    return !path ? url : url + path;
};

export const apiTokenPath = "/api/token";
export const apiDelegatedLoginPath = "/api/delegatedlogin";
export const apiClientlogPath = "/api/clientlog";
