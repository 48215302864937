import { getAppVersionString } from "shared/services/appSettings";
import { getBrowserName, getInstallationId, getOSName, OperatingSystemName } from "services/app/appService";
import { mutateProvideFeedback } from "services/backend";
import { environment } from "services/environment";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { isAppRunAsPWA } from "services/pwa/initPWAService";
import type { FeedbackApplicationInfoInput, FeedbackDeviceInfoInput } from "generated/graphql-types";
import { FeedbackOperatingSystem } from "generated/graphql-types";
import { FeedbackCategory as BackendCategory } from "models/domain";

export enum FeedbackCategory {
    Default = "Default",
    Function = "Function",
    Stability = "Stability",
    Content = "Content",
    Other = "Other"
}

export async function submitInAppFeedback(category: FeedbackCategory, message: string, email: string | null): Promise<boolean> {
    const result = await mutateProvideFeedback({
        feedback: { category: getBackendCategory(category), text: message, deviceInfo: getDeviceInfo(), applicationInfo: getApplicationInfo(), email: email ?? undefined }
    });
    return result ?? false;
}

function getBackendCategory(category: FeedbackCategory): BackendCategory {
    switch (category) {
        case FeedbackCategory.Content:
            return BackendCategory.Contents;
        case FeedbackCategory.Function:
            return BackendCategory.Function;
        case FeedbackCategory.Other:
            return BackendCategory.OtherThings;
        case FeedbackCategory.Stability:
            return BackendCategory.Stability;
        case FeedbackCategory.Default:
            log.error({ code: "web-210729-1552", msg: DefaultLogMessage.UnexpectedValue });
            return BackendCategory.OtherThings;
    }
}

function getApplicationInfo(): FeedbackApplicationInfoInput {
    return { brand: environment.webAppBrand, supportKey: getInstallationId(), version: getAppVersionString() };
}

function getDeviceInfo(): FeedbackDeviceInfoInput {
    return { brand: getFeedbackBrand(), model: window.navigator.userAgent, operatingSystem: getFeedbackOS() };
}

function getFeedbackBrand(): string {
    const browser = getBrowserName();
    const isPWA = isAppRunAsPWA();

    let brand = browser.toString();
    if (isPWA) brand += " (PWA)";

    return brand;
}

function getFeedbackOS(): FeedbackOperatingSystem {
    const os = getOSName();
    switch (os) {
        case OperatingSystemName.Android:
            return FeedbackOperatingSystem.Android;
        case OperatingSystemName.Linux:
            return FeedbackOperatingSystem.Linux;
        case OperatingSystemName.Unknown:
            return FeedbackOperatingSystem.Unknown;
        case OperatingSystemName.Windows:
            return FeedbackOperatingSystem.Windows;
        case OperatingSystemName.iOS:
        case OperatingSystemName.iPadOS:
        case OperatingSystemName.iPodOS:
            return FeedbackOperatingSystem.Apple;
        case OperatingSystemName.macOS:
            return FeedbackOperatingSystem.MacOs;
    }
}
