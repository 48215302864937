import { h } from "preact";
import { useState, useLayoutEffect, useMemo, useEffect, useRef, useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import "./PlayRange.scss";
import { dispatch } from "global";
import { PLAYER_UI_SEEKING } from "global/actions";
import { log } from "services/logger/initLoggerService";
import { getAudioItemPositionDelay, getAudioItemPositionPercent } from "services/player/inputs/service/helpers";
import type { RootModel } from "models/app";
import { AudioInputType, PlayState } from "models/app/player";
import { useAudioProperties, usePlayRange, useTabIsActive } from "components/shared/hooks";

export enum RangeType {
    MobileMini = "mobileMini",
    MobileMaxi = "mobileMaxi",
    Desktop = "desktop"
}

interface Props {
    onChange?: (percent: number) => void;
    onSeekPositionChange?: (seconds: number | null) => void;
    type: RangeType;
}

export const PlayRange = ({ onChange, onSeekPositionChange, type }: Props) => {
    const disabled = !usePlayRange();
    const audioProperties = useAudioProperties();
    let { duration } = audioProperties;
    const { playState, position, loaded } = audioProperties;

    const inputRef = useRef<HTMLInputElement | null>(null);

    const inputRange = Number.MAX_SAFE_INTEGER;
    const [drag, setDrag] = useState<number | null>(null);
    const [random, setRandom] = useState<number>(Math.random() * Number.MAX_SAFE_INTEGER);
    const [progressStyle, setProgressStyle] = useState({});

    const isTabActive = useTabIsActive();

    if (duration === 0) duration = 100;
    const isInputLiveRadio = useSelector((root: RootModel) => root.player.input) === AudioInputType.LiveRadio;
    const getDelay = useMemo(() => {
        if (drag != null) {
            return (drag * duration) / -100;
        } else {
            return getAudioItemPositionDelay(playState, position, duration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playState, position, drag, duration, isTabActive]);

    useEffect(() => {
        const update = () => {
            if (drag != null) return;
            const percent = getAudioItemPositionPercent(playState, position, duration);
            const value = (percent / 100) * inputRange;

            if (inputRef.current) inputRef.current.value = value.toString();
        };

        update();

        if (playState === PlayState.Playing) {
            const intervalId = setInterval(() => update(), 100);
            return () => clearInterval(intervalId);
        }
    }, [playState, position, duration, inputRange, drag]);

    useLayoutEffect(() => {
        let animationPlayState: "paused" | "running" = "paused";
        const delay = getDelay;
        if (
            isInputLiveRadio ||
            drag != null ||
            playState === PlayState.Buffering ||
            playState === PlayState.Paused ||
            playState === PlayState.Stopped ||
            playState === PlayState.Error
        ) {
            animationPlayState = "paused";
        } else if (playState === PlayState.Playing) {
            animationPlayState = "running";
        } else {
            log.error({ code: "web-210308-1730", msg: "progress range error" });
            return;
        }
        const progressStyle = {
            animationPlayState,
            animationDuration: `${duration}s`,
            animationDelay: `${delay}s`
        };
        setRandom(Math.random() * Number.MAX_SAFE_INTEGER);
        setProgressStyle(progressStyle);
    }, [getDelay, drag, playState, position.playStartMs, duration, isInputLiveRadio]);

    const getPercentFromInput = useCallback(
        (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
            return (Number(e.currentTarget.value) / inputRange) * 100;
        },
        [inputRange]
    );

    const handleOnInput = useCallback(
        (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
            const percent = getPercentFromInput(e);
            setDrag(percent);
            const seconds = (percent * (duration ?? 0)) / 100;
            dispatch({
                type: PLAYER_UI_SEEKING,
                payload: {
                    percent,
                    seconds
                }
            });
            onSeekPositionChange && onSeekPositionChange(seconds);
        },
        [duration, getPercentFromInput, onSeekPositionChange]
    );
    const handleOnChange = useCallback(
        (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
            const percent = getPercentFromInput(e);
            setDrag(null);
            onChange && onChange(percent);
            onSeekPositionChange && onSeekPositionChange(null);
        },
        [getPercentFromInput, onChange, onSeekPositionChange]
    );

    const animation = drag == null && playState === PlayState.Playing;

    return (
        <div className={`range Js8 --movement-${animation ? "animation" : "position"} --type-${type}`} disabled={!loaded || isInputLiveRadio || playState === PlayState.Error}>
            {loaded && type !== RangeType.MobileMini && playState !== PlayState.Error && (
                <input
                    ref={inputRef}
                    type="range"
                    className="hiddenRange"
                    min={0}
                    max={inputRange}
                    step={1}
                    disabled={disabled}
                    onMouseUp={handleOnChange}
                    onTouchEnd={handleOnChange}
                    onInput={handleOnInput}
                />
            )}
            <div className={`visualRange --inputLiveRadio-${isInputLiveRadio}`}>
                <div className={`progress --hideThumb-${isInputLiveRadio}`} key={random} style={progressStyle} />
            </div>
        </div>
    );
};
