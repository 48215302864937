import { logQueueActionEvent } from "./logQueueActionEvent";
import type { QueueActionType } from "../properties/event/queueActionProperties";
import { store } from "global";
import { ShuffleState } from "models/view";

export function logShuffleUnshuffleEvent() {
    const { shuffle } = store.getState().player;
    const isActionFromDesktopPlayer = shuffle !== ShuffleState.Light;
    if (!isActionFromDesktopPlayer) return;
    const type: QueueActionType = shuffle === ShuffleState.Off ? "Unshuffle" : "Shuffle";
    logQueueActionEvent(type);
}
