import { store } from "global";
import { isAudioLoaded } from "services/player/helpers";
import { AudioInputType } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";

export const getAudioInputItemByPredicate = (audios: AudioInputItemModel[], predicate: (item: AudioInputItemModel) => boolean): AudioInputItemModel | null => {
    const inputItem = audios.find(predicate) ?? null;
    return inputItem;
};

export const getAudioInputItemByPredicateFromState = (predicate: (item: AudioInputItemModel) => boolean): AudioInputItemModel | null => {
    const audios = store.getState().audioInput.audios;
    return getAudioInputItemByPredicate(audios, predicate);
};

export function getAudioByInputItemId(audios: AudioInputItemModel[], uniqueId: string) {
    return getAudioInputItemByPredicate(audios, (item) => {
        if (item.input === AudioInputType.LiveRadio) return item.radioId === uniqueId;
        return item.queueId === uniqueId;
    });
}

export function getAudioByInputItemIdFromState(uniqueId: string) {
    const audios = store.getState().audioInput.audios;
    return getAudioByInputItemId(audios, uniqueId);
}

export const getAudioByIdFromState = (audioId: string) => store.getState().audioInput.audios.find((audio) => audio.audioId === audioId);

export const getAudioByChromecastIdFromState = (chromecastId: number) => store.getState().audioInput.audios.find((audio) => audio.chromecastId === chromecastId);

export const getLoadedInputAudio = (): AudioInputItemModel[] => {
    return store.getState().audioInput.audios.filter((item) => isAudioLoaded(item.playState));
};
