import {
    addToQueue,
    dropTrack,
    removeItemFromQueue,
    addEndlessPlayToQueue,
    updateEndlessPlay,
    replaceQueueAndPlayTrack,
    clearPriorityQueue,
    playQueuePlayTrack,
    clearQueue,
    addMixRadioTracksToQueue,
    moveEndlessPlayToFlowLane
} from "./actions";
import { audioEnded } from "./actions/audioEnded";
import { playFromAudioChange } from "./actions/playFromAudioChange";
import { skipOnFail } from "./actions/skipOnFail";
import { isInputPlayQueueFromState } from "../../service/helpers";
import { messageBus } from "global";
import {
    PLAYQUEUE_UPDATE,
    PLAYQUEUE_ADD,
    PLAYQUEUE_DROP,
    PLAYQUEUE_SET_AND_PLAY,
    PLAYQUEUE_ENDLESSPLAY_UPDATE,
    AUDIO_OUTPUT_AUDIO_ENDED,
    USER_LOGGED_OUT,
    PLAYQUEUE_CLEAR,
    PLAYQUEUE_REMOVE_TRACK,
    PLAYQUEUE_ADD_TO_LAST,
    AUDIO_INPUT_AUDIO_PLAY_CHANGE,
    PLAYQUEUE_PLAY_TRACK,
    AUDIO_INPUT_PLAY_AUDIO,
    PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE,
    PLAYQUEUE_CLEAR_PRIORITY_LANE
} from "global/actions";
import { isControllerLocalFromState } from "services/player/controllers/service/helpers";
import { AudioControllerType } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { AudioContextAction } from "models/app/player/AudioContext";
import { QueueLane } from "models/app/player/input";

export const initPlayQueueAudioInputService = () => {
    messageBus.subscribeEvery(PLAYQUEUE_PLAY_TRACK, async (msg) => {
        const { queueId, isPreviewLane, context } = msg.payload;
        playQueuePlayTrack(queueId, isPreviewLane ?? false, context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_ADD_TO_LAST, async (msg) => {
        const { playable, context, playableContext } = msg.payload;
        addToQueue(playable, null, QueueLane.Flow, true, playableContext, context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_ADD, async (msg) => {
        const { playable, context, playableContext } = msg.payload;
        addToQueue(playable, null, QueueLane.Priority, true, playableContext, context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_DROP, async (msg) => {
        dropTrack(msg.payload);
    });

    messageBus.subscribeEvery(PLAYQUEUE_CLEAR_PRIORITY_LANE, (msg) => {
        clearPriorityQueue(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_SET_AND_PLAY, async (msg) => {
        replaceQueueAndPlayTrack(msg.payload);
    });

    messageBus.subscribeEvery(PLAYQUEUE_ENDLESSPLAY_UPDATE, async (msg) => {
        addEndlessPlayToQueue(msg.payload);
    });

    messageBus.subscribeEvery(PLAYQUEUE_CLEAR, async (msg) => {
        await clearQueue(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_REMOVE_TRACK, async (msg) => {
        const { queueId, isPreviewLane, context } = msg.payload;
        removeItemFromQueue(queueId, isPreviewLane, context);
    });

    messageBus.subscribeEvery(PLAYQUEUE_UPDATE, async (msg) => {
        const { context, blockEndlessPlayUpdate } = msg.payload;

        if (!blockEndlessPlayUpdate) {
            await updateEndlessPlay(context);
            await moveEndlessPlayToFlowLane(context);
        }
        await addMixRadioTracksToQueue(context);
    });

    messageBus.subscribeEvery(PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE, async (msg) => {
        const { context, controller } = msg.payload;

        if (controller === AudioControllerType.Chromecast) {
            await updateEndlessPlay(context);
            await moveEndlessPlayToFlowLane(context);
        }
        await addMixRadioTracksToQueue(context);
    });

    messageBus.subscribeEvery(AUDIO_INPUT_PLAY_AUDIO, async (msg) => {
        if (!isInputPlayQueueFromState()) return;
        const { audio, context } = msg.payload;
        playFromAudioChange(audio, context);
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_PLAY_CHANGE, async (msg) => {
        if (!isControllerLocalFromState()) return;
        if (!isInputPlayQueueFromState()) return;

        const { audio, playStateBefore } = msg.payload;
        skipOnFail(audio, playStateBefore);
    });

    messageBus.subscribeEvery(AUDIO_OUTPUT_AUDIO_ENDED, async (msg) => {
        if (!isInputPlayQueueFromState()) return;
        const { audioId, context } = msg.payload;

        audioEnded(audioId, context);
    });

    messageBus.subscribeEvery(USER_LOGGED_OUT, async () => {
        const context: AudioContextModel = { action: AudioContextAction.UserLogOut, trace: null };
        clearQueue(context);
    });
};
