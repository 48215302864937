import { h } from "preact";
import { pageContent } from "global/constants/pageContent";
import { useTrackRadioPage } from "services/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { usePageContext, useSectionContext, useTrackPlayables } from "components/shared/hooks";
import { ResourceSection } from "components/organisms/resourceSection";
import { ProductPageTemplate } from "components/templates/productPage";

interface Props {
    trackId: string;
}

export const TrackRadioPage = ({ trackId }: Props) => {
    const query = useTrackRadioPage({ id: trackId, first: pageContent.trackRadioPageItemsLimit });
    const product = query.model;

    const page = usePageContext({ type: ContentType.TrackRadio, resource: product, root: null, done: query.success });
    const top = useSectionContext(ContentType.TrackRadio, product, page, 0, ResourceDisplayType.Top);
    const tracks = useSectionContext(ContentType.TrackRadio, product, page, 1, ResourceDisplayType.List);

    const playables = useTrackPlayables(product?.tracks?.items ?? null, product);

    return (
        <ProductPageTemplate query={query} type={ContentType.TrackRadio} section={top} isUserOwned={false}>
            <ResourceSection resources={playables} context={tracks} />
        </ProductPageTemplate>
    );
};
