import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getLayoutItemContentPagination, useLayoutItemPage } from "services/backend/backend";
import { isPlayable } from "services/playable";
import { getResourceFromUnion } from "services/resource";
import type { LocationContentType } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { getLayoutPageItemConnectionModelItems } from "models/domain/helpers";
import type { LayoutItemContentContentPreviewModel, LayoutItemPageModel, LayoutItemSectionModel } from "models/domain/layoutPage";
import { ContentType } from "models/ModelType";
import type { AlternateAutoPlayFn } from "components/templates/showAllPage";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    pageId: string;
    itemId: string;
    pageType: LocationContentType;
    resourceId?: string;
}

export const LayoutPageItemShowAllPage = ({ pageId, itemId, pageType, resourceId }: Props) => {
    const query = useLayoutItemPage({ first: getPaginationInit(), pageId, itemId });
    const product = query.model;
    const connection = getLayoutPageItemConnectionModelItems(product?.content ?? null);
    const fetchFn = useCallback((first: number, after: string) => getLayoutItemContentPagination({ first, after, pageId, itemId }), [pageId, itemId]);

    const type = product?.contentType ?? ContentType.LayoutItem;
    const contentType = product?.content?.contentType ?? null;

    const display = useDisplayType(contentType);

    const autoPlayResource = useAutoPlayResource({ product, items: connection?.items ?? null, resourceId });
    const getAutoPlayItem: AlternateAutoPlayFn<LayoutItemContentContentPreviewModel> = useCallback(() => {
        return { type, resource: autoPlayResource };
    }, [autoPlayResource, type]);

    return <ShowAllPageTemplate query={query} type={type} pageType={pageType} items={connection} fetchFn={fetchFn} display={display} alternateAutoPlayFn={getAutoPlayItem} />;
};

function useDisplayType(type: NonNullable<LayoutItemSectionModel["content"]>["contentType"] | null): ResourceDisplayType {
    return useMemo(() => {
        switch (type) {
            case ContentType.AlbumsLayoutItemContent:
            case ContentType.ArtistsLayoutItemContent:
            case ContentType.ChartsLayoutItemContent:
            case ContentType.LiveRadioCategoriesLayoutItemContent:
            case ContentType.LiveRadiosLayoutItemContent:
            case ContentType.MixRadiosLayoutItemContent:
            case ContentType.MixedContentLayoutItemContent:
            case ContentType.PlaylistCategoriesLayoutItemContent:
            case ContentType.PlaylistsLayoutItemContent:
            case ContentType.RecommendationsLayoutItemContent:
            case ContentType.BannerLayoutItemContent: // todo?
            case null:
                return ResourceDisplayType.Grid;
            case ContentType.TracksLayoutItemContent:
                return ResourceDisplayType.List;
        }
    }, [type]);
}

interface UseAutoPlayResourceProps {
    resourceId: string | undefined;
    product: LayoutItemPageModel | null;
    items: LayoutItemContentContentPreviewModel[] | null;
}

function useAutoPlayResource({ resourceId, items, product }: UseAutoPlayResourceProps) {
    return useMemo(() => {
        if (!product || !items) return null;
        const item = items.find((item) => item.id === resourceId) ?? null;
        if (!item) return null;
        const resource = getResourceFromUnion(item, product);
        if (!resource || !isPlayable(resource)) return null;
        return resource;
    }, [product, items, resourceId]);
}
