import { h } from "preact";
import { useContext } from "preact/hooks";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import { AddToPlaylistLink, AddToQueueLink, AddToLastQueueLink } from "../links";
import { ResourceMenuTop } from "./tops";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { TrackRecommendationModel } from "models/domain";

interface Props {
    trackRecommendation: TrackRecommendationModel | null;
    context: PreviewContextModel;
}

export const TrackRecommendationContextMenu = ({ context, trackRecommendation }: Props) => {
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <AddToPlaylistLink playable={trackRecommendation} context={context} showIcon={showIcon} />
            <AddToQueueLink playable={trackRecommendation} context={context} showIcon={showIcon} />
            <AddToLastQueueLink playable={trackRecommendation} context={context} showIcon={showIcon} />
        </Sheet>
    );
};
