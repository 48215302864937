import { SetAnalyticsProp, createAnalyticsData, SetFirstAnalyticsProp, IncrementAnalyticsProp } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";
import { SearchResultAreaSource } from "components/molecules/searchResultArea";

export type AnalyticsSearchContentProperties = {
    searchName: string | null;
    searchLength: number | null;
    searchGroupRank: number | null;
    resultType: AnalyticsKey;
};

const getResultType = (source: SearchResultAreaSource, preview: PreviewContextModel): AnalyticsKey => {
    if (source === SearchResultAreaSource.History) return "History";
    if (preview.section.type === ContentType.SearchMixedResults) return "BestResult";
    return "Group";
};

export function getAnalyticsSearchContentProperties(criterion: string | null, source: SearchResultAreaSource, preview: PreviewContextModel): AnalyticsSearchContentProperties {
    const properties: AnalyticsSearchContentProperties = {
        searchName: criterion,
        searchLength: criterion?.length ?? null,
        searchGroupRank: preview.rank != null ? preview.rank + 1 : null,
        resultType: getResultType(source, preview)
    };

    return properties;
}

export function convertAnalyticsSearchContentProperties(properties: AnalyticsSearchContentProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "SearchName", properties.searchName as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "SearchLength", properties.searchLength, true);
    SetAnalyticsProp(data, "event", "SearchGroupRank", properties.searchGroupRank, true);
    SetAnalyticsProp(data, "event", "ResultType", properties.resultType);
    SetFirstAnalyticsProp(data, "user", "FirstSearchContent", new Date());
    SetAnalyticsProp(data, "user", "LastSearchContent", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfSearches");

    return data;
}
