import { getCastSession } from "../../setup";
import type { LogProps } from "services/logger/initLoggerService";
import { log, LogTag } from "services/logger/initLoggerService";

export async function castLoadMedia(request: chrome.cast.media.LoadRequest): Promise<boolean> {
    const session = getCastSession();
    if (!session) return false;

    log.debug([LogTag.Chromecast], () => ({ code: "web-211029-1410", msg: `castLoadMedia...` }));

    console.log(request);

    const result = await session.loadMedia(request);
    const ok = result == undefined;

    const logProps: LogProps = { tags: [LogTag.Chromecast], code: "web-200106-1615", msg: `...castLoadMedia, ok: ${ok}`, data: { ok, request, result } };
    if (ok) log.info(logProps);
    else log.error(logProps);

    return ok;
}
