import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { Link } from "../Link";
import { dispatch } from "global";
import { SET_MY_MUSIC_SORTING } from "global/actions";
import type { FavoriteAlbumsSorting, FavoriteArtistsSorting, FavoriteTracksSorting } from "generated/graphql-types";
import type { RootModel } from "models/app";
import { IconName } from "components/atoms/icon";

interface Props {
    sorting: FavoriteAlbumsSorting | FavoriteArtistsSorting | FavoriteTracksSorting;
    title: string;
    type: "albumsSorting" | "artistsSorting" | "tracksSorting";
}

export const FavoritesSortingLink = ({ sorting, title, type }: Props) => {
    const currentSorting = useSelector((root: RootModel) => root.ui[type]);
    const onClick = useCallback(() => {
        dispatch({ type: SET_MY_MUSIC_SORTING, payload: { [type]: sorting } });
    }, [type, sorting]);

    return (
        <Link closeOnClick icon={currentSorting == sorting ? IconName.Checkmark : IconName.None} onClick={currentSorting !== sorting ? onClick : undefined}>
            {title}
        </Link>
    );
};
