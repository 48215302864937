export type Message = {
    type: string;
};

export type MessageListener = (message: Message) => (void | Promise<void>);
export type Unsubscriber = () => void;

export class MessageBus {
    protected globalListeners: MessageListener[] = [];
    protected listeners: { [actionType: string]: MessageListener[] } = {};
    protected promise: Promise<void>;
    protected inStoreSync = 0;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected resolve: () => void = () => {};

    constructor() {
        this.promise = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    public setStoreSync = (): void => {
        this.inStoreSync++;
    };

    public dispatch = (message: Message): void => {
        try {
            if (typeof this.listeners[message.type] === "object") {
                this.listeners[message.type].forEach((listener) => listener(message));
            }
            this.globalListeners.forEach((listener) => listener(message));
        } finally {
            // if (this.inStoreSync === 0) log.error({code: "web-211003-1856", msg: DefaultLogMessage.UnexpectedValue});
            if (this.inStoreSync > 0) this.inStoreSync--;
            if (this.inStoreSync === 0) {
                const _resolve = this.resolve;
                this.promise = new Promise((resolve) => {
                    this.resolve = resolve;
                });
                _resolve();
            }
        }
    };

    public sync = async () => {
        if (this.inStoreSync === 0) return;
        await this.promise;
    };

    protected unsubscribeListener = (messageType: string, listener: MessageListener) => {
        if (typeof this.listeners[messageType] === "object") {
            const index: number = this.listeners[messageType].indexOf(listener);
            if (index > -1) {
                this.listeners[messageType].splice(index, 1);
            }
        }
    };

    private unsubscribeGlobalListener = (listener: MessageListener) => {
        const index: number = this.globalListeners.indexOf(listener);
        if (index > -1) {
            this.globalListeners.splice(index, 1);
        }
    };

    public cancelAll = () => {
        this.globalListeners = [];
        this.listeners = {};
    };

    public subscribeAll = (listener: MessageListener): Unsubscriber => {
        this.globalListeners.push(listener);
        return () => this.unsubscribeGlobalListener(listener);
    };

    public subscribeOneGeneric = (messageType: string, listener: MessageListener): Unsubscriber => {
        if (typeof this.listeners[messageType] !== "object") {
            this.listeners[messageType] = [];
        }

        const emitAndUnsubscribe = (message: Message) => {
            this.unsubscribeListener(messageType, emitAndUnsubscribe);
            listener(message);
        };

        this.listeners[messageType].push(emitAndUnsubscribe);
        return () => this.unsubscribeListener(messageType, emitAndUnsubscribe);
    };

    public subscribeEveryGeneric = (messageType: string, listener: MessageListener): Unsubscriber => {
        if (typeof this.listeners[messageType] !== "object") {
            this.listeners[messageType] = [];
        }
        this.listeners[messageType].push(listener);
        return () => this.unsubscribeListener(messageType, listener);
    };
}
