import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { FAVORITES_UPDATED, USER_LOGGED_IN } from "global/actions";
import { messageBus, useTranslations } from "global/config";
import { getPaginationInit } from "global/constants/pagination";
import { useMessageBus } from "global/hooks";
import { getFavoriteTracks, useFavoriteTracksPage } from "services/backend";
import { unSelect, useMultiselect } from "services/selection";
import { useDisplayLoggedIn } from "services/user";
import type { RootModel } from "models/app";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { useCoalesced } from "components/shared/hooks";
import { MyMusicTracksSortingContextMenu } from "components/organisms/contextMenu/menus";
import { MyMusicPageTemplate } from "components/templates/myMusicPage";
//TODO: should we rename this
export const MyMusicTracksPage = () => {
    const orderBy = useSelector((root: RootModel) => root.ui.tracksSorting);
    const [criterion, setCriterion] = useState<string | null>(null);

    const translations = useTranslations();
    const skip = !useDisplayLoggedIn();
    const query = useFavoriteTracksPage({ first: getPaginationInit(), after: null, orderBy, criterion }, skip);
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string | null) => (product ? getFavoriteTracks({ first, after, orderBy, criterion }) : null), [product, orderBy, criterion]);

    const tracks = useCoalesced(product?.tracks ?? null);

    const multiselect = useMultiselect(tracks?.items ?? null, ContentType.MyMusicTracks);

    messageBus.subscribeEvery(USER_LOGGED_IN, (msg) => {
        if (!msg.payload.isNewUser) return;
        query.refetch(false);
    });

    useMessageBus(FAVORITES_UPDATED, (message) => {
        if (multiselect && message.payload.changes.operation === "remove") {
            unSelect(multiselect, message.payload.changes.playables);
        }
        if (message?.payload?.changes.playables.find((n) => n.contentType === ContentType.TrackPlayable || n.contentType === ContentType.Track)) {
            query.refetch(false);
        }
    });

    return (
        <MyMusicPageTemplate
            multiselect={multiselect}
            onFilterChanged={setCriterion}
            placeholderTitle={translations.MyMusicTracksPlaceholderTitle}
            placeholderSubtitle={translations.MyMusicTracksPlaceholderSubtitle}
            placeholderFilter={translations.FilterTracks}
            placeholderFilterNoResult={translations.FilterNoTracksFound}
            placeholderFilterNoResultSubtitle={translations.SearchNoResultHint}
            query={query}
            productType={ContentType.MyMusicTracks}
            resources={tracks}
            criterion={criterion}
            resourcesFetchFn={fetchFn}
            displayType={ResourceDisplayType.List}
            sortingMenu={<MyMusicTracksSortingContextMenu />}
        />
    );
};
