import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import "./TextArea.scss";
import { TextInputState } from "../textInput";

interface Props {
    className?: string;
    disabled: boolean;
    maxLength: number;
    onChange: (value: string) => void;
    onFocus: () => void;
    onBlur: () => void;
    placeholder?: string;
    spellcheck?: boolean;
    state?: TextInputState;
    value: string;
}

export const TextArea = ({ spellcheck = false, disabled, maxLength, className, value: propVal, onChange, onFocus, onBlur, state = TextInputState.Default, placeholder }: Props) => {
    const [value, setValue] = useState(propVal ?? "");
    useEffect(() => setValue(propVal), [propVal]);

    const input = useCallback(
        (value: string) => {
            setValue(value);
            onChange(value);
        },
        [setValue, onChange]
    );

    return (
        <div className={`textArea ${className ?? ""} --state-${state}`}>
            <textarea
                maxLength={maxLength}
                placeholder={placeholder}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onInput={(ev) => input((ev as any).target.value)}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                data-gramm="false"
                data-gramm_editor="false"
                // eslint-disable-next-line react/no-unknown-property
                spellcheck={spellcheck}
                value={value} />
        </div>
    );
};
