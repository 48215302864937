export enum ThemeSetting
{
    Light = "light",
    Dark = "dark",
    Auto = "auto"
}

export enum Theme
{
    Light = "light",
    Dark = "dark"
}