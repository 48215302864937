import { submitModal, dismissModal } from ".";
import type { TranslationKey } from "global/config";
import { ButtonDesign } from "components/atoms/controls/button";

export interface ModalButton {
    design: ButtonDesign.PrimarySmall | ButtonDesign.TextBrandMedium;
    title: TranslationKey;
    action?: (() => (void)) | (() => Promise<boolean>);
}

export const modalButtonTryAgain = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "TryAgain",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitAdd = (data: string): ModalButton => ({
    title: "Add",
    action: () => submit(undefined, data),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitAddAll = (data: string): ModalButton => ({
    title: "Button_AddAll",
    action: () => submit(undefined, data),
    design: ButtonDesign.TextBrandMedium
});
export const modalButtonSubmitAddMissing = (data: string): ModalButton => ({
    title: "Button_AddMissing",
    action: () => submit(undefined, data),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitOK = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "Button_Ok",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitMaintenance = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "MaintenanceBannerPopupClose",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitClose = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "GlobalCloseName",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitSend = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "GlobalSubmitName",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitContinue = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "Continue",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitContinueAnyways = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "ModalButtonContinueAnywaysText",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});
export const modalButtonSubmitGetStarted = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "GlobalGetStartedAction",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});

export const modalButtonDismissCancel = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "Cancel",
    action: () => dismiss(customAction),
    design: ButtonDesign.TextBrandMedium
});
export const modalButtonEditPlaylistSave = (customAction?: () => Promise<boolean>): ModalButton => ({
    title: "EditPlaylistSave",
    action: () => submit(customAction),
    design: ButtonDesign.PrimarySmall
});

const submit = async (customAction?: () => Promise<boolean>, data: string | null = null) => {
    if (!customAction || (await customAction()) === true) submitModal("Buttons.submit", data);
};
const dismiss = async (customAction?: () => Promise<boolean>, data: string | null = null) => {
    if (!customAction || (await customAction()) === true) dismissModal("Buttons.dismiss", data);
};
