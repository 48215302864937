import { getAppSession } from "./appSessionService";
import { getStateDbName } from "./storageKeys";

export function isMySession(storageId: string) {
    return getAppSession()?.storageId === storageId;
}

export function saveData(key: string, value: string | null) {
    if (!value) localStorage.removeItem(key);
    else localStorage.setItem(key, value);
}

export function getData(key: string) {
    return localStorage.getItem(key) ?? null;
}

export function parseData<T>(data: string | null): T | null {
    if (data == null || data == "") return null;
    return JSON.parse(data);
}

export function getSessionDbKey() {
    const storageId = getAppSession().storageId;
    return getStateDbName(storageId);
}
