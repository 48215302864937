import { h } from "preact";
import { useRef } from "preact/hooks";
import "./PreviewSquare.scss";
import type { PreviewProps } from "./PreviewProps";
import { usePreviewScrollToHighlight } from "./usePreviewScrollToHighlight";
import { PreviewSquareTestLocator } from "global/constants";
import { getIsCoverRounded } from "services/resource";

export const PreviewSquare = ({ className, context, image, links, resourceType: contentType, available, highlight }: PreviewProps) => {
    const ref = useRef<HTMLDivElement>(null);

    usePreviewScrollToHighlight({ ref, highlight });
    const center = getIsCoverRounded(context.type, context.resource);

    return (
        <div
            ref={ref}
            className={`previewSquare c9f --type-${contentType} --center-${center} ${className ?? ""} --available-${available} --highlight-${highlight}`}
            data-cy={PreviewSquareTestLocator(contentType)}>
            <figure>
                {image}
                {links.length > 0 && (
                    <figcaption>
                        {links.map((link, index) => (
                            <div key={index}>{link}</div>
                        ))}
                    </figcaption>
                )}
            </figure>
        </div>
    );
};
