import { h } from "preact";
import { useContext } from "preact/hooks";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import { ShareLink } from "../links/ShareLink";
import { ResourceMenuTop } from "./tops";
import { PreviewContextModel } from "models/app/resourceContextModel";
import { MixRadioPreviewModel } from "models/domain/MixRadioModel";

interface Props {
    mixRadio: MixRadioPreviewModel | null;
    context: PreviewContextModel;
}

export const MixRadioContextMenu = ({ context }: Props) => {
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <ShareLink context={context} showIcon={showIcon} />
        </Sheet>
    );
};
