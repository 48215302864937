import { logMuteUnmuteEvent } from "./logMuteUnmuteEvent";
import { logPlayerActionEvent } from "./logPlayerActionEvent";
import { logPlayPauseToEvent } from "./logPlayPauseToEvent";
import { tryLogVolumeSeekEvent } from "./tryLogVolumeSeekEvent";
import { messageBus } from "global";
import { PLAYER_TOGGLE_PLAY, PLAYER_SET_MUTE, PLAYER_SEEK, PLAYER_SET_VOLUME, PLAYER_TOGGLE_VOLUME_EXPAND } from "global/actions";

export const initPlayerActionAnalyticsService = () => {
    messageBus.subscribeEvery(PLAYER_TOGGLE_PLAY, () => {
        logPlayPauseToEvent();
    });

    messageBus.subscribeEvery(PLAYER_SET_MUTE, () => {
        logMuteUnmuteEvent();
    });
    messageBus.subscribeEvery(PLAYER_SEEK, () => {
        logPlayerActionEvent("TrackSeek");
    });

    messageBus.subscribeEvery(PLAYER_SET_VOLUME, () => {
        tryLogVolumeSeekEvent({});
    });

    messageBus.subscribeEvery(PLAYER_TOGGLE_VOLUME_EXPAND, () => {
        logPlayerActionEvent("ExpandVolume");
    });
};
