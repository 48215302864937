import type { Action } from "../actions";
import { NOTIFICATION_MAINTENANCE_DATA_UPDATE } from "../actions";
import { NOTIFICATION_BANNER_TOGGLE, NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING, NOTIFICATION_WELCOME_MESSAGE_SHOWN, REHYDRATE } from "global/actions";
import type { BannerWarning, NotificationsModel } from "models/app";

const initialState: NotificationsModel = {
    banners: [],
    welcomeMessageShown: false,
    pwaInstallMessageWaiting: false,
    maintenanceBannerData: null
};

export function notificationsReducer(state: NotificationsModel = initialState, action: Action): NotificationsModel {
    switch (action.type) {
        case NOTIFICATION_BANNER_TOGGLE: {
            const { banners } = state;
            const { warning, show } = action.payload;

            let newBanners: BannerWarning[];
            const has = banners.indexOf(warning) !== -1;
            if (show == has) return state;

            if (show) newBanners = [...banners, warning];
            else newBanners = banners.filter((b) => b !== warning);

            return { ...state, banners: newBanners };
        }
        case NOTIFICATION_MAINTENANCE_DATA_UPDATE: {
            return { ...state, maintenanceBannerData: action.payload.data };
        }
        case NOTIFICATION_PWA_INSTALL_MESSAGE_WAITING:
            return { ...state, pwaInstallMessageWaiting: action.payload.waiting };
        case NOTIFICATION_WELCOME_MESSAGE_SHOWN:
            return { ...state, welcomeMessageShown: true };
        case REHYDRATE: {
            state = action.payload?.notifications ?? state;
            if (state.pwaInstallMessageWaiting == undefined) state = { ...state, pwaInstallMessageWaiting: false };
            if (state.welcomeMessageShown == undefined) state = { ...state, welcomeMessageShown: false };
            if (state.maintenanceBannerData == undefined) state = { ...state, maintenanceBannerData: null };
            return state;
        }
        default:
            return state;
    }
}
