import { h } from "preact";
import { dispatch } from "global";
import {
    ANALYTICS_PLAYQUEUE_CLEAR_QUEUE,
    ANALYTICS_PLAYQUEUE_SAVE_TO_PLAYLIST,
    PLAYQUEUE_CLEAR,
    SET_DESKTOP_QUEUE_OPEN,
    SET_MAXIPLAYER_OPEN,
    SET_MINIPLAYER_OPEN
} from "global/actions";
import { useTranslations } from "global/config";
import { animations, TestLocator } from "global/constants";
import { openMenuByElement } from "services/contextMenus";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { DesktopQueueOpen, MobileMaxiPlayerOpen } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { ModalOpenResult } from "models/view/AppModalModel";
import { MediaSize, useAppMediaSize, useRefUpdate } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { AddQueueToPlaylistContextMenu } from "components/organisms/contextMenu/menus";
import { showClearQueueModal } from "components/organisms/modal/modals/ClearQueueModal";

interface Props {
    historyClick: () => void;
    historyOpen: boolean;
    disableHistoryButton: boolean;
    isQueueEmpty: boolean;
}

export const TrackQueueActions = ({ historyClick, historyOpen, disableHistoryButton, isQueueEmpty }: Props) => {
    const translations = useTranslations();
    const isMobile = useAppMediaSize() === MediaSize.Mobile;

    const buttonRef = useRefUpdate<HTMLButtonElement>();

    const showQueueMenu = () => {
        dispatch({ type: ANALYTICS_PLAYQUEUE_SAVE_TO_PLAYLIST });
        if (!buttonRef.current) {
            log.error({ code: "web-211116-1247", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        openMenuByElement(<AddQueueToPlaylistContextMenu />, buttonRef.current, false);
    };

    const clearQueue = async () => {
        dispatch({ type: ANALYTICS_PLAYQUEUE_CLEAR_QUEUE });
        const result = await showClearQueueModal();
        if (result === ModalOpenResult.Submit) {
            dispatch({ type: SET_DESKTOP_QUEUE_OPEN, payload: { open: DesktopQueueOpen.Closed } });
            dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });
            dispatch({ type: SET_MINIPLAYER_OPEN, payload: { open: false } });
            const timeOutMs = isMobile ? animations.durationLong : 0;
            setTimeout(() => dispatch({ type: PLAYQUEUE_CLEAR, payload: { context: { action: AudioContextAction.UserPlayQueueClear, trace: null } } }), timeOutMs);
        }
    };

    return (
        <div className="queueActions">
            <Button className={"clearQueue"} onClick={clearQueue} disabled={isQueueEmpty} design={ButtonDesign.LightMicro} testLocator={TestLocator.QueueClearButton}>
                {translations.QueueClearButton}
            </Button>
            <Button
                className={"history"}
                disabled={disableHistoryButton}
                toggled={historyOpen}
                onClick={historyClick}
                design={ButtonDesign.LightMicro}
                testLocator={TestLocator.QueueHistoryButton}>
                {translations.QueueHistoryButtonTextMobile}
            </Button>
            <Button
                ref={buttonRef}
                className={"saveQueue"}
                disabled={isQueueEmpty}
                onClick={showQueueMenu}
                design={ButtonDesign.LightMicro}
                testLocator={TestLocator.QueueSaveButton}>
                {translations.QueueSave}
            </Button>
        </div>
    );
};
