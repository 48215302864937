import { useCallback, useMemo, useState } from "preact/hooks";
import { getSortOptions } from "services/sort/sortService";
import type { SortOption, SortOptionsModel } from "models/app/SortOption";
import type { ContentType } from "models/ModelType";

interface Props {
    context: ContentType.ArtistAlbums | ContentType.ArtistSingles | ContentType.AlbumArtistAlbums | ContentType.ArtistTracks | ContentType.ArtistAppearsOn;
}

export const useSortOptions = ({ context }: Props): SortOptionsModel => {
    const { options, initial } = getSortOptions(context);
    const [selected, setSelected] = useState(initial);
    const onChange = useCallback((option: SortOption) => {
        setSelected(option);
    }, []);

    const sortOptions: SortOptionsModel = useMemo(() => ({ options, selected, onChange }), [options, selected, onChange]);
    return sortOptions;
};
