import { useState, useLayoutEffect } from "preact/hooks";
import { getSecondsPlayed } from "services/player/helpers";
import { getAudioPropertiesFromState, useAudioProperties } from "components/shared/hooks";

export function useSecondsPlayedFromState(): { secondsPlayed: number; duration: number } {
    const { playState, duration, position } = useAudioProperties();
    const [secondsPlayed, setSecondsPlayed] = useState(0);

    useLayoutEffect(() => {
        let timeOut: number | null = null;
        const updateSecondsPlayed = () => {
            timeOut = null;
            const { seconds, next } = getSecondsPlayed({ playState, position });
            setSecondsPlayed(seconds);
            if (next) {
                timeOut = window.setTimeout(updateSecondsPlayed, next * 1000 + 1);
            }
        };
        updateSecondsPlayed();
        return () => {
            if (timeOut) clearTimeout(timeOut);
        };
    }, [playState, position]);

    return { secondsPlayed, duration };
}
export function getSecondsPlayedFromState(): { secondsPlayed: number; duration: number } {
    const { playState, duration, position } = getAudioPropertiesFromState();

    const secondsPlayed = getSecondsPlayed({ playState, position }).seconds;

    return { secondsPlayed, duration };
}
