import { currentPlaybackStreamTicket, setActivePlayback } from "./setActivePlayback";
import { initPlayerStartedByUserEvent } from "./userTriggeredPlaybackEvent";
import { dispatch, messageBus, store } from "global";
import { PLAYER_CONCURRENCY_LOST, PLAYER_SET_PLAY, PLAYER_STARTED_BY_USER } from "global/actions";
import type { PlayerConcurrencyLostAction } from "global/actions/actionTypes";
import { mutateGetPlaybackStream } from "services/backend";
import { getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import { getIsLoggedInFromState } from "services/user";
import { AudioControllerType, PlayState } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import { showConcurrencyModal } from "components/organisms/concurrencyModal";

let throttled = false;
// let concurrencyHasBeenAway = false;

// export function getConcurrencyHasBeenAway() {
//     return concurrencyHasBeenAway;
// }

// export function setConcurrencyHasBeenAway(value: boolean) {
//     concurrencyHasBeenAway = value;
// }

export function initConcurrency() {
    initPlayerStartedByUserEvent();
    messageBus.subscribeEvery(PLAYER_STARTED_BY_USER, onPlaybackTriggered);

    // todo: if we create a PLAYER_SWITCHED_TRACK event later, we'll want to subscribe to it here

    // This is sent by the GraphQL subscription
    messageBus.subscribeEvery(PLAYER_CONCURRENCY_LOST, onPlaybackLost);
}

async function onPlaybackTriggered() {
    const isLoggedIn = await getIsLoggedInFromState();
    const isCasting = store.getState().player.controller === AudioControllerType.Chromecast;
    if (!isCasting && isLoggedIn) {
        if (throttled) {
            return;
        }
        throttled = true;
        setTimeout(() => {
            throttled = false;
        }, 1000);

        const ticket = currentPlaybackStreamTicket();
        if (ticket == null) {
            await setActivePlayback();
            return;
        }
        const ticketResponse = await mutateGetPlaybackStream({ id: ticket });
        if (ticketResponse !== true) {
            await setActivePlayback();
        }
    }
}

// export async function concurrencyLost() {
//     await setActivePlayback();
//     const id = currentPlaybackStreamId();
//     const isStillActive = id == null ? true : await getPlaybackStream({ id });
//     if (isStillActive) return;

//     setConcurrencyHasBeenAway(true);
// }

function onPlaybackLost(event: PlayerConcurrencyLostAction) {
    const state = getCurrentInputAudioItemFromState();
    const isCasting = store.getState().player.controller === AudioControllerType.Chromecast;
    if (!isCasting && state?.playState === PlayState.Playing) {
        dispatch({
            type: PLAYER_SET_PLAY,
            payload: {
                play: false,
                context: { action: AudioContextAction.ConcurrencyLost, trace: null }
            }
        });

        // setConcurrencyHasBeenAway(true);
        showConcurrencyModal(event.payload.deviceId, event.payload.friendlyDeviceName);
    }
}
