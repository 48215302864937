import type { NormalizedCacheObject, Operation } from "@apollo/client";
import { ApolloClient, ApolloLink, fromPromise, HttpLink, InMemoryCache, split } from "@apollo/client";
import type { GraphQLErrors } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition, Observable } from "@apollo/client/utilities";
import { sha256 } from "crypto-hash";
import type { GraphQLError } from "graphql";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { store } from "global";
import { WebAppBrand } from "shared/models";
import { headerNames } from "shared/services";
import { isFeatureEnabled, Feature } from "global/config";
import { getAppProperties, getGraphQlLanguageCode, getNewCorrelationId } from "services/app/appService";
import { getTokens } from "services/appSession/operations/sessionTokens";
import { fetchGraphqlContent } from "services/cache";
import type { Environment } from "services/environment";
import { environment, GraphqlEnvironmentName } from "services/environment";
import { log } from "services/logger/initLoggerService";
import { getUserIdFromState, onGqlUnauthenticated, tryRefreshToken } from "services/user";

let webSocketClient: SubscriptionClient | null = null;
let webSocketLink: WebSocketLink | null = null;
let webSocketCurrentUserId: string | null = null;

export let graphqlClient: ApolloClient<NormalizedCacheObject> | null = null;

export function initGraphQLClient() {
    if (graphqlClient) {
        log.error({ code: "web-210313-1516", msg: "graphlql client not null" });
        return;
    }
    try {
        graphqlClient = client(createApolloLink(environment));
    } catch (e) {
        log.error({ code: "web-220221-1316", msg: "error when creating graphlql client", error: e });
        throw e;
    }
}

export function ensureWebsocketRoutesToUser(userId: string) {
    // in order to reach the right server on environments with multiple servers,
    // we need to append a userid to the url.  this must be updated if the userid changes.

    if (graphqlClient && userId !== webSocketCurrentUserId) {
        closeWebsocket();
        // ideally we'd just update the WebSocketLink, but that's not allowed.
        // failing that, ideally we'd just recreate the whole link chain and set it on the same client, but that's not allowed.
        // instead we have to create a new client to update the websocket url...
        graphqlClient = client(createApolloLink(environment));
    }
}

export function closeWebsocket() {
    if (webSocketClient) {
        webSocketClient.unsubscribeAll();
        webSocketClient.close(true);
        webSocketClient = null;
    }
}

const uri = ({ graphqlEnvironmentName, apiPortalId }: Environment): string => {
    switch (graphqlEnvironmentName) {
        case GraphqlEnvironmentName.Dev:
            return `https://graphql-${apiPortalId}.dev.api.247e.com/graphql`;
        case GraphqlEnvironmentName.Local:
            return `https://localhost:44357/graphql/${apiPortalId}`;
        case GraphqlEnvironmentName.Prod:
        case GraphqlEnvironmentName.Stage:
        case GraphqlEnvironmentName.Test:
            return `https://graphql-${apiPortalId}.api.247e.com/graphql`;
    }
};

const standardHeadersLink = new ApolloLink((operation, forward) => {
    const { language, userAgent, appVersion, installationId, platform } = getAppProperties();
    const correlationId = getNewCorrelationId();
    const acceptLanguage = getGraphQlLanguageCode(language);
    const headers = {
        [headerNames.acceptLanguage]: acceptLanguage,
        [headerNames.userAgent]: userAgent,
        [headerNames.appVersion]: appVersion,
        [headerNames.installationId]: installationId,
        [headerNames.correlationId]: correlationId,
        [headerNames.platform]: platform
    };

    operation.setContext((context: Record<string, Record<string, string>>) => ({ ...context, headers: { ...context.headers, ...headers } }));

    return forward(operation);
});

const operationHeadersLink = new ApolloLink((operation, forward) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const operationType = (operation?.query?.definitions[0] as unknown as any)?.operation as string | undefined;
    if (operationType) {
        const headers: Record<string, string> = {};
        headers[headerNames.operationType] = operationType;
        operation.setContext((context: Record<string, Record<string, string>>) => ({ ...context, headers: { ...context.headers, ...headers } }));
    }

    const operationName = operation?.operationName as string | undefined;
    if (operationName) {
        const headers: Record<string, string> = {};
        headers[headerNames.operationName] = operationName;
        operation.setContext((context: Record<string, Record<string, string>>) => ({ ...context, headers: { ...context.headers, ...headers } }));
    }

    return forward(operation);
});

const apolloErrorResult = (errorMessage: string) => {
    const fetchResult = {
        errors: []
    };

    const linkResult = Observable.of(fetchResult).map(() => {
        throw new Error(errorMessage);
    });
    return linkResult;
};

const authHeadersLink = new ApolloLink((operation, forward) => {
    const getOrWaitForTokens = (async () => {
        if (operation.operationName === "userId") {
            // this particular query is called during token refresh, so we must not wait for the token-refresh to complete before running it
            return getTokens();
        }
        const tokens = await getTokens();
        if (tokens.refreshToken && !tokens.accessToken && !!store.getState().user.id) {
            // if something maliciously deleted the accesstoken, we need to get a new one
            const refreshResult = await tryRefreshToken();
            tokens.accessToken = refreshResult.accessToken;
        }
        return tokens;
    })();

    return fromPromise(getOrWaitForTokens).flatMap((tokens) => {
        const accessToken = tokens.accessToken;
        const authorizationValue = accessToken ? `Bearer ${accessToken}` : `Basic ${getGraphqlClientId()}`;
        const headers = { [headerNames.authorization]: authorizationValue };
        operation.setContext((context: Record<string, Record<string, string>>) => ({ ...context, headers: { ...context.headers, ...headers } }));
        return forward(operation);
    });
});

const correlationIdHeaderLink = new ApolloLink((operation, forward) => {
    if (operation.variables.correlationId) {
        // remove the correlationid from variables, and place it in a header instead.
        // (this seems to be the easiest way for the queries to provide this to the link.)
        const headers = { [headerNames.correlationId]: operation.variables.correlationId };
        delete operation.variables.correlationId;
        operation.setContext((context: Record<string, Record<string, string>>) => ({ ...context, headers: { ...context.headers, ...headers } }));
    }
    return forward(operation);
});

const refreshTokenLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        const isAuthError = isAuthenticationError(graphQLErrors);
        if (isAuthError) {
            return fromPromise(onGqlUnauthenticated()).flatMap(({ ok, accessToken }) => {
                if (!ok) return apolloErrorResult("unable to refresh token");
                const headers = {
                    [headerNames.authorization]: `Bearer ${accessToken}`
                };
                operation.setContext((context: Record<string, Record<string, string>>) => ({
                    ...context,
                    headers: { ...context.headers, ...headers }
                }));
                return forward(operation);
            });
        }
    }
});

const createHttpLink = (environment: Environment) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getQuery = (operation: Operation) => {
        const query = "";
        return query;
    };
    const httpLink = new HttpLink({ uri: (operation) => `${uri(environment)}${getQuery(operation)}`, fetch: fetchGraphqlContent });

    if (isFeatureEnabled(Feature.APQ)) {
        const persistentQueryLink = createPersistedQueryLink({ sha256 });
        return persistentQueryLink.concat(httpLink);
    }
    else {
        return httpLink;
    }
};

const createWsLink = (environment: Environment) => {
    webSocketCurrentUserId = getUserIdFromState();

    const url = `${uri(environment).replace("https://", "wss://").replace("http://", "ws://")}?userId=${webSocketCurrentUserId}`;

    webSocketClient = new SubscriptionClient(url, {
        reconnect: true,
        lazy: true
    });

    webSocketLink = new WebSocketLink(webSocketClient);

    return webSocketLink;
};

const isWsSubscription = (op: Operation) => {
    const definition = getMainDefinition(op.query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
};

const createApolloLink = (environment: Environment) =>
    ApolloLink.from([
        standardHeadersLink,
        operationHeadersLink,
        authHeadersLink,
        correlationIdHeaderLink,
        errorLink,
        refreshTokenLink,
        split(isWsSubscription, createWsLink(environment), createHttpLink(environment))
    ]);

function isAuthenticationError(errors: GraphQLErrors) {
    const isAuthenticationError = !!errors.find((n) => {
        return n?.extensions?.code === "UNAUTHENTICATED";
    });
    return isAuthenticationError;
}

function isLoggedError(error: GraphQLError) {
    switch (error?.extensions?.code) {
        case "TRACKS_DUPLICATION": return true;
        default: return false;
    }
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        const isAuthError = isAuthenticationError(graphQLErrors);
        graphQLErrors.forEach((error) => {
            const { message, locations, path } = error;
            if (isAuthError) {
                log.info({
                    code: "web-220916-1342",
                    msg: "graphQL error",
                    data: {
                        message,
                        locations,
                        path,
                        operation
                    },
                    report: true
                });
            } else if (!isLoggedError(error)) {
                log.error({
                    code: "web-210519-1302",
                    msg: "graphQL error",
                    data: {
                        message,
                        locations,
                        path,
                        operation
                    }
                });
            }
        });
    }
    if (networkError) log.info({ code: "web-210519-1303", msg: "network error", data: { error: networkError } });
});

const client = (link: ApolloLink) => {
    const cache = new InMemoryCache();

    return new ApolloClient({
        link,
        cache,
        defaultOptions: { watchQuery: { fetchPolicy: "no-cache" }, query: { fetchPolicy: "no-cache" } }
    });
};
function getGraphqlClientId(): string {
    switch (environment.graphqlEnvironmentName) {
        case GraphqlEnvironmentName.Dev:
        case GraphqlEnvironmentName.Local:
        case GraphqlEnvironmentName.Test:
        case GraphqlEnvironmentName.Stage:
            {
                switch (environment.webAppBrand) {
                    case WebAppBrand.Telmore:
                        return "Z3FsLW51dS13ZWItdGVsbW9yZTplNmYxNzZiYThhMGM0ODUwOThkZDc1YTk2ZjdlNmE3Ng==";
                    case WebAppBrand.YouSee:
                        return "Z3FsLW51dS13ZWIteW91c2VlOmEzMWRlNTFmYjUyOTRiMGI4ZGE4OTk2NGYwNDQzNTlh";
                }
            }
            break;
        case GraphqlEnvironmentName.Prod: {
            switch (environment.webAppBrand) {
                case WebAppBrand.Telmore:
                    return "Z3FsLW51dS13ZWItdGVsbW9yZTpiN2YzMTVkNWM0MTk0NzEwOGExMzYxZGJlYzRmNmE2NQ==";
                case WebAppBrand.YouSee:
                    return "Z3FsLW51dS13ZWIteW91c2VlOmUyY2RlZmM2ZmM2MjQ4YTc4YWE5YWZjNDJkYzk1ZWIx";
            }
        }
    }
}
