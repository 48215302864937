import { h } from "preact";
import type { Ref } from "preact/hooks";
import { getComparableId } from "services/resource";
import type { DragProps, DropProps } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import { ResourceDisplayType } from "models/domain";
import type { ResourcePreviewModel } from "models/domain";
import type { NotificationConversion } from "models/domain/NotificationModel";
import { MediaSize, useAppMediaSize, useSectionScrollRestoration } from "components/shared/hooks";
import { CoverSize } from "components/atoms/cover";
import type { PreviewAction } from "components/molecules/preview";
import { ResourcePreviewSquare } from "components/molecules/preview/squares/ResourcePreviewSquare";
import Scroller from "components/organisms/scroller";
import type { ScrollerNavigateCallback, ScrollerNavigatePropetiesCallback } from "components/organisms/scroller/options";
import { ScrollerAlign, ScrollerEffect, ScrollerPurpose, ScrollerSize } from "components/organisms/scroller/options";

type DisplayType = ResourceDisplayType.Scroller | ResourceDisplayType.LargeScroller;

interface Props {
    section: SectionContextModel;
    navigateRef: Ref<ScrollerNavigateCallback | null>;
    onNavigatePropertiesChange: ScrollerNavigatePropetiesCallback;
    context: SectionContextModel;
    dragProps: DragProps;
    getDropProps?: (model: ResourcePreviewModel, index: number) => DropProps;
    onAction: (action: PreviewAction, preview: PreviewContextModel, notificationAction: NotificationConversion | null) => void;
    isHighlight?: (id: string) => boolean;
    displayType: DisplayType;
    resources: ResourcePreviewModel[] | null;
}

const getSizes = (displayType: DisplayType, isMobile: boolean) => {
    switch (displayType) {
        case ResourceDisplayType.Scroller: {
            return {
                scrollerSize: isMobile ? ScrollerSize.Medium : ScrollerSize.MediumLarge,
                coverSize: isMobile ? CoverSize.Size144 : CoverSize.Size208
            };
        }
        case ResourceDisplayType.LargeScroller: {
            return { scrollerSize: ScrollerSize.Large, coverSize: CoverSize.Size256 };
        }
    }
};
export const ResourceSectionScroller = ({
    resources,
    onAction,
    section,
    navigateRef,
    onNavigatePropertiesChange,
    context,
    getDropProps,
    dragProps,
    isHighlight,
    displayType
}: Props) => {
    const restoration = useSectionScrollRestoration({ section });
    const mediaSize = useAppMediaSize();
    const { scrollerSize, coverSize } = getSizes(displayType, mediaSize === MediaSize.Mobile);

    return (
        <Scroller
            size={scrollerSize}
            restoration={restoration}
            align={ScrollerAlign.Left}
            purpose={ScrollerPurpose.Scroll}
            effect={ScrollerEffect.None}
            navigateRef={navigateRef}
            onNavigatePropertiesChange={onNavigatePropertiesChange}>
            {resources?.map((resource, i) => (
                <ResourcePreviewSquare
                    context={context}
                    dragProps={dragProps}
                    dropProps={getDropProps?.(resource, i) ?? null}
                    key={getComparableId(resource)}
                    onAction={onAction}
                    resource={resource}
                    size={coverSize}
                    rank={i}
                    highlight={isHighlight?.(resource.id) ?? false}
                    disableLazyLoad
                />
            ))}
        </Scroller>
    );
};
