import type { Action } from "global/actions";
import {
    SET_BROWSER_LANGUAGE,
    SET_LOCALIZATION_SETTING,
    AUDIO_INPUT_SET_AND_PLAY,
    SET_MY_MUSIC_SORTING,
    SET_MAXIPLAYER_OPEN,
    SET_DESKTOP_QUEUE_OPEN,
    SET_THEME_SETTING,
    SET_USER_DRAGGING,
    REHYDRATE,
    SET_MY_PLAYLISTS_SELECTION,
    USER_LOGGED_OUT,
    SET_DESKTOP_LYRICS_VISIBLE,
    SET_MY_PLAYLIST_TRACKS_SORTING,
    NAVIGATION_NAVIGATED,
    SET_SOUNDQUALITY_SETTING
} from "global/actions";
import { cloneWithDefaultValues } from "services/utils";
import { CombinedPlaylistsSorting, OwnPlaylistsSorting } from "generated/graphql-types";
import { AudioInputType } from "models/app/player";
import { SortOption } from "models/app/SortOption";
import type { UiModel } from "models/app/UiModel";
import { DesktopQueueOpen, MobileMaxiPlayerOpen } from "models/app/UiModel";
import { FavoriteAlbumsSorting, FavoriteArtistsSorting, FavoriteTracksSorting } from "models/domain";
import { ContentType } from "models/ModelType";
import { Language, LanguageSetting, SoundQualitySetting } from "models/view/Localization";
import { ThemeSetting } from "models/view/Theme";

const initialState: UiModel = {
    albumsSorting: FavoriteAlbumsSorting.LatestAdded,
    artistsSorting: FavoriteArtistsSorting.LatestAdded,
    tracksSorting: FavoriteTracksSorting.LatestAdded,
    playlistsSorting: CombinedPlaylistsSorting.ModifiedDate,
    ownPlaylistTracksSorting: SortOption.UserDefined,
    ownPlaylistsSorting: OwnPlaylistsSorting.ModifiedDate,
    sidebarPlaylistsSorting: CombinedPlaylistsSorting.ModifiedDate,
    theme: ThemeSetting.Auto,
    localizationSetting: LanguageSetting.Danish,
    soundQualitySetting: SoundQualitySetting.Normal,
    browserLanguage: Language.Danish,
    layout: {
        mobileMaxiPlayerOpen: MobileMaxiPlayerOpen.Closed,
        desktopQueueOpen: DesktopQueueOpen.Default,
        lyricsOpen: false,
        lyricsId: undefined,
        lyricsTrackId: undefined,
        lyricsStart: undefined,
    },
    userDragging: false,
    userDraggingItem: null,
    version: 1
};

export function uiReducer(state: UiModel = initialState, action: Action): UiModel {
    switch (action.type) {
        case SET_MY_MUSIC_SORTING:
            return {
                ...state,
                ...action.payload
            };
        case SET_MY_PLAYLIST_TRACKS_SORTING:
            return {
                ...state,
                ownPlaylistTracksSorting: action.payload.sorting
            }
        case SET_MY_PLAYLISTS_SELECTION:
            // action is ignored now; only exists to be available for analytics
            return state;
        case NAVIGATION_NAVIGATED:
            return { ...state, layout: { ...state.layout, lyricsOpen: false } };
        case SET_MAXIPLAYER_OPEN:
            return { ...state, layout: { ...state.layout, mobileMaxiPlayerOpen: action.payload.open } };
        case SET_DESKTOP_LYRICS_VISIBLE:
            return {
                ...state,
                layout: {
                    ...state.layout,
                    ...(action.payload.open
                        ? {
                            lyricsOpen: true,
                            lyricsId: action.payload.lyricsId,
                            lyricsTrackId: action.payload.trackId,
                            lyricsStart: action.payload.startingPoint
                        } : {
                            lyricsOpen: false,
                            lyricsId: undefined,
                            lyricsTrackId: undefined,
                            lyricsStart: undefined
                        })
                }
            };
        case SET_DESKTOP_QUEUE_OPEN:
            return { ...state, layout: { ...state.layout, desktopQueueOpen: action.payload.open } };
        case SET_THEME_SETTING:
            return { ...state, theme: action.payload.theme };
        case SET_BROWSER_LANGUAGE:
            return { ...state, browserLanguage: action.payload.localization };
        case SET_LOCALIZATION_SETTING:
            return { ...state, localizationSetting: action.payload.localizationSetting };
        case SET_SOUNDQUALITY_SETTING:
            return { ...state, soundQualitySetting: action.payload.soundQualitySetting };
        case SET_USER_DRAGGING: {
            const { item } = action.payload;
            return {
                ...state,
                userDragging: !!item,
                userDraggingItem: item
            };
        }
        case AUDIO_INPUT_SET_AND_PLAY: {
            const { playable } = action.payload;
            if (playable.contentType === ContentType.MixRadio) {
                return { ...state, layout: { ...state.layout, desktopQueueOpen: DesktopQueueOpen.Closed } };
            }
            return state;
        }
        case REHYDRATE: {
            const payload = action.payload;
            const ui = cloneWithDefaultValues(payload?.ui, initialState) as UiModel;
            if (ui.version === undefined) {
                return {
                    ...initialState,
                    theme: ui.theme,
                    localizationSetting: ui.localizationSetting
                };
            }

            const playQueueLength = payload?.queue?.playQueue.length ?? 0;
            const isInputLiveRadio = payload?.player?.input === AudioInputType.LiveRadio;
            if (!isInputLiveRadio && playQueueLength === 0) {
                ui.layout.desktopQueueOpen = DesktopQueueOpen.Closed;
            }
            ui.layout.lyricsOpen = false;

            return ui;
        }
        case USER_LOGGED_OUT:
            return {
                ...state,
                albumsSorting: FavoriteAlbumsSorting.LatestAdded,
                artistsSorting: FavoriteArtistsSorting.LatestAdded,
                tracksSorting: FavoriteTracksSorting.LatestAdded,
                playlistsSorting: CombinedPlaylistsSorting.ModifiedDate,
                ownPlaylistsSorting: OwnPlaylistsSorting.ModifiedDate,
                ownPlaylistTracksSorting: SortOption.UserDefined,
                sidebarPlaylistsSorting: CombinedPlaylistsSorting.ModifiedDate,
                layout: {
                    mobileMaxiPlayerOpen: MobileMaxiPlayerOpen.Closed,
                    desktopQueueOpen: DesktopQueueOpen.Default,
                    lyricsOpen: false,
                    lyricsId: undefined,
                    lyricsTrackId: undefined,
                    lyricsStart: undefined
                }
            };
        default:
            return state;
    }
}
