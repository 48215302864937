import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castQueueSetRepeatMode(repeatMode: chrome.cast.media.RepeatMode): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castQueueSetRepeatMode", repeatMode, (request, success, error) => {
        media.queueSetRepeatMode(request, success, error);
    });
}
