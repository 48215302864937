import { useState, useLayoutEffect } from "preact/hooks";

export const useResizeObserver = (target: HTMLElement | null): DOMRectReadOnly | null => {
    const [rect, setRect] = useState<DOMRectReadOnly | null>(null);

    useLayoutEffect(() => {
        if (!target) return;

        let stop = false;
        const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            if (stop) return;

            // requestAnimationFrame workaround to prevent "loop limit exceeded" issues
            // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            window.requestAnimationFrame(() => {
                entries.forEach((entry) => {
                    if (!entry) return;
                    setRect(entry.contentRect);
                });
            });
        });
        resizeObserver.observe(target);
        return () => {
            stop = true;
            resizeObserver.disconnect();
        };
    }, [target]);

    return rect;
};
