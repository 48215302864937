import { castLoadMedia } from "../castRequests/session";
import { convertToRepeatMode, getCurrentTimeFromAudio, getLocalRepeat } from "../helpers";
import { hasCastMediaSession } from "../setup";
import { getLocalCastQueue } from "./localCastQueue";
import { setRemoteCastQueue } from "./remoteCastQueue";
import { getQueueItemsFromAudios } from "./syncExistingQueue";
import { log, LogTag } from "services/logger";
import { isPlayStatePlayIntent } from "services/player/helpers";

export async function syncNewQueueFromLocal(replace = false): Promise<boolean> {
    if (!replace && hasCastMediaSession()) {
        log.error({ tags: [LogTag.Chromecast], code: "web-230109-1644", msg: "cast already has a queue" });
        return false;
    }

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `syncNewQueueFromLocal...` });

    const { audios, currentIndex, currentAudio } = getLocalCastQueue();
    if (audios.length == 0) {
        log.error({ code: "web-230109-1536", msg: "no audios" });
        return false;
    }

    if (currentIndex == null) {
        log.error({ code: "web-230109-1536", msg: "no current index" });
        return false;
    }

    if (currentAudio == null) {
        log.error({ code: "web-230109-1536", msg: "no current audio" });
        return false;
    }

    const items = getQueueItemsFromAudios(audios);
    const currentItem = items[currentIndex];

    const autoPlay = isPlayStatePlayIntent(currentAudio.playState);
    const currentTime = getCurrentTimeFromAudio(currentAudio);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queueData = new (chrome.cast.media as any).QueueData();
    queueData.items = items;
    queueData.startIndex = currentIndex;
    queueData.repeatMode = convertToRepeatMode(getLocalRepeat());
    if (currentTime != null) queueData.startTime = currentTime;

    const request = new chrome.cast.media.LoadRequest(currentItem.media);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (request as any).queueData = queueData;
    request.autoplay = autoPlay;
    if (currentTime != null) request.currentTime = currentTime;

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `...syncNewQueueFromLocal, items: ${items.map((item) => item.itemId)}` });

    const send = await castLoadMedia(request);
    if (!send) return false;

    setRemoteCastQueue(items.map((item) => item.itemId));

    return true;
}
