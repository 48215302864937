import { getLangFromState, useLocalization } from "components/app/hooks";
import * as _analyticsKeys from "generated/analyticsKeys.json";
import type { NuuApps } from "generated/localization";
import type { Language } from "models/view/Localization";

// todo: This is just something quick'n'dirty to get the ball rolling.
// It'll be replaced by something that fetches and caches the json, and checks for updates if already cached.

export const analyticsKeys = _analyticsKeys;

let translations: { [key in Language]: NuuApps } = {
    Danish: {} as NuuApps,
    English: {} as NuuApps
};

export const setTranslations = (values: typeof translations) => {
    translations = values;
};

export type TranslationKey = keyof NuuApps;
export type AnalyticsKey = keyof typeof analyticsKeys;

export const translate = (key: TranslationKey) => {
    const lang = getLangFromState();
    return translations[lang][key];
};
export const translateWithReplacement = (key: TranslationKey, name: string, value: string) => translate(key).replace(`\${${name}}`, value);

export const analyticKey = (key: AnalyticsKey) => analyticsKeys[key];

const translationsPromise = new Promise<void>((resolve) => resolve());

export const configcmsInit = () => translationsPromise;

export function useTranslations() {
    const local = useLocalization();
    return translations[local];
}
export function useTranslateKey(key: TranslationKey) {
    const local = useLocalization();
    return translations[local][key];
}
