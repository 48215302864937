import { dispatch } from "global";
import { LIVERADIO_UPDATE } from "global/actions";
import { getLiveRadioPlay } from "services/backend";
import { log } from "services/logger";
import { getAnalyticsPlayableProperties } from "services/logger/analytics/properties/event";
import { getAnalyticsQueueIntentPropertiesGroup, createQueueAddedAnalyticsData } from "services/logger/analytics/properties/groups";
import { createNormalizedData } from "services/normalizeData";
import { isInputLiveRadioFromState } from "services/player/inputs/service/helpers";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { LiveRadioPlayablePreviewModel } from "models/domain";
import { createLiveRadioPlayablePlay } from "models/domain";
import { openToast } from "components/organisms/toast";
import { PlaybackFailToast } from "components/organisms/toast/toasts";

export async function setAndPlay(radio: LiveRadioPlayablePreviewModel, radioContext: PreviewContextModel, context: AudioContextModel) {
    if (!isInputLiveRadioFromState()) return;

    const analytics = getAnanlytics(radio, radioContext);

    dispatch({ type: LIVERADIO_UPDATE, payload: { radio, radioContext, analytics, context } });

    const radioPlay = (await getLiveRadioPlay({ id: radio.id })).model;

    if (radioPlay?.mp3Url == null) {
        log.error({ code: "web-220224-1611", msg: "could not load live radio url", data: { radio, radioPlay } });

        dispatch({ type: LIVERADIO_UPDATE, payload: { radio: null, radioContext: null, analytics: null, context } });
        openToast(PlaybackFailToast());
        return;
    }

    const radioPlayablePlay = createLiveRadioPlayablePlay(radioPlay, radio.parent);

    dispatch({ type: LIVERADIO_UPDATE, payload: { radio: radioPlayablePlay, analytics, radioContext, context } });
}

function getAnanlytics(radio: LiveRadioPlayablePreviewModel, radioContext: PreviewContextModel) {
    const intentData = getAnalyticsQueueIntentPropertiesGroup(radioContext);
    const queueAddData = createNormalizedData(createQueueAddedAnalyticsData(intentData, radio.parent));
    const playableData = getAnalyticsPlayableProperties(radio);

    return { playableData, queueAddData };
}
