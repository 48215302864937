import type { RefObject } from "preact";
import { Fragment, h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import "./DefaultTemplateDesktop.scss";
import "./DefaultTemplateMobile.scss";
import { DesktopTemplateWidth, useDesktopProperties } from "./hooks/useDesktopProperties";
import { PageTemplate } from "../page/PageTemplate";
import { dispatch } from "global";
import { SET_DESKTOP_QUEUE_OPEN, PLAYER_TOGGLE_PLAY } from "global/actions";
import type { RootModel } from "models/app";
import { DesktopQueueOpen } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { ContentType } from "models/ModelType";
import { useRefUpdate, useAudioProperties, useDelay, DelayType } from "components/shared/hooks";
import { useAppMediaSize, MediaSize } from "components/shared/hooks/useAppMediaSize";
import { useDesktopLyricsOpen } from "components/shared/hooks/useDesktopLyricsOpen";
import { DesktopHeader } from "components/organisms/headers/DesktopHeader";
import { MobileHeader } from "components/organisms/headers/MobileHeader";
import { Lyrics } from "components/organisms/lyrics/Lyrics";
import { SideBar } from "components/organisms/menus";
import { PlayerDesktop } from "components/organisms/playerDesktop";
import { PlayerMaxi } from "components/organisms/playerMaxi";
import { PlayerMini } from "components/organisms/playerMini";
import { Queue } from "components/organisms/queue";

export interface DefaultTemplateProps {
    children: preact.ComponentChildren;
    className?: string;
    requireLogin?: boolean;
    mobileHeader?: boolean;
    pageRef?: RefObject<HTMLDivElement>;
}

export const DefaultTemplate = ({ children, className, pageRef, mobileHeader = false }: DefaultTemplateProps) => {
    const isMobile = useAppMediaSize() === MediaSize.Mobile;
    const templateRef = useRefUpdate<HTMLDivElement>();
    const { templateWidth, queueLoaded, queueOpen } = useDesktopProperties(templateRef.current);

    const lyricsOpen = useDesktopLyricsOpen();

    const userDragging = useSelector((root: RootModel) => root.ui.userDragging);

    const toggleQueue = useCallback(() => {
        dispatch({
            type: SET_DESKTOP_QUEUE_OPEN,
            payload: {
                open: queueOpen ? DesktopQueueOpen.Closed : DesktopQueueOpen.Open,
                type: ContentType.Player
            }
        });
    }, [queueOpen]);

    const loaded = useDelay({ delay: { type: DelayType.AnimationFrame } });

    return (
        <div
            ref={templateRef}
            className={
                isMobile
                    ? `defaultTemplateMobile XWV ${className ?? ""} --header-${mobileHeader}`
                    : `defaultTemplateDesktop qbA ${className ?? ""} --loaded-${loaded} --queueVisible-${queueOpen} --userDragging-${userDragging} --width-${templateWidth ?? "none"
                    }`
            }>
            <SideBar className={`menu`} minimal={templateWidth !== DesktopTemplateWidth.Step3} />
            {
                <PageTemplate className={`page${lyricsOpen ? " noScreen" : ""}`} ref={pageRef}>
                    {!isMobile && <div className="overlay" onClick={toggleQueue} />}
                    {children}
                </PageTemplate>
            }
            {
                lyricsOpen &&
                <PageTemplate className="page noPrint">
                    <Lyrics />
                </PageTemplate>
            }
            {isMobile ? (
                <Fragment>
                    {mobileHeader && <MobileHeader />}
                    <PlayerMini className="player" />
                    <PlayerMaxi />
                </Fragment>
            ) : (
                <Fragment>
                    <PlayQueue queueLoaded={queueLoaded} queueOpen={queueOpen} />
                    <PlayerDesktop className="player" showQueue={queueOpen} toggleQueue={toggleQueue} />
                    <DesktopHeader className="header" />
                </Fragment>
            )}
        </div>
    );
};

function PlayQueue({ queueLoaded, queueOpen }: { queueLoaded: boolean; queueOpen: boolean }) {
    const { canPlay, canPause } = useAudioProperties();

    const togglePlay = useCallback(
        () => canPlay && canPause && dispatch({ type: PLAYER_TOGGLE_PLAY, payload: { context: { action: AudioContextAction.UserPlayerTogglePlay, trace: null } } }),
        [canPlay, canPause]
    );
    if (!queueLoaded) return null;
    return <Queue onCurrentCoverClick={togglePlay} queueOpen={queueOpen} disableFade />;
}
