import { getCastSession } from "../../setup";
import type { LogProps } from "services/logger";
import { log, LogTag } from "services/logger";

export async function castSendMessage(namespace: string, data: unknown): Promise<boolean> {
    const session = getCastSession();
    if (!session) return false;

    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1205", msg: `castSendMessage... : ${namespace}` });

    const result = await session.sendMessage(namespace, data);
    const ok = result == undefined;

    const logProps: LogProps = { tags: [LogTag.Chromecast], code: "web-200106-1615", msg: `...castSendMessage, ok: ${ok}`, data: { namespace, data, result, ok } };
    if (ok) log.info(logProps);
    else log.error(logProps);

    return ok;
}
