import { SearchResultModel } from "models/domain/SearchResultModel";

export function encodeUrlSearchString(criterion: string): string {
    return encodeURIComponent(criterion);
}

export function decodeUrlSearchString(criterion: string | null): string | null {
    if (criterion == null) return null;
    return decodeURIComponent(criterion);
}

export function isEmptyResult(result: SearchResultModel) {
    if (result?.mixedResults?.mixedResults?.items.length ?? 0 > 0) return false;
    if (result?.tracks?.tracks?.items.length ?? 0 > 0) return false;
    if (result?.albums?.albums?.items.length ?? 0 > 0) return false;
    if (result?.artists?.artists?.items.length ?? 0 > 0) return false;
    if (result?.playlists?.playlists?.items.length ?? 0 > 0) return false;

    return true;
}
