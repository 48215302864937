import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getMixRadioCategoryPagination, useMixRadioCategoryPage } from "services/backend/backend";
import type { MixRadioPreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import type { AlternateAutoPlayFn } from "components/templates/showAllPage";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    mixRadioCategoryId: string;
    mixRadioId?: string;
}

export const MixRadioCategoryPage = ({ mixRadioCategoryId, mixRadioId }: Props) => {
    const query = useMixRadioCategoryPage({ id: mixRadioCategoryId, first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => getMixRadioCategoryPagination({ first, after, id: mixRadioCategoryId }), [mixRadioCategoryId]);

    const getAutoPlayRadio: AlternateAutoPlayFn<MixRadioPreviewModel> = useCallback(
        (items) => {
            const radio = items?.items.find((radio) => radio.id === mixRadioId) ?? null;
            return { type: ContentType.MixRadio, resource: radio };
        },
        [mixRadioId]
    );
    return (
        <ShowAllPageTemplate
            query={query}
            type={ContentType.MixRadioCategory}
            items={product?.radios ?? null}
            fetchFn={fetchFn}
            display={ResourceDisplayType.Grid}
            alternateAutoPlayFn={getAutoPlayRadio}
        />
    );
};
