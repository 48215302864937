import { saveUrl } from "../url/saveUrl";
import { getBrowserAudio } from "./updateBrowserAudios";
import { updateFlow } from "./updateFlow";
import type { AudioInputItemModel } from "models/app/player/input";

export async function updateUrl(audio: AudioInputItemModel) {
    let browserAudio = getBrowserAudio(audio.audioId);
    if (!browserAudio) return;

    browserAudio = await saveUrl(audio, browserAudio);
    updateFlow();
}
