import type { AnalyticsKeys } from "generated/analyticsKeys";
import { ThemeSetting, Theme } from "models/view";

type AnalyticsThemeSetting = keyof Pick<AnalyticsKeys, "Auto" | AnalyticsTheme>;
export function convertThemeSetting(themeSetting: ThemeSetting): AnalyticsThemeSetting {
    switch (themeSetting) {
        case ThemeSetting.Auto:
            return "Auto";
        case ThemeSetting.Dark:
            return "Dark";
        case ThemeSetting.Light:
            return "Light";
    }
}
type AnalyticsTheme = keyof Pick<AnalyticsKeys, "Dark" | "Light">;
export function convertTheme(theme: Theme): AnalyticsTheme {
    switch (theme) {
        case Theme.Dark:
            return "Dark";
        case Theme.Light:
            return "Light";
    }
}
