import { tryAddLoadDataToAudio } from "./updateAudios";
import { tryGetAudioPosition } from "../helpers";
import { getAudioByInputItemIdFromState } from "../helpers/audio";
import { getCurrentInputAudioItemFromState } from "../helpers/currentAudio";
import { dispatch, store } from "global";
import { AUDIO_INPUT_AUDIO_PLAY_CHANGE } from "global/actions";
import { DefaultLogMessage, log, LogTag } from "services/logger";
import { getPlayPausePosition, isPlayPausePositionAlmostEqual, isPlayPausePositionEqual } from "services/player/helpers";
import type { PausePositionModel, PlayPausePositionModel } from "models/app/player";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";

export const playPause = (play: boolean, context: AudioContextModel) => {
    const loaded = getCurrentInputAudioItemFromState();

    if (!loaded) {
        log.error({ code: "web-210519-1114", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    const playTrack = () => {
        log.debug([LogTag.Playback], () => ({
            code: "web-210212-0952",
            msg: "player play track",
            data: { loaded }
        }));

        playChange(loaded, PlayState.Buffering, null, context);
    };

    const pauseTrack = () => {
        log.debug([LogTag.Playback], () => ({
            code: "web-210212-0952",
            msg: "player pause track",
            data: { loaded }
        }));

        playChange(loaded, PlayState.Paused, null, context);
    };

    return play ? playTrack() : pauseTrack();
};

export function startPlay(sourceId: string, context: AudioContextModel) {
    tryStopPlayAll(context);

    const item = getAudioByInputItemIdFromState(sourceId);
    if (item == null) {
        log.error({ code: "web-210519-1147", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    const position: PausePositionModel = {
        pausePosition: 0
    };

    playChange(item, PlayState.Buffering, position, context);
}

export function tryStopPlayAll(context: AudioContextModel) {
    const { audios } = store.getState().audioInput;
    const stopAudios = audios.filter((audio) => audio.playState !== PlayState.Stopped && audio.playState !== PlayState.Error);

    const position: PausePositionModel = {
        pausePosition: 0
    };

    for (const audio of stopAudios) {
        playChange(audio, PlayState.Stopped, position, context);
    }
}

export function playChange(audio: AudioInputItemModel, playState: PlayState | null, position: PlayPausePositionModel | null, context: AudioContextModel) {
    const playStateBefore = audio.playState;

    if (playState) {
        audio.playState = playState;
        audio = tryAddLoadDataToAudio(audio);
    }

    if (audio.input === AudioInputType.PlayQueue) {
        if (playState === PlayState.Stopped) position = { pausePosition: 0 };
        const playing = audio.playState === PlayState.Playing;
        audio.position = getPlayPausePosition(position ?? audio.position, playing);
    }

    if (playState === PlayState.Playing) audio.errorCount = 0;

    dispatch({
        type: AUDIO_INPUT_AUDIO_PLAY_CHANGE,
        payload: { audio, playStateBefore, context }
    });
}

export const playChangeFromOutput = (
    audio: AudioInputItemModel,
    allowPlayChange: boolean,
    outputPlayState: PlayState | null,
    outputPosition: PlayPausePositionModel | null,
    context: AudioContextModel
) => {
    const currentPlayState = audio.playState;
    const currentPosition = tryGetAudioPosition(audio);

    const newPlayState = getNewPlayState(currentPlayState, outputPlayState, allowPlayChange);
    const newPosition = currentPosition ? getNewPosition(currentPosition, outputPosition, !!newPlayState) : null;

    if (newPlayState == null && newPosition == null) return;

    playChange(audio, newPlayState, newPosition, context);
};

function getNewPlayState(currentPlayState: PlayState, outputPlayState: PlayState | null, allowPlayChange: boolean): PlayState | null {
    if (!outputPlayState) return null;
    if (currentPlayState === outputPlayState) return null;

    if (!allowPlayChange) {
        let currentPlay = currentPlayState;
        if (currentPlay === PlayState.Buffering) currentPlay = PlayState.Playing;

        let outputPlay = outputPlayState;
        if (outputPlay === PlayState.Buffering) outputPlay = PlayState.Playing;

        if (currentPlay !== outputPlay) return null;
    }

    return outputPlayState;
}

function getNewPosition(currentPosition: PlayPausePositionModel, outputPosition: PlayPausePositionModel | null, playStateChange: boolean) {
    if (!outputPosition) return null;

    const equal = isPlayPausePositionEqual(currentPosition, outputPosition);
    if (equal) return null;

    if (playStateChange) return outputPosition;

    const almost = isPlayPausePositionAlmostEqual(currentPosition, outputPosition);
    if (almost) return null;

    return outputPosition;
}
