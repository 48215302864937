import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { convertPlayableProperties, getAnalyticsPlayableProperties } from "../properties/event";
import { getAndConvertAnalyticsLyricsProperties } from "../properties/event/lyricsProperties";
import { getAnalyticsUserProperties } from "../properties/user";
import type { SynchronizationType } from "generated/graphql-types";
import type { PlayableModel } from "models/domain";

export async function getAnalyticsLyricsDisplayedEvent(
    playableData: PlayableModel,
    lyricsId: string,
    startingPoint: "ContextMenu" | "MaxiPlayer",
    sync: SynchronizationType
): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertPlayableProperties(getAnalyticsPlayableProperties(playableData)),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getAndConvertAnalyticsLyricsProperties(lyricsId, startingPoint, sync)
    );
    return data;
}
