import { v4 } from "uuid";
import { messageBus, store } from "global";
import { AUDIO_INPUT_AUDIOS_CHANGE, AUDIO_INPUT_AUDIO_PLAY_CHANGE, PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE } from "global/actions";
import { Feature, isFeatureEnabled } from "global/config";
import { getLiveRadioHeartbeatInSeconds, mutateReportLiveRadioHeartbeat } from "services/backend";
import { log } from "services/logger";
import { getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import { isOutputBrowserFromState } from "services/player/outputs/service";
import { HeartbeatType } from "generated/graphql-types";
import { AudioInputType, PlayState } from "models/app/player";
import { DomainModelType } from "models/ModelType";

export function initLiveRadioHeartbeatService() {
    messageBus.subscribeEvery(AUDIO_INPUT_AUDIOS_CHANGE, async () => {
        updateHeartbeat();
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_PLAY_CHANGE, async () => {
        updateHeartbeat();
    });

    messageBus.subscribeEvery(PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE, async () => {
        updateHeartbeat();
    });
}

interface CurrentLiveRadio {
    id: string;
    playbackContext: string | null;
    url: string;
}

interface LiveRadioSession extends CurrentLiveRadio {
    sessionId: string;
    lastReport: number;
}

let session: LiveRadioSession | null = null;
let timeout: number | null = null;
let interval = 5 * 60 * 1000;

async function updateHeartbeat() {
    if (!isFeatureEnabled(Feature.LiveradioHeartbeat)) return;

    if (timeout != null) {
        clearTimeout(timeout);
        timeout = null;
    }

    const playing = getPlayingRadio();
    const same = session?.id === playing?.id;

    if (!same) {
        if (session) {
            sendHeartbeat(session, HeartbeatType.Stop);
            session = null;
        }
        if (playing) {
            session = { ...playing, lastReport: 0, sessionId: v4() };
            await sendHeartbeatAndFetchInterval(session, HeartbeatType.Start);
            scheduleUpdate(interval);
        }
        return;
    }

    if (!session) return;

    const now = new Date().getTime();
    const next = session.lastReport + interval - now;

    if (next <= 0) {
        await sendHeartbeatAndFetchInterval(session, HeartbeatType.Live);
        scheduleUpdate(interval);
    } else {
        scheduleUpdate(next);
    }
}

function scheduleUpdate(next: number) {
    timeout = window.setTimeout(() => updateHeartbeat(), next);
}

function getPlayingRadio(): CurrentLiveRadio | null {
    if (!isOutputBrowserFromState()) return null;

    const current = getCurrentInputAudioItemFromState();
    if (current?.input !== AudioInputType.LiveRadio) return null;
    if (current.playState !== PlayState.Playing) return null;

    const radio = store.getState().liveRadio.currentRadio;

    const url = radio?.type === DomainModelType.Play ? radio.liveRadio.mp3Url : null;
    if (!url) return null;

    if (radio?.id !== current.radioId) {
        log.error({ code: "web-230322-0952", msg: "playing radio id does not match" });
        return null;
    }

    const playbackContext = radio.liveRadio.playbackContext;
    if (!playbackContext) {
        log.error({ code: "web-230322-0952", msg: "playbackContext missing" });
    }

    return { id: radio.id, playbackContext, url };
}

async function sendHeartbeatAndFetchInterval(current: LiveRadioSession, type: HeartbeatType) {
    sendHeartbeat(current, type);
    await fetchInterval();
}

async function sendHeartbeat(current: LiveRadioSession, type: HeartbeatType) {
    current.lastReport = new Date().getTime();

    console.warn("sending live radio heartbeat");

    mutateReportLiveRadioHeartbeat({
        heartBeat: { playbackSessionId: current.sessionId, radioId: current.id, streamingUrl: current.url, playbackContext: current.playbackContext, type }
    });
}

async function fetchInterval() {
    const intervalSec = (await getLiveRadioHeartbeatInSeconds({})).model;
    if (intervalSec == null) return;

    interval = intervalSec * 1000;
}
