import { h } from "preact";
import type { IconName } from "./";
import { IconSize } from "./";
import "./Icon.scss";
import { assetPaths } from "global/constants";

interface Props {
    icon: IconName;
    className?: string;
    alt?: string;
    title?: string;
    size?: IconSize;
}

export const Icon = ({ className, icon, alt, title, size = IconSize.Default }: Props) => (
    <svg className={`icon KZi --size-${size} ${className ?? ""}`} alt={alt} title={title}>
        <use xlinkHref={`${assetPaths.iconSprites}#${icon}`} />
    </svg>
);
