import { h } from "preact";
import { useCallback, useRef } from "preact/hooks";
import { useSelector } from "react-redux";
import "./MobileHeader.scss";
import { AccountContextMenu } from "../contextMenu/menus";
import { showLoginUi } from "../login";
import { Feature, isFeatureEnabled, useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { hasPermissionFromToken, shouldDisplayAsLoggedIn } from "services/user";
import { LoginState, type RootModel } from "models/app";
import { useContextMenu } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { ButtonNotificationFeed } from "components/atoms/controls/button/buttons";
import { IconName } from "components/atoms/icon";
import { Logo } from "components/atoms/logo";

interface Props {
    className?: string;
}

export function MobileHeader({ className }: Props) {
    const accountMenuButtonRef = useRef<HTMLButtonElement>(null);
    const accountMenu = useContextMenu(<AccountContextMenu />, false);
    const translations = useTranslations();
    const loginState = useSelector((root: RootModel) => root.user.state);
    const loggedOut = !shouldDisplayAsLoggedIn(loginState);

    const profile = useSelector((root: RootModel) => root.user.profiles?.find(n => n.current));
    const showProfiles = loginState === LoginState.LoggedIn && profile && hasPermissionFromToken("prof") && isFeatureEnabled(Feature.Profiles);

    const openAccountMenu = useCallback(() => {
        if (!accountMenuButtonRef.current) {
            log.error({ code: "web-210519-1223", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        accountMenu.openAtPosition(8, 8);
    }, [accountMenu]);

    return (
        <div className={`mobileHeader t0P ${className ?? ""}`}>
            <div className="left">
                {loggedOut && (
                    <Button design={ButtonDesign.PrimarySmall} onClick={showLoginUi} testLocator={TestLocator.PageHeaderLoginButton}>
                        {translations.NavBarLogin}
                    </Button>
                )}
            </div>
            <div className="middle">
                <Logo />
            </div>
            <div className="right">
                <ButtonNotificationFeed />
                {
                    showProfiles &&
                    <div class="headeravatar" style={{ "--colorHighlight": profile.color }}>
                        <Button
                            inline={{ right: true }}
                            activated={accountMenu.isOpen}
                            design={ButtonDesign.LightSmall}
                            ref={accountMenuButtonRef}
                            onClick={openAccountMenu}
                            testLocator={TestLocator.PageHeaderAccountButton}
                        ><span>{profile.title.substring(0, 1).toUpperCase()}</span></Button>
                    </div>
                }
                {
                    !showProfiles &&
                    <Button
                        inline={{ right: true }}
                        activated={accountMenu.isOpen}
                        ref={accountMenuButtonRef}
                        icon={IconName.Profile}
                        onClick={openAccountMenu}
                        testLocator={TestLocator.PageHeaderAccountButton}
                        className="userbutton"
                    />
                }
            </div>
        </div>
    );
}
