import type { AlbumArtistAlbumsSectionModel } from "./AlbumArtistAlbums";
import { createAlbumArtistAlbumsSectionModel } from "./AlbumArtistAlbums";
import type { ArtistLinkModel } from "./ArtistModel";
import { createArtistLinkModel } from "./ArtistModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createTrackNoAlbumPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { ResourceModelLinkInterface } from "./ResourceModel";
import type { TrackPreviewModel } from "./TrackModel";
import type {
    AlbumArtistAlbumsSectionFragment,
    AlbumLinkFragment,
    AlbumHeaderSectionFragment,
    AlbumTracksSectionFragment,
    AlbumPlayFragment,
    AlbumPreviewFragment
} from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

export type AlbumModel = AlbumLinkModel;
export type AlbumLinkModel = ({ type: DomainModelType.Link } & AlbumLink) | AlbumPreviewModel;
export type AlbumPreviewModel = ({ type: DomainModelType.Preview } & AlbumPreview) | AlbumPlayModel;
export type AlbumPlayModel = ({ type: DomainModelType.Play } & AlbumPlay) | AlbumPageModel;
export type AlbumPageModel = { type: DomainModelType.Page } & AlbumPage;

interface AlbumLink extends ResourceModelLinkInterface {
    readonly contentType: ContentType.Album;
    readonly title: string;
}

interface AlbumBase extends AlbumLink, PlayQueuePlayableModelInterface {
    readonly releaseDate: Date | null;
    readonly availableInSubscription: boolean;
    readonly sharingUrl: string | null;
}

interface AlbumPreview extends AlbumBase {
    readonly artist: ArtistLinkModel | null;
}

interface AlbumPlay extends AlbumPreview {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

interface AlbumPage extends AlbumPlay {
    readonly artistAlbums: AlbumArtistAlbumsSectionModel | null;
    readonly duration: number | null;
    readonly label: {
        name: string | null;
    };
}

export function createAlbumLinkModel(data: AlbumLinkFragment): AlbumLinkModel {
    return {
        type: DomainModelType.Link,
        contentType: ContentType.Album,
        id: data.id,
        title: data.title
    };
}

function createAlbum(data: AlbumPreviewFragment): AlbumBase {
    return {
        cover: data.cover ?? null,
        contentType: ContentType.Album,
        graphType: data.__typename,
        id: data.id,
        availableInSubscription: data.available,
        title: data.title,
        releaseDate: data.releaseDate ? new Date(Date.parse(data.releaseDate)) : null,
        trackCount: data.tracksCount,
        sharingUrl: data.share ?? null
    };
}

export function createAlbumPreviewModel(data: AlbumPreviewFragment): AlbumPreviewModel {
    return {
        ...createAlbum(data),
        type: DomainModelType.Preview,
        artist: createArtistLinkModel(data.artist)
    };
}

export function createAlbumPlayModel(data: AlbumPlayFragment): AlbumPlayModel {
    return {
        ...createAlbum(data),
        type: DomainModelType.Play,
        artist: createArtistLinkModel(data.artist),
        tracks: data.tracks ? createTrackNoAlbumPreviewConnectionModel(data.tracks, data.cover ?? null, data) : null
    };
}

export function createEmptyAlbumPageModel(): AlbumPageModel {
    return {
        graphType: undefined,
        cover: null,
        id: "",
        availableInSubscription: true,
        title: "",
        releaseDate: null,
        trackCount: null,
        type: DomainModelType.Page,
        contentType: ContentType.Album,
        artist: null,
        tracks: null,
        artistAlbums: null,
        duration: null,
        label: { name: null },
        sharingUrl: null
    };
}

export function fillAlbumPageModelWithBasicSection(model: AlbumPageModel, data: AlbumHeaderSectionFragment): AlbumPageModel {
    return {
        ...model,
        id: data.id,
        cover: data.cover ?? null,
        graphType: data.__typename,
        title: data.title ?? null,
        availableInSubscription: data.available,
        releaseDate: data.releaseDate ? new Date(Date.parse(data.releaseDate)) : null,
        trackCount: data.tracksCount,
        artist: createArtistLinkModel(data.artist),
        duration: data.duration,
        label: { name: data.label ?? null },
        sharingUrl: data.share ?? null
    };
}

export function fillAlbumPageModelWithTracksSection(model: AlbumPageModel, data: AlbumTracksSectionFragment): AlbumPageModel {
    return {
        ...model,
        tracks: data.tracks ? createTrackNoAlbumPreviewConnectionModel(data.tracks, model.cover, { id: model.id, title: model.title }) : null
    };
}

export function fillAlbumPageModelWithAlbumArtistAlbumsSectionData(model: AlbumPageModel, data: AlbumArtistAlbumsSectionFragment): AlbumPageModel {
    return {
        ...model,
        artistAlbums: data.artist.albums ? createAlbumArtistAlbumsSectionModel(data, true) : null
    };
}
