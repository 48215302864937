import type { UserPlayTraceModel } from "models/app";

export interface AudioContextModel {
    action: AudioContextAction;
    trace: UserPlayTraceModel | null;
}


export enum AudioContextAction {
    AppSessionEnded = "AppSessionEnded",
    AppStart = "AppStart",
    AudioHlsError = "AudioHlsError",
    AudioHtmlElementError = "AudioHtmlElementError",
    AudioLoadTrackStream = "AudioLoadTrackStream",
    AudioTrackStreamError = "AudioTrackStreamError",
    BrowserAudioEnded = "BrowserAudioEnded",
    BrowserAudioPause = "BrowserAudioPause",
    BrowserAudioPlayPrevented = "BrowserAudioPlayPrevented",
    BrowserAudioPlaying = "BrowserAudioPlaying",
    BrowserAudioSeeked = "BrowserAudioSeeked",
    BrowserAudioSeeking = "BrowserAudioSeeking",
    BrowserAudioWaiting = "BrowserAudioWaiting",
    ChromecastApiAvailable = "ChromecastApiAvailable",
    ChromecastCurrentTimeChange = "ChromeCurrentTimeChange",
    ChromecastLocalSync = "ChromecastLocalSync",
    ChromecastMediaLoadedChange = "ChromecastMediaLoadedChange",
    ChromecastNoSession = "ChromecastNoSession",
    ChromecastPlayerStateChange = "ChromecastPlayerStateChange",
    ChromecastResumeTimeoutOnPageLoad = "ChromecastResumeTimeoutOnPageLoad",
    ChromecastSessionResumed = "ChromecastSessionResumed",
    ChromecastSessionSessionEnded = "ChromecastSessionSessionEnded",
    ChromecastSessionSessionEnding = "ChromecastSessionSessionEnding",
    ChromecastSessionStartFailed = "ChromecastSessionStartFailed",
    ChromecastSessionStarted = "ChromecastSessionStarted",
    ConcurrencyLost = "ConcurrencyLost",
    ConcurrencyResume = "ConcurrencyResume",
    DeeplinkPlay = "DeeplinkPlay",
    EndlessPlayUpdate = "EndlessPlayUpdate",
    SkipOnFail = "SkipOnFail",
    Unknown = "Unknown",
    UserContextMenuAddToQueue = "UserContextMenuAddToQueue",
    UserEndChromeCast = "UserEndChromeCast",
    UserKeybordKeySpace = "UserKeybordKeySpace",
    UserLogIn = "UserLogIn",
    UserLogOut = "UserLogOut",
    UserMediaControlsNext = "UserMediaControlsNext",
    UserMediaControlsPause = "UserMediaControlsPause",
    UserMediaControlsPlay = "UserMediaControlsPlay",
    UserMediaControlsPrevious = "UserMediaControlsPrevious",
    UserPageSetAndPlay = "UserPageSetAndPlay",
    UserPlayQueueClear = "UserPlayQueueClear",
    UserPlayQueueRemove = "UserPlayQueueRemove",
    UserPlayerNext = "UserPlayerNext",
    UserPlayerPreviousOrRewind = "UserPlayerPreviousOrRewind",
    UserPlayerSeek = "UserPlayerSeek",
    UserPlayerShuffle = "UserPlayerShuffle",
    UserPlayerSwipeNext = "UserPlayerSwipeNext",
    UserPlayerSwipePrevious = "UserPlayerSwipePrevious",
    UserPlayerTogglePlay = "UserPlayerTogglePlay",
    UserPreviewDragDrop = "UserPreviewDragDrop",
    UserPreviewSetAndPlay = "UserPreviewSetAndPlay",
    UserPreviewTogglePlay = "UserPreviewTogglePlay",
}
