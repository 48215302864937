import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useMixRadiosPage } from "services/backend/backend";
import type { MixRadioPreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import type { AlternateAutoPlayFn } from "components/templates/showAllPage";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    mixRadioId?: string;
}

export const MixRadiosPage = ({ mixRadioId }: Props) => {
    const query = useMixRadiosPage({}, mixRadioId);
    const product = query.model;

    const getAutoPlayRadio: AlternateAutoPlayFn<MixRadioPreviewModel> = useCallback(
        (items) => {
            const radio = items?.items.find((radio) => radio.id === mixRadioId) ?? null;
            return { type: ContentType.MixRadio, resource: radio };
        },
        [mixRadioId]
    );

    return (
        <ShowAllPageTemplate
            query={query}
            type={ContentType.MixRadios}
            items={product?.radios ?? null}
            display={ResourceDisplayType.Grid}
            alternateAutoPlayFn={getAutoPlayRadio}
            highlightedId={mixRadioId}
        />
    );
};
