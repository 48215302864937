import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { log } from "services/logger/initLoggerService";
import type { TrackPlayableModel, LiveRadioPlayableModel, PlayQueuePlayableModel, TrackPreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";

export type AnalyticsPlayableProperties = {
    contentId?: string;
    contentType?: ContentType;
    graphqlType?: string;
    contentName?: string;
    contentArtist?: string;
    contentAlbum?: string;
    contentGenre?: string;
    playbackContext?: string;
    trackDuration?: number;
};

export function getAnalyticsPlayableProperties(resource: TrackPlayableModel | LiveRadioPlayableModel | PlayQueuePlayableModel): AnalyticsPlayableProperties {
    switch (resource.contentType) {
        case ContentType.TrackPlayable:
        case ContentType.LiveRadioTrack: {
            const track = resource.track;
            const properties: AnalyticsPlayableProperties = {
                contentId: track.id,
                contentName: track.title,
                contentType: track.contentType,
                graphqlType: track.graphType,
                contentArtist: track.artist.name,
                contentAlbum: track.album.title,
                contentGenre: track.genre,
                playbackContext: track.playbackReportContext,
                trackDuration: Math.floor(track.duration)
            };
            return properties;
        }
        case ContentType.Album: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.title,
                contentType: resource.contentType,
                graphqlType: resource.graphType,
                contentArtist: resource.artist?.name,
                contentGenre: undefined //TODO,
            };
            return properties;
        }
        case ContentType.LiveRadioPlayable: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.liveRadio.title,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }
        case ContentType.Artist: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.name,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }

        case ContentType.MixedContentTrack:
        case ContentType.QueueTrack:
        case ContentType.SearchResultTrack: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.track?.title,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }
        case ContentType.ArtistTracks: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.tracks?.items[0].artist.name,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }
        case ContentType.LayoutItem: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.name ?? undefined,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }
        case ContentType.EndlessPlay: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: undefined,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }

        default: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.title,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }
    }
    resource.contentType;
}

export function convertPlayableProperties(properties: AnalyticsPlayableProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "ContentID", properties.contentId as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "ContentName", properties.contentName as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "ContentType", convertContentType(properties.contentType, properties.graphqlType), true);
    SetAnalyticsProp(data, "event", "ContentArtist", properties.contentArtist as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "ContentAlbum", properties.contentAlbum as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "ContentGenre", properties.contentGenre as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "PlaybackContext", properties.playbackContext as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "TrackDuration", properties.trackDuration, true);

    return data;
}

export function convertContentType(type: ContentType | undefined, fallback: string | undefined): AnalyticsKey | null {
    switch (type) {
        case ContentType.LiveRadioTrack:
        case ContentType.TrackPlayable:
        case ContentType.QueueTrack:
            return "Track";
        case ContentType.Album:
            return "Album";
        case ContentType.Artist:
            return "Artist";
        case ContentType.Playlist:
            return "Playlist";
        case ContentType.LiveRadioPlayable:
            return "LiveRadio";
    }

    if (fallback) return fallback as AnalyticsKey;

    log.error({ code: "web-220503-2120", msg: "content type not supported in analytics", data: { type } });
    return null;
}

export function getAnalyticsPlayableIdProperties(id: string, type: AnalyticsKey) {
    const data = createAnalyticsData();
    SetAnalyticsProp(data, "event", "ContentID", id as AnalyticsKey);
    SetAnalyticsProp(data, "event", "ContentType", type);
    return { ...data, type: "PlayableProperties" };
}
