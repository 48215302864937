import { setPosition } from "./setPosition";
import { playNotAllowedError } from "../error";
import { inSkipDebounce } from "../skipDebounce";
import type { PlayPausePositionModel } from "models/app/player";
import { PlayState } from "models/app/player";
import type { BrowserAudioItemModel } from "models/app/player/output";

export function setPlayStateAndPosition(browserAudio: BrowserAudioItemModel, playState: PlayState, position: PlayPausePositionModel) {
    setPlayState(browserAudio, playState);
    setPosition(browserAudio, position, playState);
}

export async function setPlayState(browserAudio: BrowserAudioItemModel, playState: PlayState) {
    if (!browserAudio.isMediaSet) return;

    if (inSkipDebounce) {
        if (playState === PlayState.Buffering) return;
        if (playState === PlayState.Playing) return;
    }

    if (browserAudio.intendedPlayState === playState) return;
    browserAudio.intendedPlayState = playState;

    const element = browserAudio.element;
    const wasPlaying = !element.paused;

    switch (playState) {
        case PlayState.Buffering:
        case PlayState.Playing: {
            if (!wasPlaying) {
                try {
                    await element.play();
                } catch (e) {
                    const domException = e as DOMException;
                    console.error("play error", e);

                    if (domException?.name === "NotAllowedError") playNotAllowedError(browserAudio, e);
                    return;
                }
            }
            return;
        }
        case PlayState.Error:
        case PlayState.Stopped:
            if (wasPlaying) {
                element.pause();
            }
            return;
        case PlayState.Paused: {
            if (wasPlaying) {
                element.pause();
            }
            return;
        }
    }
}
