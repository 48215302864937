import type { Analytics, AnalyticsCallOptions } from "firebase/analytics";
import type { FirebaseApp, FirebaseOptions } from "firebase/app";
import { getMessageLogFields } from ".";
import { DefaultLogMessage, log, LogLevel } from "./initLoggerService";
import { messageBus } from "global";
import type { WebAppBrand, WebAppEnvironment } from "shared/models";
import { LOGGING_EVENT_MESSAGE } from "global/actions";
import { environment } from "services/environment";

let fbApp: FirebaseApp | null | undefined = null;

type GetConfig = (webAppBrand: WebAppBrand, webAppEnvironment: WebAppEnvironment) => FirebaseOptions;
type FirebaseInitializeApp = (options: FirebaseOptions) => FirebaseApp;
type FirebaseInitializeAnalytics = (app: FirebaseApp) => Analytics;
type FirebaseLogEvent = (analyticsInstance: Analytics, eventName: string, eventParams?: { key: string; value: string }[], options?: AnalyticsCallOptions) => void;

const initConfig = () => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fb_GetConfig: GetConfig | null = (<any>window).trackingFbase?.getConfig ?? null;
        if (!fb_GetConfig) {
            log.warn({ code: "web-220505-1343", msg: "blocked" });
            return null;
        }

        const fbOptions = fb_GetConfig(environment.webAppBrand, environment.runtimeEnvironment);
        if (!fbOptions) {
            log.error({ code: "web-220505-1344", msg: DefaultLogMessage.UnexpectedNull });
            return null;
        }
        return fbOptions;
    } catch (e) {
        log.error({ code: "web-220601-1044", msg: "exception", error: e });
        return null;
    }
};

const initFbApp = (config: FirebaseOptions | null) => {
    if (!config) return config;

    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const initializeApp: FirebaseInitializeApp | null = (<any>window).trackingFbase?.initializeApp ?? null;
        if (!initializeApp) {
            log.warn({ code: "web-220429-1707", msg: "blocked" });
            return null;
        }

        const fbApp = initializeApp(config);
        return fbApp;
    } catch (e) {
        log.error({ code: "web-220601-1055", msg: "exception", error: e });
        return null;
    }
};

export const getOrCreateFbApp = () => {
    try {
        if (!fbApp) {
            const config = initConfig();
            fbApp = initFbApp(config);
        }
        return fbApp;
    } catch (e) {
        log.error({ code: "web-210519-1542", msg: "exception", error: e });
        return null;
    }
};

const initAnalytics = (fbApp: FirebaseApp): Analytics | null => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const initializeAnalytics: FirebaseInitializeAnalytics | null = (<any>window).trackingFbase?.initializeAnalytics ?? null;
        if (!initializeAnalytics) {
            log.warn({ code: "web-220502-1253", msg: DefaultLogMessage.UnexpectedNull });
            return null;
        }

        return initializeAnalytics(fbApp);
    } catch (e) {
        log.error({ code: "web-211026-1542", msg: DefaultLogMessage.UnexpectedNull, error: e });
        return null;
    }
};
export const initFbLoggerService = () => {
    const fbApp = getOrCreateFbApp();
    if (!fbApp) return;
    const analytics = initAnalytics(fbApp);
    if (!analytics) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const logEvent: FirebaseLogEvent | null = (<any>window).trackingFbase?.logEvent ?? null;
    if (!logEvent) {
        log.warn({ code: "web-220429-1443", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    const getEventName = (level: LogLevel) => {
        switch (level) {
            case LogLevel.Warning:
                return "web_warning";
            case LogLevel.Error:
                return "web_error";
            default:
                return null;
        }
    };
    messageBus.subscribeEvery(LOGGING_EVENT_MESSAGE, (msg) => {
        const logToFbaseAnalytics = () => {
            const { message } = msg.payload;

            const logName = getEventName(message.level);
            if (logName == null) return;

            try {
                const fields = getMessageLogFields(message, "Web Log Field");
                logEvent(analytics, logName, fields);
            } catch (e) {
                log.error({ code: "web-211026-1442", msg: "couldn't log to fbase", error: e });
            }
        };
        setTimeout(() => logToFbaseAnalytics(), 0);
    });
};
