import { useCallback, useEffect, useState } from "preact/hooks";
import { ScrollerPurpose, ScrollerState, ScrollerAlign, ScrollerSnapType, ScrollerSnapStop, ScrollerEffect, ScrollerNavigateCallback } from "../options";
import { ScrollerProps } from "../Scroller";
import { getMaxIndex } from "./useScroller/getMaxIndex";
import { getEffectAjust, useUserScroll, useScrollIndex, useScrollTo } from "./useScroller/index";
import { useScrollerNavigateProperties } from "./useScroller/useScrollerNavigateProperties";
import { useScrollZone } from "./useScroller/useScrollZone";
import { useRefUpdate } from "components/shared/hooks";
import { useAppMediaSize, MediaSize } from "components/shared/hooks/useAppMediaSize";
import { ScrollAxis } from "components/shared/hooks/useScrollPosition";
import { useScrollState } from "components/shared/hooks/useScrollState";

const useScroller = ({
    children,
    size,
    onSelectedChange,
    align = ScrollerAlign.Center,
    purpose = ScrollerPurpose.Selection,
    scrollSnap = {
        type: ScrollerSnapType.Mandatory,
        stop: ScrollerSnapStop.Normal
    },
    effect = ScrollerEffect.ActiveGrow,
    index = 0,
    navigateRef,
    onNavigatePropertiesChange
}: ScrollerProps) => {
    const appSize = useAppMediaSize();

    const [state, setState] = useState<ScrollerState>(ScrollerState.ComponentLoad);

    const adjustTransform = getEffectAjust(effect);
    const scrollSnapFix = scrollSnap != null && effect === ScrollerEffect.ActiveGrow;

    const gapSize = appSize === MediaSize.Mobile ? 16 : 24;
    const adjustSize = !scrollSnapFix ? adjustTransform : 1;
    const gap = !scrollSnapFix ? gapSize : 0;

    const [selectedIndex, setSelectedIndex] = useState(index);
    const containerRef = useRefUpdate<HTMLElement>();
    const contentRef = useRefUpdate<HTMLElement>();

    const scrollState = useScrollState(containerRef.current, contentRef.current, ScrollAxis.x);
    const { zoneCount, canvasCount } = useScrollZone(containerRef?.current, contentRef?.current, size, align, adjustSize, gap);
    const maxIndex = getMaxIndex(children?.length ?? 0, canvasCount, align, purpose);
    const { inUserScroll, inTouch } = useUserScroll(containerRef.current);
    const { scrollIndex, scrollIndexDecimal } = useScrollIndex(containerRef.current, size, align, selectedIndex, adjustSize, gap);

    useScrollerNavigateProperties(purpose, selectedIndex, maxIndex, scrollState, onNavigatePropertiesChange);

    const onScrollTo = () => setState(ScrollerState.None);
    useScrollTo(containerRef.current, selectedIndex, state, false, align, size, adjustSize, gap, onScrollTo);

    useEffect(() => {
        if (selectedIndex === index) return;
        onSelectedChange && onSelectedChange(selectedIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);

    useEffect(() => {
        setState(ScrollerState.ExternalChange);
        setSelectedIndex(index);
    }, [index]);

    useEffect(() => {
        if (state === ScrollerState.None && inTouch) setState(ScrollerState.UserScroll);
        if (state === ScrollerState.UserScroll && !inUserScroll) setState(ScrollerState.UserScrollStop);
    }, [state, inUserScroll, inTouch]);

    useEffect(() => {
        if (state === ScrollerState.UserScrollStop) {
            setState(ScrollerState.None);
            setSelectedIndex(scrollIndex);
        }
    }, [state, scrollIndex]);

    const navigate: ScrollerNavigateCallback = useCallback(
        (add: 1 | -1) => {
            const getNewIndex = () => {
                let index = selectedIndex;

                index += add * zoneCount;
                index = Math.round(index / zoneCount) * zoneCount;

                if (index < 0) index = 0;
                if (index > maxIndex) index = maxIndex;
                return index;
            };

            setState(ScrollerState.ButtonClick);
            setSelectedIndex(getNewIndex());
        },
        [maxIndex, zoneCount, selectedIndex]
    );
    if (navigateRef) navigateRef.current = navigate;

    return {
        scrollIndexDecimal,
        containerRef,
        contentRef,
        scrollSnapFix,
        adjustSize,
        adjustTransform,
        selectedIndex,
        scrollIndex,
        scrollState,
        gap
    };
};
export default useScroller;
