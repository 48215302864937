import { h } from "preact";
import { useMemo } from "preact/hooks";
import {
    fillArtistPage_appearsOn,
    fillArtistPage_artistAlbums,
    fillArtistPage_artistSingles,
    fillArtistPage_artistTracks,
    fillArtistPage_headerSection,
    fillArtistPage_similarArtists
} from "services/backend";
import type { CombinedQueryParams } from "services/backend/backendService";
import { useCombinedQuery } from "services/backend/backendService";
import type { ArtistPageModel } from "models/domain";
import {
    fillArtistPageModel_appearsOn,
    createEmptyArtistPageModel,
    fillArtistPageModel_artistAlbums,
    fillArtistPageModel_artistSingles,
    fillArtistPageModel_artistTracks,
    fillArtistPageModel_basicSectionAndRadio,
    fillArtistPageModel_similarArtists,
    ResourceDisplayType
} from "models/domain";
import { ContentType } from "models/ModelType";
import { useAutoPlay, usePageContext, useSectionContext, useTrackPlayables } from "components/shared/hooks";
import { Line } from "components/atoms/line";
import { ArtistRadioPreviewSingle } from "components/molecules/preview/singles";
import { ResourceSection } from "components/organisms/resourceSection";
import { ProductPageTemplate } from "components/templates/productPage";

interface Props {
    artistId: string;
}

export const ArtistPage = ({ artistId }: Props) => {
    const combined: CombinedQueryParams<ArtistPageModel> = useMemo(() => {
        return {
            model: createEmptyArtistPageModel(artistId),
            params: [
                { fetch: () => fillArtistPage_headerSection({ id: artistId }), map: fillArtistPageModel_basicSectionAndRadio, essential: true },
                { fetch: () => fillArtistPage_artistTracks({ id: artistId }), map: fillArtistPageModel_artistTracks, essential: true },
                { fetch: () => fillArtistPage_artistAlbums({ id: artistId }), map: fillArtistPageModel_artistAlbums },
                { fetch: () => fillArtistPage_artistSingles({ id: artistId }), map: fillArtistPageModel_artistSingles },
                { fetch: () => fillArtistPage_similarArtists({ id: artistId }), map: fillArtistPageModel_similarArtists },
                { fetch: () => fillArtistPage_appearsOn({ id: artistId }), map: fillArtistPageModel_appearsOn }
            ]
        };
    }, [artistId]);

    const { query } = useCombinedQuery(combined);
    const artist = query.model;

    const page = usePageContext({ type: ContentType.Artist, resource: artist, root: null, done: query.success });

    const top = useSectionContext(ContentType.ArtistTracks, artist?.tracksSection ?? null, page, 0, ResourceDisplayType.Single);
    const tracks = useSectionContext(ContentType.ArtistTracks, artist?.tracksSection ?? null, page, 1, ResourceDisplayType.List);
    const albums = useSectionContext(ContentType.ArtistAlbums, artist?.albums ?? null, page, 2, ResourceDisplayType.Scroller);
    const singles = useSectionContext(ContentType.ArtistSingles, artist?.singles ?? null, page, 3, ResourceDisplayType.Scroller);
    const radio = useSectionContext(ContentType.ArtistRadio, artist?.artistRadio ?? null, page, 4, ResourceDisplayType.Scroller);
    const artists = useSectionContext(ContentType.ArtistSimilarArtists, artist?.similarArtists ?? null, page, 5, ResourceDisplayType.Scroller);
    const appearsOn = useSectionContext(ContentType.ArtistAppearsOn, artist?.appearsOn ?? null, page, 6, ResourceDisplayType.Scroller);

    const trackPlayables = useTrackPlayables(artist?.tracksSection?.tracks?.items ?? null, artist);

    useAutoPlay({ page, type: ContentType.ArtistTracks, resource: artist?.tracksSection ?? null });

    return (
        <ProductPageTemplate key={artistId} query={query} type={ContentType.Artist} section={top} isUserOwned={false}>
            <ResourceSection showTitle resources={trackPlayables} context={tracks} showAll={artist?.tracksSection} />
            <ResourceSection showTitle resources={artist?.albums?.albums?.items ?? null} context={albums} showAll={artist?.albums} />
            <ResourceSection showTitle resources={artist?.singles?.albums?.items ?? null} context={singles} showAll={artist?.singles} />
            <ResourceSection showTitle resources={artist?.appearsOn?.albums?.items ?? null} context={appearsOn} showAll={artist?.appearsOn} />
            <ArtistRadioPreviewSingle section={radio}>{artist?.artistRadio ?? null}</ArtistRadioPreviewSingle>
            <Line />
            <ResourceSection showTitle resources={artist?.similarArtists?.artists?.items ?? null} context={artists} showAll={artist?.similarArtists} />
        </ProductPageTemplate>
    );
};
