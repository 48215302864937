import { Fragment, h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { LiveRadioQueueTopBar } from "./LiveRadioActions";
import { useTranslations } from "global/config";
import { animations } from "global/constants";
import { getNextUUID } from "services/utils";
import type { DragProps, RootModel } from "models/app";
import type { TrackPlayableModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useRefUpdate, useSectionContext, useTrackPlayables } from "components/shared/hooks";
import { CoverSize } from "components/atoms/cover";
import { ResourcePreviewRow } from "components/molecules/preview/rows";
import { QueueListView } from "components/organisms/recyclerView";
import { ScrollerWithFade } from "components/organisms/scrollerWithFade";

interface Props {
    disableFade?: boolean;
}

export const LiveRadioQueue = ({ disableFade }: Props) => {
    const radio = useSelector((root: RootModel) => root.liveRadio.currentRadio?.liveRadio ?? null);
    const radioPreview = radio;
    const recentlyPlayed = useMemo(() => radio?.tracks?.recentlyPlayedTracks?.items ?? null, [radio]);
    const sectionContext = useSectionContext(ContentType.Queue, null, null, null, ResourceDisplayType.List);

    const dragProps: DragProps = useMemo(() => ({ dragSourceId: getNextUUID() }), []);
    const scrollRef = useRefUpdate<HTMLDivElement>();

    const isMobile = useAppMediaSize() === MediaSize.Mobile;
    const playables = useTrackPlayables(recentlyPlayed?.map((item) => item.track) ?? null, radio);
    const translations = useTranslations();

    const mapper = useCallback(
        (track: TrackPlayableModel, index: number) => (
            <ResourcePreviewRow
                dragProps={dragProps}
                key={track.id}
                dropProps={null}
                resource={track}
                context={sectionContext}
                rank={index}
                size={CoverSize.Size48}
                width={null}
                disableLazyLoad={false}
            />
        ),
        [dragProps, sectionContext]
    );

    return (
        <div className={`queue LYy --animate-${animations.animateQueue}`}>
            {!isMobile && (
                <Fragment>
                    <LiveRadioQueueTopBar />
                    <hr />
                </Fragment>
            )}
            {radioPreview && (
                <Fragment>
                    <section className="currentLanePreview">
                        <ResourcePreviewRow
                            dragProps={dragProps}
                            dropProps={null}
                            resource={radioPreview}
                            context={sectionContext}
                            rank={0}
                            size={CoverSize.Size64}
                            width={null}
                            disableLazyLoad={true}
                        />
                    </section>
                    <hr />
                </Fragment>
            )}
            <ScrollerWithFade tabIndex={-1} ref={scrollRef} disableFade={disableFade}>
                <section className={"recentlyPlayed"}>
                    <div className="top">
                        <h1 className="headline">{playables ? translations.LiveRadioTracksHistoryHeader : translations.LiveRadioTracksHistoryEmpty}</h1>
                    </div>
                    {playables && <QueueListView resources={playables} mapper={mapper} />}
                </section>
            </ScrollerWithFade>
        </div>
    );
};
