import { createBrowserAudio, disposeBrowserAudio, updateBrowserAudio } from "../browserAudio";
import { store } from "global";
import { preloadOrderConfig } from "global/constants";
import { shouldTryReplayAudio } from "services/player/inputs/service/actions";
import { isOutputBrowserFromState } from "services/player/outputs/service";
import { PlayState } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";
import type { BrowserAudioItemModel } from "models/app/player/output";

let browserAudios: BrowserAudioItemModel[] = [];

export function getBrowserAudios(): BrowserAudioItemModel[] {
    return browserAudios;
}

export function getBrowserAudio(audioId: string): BrowserAudioItemModel | null {
    const audio = browserAudios.find((audio) => audio.audioId === audioId) ?? null;
    return audio;
}

export function removeBrowserAudio(browserAudio: BrowserAudioItemModel) {
    disposeBrowserAudio(browserAudio);
    browserAudios = browserAudios.filter((browserAudio) => browserAudio !== browserAudio);
}

export function replaceBrowserAudio(remove: BrowserAudioItemModel, add: AudioInputItemModel): BrowserAudioItemModel {
    removeBrowserAudio(remove);
    const browserAudio = addBrowserAudio(add);
    return browserAudio;
}

export function updateBrowserAudios() {
    if (!isOutputBrowserFromState()) {
        disposeBrowserAudios();
        return [];
    }

    const audios = store.getState().audioInput.audios.filter((audio) => shouldLoad(audio));

    let newBrowserAudios = createOrUpdate(audios);
    newBrowserAudios = browserAudiosSort(newBrowserAudios);

    browserAudios.forEach((item) => newBrowserAudios.indexOf(item) === -1 && disposeBrowserAudio(item));
    browserAudios = newBrowserAudios;
}

function addBrowserAudio(audio: AudioInputItemModel) {
    const browserAudio = createBrowserAudio(audio);
    browserAudios.push(browserAudio);
    browserAudios = browserAudiosSort(browserAudios);

    return browserAudio;
}

function createOrUpdate(audios: AudioInputItemModel[]) {
    return audios.map((audio) => {
        let browserAudio = getBrowserAudio(audio.audioId);

        if (browserAudio == null) browserAudio = createBrowserAudio(audio);
        else browserAudio = updateBrowserAudio(audio, browserAudio);

        return browserAudio;
    });
}

function disposeBrowserAudios() {
    browserAudios.forEach((n) => disposeBrowserAudio(n));
    browserAudios = [];
}

function browserAudiosSort(browserAudios: BrowserAudioItemModel[]): BrowserAudioItemModel[] {
    return browserAudios.sort((a, b) => a.preload.order - b.preload.order);
}

function shouldLoad(audio: AudioInputItemModel): boolean {
    const playState = audio.playState;

    if (playState === PlayState.Error) return false;
    if (playState === PlayState.Paused || playState === PlayState.Playing || playState === PlayState.Buffering) return true;
    if (!shouldTryReplayAudio(audio)) return false;
    if (!store.getState().controlPanel.enableAudioPreload) return false;

    const should = preloadOrderConfig.some((value) => value.index === audio.index);
    return should;
}
