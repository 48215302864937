import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { FAVORITES_UPDATED, USER_LOGGED_IN } from "global/actions";
import { messageBus, useTranslations } from "global/config";
import { getPaginationInit } from "global/constants/pagination";
import { useMessageBus } from "global/hooks";
import { getFavoriteAlbums, useFavoriteAlbumsPage } from "services/backend";
import { useDisplayLoggedIn } from "services/user";
import type { RootModel } from "models/app";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useCoalesced } from "components/shared/hooks";
import { MyMusicAlbumsSortingContextMenu } from "components/organisms/contextMenu/menus";
import { MyMusicPageTemplate } from "components/templates/myMusicPage";

export const MyMusicAlbumsPage = () => {
    const appSize = useAppMediaSize();
    const displayType = appSize === MediaSize.Mobile ? ResourceDisplayType.ListLarge : ResourceDisplayType.Grid;
    const translations = useTranslations();

    const orderBy = useSelector((root: RootModel) => root.ui.albumsSorting);
    const [criterion, setCriterion] = useState<string | null>(null);

    const skip = !useDisplayLoggedIn();
    const query = useFavoriteAlbumsPage({ first: getPaginationInit(), after: null, orderBy, criterion }, skip);
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string | null) => (product ? getFavoriteAlbums({ first, after, criterion, orderBy }) : null), [criterion, orderBy, product]);

    const albums = useCoalesced(product?.albums ?? null);

    messageBus.subscribeEvery(USER_LOGGED_IN, (msg) => {
        if (!msg.payload.isNewUser) return;
        query.refetch(false);
    });

    useMessageBus(FAVORITES_UPDATED, (message) => {
        if (message?.payload?.changes.playables.find((n) => n.contentType === ContentType.Album)) {
            // todo: consider editing-inplace as an optimization if this was an item being removed
            query.refetch(false);
        }
    });

    return (
        <MyMusicPageTemplate
            criterion={criterion}
            onFilterChanged={setCriterion}
            placeholderTitle={translations.MyMusicAlbumPlaceholderTitle}
            placeholderSubtitle={translations.MyMusicAlbumPlaceholderSubtitle}
            placeholderFilter={translations.FilterAlbums}
            placeholderFilterNoResult={translations.FilterNoAlbumsFound}
            placeholderFilterNoResultSubtitle={translations.SearchNoResultHint}
            query={query}
            resources={albums}
            productType={ContentType.MyMusicAlbums}
            resourcesFetchFn={fetchFn}
            displayType={displayType}
            sortingMenu={<MyMusicAlbumsSortingContextMenu />}
        />
    );
};
