import type { ErrorPolicy, FetchResult } from "@apollo/client";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { graphqlClient } from "./graphqlClient";
import { log } from "services/logger";

export async function mutateDomainModelWithErrors<Data, Variables>(
    mutation: TypedDocumentNode<Data, Variables>,
    variables: Variables | null,
    correlationId: string | null | undefined = undefined,
    enableErrorLog = true,
    errorPolicy: ErrorPolicy | undefined = "all"
): Promise<FetchResult<Data> | null> {
    if (!graphqlClient) {
        enableErrorLog && log.error({ code: "web-210207-1702", msg: "no graphQLClient" });
        return null;
    }
    let result: FetchResult<Data> | null = null;
    if (!graphqlClient) {
        enableErrorLog && log.error({ code: "web-210207-1701", msg: "no graphQLClient" });
        return null;
    }
    try {
        if (correlationId) {
            // temporarily add the correlationId to the variables.
            // the apollo link will remove it and place it in a header instead.
            // (this seems to be the easiest way to forward generic data to the link.)
            if (!variables) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (variables as any) = {};
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (variables as any).correlationId = correlationId;
        }

        result = await graphqlClient.mutate<Data>({ mutation, variables: variables ?? undefined, errorPolicy });
    } catch (e) {
        console.log("mutate error", e);
        enableErrorLog && log.error({ code: "web-210207-1716", msg: "mutate error", data: { exception: e } });
        return null;
    }

    return result;
}

// this mutate helper should replace the other one: "mutateDomainModel"
export async function mutateDomainModel<Data, Variables, Model>(
    mutation: TypedDocumentNode<Data, Variables>,
    variables: Variables | null,
    map: (data: Data) => Model | null | undefined,
    correlationId: string | null | undefined = undefined,
    enableErrorLog = true
): Promise<Model | null> {
    const result = await mutateDomainModelWithErrors(mutation, variables, correlationId, enableErrorLog, undefined);
    if (result?.data == null) {
        enableErrorLog && log.error({ code: "web-210207-1714", msg: "mutate error" });
        return null;
    }
    if (result?.data) {
        try {
            return map(result.data) ?? null;
        } catch (e) {
            enableErrorLog && log.error({ code: "web-210207-1715", msg: "map error", data: { exception: e } });
        }
    }
    return null;
}
