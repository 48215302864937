import { messageBus } from "global";
import { DISPLAYED_LYRICS } from "global/actions";
import { getAppSession } from "services/appSession";
import { getPendingLyricReports, savePendingLyricReports, type LyricReport } from "services/appSession/operations/lyricReports";
import { mutateReportLyrics } from "services/backend";
import { DisplayType, SynchronizationType } from "generated/graphql-types";

const initialDelayInSeconds = 10;
const maxDelayInSeconds = 120;
let delayInSeconds = initialDelayInSeconds;

export async function initLyricsReportService() {
    messageBus.subscribeEvery(DISPLAYED_LYRICS, async (msg) => {
        sendOrSaveReport({
            displayType: msg.payload.sync == SynchronizationType.Lines ? DisplayType.Sync : DisplayType.Static,
            displayedAt: new Date().toISOString(),
            lyricsId: msg.payload.lyricsId,
            trackId: msg.payload.playable.id
        })
    });
    setTimeout(trySendPendingReports, delayInSeconds * 1000);
}

async function sendOrSaveReport(report: LyricReport) {
    try {
        const ok = await mutateReportLyrics({ report });
        if (!ok) throw new Error("Error sending lyrics report.");
    }
    catch (e) {
        const storageId = getAppSession().storageId;
        const reports = getPendingLyricReports(storageId).slice(-999); // basic sanity-check, lets never store more than 1000 pending lyric-reports
        reports.push(report);
        savePendingLyricReports(storageId, reports);
    }
}

async function trySendPendingReports() {
    try {
        let count = 5; // basic sanity-check, lets never try to save more then 5 in a row
        const storageId = getAppSession().storageId;
        while (count > 0) {
            count--;
            let reports = getPendingLyricReports(storageId);
            if (reports.length == 0) return;

            try {
                const report = reports[0];
                const ok = await mutateReportLyrics({ report });
                if (!ok) throw new Error("Error sending lyrics report.");
                delayInSeconds = initialDelayInSeconds;
            }
            catch (e) {
                delayInSeconds = Math.min(delayInSeconds * 2, maxDelayInSeconds);
                console.log(`Failed to re-send lyric report, increasing retry-delay to ${delayInSeconds} seconds.`);
                return;
            }

            reports = getPendingLyricReports(storageId).slice(1); // fetch again in case it was mutated while we were waiting
            savePendingLyricReports(storageId, reports);
        }
    }
    finally {
        setTimeout(trySendPendingReports, delayInSeconds * 1000);
    }
}