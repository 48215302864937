import { getAppSession } from "../appSessionService";
import { getData, saveData } from "../helpers";
import { dispatch } from "global";
import { USER_UPDATED_TOKENS } from "global/actions";
import { isUnloading } from "global/config";
import { log, LogTag } from "services/logger";

export async function getTokens(tokenStorageId?: string): Promise<{ refreshToken: string | null; refreshTokenDate: Date | null, accessToken: string | null }> {
    tokenStorageId ??= getAppSession().tokenStorageId;
    await tokenRefreshBlock(tokenStorageId);
    return getTokensNow(tokenStorageId);
}

export function getTokensNow(tokenStorageId?: string): { refreshToken: string | null; refreshTokenDate: Date | null, accessToken: string | null } {
    tokenStorageId ??= getAppSession().tokenStorageId;
    const refreshToken = getRefreshTokenNow(tokenStorageId);
    const refreshTokenDate = getRefreshTokenDateNow(tokenStorageId);
    const accessToken = getAccessTokenNow(tokenStorageId);
    return { refreshToken, refreshTokenDate, accessToken };
}

export function removeTokens() {
    //log.warn({ code: "web-220926-1530", msg: "remove tokens", tags: [LogTag.User] });
    return saveTokens(null, null, "logout");
}

export function saveTokens(refreshToken: string | null, accessToken: string | null, tokenSource: "init" | "login" | "refresh" | "logout"): boolean {
    const tokenStorageId = getAppSession().tokenStorageId;

    if (inRefreshBlock(tokenStorageId)) {
        log.error({ code: "web-220718-1307", msg: "can't write user refresh/access tokens cause they're locked" });
        return false;
    }

    if (tokenSource == "login") {
        removeRefreshTokenDate(tokenStorageId);
    }

    if (!saveRefreshToken(tokenStorageId, refreshToken, tokenSource == "refresh")) return false;
    if (!saveAccessToken(tokenStorageId, accessToken)) return false;

    log.info({ code: "web-220926-1530", msg: "--- stored tokens", data: { refreshToken, accessToken }, tags: [LogTag.User] });
    dispatch({
        type: USER_UPDATED_TOKENS,
        payload: {
            refreshToken,
            accessToken,
        }
    });

    return true;
}

export function saveTokensNow(refreshToken: string | null, accessToken: string | null, isRefresh: boolean, tokenStorageId?: string) {
    tokenStorageId ??= getAppSession().tokenStorageId;
    saveRefreshTokenNow(tokenStorageId, refreshToken, isRefresh);
    saveAccessTokenNow(tokenStorageId, accessToken);
}

function getRefreshTokenDateNow(tokenStorageId: string): Date | null {
    const key = getTokenSessionRefreshTokenDateKey(tokenStorageId);
    const data = getData(key);
    return data ? new Date(data) : null;
}

function getRefreshTokenNow(tokenStorageId: string): string | null {
    const key = getTokenSessionRefreshTokenKey(tokenStorageId);
    const data = getData(key);
    return data;
}

function saveRefreshToken(tokenStorageId: string, refreshToken: string | null, isRefresh: boolean): boolean {
    if (inRefreshBlock(tokenStorageId)) {
        log.error({ code: "web-220718-1305", msg: "can't write user refresh token cause it's locked" });
        return false;
    }

    saveRefreshTokenNow(tokenStorageId, refreshToken, isRefresh);
    return true;
}


function removeRefreshTokenDate(tokenStorageId: string) {
    if (inRefreshBlock(tokenStorageId)) {
        log.error({ code: "web-220718-1305", msg: "can't write user refresh token date cause it's locked" });
        return false;
    }
    removeRefreshTokenDateNow(tokenStorageId);
}

function removeRefreshTokenDateNow(tokenStorageId: string) {
    const dateKey = getTokenSessionRefreshTokenDateKey(tokenStorageId);
    saveData(dateKey, null);
}

function saveRefreshTokenNow(tokenStorageId: string, refreshToken: string | null, isRefresh: boolean) {
    const tokenKey = getTokenSessionRefreshTokenKey(tokenStorageId);
    saveData(tokenKey, refreshToken);

    if (isRefresh || !refreshToken) {
        const dateKey = getTokenSessionRefreshTokenDateKey(tokenStorageId);
        saveData(dateKey, isRefresh ? new Date().toJSON() : null);
    }
}

function getAccessTokenNow(tokenStorageId: string): string | null {
    const key = getTokenSessionAccessTokenKey(tokenStorageId);
    const data = getData(key);
    return data;
}

function saveAccessToken(tokenStorageId: string, accessToken: string | null): boolean {
    if (inRefreshBlock(tokenStorageId)) {
        log.error({ code: "web-220718-1306", msg: "can't write user access token cause it's locked" });
        return false;
    }

    saveAccessTokenNow(tokenStorageId, accessToken);
    return true;
}

function saveAccessTokenNow(tokenStorageId: string, accessToken: string | null) {
    const key = getTokenSessionAccessTokenKey(tokenStorageId);
    saveData(key, accessToken);
}

function getTokenSessionAccessTokenKey(tokenStorageId: string) {
    return `accessToken_${tokenStorageId}`;
}

function getTokenSessionRefreshTokenKey(tokenStorageId: string) {
    return `refreshToken_${tokenStorageId}`;
}

function getTokenSessionRefreshTokenDateKey(tokenStorageId: string) {
    return `refreshToken_date_${tokenStorageId}`;
}

function getTokenSessionInTokenRefreshKey(tokenStorageId: string) {
    return `inTokenRefresh_${tokenStorageId}`;
}

function tokenRefreshBlock(tokenStorageId: string): Promise<void> {
    return new Promise<void>((resolve) => {
        let inBlock = false;

        const check = () => {
            inBlock = inRefreshBlock(tokenStorageId);
            if (!inBlock) {
                resolve();
                return;
            }
            setTimeout(() => check(), 100);
        };

        check();
        if (inBlock) {
            setTimeout(() => {
                if (!inBlock) return;
                inBlock = false;

                log.error({ code: "web-220718-1409", msg: "userTokenRefreshLock timed out, has been unlocked" });

                _setTokenRefreshLock(tokenStorageId, false);
                resolve();
            }, 10_000);
        }
    });
}

function inRefreshBlock(tokenStorageId?: string): boolean {
    tokenStorageId ??= getAppSession().tokenStorageId;

    const me = getAppSession().tokenStorageId === tokenStorageId;
    if (me && inTokenSessionRefresh) return false;

    const lock = inRefreshLock(tokenStorageId);
    return lock;
}

function inRefreshLock(tokenStorageId?: string): boolean {
    tokenStorageId ??= getAppSession().tokenStorageId;

    const key = getTokenSessionInTokenRefreshKey(tokenStorageId);
    const lock = getData(key) === "true";

    return lock;
}

export function setTokenRefreshLock(value: boolean, tokenStorageId?: string) {
    tokenStorageId ??= getAppSession().tokenStorageId;
    return _setTokenRefreshLock(tokenStorageId, value);
}

let inTokenSessionRefresh = false;
function _setTokenRefreshLock(tokenStorageId: string, value: boolean) {
    if (value === inRefreshLock(tokenStorageId)) log.error({ code: "web-220718-1424", msg: "setting lock to same value" });

    inTokenSessionRefresh = value;
    _saveInTokenRefresh(value, tokenStorageId);
}

export function setTokenRefreshLockTrueForDebug() {
    _saveInTokenRefresh(true);
}

function _saveInTokenRefresh(value: boolean, tokenStorageId?: string) {
    if (value && isUnloading) return;
    tokenStorageId ??= getAppSession().tokenStorageId;
    const key = getTokenSessionInTokenRefreshKey(tokenStorageId);
    saveData(key, value ? "true" : "false");
}

export function unloadTokenLock() {
    if (inTokenSessionRefresh) {
        _saveInTokenRefresh(false);
    }
}
