export interface UserProfileModel {
    id: string;
    title: string;
    description: string | null;
    current: boolean;
    master: boolean;
    color: string;
}

export function createUserProfileModel(input: { id: string, title: string, description?: string | null | undefined, current: boolean, master: boolean, color: string }): UserProfileModel {
    return ({
        id: input.id,
        title: input.title,
        description: input.description ?? null,
        current: input.current,
        master: input.master,
        color: input.color
    });
}


/*
export function getColorFromProfile(color: ProfileColor): string {
    switch (color) {
        case ProfileColor.Black:
            return "#000000";
        case ProfileColor.Blue:
            return "#BCC7E6";
        case ProfileColor.Green:
            return "#83F180";
        case ProfileColor.Orange:
            return "#FFBBB6";
        case ProfileColor.Red:
            return "#FF0000";
        case ProfileColor.White:
            return "#D8D5CA";
    }
}
*/