import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { log } from "services/logger";
import { AudioContextAction } from "models/app/player/AudioContext";

export type AnalyticsPlayableStopProperties = {
    playDuration: number;
    playedMoreThan30sec: boolean;
    endReason: AudioContextAction;
};

export interface AnalyticsPlayablePlayEndData {
    playDuration: number;
    endReason: AudioContextAction;
}

export function getAnalyticsPlayableStopProperties({ playDuration, endReason }: AnalyticsPlayablePlayEndData): AnalyticsPlayableStopProperties {
    return {
        playDuration,
        playedMoreThan30sec: playDuration >= 30,
        endReason
    };
}

export function convertPlayableStopProperties(properties: AnalyticsPlayableStopProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "PlayDuration", properties.playDuration);
    SetAnalyticsProp(data, "event", "PlayedMoreThan30sec", properties.playDuration >= 30);
    SetAnalyticsProp(data, "event", "EndReason", convertAnalyticsPlayEndReasonFromAction(properties.endReason));

    return data;
}

function convertAnalyticsPlayEndReasonFromAction(action: AudioContextAction): AnalyticsKey | null {
    switch (action) {
        case AudioContextAction.ChromecastApiAvailable:
        case AudioContextAction.ChromecastLocalSync:
        case AudioContextAction.ChromecastNoSession:
        case AudioContextAction.ChromecastResumeTimeoutOnPageLoad:
        case AudioContextAction.ChromecastSessionResumed:
        case AudioContextAction.ChromecastSessionSessionEnded:
        case AudioContextAction.ChromecastSessionSessionEnding:
        case AudioContextAction.ChromecastSessionStartFailed:
        case AudioContextAction.ChromecastSessionStarted:
            return "Skip";
        case AudioContextAction.AppSessionEnded:
            return "AppClose";
        case AudioContextAction.BrowserAudioEnded:
        case AudioContextAction.ChromecastPlayerStateChange:
        case AudioContextAction.ChromecastMediaLoadedChange:
        case AudioContextAction.ChromecastCurrentTimeChange:
            return "End";
        case AudioContextAction.UserMediaControlsNext:
        case AudioContextAction.UserMediaControlsPrevious:
        case AudioContextAction.UserPlayerNext:
        case AudioContextAction.UserPlayerPreviousOrRewind:
        case AudioContextAction.UserPlayerSwipeNext:
        case AudioContextAction.UserPlayerSwipePrevious:
        case AudioContextAction.UserEndChromeCast:
            return "Skip";
        case AudioContextAction.UserPageSetAndPlay:
        case AudioContextAction.UserPreviewSetAndPlay:
            return "Replace";
        case AudioContextAction.UserLogIn:
        case AudioContextAction.UserLogOut:
        case AudioContextAction.AppStart:
            return "LogInOut";
        case AudioContextAction.UserPlayQueueClear:
            return "Clear";
        case AudioContextAction.DeeplinkPlay:
            return "Deeplink";
        case AudioContextAction.SkipOnFail:
        case AudioContextAction.AudioHlsError:
        case AudioContextAction.AudioHtmlElementError:
        case AudioContextAction.AudioTrackStreamError:
            return "ErrorSkip";
        case AudioContextAction.Unknown:
            return null;
        case AudioContextAction.AudioLoadTrackStream:
        case AudioContextAction.BrowserAudioPause:
        case AudioContextAction.BrowserAudioPlayPrevented:
        case AudioContextAction.BrowserAudioPlaying:
        case AudioContextAction.BrowserAudioSeeked:
        case AudioContextAction.BrowserAudioSeeking:
        case AudioContextAction.BrowserAudioWaiting:
        case AudioContextAction.ConcurrencyLost:
        case AudioContextAction.ConcurrencyResume:
        case AudioContextAction.EndlessPlayUpdate:
        case AudioContextAction.UserContextMenuAddToQueue:
        case AudioContextAction.UserKeybordKeySpace:
        case AudioContextAction.UserMediaControlsPause:
        case AudioContextAction.UserMediaControlsPlay:
        case AudioContextAction.UserPlayQueueRemove:
        case AudioContextAction.UserPlayerSeek:
        case AudioContextAction.UserPlayerShuffle:
        case AudioContextAction.UserPlayerTogglePlay:
        case AudioContextAction.UserPreviewDragDrop:
        case AudioContextAction.UserPreviewTogglePlay:
            log.error({ code: "web-210930-1817", msg: "action is not a valid end reason", data: { action } });
            return null;
    }
    action;
}
