import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import { getCurrentNotifications, getNotificationFeedFromState, getNotificationsCounts } from "services/notificationFeed";

export type AnalyticsNotificationFeedProperties = {
    numberOfCurrentNotifications: number;
    numberOfCurrentNotificationsSeen: number;
    numberOfCurrentNotificationsConverted: number;
    numberOfNotifications: number;
    numberOfNotificationsSeen: number;
    numberOfNotificationsConverted: number;
    numberOfPreviousNotifications: number;
    numberOfPreviousNotificationsSeen: number;
    numberOfPreviousNotificationsConverted: number;
};

export function getAnalyticsNotificationFeedProperties(): AnalyticsNotificationFeedProperties {
    const feed = getNotificationFeedFromState();
    const all = getNotificationsCounts(feed.notifications);
    const currentNotifications = getCurrentNotifications();
    const current = getNotificationsCounts(currentNotifications.currentNotifications);
    const previous = getNotificationsCounts(currentNotifications.previousNotifications);

    return {
        numberOfNotifications: all.total,
        numberOfNotificationsSeen: all.seen,
        numberOfNotificationsConverted: all.converted,

        numberOfCurrentNotifications: current.total,
        numberOfCurrentNotificationsSeen: current.seen,
        numberOfCurrentNotificationsConverted: current.converted,

        numberOfPreviousNotifications: previous.total,
        numberOfPreviousNotificationsSeen: previous.seen,
        numberOfPreviousNotificationsConverted: previous.converted
    };
}

export function convertNotificationFeedProperties(properties: AnalyticsNotificationFeedProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "user", "NumberOfNotifications", properties.numberOfNotifications);
    SetAnalyticsProp(data, "user", "NumberOfNotificationsSeen", properties.numberOfNotificationsSeen);
    SetAnalyticsProp(data, "user", "NumberOfNotificationsConverted", properties.numberOfNotificationsConverted);

    SetAnalyticsProp(data, "user", "NumberOfCurrentNotifications", properties.numberOfCurrentNotifications);
    SetAnalyticsProp(data, "user", "NumberOfCurrentNotificationsSeen", properties.numberOfCurrentNotificationsSeen);
    SetAnalyticsProp(data, "user", "NumberOfCurrentNotificationsConverted", properties.numberOfCurrentNotificationsConverted);

    SetAnalyticsProp(data, "user", "NumberOfPreviousNotifications", properties.numberOfPreviousNotifications);
    SetAnalyticsProp(data, "user", "NumberOfPreviousNotificationsSeen", properties.numberOfPreviousNotificationsSeen);
    SetAnalyticsProp(data, "user", "NumberOfPreviousNotificationsConverted", properties.numberOfPreviousNotificationsConverted);

    return data;
}
