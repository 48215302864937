import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getArtistSinglesPagination, useArtistSinglesPage } from "services/backend/backend";
import { convertToAlbumSortOption } from "services/sort/sortService";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useSortOptions } from "components/shared/hooks";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    artistId: string;
}

export const ArtistSinglesShowAllPage = ({ artistId }: Props) => {
    const sortOptions = useSortOptions({ context: ContentType.ArtistSingles });
    const orderBy = convertToAlbumSortOption(sortOptions.selected);

    const query = useArtistSinglesPage({ id: artistId, first: getPaginationInit(), orderBy });
    const resource = query.model;

    const fetchFn = useCallback(
        (first: number, after: string | null) => (resource?.artist ? getArtistSinglesPagination({ first, after, id: artistId, orderBy }) : null),
        [artistId, orderBy, resource]
    );

    const display = useAppMediaSize() === MediaSize.Mobile ? ResourceDisplayType.ListLarge : ResourceDisplayType.Grid;

    return <ShowAllPageTemplate query={query} type={ContentType.ArtistSingles} items={resource?.albums ?? null} fetchFn={fetchFn} display={display} sortOptions={sortOptions} />;
};
