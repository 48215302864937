import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { isFeatureEnabled, Feature } from "global/config";
import { getPaginationInit } from "global/constants/pagination";
import { getRecommendationContentPagination, useRecommendationPage } from "services/backend";
import { useFilter } from "services/filter";
import type { RecommendationModel, RecommendationOwner } from "models/domain";
import { ResourceDisplayType, getItemsFromRecommendation } from "models/domain";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useAutoPlay, usePageContext, useResourcesFromUnions, useSectionContext } from "components/shared/hooks";
import { usePagination } from "components/shared/hooks/usePagination";
import { FilterUi } from "components/organisms/filter";
import { ResourceSection } from "components/organisms/resourceSection";
import { ProductPageTemplate } from "components/templates/productPage";

interface Props {
    recommendationId: string;
    owner: RecommendationOwner;
}

export const RecommendationPage = ({ recommendationId, owner }: Props) => {
    const query = useRecommendationPage({ id: recommendationId, first: getPaginationInit() }, owner);

    const product = query.model;
    const fetchFn = useCallback(
        (first: number, after: string, index: number) => getRecommendationContentPagination({ first, after, id: recommendationId }, index),
        [recommendationId]
    );

    const type = product?.contentType ?? ContentType.Recommendation;
    const display = useDisplayType(type);

    const page = usePageContext({ type, resource: product, root: null, done: query.success });
    const top = useSectionContext(type, product, page, 0, ResourceDisplayType.Top);
    const section = useSectionContext(type, product, page, 1, display);

    const pagination = usePagination({
        connection: product ? getItemsFromRecommendation(product) : null,
        fetchFn,
        resource: product
    });
    const unfilteredItems = useResourcesFromUnions(pagination.connection?.items ?? null, product);

    const { filteredItems: items, setFilter, hasLoadedOnce } = useFilter(unfilteredItems, pagination);

    useAutoPlay({ page, type, resource: product ?? null });

    return (
        <ProductPageTemplate query={query} type={type} section={top} isUserOwned={false} scrollRef={pagination.scrollRef}>
            {hasLoadedOnce && isFeatureEnabled(Feature.ClientsideFiltering) && <FilterUi onChange={setFilter} placeholder="" />}
            <ResourceSection pagination={pagination} resources={items} context={section} />
        </ProductPageTemplate>
    );
};

type RecommendationContentType = RecommendationModel["contentType"] | ContentType.Recommendation;

function useDisplayType(type: RecommendationContentType) {
    const appSize = useAppMediaSize();

    const displayType = useMemo(() => {
        switch (type) {
            case ContentType.PlaylistRecommendation: {
                if (appSize === MediaSize.Mobile) return ResourceDisplayType.ListLarge;
                else return ResourceDisplayType.Grid;
            }
            case ContentType.AlbumRecommendation:
            case ContentType.TrackRecommendation:
                return ResourceDisplayType.List;
            case ContentType.Recommendation:
                return null;
            default:
                return null;
        }
    }, [type, appSize]);

    return displayType;
}
