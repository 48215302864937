import { SetAnalyticsProp, SetFirstAnalyticsProp, IncrementAnalyticsProp, createAnalyticsData } from "../../analyticsData";

export function getAnalyticsMyMusicAddedProperties() {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstAddToMyMusic", new Date());
    SetAnalyticsProp(data, "user", "LastAddToMyMusic", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfAddedToMyMusic");

    return data;
}

export function getAnalyticsMyMusicRemovedProperties() {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstRemoveFromMyMusic", new Date());
    SetAnalyticsProp(data, "user", "LastRemoveFromMyMusic", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfRemoveFromMyMusic");

    return data;
}
