import { getCurrentInputAudioItemFromState } from "../inputs/service/helpers";
import { dispatch } from "global";
import { AUDIO_INPUT_AUDIOS_CHANGE, AUDIO_INPUT_AUDIO_PLAY_CHANGE, RECENT_TRACKS_ADD_ID, RECENT_TRACKS_UPDATE } from "global/actions";
import { messageBus, store } from "global/config/configureStore";
import { getRecentTracks } from "services/backend";
import { log } from "services/logger";
import { getIsLoggedInFromState } from "services/user";
import { AudioInputType, PlayState } from "models/app/player";

const recentTracksLimit = 200;
const backendSyncIntervalMs = 1_000 * 60 * 60 * 12;

let timeOutId: null | number = null;
let timeOutAudioId: string | null = null;

export async function initRecentTracksService() {
    const loggedIn = await getIsLoggedInFromState();
    if (!loggedIn) return;

    scheduleRecentTracksSync();

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_PLAY_CHANGE, () => {
        tryAddRecentlyPlayedTrack();
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIOS_CHANGE, () => {
        tryAddRecentlyPlayedTrack();
    });
}

async function syncRecentTracks() {
    try {
        console.log("recentTrackIds");
        const recentTrackIds = await (await getRecentTracks({ first: recentTracksLimit })).model?.trackIds;
        if (recentTrackIds) {
            dispatch({
                type: RECENT_TRACKS_UPDATE,
                payload: {
                    lastUpdated: new Date().getTime(),
                    recentTrackIds
                }
            });
        }
    } catch (e) {
        log.error({ code: "web-220923-1509", msg: "getRecentTracks exception" });
    }

    window.setTimeout(() => syncRecentTracks(), backendSyncIntervalMs);
}

function scheduleRecentTracksSync() {
    const lastUpdated = store.getState().queue.recentTracksLastUpdated;
    const timeOut = lastUpdated + backendSyncIntervalMs - new Date().getTime();
    window.setTimeout(() => syncRecentTracks(), timeOut);
}

const tryAddRecentlyPlayedTrack = () => {
    const audio = getCurrentInputAudioItemFromState();

    if (timeOutId != null && timeOutAudioId === audio?.audioId && audio?.playState === PlayState.Playing) {
        return;
    }

    if (timeOutId != null) {
        window.clearTimeout(timeOutId);
        timeOutAudioId = null;
        timeOutId = null;
    }

    if (audio?.input !== AudioInputType.PlayQueue) return;

    timeOutAudioId = audio.audioId;
    timeOutId = window.setTimeout(
        () =>
            dispatch({
                type: RECENT_TRACKS_ADD_ID,
                payload: { id: audio.trackId }
            }),
        10_000
    );
};
