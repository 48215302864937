import { store } from "global";
import type { AudioInputItemLiveRadioModel } from "models/app/player/input";
import type { LiveRadioPlayableModel } from "models/domain";

export function getLiveRadioPlayableFromAudio(audio: AudioInputItemLiveRadioModel): LiveRadioPlayableModel | null {
    const radio = store.getState().liveRadio.currentRadio;
    if (!radio) return null;

    const same = audio.radioId === radio.id;
    if (!same) return null;

    return radio;
}
