import type { AnalyticsData } from "../analyticsData";
import { SetAnalyticsProp, combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { getAnalyticsPlayableIdProperties, convertPlayableProperties, getAnalyticsPlayableProperties, getAndConvertAnalyticsNavigationProperties } from "../properties/event";
import { getAnalyticsMyMusicAddedProperties, getAnalyticsUserProperties } from "../properties/user";
import { getAnalyticsAddToPlaylistProperties } from "../properties/user/addToPlaylistProperties";
import type { PlayableModel, PlaylistPreviewModel, TrackPreviewModel } from "models/domain";

export async function getAddToPlaylistEvent(
    playlistId: string,
    playlist: PlaylistPreviewModel | null,
    playable: PlayableModel | TrackPreviewModel | null,
    trackCount: number,
    duplicateTracksAdded: null | "all" | "unique" | "cancel"
): Promise<AnalyticsData> {
    const playlistData = playlist ? convertPlayableProperties(getAnalyticsPlayableProperties(playlist)) : getAnalyticsPlayableIdProperties(playlistId, "Playlist");

    const map = await combineAnalyticsData(
        playlistData,
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getAndConvertAnalyticsNavigationProperties(),
        getAnalyticsAddToPlaylistProperties(duplicateTracksAdded)
    );
    return map;
}

export async function getAnalyticsPlaylistCreatedEvent(playlistId: string, playlist?: PlaylistPreviewModel): Promise<AnalyticsData> {
    const playlistData = playlist ? convertPlayableProperties(getAnalyticsPlayableProperties(playlist)) : getAnalyticsPlayableIdProperties(playlistId, "Playlist");
    SetAnalyticsProp(playlistData, "event", "NewPlaylist", true);

    const map = await combineAnalyticsData(
        getAnalyticsMyMusicAddedProperties(),
        playlistData,
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getAndConvertAnalyticsNavigationProperties()
    );

    return map;
}

export async function getAnalyticsPlaylistDeletedEvent(playlistId: string): Promise<AnalyticsData> {
    const map = await combineAnalyticsData(
        getAnalyticsMyMusicAddedProperties(),
        getAnalyticsPlayableIdProperties(playlistId, "Playlist"),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getAndConvertAnalyticsNavigationProperties()
    );

    return map;
}
