import { requestHelper } from "../../helpers/requestHelper";
import { getCastMediaSession } from "../../setup";

export async function castQueueJumpToItem(itemId: number): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castQueueJumpToItem", itemId, (request, success, error) => {
        media.queueJumpToItem(request, success, error);
    });
}
