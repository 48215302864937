import type { AnalyticsPlayableProperties } from "./playableProperties";
import { log } from "services/logger/initLoggerService";
import type { PageContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";

export function getAnalyticsPlayablePageProperties(page: PageContextModel | null): AnalyticsPlayableProperties {
    if (!page) {
        log.error({ code: "web-220901-1451", msg: "no page in analytics" });
        return {};
    }

    const resource = page?.resource;
    if (!resource) {
        const properties: AnalyticsPlayableProperties = {
            contentId: ""
        };
        return properties;
    }

    switch (resource?.contentType) {
        case ContentType.Album: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: resource.title,
                contentType: resource.contentType,
                graphqlType: resource.graphType,
                contentArtist: resource.artist?.name
            };
            return properties;
        }
        default: {
            const properties: AnalyticsPlayableProperties = {
                contentId: resource.id,
                contentName: ("title" in resource ? resource.title : "name" in resource ? resource.name : undefined) ?? undefined,
                contentType: resource.contentType,
                graphqlType: resource.graphType
            };
            return properties;
        }
    }
}
