import { RefObject } from "preact";
import { useMemo, useState } from "preact/hooks";

export function useRefUpdate<T>() {
    const [current, setCurrent] = useState<T | null>(null);
    const ref = useMemo(
        () =>
            new CustomRef(
                () => current,
                (value) => {
                    if (value != null) setCurrent(value);
                }
            ),
        [current]
    );
    return ref;
}

export class CustomRef<T> implements RefObject<T> {
    private getValue: () => T | null;
    private setValue: (value: T | null) => void;

    constructor(getValue: () => T | null, setValue: (value: T | null) => void) {
        this.getValue = getValue;
        this.setValue = setValue;
    }

    get current(): T | null {
        return this.getValue();
    }

    set current(value: T | null) {
        this.setValue(value);
    }
}
