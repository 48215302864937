import { useEffect, useMemo, useState } from "preact/hooks";
import type { DomainQueryResult } from "services/backend/backendService";
import type { ConnectionHandler } from "services/connection/connectionHandler";
import { createConnectionHandler } from "services/connection/connectionHandler";
import type { ConnectionModel } from "models/domain";
import { ConnectionState } from "models/domain";

interface Props<T> {
    connection: ConnectionModel<T> | null;
    fetchFn?: ConnectionFetchFn<T>;
    max?: number;
    eagerLoadingCount: number;
}

export type ConnectionFetchFn<T> = (first: number, after: string, index: number) => Promise<DomainQueryResult<ConnectionModel<T>>> | null;

export function useConnectionHandler<T>({ connection: connectionProp, fetchFn, max, eagerLoadingCount }: Props<T>) {
    const [connection1, setConnection1] = useState<ConnectionModel<T> | null>(null);
    const [connection2, setConnection2] = useState<ConnectionModel<T> | null>(null);
    const [connectionHandler, setConnectionHandler] = useState<ConnectionHandler<T> | null>(null);

    useEffect(() => {
        setConnection1(connectionProp);
        setConnection2(connectionProp);
    }, [connectionProp, fetchFn]);

    useEffect(() => {
        if (!connection1) {
            setConnectionHandler(null);
            return;
        }

        const handler = createConnectionHandler({ connection: connection1, fetchFn, max, eagerLoadingCount });
        handler.onChange = () => setConnection2({ ...handler.connection });
        setConnectionHandler(handler);

        return () => handler.dispose();
    }, [connection1, eagerLoadingCount, fetchFn, max]);

    const more = connectionHandler?.more ?? null;
    const modify = connectionHandler?.modify ?? null;
    const state = connectionHandler?.state ?? ConnectionState.Unknown;

    return useMemo(() => ({ more, modify, state, connection: connection2 }), [more, modify, state, connection2]);
}
