import { getLyricsState } from "./useLyricsState";
import { dispatch } from "global";
import type { DisplayedLyricsAction } from "global/actions";
import { SET_DESKTOP_LYRICS_VISIBLE } from "global/actions";

export async function toggleLyrics(startingPoint: DisplayedLyricsAction["payload"]["startingPoint"]) {
    const { isLyricsEnabled, isLyricsAvailable, isLyricsOpen, currentQueueLyricsId, currentQueueTrackId } = await getLyricsState();
    const open = isLyricsEnabled && isLyricsAvailable && !isLyricsOpen && !!currentQueueLyricsId && !!currentQueueTrackId;

    dispatch({
        type: SET_DESKTOP_LYRICS_VISIBLE,
        payload: !open
            ? { open: false }
            : {
                open,
                startingPoint,
                lyricsId: currentQueueLyricsId,
                trackId: currentQueueTrackId
            }
    });
}
