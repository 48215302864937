import { useState } from "preact/hooks";
import { isEqual } from "services/arrayHelper";

interface CallbackMap<T> {
    key: T;
    callback: () => void;
}

interface State<T> {
    maps: CallbackMap<T>[];
    fn: (value: T) => void;
    values: T[];
}

export function useCallbackMap<T>(values: T[], fn: (value: T) => void) {
    const [state, setState] = useState<State<T>>();

    const sameFn = state?.fn === fn;
    if (sameFn && state.values && isEqual(values, state.values)) return state.maps;

    const maps = values.map((value) => (sameFn && state.maps.find((callback) => callback.key === value)) || { key: value, callback: () => fn(value) });
    setState({ fn, values, maps });

    return maps;
}
