import type { LayoutItemContentModel, LayoutItemContentContentPreviewConnectionModel } from "models/domain/layoutPage";
import { ContentType } from "models/ModelType";

export function getLayoutPageItemConnectionModelItems(content: LayoutItemContentModel | null): LayoutItemContentContentPreviewConnectionModel | null {
    switch (content?.contentType) {
        case ContentType.AlbumsLayoutItemContent:
            return content.albums;
        case ContentType.ArtistsLayoutItemContent:
            return content.artists;
        case ContentType.ChartsLayoutItemContent:
            return content.charts;
        case ContentType.LiveRadioCategoriesLayoutItemContent:
            return content.liveRadioCategories;
        case ContentType.LiveRadiosLayoutItemContent:
            return content.liveRadios;
        case ContentType.MixRadiosLayoutItemContent:
            return content.mixRadios;
        case ContentType.MixedContentLayoutItemContent:
            return content.mixedContents;
        case ContentType.PlaylistCategoriesLayoutItemContent:
            return content.playlistCategories;
        case ContentType.PlaylistsLayoutItemContent:
            return content.playlists;
        case ContentType.RecommendationsLayoutItemContent:
            return content.userRecommendations;
        case ContentType.TracksLayoutItemContent:
            return content.tracks;
        case ContentType.BannerLayoutItemContent:
            return content.banners;
        case undefined:
            return null;
    }
}
