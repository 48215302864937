import { h } from "preact";
import { ContextMenuAlign } from "..";
import { ContextMenuItemType, ContextMenuItemModelBase } from "./ContextMenuItemModel";

export enum ContextMenuType {
    Playable = "playable",
    AddToPlaylist = "addToPlaylist",
    User = "user"
}

export enum ContextMenuSheetWidth {
    Default = "Default",
    Wide = "Wide"
}

export interface ContextMenuSheetModel extends ContextMenuItemModelBase {
    type: ContextMenuItemType.Sheet;
    align: ContextMenuAlign;
    gap: boolean;
    content: h.JSX.Element;
    width: ContextMenuSheetWidth;
}
