import { h } from "preact";
import { Button, ButtonDesign } from "../Button";
import { dispatch } from "global";
import { AUDIO_INPUT_SET_AND_PLAY } from "global/actions";
import { useTranslations } from "global/config";
import type { TestLocator } from "global/constants";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { createAndStartUserPlayTraceModel, TraceName } from "models/app/TraceModel";
import type { PlayQueuePlayablePageModel, PlayQueuePlayablePreviewModel } from "models/domain";
import { ShuffleState } from "models/view/ShuffleState";
import { IconName } from "components/atoms/icon";

interface Props {
    playable: PlayQueuePlayablePreviewModel | PlayQueuePlayablePageModel | null;
    context: PreviewContextModel;
    design?: ButtonDesign;
    testLocator?: TestLocator;
    disabled?: boolean;
}

export const ButtonPlayShuffleBig = ({ playable, context, design = ButtonDesign.SecondaryBig, testLocator, disabled }: Props) => {
    const translations = useTranslations();
    const click = async () => {
        if (!playable) {
            log.error({ code: "web-210517-1417", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }

        const trace = await createAndStartUserPlayTraceModel(TraceName.ClickToSound);
        dispatch({
            type: AUDIO_INPUT_SET_AND_PLAY,
            payload: {
                shuffle: ShuffleState.Light,
                playable,
                playableContext: context,
                context: { action: AudioContextAction.UserPageSetAndPlay, trace }
            }
        });
    };

    return (
        <Button icon={IconName.Shuffle} design={design} onClick={click} testLocator={testLocator} disabled={disabled}>
            {translations.Shuffle}
        </Button>
    );
};
