import { h } from "preact";
import { Modal, modalButtonTryAgain, openModal } from "..";
import { modalButtonDismissCancel } from "../Buttons";
import { useTranslations } from "global/config";

export async function showErrorTryAgainModal() {
    return openModal(<ErrorTryAgainModal />);
}

export function ErrorTryAgainModal() {
    const translations = useTranslations();
    return <Modal title={translations.FeedbackFailedTitle} text={translations.FeedbackFailedSubtitle} buttons={[modalButtonDismissCancel(), modalButtonTryAgain()]} />;
}
