import { h } from "preact";
import { useCallback, useMemo, useState } from "preact/hooks";
import { route } from "preact-router";
import { useSelector } from "react-redux";
import { ExperimentalProductLoader } from "./experimentalProductLoader";
import { dispatch } from "global";
import {
    PLAYLIST_COLLECTION_ADDED,
    PLAYLIST_DELETED,
    PLAYLIST_MODIFIED,
    PLAYLIST_TRACK_ADDED,
    PLAYLIST_TRACKS_REMOVED,
    PLAYLIST_TRACK_REORDERED,
    PLAYLIST_TRACKS_ADDED,
    SET_MY_PLAYLIST_TRACKS_SORTING,
    USER_LOGGED_IN
} from "global/actions";
import { messageBus, useTranslations } from "global/config";
import { useMessageBus } from "global/hooks";
import { getPlaylistPreview, mutateModifyTracksInPlaylist } from "services/backend";
import { useFavoriteStatus } from "services/favorites";
import { getShortDateDDMMYYYY } from "services/formatters";
import { log } from "services/logger";
import { navigation } from "services/navigation";
import { getTracks } from "services/playable";
import { removeTrackFromPlaylist, useCachedUserPlaylistPreview } from "services/playlist";
import { unSelect, useMultiselect } from "services/selection";
import { convertToPlaylistTracksSortOption, getSortOptions } from "services/sort/sortService";
import { TrackModificationType, PlaylistVisibility } from "generated/graphql-types";
import type { DraggablePlayableModel, DragProps, DropProps, RootModel } from "models/app";
import { LoginState, SortOption } from "models/app";
import type { QueueTrackModel } from "models/app/player/input";
import { ContentPlaylistType } from "models/app/resourceContextModel";
import type { PlayableModel, PlaylistPreviewModel, ResourcePreviewModel, TrackPlayablePreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { useAutoPlay, usePageContext, useSectionContext, useTrackPlayables } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";
import { Line } from "components/atoms/line";
import { DropZone } from "components/molecules/dropZone";
import { EmptyState } from "components/molecules/emptyState/EmptyState";
import { FilterUi } from "components/organisms/filter";
import { ResourceSection } from "components/organisms/resourceSection";
import { openToast } from "components/organisms/toast";
import { AddedToPlaylistToast, NotAddedToPlaylistToast, PlaylistNotSavedToast, PlaylistSavedToast } from "components/organisms/toast/toasts";
import { ProductPageTemplate2 } from "components/templates/productPage";
import { FooterText } from "components/templates/productPage/FooterText";

const loader = new ExperimentalProductLoader();

interface Props {
    playlistId: string;
}

export const PlaylistPage = (props: Props) => <PlaylistPageInner key={props.playlistId} {...props} />;

const PlaylistPageInner = ({ playlistId }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [criterion, setFilter] = useState("");

    const isUserOwned = !!useCachedUserPlaylistPreview(playlistId);
    const defaultSortOptions = useMemo(() => getSortOptions(ContentType.Playlist), []);
    const sorting = useSelector((root: RootModel) => root.ui.ownPlaylistTracksSorting);
    const orderBy = convertToPlaylistTracksSortOption(isUserOwned ? sorting : defaultSortOptions.initial);
    const isReorderingAllowed = isUserOwned && sorting === SortOption.UserDefined;

    //const loader = useExperimentalProductLoader();
    const [, loaderCallback] = useState("");
    loader.callback = loaderCallback;
    loader.setProduct(playlistId, orderBy, criterion);
    const modifyQuery = loader.modify;
    const product = loader.model;
    const playables = useTrackPlayables(product?.tracks?.items ?? null, product);

    const sortOptions = useMemo(
        () =>
            !isUserOwned
                ? undefined
                : {
                    options: defaultSortOptions.options,
                    selected: sorting,
                    onChange: (option: SortOption) => {
                        if (sorting === option) return;
                        dispatch({
                            type: SET_MY_PLAYLIST_TRACKS_SORTING,
                            payload: {
                                sorting: option
                            }
                        });
                    }
                },
        [isUserOwned, defaultSortOptions, sorting]
    );

    const lastModified = product?.lastModified ?? null;
    const lastModifiedStr = useMemo(() => (lastModified ? getShortDateDDMMYYYY(lastModified) : ""), [lastModified]);

    const isFavorite = useFavoriteStatus(!isUserOwned ? product : null);
    const contentPlaylistType: ContentPlaylistType | undefined = isUserOwned
        ? ContentPlaylistType.Own
        : isFavorite === true
            ? ContentPlaylistType.Follow
            : isFavorite === false
                ? ContentPlaylistType.Public
                : undefined;

    const contentPlaylistTypeObj = useMemo(() => ({ contentPlaylistType }), [contentPlaylistType]);
    const page = usePageContext({
        type: ContentType.Playlist,
        resource: product,
        root: null,
        done: loader.success && contentPlaylistType != undefined,
        extraInfo: contentPlaylistTypeObj
    });
    const topContext = useSectionContext(ContentType.Playlist, product, page, 0, null);
    const tracksContext = useSectionContext(ContentType.Playlist, product, page, 1, ResourceDisplayType.List);

    const multiselect = useMultiselect(playables, ContentType.Playlist, page);

    useAutoPlay({ page, type: ContentType.Playlist, resource: loader.model });

    messageBus.subscribeEvery(USER_LOGGED_IN, (msg) => {
        if (!msg.payload.isNewUser) return;
        if (loader.model?.id) {
            if (loader.model.visibility === PlaylistVisibility.Private)
                route("/");
            else
                loader.reload();
        }
    });

    useMessageBus(PLAYLIST_DELETED, (msg) => {
        if (msg.payload.playlistId === playlistId) {
            const url = navigation.templates.myMusicPlaylists();
            route(url, true);
        }
    });

    useMessageBus(PLAYLIST_MODIFIED, (msg) => {
        if (msg.payload.playlist && product) {
            modifyQuery({
                ...product,
                title: msg.payload.playlist.title ?? product.title,
                visibility: msg.payload.playlist?.visibility ?? product.visibility
            });
        } else {
            updateMetadataAndTracks(null, false);
        }
    });

    useMessageBus(PLAYLIST_TRACKS_REMOVED, (msg) => {
        if (msg.payload.playlistId === playlistId) {
            if (multiselect) {
                const items = loader.model?.tracks?.items.filter((n) => -1 != msg.payload.positions.indexOf(n.position)) ?? [];
                unSelect(multiselect, items);
            }

            const canModifyInPlace = false;
            /*
            // todo: I'm classifying this as technical debt, and making a ticket about writing a simpler, linear, implementation for handling pagination/filtering/reloads

            const isFullyLoaded = modified.model?.trackCount === playables?.length;
            const isSingleDeletion = (msg.payload.positions.length == 1); // todo: update this with support for multiple items, when we add multiselect back.  it'll work without, but by falling back on the reload
            const isSupportedSorting = true; //(sorting === SortOption.UserDefined);
            const canModifyInPlace = (!!modifyRef.current) && isFullyLoaded && isSupportedSorting && isSingleDeletion;
            console.log(`--- modify in place? ${canModifyInPlace}  modeltotal: ${modified.model?.trackCount}  paginationtotal: ${playables?.length}`);

            if (canModifyInPlace) {
                // to avoid having to reload the tracklist just to update their "position" field,
                // we simply recompute the position of tracks after the deleted tracks.
                // note that this assumes the backend doesnt switch from its current linear numbering system.
                const position = msg.payload.positions[0];
                modifyRef.current?.((items) => {
                  return items.filter((track) => position !== track.position).map(track => (track.position < position ? track : { ...track, position: position - 1 }));
            });
            */
            updateMetadataAndTracks(msg.payload.playlist, !canModifyInPlace);

            setIsSaving(false);
        }
    });

    useMessageBus(PLAYLIST_TRACKS_ADDED, (msg) => {
        if (msg.payload.playlistId === playlistId && !msg.payload.alreadyHandledByPlaylistPage) {
            // todo: could play around with inserting the tracks, and updating the position of all subsequent tracks, instead of reloading
            // this would also be complicated if tracks are are sorted by anything other than userdefined-position.
            updateMetadataAndTracks(msg.payload.playlist, true);
        }
    });

    useMessageBus(PLAYLIST_TRACK_ADDED, (msg) => {
        if (msg.payload.playlistId === playlistId && !msg.payload.alreadyHandledByPlaylistPage) {
            if (msg.payload.track) {
                // todo: could play around with inserting the track, and updating the position of all subsequent tracks, instead of reloading
                // this would also be complicated if tracks are are sorted by anything other than userdefined-position.
            }
            updateMetadataAndTracks(msg.payload.playlist, true);
        }
    });

    useMessageBus(PLAYLIST_COLLECTION_ADDED, (msg) => {
        if (msg.payload.playlistId === playlistId) {
            updateMetadataAndTracks(null, true);
        }
    });

    const updateMetadataAndTracks = async (playlist: PlaylistPreviewModel | null, alsoFetchTracks: boolean) => {
        if (modifyQuery && product) {
            if (!playlist && !alsoFetchTracks) {
                playlist ??= (await getPlaylistPreview({ id: playlistId }))?.model;
            }
            if (playlist) {
                modifyQuery({
                    ...product,
                    duration: playlist.duration,
                    trackCount: playlist.trackCount,
                    cover: playlist.cover
                });
            }
        }

        if (alsoFetchTracks) {
            await loader.reload(false, false);
        }
    };

    const onRemoveTrack = useCallback(
        async (resource: ResourcePreviewModel) => {
            // todo: could instantly remove the visible item

            setIsSaving(true);
            const wasRemoved = await removeTrackFromPlaylist((resource as TrackPlayablePreviewModel).track, product);

            if (!wasRemoved) {
                setIsSaving(false);
                // todo: it failed and if we already removed the visual item we must add it back
            }
        },
        [product]
    );

    const onDrop = useCallback(
        async (model: DraggablePlayableModel, toIndex: number) => {
            if (playables) {
                const isTrackFromThisPlaylist =
                    model.playable.contentType === ContentType.TrackPlayable &&
                    model.context.section.type === ContentType.Playlist &&
                    model.context.section.page?.type == ContentType.Playlist &&
                    model.context.section.resource?.id === playlistId;
                if (isTrackFromThisPlaylist) {
                    const fromIndex = playables.findIndex((n) => n.track.uniqueId === (model.playable as QueueTrackModel).track.uniqueId);
                    if (fromIndex === -1) {
                        log.error({ code: "web-211122-1140", msg: "can't find dragged playlist track" });
                        return;
                    }
                    await moveTrackInPlaylist(fromIndex, toIndex);
                } else if (model.playable.contentType == ContentType.QueueTrack) {
                    await insertQueueTrackInPlaylist(model.playable, toIndex);
                } else {
                    insertPlayableInPlaylist(model.playable, toIndex);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [playables, playlistId]
    );

    const dropProps = useMemo<DragProps[]>(
        () =>
            playables?.map((_, index) => ({
                onDrop: (n: DraggablePlayableModel) => onDrop(n, index),
                dragSourceId: "..."
            })) ?? [],
        [playables, onDrop]
    );

    const getDropProps = useCallback(
        (model: ResourcePreviewModel, index: number): DropProps => {
            return (
                dropProps[index] ?? {
                    dragSourceId: "..."
                }
            );
        },
        [dropProps]
    );

    const moveTrackInPlaylist = async (fromIndex: number, toIndex: number) => {
        if (fromIndex < 0 || toIndex < 0 || !playables || fromIndex > playables?.length || toIndex > playables?.length) {
            log.error({ code: "web-211122-1150", msg: "dragged tracks outside range of playlist" });
            return;
        }

        if (toIndex > fromIndex) {
            toIndex--;
        }
        if (toIndex === fromIndex) {
            return;
        }

        const trackId = playables[fromIndex]?.id;

        loader.modifyTracks((items) => {
            const remappedColl = [...items];
            const removedItems = remappedColl.splice(fromIndex, 1);
            remappedColl.splice(toIndex, 0, ...removedItems);
            // this works because we dont allow users to rearrange tracks in other sorting modes than user-defined
            return remappedColl.map((n, index) => (n.position === index ? n : { ...n, position: index }));
        });

        const result = await mutateModifyTracksInPlaylist({
            id: playlistId, // todo: graphql must fix this
            modifications: [
                {
                    type: TrackModificationType.Move,
                    positionFrom: fromIndex,
                    positionTo: toIndex
                }
            ]
        });

        if (result?.ok) {
            openToast(PlaylistSavedToast());

            dispatch({
                type: PLAYLIST_TRACK_REORDERED,
                payload: {
                    playlistId,
                    playlist: null,
                    fromPosition: fromIndex,
                    toPosition: toIndex,
                    trackId,
                    alreadyHandledByPlaylistPage: true
                }
            });
        } else {
            // we already moved the visual representation of the track, but the backend call failed, so we need to restore it
            updateMetadataAndTracks(result?.playlist ?? null, true);
            openToast(PlaylistNotSavedToast());
        }
    };

    const insertQueueTrackInPlaylist = async (queueTrack: QueueTrackModel, index: number) => {
        const mustReloadTracks = false;

        loader.modifyTracks((items) => {
            const remappedColl = [...items];
            remappedColl.splice(index, 0, queueTrack.track);
            return remappedColl.map((n, index) => (n.position === index ? n : { ...n, position: index }));
        });

        const result = await mutateModifyTracksInPlaylist({
            id: playlistId,
            modifications: [
                {
                    type: TrackModificationType.Insert,
                    positionTo: index,
                    trackId: queueTrack.id
                }
            ]
        });

        if (result?.ok) {
            updateMetadataAndTracks(result.playlist ?? null, mustReloadTracks);
            openToast(PlaylistSavedToast());

            dispatch({
                type: PLAYLIST_TRACK_ADDED,
                payload: {
                    playlistId,
                    playlist: null,
                    position: index,
                    trackId: queueTrack.id,
                    alreadyHandledByPlaylistPage: true,
                    duplicateTracksAdded: null
                }
            });
        } else {
            // we already added the visual representation of the track, but the backend call failed, so we need to remove it
            updateMetadataAndTracks(null, true);
            openToast(PlaylistNotSavedToast());
        }
    };

    const insertPlayableInPlaylist = async (playable: PlayableModel, index: number) => {
        const tracks = await getTracks(playable);

        const result = !tracks
            ? null
            : await mutateModifyTracksInPlaylist({
                id: playlistId,
                modifications: tracks.map((track, newTrackIndex) => ({
                    type: TrackModificationType.Insert,
                    positionTo: index + newTrackIndex,
                    trackId: `${track.id}`
                }))
            });

        if (tracks && result?.ok) {
            updateMetadataAndTracks(result.playlist ?? null, true);
            openToast(AddedToPlaylistToast(tracks.length));

            dispatch({
                type: PLAYLIST_COLLECTION_ADDED,
                payload: {
                    playlistId,
                    playlist: null,
                    playable,
                    trackCount: tracks.length,
                    duplicateTracksAdded: null
                }
            });
        } else {
            openToast(NotAddedToPlaylistToast());
        }
    };

    const translations = useTranslations();
    const loginState = useSelector((root: RootModel) => root.user.state);
    if (loader.model && loader.model.visibility === PlaylistVisibility.Private && (loginState === LoginState.LoggingOut || loginState === LoginState.LoggedOut)) {
        route("/", true);
        return null;
    }

    return (
        <ProductPageTemplate2
            product={product}
            type={ContentType.Playlist}
            section={topContext}
            isUserOwned={isUserOwned}
            sortOptions={sortOptions}
            filter={((loader.model?.trackCount ?? 0) > 0) ? <FilterUi onChange={setFilter} placeholder="" /> : undefined}
        >
            {
                !loader.isLoadingTracks && (playables?.length ?? 0) == 0 && criterion &&
                <EmptyState title={translations.FilterNoTracksFound} subtitle={translations.SearchNoResultHint} button={undefined} />
            }
            <ResourceSection
                multiselect={multiselect}
                resources={playables}
                context={tracksContext}
                paginationCallback={loader.more}
                customIcon={isUserOwned ? IconName.Delete : undefined}
                customIconAction={isUserOwned ? onRemoveTrack : undefined}
                getDropProps={isReorderingAllowed ? getDropProps : undefined}
                totalItems={loader.itemCount}
                locked={isSaving}
            />
            {playables && isReorderingAllowed && (
                <div className={`bottomDropzone${playables.length > 0 ? "" : " noTracks"}`}>
                    <DropZone onDropFn={(item) => onDrop(item, playables.length)} />
                </div>
            )}
            <Line />
            <FooterText>
                {translations.PlaylistLastUpdated} {lastModifiedStr}
            </FooterText>
        </ProductPageTemplate2>
    );
};
