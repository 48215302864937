import { locale } from "global/constants";
import { DefaultLogMessage, log } from "services/logger";
import { AlbumsSorting, PlaylistTracksSorting, TracksSorting } from "generated/graphql-types";
import { SortOption } from "models/app/SortOption";
import type { AlbumPreviewModel } from "models/domain";
import type { AlbumArtistAlbumsPageModel } from "models/domain/AlbumArtistAlbums";
import type { ArtistAlbumsPageModel } from "models/domain/ArtistAlbums";
import { ContentType } from "models/ModelType";

export interface ResourceSortOptions {
    options: SortOption[];
    initial: SortOption;
}

const albumSortOptions = {
    options: [SortOption.ReleaseDate, SortOption.Popularity, SortOption.Name],
    initial: SortOption.ReleaseDate
};
const albumArtistAlbumSortOptions = {
    options: [SortOption.ReleaseDate, SortOption.Popularity, SortOption.Name],
    initial: SortOption.Popularity
};
const albumSortOptionsFallback = [SortOption.Name, SortOption.ReleaseDate, SortOption.Popularity];
const playlistTracksSortOptions = {
    options: [SortOption.UserDefined, SortOption.LatestAdded, SortOption.Name, SortOption.ArtistName],
    initial: SortOption.UserDefined
};
const artistAllTracksSortOptions = {
    options: [SortOption.Popularity, SortOption.Name, SortOption.ReleaseDate],
    initial: SortOption.Popularity
};
const artistAppearsOnSortOptions = {
    options: [SortOption.Popularity, SortOption.Name, SortOption.ReleaseDate],
    initial: SortOption.Popularity
};

export function convertToTracksSortOption(option: SortOption): TracksSorting {
    switch (option) {
        case SortOption.ArtistName:
        case SortOption.LatestAdded:
        case SortOption.Name:
        case SortOption.UserDefined:
            return TracksSorting.Alphabetically;
        case SortOption.Popularity:
            return TracksSorting.Popularity;
        case SortOption.ReleaseDate:
            return TracksSorting.Newest;
    }
}
export function convertToAlbumSortOption(option: SortOption): AlbumsSorting {
    switch (option) {
        case SortOption.ArtistName:
        case SortOption.LatestAdded:
        case SortOption.Name:
        case SortOption.UserDefined:
            return AlbumsSorting.Alphabetically;
        case SortOption.Popularity:
            return AlbumsSorting.Popularity;
        case SortOption.ReleaseDate:
            return AlbumsSorting.Newest;
    }
}

export function convertToPlaylistTracksSortOption(option: SortOption): PlaylistTracksSorting {
    switch (option) {
        case SortOption.Popularity:
        case SortOption.ReleaseDate:
        case SortOption.UserDefined:
            return PlaylistTracksSorting.TrackPosition;
        case SortOption.ArtistName:
            return PlaylistTracksSorting.ArtistTitle;
        case SortOption.Name:
            return PlaylistTracksSorting.TrackTitle;
        case SortOption.LatestAdded:
            return PlaylistTracksSorting.LatestAddedDesc;
    }
}

export function getSortOptions(
    context: ContentType.ArtistAlbums | ContentType.ArtistAppearsOn | ContentType.ArtistSingles | ContentType.AlbumArtistAlbums | ContentType.Playlist | ContentType.ArtistTracks
): ResourceSortOptions {
    switch (context) {
        case ContentType.ArtistAppearsOn:
            return artistAppearsOnSortOptions;
        case ContentType.ArtistAlbums:
        case ContentType.ArtistSingles:
            return albumSortOptions;
        case ContentType.AlbumArtistAlbums:
            return albumArtistAlbumSortOptions;
        case ContentType.Playlist:
            return playlistTracksSortOptions;
        case ContentType.ArtistTracks:
            return artistAllTracksSortOptions;
    }
}

export function sortArtistAlbumsPageModel(model: ArtistAlbumsPageModel, option: SortOption): ArtistAlbumsPageModel {
    if (!model.albums) return model;
    const albumItems = sortArrayByOptions(model.albums.items, mixOptions(option, albumSortOptionsFallback), sortAlbumByOption);
    return {
        ...model,
        albums: { ...model.albums, items: albumItems }
    };
}

export function sortAlbumArtistAlbumsPageModel(model: AlbumArtistAlbumsPageModel, option: SortOption): AlbumArtistAlbumsPageModel {
    if (!model.albums) return model;
    const albumItems = sortArrayByOptions(model.albums.items, mixOptions(option, albumSortOptionsFallback), sortAlbumByOption);
    return {
        ...model,
        albums: { ...model.albums, items: albumItems }
    };
}

function sortAlbumByOption(a: AlbumPreviewModel, b: AlbumPreviewModel, option: SortOption): number {
    switch (option) {
        case SortOption.Name:
            return compareString(a, b, (model) => model.title);
        // case SortOption.Popularity:
        //     return -1 * compareString(a, b, (model) => model.title); // todo not implemented
        case SortOption.ReleaseDate:
            return -1 * compareDate(a, b, (model) => model.releaseDate);
    }

    log.error({ code: "web-210210-2007", msg: DefaultLogMessage.NotImplemented });
    return 0;
}

function mixOptions<T>(option: T, fallback: T[]) {
    if (fallback.indexOf(option) === -1) {
        log.error({ code: "web-210210-2000", msg: DefaultLogMessage.NotImplemented });
    }

    const options = [option, ...fallback];
    return [...new Set(options)];
}

function sortArrayByOptions<T>(array: T[], options: SortOption[], fn: (a: T, b: T, option: SortOption) => number) {
    return [...array].sort((a, b) => {
        for (let i = 0; i < options.length; i++) {
            const s = fn(a, b, options[i]);
            if (s !== 0) return s;
        }
        return 0;
    });
}

function compareString<T>(a: T, b: T, predicate: (model: T) => string | null): number {
    return (predicate(a) ?? "").localeCompare(predicate(b) ?? "", locale);
}

function compareDate<T>(a: T, b: T, predicate: (model: T) => Date | null): number {
    return (predicate(a)?.getTime() ?? 0) - (predicate(b)?.getTime() ?? 0);
}
