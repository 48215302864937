import { useEffect, useState } from "preact/hooks";

export const useTabIsActive = () => {
    const [isTabActive, setIsTabActive] = useState<boolean>(document.visibilityState === "visible");

    const onVisibilityChange = () => {
        setIsTabActive(document.visibilityState === "visible");
    };

    useEffect(() => {
        window.addEventListener("visibilitychange", onVisibilityChange, { passive: true });

        return () => {
            window.removeEventListener("visibilitychange", onVisibilityChange);
        };
    }, []);
    return isTabActive;
};
