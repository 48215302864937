import { environment } from "services/environment";
import { replaceAllBackwardsCompatible } from "services/formatters";
import { encodeUrlSearchString } from "services/search/searchService";

export const navigation = {
    templates: {
        album: (albumId: string) => formatUrlToUTF8(`/album/${albumId}`),
        albumArtistAlbums: (albumId: string) => formatUrlToUTF8(`/album/${albumId}/vis-alle/kunstner-album`),
        //albumTrack: (albumId: string, trackId: string) => formatUrlToUTF8(`/album/${albumId}/sang/${trackId}`),
        artist: (artistId: string) => formatUrlToUTF8(`/kunstner/${artistId}`),
        artistAlbums: (artistId: string) => formatUrlToUTF8(`/kunstner/${artistId}/vis-alle/album`),
        ArtistAlbumsAppearsOn: (artistId: string) => formatUrlToUTF8(`/kunstner/${artistId}/vis-alle/optræder-på`),
        artistPopularTracks: (artistId: string) => formatUrlToUTF8(`/kunstner/${artistId}/vis-alle/populære-sange`),
        artistRadio: (artistRadioId: string) => formatUrlToUTF8(`/kunstner-radio/${artistRadioId}`),
        artistSimilarArtists: (artistId: string) => formatUrlToUTF8(`/kunstner/${artistId}/vis-alle/lignende-kunstnere`),
        artistSingles: (artistId: string) => formatUrlToUTF8(`/kunstner/${artistId}/vis-alle/singler-og-ep`),
        chart: (trackChartId: string) => formatUrlToUTF8(`/hitliste/${trackChartId}`),
        installPWA: () => `#desktop-app`,
        frontPage: () => formatUrlToUTF8(`/`),
        settingsPage: () => formatUrlToUTF8(`/indstillinger`),
        profilesPage: (id?: string) => formatUrlToUTF8(`/profiler${id ? ("/" + id) : ""}`),
        addProfilesPage: () => formatUrlToUTF8(`/profiler/ny`),
        layoutPage: (pageId: string) => {
            if (pageId === environment.apiFrontPageId) return formatUrlToUTF8(`/forside`);
            if (pageId === environment.apiRadioPageId) return formatUrlToUTF8(`/stationer`);
            else return formatUrlToUTF8(`/side/${pageId}`);
        },
        layoutPageItem: (pageId: string, itemId: string) => {
            return navigation.templates.layoutPage(pageId) + formatUrlToUTF8(`/vis-alle/${itemId}`);
        },
        layoutPageItemResource: (pageId: string, itemId: string, resourceId: string) => {
            return navigation.templates.layoutPageItem(pageId, itemId) + formatUrlToUTF8(`/${resourceId}`);
        },
        liveRadio: (liveRadioId: string) => formatUrlToUTF8(`/live-radio/${liveRadioId}`),
        liveRadioCategory: (liveRadioCategoryId: string) => formatUrlToUTF8(`/live-radio-kategori/${liveRadioCategoryId}`),
        mixRadioCategory: (mixRadioCategoryId: string) => formatUrlToUTF8(`/more-music-kategori/${mixRadioCategoryId}`),
        mixRadioCategoryMixRadio: (mixRadioCategoryId: string, mixRadioId: string) => formatUrlToUTF8(`/more-music-kategori/${mixRadioCategoryId}/${mixRadioId}`),
        mixRadios: () => formatUrlToUTF8(`/more-music`),
        mixRadiosMixRadio: (mixRadioId: string) => formatUrlToUTF8(`/more-music/${mixRadioId}`),
        myMusicAlbums: () => formatUrlToUTF8("/min-musik/album"),
        myMusicArtists: () => formatUrlToUTF8("/min-musik/kunstnere"),
        myMusicPlaylist: (playlistId: string) => formatUrlToUTF8(`/min-musik/playliste/${playlistId}`),
        myMusicPlaylists: () => formatUrlToUTF8("/min-musik/playlister"),
        myMusicTracks: () => formatUrlToUTF8("/min-musik/sange"),
        playlist: (playlistId: string) => formatUrlToUTF8(`/playliste/${playlistId}`),
        playlistCategory: (playlistCategoryId: string) => formatUrlToUTF8(`/playliste-kategori/${playlistCategoryId}`),
        radio: () => formatUrlToUTF8("/stationer"),
        search: () => formatUrlToUTF8("/udforsk"),
        searchAlbumResults: (criterion: string) => formatUrlToUTF8(`/søgning/album/vis-alle/${encodeUrlSearchString(criterion)}`),
        searchArtistResults: (criterion: string) => formatUrlToUTF8(`/søgning/kunstnere/vis-alle/${encodeUrlSearchString(criterion)}`),
        searchPlaylistResults: (criterion: string) => formatUrlToUTF8(`/søgning/playlister/vis-alle/${encodeUrlSearchString(criterion)}`),
        searchTrackResults: (criterion: string) => formatUrlToUTF8(`/søgning/sange/vis-alle/${encodeUrlSearchString(criterion)}`),
        track: (trackId: string) => formatUrlToUTF8(`/sang/${trackId}`),
        trackRadio: (trackId: string) => formatUrlToUTF8(`/sang-radio/${trackId}`),
        userHistory: () => formatUrlToUTF8(`/historik`),
        userHistoryRecommendation: (recommendationName: string) => formatUrlToUTF8(`/historik/${recommendationName}`),
        userRecommendations: () => formatUrlToUTF8(`/anbefaling`),
        userRecommendationsRecommendation: (recommendationName: string) => formatUrlToUTF8(`/anbefaling/${recommendationName}`),
        yourMixes: () => formatUrlToUTF8("/forside/vis-alle/mix-til-dig")
    }
};

const æ = "%C3%A6";
const ø = "%C3%B8";
const å = "%C3%A5";

export function formatUrlToUTF8(url: string): string {
    url = replaceAllBackwardsCompatible(url, "æ", æ);
    url = replaceAllBackwardsCompatible(url, "ø", ø);
    url = replaceAllBackwardsCompatible(url, "å", å);

    return url;
}

export function formatUrlFromUTF8(url: string): string {
    url = replaceAllBackwardsCompatible(url, æ, "æ");
    url = replaceAllBackwardsCompatible(url, ø, "ø");
    url = replaceAllBackwardsCompatible(url, å, "å");

    return url;
}
