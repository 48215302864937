import type { RefObject } from "preact";
import { h } from "preact";
import type { TextInputState } from "../TextInput";
import { TextInput } from "../TextInput";
import { IconName } from "components/atoms/icon";

interface Props {
    className?: string;
    autoFocus?: boolean;
    onChange: (value: string) => void;
    onFocus?: () => void;
    onBlur?: (ev: Event) => boolean;
    onKeyDown?: (e: h.JSX.TargetedKeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    state?: TextInputState;
    value: string;
}

export const SearchTextInput = ({ className, autoFocus, value: text, placeholder, state, onChange, onFocus, onBlur, onKeyDown }: Props) => {
    return (
        <TextInput
            autoComplete="off"
            autoFocus={autoFocus}
            className={className}
            left={IconName.Search}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            state={state}
            type="search"
            value={text}
        />
    );
};
