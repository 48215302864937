import { store } from "global";
import { AudioInputType } from "models/app/player";

export const isNextDisabled = () => {
    const { queue, player } = store.getState();
    const isPlayQueueEmpty = queue.playQueue.length === 0;
    const isInputLiveRadio = player.input === AudioInputType.LiveRadio;
    return isInputLiveRadio || isPlayQueueEmpty;
};

export const isPreviousDisabled = () => {
    const { queue, player } = store.getState();
    const isPlayQueueEmpty = queue.playQueue.length === 0;
    const isInputLiveRadio = player.input === AudioInputType.LiveRadio;
    return isInputLiveRadio || isPlayQueueEmpty;
};
