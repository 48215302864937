import { h } from "preact";
import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import "./GridView.scss";
import type { RowSharedProps } from "./GridRow";
import { GridRow } from "./GridRow";
import { getRows } from "./useRows";
import { useScrollYPosition } from "./useScrollYPosition";
import { useSplitArrayIntoChunksWithReuse } from "services/arrayHelper";
import type { DragProps, DropProps } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { NotificationConversion, ResourcePreviewModel } from "models/domain";
import { useAppMediaSize, MediaSize } from "components/shared/hooks";
import type { Pagination } from "components/shared/hooks/usePagination";
import { useResizeRowsColumns } from "components/shared/hooks/useResizeRowsColumns";
import type { PreviewAction } from "components/molecules/preview";

interface Props {
    resources: ResourcePreviewModel[] | null;
    pagination?: Pagination<unknown> | null;
    context: SectionContextModel;
    getDropProps?: (model: ResourcePreviewModel, index: number) => DropProps;
    onAction: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    isHighlight?: (id: string, index: number) => boolean;
    dragProps: DragProps;
}

export const GridView = ({ pagination, resources, isHighlight, context, getDropProps, onAction, dragProps }: Props) => {
    const viewRef = useRef<HTMLDivElement>(null);

    //TODO: Brian and Haci, calculate labelCount before rendering previews, currently rerender 10x in total
    const [labelCount, setLabelCount] = useState(1);
    const mediaSize = useAppMediaSize();
    const margin = mediaSize === MediaSize.Mobile ? 16 : 24;
    const data = useMemo(() => ({ margin, labelCount }), [labelCount, margin]);

    // todo: we might save a render-cycle by having useResizeRowsColumns() access a parent-element instead of needing to wait a cycle for results
    const { columns, rowHeight, rows, isReady } = useResizeRowsColumns(viewRef.current, calculateGrid, data);
    const { firstRowInView, lastRowInView } = getRows(viewRef.current, rowHeight, rows);

    const resourceLenght = resources?.length ?? 0;

    const rowChunks = useSplitArrayIntoChunksWithReuse(resources ?? [], columns);
    const parent = viewRef.current?.parentElement?.parentElement?.parentElement;
    const scrollYPosition = useScrollYPosition(parent, (n) => n - (n % rowHeight));

    const totalCount = pagination?.connection?.totalCount ?? resourceLenght;
    const totalRows = Math.ceil(totalCount / columns);

    const shared = useMemo<RowSharedProps>(
        () => ({ context, labelCount, isHighlight, setLabelCount, getDropProps, columns, onAction, dragProps }),
        [columns, context, dragProps, getDropProps, isHighlight, labelCount, onAction]
    );

    const topSpacerHeight = rowHeight * firstRowInView;
    const bottomSpacerHeight = (totalRows - lastRowInView) * rowHeight;

    const [hasAutoscrolled, setHasAutoscrolled] = useState(false);
    useEffect(() => {
        if (hasAutoscrolled || !isHighlight || !resources || columns == 1 || !parent) return;
        const index = resources.findIndex((resource, i) => isHighlight(resource.id, i));
        if (index != -1) {
            const highlightRow = Math.floor(index / columns);
            const pixelHeight = Math.max(0, (highlightRow - 2) * rowHeight);
            parent.scrollTop = pixelHeight;
        }
        else if (pagination?.more) {
            pagination?.more?.();
        }
    }, [resources, pagination, isHighlight, hasAutoscrolled, setHasAutoscrolled, columns, totalRows, rowHeight, parent])

    useEffect(() => {
        const requiredItemsCount = (lastRowInView + 4) * columns;

        if (requiredItemsCount > resourceLenght) {
            pagination?.more && pagination.more();
        }
    }, [firstRowInView, lastRowInView, columns, resourceLenght, pagination, totalCount, scrollYPosition]);


    return (
        <div className="gridView s2e" style={{ "--grid-col": columns }} ref={viewRef}>
            <div className="top-spacer" key="top-spacer" style={{ height: `${topSpacerHeight}px` }} />
            {isReady && rowChunks.slice(firstRowInView, lastRowInView).map((resources, i) => (
                <GridRow key={`${columns}_${firstRowInView + i}`} shared={shared} resources={resources} rowIndex={firstRowInView + i} />
            ))}
            <div className="bottom-spacer" key="bottom-spacer" style={{ height: `${bottomSpacerHeight}px` }} />
        </div>
    );
};

const calculateGrid = (containerHeight: number, containerWidth: number, data: { margin: number; labelCount?: number }) => {
    const minItemSize = 128 + data.margin;
    const maxItemSize = 256 + data.margin;

    const scaleFrom = 272 + data.margin;
    const scaleTo = 720 + data.margin;

    let scale = (containerWidth - scaleFrom) / scaleTo;
    if (scale < 0) scale = 0;
    if (scale > 1) scale = 1;

    const itemSize = minItemSize + scale * (maxItemSize - minItemSize);

    let columns = Math.floor(containerWidth / itemSize);
    columns = Math.max(1, columns);
    const rowWidth = containerWidth / columns;
    let rowHeight = rowWidth;

    if (data.labelCount != null) {
        if (data.labelCount >= 1) rowHeight += 28;
        if (data.labelCount >= 2) rowHeight += 20;
        if (data.labelCount >= 3) rowHeight += 20;
    }

    const rows = Math.ceil(window.innerHeight / rowHeight);
    return { rows, rowHeight, columns };
};
