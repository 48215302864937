import { IncrementAnalyticsProp, SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { SynchronizationType } from "generated/graphql-types";

export function getAndConvertAnalyticsLyricsProperties(
    lyricsId: string,
    startingPoint: "ContextMenu" | "MaxiPlayer",
    sync: SynchronizationType
) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "LyricsID", lyricsId as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "LyricsType", (sync === SynchronizationType.Lines ? "LyricsTypeSync" : "LyricsTypeStatic"), true);
    SetAnalyticsProp(data, "event", "LyricsStartingPoint", (startingPoint === "ContextMenu" ? "LyricsStartingPointContextMenu" : "LyricsStartingPointMaxiPlayer"), true);
    IncrementAnalyticsProp(data, "user", sync === SynchronizationType.Lines ? "NumberOfLyricsDisplayedSync" : "NumberOfLyricsDisplayedStatic");

    return data;
}
