import { initElementPool, setMuted, setVolume } from "./service/element";
import { updateFlow, updateUrl } from "./service/flow";
import { isOutputBrowserFromState } from "../service";
import { messageBus, store } from "global";
import {
    AUDIO_OUTPUT_AUDIO_FAILED,
    AUDIO_INPUT_AUDIOS_CHANGE,
    AUDIO_INPUT_AUDIO_PLAY_CHANGE,
    PLAYER_SET_VOLUME,
    PLAYER_SET_MUTE,
    AUDIO_INPUT_AUDIO_SRC_CHANGE,
    PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE
} from "global/actions";
import { log } from "services/logger";

export const initBrowserAudio = () => {
    messageBus.subscribeEvery(PLAYER_SET_CONTROLLER_AND_OUTPUT_TYPE, async () => {
        updateFlow();
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIOS_CHANGE, async () => {
        if (!isOutputBrowserFromState()) return;
        updateFlow();
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_PLAY_CHANGE, async () => {
        if (!isOutputBrowserFromState()) return;
        updateFlow();
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_SRC_CHANGE, async (msg) => {
        if (!isOutputBrowserFromState()) return;
        updateUrl(msg.payload.audio);
    });

    messageBus.subscribeEvery(AUDIO_OUTPUT_AUDIO_FAILED, async () => {
        if (!isOutputBrowserFromState()) return;
        updateFlow();
    });

    messageBus.subscribeEvery(PLAYER_SET_VOLUME, async () => {
        if (!isOutputBrowserFromState()) return;
        setVolume(store.getState().player.volume);
    });

    messageBus.subscribeEvery(PLAYER_SET_MUTE, async () => {
        if (!isOutputBrowserFromState()) return;
        setMuted(store.getState().player.muted);
    });

    try {
        initElementPool();
        updateFlow();
    } catch (e) {
        log.error({ code: "web-220221-1334", msg: "error browser audio init", error: e });
    }
};
