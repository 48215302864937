import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castPause(): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castPause", new chrome.cast.media.PauseRequest(), (request, success, error) => {
        media.pause(request, success, error);
    });
}
