import { updateAudios } from "./updateAudios";
import { getAudioInputReportId, getAudioSendPlaybackReport, isInputLiveRadioFromState } from "../helpers";
import { store } from "global";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import type { NormalizedData } from "services/normalizeData";
import { getNewCastItemId } from "services/player/controllers/chromeCast/service/helpers";
import { getPlayerOutputTypeFromState } from "services/player/outputs/service";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemLiveRadioModel, StreamUrl } from "models/app/player/input";
import { StreamUrlType, createStreamUrl } from "models/app/player/input";
import { DomainModelType } from "models/ModelType";

export function updateAudiosFromLiveRadio(queueAddData: NormalizedData<AnalyticsQueueAddedPropertiesGroup> | null, context: AudioContextModel) {
    if (!isInputLiveRadioFromState()) return;

    const audio = createAudioFromLiveRadio();

    if (audio) updateAudios([audio], queueAddData, context);
    else updateAudios([], null, context);
}

function createAudioFromLiveRadio() {
    const { currentRadio, currentRadioContext, currentRadioAnalytics } = store.getState().liveRadio;
    if (currentRadio == null || currentRadioContext == null || currentRadioAnalytics == null) return null;

    const radio = currentRadio;
    const { playableData, queueAddData } = currentRadioAnalytics;

    const output = getPlayerOutputTypeFromState();
    const url: StreamUrl | null = radio.type === DomainModelType.Play && radio.liveRadio.mp3Url ? createStreamUrl(radio.liveRadio.mp3Url, StreamUrlType.Mp3) : null;

    const audioId = radio.id + (url ? "" : "_noUrl"); // todo: implement that radio always have src and change this
    const reportId = getAudioInputReportId(audioId, output);
    const sendReport = getAudioSendPlaybackReport(AudioInputType.LiveRadio, output, false);

    const audio: AudioInputItemLiveRadioModel = {
        analytics2: { playableData, addedDataId: queueAddData.referenceId, loadData: null },
        audioId,
        chromecastId: getNewCastItemId(),
        errorCount: 0,
        index: 0,
        input: AudioInputType.LiveRadio,
        logTitle: radio.liveRadio.title,
        playState: PlayState.Buffering,
        playbackContext: radio.liveRadio.playbackContext,
        radioId: radio.id,
        reportId,
        sendReport,
        url2: url
    };

    return audio;
}
