import { updatePlayQueue } from "./updatePlayQueue";
import { getCurrentLaneIndexFromState, getCurrentLaneQueueId, updateQueueLanes } from "../helpers";
import { messageBus, store } from "global";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { startPlay } from "services/player/inputs/service/actions";
import { PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { getAudioPropertiesFromState } from "components/shared/hooks";

export const playPrevious = async (context: AudioContextModel) => {
    const playState = getAudioPropertiesFromState().playState;
    const play = playState === PlayState.Buffering || playState === PlayState.Playing;
    let { queue } = store.getState();

    let { playQueue } = queue;
    let currentIndex = getCurrentLaneIndexFromState();
    currentIndex = currentIndex > 0 ? currentIndex - 1 : playQueue.length - 1;
    playQueue = updateQueueLanes(playQueue, currentIndex);

    queue = { ...queue, playQueue };

    await updatePlayQueue(queue, context, false, null);
    await messageBus.sync();

    const queueId = getCurrentLaneQueueId();
    if (queueId == null) {
        log.error({ code: "web-210520-1621", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    if (play) startPlay(queueId, context);
};
