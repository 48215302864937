import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castQueueStop(): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castQueueStop", new chrome.cast.media.StopRequest(), (request, success, error) => {
        media.stop(request, success, error);
    });
}
