import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { TrackPreviewModel } from "./TrackModel";
import { createTrackPreviewModel } from "./TrackModel";
import type { TrackPlayablePreviewModel } from "./TrackPlayableModel";
import { createTrackPlayablePreview } from "./TrackPlayableModel";
import { getNextUUID } from "services/utils";
import type { TrackPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type SearchTrackResultPreviewData = TrackPreviewFragment;

export type SearchTrackResultModel = SearchTrackResultPreviewModel;

interface SearchTrackResultPreviewModel extends PlayQueuePlayableModelInterface {
    type: DomainModelType.Preview;
    contentType: ContentType.SearchResultTrack;
    track: TrackPreviewModel;
}

function createSearchTrackResultPreviewModel(data: SearchTrackResultPreviewData): SearchTrackResultPreviewModel {
    return {
        type: DomainModelType.Preview,
        contentType: ContentType.SearchResultTrack,
        graphType: data.__typename,
        id: getNextUUID(),
        cover: data.cover ?? null,
        trackCount: 1,
        track: createTrackPreviewModel(data, 0)
    };
}

export function createTrackPlayablePreviewModelFromSearchTrackResult(data: SearchTrackResultPreviewData): TrackPlayablePreviewModel {
    const searchTrack = createSearchTrackResultPreviewModel(data);
    return createTrackPlayablePreview(searchTrack.track, searchTrack);
}
