import { addCastSession, hasCastSession } from "../setup";
import { OperationSource, tryOperation } from "./operation";
import { stopCastSession } from "./stopCastSession";
import { syncResumeFromRemote } from "./sync";
import { log, LogTag } from "services/logger";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";
import type { AudioContextModel } from "models/app/player/AudioContext";

export async function castSessionResumed(context: AudioContextModel) {
    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1205", msg: `castSessionResumed` });

    if (!isControllerChromecastFromState()) {
        log.info({ tags: [LogTag.Chromecast], code: "web-230109-1207", msg: `cast session resume prevented` });
        stopCastSession(context);
        return;
    }

    if (hasCastSession()) {
        log.error({ tags: [LogTag.Chromecast], code: "web-230201-1600", msg: "there is already a session" });
        return;
    }

    await addCastSession();

    const sync = await tryOperation(() => syncResumeFromRemote(context), OperationSource.Chromecast);
    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1529", msg: `castSessionResumed > sync: ${sync}` });
}
