import { h } from "preact";
import "./LiveRadioTitle.scss";
import { PlayState } from "models/app/player";
import { useAudioProperties } from "components/shared/hooks";
import { Soundbars, SoundbarState, SoundbarStyle } from "components/atoms/soundbars";

export const LiveRadioTitle = () => {
    const { playState } = useAudioProperties();
    const soundbarPlayState = playState === PlayState.Playing ? SoundbarState.Playing : SoundbarState.Paused;
    return (
        <div className="liveRadioTitle ue2">
            <Soundbars state={soundbarPlayState} style={SoundbarStyle.Default} />
            <h1>Live</h1>
        </div>
    );
};
