import { useState, useEffect } from "preact/hooks";
import { CustomResizeObserver } from "./CustomResizeObserver";

export const useResizeRowsColumns = <T>(
    target: HTMLElement | null,
    calculateGrid: (containerHeight: number, containerWidth: number, data: T) => { rows: number; columns: number; rowHeight: number },
    data: T
) => {
    const [columns, setColumns] = useState(1);
    const [rows, setRows] = useState(1);
    const [rowHeight, setRowHeight] = useState(1);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const disconnect = CustomResizeObserver(target, (height, width) => {
            const { rows, columns, rowHeight } = calculateGrid(height, width, data);
            setColumns(columns);
            setRows(rows);
            setRowHeight(rowHeight);
            setIsReady(true);
        });

        return () => {
            disconnect && disconnect();
        };
    }, [data, target, rowHeight, calculateGrid]);

    return { columns, rows, rowHeight, isReady };
};
