import { h, createContext } from "preact";
import { forwardRef } from "preact/compat";
import { useCallback, useLayoutEffect, useMemo, useState } from "preact/hooks";
import { FloatingSheet, BottomSheet } from ".";
import "./AppContextMenu.scss";
import { useContextMenus } from "components/app/hooks";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { distinct } from "services/arrayHelper";
import { OpenMenuResultAction } from "services/contextMenus/open";
import type { ContextMenuSheetModel, ContextMenuLinkModel } from "models/view/contextMenu";
import { KeyboardKeyName, usePageKey } from "components/shared/hooks";
import { useAppMediaSize, MediaSize } from "components/shared/hooks/useAppMediaSize";
import { Feature, isFeatureEnabled } from "global/config";

export enum ContextMenuMode {
    BottomSheet = "bottomSheet",
    Floating = "floating"
}

interface ContextMenuSheetContextValue {
    sheet?: ContextMenuSheetModel;
}

interface ContextMenuLinkContextValue {
    link?: ContextMenuLinkModel;
}

interface ContextMenuManagerContextValue {
    mode?: ContextMenuMode;
    index?: number;
    sheet?: ContextMenuSheetModel;
}

export const ContextMenuSheetContext = createContext<ContextMenuSheetContextValue>({});
export const ContextMenuLinkContext = createContext<ContextMenuLinkContextValue>({});
export const ContextMenuManagerContext = createContext<ContextMenuManagerContextValue>({});

interface Props {
    className?: string;
}

export const AppContextMenu = forwardRef<HTMLDialogElement, Props>(({ className }: Props, ref) => {
    const appMediaSize = useAppMediaSize();
    const { open, visible, isOpen } = useContextMenus();

    const mode: ContextMenuMode = (() => {
        switch (appMediaSize) {
            case MediaSize.Mobile:
                return isFeatureEnabled(Feature.BottomSheetMenus) ? ContextMenuMode.BottomSheet : ContextMenuMode.Floating;
            case MediaSize.Tablet:
            case MediaSize.Desktop:
                return ContextMenuMode.Floating;
        }
    })();

    const sheets = useMemo(() => distinct([...open.reverse(), ...visible]), [open, visible]);
    const close = useCallback((ev?: MouseEvent) => {
        if (ev) {
            ev.stopPropagation();
            ev.preventDefault();
        }
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Cancel } } });
    }, []);

    usePageKey((ev) => {
        if (!isOpen) return;
        if (ev.key === KeyboardKeyName.Escape) {
            ev.stopPropagation();
            ev.preventDefault();
            close();
        }
    });

    return (
        <ContextMenuManagerContext.Provider value={{ mode }}>
            <dialog onClick={close} onContextMenu={close} tabIndex={-1} ref={ref} open={true} className={`appContextMenu RTs ${className ?? ""} --open-${isOpen} --mode-${mode}`}>
                <div className="background" />
                {sheets.map((sheet, index) => (
                    <SheetRender key={sheet.id} sheet={sheet} mode={mode} index={index} />
                ))}
            </dialog>
        </ContextMenuManagerContext.Provider>
    );
});

export interface SheetProps {
    sheet: ContextMenuSheetModel;
    mode: ContextMenuMode;
    index: number;
}

const SheetRender = (props: SheetProps) => {
    const { mode, sheet } = props;

    const [count, setCount] = useState(-1);
    useLayoutEffect(() => setCount((count) => (count < 1 ? count + 1 : count)), [mode]);

    return (
        <ContextMenuSheetContext.Provider key={sheet.id} value={{ sheet }}>
            {mode === ContextMenuMode.Floating && <FloatingSheet key={sheet.id} {...props} />}
            {mode === ContextMenuMode.BottomSheet && <BottomSheet key={sheet.id} {...{ ...props, openSmooth: count < 1 }} />}
        </ContextMenuSheetContext.Provider>
    );
};
