import { store } from "global";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";

export const getCurrentLane = (playQueue: QueueTrackModel[]): QueueTrackModel | null => {
    const currentLane = playQueue.find((item) => item.lane === QueueLane.Current);
    return currentLane ?? null;
};

export const getCurrentLaneFromState = (): QueueTrackModel | null => {
    return getCurrentLane(store.getState().queue.playQueue);
};

export const getCurrentLaneIndex = (playQueue: QueueTrackModel[]) => {
    const index = playQueue.findIndex((item) => item.lane === QueueLane.Current);
    return index;
};

export const getCurrentLaneIndexFromState = () => {
    const { playQueue } = store.getState().queue;
    const index = playQueue.findIndex((item) => item.lane === QueueLane.Current);
    return index;
};

export const getCurrentLaneQueueId = (): string | null => {
    const current = getCurrentLaneFromState();
    return current?.queueId ?? null;
};
