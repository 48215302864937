import type { ComponentChildren } from "preact";
import { h } from "preact";

interface Props {
    top?: {
        title: string;
        button?: ComponentChildren;
    };
    children: ComponentChildren;
    className: string;
}

export const Section = ({ className, top, children }: Props) => {
    return (
        <section className={`section KHo ${className}`}>
            {top && (
                <div className="top">
                    <h1>{top.title}</h1>
                    {top.button && top.button}
                </div>
            )}
            <nav className="links">{children}</nav>
        </section>
    );
};
