import { getCachedUserPlaylistPreview } from "./userPlaylistCache";
import { dispatch, messageBus, store } from "global";
import { PLAYLIST_COLLECTION_ADDED, PLAYLIST_CANCELLED_ADDING_DUPLICATE_ITEMS, PLAYLIST_TRACKS_REMOVED, PLAYLIST_TRACK_ADDED } from "global/actions";
import {
    getPlaylistPreview,
    mutateAddAlbumToPlaylist,
    mutateAddPlaylistToPlaylist,
    mutateAddRecommendationToPlaylist,
    mutateAddTrackChartToPlaylist,
    mutateAddTracksToPlaylist,
    mutateModifyTracksInPlaylist
} from "services/backend";
import { DefaultLogMessage, log } from "services/logger";
import { getTracks } from "services/playable";
import { getUnique } from "services/utils";
import { DuplicatesHandling, TrackModificationType } from "generated/graphql-types";
import type { PlayableModel, PlaylistPreviewModel, TrackPreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { ModalOpenResult } from "models/view/AppModalModel";
import { showAddDuplicatesModal } from "components/organisms/modal/modals/AddDuplicatesModal";
import { openToast } from "components/organisms/toast";
import { AddedToPlaylistToast, NotAddedToPlaylistToast } from "components/organisms/toast/toasts";

export function canBeAddedToPlaylist(playable: PlayableModel | null) {
    if (playable == null) return true;
    return (
        playable.contentType === ContentType.TrackPlayable ||
        playable.contentType === ContentType.LiveRadioTrack ||
        playable.contentType === ContentType.TrackRecommendation ||
        playable.contentType === ContentType.TrackChart ||
        playable.contentType === ContentType.Album ||
        playable.contentType === ContentType.Playlist
    );
}

export async function addQueueToPlaylist(playlistId: string): Promise<boolean> {
    const trackIds = store.getState().queue.playQueue.map((n) => n.track.id);
    return await _addTracksToPlaylist(playlistId, trackIds, DuplicatesHandling.CheckDuplicates);
}

export async function addTracksToPlaylist(playlistId: string, tracks: TrackPreviewModel[]) {
    const trackIds = tracks.map((n) => n.id);
    return await _addTracksToPlaylist(playlistId, trackIds, DuplicatesHandling.CheckDuplicates);
}

async function _addTracksToPlaylist(playlistId: string, tracksIds: string[], duplicatesHandling: DuplicatesHandling): Promise<boolean> {
    let wasAdded = false, duplicates = false, duplicatesCount = 0;
    if (tracksIds.length > 0) {
        ({ wasAdded, duplicates, duplicatesCount } = await mutateAddTracksToPlaylist({
            id: playlistId,
            trackIds: tracksIds,
            duplicatesHandling
        }));

        if (duplicates) {
            const isFullyContained = duplicatesCount >= getUnique(tracksIds, n => n).length;

            const { result, data } = await showAddDuplicatesModal(duplicatesCount, tracksIds.length > 1, isFullyContained);
            if (result == ModalOpenResult.Submit) {
                const handling = data === DuplicatesHandling.SkipDuplicates ? DuplicatesHandling.SkipDuplicates : DuplicatesHandling.AllowDuplicates;
                return await _addTracksToPlaylist(playlistId, tracksIds, handling);
            }
            notifyOperationCancelled(playlistId, null, tracksIds.length, null, tracksIds.map(trackId => ({ trackId })));
            return false;
        }
    }
    notifyCollectionAddedAndToast(playlistId, null, wasAdded, tracksIds.length, null, duplicatesHandling);
    return wasAdded;
}

export async function addPlayableToPlaylist(playlistId: string, playable: PlayableModel, duplicatesHandling = DuplicatesHandling.CheckDuplicates): Promise<boolean> {
    let wasAdded: boolean | null = null;
    let duplicates: boolean | null = null;
    let addedCount: number | null = null;
    let duplicatesCount: number | null = null;

    switch (playable.contentType) {
        case ContentType.QueueTrack:
        case ContentType.LiveRadioTrack:
        case ContentType.TrackPlayable:
            ({ wasAdded, duplicates } = await mutateAddTracksToPlaylist({
                id: playlistId,
                trackIds: [playable.id],
                duplicatesHandling
            }));
            if (duplicates) {
                const { result, data } = await showAddDuplicatesModal(1, false, false);
                if (result == ModalOpenResult.Submit) {
                    const handling = data === DuplicatesHandling.SkipDuplicates ? DuplicatesHandling.SkipDuplicates : DuplicatesHandling.AllowDuplicates;
                    return await addPlayableToPlaylist(playlistId, playable, handling);
                }
                notifyOperationCancelled(playlistId, null, 1, playable, null);
                return false;
            }
            if (wasAdded) {
                dispatch({
                    type: PLAYLIST_TRACK_ADDED,
                    payload: {
                        playlistId,
                        playlist: null,
                        trackId: playable.id,
                        duplicateTracksAdded: duplicatesHandling === DuplicatesHandling.CheckDuplicates ? null : "all"
                    }
                });

                await messageBus.sync();
                const playlist = await getCachedUserPlaylistPreview(playlistId);
                const undoCallback = playlist?.trackCount !== null ? () => undo(playlistId, 1) : undefined;
                openToast(wasAdded ? AddedToPlaylistToast(1, undoCallback) : NotAddedToPlaylistToast());
            }
            return wasAdded;

        case ContentType.TrackRecommendation:
            ({ wasAdded, duplicates, addedCount, duplicatesCount } = await mutateAddRecommendationToPlaylist({
                id: playlistId,
                recommendationID: playable.id,
                duplicatesHandling
            }));
            break;

        case ContentType.TrackChart:
            ({ wasAdded, duplicates, addedCount, duplicatesCount } = await mutateAddTrackChartToPlaylist({
                id: playlistId,
                chartId: playable.id,
                duplicatesHandling
            }));
            break;

        case ContentType.Album:
            ({ wasAdded, duplicates, addedCount, duplicatesCount } = await mutateAddAlbumToPlaylist({
                id: playlistId,
                albumId: playable.id,
                duplicatesHandling
            }));
            break;

        case ContentType.Playlist:
            ({ wasAdded, duplicates, addedCount, duplicatesCount } = await mutateAddPlaylistToPlaylist({
                id: playlistId,
                playlistId: playable.id,
                duplicatesHandling
            }));
            break;

        case ContentType.Artist:
        case ContentType.MixedContent:
            {
                const tracks = await getTracks(playable);

                if (tracks && tracks.length > 0) {
                    ({ wasAdded, duplicates, addedCount, duplicatesCount } =
                        await mutateAddTracksToPlaylist({
                            id: playlistId,
                            trackIds: tracks.map((n) => n.id) ?? [],
                            duplicatesHandling
                        }));
                } else {
                    wasAdded = false;
                    addedCount = 0;
                    duplicatesCount = 0;
                }
            }
            break;

        default:
            log.error({ code: "web-210519-1225", msg: DefaultLogMessage.NotImplemented });
            wasAdded = false;
            duplicates = false;
            duplicatesCount = 0;
            addedCount = 0;
            break;
    }

    if (duplicates) {
        const { result, data } = await showAddDuplicatesModal(duplicatesCount, true, playable.trackCount === duplicatesCount);
        if (result == ModalOpenResult.Submit) {
            const handling = data === DuplicatesHandling.SkipDuplicates ? DuplicatesHandling.SkipDuplicates : DuplicatesHandling.AllowDuplicates;
            return await addPlayableToPlaylist(playlistId, playable, handling);
        }
        notifyOperationCancelled(playlistId, null, addedCount, playable, null);
        return false;
    }

    notifyCollectionAddedAndToast(playlistId, null, wasAdded, addedCount, playable, duplicatesHandling);
    return wasAdded ?? false;
}

async function notifyCollectionAddedAndToast(
    playlistId: string,
    playlist: PlaylistPreviewModel | null,
    wasAdded: boolean,
    addedCount: number,
    playable: PlayableModel | null,
    duplicatesHandling: DuplicatesHandling
) {
    if (wasAdded && addedCount) {
        dispatch({
            type: PLAYLIST_COLLECTION_ADDED,
            payload: {
                playlistId,
                playlist,
                playable,
                trackCount: addedCount,
                duplicateTracksAdded: duplicatesHandling == DuplicatesHandling.SkipDuplicates ? "unique"
                    : duplicatesHandling == DuplicatesHandling.AllowDuplicates ? "all" : null
            }
        });
        await messageBus.sync();
    }

    const undoCallback = wasAdded && addedCount ? () => undo(playlistId, addedCount) : undefined;
    openToast(wasAdded ? AddedToPlaylistToast(addedCount, undoCallback) : NotAddedToPlaylistToast());
}


async function notifyOperationCancelled(
    playlistId: string,
    playlist: PlaylistPreviewModel | null,
    trackCount: number,
    playable: PlayableModel | null,
    tracks: {
        trackId: string;
        track?: TrackPreviewModel;
    }[] | null
) {
    dispatch({
        type: PLAYLIST_CANCELLED_ADDING_DUPLICATE_ITEMS,
        payload: {
            playlistId,
            playlist,
            trackCount,
            playable,
            tracks
        }
    });
}

async function undo(playlistId: string, count: number) {
    // we don't trust any cached trackcount since we dont have a subscription for what other clients might do, so we have to fetch it
    const playlistPreview = await getPlaylistPreview({ id: playlistId });
    const playlistTrackCount = playlistPreview?.model?.trackCount;

    if (playlistTrackCount === null || playlistTrackCount === undefined || playlistTrackCount < count) {
        log.error({ code: "web-220919-1255", msg: "Undo add-to-playlist error", data: { playlistId, trackCount: count } });
        return;
    }

    const positions = [...Array(count).keys()].map((indx) => playlistTrackCount - 1 - indx);
    const res = await mutateModifyTracksInPlaylist({
        id: playlistId,
        modifications: positions.map((positionFrom) => ({
            type: TrackModificationType.Remove,
            positionFrom
        }))
    });

    if (res?.ok) {
        dispatch({
            type: PLAYLIST_TRACKS_REMOVED,
            payload: {
                playlistId,
                playlist: null,
                positions
            }
        });
    }
}
