import { store } from "global";
import { playQueueShuffleCoefficients } from "global/constants";
import { updatePlayQueue } from "services/player/inputs/inputs/playQueue/actions";
import { unshufflePlayQueue, updateQueueLanes } from "services/player/inputs/inputs/playQueue/helpers";
import { lerp } from "services/utils";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { PlayQueueModel } from "models/app/player/input";
import type { QueueTrackModel } from "models/app/player/input/playQueue/QueueTrackModel";
import { QueueLane } from "models/app/player/input/playQueue/QueueTrackModel";
import { ShuffleState } from "models/view";

export const shuffle = async (context: AudioContextModel) => {
    const { queue, player } = store.getState();
    let { playQueue } = queue;

    if (player.shuffle === ShuffleState.Off) {
        playQueue = unshufflePlayQueue(playQueue);
    } else {
        playQueue = shuffleQueueList(playQueue, queue.recentTrackIds);
    }
    const newQueue: PlayQueueModel = { ...queue, playQueue };

    await updatePlayQueue(newQueue, context, false, null);
};

function shuffleQueueList(oldQueue: QueueTrackModel[], myRecentTrackIds: string[]): QueueTrackModel[] {
    oldQueue = oldQueue.map((item, index) => ({ ...item, originalIndex: index }));

    const memoryLane = oldQueue.filter((item) => item.lane == QueueLane.Memory);
    const currentTrack = oldQueue.filter((item) => item.lane == QueueLane.Current);
    const priorityLane = oldQueue.filter((item) => item.lane == QueueLane.Priority);
    const flowLane = oldQueue.filter((item) => item.lane == QueueLane.Flow);

    const shuffledQueue = weightedShuffle([...memoryLane, ...flowLane], myRecentTrackIds);

    return updateQueueLanes([...currentTrack, ...priorityLane, ...shuffledQueue], 0);
}

function weightedShuffle(inputQueue: QueueTrackModel[], myRecentTrackIds: string[]): QueueTrackModel[] {
    if (inputQueue.length <= 1) return inputQueue;

    const recentTracksMap: Map<string, number> = new Map(myRecentTrackIds.map((item, index) => [item, index]));

    const { relativeWeightMin, relativeWeightMax } = playQueueShuffleCoefficients;

    function getWeight(trackId: string) {
        const recentPosition = recentTracksMap.get(trackId);
        const randomWeight = Math.random();

        if (recentPosition == null) return randomWeight;

        const relativeWeight = lerp(relativeWeightMin, relativeWeightMax, recentPosition / myRecentTrackIds.length);
        const absoluteWeight = Math.pow(randomWeight, 1 / relativeWeight); //TODO: new equation
        return absoluteWeight;
    }

    return inputQueue
        .map((queueTrack) => [queueTrack, getWeight(queueTrack.track.id)] as [QueueTrackModel, number])
        .sort((a, b) => b[1] - a[1])
        .map((item) => item[0]);
}
