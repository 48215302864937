import type { LocationContentType, ResourceModel, ResourceModelInterface } from ".";
import { ContentType, DomainModelType } from "models/ModelType";

export type SkeletonModel = SkeletonPreviewModel;

export interface SkeletonPreviewModel extends ResourceModelInterface {
    type: DomainModelType.Preview;
    contentType: ContentType.Skeleton;
    parent: ResourceModel | null;
    parentType: LocationContentType;
    estimatedType: LocationContentType;
}

export function createSkeletonPreviewModels(parentType: LocationContentType, parent: ResourceModel | null, count: number): SkeletonPreviewModel[] | null {
    const estimatedType = getEstimatedContentType(parentType, parent);
    if (estimatedType == null) return null;

    const skeletons: SkeletonPreviewModel[] = [];

    for (let i = 0; i < count; i++) {
        const skeleton = createSkeletonPreviewModel(parentType, parent, estimatedType, i);
        skeletons.push(skeleton);
    }

    return skeletons;
}

function createSkeletonPreviewModel(parentType: LocationContentType, parent: ResourceModel | null, estimatedType: LocationContentType, index: number): SkeletonPreviewModel {
    return {
        id: `skeleton_${index}`,
        cover: null,
        graphType: undefined,
        type: DomainModelType.Preview,
        contentType: ContentType.Skeleton,
        parentType,
        parent,
        estimatedType
    };
}

function getEstimatedContentType(parentType: LocationContentType, parent: ResourceModel | null): LocationContentType | null {
    if (parent?.contentType === ContentType.LayoutItem) {
        return parent.domainItemType;
    }

    switch (parentType) {
        case ContentType.AlbumArtistAlbums:
        case ContentType.AlbumChart:
        case ContentType.AlbumRecommendation:
        case ContentType.ArtistAlbums:
        case ContentType.ArtistAppearsOn:
        case ContentType.ArtistSingles:
        case ContentType.MyMusicAlbums:
        case ContentType.SearchAlbumResults:
            return ContentType.Album;

        case ContentType.SearchArtistResults:
        case ContentType.MyMusicArtists:
        case ContentType.ArtistSimilarArtists:
            return ContentType.Artist;

        case ContentType.LiveRadioCategory:
            return ContentType.LiveRadioPlayable;

        case ContentType.MixRadioCategory:
        case ContentType.MixRadios:
            return ContentType.MixRadio;

        case ContentType.PlaylistCategory:
        case ContentType.PlaylistRecommendation:
        case ContentType.SearchPlaylistResults:
        case ContentType.MyMusicPlaylists:
            return ContentType.Playlist;

        case ContentType.PlaylistCategories:
            return ContentType.PlaylistCategory;

        case ContentType.Queue:
            return ContentType.QueueTrack;

        case ContentType.UserHistory:
        case ContentType.UserRecommendations:
            return ContentType.Recommendation;

        case ContentType.Album:
        case ContentType.ArtistRadio:
        case ContentType.ArtistTracks:
        case ContentType.EndlessPlay:
        case ContentType.MyMusicTracks:
        case ContentType.Playlist:
        case ContentType.SearchTrackResults:
        case ContentType.TrackChart:
        case ContentType.TrackRadio:
        case ContentType.TrackRecommendation:
            return ContentType.TrackPlayable;

        default:
            return null;
    }
    parentType;
}
