import { Fragment, h } from "preact";
import { useSelector } from "react-redux";
import { MobileTopBar } from "..";
import { dispatch } from "global";
import { SET_MAXIPLAYER_OPEN } from "global/actions";
import { getLinkFromResource } from "services/resource";
import type { RootModel } from "models/app";
import { AudioInputType } from "models/app/player";
import { MobileMaxiPlayerOpen } from "models/app/UiModel";
import { CastButton } from "components/molecules/castButton";
import { LiveRadioTitle } from "components/molecules/liveRadioTitle";
import { ExpandDirection, VolumeExpandButton } from "components/molecules/volumeExpandButton";

interface Props {
    showQueue: boolean;
}
export const MaxiPlayerTopBar = ({ showQueue }: Props) => {
    const onClose = () => dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });

    const trackParent = useSelector((root: RootModel) => root.queue.trackParent);
    const currentRadio = useSelector((root: RootModel) => root.liveRadio.currentRadio);
    const isInputLiveRadio = useSelector((root: RootModel) => root.player.input) === AudioInputType.LiveRadio;
    const trackParentTitle = getLinkFromResource(isInputLiveRadio ? currentRadio : trackParent)?.text ?? "";

    return (
        <MobileTopBar
            onClose={onClose}
            middle={showQueue ? trackParentTitle : isInputLiveRadio ? <LiveRadioTitle /> : trackParentTitle}
            right={
                <Fragment>
                    <VolumeExpandButton direction={ExpandDirection.Down} />
                    <CastButton />
                </Fragment>
            }
        />
    );
};
