import type { ComponentChildren } from "preact";
import { h } from "preact";
import "./Control.scss";

export enum ControlStyle {
    Default = "Default",
    ContextMenu = "ContextMenu"
}

interface Props {
    className?: string;
    onClick?: () => void;
    style?: ControlStyle;
    left?: ComponentChildren;
    right?: ComponentChildren;
    title: string;
    text?: string;
}

export const Control = ({ className, onClick, style = ControlStyle.Default, left, right, title, text }: Props) => {
    const click = (ev: h.JSX.TargetedEvent<HTMLLabelElement, MouseEvent>) => {
        ev.stopPropagation();
        onClick && onClick();
    };
    const hasPrimaryAction = onClick != null;

    return (
        <label onClick={click} className={`control PTz --design-${style} --hasPrimaryAction-${hasPrimaryAction} ${className ?? ""}`}>
            {left && <div className="left">{left}</div>}
            <div className="text">
                <h2>{title}</h2>
                {text && <p>{text}</p>}
            </div>
            {right && <div className="right">{right}</div>}
        </label>
    );
};
