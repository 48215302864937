import { h } from "preact";
import { LinkWithSubmenu } from "..";
import { useTranslations } from "global/config";
import { IconName } from "components/atoms/icon";
import { LocalizationSettingControl } from "components/molecules/localizationSettingControl/LocalizationSettingControl";

interface Props {
    showIcon: boolean;
}

export const LanguageLink = ({ showIcon }: Props) => {
    const translations = useTranslations();
    return (
        <LinkWithSubmenu icon={showIcon ? IconName.Language : undefined} submenu={<LocalizationSettingControl />}>
            {translations.SettingsLanguage}
        </LinkWithSubmenu>
    );
};
