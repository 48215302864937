import type { ConnectionModel } from "./ConnectionModel";
import { createPlaylistCategoryPreviewConnectionModel } from "./ConnectionModel";
import type { PlaylistCategoryPreviewModel } from "./PlaylistCategoryModel";
import type { ResourceModelInterface, ResourceModelLinkInterface } from "./ResourceModel";
import { getNextUUID } from "services/utils";
import type { PlaylistCategoryPreviewConnectionFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type PlaylistCategoriesPageData = PlaylistCategoryPreviewConnectionFragment;

export type PlaylistCategoriesModel = PlaylistCategoriesPageModel;
export type PlaylistCategoriesPageModel = { type: DomainModelType.Page } & PlaylistCategoriesPage;

interface PlaylistCategoriesPage extends ResourceModelLinkInterface, ResourceModelInterface {
    readonly contentType: ContentType.PlaylistCategories;
    readonly playlistCategories: ConnectionModel<PlaylistCategoryPreviewModel> | null;
}

export function createPlaylistCategoriesPageModel(data: PlaylistCategoriesPageData): PlaylistCategoriesPageModel {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        type: DomainModelType.Page,
        contentType: ContentType.PlaylistCategories,
        playlistCategories: data ? createPlaylistCategoryPreviewConnectionModel(data) : null
    };
}
