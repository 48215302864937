import type { AlbumPreviewModel } from "./AlbumModel";
import type { ArtistLinkModel } from "./ArtistModel";
import { createArtistLinkModel } from "./ArtistModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import type { ArtistSinglesSectionFragment, ArtistSinglesPageFragment } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type ArtistSinglesSectionData = ArtistSinglesSectionFragment;
type ArtistSinglesPageData = ArtistSinglesPageFragment;

export type ArtistSinglesModel = ArtistSinglesSectionModel;
export type ArtistSinglesSectionModel = ({ type: DomainModelType.Section } & ArtistSinglesSection) | ArtistSinglesPageModel;
export type ArtistSinglesPageModel = { type: DomainModelType.Page } & ArtistSinglesPage;

interface ArtistSinglesSection extends ResourceModelInterface {
    readonly contentType: ContentType.ArtistSingles;
    readonly albums: ConnectionModel<AlbumPreviewModel> | null;
}
interface ArtistSinglesPage extends ArtistSinglesSection {
    readonly artist: ArtistLinkModel;
}

export function createArtistSinglesSectionModel(data: ArtistSinglesSectionData, artistId: string): ArtistSinglesSectionModel {
    return {
        graphType: data.__typename,
        type: DomainModelType.Section,
        contentType: ContentType.ArtistSingles,
        id: artistId,
        cover: null,
        albums: data.singles ? createAlbumPreviewConnectionModel(data.singles) : null
    };
}

export function createArtistSinglesPageModel(data: ArtistSinglesPageData): ArtistSinglesPageModel {
    return {
        ...createArtistSinglesSectionModel(data, data.id),
        type: DomainModelType.Page,
        artist: createArtistLinkModel(data)
    };
}
