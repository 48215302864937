export function sessionStartFailed() {
    // if (isKnownPWAError()) showChromecastNotAvailableOnPWA();
}

// function isKnownPWAError(): boolean {
//     if (!(isAppRunAsPWA() && getBrowserName() === BrowserName.Chrome)) return false;

//     const requestDuration = new Date().getTime() - (lastSessionRequest());
//     const quickFail = requestDuration < 200;

//     return quickFail;
// }
