import { h } from "preact";
import { useState } from "preact/hooks";
import "./sessionFrame.scss";
import { USER_LOGGING_OUT, USER_LOGIN_ERROR } from "global/actions";
import { isFeatureEnabled, Feature } from "global/config";
import { useMessageBus } from "global/hooks";
import { environment } from "services/environment";

// This is a workaround for the Ping OAuth logins.
// Their UI sets cookies that remember a session, and if the UI is reloaded before that session exires,
// the UI will automatically log people in again, i.e. people will be unable to log in as a different user until the session expires.
// Since the feature can't be configured off, they have provided us a URL we can load that clears the session cookies...

export function SessionFrame() {
    const [url, setUrl] = useState<string | null>(null);

    function show() {
        if (isFeatureEnabled(Feature.SessionFrame)) {
            setUrl(environment.sessionHackUrl);
            setTimeout(() => {
                setUrl(null)
            }, 8000);
        }
    }

    useMessageBus(USER_LOGGING_OUT, show);
    useMessageBus(USER_LOGIN_ERROR, show);

    if (!url) return null;

    return (
        <iframe className="sessionframe wtf" src={url} />
    )
}