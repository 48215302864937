import type { ComponentChildren } from "preact";
import { h } from "preact";
import { useCallback, useEffect, useMemo, useRef, useState } from "preact/hooks";
import "./Cover.scss";
import { Button, ButtonDesign } from "../controls/button";
import { useTheme } from "components/app/hooks";
import { CoverTestLocator, assetPaths } from "global/constants";
import type { OpenMenuResult } from "services/contextMenus";
import { log } from "services/logger";
import { getAltText, getCoverSrcFromTemplate, getResourceCoverTemplate, getToolTip } from "services/resource";
import type { DraggableItemModel } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { Theme } from "models/view";
import { useLinkContextFromPreview } from "components/shared/hooks";
import { useOnline } from "components/shared/hooks/useOnline";

export enum CoverSize {
    Size48 = 48,
    Size64 = 64,
    Size80 = 80,
    Size96 = 96,
    Size144 = 144,
    Size208 = 208,
    Size256 = 256,
    Size320 = 320,
    Size512 = 512
}

export enum CoverRounding {
    Default,
    Circle,
    None
}

interface Props {
    children?: ComponentChildren;
    className?: string;
    context: PreviewContextModel;
    disabled?: boolean;
    disabledAction?: boolean;
    draggableItem?: () => DraggableItemModel | null;
    dynamicSize: boolean | undefined;
    navigate: boolean;
    onClick?: () => void;
    onContextMenu?: (ev: MouseEvent) => Promise<OpenMenuResult | undefined>;
    rounding: CoverRounding | undefined;
    size: CoverSize;
    disableLazyLoad: boolean;
    highlight?: boolean;
}

export function getPlaceholderPath(theme: Theme): string {
    switch (theme) {
        case Theme.Light:
            return assetPaths.coverPlaceholderLight;
        case Theme.Dark:
            return assetPaths.coverPlaceholderDark;
    }
}

export const Cover = ({
    children,
    className,
    context,
    disabled,
    disabledAction,
    draggableItem,
    dynamicSize = false,
    navigate,
    onClick,
    onContextMenu,
    rounding = CoverRounding.Default,
    size,
    disableLazyLoad,
    highlight
}: Props) => {
    const link = useLinkContextFromPreview(context);
    const { src, srcSet } = useCoverUrl(context, size);
    const width = !dynamicSize ? size : undefined;

    let roundingStr = "";
    if (rounding === CoverRounding.Circle) roundingStr = "circle";
    else if (rounding === CoverRounding.None) roundingStr = "none";
    else if (rounding === CoverRounding.Default && size <= 48) roundingStr = "small";
    else if (rounding === CoverRounding.Default) roundingStr = "default";

    const hasSrc = src != null;

    const theme = useTheme();
    const fallbackPath = useMemo(() => getPlaceholderPath(theme), [theme]);

    const onlineChage = useCallback((online: boolean) => online && setError(false), []);
    useOnline(onlineChage);

    const [error, setError] = useState(false);
    useEffect(() => setError(false), [src]);

    const ref = useRef<HTMLButtonElement>(null);

    const [highlighted, setHighlighed] = useState(false);
    useEffect(() => {
        if (!highlight || highlighted || !ref.current) return;
        setHighlighed(true);
        ref.current.focus({ preventScroll: true });
    }, [highlight, highlighted, ref]);

    const onError = useCallback(() => setError(true), [setError]);
    const imageOK = useMemo(() => hasSrc && !error, [hasSrc, error]);
    const title = link.resource ? getToolTip(link.resource) : null;
    const alt = link.resource ? getAltText(link.resource) : null;

    const srcSetPath = imageOK && srcSet ? srcSet : undefined;

    const path = useMemo(() => (imageOK && src ? src : fallbackPath), [imageOK, src, fallbackPath]);
    const image = <img src={path} srcSet={srcSetPath} loading={disableLazyLoad ? undefined : "lazy"} onError={onError} title={title ?? ""} alt={alt ?? ""} />;
    return (
        <div
            style={{ width, height: width }}
            data-cy={link.type ? CoverTestLocator(link.type) : undefined}
            className={`cover tOk ${className ?? ""} --size-${size ?? ""} --type-${
                link.type ?? ""
            } --dynamicSize-${dynamicSize} --rounding-${roundingStr} --border-${imageOK} --disabled-${disabled}  --disabledAction-${disabledAction}`}>
            <Button
                ref={ref}
                disabled={disabled}
                design={ButtonDesign.Cover}
                link={disabledAction || !navigate ? undefined : link}
                onClick={disabledAction ? undefined : onClick}
                onContextMenu={disabledAction ? undefined : onContextMenu}
                draggableItem={draggableItem}>
                {image}
            </Button>
            {children}
        </div>
    );
};

function useCoverUrl(preview: PreviewContextModel, size: CoverSize): { src: string | null; srcSet: string | null } {
    return useMemo(() => {
        return getCoverSrcSet(preview, size);
    }, [preview, size]);
}

function getCoverSrcSet(preview: PreviewContextModel, size: CoverSize): { src: string | null; srcSet: string | null } {
    const template = getResourceCoverTemplate(preview);
    if (template == null || template == "") {
        return { src: null, srcSet: null };
    }

    const sizes = getCoverSizes(size);
    let mainSize = sizes[0]?.size ?? null;
    if (mainSize == null) {
        log.error({ code: "web-211021-1248", msg: "main cover is null" });
        mainSize = CoverSize.Size48;
    }

    const { src } = getCoverSrcFromTemplate(template, mainSize, mainSize);
    const sets: string[] = [];

    sizes.forEach((size) => {
        const { success, src: sizeSrc } = getCoverSrcFromTemplate(template, size.size, size.size);
        if (!success) return;

        if (!size.xDesciptor) {
            sets.push(sizeSrc);
        } else {
            sets.push(`${sizeSrc} ${size.xDesciptor}`);
        }
    });

    const srcSet = sets.length > 0 ? sets.join(", ") : null;
    return { src, srcSet };
}

function getCoverSizes(size: CoverSize): { xDesciptor: string | null; size: number }[] {
    const pixels = [
        { xDesciptor: null, size },
        { xDesciptor: "1.5x", size: size * 1.5 },
        { xDesciptor: "2x", size: size * 2 }
    ];
    return pixels;
}
