import { createAnalyticsData, SetAnalyticsProp } from "../../analyticsData";
import type { AnalyticsKeys } from "generated/analyticsKeys";
import { RepeatStatus } from "models/view";

type ActionType =
    | "Shuffle"
    | "Unshuffle"
    | "ActivateRepeatOne"
    | "ActivateRepeatAll"
    | "ActivateRepeatNone"
    | "ClearQueue"
    | "ClearPriorityQueue"
    | "ReorderQueue"
    | "RemoveItem"
    | "SkipForward"
    | "SkipBack"
    | "SkipToStart"
    | "TurnHistoryOn"
    | "TurnHistoryOff"
    | "SaveQueueAsPlaylist"
    | "OpenQueueView"
    | "CloseQueueView"
    | "OpenContextMenu"
    | "FavoriteTrack"
    | "KeepPriorityTracks"
    | "DiscardPriorityTracks"
    | "UnfavoriteTrack";

export type QueueActionType = keyof Pick<AnalyticsKeys, ActionType>;

export function getAnalyticsQueueActionProperties(actionType: QueueActionType, IsViewingQueue: boolean) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "ActionType", actionType, true);
    SetAnalyticsProp(data, "event", "ActionSource", "DesktopPlayer", true);
    SetAnalyticsProp(data, "event", "IsViewingQueue", IsViewingQueue);

    return data;
}

export const getRepeatStatusAnalyticsKey = (repeat: RepeatStatus): QueueActionType => {
    switch (repeat) {
        case RepeatStatus.None:
            return "ActivateRepeatNone";
        case RepeatStatus.All:
            return "ActivateRepeatAll";
        case RepeatStatus.One:
            return "ActivateRepeatOne";
    }
};
