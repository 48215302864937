/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, h } from "preact";
import { useContext } from "preact/hooks";
import { ResourceMenuTop } from "./tops";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import {
    AddToPlaylistLink,
    ShareLink,
    AddToLastQueueLink,
    AddToMyMusicLink,
    GoToAlbumLink,
    GoToArtistLink,
    GoToTrackRadioLink,
    PlaylistRemoveTrackLink
} from "../links";
import { AddToQueueLink } from "../links/AddToQueueLink";
import { CopyIdLink } from "../links/CopyIdLink";
import { ShowLyricsLink } from "../links/ShowLyricsLink";
import { environment } from "services/environment";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { LiveRadioTrackPreviewModel } from "models/domain";
import type { TrackPlayablePreviewModel } from "models/domain/TrackPlayableModel";
import { ContentType } from "models/ModelType";

interface Props {
    track: TrackPlayablePreviewModel | LiveRadioTrackPreviewModel | null;
    context: PreviewContextModel;
}

export const TrackContextMenu = ({ track, context }: Props) => {
    const available = track?.track.availableInSubscription;
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    const pageContext = context.section.page;
    const page = pageContext?.resource;
    const contentEditorMode = environment.contentEditorMode;

    const isLiveRadioTrack =
        track?.contentType === ContentType.LiveRadioTrack || track?.parent.contentType === ContentType.LiveRadio || track?.parent.contentType === ContentType.MixRadio;

    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            {page?.contentType === ContentType.Playlist && page.owner && <PlaylistRemoveTrackLink track={track?.track ?? null} playlist={page} showIcon={showIcon} />}
            <AddToMyMusicLink context={context} playable={track} showIcon={showIcon} />
            {available && (
                <Fragment>
                    <AddToPlaylistLink playable={track} context={context} showIcon={showIcon} />
                    {!isLiveRadioTrack && <AddToQueueLink playable={track} context={context} showIcon={showIcon} />}
                    {!isLiveRadioTrack && <AddToLastQueueLink playable={track} context={context} showIcon={showIcon} />}
                    <ShowLyricsLink playable={track} showIcon={showIcon} />
                    {pageContext?.type !== ContentType.Artist && <GoToArtistLink artist={track.track.artist} showIcon={showIcon} context={context} />}
                    {pageContext?.type !== ContentType.Album && <GoToAlbumLink album={track.track.album} showIcon={showIcon} context={context} />}
                    <GoToTrackRadioLink trackRadio={track.track.trackRadio} showIcon={showIcon} context={context} />
                    <ShareLink context={context} showIcon={showIcon} />
                    {contentEditorMode && <CopyIdLink context={context} />}
                </Fragment>
            )}
        </Sheet>
    );
};
