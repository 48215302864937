import { PlayState } from "models/app/player";

export function isAudioLoaded(playState: PlayState): boolean {
    return playState === PlayState.Playing || playState === PlayState.Paused || playState === PlayState.Buffering;
}

export function isAudioLoadChange(playStateNow: PlayState, playStateBefore: PlayState) {
    const loadedNow = isAudioLoaded(playStateNow);
    const loadedBefore = isAudioLoaded(playStateBefore);
    return loadedNow !== loadedBefore;
}

export function isPlayStatePlayIntent(state: PlayState) {
    return state === PlayState.Buffering || state === PlayState.Playing;
}

