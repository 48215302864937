import { dispatch, store } from "global";
import { PLAYQUEUE_UPDATE_MIXRADIO_SESSION } from "global/actions";
import { api } from "global/constants";
import { pageContent } from "global/constants/pageContent";
import {
    getAlbumPlay,
    getArtistPlay,
    getArtistRadioPage,
    getLayoutItemPlay,
    getMixRadioPlay,
    getMyMusicTracksPlay,
    getMixedContentTrackPlay,
    getPlaylistPlay,
    getRecommendationPlay,
    getTrackChartPlay,
    getTrackRadioPlay
} from "services/backend";
import { combineConnections } from "services/connection/connectionFunctions";
import { getAnalyticsQueueIntentPropertiesGroup } from "services/logger/analytics/properties/groups";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { PlaylistTracksSorting } from "generated/graphql-types";
import type { MixRadioSession } from "models/app/player/input";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type {
    PlayableContentType,
    TrackParentContentType,
    PlayQueuePlayableModel,
    ConnectionModel,
    RadioContentType,
    ResourceModel,
    TrackParentModel,
    TrackPlayablePreviewModel,
    LocationContentType,
    TrackPreviewModel,
    PlayableModel,
    PlayQueuePlayablePlayModel
} from "models/domain";
import { FavoriteTracksSorting, createTrackPlayablePreview, getTracksFromLiveRadioModel } from "models/domain";
import type { LiveRadioParentContentType, LiveRadioParentModel } from "models/domain/LiveRadioPlayableModel";
import type { MixRadioPreviewModel, MixRadioPlayModel } from "models/domain/MixRadioModel";
import { MixRadioLinkParentType, createMixRadioPreviewModelFromPlayModel } from "models/domain/MixRadioModel";
import { isNotificationPlayable } from "models/domain/NotificationModel";
import type { SearchMixedResultPreviewModel } from "models/domain/SearchMixedResultModel";
import { ContentType, DomainModelType } from "models/ModelType";

export function isDraggable(resource: ResourceModel) {
    return isPlayable(resource) && resource.contentType !== ContentType.MixRadio;
}

export function isPlayable(resource: ResourceModel): resource is PlayableModel {
    if (resource.contentType === ContentType.MixedContent) {
        if (resource.mixedContentType === ContentType.UserRecommendations) return false;
        if (resource.mixedContentType === ContentType.UserHistory) return false;
        if (resource.mixedContentType === ContentType.LiveRadioCategory) return false;
        if (resource.mixedContentType === ContentType.YourMixesPage) return false;
    }

    if (resource.contentType === ContentType.Notification) {
        return isNotificationPlayable(resource);
    }

    return isPlayableContentType(resource.contentType);
}

export function isPlayableContentType(type: ContentType): type is PlayableContentType {
    if (isPlayableParentContentType(type)) return true;
    if (type === ContentType.TrackPlayable) return true;
    // if (type === ContentType.LiveRadioPlayable) return true;

    return false;
}

export function isLiveRadioParent(resourceModel: ResourceModel): resourceModel is LiveRadioParentModel {
    return isLiveRadioParentContentType(resourceModel.contentType);
}

export function isLiveRadioParentContentType(type: LocationContentType): type is LiveRadioParentContentType {
    switch (type) {
        case ContentType.LiveRadioCategory:
        case ContentType.LayoutItem:
            return true;
    }
    return false;
}

export function isTrackParent(resourceModel: ResourceModel): resourceModel is TrackParentModel {
    return isPlayableParentContentType(resourceModel.contentType);
}

function isPlayableParentContentType(type: ContentType): type is TrackParentContentType {
    switch (type) {
        case ContentType.Album:
        case ContentType.Artist:
        case ContentType.ArtistTracks:
        case ContentType.ArtistRadio:
        case ContentType.EndlessPlay:
        case ContentType.LayoutItem:
        case ContentType.LiveRadio:
        case ContentType.LiveRadioPlayable:
        case ContentType.LiveRadioTrack:
        case ContentType.MixRadio:
        case ContentType.MyMusicTracks:
        case ContentType.Notification:
        case ContentType.MixedContent:
        case ContentType.Playlist:
        case ContentType.QueueTrack:
        case ContentType.SearchResultTrack:
        case ContentType.TrackChart:
        case ContentType.TrackRadio:
        case ContentType.TrackRecommendation:
            return true;
    }
    return false;
}

export function getTrackPlayablePreviews(tracks: TrackPreviewModel[], parent: TrackParentModel) {
    return tracks.map((track) => createTrackPlayablePreview(track, parent));
}

export function getTrackAndParentFromPlayable(playable: PlayQueuePlayableModel): { track: TrackPreviewModel | null; parent: TrackParentModel } {
    if (playable.contentType === ContentType.TrackPlayable) {
        return { track: playable.track, parent: playable.parent };
    }
    if (playable.contentType === ContentType.QueueTrack) {
        return { track: playable.track, parent: playable };
    }
    return { track: null, parent: playable };
}

export function isRadioContentType(resourceContentType: LocationContentType): resourceContentType is RadioContentType {
    if (resourceContentType === ContentType.ArtistRadio) return true;
    if (resourceContentType === ContentType.LiveRadioPlayable) return true;
    if (resourceContentType === ContentType.MixRadio) return true;
    if (resourceContentType === ContentType.TrackRadio) return true;

    return false;
}

export function isSearchMixedResultPreviewModel(value: ResourceModel | null): value is SearchMixedResultPreviewModel {
    switch (value?.contentType) {
        case ContentType.TrackPlayable:
        case ContentType.Album:
        case ContentType.Artist:
        case ContentType.Playlist:
            return true;
    }
    return false;
}

export async function getAvailableTracksAndConnectionForQueue(
    resource: TrackParentModel
): Promise<{ tracks: TrackPreviewModel[] | null; connection: ConnectionModel<TrackPreviewModel> | null }> {
    const result = await getTracksAndConnectionForQueue(resource);

    const resultTracks = result.tracks;
    if (resultTracks == null) {
        log.error({ code: "web-220217-1112", msg: DefaultLogMessage.UnexpectedNull, data: { resource } });
        return { connection: result.connection, tracks: null };
    }
    const tracks = resultTracks.filter((track) => track.availableInSubscription);

    if (tracks.length == 0) {
        alert(store.getState().player.input);

        log.error({ code: "web-220217-1114", msg: DefaultLogMessage.UnexpectedNull });
        return { connection: result.connection, tracks };
    }

    return { connection: result.connection, tracks };
}

async function getTracksAndConnectionForQueue(resource: TrackParentModel): Promise<{ tracks: TrackPreviewModel[] | null; connection: ConnectionModel<TrackPreviewModel> | null }> {
    if (resource.contentType === ContentType.SearchResultTrack || resource.contentType === ContentType.MixedContentTrack)
        return { tracks: resource.track ? [resource.track] : [], connection: null };
    if (resource.contentType === ContentType.LiveRadio) {
        const nowPlaying = resource.tracks?.nowPlaying?.track;
        const recentlyPlayedTracks = resource.tracks?.recentlyPlayedTracks?.items;
        const tracks = [];
        if (nowPlaying) {
            tracks.push(nowPlaying);
        }

        recentlyPlayedTracks?.forEach((item) => {
            tracks.push(item.track);
        });

        return { tracks, connection: null };
    }

    const existing = tryGetTrackConnection(resource) ?? null;

    if (existing) {
        if (!existing.pageInfo.hasNextPage) {
            log.info({ code: "web-210211-1531", msg: "used existing tracks in getTracksForQueue", data: { existing: existing.items.length } });
            return { tracks: existing.items, connection: existing };
        }

        if (existing.pageInfo.endCursor == null) log.error({ code: "web-210810-1642", msg: DefaultLogMessage.UnexpectedNull });
        if (existing.items.length === 0) log.error({ code: "web-210811-1148", msg: DefaultLogMessage.UnexpectedValue });

        const after = existing.pageInfo.endCursor ?? undefined;
        if (after == null) log.error({ code: "web-210811-1052", msg: DefaultLogMessage.UnexpectedNull });

        const fetchedPlay = await getTrackParentPlayModelFromBackend(resource, existing.items.length, after);

        if (!fetchedPlay) {
            log.error({ code: "web-210811-1424", msg: DefaultLogMessage.UnexpectedValue, data: { resource, after } });
            return { tracks: null, connection: null };
        }

        const fetched = tryGetTrackConnection(fetchedPlay);
        if (!fetched) {
            log.error({ code: "web-210811-1427", msg: DefaultLogMessage.UnexpectedValue });
            return { tracks: null, connection: null };
        }

        log.info({
            code: "web-210211-1431",
            msg: "combined existing and fetched tracks in getTracksForQueue",
            data: {
                existing: existing.items.length,
                fetched: fetched.items.length
            }
        });

        const combined = combineConnections(existing, fetched);
        return { tracks: combined.items, connection: combined };
    } else {
        const playModel = await getTrackParentPlayModelFromBackend(resource, 0);
        if (!playModel) return { tracks: null, connection: null };
        const tracks = getTracksFromTrackParent(playModel);
        const connection = tryGetTrackConnection(playModel);

        if (tracks == null) log.error({ code: "web-21081-1547", msg: DefaultLogMessage.UnexpectedNull });
        if (connection == null) {
            log.error({
                code: "web-21081-1551",
                msg: "connection == null in tryGetTrackConnection",
                data: {
                    "resource type": resource.type,
                    "resource id": resource.id,
                    "parent type": playModel.type,
                    "parent id": playModel.id
                }
            });
        }
        log.info({ code: "web-210211-1531", msg: "fetched all tracks in getTracksForQueue", data: { fetched: tracks?.length ?? 0 } });
        return { tracks, connection };
    }
}

export function getTracksFromTrackParent(parent: PlayQueuePlayablePlayModel): TrackPreviewModel[] | null {
    if (parent.contentType === ContentType.SearchResultTrack) return parent.track ? [parent.track] : null;
    if (parent.contentType === ContentType.MixedContentTrack) return parent.track ? [parent.track] : null;
    if (parent.contentType === ContentType.LiveRadio) return null; //todo: is this correct?
    if (parent.contentType === ContentType.Notification) return null;
    if (parent.contentType === ContentType.LiveRadioPlayable) return getTracksFromLiveRadioModel(parent.liveRadio);

    if (parent.contentType === ContentType.LayoutItem) return parent.content.tracks?.items ?? null;

    return parent.tracks?.items ?? null;
}

export async function getTrackParentPlayModelFromBackend(resource: TrackParentModel | PlayableModel, index: number, after?: string): Promise<PlayQueuePlayablePlayModel | null> {
    const type = resource.contentType;
    switch (type) {
        case ContentType.Album:
            return (await getAlbumPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: resource.id })).model;

        case ContentType.Artist:
        case ContentType.ArtistTracks:
            return (await getArtistPlay({ after, first: api.graphqlMaxPlayItemsFetch - index, id: resource.id }, index)).model;

        case ContentType.ArtistRadio:
            return (await getArtistRadioPage({ first: pageContent.artistRadioPageItemsLimit, id: resource.id })).model;

        case ContentType.LayoutItem:
            return (await getLayoutItemPlay({ after, first: api.graphqlMaxPlayItemsFetch, pageId: resource.pageId, itemId: resource.id })).model;

        case ContentType.MixRadio:
            return (await getMixRadioPlay({ after, id: resource.id, first: api.mixRadioCountFetch }, resource.parent)).model;

        case ContentType.MyMusicTracks:
            return (await getMyMusicTracksPlay({ after, first: api.graphqlMaxPlayItemsFetch, orderBy: FavoriteTracksSorting.LatestAdded })).model;

        case ContentType.Playlist:
            return (await getPlaylistPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: resource.id, orderBy: PlaylistTracksSorting.TrackPosition })).model;

        case ContentType.EndlessPlay:
        case ContentType.SearchResultTrack:
        case ContentType.MixedContentTrack:
            return resource;

        case ContentType.TrackChart:
            return (await getTrackChartPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: resource.id })).model;

        case ContentType.TrackRadio:
            return (await getTrackRadioPlay({ after, id: resource.id, first: pageContent.trackRadioPageItemsLimit })).model;

        case ContentType.TrackRecommendation:
            return (await getRecommendationPlay({ id: resource.id, first: api.graphqlMaxPlayItemsFetch }, resource.owner)).model; //TODO: v2 - is first correct?

        case ContentType.LiveRadioPlayable:
            log.error({ code: "web-220301-1607", msg: "live radio tracks not implemented" });
            return null;

        case ContentType.LiveRadio:
        case ContentType.LiveRadioTrack:
        case ContentType.QueueTrack:
            return null;

        case ContentType.Notification: {
            if (resource.playable == null) return null;
            if (resource.playable.contentType === ContentType.TrackPlayable) return resource;
            return getTrackParentPlayModelFromBackend(resource.playable, index);
        }

        case ContentType.MixedContent: {
            const contextType = resource.mixedContentType;
            if (contextType === ContentType.MyMusicTracks)
                return (await getMyMusicTracksPlay({ after, first: api.graphqlMaxPlayItemsFetch, orderBy: FavoriteTracksSorting.LatestAdded })).model;

            const contextId = resource.mixedContentId;
            if (contextId == null) {
                log.error({ code: "web-211026-1627", msg: "contextId is null", data: { contextType } });
                return null;
            }

            switch (contextType) {
                case ContentType.Album:
                    return (await getAlbumPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: contextId })).model;
                case ContentType.Artist:
                case ContentType.ArtistRadio:
                    return (await getArtistPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: contextId }, 0)).model;
                case ContentType.Playlist:
                    return (await getPlaylistPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: contextId, orderBy: PlaylistTracksSorting.TrackPosition })).model;
                case ContentType.MixRadio:
                    return (await getMixRadioPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: contextId }, { type: MixRadioLinkParentType.MixRadios })).model;
                case ContentType.UserRecommendations:
                case ContentType.UserHistory:
                case ContentType.YourMixesPage:
                    return null;
                case ContentType.TrackPlayable:
                    return (await getMixedContentTrackPlay({ id: contextId })).model;
                case ContentType.TrackChart:
                    return (await getTrackChartPlay({ after, first: api.graphqlMaxPlayItemsFetch, id: contextId })).model;
                case ContentType.TrackRadio:
                    return (await getTrackRadioPlay({ after, first: pageContent.trackRadioPageItemsLimit, id: contextId })).model;
            }
            contextType;
        }
    }
    type;
    return null;
}

function tryGetTrackConnection(resource: TrackParentModel): ConnectionModel<TrackPreviewModel> | null {
    if (
        resource.contentType === ContentType.SearchResultTrack ||
        resource.contentType === ContentType.MixedContentTrack ||
        resource.contentType === ContentType.LiveRadioPlayable ||
        resource.contentType === ContentType.LiveRadioTrack
    ) {
        return null;
    }

    switch (resource.type) {
        case DomainModelType.Page:
            if (resource.contentType === ContentType.Artist) {
                if (resource?.tracksSection?.tracks) {
                    return resource?.tracksSection?.tracks;
                } else {
                    log.error({ code: "web-220216-1615", msg: "artist tracks is empty" });
                    return null;
                }
            } else return resource.tracks;
        case DomainModelType.Section:
        case DomainModelType.Play: {
            if (resource.contentType === ContentType.LiveRadio) {
                return null;
            }
            if (resource.contentType === ContentType.LayoutItem) {
                return resource.content.tracks;
            }
            return resource.tracks;
        }
        default: {
            return null;
        }
    }
}

export async function updateSessionAndGetTracks(playable: PlayQueuePlayableModel, context: PreviewContextModel): Promise<TrackPlayablePreviewModel[]> {
    const parent = getTrackAndParentFromPlayable(playable).parent;
    if (parent.contentType === ContentType.MixRadio) {
        const connection = tryGetTrackConnection(parent);
        updateMixRadioSession(parent, connection, context);
    }
    const { tracks, connection } = await getAvailableTracksAndConnectionForQueue(parent);

    if (parent.contentType === ContentType.MixRadio) {
        updateMixRadioSession(parent, connection, context);
    }

    if (!tracks) {
        log.error({ code: "web-210811-1409", msg: DefaultLogMessage.UnexpectedNull });
        return [];
    }

    return getTrackPlayablePreviews(tracks, parent);
}

export const getPlayablesTracks = async (playables: PlayQueuePlayableModel[]): Promise<TrackPlayablePreviewModel[]> => {
    const trackPlayables: TrackPlayablePreviewModel[] = [];

    for (const playable of playables) {
        const parent = getTrackAndParentFromPlayable(playable).parent;

        if (parent.contentType === ContentType.MixRadio) {
            log.error({ code: "web-210811-1410", msg: DefaultLogMessage.UnexpectedNull });
            continue;
        }

        const { tracks } = await getAvailableTracksAndConnectionForQueue(parent);

        if (!tracks) {
            log.error({ code: "web-210811-1411", msg: DefaultLogMessage.UnexpectedNull });
            continue;
        }

        getTrackPlayablePreviews(tracks, parent).forEach((value) => trackPlayables.push(value));
    }

    return trackPlayables;
};

export function updateMixRadioSession(mixRadio: MixRadioPreviewModel | MixRadioPlayModel, connection: ConnectionModel<TrackPreviewModel> | null, context: PreviewContextModel) {
    if (mixRadio.type === DomainModelType.Play) {
        mixRadio = createMixRadioPreviewModelFromPlayModel(mixRadio);
    }

    const queueIntentData2 = getAnalyticsQueueIntentPropertiesGroup(context);

    const mixRadioSession: MixRadioSession = {
        mixRadio,
        connection: connection ? { endCursor: connection.pageInfo.endCursor, hasNextPage: connection.pageInfo.hasNextPage } : null,
        queueIntentData2
    };

    dispatch({
        type: PLAYQUEUE_UPDATE_MIXRADIO_SESSION,
        payload: { mixRadioSession }
    });
}

export function getDurationFromTracks(tracks: TrackPreviewModel[]): number {
    let duration = 0;
    tracks.forEach((track) => (duration += track.duration));
    return duration;
}

export function isTracksEmptyOrNotAvailableInSubscription(tracks: TrackPreviewModel[]) {
    for (const track of tracks) {
        if (track.availableInSubscription) return false;
    }
    return true;
}

// todo: extend this until it handles every conceivable playable-type as input, not just PlayableModel
export async function getTracks(playable: PlayableModel | null): Promise<TrackPreviewModel[] | null> {
    if (!playable) return null;

    const parent = await getTrackParentPlayModelFromBackend(playable, 0);
    if (!parent) return null;

    // todo: ensure parent has loaded all pages? for finite types (i.e. not radios etc)

    const tracks = getTracksFromTrackParent(parent);
    return tracks;
}
