import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import type { QueueActionType } from "../properties/event/queueActionProperties";
import { getAnalyticsQueueActionProperties } from "../properties/event/queueActionProperties";
import { getAnalyticsUserProperties } from "../properties/user";

export async function getAnalyticsQueueActionEvent(actionType: QueueActionType, IsViewingQueue: boolean): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getAnalyticsQueueActionProperties(actionType, IsViewingQueue)
    );
    return data;
}
