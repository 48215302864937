import type { AlbumPreviewModel, ResourceModelInterface } from ".";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel } from "./ConnectionModel";
import { translate } from "global/config";
import type { FavoriteAlbumsQuery } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type MyAlbumsPageData = (FavoriteAlbumsQuery["me"] & Record<string, unknown>)["favorites"] & Record<string, unknown>;

export type MyMusicAlbumsModel = MyMusicAlbumsPageModel;
export type MyMusicAlbumsPageModel = { type: DomainModelType.Page } & MyMusicAlbums;

interface MyMusicAlbums extends ResourceModelInterface {
    readonly title: string;
    readonly albums: ConnectionModel<AlbumPreviewModel> | null;
    readonly contentType: ContentType.MyMusicAlbums;
}

export function createMyMusicAlbumsPageModel(data: MyAlbumsPageData): MyMusicAlbumsPageModel {
    return {
        graphType: data.__typename,
        id: "myMusicAlbums",
        title: translate("MyMusicAlbumsTitle"),
        cover: null,
        type: DomainModelType.Page,
        contentType: ContentType.MyMusicAlbums,
        albums: data.albums ? createAlbumPreviewConnectionModel(data.albums) : null
    };
}
