import type { Message, MessageBus } from "./messageBus";

// Turn a MessageBus into a Redux middleware
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createMessageBusMiddleware<TStore, TAction extends Message>(messageBus: MessageBus, updateReduxFirst = true) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return () => (next: any) => (action: TAction) => {
        if (updateReduxFirst) {
            const result = next(action);
            if (action && typeof action.type === "string") {
                messageBus.dispatch(action);
            }
            return result;
        } else {
            if (action && typeof action.type === "string") {
                messageBus.dispatch(action);
            }
            return next(action);
        }
    };
}