import { initDebug } from "./debug";
import { saveAppSessionHandler } from "./operations/appSessionHandler";
import { restorePlaybackReports } from "./operations/reports";
import { copySessionStorage, deleteSessionStorage, restoreSessionStorage } from "./operations/session";
import { getTokensNow, saveTokensNow } from "./operations/sessionTokens";
import { getNextUUID } from "../../services/utils";
import { log, tryTransferReportsFromSharedStorageAndFinishInCurrentSession } from "services/logger";

const sessionIdKey = "sessionId";
const sessionsKey = "AppSessions";
const sessionActivityTimeout = 2 * 24 * 60 * 60 * 1_000;
const sessionActivityUpdateInterval = 10_000;
const sessionsInvalidateUpdateInterval = 10_000;
const sleepTimeout = 20_000;

let loaded = true;

export interface AppSession {
    id: string;
    storageId: string;
    activity: number;
    loaded: boolean;
    created: number;
    tokenStorageId: string;
}

export interface AppSessionHandler {
    sessions: AppSession[];
}

let _session: AppSession; // are we sure this is being updated everytime the persisted sessions are?

export function getAppSession() {
    return _session;
}

export async function initAppSession() {
    initDebug();

    try {
        _session = await getOrCreateSession();
        // console.log("----session", _session);

        window.setTimeout(() => runSessionActivityUpdate(), sessionActivityUpdateInterval);
        window.setTimeout(() => runSessionsInvalidateUpdate(), sessionsInvalidateUpdateInterval);
        window.setTimeout(() => cleanupOrphanSessionData(), 5000);
    } catch (e) {
        console.error({ code: "web-220217-1313", msg: "app session could not initiate", error: e });
        throw e;
    }
}

export async function unloadAppSesssion() {
    await unloadSession();
}

export function ensureOwnTokenSession() {
    // console.log("---ensure own token session");
    // call on login and logout

    const { session, handler } = getCurrentHandlerAndSession(true);
    if (!session || !handler) return;

    const own = !handler.sessions.find((n) => n.id !== session.id && n.tokenStorageId === session.tokenStorageId);
    if (!own) seperateTokenSession(handler, session);
}

function seperateTokenSession(handler: AppSessionHandler, session: AppSession) {
    session.tokenStorageId = getNextUUID();
    if (_session.id == session.id) {
        _session.tokenStorageId = session.tokenStorageId;
    }
    saveAppSessionHandler(handler);
}

async function getOrCreateSession(): Promise<AppSession> {
    const handler = getOrCreateSessionHandler();
    addTokenStorageIdToExistingAppSessions(handler);

    let session = tryGetSessionFromWindowName(handler);
    const relevant = tryGetRelevantSession(handler);

    if (session != null && relevant == session) {
        // dodgy object comparison that just happens to work because they both come from the same deserialized handler data
        // console.log("--- getOrCreateSession found same session", relevant);
    } else if (relevant != null) {
        if (!isSessionLoaded(relevant)) {
            session = claimSession(handler, relevant);

            // console.log("--- getOrCreateSession taking over session", relevant);
            session = relevant;
        } else {
            // console.log("--- getOrCreateSession copying session", relevant);
            session = await copySession(handler, relevant);
        }
    } else {
        // console.log("--- getOrCreateSession creating new session");
        session = createSession(handler, undefined);
    }

    setCurrentSession(handler, session);

    return session;
}

function addTokenStorageIdToExistingAppSessions(handler: AppSessionHandler) {
    if (!handler.sessions.find((n) => !n.tokenStorageId)) return;
    // console.log("app sessions need token storage ids");

    // if sessions share refreshtokens, then make them share a tokenstorageid
    // move tokens from the old storageId to the new tokenStorageId

    handler.sessions.forEach((session) => {
        if (!session.tokenStorageId) {
            // check if there is a refreshtoken associated with the storageid
            // console.log(`--- processing session with storageId: ${session.storageId}`, session);
            const tokens = getTokensNow(session.storageId);
            if (tokens.refreshToken) {
                const tokenStorageId = findTokenStorageIdWithRefreshToken(handler, tokens.refreshToken);
                if (tokenStorageId) {
                    // another session with a tokenStorageId shares our refreshtoken, so we use its tokenStorageId
                    // console.log("---copying tokenStorageId");
                    session.tokenStorageId = tokenStorageId;
                } else {
                    // there's no tokenStorageId with our refreshtoken, so we create one and copy the tokens to it
                    // console.log("---creating tokenStorageId and migrating tokens");
                    session.tokenStorageId = getNextUUID();
                    saveTokensNow(tokens.refreshToken, tokens.accessToken, false, session.tokenStorageId);
                }
            } else {
                // console.log("---new tokenStorageId");
                session.tokenStorageId = getNextUUID();
            }
        }
    });
}

function findTokenStorageIdWithRefreshToken(handler: AppSessionHandler, refreshToken: string): string | null {
    handler.sessions.forEach((session) => {
        if (session.tokenStorageId) {
            const tokens = getTokensNow(session.tokenStorageId);
            if (tokens.refreshToken == refreshToken) {
                return session.tokenStorageId;
            }
        }
    });
    return null;
}

function setCurrentSession(handler: AppSessionHandler, session: AppSession) {
    window.name = session.id;
    window.sessionStorage.setItem(sessionIdKey, session.id);

    const now = new Date().getTime();
    session.loaded = true;
    session.activity = now;
    session.created = now;

    saveAppSessionHandler(handler);
}

function claimSession(handler: AppSessionHandler, session: AppSession): AppSession | null {
    const now = new Date().getTime();
    session.id = getNextUUID();
    session.loaded = true;
    session.activity = now;
    session.created = now;

    saveAppSessionHandler(handler);
    return session;
}

function tryGetSessionFromWindowName(handler: AppSessionHandler): AppSession | null {
    const sessionId: string | null = window.name != "" ? window.name ?? null : null;
    if (sessionId == null) return null;

    return tryGetSession(handler, sessionId);
}

function tryGetSession(handler: AppSessionHandler, sessionId: string): AppSession | null {
    const session = sessionId != null ? handler.sessions.find((session) => session.id === sessionId) ?? null : null;

    return session;
}

function tryGetRelevantSession(handler: AppSessionHandler): AppSession | null {
    // const dublicateTab = tryGetDublicateTabSession(handler);
    // if (dublicateTab != null) return dublicateTab;

    const latest = tryGetLatestSession(handler);
    if (latest != null) return latest;

    return null;
}

// function tryGetDublicateTabSession(handler: AppSessionHandler): AppSession | null {
//     const sessionId = window.sessionStorage.getItem(sessionIdKey) ?? null;
//     if (sessionId == null) return null;

//     const dublicateTab = tryGetSession(handler, sessionId);

//     console.log("get dublicate tab", dublicateTab);

//     return dublicateTab;
// }

function tryGetLatestSession(handler: AppSessionHandler): AppSession | null {
    const sorted = handler.sessions.sort((a, b) => b.created - a.created);
    const latest = sorted.length > 0 ? sorted[0] : null;
    return latest;
}

function isSessionLoaded(session: AppSession) {
    if (session.loaded) return true;
    return false;
}

async function copySession(handler: AppSessionHandler, session: AppSession): Promise<AppSession> {
    const copy = createSession(handler, session.tokenStorageId);
    await copySessionStorage(session, copy);

    return copy;
}

function createSession(handler: AppSessionHandler, tokenStorageId: string | undefined): AppSession {
    if (!tokenStorageId) tokenStorageId = getNextUUID();

    const id = getNextUUID();
    const created = new Date().getTime();

    const session: AppSession = { id, storageId: id, created, activity: created, loaded: true, tokenStorageId };

    handler.sessions.push(session);
    saveAppSessionHandler(handler);

    return session;
}

async function unloadSession() {
    loaded = false;

    const { handler, session } = getCurrentHandlerAndSession(false);
    if (handler == null || session == null) return;

    if (isLatestSession(handler, session)) {
        // console.log("unload session", session);

        session.loaded = false;
        session.activity = new Date().getTime();

        saveAppSessionHandler(handler);
    } else {
        await deleteSession(handler, session);
    }
}

function getOrCreateSessionHandler(): AppSessionHandler {
    let handler = getHandler();
    if (handler == null) handler = createHandler();

    return handler;
}

function getHandler(): AppSessionHandler | null {
    const sessionsStr = localStorage.getItem(sessionsKey);
    if (sessionsStr == null) {
        console.error({ code: "web-220217-0958", msg: "session storage is null" });
        return null;
    }

    try {
        return JSON.parse(sessionsStr) as AppSessionHandler;
    } catch (e) {
        return null;
    }
}

function createHandler(): AppSessionHandler {
    const handler: AppSessionHandler = { sessions: [] };
    saveAppSessionHandler(handler);
    return handler;
}

async function runSessionActivityUpdate() {
    if (!loaded) return;

    const { session, handler } = await getCurrentHandlerAndSession(true);
    if (handler == null || session == null) return;
    if (!session.loaded) return;

    session.activity = new Date().getTime();
    saveAppSessionHandler(handler);

    tryTransferReportsFromSharedStorageAndFinishInCurrentSession();

    window.setTimeout(() => runSessionActivityUpdate(), sessionActivityUpdateInterval);
}

let lastSleepCheck: number | null = null;
let inSleepTimeout = 0;

function isInSleepTimeout(): boolean {
    const now = new Date().getTime();

    const last = lastSleepCheck;
    lastSleepCheck = now;

    if (last) {
        const timeSinceLast = now - last;
        const computerBeenSleeping = timeSinceLast > sessionsInvalidateUpdateInterval + 10_000;

        if (computerBeenSleeping) inSleepTimeout = new Date().getTime() + sleepTimeout;
    }

    return inSleepTimeout > now;
}

function runSessionsInvalidateUpdate() {
    if (!loaded) return;

    if (isInSleepTimeout()) {
        window.setTimeout(() => runSessionsInvalidateUpdate(), sessionsInvalidateUpdateInterval);
        return;
    }

    const { handler, session } = getCurrentHandlerAndSession(false);
    if (handler == null || session == null) return;

    const now = new Date().getTime();

    handler.sessions.forEach(async (value) => {
        if (value.id === session.id) return;

        if (now > value.activity + sessionActivityTimeout) {
            await deleteSession(handler, value);
        }
    });

    window.setTimeout(() => runSessionsInvalidateUpdate(), sessionsInvalidateUpdateInterval);
}

function getCurrentHandlerAndSession(restore: boolean): { handler: AppSessionHandler | null; session: AppSession | null } {
    const currentSession = getAppSession();

    const handler = getHandler();
    if (handler == null) {
        log.error({ code: "web-211031-1331", msg: "handler is null" });
        return { handler: null, session: null };
    }

    const session = handler.sessions.find((session) => session.id === currentSession.id) ?? null;

    if (!session) {
        log.error({ code: "web-211031-1330", msg: "session is null", data: { "current session": currentSession, handler } });

        if (restore) {
            const session = restoreSession(handler);
            return session;
        }
    }

    return { handler, session };
}

function restoreSession(handler: AppSessionHandler) {
    const session = getAppSession();

    if (handler.sessions.some((n) => n.id === session.id)) {
        log.error({ code: "web-220715-1201", msg: "session already in handler" });
        return { handler: null, session: null };
    }

    handler.sessions.push(session);

    restoreSessionStorage();
    restorePlaybackReports();
    saveAppSessionHandler(handler);

    return { handler, session };
}

function isLatestSession(handler: AppSessionHandler, session: AppSession) {
    const latest = tryGetLatestSession(handler);
    return latest?.id === session.id;
}

async function deleteSession(handler: AppSessionHandler, session: AppSession) {
    // const deletingCurrentSession = session.id === getAppSession().id;

    // console.log("delete session", session, `deletingCurrentSession: ${deletingCurrentSession}`);

    handler.sessions = handler.sessions.filter((value) => value.id !== session.id);
    saveAppSessionHandler(handler);

    await deleteSessionStorage(session);
}

async function cleanupOrphanSessionData() {
    try {
        const handler = getOrCreateSessionHandler();
        const prefixes = ["loadedPlaybackReports_", "finishedPlaybackReports_", "refreshToken_", "accessToken_", "refreshToken_date_"];
        const deleteKeys: string[] = [];

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (!key) continue;

            prefixes.forEach((prefix) => {
                if (key.indexOf(prefix) !== 0) return;

                const storageId = key.substring(prefix.length);
                if (Number.isNaN(parseInt(storageId, 10))) return;
                if (handler.sessions.some((session) => session.storageId == storageId || session.tokenStorageId == storageId)) return;

                deleteKeys.push(key);
            });
        }

        deleteKeys.forEach((key) => {
            localStorage.removeItem(key);
        });

        if ("databases" in window.indexedDB) {
            const databases = await indexedDB.databases();

            databases.forEach((database) => {
                const prefix = "state_";

                const name = database.name;
                if (!name) return;
                if (name.indexOf(prefix) !== 0) return;

                const storageId = name.substring(prefix.length);
                if (handler.sessions.some((session) => session.storageId == storageId)) return;

                indexedDB.deleteDatabase(name);
            });
        }
    } catch (e) {
        //
    }
}
