import { WebAppBrand } from "shared/models";
import "theme.scss";
import { appInit } from "global/config";
import type { BuildEnvironment } from "services/environment";
import { getEnvironmentConfigTelmoreDefault } from "services/environment";
import * as Danish from "generated/localization-da-telmore.json";
import * as English from "generated/localization-en-telmore.json";

const environment: BuildEnvironment = {
    environment: { ...getEnvironmentConfigTelmoreDefault(), webAppBrand: WebAppBrand.Telmore },
    translations: { Danish, English }
};

appInit(environment);
