import { useEffect, useState } from "preact/hooks";
import { useScrollPosition, ScrollAxis } from "components/shared/hooks/useScrollPosition";

export const useUserScroll = (element: HTMLElement | null) => {
    const scrollLeft = useScrollPosition(element, ScrollAxis.x);
    const [inMove, setInMove] = useState(false);
    const [inTouch, setInTouch] = useState(false);

    useEffect(() => {
        setInMove(true);
        const timeout = setTimeout(() => setInMove(false), 150);

        return () => {
            clearTimeout(timeout);
            setInMove(false);
        };
    }, [scrollLeft]);

    useEffect(() => {
        if (!element) return;
        const touchEnd = () => {
            setInTouch(false);
            element.removeEventListener("touchend", touchEnd);
        };
        const touchStart = () => {
            setInTouch(true);
            element.addEventListener("touchend", touchEnd, { passive: true });
        };
        const mouseUp = () => {
            setInTouch(false);
            element.removeEventListener("mouseup", mouseUp);
        };
        const mouseDown = () => {
            setInTouch(true);
            element.addEventListener("mouseup", mouseUp, { passive: true });
        };

        element.addEventListener("touchstart", touchStart, { passive: true });
        element.addEventListener("mousedown", mouseDown);

        return () => {
            touchEnd();
            mouseUp();
            element.removeEventListener("touchstart", touchStart);
            element.removeEventListener("mousedown", mouseDown);
        };
    }, [element]);

    const inUserScroll = inMove || inTouch;
    return { inUserScroll, inTouch };
};
