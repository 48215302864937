import { getTokensNow, saveTokensNow, setTokenRefreshLockTrueForDebug } from "services/appSession/operations/sessionTokens";

export function initUserDebug() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).userDebug = {
        simulateInvalidAccessToken: () => simulateInvalidAccessToken(),
        simulateInvalidRefreshAndAccessToken: () => simulateInvalidRefreshAndAccessToken(),
        simulateRefreshBlockAndInvalidAccessToken: () => simulateRefreshBlockAndInvalidAccessToken(),
        simulateInvalidAccessTokenOnPageload: () => simulateInvalidAccessTokenOnPageload(),
        simulateInvalidRefreshAndAccessTokenOnPageload: () => simulateInvalidRefreshAndAccessTokenOnPageload(),
        simulateTemporaryTokenRefreshError: () => simulateTemporaryTokenRefreshError()
    };
}

const invalidRefreshToken = "invalid refreshToken (web test)";
const invalidAccessToken = "invalid accessToken (web test)";

function simulateInvalidAccessToken() {
    const { refreshToken } = getTokensNow();
    saveTokensNow(refreshToken, invalidAccessToken, false);
}

function simulateInvalidRefreshAndAccessToken() {
    saveTokensNow(invalidRefreshToken, invalidAccessToken, false);
}

function simulateRefreshBlockAndInvalidAccessToken() {
    setTokenRefreshLockTrueForDebug();
    const { refreshToken } = getTokensNow();
    saveTokensNow(refreshToken, invalidAccessToken, false);
}

function simulateInvalidAccessTokenOnPageload() {
    const { refreshToken } = getTokensNow();
    saveTokensNow(refreshToken, invalidAccessToken, false);
    window.location.reload();
}

function simulateInvalidRefreshAndAccessTokenOnPageload() {
    saveTokensNow(invalidRefreshToken, invalidAccessToken, false);
    window.location.reload();
}

let temporary: number | undefined;
export function getSimulateTemporaryTokenRefreshError(): boolean {
    if (!temporary) return false;

    const now = new Date().getTime();
    return now < temporary;
}

function simulateTemporaryTokenRefreshError() {
    const { refreshToken } = getTokensNow();
    saveTokensNow(refreshToken, invalidAccessToken, false);

    const now = new Date().getTime();
    temporary = now + 1000 * 10;
}
