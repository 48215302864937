import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { getAnalyticsLoginProperties, getAnalyticsLogoutProperties, getAndConvertAnalyticsNavigationProperties } from "../properties/event";
import { getAnalyticsUserProperties } from "../properties/user";
import type { UserLoggingOutAction } from "global/actions";

export async function getAnalyticsLogInEvent(): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAndConvertAnalyticsNavigationProperties(),
        getAnalyticsUserProperties(),
        getAnalyticsLoginProperties()
    );
    return data;
}

export async function getAnalyticsLogOutEvent(action: UserLoggingOutAction): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAndConvertAnalyticsNavigationProperties(),
        getAnalyticsUserProperties(),
        getAnalyticsLogoutProperties(action)
    );
    return data;
}
