import { MediaChangeEvent } from "../element";
import { removeBrowserAudio } from "../flow";
import { deleteTrackCache } from "../hls/hlsCachePool";
import { dispatch } from "global";
import { AUDIO_OUTPUT_AUDIO_FAILED, AUDIO_OUTPUT_AUDIO_PLAY_CHANGE } from "global/actions";
import type { LogCode } from "services/logger";
import { log, LogTag } from "services/logger";
import { getAudioByIdFromState } from "services/player/inputs/service/helpers";
import { putAttributePlayUserTrace } from "models/app";
import { PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { BrowserAudioItemModel } from "models/app/player/output";

export async function hlsElementError(browserAudio: BrowserAudioItemModel, message: string) {
    if (browserAudio.playTrace) putAttributePlayUserTrace(browserAudio.playTrace, { type: "error", value: "StreamUrlError" });
    const code = "web-230524-1503";
    const context = { action: AudioContextAction.AudioHlsError, trace: browserAudio.playTrace };
    await disposeAudioAndResetTrackStreamUrl(code, browserAudio, context, message);
}

export async function audioElementError(browserAudio: BrowserAudioItemModel, e: ErrorEvent, message: string) {
    const code = "web-210223-1547";
    log.error({ code, msg: "audio element error", error: e });
    if (browserAudio.playTrace) putAttributePlayUserTrace(browserAudio.playTrace, { type: "error", value: "StreamUrlError" });

    const context = { action: AudioContextAction.AudioHtmlElementError, trace: browserAudio.playTrace };
    await disposeAudioAndResetTrackStreamUrl(code, browserAudio, context, message);
}

export async function trackStreamError(browserAudio: BrowserAudioItemModel, cacheError: string, fetchError: string) {
    if (browserAudio.playTrace) putAttributePlayUserTrace(browserAudio.playTrace, { type: "error", value: "LoadTrackStreamError" });
    const code = "web-230524-1504";
    const context = { action: AudioContextAction.AudioTrackStreamError, trace: browserAudio.playTrace };
    await disposeAudioAndResetTrackStreamUrl(code, browserAudio, context, "LoadTrackStreamError", cacheError, fetchError);
}

export function playNotAllowedError(browserAudio: BrowserAudioItemModel, error: unknown) {
    log.info({ tags: [LogTag.Playback], code: "web-220105-0939", msg: "element.play failed", error });

    const audio = getAudioByIdFromState(browserAudio.audioId);
    const context: AudioContextModel = { action: AudioContextAction.BrowserAudioPlayPrevented, trace: null };

    if (!audio) {
        log.error({ code: "web-221121-1547", msg: "audio not found" });
        return;
    }

    dispatch({
        type: AUDIO_OUTPUT_AUDIO_PLAY_CHANGE,
        payload: { audio, playState: PlayState.Paused, allowPlayChange: true, position: null, context, mediaChangeEvent: MediaChangeEvent.Prevented }
    });
}

async function disposeAudioAndResetTrackStreamUrl(
    code: LogCode,
    browserAudio: BrowserAudioItemModel,
    context: AudioContextModel,
    message: string,
    trackUrlCacheError?: string,
    trackUrlFetchError?: string
) {
    await deleteTrackCache(browserAudio);
    removeBrowserAudio(browserAudio);

    const audio = getAudioByIdFromState(browserAudio.audioId);
    if (!audio) return;

    await dispatch({
        type: AUDIO_OUTPUT_AUDIO_FAILED,
        payload: { code, audio, context, message, trackUrlCacheError, trackUrlFetchError }
    });
}
