import { h } from "preact";
import { ResourceContextMenu } from ".";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { NotificationPreviewModel } from "models/domain/NotificationModel";
import { ContentType } from "models/ModelType";
import { usePreviewContext } from "components/shared/hooks";

interface Props {
    notification: NotificationPreviewModel | null;
    context: PreviewContextModel;
}

export const NotificationPlayableContextMenu = ({ context, notification }: Props) => {
    const playable = notification?.playable ?? null;

    context = usePreviewContext(playable?.contentType ?? ContentType.Notification, playable, context.section, null);
    if (playable == null) return null;

    return <ResourceContextMenu context={context} resourceType={playable.contentType} resource={playable} />;
};
