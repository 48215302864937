import { ComponentChildren, h } from "preact";
import { InputLabel } from "components/atoms/controls/label";
import { TextInputState } from "components/atoms/controls/textInput";
import "./InputSection.scss";

interface Props {
    title?: string;
    children: ComponentChildren;
    className?: string;
    state: TextInputState;
    label: string;
}

export const InputSection = ({ title, state, label, children, className }: Props) => {
    return (
        <section className={`inputSection ${className ?? ""}`}>
            {title && <h1 className="title">{title}</h1>}
            <div className="control">{children}</div>
            <div className="label">
                <InputLabel state={state}>{label}</InputLabel>
            </div>
        </section>
    );
};
