import { combineReducers } from "redux";
import { appReducer } from "./appReducer";
import { audioInputReducer } from "./audioInputReducer";
import { cacheReducer } from "./cacheReducer";
import { castReducer } from "./castReducer";
import { contextMenuReducer } from "./contextMenuReducer";
import { controlPanelReducer } from "./controlPanelReducer";
import { liveRadioReducer } from "./liveRadioReducer";
import { modalReducer } from "./modalReducer";
import { notificationFeedReducer } from "./notificationFeedReducer";
import { notificationsReducer } from "./notificationsReducer";
import { playerReducer } from "./playerReducer";
import { playQueueReducer } from "./playQueueReducer";
import { uiReducer } from "./uiReducer";
import { userReducer } from "./userReducer";
import type { Action } from "global/actions";
import { CLEAR_APP_STATE } from "global/actions";

const _rootReducer = combineReducers({
    app: appReducer,
    audioInput: audioInputReducer,
    cache: cacheReducer,
    cast: castReducer,
    controlPanel: controlPanelReducer,
    liveRadio: liveRadioReducer,
    menu: contextMenuReducer,
    modal: modalReducer,
    notifications: notificationsReducer,
    notificationFeed: notificationFeedReducer,
    player: playerReducer,
    queue: playQueueReducer,
    ui: uiReducer,
    user: userReducer
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rootReducer = (state: any, action: Action) => {
    if (action.type === CLEAR_APP_STATE) state = undefined;
    return _rootReducer(state, action);
};
