import { DefaultLogMessage, log } from "services/logger";
import type { ContextMenuItemModel, AppContextMenuModel, ContextMenuSheetModel, ContextMenuLinkModel, ContextMenuLayerModel } from "models/view/contextMenu";
import { ContextMenuItemType } from "models/view/contextMenu";

export function getAllContextMenuChildren(item: ContextMenuItemModel): ContextMenuItemModel[] {
    const childs: ContextMenuItemModel[] = [];
    const add = (child: ContextMenuItemModel) => {
        childs.push(child);
        child.children.forEach((child) => add(child));
    };
    item.children.forEach((child) => add(child));
    return childs;
}

export function getAllContextMenuParents(item: ContextMenuItemModel): ContextMenuItemModel[] {
    const parents: ContextMenuItemModel[] = [];
    const add = (parent: ContextMenuItemModel) => {
        parents.push(parent);
        if (parent.parent) add(parent.parent);
    };
    if (item.parent) add(item.parent);
    return parents;
}

export function getAllContextMenus(item: ContextMenuItemModel): ContextMenuItemModel[] {
    return [...getAllContextMenuParents(item).reverse(), item, ...getAllContextMenuChildren(item)];
}

export function getContextMenuAppSheets(appMenus: AppContextMenuModel): ContextMenuSheetModel[] {
    const open = appMenus.open;
    const all = open ? ([open, ...getAllContextMenuParents(open).filter((item) => item.type === ContextMenuItemType.Sheet)] as ContextMenuSheetModel[]) : [];
    return all;
}

export function getContextMenuSheetChildrenLayers(sheet: ContextMenuItemModel): ContextMenuLayerModel[] {
    const layers = sheet.tree.layers;
    const index = layers.indexOf(sheet.layer);

    if (index === -1) {
        log.error({ code: "web-220410-1146", msg: DefaultLogMessage.UnexpectedValue });
        return [];
    }

    const children = layers.slice(index);
    return children;
}

export function isContextMenuLayerHover(hoverIds: string[], item: ContextMenuItemModel): boolean {
    const top = ([item, ...getAllContextMenuParents(item)].reverse()[0] ?? null) as ContextMenuItemModel | null;
    return top != null && [top, ...getAllContextMenuChildren(top)].some((child) => isContextMenuHover(hoverIds, child, item.layer));
}

export function isContextMenuChildHover(hoverIds: string[], item: ContextMenuItemModel): boolean {
    return getAllContextMenuChildren(item).some((child) => isContextMenuHover(hoverIds, child, item.layer));
}

export function isContextMenuChildOpen(openId: string | null, item: ContextMenuItemModel): boolean {
    return getAllContextMenuChildren(item).some((child) => child.type === ContextMenuItemType.Sheet && child.id === openId);
}

export function isContextMenuChildOpenWithAnotherLayer(item: ContextMenuItemModel): boolean {
    return getAllContextMenuChildren(item).some((child) => child.type === ContextMenuItemType.Sheet && child.layer != item.layer);
}

export function isContextMenuHover(hovers: string[], item: ContextMenuItemModel, layer: ContextMenuLayerModel): boolean {
    if (item.layer !== layer) return false;
    return hovers.indexOf(item.id) !== -1;
}

export function isContextMenuLinkActive(openId: string | null, hoverIds: string[], item: ContextMenuLinkModel): boolean {
    const hover = isContextMenuHover(hoverIds, item, item.layer);
    if (hover) return true;

    const childHover = isContextMenuChildHover(hoverIds, item);
    if (childHover) return true;

    const layerHover = isContextMenuLayerHover(hoverIds, item);
    if (layerHover) return false;

    const childOpen = isContextMenuChildOpen(openId, item);
    if (childOpen) return true;

    return false;
}

export function isContextMenuSheetOpen(open: ContextMenuSheetModel | null, sheet: ContextMenuSheetModel): boolean {
    return open?.id === sheet.id;
}

export function isContextMenuSheetVisible(visible: ContextMenuSheetModel[], sheet: ContextMenuSheetModel): boolean {
    return visible.indexOf(sheet) !== -1;
}

export function isContextMenuSheetOpenOrVisible(open: ContextMenuSheetModel | null, visible: ContextMenuSheetModel[], sheet: ContextMenuSheetModel): boolean {
    return isContextMenuSheetOpen(open, sheet) || isContextMenuSheetVisible(visible, sheet);
}

export function isContextMenuLayerVisible(open: ContextMenuSheetModel | null, sheet: ContextMenuItemModel): boolean {
    return open?.layer == sheet.layer;
}

export function isContextMenuTreeVisible(open: ContextMenuSheetModel | null, sheet: ContextMenuItemModel): boolean {
    return open?.tree == sheet.tree;
}
