import { addTracksToQueueTracks } from "./tracks";
import { store } from "global";
import { api } from "global/constants";
import { getMixRadioTracksPagination } from "services/backend";
import { DefaultLogMessage, log } from "services/logger";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import { createQueueAddedAnalyticsData } from "services/logger/analytics/properties/groups";
import type { NormalizedData } from "services/normalizeData";
import { createNormalizedData } from "services/normalizeData";
import { getTrackPlayablePreviews } from "services/playable";
import { QueueLane, QueueMode } from "models/app/player/input";
import type { PlayQueueModel, QueueTrackModel } from "models/app/player/input";

export function isMixRadioSessionActive(id: string): boolean {
    if (store.getState().queue.mode !== QueueMode.MixRadio) return false;
    const mixRadioSession = store.getState().queue.mixRadioSession;
    return mixRadioSession?.mixRadio.id === id;
}

export async function getMixRadioQueueTracks(
    queue: PlayQueueModel,
    currentIndex: number
): Promise<{ queueTracks: QueueTrackModel[]; queueAddData: NormalizedData<AnalyticsQueueAddedPropertiesGroup> } | null> {
    let playQueue = queue.playQueue;
    if (currentIndex === -1) {
        log.error({ code: "web-210901-1122", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    const remaining = playQueue.length - currentIndex - 1;
    const timeToFetch = remaining <= api.mixRadioMinFetch;
    if (!timeToFetch) return null;
    const mixRadioSession = queue.mixRadioSession;
    if (!mixRadioSession) {
        log.error({ code: "web-210901-1123", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    if (!mixRadioSession.connection?.hasNextPage) {
        log.error({ code: "web-210901-1124", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    if (!mixRadioSession.connection?.endCursor) {
        log.error({ code: "web-210901-1125", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    const tracks = await getMixRadioTracksPagination({ id: mixRadioSession.mixRadio.id, first: api.mixRadioCountFetch, after: mixRadioSession.connection.endCursor });
    if (!tracks.model) {
        log.error({ code: "web-210901-1126", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    if (!isMixRadioSessionActive(mixRadioSession.mixRadio.id)) return null;

    const queueIntentData = mixRadioSession.queueIntentData2;
    if (!queueIntentData) {
        log.error({ code: "web-210922-1611", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    const addData = createQueueAddedAnalyticsData(queueIntentData, mixRadioSession.mixRadio);
    const queueAddData = createNormalizedData(addData);

    const trackPlayables = getTrackPlayablePreviews(tracks.model.items, mixRadioSession.mixRadio);

    playQueue = addTracksToQueueTracks(playQueue, trackPlayables, QueueLane.Flow, true, null, false, false, queueAddData.referenceId, "web-210803-1329");

    return { queueTracks: playQueue, queueAddData };
}
