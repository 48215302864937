export enum LanguageSetting {
    English = "English",
    Danish = "Danish",
    Auto = "Automatic"
}

export enum SoundQualitySetting {
    High = "High",
    Normal = "Normal",
    Low = "Low"
}

export enum Language {
    English = "English",
    Danish = "Danish"
}
