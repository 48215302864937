import { getLang } from "components/app/hooks";
import { dispatch, messageBus } from "global";
import { SET_BROWSER_LANGUAGE, SET_LOCALIZATION_SETTING } from "global/actions";
import { getISOLanguageCode } from "services/app";
import type { LanguageSetting } from "models/view/Localization";
import { Language } from "models/view/Localization";

export function initLanguageService() {
    const browserLanguageChange = () => {
        const lang = getBrowserLang();
        dispatch({ type: SET_BROWSER_LANGUAGE, payload: { localization: lang } });
    };
    browserLanguageChange();
    window.addEventListener("languagechange", browserLanguageChange);

    messageBus.subscribeEvery(SET_LOCALIZATION_SETTING, (msg) => {
        browserLanguageChange();
        updateHtmlLang(msg.payload.localizationSetting);
    });
}

function getBrowserLang(): Language {
    const browserLanguages = navigator.languages.map((item) => item.toLowerCase());

    if (browserLanguages.find((lang) => lang === "da" || lang === "da-dk")) return Language.Danish;
    return Language.English;
}
const updateHtmlLang = (setting: LanguageSetting) => {
    const local = getLang(setting);
    const isoLangCode = getISOLanguageCode(local);
    document.documentElement.lang = isoLangCode;
};
