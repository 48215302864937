import { useState, useEffect } from "preact/hooks";
import { isAppRunAsPWAChange } from "services/pwa/initPWAService";

export const useIsAppRunAsPWA = (): boolean => {
    
    const [value, setValue] = useState(false);
 
    useEffect(() => {
        return isAppRunAsPWAChange(value => setValue(value));
    }, []);

    return value;
};
