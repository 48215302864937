import { MaintenanceConfig } from "./remoteConfigService";

export function getEmptyMaintenanceConfig(): MaintenanceConfig {
    return {
        WebMaintenanceEnabled: false,
        AppMaintenanceBannerTitle: "",
        AppMaintenanceLinkTitle: "",
        AppMaintenanceLinkUrl: "",
        AppMaintenancePopupText: "",
        AppMaintenancePopupTitle: ""
    };
}
