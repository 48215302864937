import { Fragment, h } from "preact";
import { useContext } from "preact/hooks";
import { ResourceMenuTop } from "./tops";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import { AddToPlaylistLink, AddToMyMusicLink, AddToQueueLink, AddToLastQueueLink, GoToArtistLink, ShareLink } from "../links";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { AlbumPreviewModel } from "models/domain";

interface Props {
    album: AlbumPreviewModel | null;
    context: PreviewContextModel;
}

export const AlbumContextMenu = ({ album, context }: Props) => {
    const available = album ? album.availableInSubscription : true;
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;
    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <AddToMyMusicLink playable={album} showIcon={showIcon} context={context} />
            {available && (
                <Fragment>
                    <AddToPlaylistLink playable={album} context={context} showIcon={showIcon} />
                    <AddToQueueLink playable={album} context={context} showIcon={showIcon} />
                    <AddToLastQueueLink playable={album} context={context} showIcon={showIcon} />
                    <GoToArtistLink artist={album?.artist ?? null} showIcon={showIcon} context={context} />
                    <ShareLink context={context} showIcon={showIcon} />
                </Fragment>
            )}
        </Sheet>
    );
};
