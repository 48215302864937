import { h } from "preact";
import { useSelector } from "react-redux";
import type { RootModel } from "models/app";

export const UsernameSetting = () => {
    const username = useSelector((root: RootModel) => root.user.username);
    return (
        <p className="username">{username}</p>
    );
};
