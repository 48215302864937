import { h } from "preact";
import "./DelegatedLogin.scss";
import { getThemeSettingFromState } from "components/app/hooks";
import { messageBus } from "global";
import { WebAppBrand } from "shared/models";
import { USER_LOGGED_IN, USER_LOGIN_ERROR } from "global/actions";
import { Feature, isFeatureEnabled } from "global/config";
import { getInstallationId } from "services/app";
import { apiDelegatedLoginPath } from "services/backend";
import { addEnvironmentVariablesToUrl, environment } from "services/environment";
import { log, LogTag } from "services/logger";
import { addQueryParameterToUrl } from "services/urlHelper";
import { ModalOpenResult } from "models/view/AppModalModel";
import { dismissModal, Modal, openModal } from "components/organisms/modal";
import { showLoginErrorModal } from "components/organisms/modal/modals";

export function openDelegatedLoginWindow(): Promise<void> {
    // todo: guard against opening multiple windows

    const win = window.open(getDelegatedLoginUrl(), "DelegatedLogin", "menubar=no,toolbar=no, status=0, resizable=no, width=480, height=512");

    return new Promise<void>((resolve, reject) => {
        if (win) {
            log.info({ code: "web-220926-1442", msg: "opened", tags: [LogTag.User] });

            const unsubscribeA = messageBus.subscribeOne(USER_LOGGED_IN, (msg) => {
                log.info({ code: "web-220926-1450", msg: "received userloggedin", data: { ...msg }, tags: [LogTag.User] });
                clearInterval(timer);
                unsubscribeA();
                unsubscribeB();
                if (!win.closed) win.close();
                resolve();
            });

            const unsubscribeB = messageBus.subscribeOne(USER_LOGIN_ERROR, (msg) => {
                log.info({ code: "web-220926-1451", msg: "received user login error", data: { ...msg }, tags: [LogTag.User] });
                unsubscribeA();
                unsubscribeB();
                if (!win.closed) win.close();
                setTimeout(() => {
                    showLoginErrorModal(msg.payload.error);
                }, 500);
                resolve();
            });

            const timer = setInterval(() => {
                if (win.closed) {
                    clearInterval(timer);

                    log.info({ code: "web-220926-1452", msg: "closing" });
                    unsubscribeA();
                    unsubscribeB();
                    reject();
                }
            }, 500);
        } else {
            reject();
        }
    });
}

export function navigateToDelegatedLogin(): Promise<void> {
    // todo: persist the return url, and then the /delegatedloginresponse should use it if present

    document.location.href = getDelegatedLoginUrl();

    return new Promise<void>(() => {
        // only needed for the method signature
    });
}

export function showDelegatedLoginModal() {
    return new Promise<void>((resolve, reject) => {
        const unsubscribeA = messageBus.subscribeOne(USER_LOGGED_IN, (msg) => {
            log.info({ code: "web-220926-1453", msg: "received userloggedin", data: { ...msg }, tags: [LogTag.User] });
            unsubscribeA();
            unsubscribeB();
            dismissModal("unsubscribeA");
            resolve();
        });

        const unsubscribeB = messageBus.subscribeOne(USER_LOGIN_ERROR, (msg) => {
            log.info({ code: "web-220926-1454", msg: "received user login error", data: { ...msg }, tags: [LogTag.User] });
            unsubscribeA();
            unsubscribeB();
            dismissModal("unsubscribeB");
            setTimeout(() => {
                showLoginErrorModal(msg.payload.error);
            }, 500);
            resolve();
        });

        openModal(
            <Modal hasXCloseButton>
                <DelegatedLogin />
            </Modal>
        ).then((result) => {
            unsubscribeA();
            unsubscribeB();
            if (result === ModalOpenResult.Submit) resolve();
            else reject();
        });
    });
}

export function DelegatedLogin() {
    return (
        <div className={"login aHu"}>
            <iframe scrolling="no" src={getDelegatedLoginUrl()} />
        </div>
    );
}

function getDelegatedLoginUrl(): string {
    const theme = getThemeSettingFromState();
    const style = "nopadding";

    let url = isFeatureEnabled(Feature.ForceRemoteBackend)
        ? `https://musik.${environment.webAppBrand === WebAppBrand.YouSee ? "yousee" : "telmore"}.dk${apiDelegatedLoginPath}${
              document.location.port ? `?port=${document.location.port}` : ""
          }`
        : document.location.hostname === "localhost"
        ? `https://${document.location.hostname}${document.location.port ? ":5000" : ""}${apiDelegatedLoginPath}${document.location.port ? `?port=${document.location.port}` : ""}`
        : apiDelegatedLoginPath;

    url = addQueryParameterToUrl(url, "theme", theme);
    url = addQueryParameterToUrl(url, "style", style);
    url = addQueryParameterToUrl(url, "installationid", getInstallationId());
    url = addEnvironmentVariablesToUrl(url);

    return url;
}
