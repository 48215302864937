import { getAppSession } from "../appSessionService";
import { isMySession, parseData, getData, saveData } from "../helpers";
import { getLoadedPlaybackReportsStorageKey } from "../storageKeys";
import type { LoadedPlaybackReport } from "models/app/playbackReport";

let loadedReports: LoadedPlaybackReport[] | null;

export function getLoadedPlaybackReports(storageId: string) {
    const mySession = isMySession(storageId);
    if (mySession && loadedReports != null) return loadedReports;

    const key = getLoadedPlaybackReportsStorageKey(storageId);
    const reports = parseData<LoadedPlaybackReport[]>(getData(key));

    if (mySession) loadedReports = reports;
    return reports ?? [];
}

export function getSessionLoadedPlaybackReports() {
    return getLoadedPlaybackReports(getAppSession().storageId);
}

export function saveLoadedPlaybackReports(storageId: string, reports: LoadedPlaybackReport[]) {
    const mySession = isMySession(storageId);
    const key = getLoadedPlaybackReportsStorageKey(storageId);

    saveData(key, JSON.stringify(reports));
    if (mySession) loadedReports = reports;
}

export function saveSessionLoadedPlaybackReports(reports: LoadedPlaybackReport[]) {
    return saveLoadedPlaybackReports(getAppSession().storageId, reports);
}

export function restoreLoadedPlaybackReports() {
    saveSessionLoadedPlaybackReports(loadedReports ?? []);
}
