import type { ComponentChildren } from "preact";
import { h } from "preact";
import { useCallback, useRef, useState } from "preact/hooks";
import "./Search.scss";
import { useSearch } from "./useSearch";
import { dispatch } from "global";
import { USER_ADD_SEARCH_HISTORY, USER_CLICK_SEARCH_RESULT } from "global/actions";
import { useTranslations } from "global/config";
import { search } from "global/constants";
import { useDebouncedState } from "services/cancellation/debounce";
import { isSearchMixedResultPreviewModel } from "services/playable";
import { getSearchViewState, SearchViewState } from "services/search/searchViewService";
import type { PageContextModel, PreviewContextModel } from "models/app/resourceContextModel";
import { useAppMediaSize } from "components/shared/hooks";
import { usePageClick } from "components/shared/hooks/usePageClick";
import { useUserRecentSearches } from "components/shared/hooks/useSearchHistory";
import { SearchTextInput } from "components/atoms/controls/textInput/inputs";
import { PreviewAction } from "components/molecules/preview";
import { SearchResultAreaSource, SearchResultArea } from "components/molecules/searchResultArea";

export enum SearchResultDisplay {
    Hide = "Hide",
    Always = "Always",
    WhenFocus = "WhenFocus"
}

interface Props {
    onFocus?: () => void;
    onBlur?: (ev: Event) => boolean;
    resultsDisplay: SearchResultDisplay;
    context: PageContextModel;
}

export const Search = ({ onFocus, onBlur, resultsDisplay, context }: Props) => {
    const [open, setOpen] = useState(false);

    const searchDebounceMs = search.searchDebounceMs;
    const [criterion, setCriterion, setCriterionInstant] = useDebouncedState("", searchDebounceMs);

    const { result, state } = useSearch(criterion);

    const hasHistory = useUserRecentSearches().length > 0;
    const size = useAppMediaSize();
    const translations = useTranslations();

    const ref = useRef<HTMLDivElement>(null);

    const viewState = getSearchViewState(result, state, hasHistory);
    const showResults = resultsDisplay === SearchResultDisplay.Always || (resultsDisplay !== SearchResultDisplay.Hide && open && viewState !== SearchViewState.None);

    usePageClick(() => setOpen(false), ref);

    const onChange = useCallback(
        (value: string) => {
            if (open) {
                setCriterion(value);
            } else {
                setCriterionInstant(value);
                setOpen(true);
            }
        },
        [open, setCriterion, setCriterionInstant]
    );

    const onFocusFn = useCallback(() => {
        setOpen(true);
        if (onFocus) onFocus();
    }, [onFocus]);

    const onBlurFn = useCallback(
        (ev: Event): boolean => {
            if (onBlur) {
                const ok = onBlur(ev);
                if (!ok) return ok;
            }
            return true;
        },
        [onBlur]
    );

    const onAction = useCallback(
        (action: PreviewAction, source: SearchResultAreaSource, preview: PreviewContextModel) => {
            if (action === PreviewAction.Navigate) setOpen(false);
            if (!isSearchMixedResultPreviewModel(preview.resource)) return;
            dispatch({ type: USER_CLICK_SEARCH_RESULT, payload: { source, preview, criterion } });
            if (source === SearchResultAreaSource.History) return;
            dispatch({ type: USER_ADD_SEARCH_HISTORY, payload: { item: preview.resource } });
        },
        [criterion]
    );

    return [
        <div ref={ref} key={1} className={`search y1s`}>
            <SearchTextInput value="" placeholder={translations.Search} onChange={onChange} onFocus={onFocusFn} onBlur={onBlurFn} />
            {showResults && (
                <SearchDropDown>
                    <SearchResultArea context={context} state={viewState} search={result ?? null} onAction={onAction} size={size} />
                </SearchDropDown>
            )}
        </div>,
        <div key={2} className={`searchBackground --show-${showResults}`} onContextMenu={() => setOpen(false)} />
    ];
};

const SearchDropDown = ({ children }: { children: ComponentChildren }) => {
    return <div className="searchDropDown">{children}</div>;
};
