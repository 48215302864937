import { logFavoriteUnfavoriteEvent } from "./logFavoriteUnfavoriteEvent";
import { logOpenCloseQueueViewEvent } from "./logOpenCloseQueueViewEvent";
import { logQueueActionEvent } from "./logQueueActionEvent";
import { logQueueHistoryOnOffEvent } from "./logQueueHistoryOnOffEvent";
import { tryLogRepeatChangeEvent } from "./logRepeatChangeEvent";
import { logShuffleUnshuffleEvent } from "./logShuffleUnshuffleEvent";
import { messageBus } from "global";
import {
    PLAYER_SHUFFLE,
    PLAYER_REPEAT,
    PLAYQUEUE_CLEAR,
    PLAYQUEUE_REMOVE_TRACK,
    PLAYER_NEXT,
    PLAYER_PREVIOUS,
    PLAYER_REWIND,
    PLAYQUEUE_HISTORY_TOGGLE,
    ANALYTICS_PLAYQUEUE_SAVE_TO_PLAYLIST,
    SET_DESKTOP_QUEUE_OPEN,
    CONTEXT_MENU_OPEN,
    PLAYER_TOGGLE_FAVORITE,
    SET_USER_DRAGGING,
    PLAYQUEUE_KEEP_PRIORITY_LANE,
    PLAYQUEUE_CLEAR_PRIORITY_LANE
} from "global/actions";
import { ContentType } from "models/ModelType";

export const initQueueActionAnalyticsService = () => {
    messageBus.subscribeEvery(PLAYER_SHUFFLE, () => {
        logShuffleUnshuffleEvent();
    });

    messageBus.subscribeEvery(PLAYER_REPEAT, () => {
        tryLogRepeatChangeEvent({});
    });

    messageBus.subscribeEvery(PLAYQUEUE_CLEAR, () => {
        logQueueActionEvent("ClearQueue", true);
    });

    messageBus.subscribeEvery(PLAYQUEUE_REMOVE_TRACK, () => {
        logQueueActionEvent("RemoveItem", true);
    });

    messageBus.subscribeEvery(PLAYER_NEXT, () => {
        logQueueActionEvent("SkipForward");
    });

    messageBus.subscribeEvery(PLAYER_PREVIOUS, () => {
        logQueueActionEvent("SkipBack");
    });

    messageBus.subscribeEvery(PLAYER_REWIND, () => {
        logQueueActionEvent("SkipToStart");
    });

    messageBus.subscribeEvery(PLAYQUEUE_HISTORY_TOGGLE, () => {
        logQueueHistoryOnOffEvent();
    });

    messageBus.subscribeEvery(ANALYTICS_PLAYQUEUE_SAVE_TO_PLAYLIST, () => {
        logQueueActionEvent("SaveQueueAsPlaylist");
    });

    messageBus.subscribeEvery(SET_DESKTOP_QUEUE_OPEN, (msg) => {
        if (msg.payload.type !== ContentType.Player) return;
        logOpenCloseQueueViewEvent();
    });

    messageBus.subscribeEvery(CONTEXT_MENU_OPEN, (msg) => {
        if (msg.payload.context?.section.type !== ContentType.Player) return;
        logQueueActionEvent("OpenContextMenu");
    });

    messageBus.subscribeEvery(PLAYER_TOGGLE_FAVORITE, (msg) => {
        const { section, operation } = msg.payload;
        if (section?.type !== ContentType.Player) return;
        logFavoriteUnfavoriteEvent(operation);
    });
    messageBus.subscribeEvery(SET_USER_DRAGGING, (msg) => {
        const type = msg.payload.item?.context.section.type;
        if (type !== ContentType.Queue) return;
        logQueueActionEvent("ReorderQueue");
    });
    messageBus.subscribeEvery(PLAYQUEUE_KEEP_PRIORITY_LANE, () => {
        logQueueActionEvent("KeepPriorityTracks");
    });
    messageBus.subscribeEvery(PLAYQUEUE_CLEAR_PRIORITY_LANE, () => {
        logQueueActionEvent("DiscardPriorityTracks");
    });
};
