import type { ConnectionModel } from "./ConnectionModel";
import { createPlaylistPreviewConnectionModel } from "./ConnectionModel";
import type { PlaylistPreviewModel } from "./PlaylistModel";
import type { ResourceModelInterface, ResourceModelLinkInterface } from "./ResourceModel";
import type { PlaylistCategoryPageFragment, PlaylistCategoryPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type PlaylistCategoryPreviewData = PlaylistCategoryPreviewFragment;
type PlaylistCategoryPageData = PlaylistCategoryPageFragment;

export type PlaylistCategoryModel = PlaylistCategoryPreviewModel;
export type PlaylistCategoryPreviewModel = ({ type: DomainModelType.Preview } & PlaylistCategoryPreview) | PlaylistCategoryPageModel;
export type PlaylistCategoryPageModel = { type: DomainModelType.Page } & PlaylistCategoryPage;

interface PlaylistCategoryPreview extends ResourceModelLinkInterface, ResourceModelInterface {
    readonly contentType: ContentType.PlaylistCategory;
    readonly title: string;
}

interface PlaylistCategoryPage extends PlaylistCategoryPreview {
    readonly playlists: ConnectionModel<PlaylistPreviewModel> | null;
}

export function createPlaylistCategoryPreviewModel(data: PlaylistCategoryPreviewData): PlaylistCategoryPreviewModel {
    return {
        contentType: ContentType.PlaylistCategory,
        id: data.id,
        title: data.title,
        graphType: data.__typename,
        cover: data.cover ?? null,
        type: DomainModelType.Preview
    };
}

export function createPlaylistCategoryPageModel(data: PlaylistCategoryPageData): PlaylistCategoryPageModel {
    return {
        ...createPlaylistCategoryPreviewModel(data),
        type: DomainModelType.Page,
        playlists: data.playlists ? createPlaylistPreviewConnectionModel(data.playlists) : null
    };
}
