import { createAnalyticsData, SetAnalyticsProp } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { store } from "global/config";
import { AudioInputType, PlayerOutputType } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import { RepeatStatus, ShuffleState } from "models/view";

export type AnalyticsPlayableLoadProperties = {
    playDestination: PlayerOutputType;
    queueType?: QueueLane;
    playerSettingShuffle?: ShuffleState;
    playerSettingRepeat?: RepeatStatus;
};

export function getAnalyticsPlayableLoadProperties(audio: AudioInputItemModel): AnalyticsPlayableLoadProperties {
    const playDestination = getPlayDestination();
    let queueType: QueueLane | undefined;
    let playerSettingShuffle: ShuffleState | undefined;
    let playerSettingRepeat: RepeatStatus | undefined;

    if (audio.input === AudioInputType.PlayQueue) {
        queueType = getQueueType(audio);
        playerSettingShuffle = getPlayerSettingShuffle();
        playerSettingRepeat = getPlayerSettingRepeat();
    }

    return {
        playDestination,
        queueType,
        playerSettingShuffle,
        playerSettingRepeat
    };
}

function getPlayDestination(): PlayerOutputType {
    return store.getState().player.output;
}

function getQueueType(item: AudioInputItemModel): QueueLane | undefined {
    return item.input === AudioInputType.PlayQueue ? item.previousQueueLane : undefined;
}

function getPlayerSettingShuffle(): ShuffleState {
    return store.getState().player.shuffle;
}

function getPlayerSettingRepeat(): RepeatStatus {
    return store.getState().player.repeat;
}

export function convertPlayableLoadProperties(properties: AnalyticsPlayableLoadProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "PlayDestination", convertPlayDestination(properties.playDestination));
    SetAnalyticsProp(data, "event", "QueueType", convertQueueType(properties.queueType ?? null), true);
    SetAnalyticsProp(data, "event", "PlayerSettingShuffle", convertPlayerSettingShuffle(properties.playerSettingShuffle ?? null), true);
    SetAnalyticsProp(data, "event", "PlayerSettingRepeat", covertPlayerSettingRepeat(properties.playerSettingRepeat ?? null), true);

    return data;
}

function convertPlayDestination(output: PlayerOutputType): AnalyticsKey {
    switch (output) {
        case PlayerOutputType.Browser:
            return "Browser";
        case PlayerOutputType.Chromecast:
            return "Chromecast";
    }
}

function convertQueueType(lane: QueueLane | null): AnalyticsKey | null {
    switch (lane) {
        case QueueLane.Current:
            return "Current";
        case QueueLane.Flow:
            return "Flow";
        case QueueLane.Memory:
            return "Memory";
        case QueueLane.Priority:
            return "Priority";
        case QueueLane.Preview:
            return "Preview";
        case null:
            return null;
    }
}

function convertPlayerSettingShuffle(state: ShuffleState | null): AnalyticsKey | null {
    switch (state) {
        case ShuffleState.Heavy:
            return "Permanent";
        case ShuffleState.Light:
            return "Temporary";
        case ShuffleState.Off:
            return "Off";
        case null:
            return null;
    }
}

function covertPlayerSettingRepeat(status: RepeatStatus | null): AnalyticsKey | null {
    switch (status) {
        case RepeatStatus.All:
            return "All";
        case RepeatStatus.None:
            return "Off";
        case RepeatStatus.One:
            return "One";
        case null:
            return null;
    }
}
