import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { analyticsKeys } from "global/config/configcms";
import { log, DefaultLogMessage } from "services/logger";
import { getLink } from "services/resource";
import type { LocationContentType, PlayableParentModel } from "models/domain";
import { ContentType } from "models/ModelType";

export type AnalyticsResourceParentProperties = {
    parentName?: string;
    parentGraphType?: string;
    parentType: LocationContentType;
    parentId?: string;
    parentIsMyMusic: boolean;
    parentIsMixedContent: boolean;
};

function isMyMusic(parent: PlayableParentModel): boolean {
    switch (parent.contentType) {
        case ContentType.MyMusicTracks:
            return true;
        case ContentType.Playlist:
            return parent.owner;
        case ContentType.MixedContent: {
            switch (parent.mixedContentType) {
                case ContentType.Playlist:
                    return parent.owner ?? false;
                case ContentType.MyMusicTracks:
                    return true;
            }
        }
    }

    return false;
}

export function getAnalyticsResourceParentProperties(parent: PlayableParentModel): AnalyticsResourceParentProperties {
    const analytics: AnalyticsResourceParentProperties = {
        parentName: getParentName(parent),
        parentGraphType: parent.graphType,
        parentType: parent.contentType,
        parentId: parent.id,
        parentIsMyMusic: isMyMusic(parent),
        parentIsMixedContent: false
    };

    if (parent.contentType === ContentType.MixedContent) {
        analytics.parentIsMixedContent = true;
        analytics.parentType = parent.mixedContentType;
        analytics.parentId = parent.mixedContentId ?? undefined;
    }

    return analytics;
}

function getParentName(parent: PlayableParentModel): string | undefined {
    if (parent.contentType === ContentType.EndlessPlay) return analyticsKeys["AutomatiskFortsættelse"];
    const name = getLink(parent.contentType, parent)?.text;
    if (name == undefined) {
        log.error({ code: "web-210928-2047", msg: DefaultLogMessage.UnexpectedNull, data: { type: parent.contentType } });
    }
    return name;
}

export function convertResourceParentProperties(properties: AnalyticsResourceParentProperties) {
    const data = createAnalyticsData();
    SetAnalyticsProp(data, "event", "ParentName", properties.parentName as AnalyticsKey);
    SetAnalyticsProp(data, "event", "ParentType", properties.parentGraphType as AnalyticsKey);
    SetAnalyticsProp(data, "event", "ParentID", properties.parentId as AnalyticsKey);

    return data;
}
