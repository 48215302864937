import { appSettingsShared } from "./";
import { parseIntAsNormalPositiveNumberOrNull } from "./formatters";

export interface AppVersionModel {
    major: number;
    minor: number;
    release: number;
    build: number;
}

let appVersion: AppVersionModel | null = null;

export function getCurrentAppVersion(): AppVersionModel {
    if (appVersion == null) appVersion = readAppVersionOrDefault();
    return appVersion;
}

function readAppVersionOrDefault(): AppVersionModel {
    let version = readAppVersion();
    if (version == null) version = { major: 0, minor: 0, release: 0, build: 0 };
    return version;
}

declare const WEBPACK_BUILDVERSION: string;

function readAppVersion(): AppVersionModel | null {
    let buildVersion = WEBPACK_BUILDVERSION;

    if (buildVersion == null || typeof buildVersion !== "string") {
        console.error("no build version");
        return null;
    }

    const regs = /[^.]+$/.exec(buildVersion);

    if (!regs) {
        console.error("wrong build version format");
        return null;
    }

    buildVersion = regs[0];

    let appVersion = appSettingsShared.AppVersion;

    if (appVersion.indexOf("{build}") === -1) {
        console.error("wrong format in app version");
        return null;
    }

    appVersion = appVersion.replace("{build}", buildVersion);

    const versions = appVersion.split(".");
    if (versions.length != 4) {
        console.error("wrong format in app version");
        return null;
    }

    const major = parseIntAsNormalPositiveNumberOrNull(versions[0]);
    const minor = parseIntAsNormalPositiveNumberOrNull(versions[1]);
    const release = parseIntAsNormalPositiveNumberOrNull(versions[2]);
    const build_ = parseIntAsNormalPositiveNumberOrNull(versions[3]);

    if (major == null || minor == null || release == null || build_ == null) {
        console.error("wrong format in app version");
        return null;
    }

    return {
        major,
        minor,
        release,
        build: build_
    };
}

let appVersionString: string | null;
export function getAppVersionString() {
    if (appVersionString != null) return appVersionString;
    const version = getCurrentAppVersion();
    appVersionString = `${version.major}.${version.minor}.${version.release}.${version.build}`;
    return appVersionString;
}

let appVersionNumber: number | null;
export function getAppVersionNumber() {
    if (appVersionNumber != null) return appVersionNumber;
    const version = getCurrentAppVersion();
    appVersionNumber = Number(`${version.major}${version.minor.toString().padEnd(2, "0")}${version.release.toString().padEnd(2, "0")}`);
    return appVersionNumber;
}
