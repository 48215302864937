import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { ResourcePreviewSquareCover } from "./ResourcePreviewSquareCover";
import { PreviewAction, PreviewDisplayType } from "..";
import { PreviewLinks } from "../PreviewLinks";
import { PreviewSquare } from "../PreviewSquare";
import { usePreviewAction } from "../usePreviewAction";
import { isDraggable } from "services/playable";
import { getResourceMainAction } from "services/resource";
import type { DragProps, DropProps } from "models/app";
import { createDraggablePlayableModel } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import type { NotificationConversion } from "models/domain/NotificationModel";
import { useOnRightClickContextMenu, usePreviewContext } from "components/shared/hooks";
import type { CoverRounding, CoverSize } from "components/atoms/cover";

interface Props {
    context: SectionContextModel;
    dragProps: DragProps;
    dropProps: DropProps | null;
    dynamicSize?: boolean;
    onAction: (action: PreviewAction, preview: PreviewContextModel, notificationAction: NotificationConversion | null) => void;
    rank: number;
    resource: ResourcePreviewModel;
    rounding?: CoverRounding;
    size: CoverSize;
    disableLazyLoad: boolean;
    highlight?: boolean;
}

export const ResourcePreviewSquare = ({
    resource,
    context: sectionContext,
    size,
    onAction: onActionProp,
    dragProps,
    dropProps,
    rounding,
    dynamicSize,
    rank,
    disableLazyLoad,
    highlight
}: Props) => {
    const [moreButtonIsOpen, setMoreButtonIsOpen] = useState(false);

    const context = usePreviewContext(resource.contentType, resource, sectionContext, rank);
    const { onContextMenu, disableContextMenu, editable, available, moreOpen } = useOnRightClickContextMenu(context, resource, sectionContext, moreButtonIsOpen);

    const mainAction = getResourceMainAction(resource.contentType, resource);
    const { onAction, onMainAction } = usePreviewAction({ preview: context, onAction: onActionProp, resource, mainAction });
    const onNavigate = useCallback(() => onActionProp(PreviewAction.Navigate, context, null), [onActionProp, context]);

    const draggableItem = useCallback(() => createDraggablePlayableModel(resource, dragProps.dragSourceId, context), [resource, dragProps.dragSourceId, context]);

    const image = (
        <ResourcePreviewSquareCover
            available={available}
            context={context}
            disableMoreButton={disableContextMenu}
            draggableItem={isDraggable(resource) ? draggableItem : undefined}
            dynamicSize={dynamicSize}
            mainAction={mainAction}
            moreOpen={moreOpen}
            onAction={onAction}
            onContextMenu={onContextMenu}
            resource={resource}
            rounding={rounding}
            setMoreOpen={setMoreButtonIsOpen}
            size={size}
            disableLazyLoad={disableLazyLoad}
            highlight={highlight}
        />
    );

    const links = PreviewLinks({
        resource,
        resourceType: resource.contentType,
        context,
        displayType: PreviewDisplayType.Square,
        onNavigate,
        disabled: !available
    });

    return (
        <PreviewSquare
            editable={editable}
            available={available}
            dropProps={dropProps}
            highlight={highlight}
            draggableItem={isDraggable(resource) ? draggableItem : undefined}
            resourceType={resource.contentType}
            onAction={onMainAction}
            image={image}
            links={links}
            activated={moreOpen}
            onContextMenu={onContextMenu}
            context={context}
            mainAction={mainAction}
        />
    );
};
