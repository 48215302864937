import type { AlbumPreviewModel } from "./AlbumModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import { getNextUUID } from "services/utils";
import type { SearchAlbumsPaginationQuery } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

export type SearchAlbumResultsModel = SearchAlbumResultsSectionModel;
export type SearchAlbumResultsSectionModel = ({ type: DomainModelType.Section } & SearchAlbumResults) | SearchAlbumResultsPageModel;
export type SearchAlbumResultsPageModel = { type: DomainModelType.Page } & SearchAlbumResults;

type SearchResultData = SearchAlbumsPaginationQuery["search"];

interface SearchAlbumResults extends ResourceModelInterface {
    contentType: ContentType.SearchAlbumResults;
    criterion: string;
    albums: ConnectionModel<AlbumPreviewModel> | null;
}

function createSearchAlbumResults(data: SearchResultData, criterion: string): SearchAlbumResults {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        contentType: ContentType.SearchAlbumResults,
        criterion,
        albums: data.albums ? createAlbumPreviewConnectionModel(data.albums) : null
    };
}
export function createSearchAlbumResultsSectionModel(data: SearchResultData, criterion: string): SearchAlbumResultsSectionModel {
    return {
        ...createSearchAlbumResults(data, criterion),
        type: DomainModelType.Section
    };
}

export function createSearchAlbumResultsPageModel(data: SearchResultData, criterion: string): SearchAlbumResultsPageModel {
    return {
        ...createSearchAlbumResults(data, criterion),
        type: DomainModelType.Page
    };
}
