import { h } from "preact";
import { ToastDuration } from "../AppToast";
import { Toast, ToastState } from "../Toast";
import { translate, translateWithReplacement } from "global/config";
import type { NuuApps } from "generated/localization";
import { ContentType } from "models/ModelType";

export interface OpenToastModel {
    toast: h.JSX.Element;
    duration: ToastDuration;
}

export const AddMusicToQueueSuccessToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastAddedToQueue")}</Toast>, duration: ToastDuration.Short });
export const AddMusicToQueueFailToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastNotAddedToQueue")}</Toast>, duration: ToastDuration.Short });

export const AddMusicToPlaylistSuccessToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastAddedToPlaylist")}</Toast>, duration: ToastDuration.Short });
export const AddMusicToPlaylistFailToast = () => ({ toast: () => <Toast state={ToastState.Fail}>{translate("ToastNotAddedToPlaylist")}</Toast>, duration: ToastDuration.Short });

export const RemoveTrackFromPlaylistSuccessToast = (onCancel: () => void) => ({
    toast: (
        <Toast state={ToastState.Success} onCancel={onCancel}>
            {translate("ToastTrackRemoved")}
        </Toast>
    ),
    duration: ToastDuration.Short
});
export const RemoveTrackFromPlaylistFailToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastTrackNotRemoved")}</Toast>, duration: ToastDuration.Short });

export const AddMusicToMyMusicSuccessToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastAddedToMyMusic")}</Toast>, duration: ToastDuration.Short });
export const AddMusicToMyMusicFailToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastNotAddedToMyMusic")}</Toast>, duration: ToastDuration.Long });
export const AddMusicToMyMusicLimitExceededToast = (type: ContentType) => {
    let key: keyof NuuApps | undefined;
    switch (type) {
        case ContentType.Album:
            key = "ToastNotAddedTooManyFavoriteAlbum";
            break;
        case ContentType.Artist:
            key = "ToastNotAddedTooManyFavoriteArtist";
            break;
        case ContentType.PlayableContext:
            key = "ToastNotAddedTooManyFavoritePlaylists";
            break;
        case ContentType.Track:
        case ContentType.TrackPlayable:
        default:
            key = "ToastNotAddedTooManyFavorites";
            break;
    }
    return ({ toast: <Toast state={ToastState.Fail}>{translate(key)}</Toast>, duration: ToastDuration.Long });
}

export const RemoveMusicFromMyMusicSuccessToast = (onCancel?: () => void) => ({
    toast: (
        <Toast state={ToastState.Success} onCancel={onCancel}>
            {translate("ToastRemovedFromMyMusic")}
        </Toast>
    ),
    duration: ToastDuration.Short
});

export const ProfileChangedToast = (name: string) => ({ toast: <Toast state={ToastState.Success}>{translateWithReplacement("ToastProfileChanged", "currentProfile", name)}</Toast>, duration: ToastDuration.Short });

export const RemoveMusicFromMyMusicFailToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastNotRemovedFromMyMusic")}</Toast>, duration: ToastDuration.Long });

export const PlaybackFailToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ErrorPlaybackFailed")}</Toast>, duration: ToastDuration.Long });

export const PlaylistDeletedToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastPlaylistDeleted")}</Toast>, duration: ToastDuration.Short });

export const PlaylistNotDeletedToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastPlaylistNotDeleted")}</Toast>, duration: ToastDuration.Long });

export const PlaylistCreatedToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastCreatePlaylist")}</Toast>, duration: ToastDuration.Short });

export const PlaylistNotCreatedToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastPlaylistNotCreated")}</Toast>, duration: ToastDuration.Long });

export const PlaylistSavedToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastPlaylistSaved")}</Toast>, duration: ToastDuration.Short });

export const PlaylistNotSavedToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastPlaylistNotSaved")}</Toast>, duration: ToastDuration.Long });

export const PlaylistMadePublicToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastPlaylistMadePublic")}</Toast>, duration: ToastDuration.Short });

export const PlaylistMadePrivateToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastPlaylistMadePrivate")}</Toast>, duration: ToastDuration.Short });

export const PlaylistErrorStateChangeToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ErrorPlaylistStateChange")}</Toast>, duration: ToastDuration.Long });

export const LoadMorePageContentFailToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastLoadMorePageContentFail")}</Toast>, duration: ToastDuration.Long });

export const TrackRemovedToast = (trackCount: number, onCancel?: () => void) => ({
    toast: (
        <Toast state={ToastState.Success} onCancel={onCancel} cancelText={translate("RegretTrackDeletion")}>
            {translateWithReplacement(trackCount > 1 ? "ToastTracksRemoved" : "ToastTrackRemoved", "Count", trackCount.toString())}
        </Toast>
    ),
    duration: onCancel ? ToastDuration.Long : ToastDuration.Short
});

export const TrackNotRemovedToast = (trackCount: number) => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastTrackNotRemoved")}</Toast>, duration: ToastDuration.Long });

export const AddedToPlaylistToast = (trackCount: number, onCancel?: () => void) => ({
    toast: (
        <Toast state={ToastState.Success} onCancel={onCancel}>
            {translateWithReplacement(trackCount != 1 ? "ToastTracksAddedToPlaylist" : "ToastAddedToPlaylist", "Count", trackCount.toString())}
        </Toast>
    ),
    duration: onCancel ? ToastDuration.Long : ToastDuration.Short
});

export const NotAddedToPlaylistToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastNotAddedToPlaylist")}</Toast>, duration: ToastDuration.Long });

export const ShareUrlCopySuccessToast = () => ({ toast: <Toast state={ToastState.Success}>{translate("ToastShareCopyUrlSuccess")}</Toast>, duration: ToastDuration.Short });
export const ShareUrlCopyFailedToast = () => ({ toast: <Toast state={ToastState.Fail}>{translate("ToastShareCopyUrlFail")}</Toast>, duration: ToastDuration.Short });

export const CopyIdSuccessToast = () => ({ toast: <Toast state={ToastState.Success}>{"id kopieret"}</Toast>, duration: ToastDuration.Short });
export const CopyIdFailedToast = () => ({ toast: <Toast state={ToastState.Fail}>{"id kunne ikke kopieres"}</Toast>, duration: ToastDuration.Short });
