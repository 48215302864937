import { getCastSession, hasCastSession } from "../setup/castSessionListeners";
import { castSessionStopped } from "./castSessionStopped";
import { LogTag, log } from "services/logger";
import type { AudioContextModel } from "models/app/player/AudioContext";

export function stopCastSession(context: AudioContextModel) {
    log.debug([LogTag.Chromecast], () => ({ code: "web-211029-1319", msg: `stop cast session` }));

    if (hasCastSession()) getCastSession()?.endSession(true);
    cast.framework.CastContext.getInstance().endCurrentSession(true);

    castSessionStopped(context);
}
