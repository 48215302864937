import { useMemo } from "preact/hooks";
import { getResourceDisplayCount } from "services/resource";
import type { SectionContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel, ResourceDisplayType } from "models/domain";
import { createSkeletonPreviewModels } from "models/domain/SkeletonModel";

interface Props {
    skeletonItems?: true;
    displayType: ResourceDisplayType | null;
    resources: ResourcePreviewModel[] | null;
    section: SectionContextModel;
}
export function useSkeletonItems({ skeletonItems, displayType, resources, section }: Props) {
    return useMemo(() => {
        if (!skeletonItems || resources != null || displayType == null) return resources;
        const estimatedCount = getResourceDisplayCount(displayType);

        return createSkeletonPreviewModels(section.type, section.resource, estimatedCount);
    }, [skeletonItems, displayType, resources, section]);
}
