import { dispatch } from "global";
import { PLAYLIST_TRACK_ADDED, PLAYLIST_TRACKS_REMOVED, PLAYLIST_TRACKS_ADDED } from "global/actions";
import { mutateModifyTracksInPlaylist } from "services/backend";
import { TrackModificationType } from "generated/graphql-types";
import type { PlaylistModel, TrackPreviewModel } from "models/domain";
import { openToast } from "components/organisms/toast";
import { TrackNotRemovedToast, TrackRemovedToast } from "components/organisms/toast/toasts";

export async function removeTracksFromPlaylist(playlistId: string, tracks: TrackPreviewModel[]) {
    if (!playlistId || !tracks || tracks.length == 0) return false;

    const sortedTracks = [...tracks].sort((n) => n.position).reverse();

    const result = await mutateModifyTracksInPlaylist({
        id: playlistId,
        modifications: sortedTracks.map((track) => ({
            type: TrackModificationType.Remove,
            positionFrom: track.position
        }))
    });

    if (result?.ok) {
        dispatch({
            type: PLAYLIST_TRACKS_REMOVED,
            payload: {
                playlistId,
                playlist: result.playlist,
                positions: sortedTracks.map((n) => n.position)
            }
        });

        const onUndo = async () => {
            const undoneResult = await mutateModifyTracksInPlaylist({
                id: playlistId,
                modifications: sortedTracks.reverse().map((track) => ({
                    type: TrackModificationType.Insert,
                    positionTo: track.position,
                    trackId: track.id
                }))
            });
            if (undoneResult?.ok) {
                if (tracks.length === 1) {
                    dispatch({
                        type: PLAYLIST_TRACK_ADDED,
                        payload: {
                            playlistId,
                            playlist: undoneResult.playlist,
                            trackId: tracks[0].id,
                            position: tracks[0].position,
                            track: tracks[0],
                            duplicateTracksAdded: null
                        }
                    });
                } else {
                    dispatch({
                        type: PLAYLIST_TRACKS_ADDED,
                        payload: {
                            playlistId,
                            playlist: undoneResult.playlist,
                            duplicateTracksAdded: null,
                            tracks: tracks.map((track) => ({
                                trackId: track.id,
                                position: track.position,
                                track
                            }))
                        }
                    });
                }
            }
        };

        openToast(TrackRemovedToast(tracks.length, onUndo));
    } else {
        openToast(TrackNotRemovedToast(tracks.length));
    }

    return result?.ok ?? false;
}

export async function removeTrackFromPlaylist(track: TrackPreviewModel | null, playlist: PlaylistModel | null): Promise<boolean> {
    if (!track || !playlist) return false;
    return await removeTracksFromPlaylist(playlist.id, [track]);
}
