import { castGetStatus } from "../../castRequests/media";
import { hasCastMediaSession } from "../../setup";
import { log } from "services/logger";

let onUpdateResolvers: (() => void)[] = [];

export function onMediaSessionUpdate(isAlive: boolean) {
    if (isAlive) {
        const resolvers = onUpdateResolvers;
        onUpdateResolvers = [];
        resolvers.forEach((resolve) => resolve());
    }
}

export async function onNextMediaSessionUpdate(invoke: boolean): Promise<void> {
    // if (!hasCastMediaSession()) {
    //     log.error({ code: "web-230228-2324", msg: "no cast media session in onNextMediaSessionUpdate" });
    //     return;
    // }

    const promise = new Promise<void>((resolve) => {
        onUpdateResolvers.push(resolve);

        setTimeout(() => {
            if (onUpdateResolvers.indexOf(resolve) === -1) return;
            log.error({ code: "web-230228-2324", msg: "onNextMediaSessionUpdate timed out" });
            resolve();
        }, 1000);
    });

    if (invoke && hasCastMediaSession()) await castGetStatus();

    return promise;
}
