import { h } from "preact";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import { animations } from "global/constants";
import type { UseDomainQueryResult } from "services/backend/backendService";
import type { PageContextModel } from "models/app/resourceContextModel";
import type { PageLoadError } from "models/app/ViewPageError";
import { PageLoadSpinner } from "components/atoms/spinner";
import { ErrorPage } from "components/pages/errorPages/ErrorPage";

export function usePageLoadState<DomainModel>(page: PageContextModel | null, query: UseDomainQueryResult<DomainModel> | null) {
    const error = useErrorPage(page, query);
    const spinner = usePageLoadSpinner(query);
    const showContent = useShowContent(query);

    return { error, spinner, showContent };
}

function useShowContent(query: UseDomainQueryResult<unknown> | null): boolean {
    const { error, loading, refetching, model, coalesced } = query ?? { error: null, errorReason: null, loading: true, refetching: false, model: null };
    return useMemo(() => model != null && !error && (coalesced || (!loading && !refetching)), [model, loading, error, refetching, coalesced]);
}

function useErrorPage<DomainModel>(page: PageContextModel | null, query: UseDomainQueryResult<DomainModel> | null) {
    const { error, loading, refetching, refetch } = query ?? { error: null, errorReason: null, loading: true, refetching: false, refetch: null };
    const show = error != null && !loading && !refetching;

    const pageLoadError = useMemo<PageLoadError | null>(() => {
        if (!error) return null;
        return {
            type: error,
            message: query?.errorReason ?? null
        };
    }, [error, query]);

    const refetchFn = useCallback(() => {
        refetch && refetch(true);
    }, [refetch]);

    // const errorPage = useMemo(() => (show && pageLoadError ? ErrorPage({ page, error: pageLoadError, refetch: refetchFn }) : null), [show, page, pageLoadError, refetchFn]);
    return show && pageLoadError ? <ErrorPage page={page} error={pageLoadError} refetch={refetchFn} /> : null;
}

function usePageLoadSpinner<DomainModel>(query: UseDomainQueryResult<DomainModel> | null) {
    const [visible, setVisible] = useState(false);
    const { loading, refetching, coalesced } = query ?? { loading: true, refetching: false, coalesced: false };

    useEffect(() => {
        if (refetching && !coalesced) {
            setVisible(true);
            return;
        }
        if (!loading || coalesced) {
            setVisible(false);
            return;
        }
        const timeoutId = window.setTimeout(() => setVisible(true), animations.spinnerPageLoad);
        return () => clearTimeout(timeoutId);
    }, [loading, refetching, coalesced]);

    return visible ? PageLoadSpinner() : null;
}
