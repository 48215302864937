import { h } from "preact";
import { useCallback } from "preact/hooks";
import { LinkWithSubmenu } from "../Link";
import { AddToPlaylistContextMenu } from "../menus";
import { useTranslations } from "global/config";
import { addPlayableToPlaylist, canBeAddedToPlaylist } from "services/playlist";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlayableModel } from "models/domain/PlayableModel";
import { IconName } from "components/atoms/icon";

interface Props {
    playable: PlayableModel | null;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const AddToPlaylistLink = ({ playable, context, showIcon }: Props) => {
    const translations = useTranslations();

    const onAdd = useCallback(async (playlistId: string) => {
        if (playable) {
            await addPlayableToPlaylist(playlistId, playable);
        }
    }, [playable])

    if (!canBeAddedToPlaylist(playable)) return null;

    return (
        <LinkWithSubmenu
            disabled={playable == null}
            icon={showIcon ? IconName.AddToPlaylist : undefined}
            submenu={<AddToPlaylistContextMenu onAdd={onAdd} context={context} />}>
            {translations.AddToPlaylist}
        </LinkWithSubmenu>
    );
};
