import { h } from "preact";
import { useRef, useState } from "preact/hooks";
import "./InAppFeedbackModal.scss";
import { Modal, modalButtonSubmitSend, openModal } from "..";
import { useTranslations } from "global/config";
import { FormState } from "components/atoms/controls/form";
import { InAppFeedback } from "components/organisms/inAppFeedback";
import { useHasPrivacyLabel, InAppFeedbackPrivacyLabel } from "components/organisms/inAppFeedback/InAppFeedbackPrivacyLabel";

export async function showInAppFeedbackModal() {
    return openModal(<InAppFeedbackModal />);
}

export function InAppFeedbackModal() {
    const submitFn = useRef<() => Promise<boolean>>(null);
    const dismissFn = useRef<() => Promise<boolean>>(null);
    const translations = useTranslations();

    const submit = async () => {
        if (submitFn.current) return submitFn.current();
        else return true;
    };

    const dismiss = async () => {
        if (dismissFn.current) return dismissFn.current();
        else return false;
    };

    const [formState, setFormState] = useState(FormState.Default);
    const hasPrivacyLabel = useHasPrivacyLabel();
    return (
        <Modal
            buttons={[modalButtonSubmitSend(submit)]}
            className="inAppFeedbackModal"
            formState={formState}
            hasXCloseButton
            onRequestClose={dismiss}
            title={translations.SettingsFeedbackTitle}
            bottom={hasPrivacyLabel && <InAppFeedbackPrivacyLabel />}>
            <InAppFeedback onFromStateChange={setFormState} submit={submitFn} dismiss={dismissFn} />
        </Modal>
    );
}
