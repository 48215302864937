import { h } from "preact";
import type { IconName } from "components/atoms/icon";
import { IconGroup } from "components/molecules/itemGroup/IconGroup";

interface Props {
    icon: IconName;
    title: string;
    subtitle?: string;
}

export const IconMenuTop = ({ icon, title, subtitle }: Props) => {
    return <IconGroup icon={icon} title={title} subtitle={subtitle} />;
};
