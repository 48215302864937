import { LoginState } from "./LoginState";
import { DefaultLogMessage, log } from "../../services/logger/initLoggerService";
import { dispatch, store } from "global";
import { TRACING_NEW_TRACE } from "global/actions/actions";
import { ContentType } from "models/ModelType";

export type TraceModel = UserPlayTraceModel;

export type UserPlayTraceModel = { type: ContentType.UserPlayTrace } & UserPlayTrace;
export type UserNavigateTraceModel = { type: ContentType.UserNavigateTrace } & UserNavigateTrace;

//TODO: maybe rename/change later
export enum TraceName {
    ClickToSound = "ClickToSound"
}
export type UserPlayTraceError = "NoPlaybackError" | "StreamUrlError" | "TimeoutError" | "LoadTrackStreamError";
// TODO rename clickOnType
type PlayUserTraceAttribute =
    | { type: "isLoggedIn"; value: boolean }
    | { type: "title_trackId"; value: string }
    | { type: "clickOnType"; value: string }
    | { type: "error"; value: UserPlayTraceError };
type NavigationTraceAttribute = { type: "isLoggedIn"; value: boolean };

enum TraceState {
    Initial,
    Started,
    Stopped
}
interface Trace {
    name: TraceName;
    startCallbacks: (() => void)[];
    state: TraceState;
    stopCallbacks: (() => void)[];
}
interface UserPlayTrace extends Trace {
    putAttributeCallbacks: ((attribute: PlayUserTraceAttribute) => void)[];
}
interface UserNavigateTrace extends Trace {
    putAttributeCallbacks: ((attribute: NavigationTraceAttribute) => void)[];
}

export function startPlayUserTrace(trace: TraceModel) {
    if (trace.state !== TraceState.Initial) {
        log.error({ code: "web-210604-1433", msg: DefaultLogMessage.UnexpectedValue });
        return;
    }
    trace.state = TraceState.Started;

    trace.startCallbacks.forEach((start) => start());
}
export function stopPlayUserTrace(trace: TraceModel) {
    if (trace.state !== TraceState.Started) {
        log.error({ code: "web-210604-1433", msg: DefaultLogMessage.UnexpectedValue });
        return;
    }
    trace.state = TraceState.Stopped;
    trace.stopCallbacks.forEach((stop) => stop());
}
export function putAttributePlayUserTrace(trace: UserPlayTraceModel, attribute: PlayUserTraceAttribute) {
    trace.putAttributeCallbacks.forEach((value) => value(attribute));
}

export async function createAndStartUserPlayTraceModel(name: TraceName) {
    const trace: UserPlayTraceModel = {
        type: ContentType.UserPlayTrace,
        name,
        state: TraceState.Initial,
        startCallbacks: [],
        putAttributeCallbacks: [],
        stopCallbacks: []
    };
    await dispatch({ type: TRACING_NEW_TRACE, payload: { trace } });
    startPlayUserTrace(trace);
    const isLoggedIn = store.getState().user.state === LoginState.LoggedIn;
    putAttributePlayUserTrace(trace, { type: "isLoggedIn", value: isLoggedIn });
    return trace;
}
