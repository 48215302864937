import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { Sheet } from "..";
import { dispatch } from "global";
import { PLAYER_REPEAT, PLAYER_SHUFFLE } from "global/actions";
import { Feature, isFeatureEnabled, useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { userToggleCastSession } from "services/player/controllers/chromeCast/service/actions";
import { getRepeatIcon } from "services/player/controllers/service/helpers/icons";
import type { RootModel } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { RepeatStatus } from "models/view/RepeatStatus";
import { ShuffleState } from "models/view/ShuffleState";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { toggleLyrics, useLyricsState } from "components/organisms/lyrics";

export const PlayerDesktopContextMenu = () => {
    const repeat = useSelector((root: RootModel) => root.player.repeat);
    const shuffle = useSelector((root: RootModel) => root.player.shuffle);
    const shuffleToggled = shuffle !== ShuffleState.Off;
    const repeatToggled = repeat !== RepeatStatus.None;

    const { isLyricsEnabled, isLyricsAvailable, isLyricsOpen } = useLyricsState();

    const playerShuffle = useCallback(() => dispatch({ type: PLAYER_SHUFFLE, payload: { context: { action: AudioContextAction.UserPlayerShuffle, trace: null } } }), []);
    const playerRepeat = useCallback(() => dispatch({ type: PLAYER_REPEAT, payload: {} }), []);
    const onToggleLyrics = useCallback(() => toggleLyrics("MaxiPlayer"), []) // its the context menu on the maxiplayer, not a context menu on some random track

    const translations = useTranslations();

    return (
        <Sheet top={<div />}>
            <Button toggled={shuffleToggled} onClick={playerShuffle} icon={IconName.Shuffle} design={ButtonDesign.ContextMenu} testLocator={TestLocator.PlayerShuffleButton}>
                {translations.Shuffle}
            </Button>
            <Button
                className={`--repeatStatus-${repeat}`}
                toggled={repeatToggled}
                onClick={playerRepeat}
                icon={getRepeatIcon(repeat)}
                design={ButtonDesign.ContextMenu}
                testLocator={TestLocator.PlayerRepeatButton}>
                {translations.GlobalRepeatName}
            </Button>

            <Button
                onClick={userToggleCastSession}
                icon={IconName.Chromecast}
                design={ButtonDesign.ContextMenu}
                testLocator={TestLocator.PlayerChromecastButton}>
                {translations.GlobalChromecastName}
            </Button>

            {
                isLyricsEnabled && (isLyricsAvailable || isFeatureEnabled(Feature.LyricsIconWhenNotAvailable)) &&
                <Button
                    onClick={onToggleLyrics}
                    disabled={!isLyricsAvailable && !isLyricsOpen}
                    toggled={isLyricsOpen}
                    icon={IconName.Lyrics}
                    design={ButtonDesign.ContextMenu}
                    testLocator={TestLocator.PlayerChromecastButton}>
                    {translations.ContextOptionShowLyrics}
                </Button>
            }
        </Sheet>
    );
};
