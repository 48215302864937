export const pageContent = {
    artistPopularTracksShowAllPageItemsLimit: 2000,
    artistRadioPageItemsLimit: 50,
    resourceHighlightCap: 100,
    searchAlbumPageItemsLimit: 1000,
    searchArtistPageItemsLimit: 1000,
    searchHistoryItemLength: 15,
    searchPlaylistPageItemsLimit: 1000,
    searchTracksPageItemsLimit: 1000,
    trackRadioPageItemsLimit: 50
};
