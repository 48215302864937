import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { dispatch } from "global";
import { PLAYER_NEXT, PLAYER_PREVIOUS_OR_REWIND, PLAYER_REPEAT, PLAYER_SHUFFLE, PLAYER_TOGGLE_PLAY } from "global/actions";
import { TestLocator } from "global/constants";
import { getShuffleIcon, getPlayIcon, getRepeatIcon } from "services/player/controllers/service/helpers/icons";
import { isShuffleDisabled, isPreviousDisabled, isNextDisabled, isRepeatDisabled } from "services/player/inputs/inputs/playQueue/helpers";
import { useLoginState } from "services/user";
import { LoginState, type RootModel } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { RepeatStatus } from "models/view/RepeatStatus";
import { ShuffleState } from "models/view/ShuffleState";
import { useAudioProperties } from "components/shared/hooks";
import { useMatchMedia } from "components/shared/hooks/useMatchMedia";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { useTranslations } from "global/config";

export const DesktopPlayerPrimaryControls = () => {
    const translations = useTranslations();
    const { playState, canPlay, canPause } = useAudioProperties();
    const shuffle = useSelector((root: RootModel) => root.player.shuffle);
    const repeat = useSelector((root: RootModel) => root.player.repeat);
    const { Next, Previous } = IconName;
    const isTabletLarge = useMatchMedia({ maxWidth: 800 });
    const loginStateIsUnknown = useLoginState() === LoginState.Unknown;

    const input = useSelector((root: RootModel) => root.player.input);

    const playerNext = useCallback(() => dispatch({ type: PLAYER_NEXT, payload: { context: { action: AudioContextAction.UserPlayerNext, trace: null } } }), []);
    const playerPreviousOrRewind = useCallback(
        () => dispatch({ type: PLAYER_PREVIOUS_OR_REWIND, payload: { context: { action: AudioContextAction.UserPlayerPreviousOrRewind, trace: null } } }),
        []
    );
    const playerShuffle = useCallback(() => dispatch({ type: PLAYER_SHUFFLE, payload: { context: { action: AudioContextAction.UserPlayerShuffle, trace: null } } }), []);
    const playerTogglePlay = useCallback(() => dispatch({ type: PLAYER_TOGGLE_PLAY, payload: { context: { action: AudioContextAction.UserPlayerTogglePlay, trace: null } } }), []);
    const playerRepeat = useCallback(() => dispatch({ type: PLAYER_REPEAT, payload: {} }), []);

    return (
        <div className="primaryControls">
            {!isTabletLarge && (
                <Button
                    toggled={shuffle !== ShuffleState.Off}
                    onClick={playerShuffle}
                    icon={getShuffleIcon()}
                    disabled={isShuffleDisabled()}
                    design={ButtonDesign.PlayerSecondary}
                    testLocator={TestLocator.PlayerShuffleButton}
                />
            )}
            <Button
                onClick={playerPreviousOrRewind}
                icon={Previous}
                disabled={isPreviousDisabled() || loginStateIsUnknown}
                design={ButtonDesign.PlayerSecondary}
                testLocator={TestLocator.PlayerPreviousButton}
            />
            <Button
                className={"playPause"}
                onClick={playerTogglePlay}
                icon={getPlayIcon(playState, input)}
                disabled={(!canPlay && !canPause) || loginStateIsUnknown}
                design={ButtonDesign.PlayerPrimary}
                testLocator={TestLocator.PlayerPlayPauseButton}
                ariaLabel={playState === "playing" ? translations.Pause : translations.Play}
            />
            <Button
                onClick={playerNext}
                icon={Next}
                disabled={isNextDisabled() || loginStateIsUnknown}
                design={ButtonDesign.PlayerSecondary}
                testLocator={TestLocator.PlayerNextButton}
            />
            {!isTabletLarge && (
                <Button
                    className={`--repeatStatus-${repeat}`}
                    toggled={repeat !== RepeatStatus.None}
                    onClick={playerRepeat}
                    disabled={isRepeatDisabled()}
                    icon={getRepeatIcon(repeat)}
                    design={ButtonDesign.PlayerSecondary}
                    testLocator={TestLocator.PlayerRepeatButton}
                />
            )}
        </div>
    );
};
