import type { AnalyticsData } from "../../analyticsData";
import { SetAnalyticsProp, createAnalyticsData, IncrementAnalyticsProp, SetFirstAnalyticsProp } from "../../analyticsData";
import type { UserLoggingOutAction } from "global/actions";
import type { AnalyticsKey } from "global/config";

export type AnalyticsLoginLogoutProperties = AnalyticsData & { type: "LoginLogoutProperties" };

export function getAnalyticsLoginProperties() {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstLogin", new Date());
    SetAnalyticsProp(data, "user", "LastLogin", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfLogins");

    return { ...data, type: "LoginLogoutProperties" };
}

export enum LogoutTrigger {
    Automatic = "Automatic",
    Manual = "Manual",
    NoMusicRights = "NoMusicRights"
}

export function getAnalyticsLogoutProperties(action: UserLoggingOutAction) {
    const data = createAnalyticsData();

    if (action.payload.refreshTokenDate) {
        const days = Math.floor((new Date().getTime() - action.payload.refreshTokenDate.getTime()) / (1000 * 60 * 60 * 24));
        SetAnalyticsProp(data, "event", "NumberOfDaysSinceLastTokenRefresh", days);
    } else {
        // the mobile adds also sends "0" when the token hasn't been refreshed at all
        SetAnalyticsProp(data, "event", "NumberOfDaysSinceLastTokenRefresh", 0);
    }

    SetAnalyticsProp(data, "event", "LogoutTrigger", getLogoutTriggerStr(action.payload.trigger));
    SetFirstAnalyticsProp(data, "user", "FirstLogout", new Date());
    SetAnalyticsProp(data, "user", "LastLogout", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfLogouts");

    return { ...data, type: "LoginLogoutProperties" };
}

function getLogoutTriggerStr(trigger: LogoutTrigger): AnalyticsKey {
    switch (trigger) {
        case LogoutTrigger.Automatic:
            return "Automatic";
        case LogoutTrigger.Manual:
            return "Manual";
        case LogoutTrigger.NoMusicRights:
            return "NoMusicRights";
    }
}
