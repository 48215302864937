import { getBrowserAudioLog, getBrowserAudiosLog } from "../log";
import { inSkipDebounce, updateSkipDebounce } from "../skipDebounce";
import { loadUrl } from "../url/loadUrl";
import { getBrowserAudios, updateBrowserAudios } from "./updateBrowserAudios";
import { updateLoadNow } from "./updateLoad";
import { updateMedia } from "./updateMedia";
import { updatePlayState } from "./updatePlayState";
import { updatePreloadState } from "./updatePreloadState";
import { log, LogTag } from "services/logger";
import type { BrowserAudioItemModel } from "models/app/player/output";

export function updateFlow() {
    updateBrowserAudios();
    updateSkipDebounce();
    const browserAudios = getBrowserAudios();

    log.debug([LogTag.Playback], () => ({
        code: "web-210212-1645",
        msg: "updating browserAudios' flow",
        data: {
            browserAudios: getBrowserAudiosLog(browserAudios)
        }
    }));

    browserAudios.forEach((browserAudio) => updateBrowserAudioFlow(browserAudio));
}

function updateBrowserAudioFlow(browserAudio: BrowserAudioItemModel) {
    if (inSkipDebounce) {
        log.debug([LogTag.Playback], () => ({
            code: "web-210212-1645",
            msg: "NOT updating browserAudio flow (because of skip debounce)",
            data: {
                browserAudio: getBrowserAudioLog(browserAudio)
            }
        }));
        return;
    }

    log.debug([LogTag.Playback], () => ({
        code: "web-210212-1451",
        msg: "updating browserAudio flow",
        data: {
            browserAudio: getBrowserAudioLog(browserAudio)
        }
    }));

    updateLoadNow(browserAudio);

    if (!browserAudio.url) {
        loadUrl(browserAudio);
        return;
    }

    updateMedia(browserAudio);
    updatePlayState(browserAudio);
    updatePreloadState(browserAudio);
}
