import type { ResourceModelLinkInterface, ConnectionModel, TrackPreviewModel, PlayQueuePlayableModelInterface, MixedContentModel } from ".";
import { createStaticPreviewConnectionModel } from ".";
import { DefaultLogMessage, log } from "services/logger";
import { getNextUUID } from "services/utils";
import type { LiveRadioPreviewFragment, LiveRadioPlayFragment, LiveRadioPreview } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type LiveRadioPreviewData = LiveRadioPreviewFragment;
type LiveRadioPlayData = LiveRadioPlayFragment;

export type LiveRadioModel = LiveRadioPreviewModel;
export type LiveRadioPreviewModel = ({ type: DomainModelType.Preview } & LiveRadioPreview) | LiveRadioPlayModel;
export type LiveRadioPlayModel = { type: DomainModelType.Play } & LiveRadioPlay;

interface LiveRadio extends ResourceModelLinkInterface, PlayQueuePlayableModelInterface {
    readonly contentType: ContentType.LiveRadio;
    readonly title: string;
    readonly description: string | null;
    readonly category?: { readonly id: string };
    readonly radioId: string;
    readonly sharingUrl: string | null;
    tracks?: LiveRadioPlayTracks;
    playbackContext: string | null;
}

type LiveRadioPreview = LiveRadio;

interface LiveRadioPlay extends LiveRadio {
    mp3Url: string | null;
}

export interface LiveRadioPlayTracks {
    nowPlaying: LiveRadioTrackPreviewModel | null;
    recentlyPlayedTracks: ConnectionModel<LiveRadioTrackPreviewModel> | null;
}

export interface LiveRadioTrackPreviewModel extends PlayQueuePlayableModelInterface {
    type: DomainModelType.Preview;
    contentType: ContentType.LiveRadioTrack;
    track: TrackPreviewModel;
}

export function getTracksFromLiveRadioModel(data: LiveRadioPreviewModel) {
    const nowPlaying = data.tracks?.nowPlaying ?? null;
    const recentTracks = data.tracks?.recentlyPlayedTracks?.items ?? null;

    let liveRadioTracks: LiveRadioTrackPreviewModel[] = [];

    if (nowPlaying) {
        liveRadioTracks.push(nowPlaying);
    }
    if (recentTracks) {
        liveRadioTracks = [...liveRadioTracks, ...recentTracks];
    }

    const tracks = liveRadioTracks.map((track) => track.track);
    return tracks;
}

export function getTracksConnectionModelFromLiveRadioModel(data: LiveRadioPlayModel): ConnectionModel<TrackPreviewModel> | null {
    const tracks = getTracksFromLiveRadioModel(data);
    return createStaticPreviewConnectionModel(tracks, data.trackCount);
}

export function createLiveRadioPreviewModel(data: LiveRadioPreviewData): LiveRadioPreviewModel {
    const categoryId = data.category?.id;

    return {
        contentType: ContentType.LiveRadio,
        id: data.id,
        title: data.title,
        category: categoryId ? { id: categoryId } : undefined,
        graphType: data.__typename,
        radioId: getNextUUID(),
        trackCount: null,
        cover: data.cover ?? null,
        sharingUrl: data.share ?? null,
        type: DomainModelType.Preview,
        playbackContext: data.playbackContext,
        description: data.description
    };
}

export function createLiveRadioPlayModel(data: LiveRadioPlayData): LiveRadioPlayModel {
    const mp3Url = data.stream.replace(/^http:\/\//i, "https://");
    return {
        ...createLiveRadioPreviewModel(data),
        type: DomainModelType.Play,
        mp3Url
    };
}

export function createLiveRadioPreviewModelFromMixedContent(mixedContent: MixedContentModel): LiveRadioPreviewModel | null {
    if (mixedContent.mixedContentId == null) {
        log.error({ code: "web-211027-1853", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    const liveRadio: LiveRadioPreviewModel = {
        contentType: ContentType.LiveRadio,
        graphType: mixedContent.graphType,
        id: mixedContent.mixedContentId,
        radioId: getNextUUID(),
        title: mixedContent.title,
        cover: mixedContent.cover,
        trackCount: null,
        type: DomainModelType.Preview,
        sharingUrl: null,
        playbackContext: null,
        description: mixedContent.subtitle ?? null
    };
    return liveRadio;
}
