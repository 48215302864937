import type { MixpanelViewPageType } from "./MixpanelViewPageType";
import { getMixpanelViewPageType } from "./MixpanelViewPageType";
import { DefaultLogMessage, log } from "../../..";
import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { getActivePageContext, getPreviousPageContext } from "services/navigationStack/navigationStack";
import { getLink } from "services/resource";
import type { PageContextModel } from "models/app/resourceContextModel";

export type AnalyticsPageProperties = {
    pageName?: string;
    pageType?: keyof MixpanelViewPageType;
    pageId?: string;
    previousPageName?: string;
};

export function getAnalyticsCurrentPageProperties(): AnalyticsPageProperties {
    const page = getActivePageContext();
    const previousPage = getPreviousPageContext();

    return {
        pageName: getPageName(page) ?? undefined,
        pageType: getMixpanelViewPageType(page?.pageType ?? page?.type ?? undefined),
        pageId: getPageId(page) ?? undefined,
        previousPageName: previousPage?.name ?? undefined
    };
}

function getPageName(page: PageContextModel | null): string | null {
    if (page == null) {
        log.error({ code: "web-220901-1542", msg: "no page in analytics" });
        return null;
    }

    const name = getLink(page.type, page.resource)?.text ?? null;

    if (name == null) {
        log.error({ code: "web-210928-2045", msg: DefaultLogMessage.UnexpectedNull, data: { "page type": page.type } });
        return null;
    }

    return name;
}

function getPageId(page: PageContextModel | null): string | null {
    if (!page) return null;
    return page.resource?.id ?? null;
}

export function convertPageProperties(properties: AnalyticsPageProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "PageName", properties.pageName as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "PageType", properties.pageType ?? null);
    SetAnalyticsProp(data, "event", "PageID", properties.pageId as AnalyticsKey, true);
    SetAnalyticsProp(data, "event", "LastPageName", properties.previousPageName as AnalyticsKey, true);

    return data;
}
