import { useMemo } from "preact/hooks";
import { getTrackPlayablePreviews } from "services/playable";
import { getNormalizedResourceFromUnion, getResourcesFromUnions } from "services/resource";
import type { LiveRadioPreviewModel, ResourceModel, ResourcePreviewModel, TrackParentModel, TrackPreviewModel } from "models/domain";
import type { TrackPlayablePreviewModel } from "models/domain/TrackPlayableModel";

export const useTrackPlayables = (tracks: TrackPreviewModel[] | null, parent: TrackParentModel | null): TrackPlayablePreviewModel[] | null => {
    return useMemo(() => (tracks && parent ? getTrackPlayablePreviews(tracks, parent) : null), [tracks, parent]);
};

export const useResourceFromUnion = (union: TrackPreviewModel | ResourcePreviewModel | null, parent: TrackParentModel | null): ResourcePreviewModel | null => {
    return useMemo(() => {
        if (!union || !parent) return null;
        return getNormalizedResourceFromUnion(union, parent);
    }, [union, parent]);
};

export const useResourcesFromUnions = (
    playableOrTracks: (TrackPreviewModel | LiveRadioPreviewModel | ResourcePreviewModel)[] | null,
    parent: ResourceModel | null
): ResourcePreviewModel[] | null => {
    return useMemo(() => getResourcesFromUnions(playableOrTracks, parent), [playableOrTracks, parent]);
};
