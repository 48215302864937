import { ComponentChild, ComponentChildren, h } from "preact";
import { ScrollingText } from "components/atoms/text";
import "./ItemGroup.scss";

interface Props {
    children?: ComponentChildren;
    className?: string;
    title: ComponentChild;
    subtitle?: ComponentChild;
    scrollingText?: boolean;
}

export const ItemGroup = ({ children, className, title, subtitle, scrollingText }: Props) => {
    title = scrollingText ? <ScrollingText>{title}</ScrollingText> : title;
    subtitle = scrollingText ? <ScrollingText>{subtitle}</ScrollingText> : subtitle;

    return (
        <figure className={`itemGroup OhR ${className ?? ""}`}>
            {children}
            <figcaption>
                {title}
                {subtitle}
            </figcaption>
        </figure>
    );
};
