import { LogLevel } from "./initLoggerService";
import { messageBus } from "global";
import { EVENT_MESSAGE } from "global/actions";

export const initConsoleLoggerService = () => {
    messageBus.subscribeEvery(EVENT_MESSAGE, (msg) => {
        const { message } = msg.payload;
        switch (message.level) {
            case LogLevel.Debug: {
                console.info(message.msg);
                break;
            }
            case LogLevel.Info: {
                console.info(message.msg, message);
                break;
            }
            case LogLevel.Warning: {
                console.warn(message.msg, message);
                break;
            }
            case LogLevel.Error: {
                console.error(message.msg, message);
                break;
            }
        }
    });
};
