import { useLayoutEffect, useCallback } from "preact/hooks";
import { useActiveAppLayer, AppLayer, useRefUpdate } from "components/shared/hooks";

export function useAppLayerFocus() {
    const pagesRef = useRefUpdate<HTMLDivElement>();
    const pageRef = useRefUpdate<HTMLDivElement>();
    const modalRef = useRefUpdate<HTMLDialogElement>();
    const contextMenuRef = useRefUpdate<HTMLDialogElement>();

    const pagesEl = (pagesRef.current ?? null) as HTMLElement | null;
    const pageEl = (pageRef.current ?? null) as HTMLElement | null;
    const modalEl = (modalRef.current ?? null) as HTMLElement | null;
    const contextMenuEl = (contextMenuRef.current ?? null) as HTMLElement | null;
    const active = useActiveAppLayer();

    const setFocus = useCallback(() => {
        switch (active) {
            case AppLayer.Page: {
                if (pagesEl && pageEl) trySetFocusWithin(pagesEl, pageEl);
                return;
            }
            case AppLayer.Modal: {
                if (modalEl) trySetFocusWithin(modalEl, modalEl);
                return;
            }
            case AppLayer.ContextMenu: {
                if (contextMenuEl) trySetFocusWithin(contextMenuEl, contextMenuEl);
                return;
            }
        }
    }, [active, pagesEl, pageEl, modalEl, contextMenuEl]);

    useLayoutEffect(() => {
        {
            const elements = [pagesEl, modalEl, contextMenuEl];
            elements.forEach((element) => element?.addEventListener("focusin", setFocus, { passive: true }));
            elements.forEach((element) => element?.addEventListener("focusout", setFocus, { passive: true }));
            return () => {
                elements.forEach((element) => element?.removeEventListener("focusin", setFocus));
                elements.forEach((element) => element?.removeEventListener("focusout", setFocus));
            };
        }
    }, [setFocus, pagesEl, modalEl, contextMenuEl]);

    useLayoutEffect(() => setFocus(), [setFocus, active]);

    return { pagesRef, pageRef, modalRef, contextMenuRef };
}

function trySetFocusWithin(element: HTMLElement, focus: HTMLElement): void {
    setTimeout(() => {
        if (hasFocusWithin(element)) return;
        if (hasFocusOnIframe()) return;

        const focusable = focus.getAttribute("tabIndex") === "-1" ? focus : (focus.querySelector(`[tabindex="-1"]`) as HTMLElement | null);
        if (focusable == null) return;

        focusable.focus({ preventScroll: true });
    }, 0);
}

function hasFocusWithin(element: HTMLElement): boolean {
    const has = document.activeElement === element || element.querySelector(":focus") != null;
    return has;
}

function hasFocusOnIframe() {
    return document.activeElement?.tagName == "IFRAME";
}
