import { h } from "preact";
import { useMemo } from "preact/hooks";
import { Link } from "../Link";
import { dispatch } from "global";
import { PLAYLIST_DELETED } from "global/actions";
import { useTranslations } from "global/config";
import { mutateDeletePlaylist } from "services/backend";
import { isOnPage, navigateForwardTo } from "services/navigationStack/navigationStack";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlaylistPageModel, PlaylistPreviewModel } from "models/domain/PlaylistModel";
import { ContentType } from "models/ModelType";
import { ModalOpenResult } from "models/view/AppModalModel";
import { IconName } from "components/atoms/icon";
import { showDeletePlaylistModal } from "components/organisms/modal/modals/DeletePlaylistModal";
import { openToast } from "components/organisms/toast";
import { PlaylistDeletedToast, PlaylistNotDeletedToast } from "components/organisms/toast/toasts";

interface Props {
    playable: PlaylistPreviewModel | PlaylistPageModel | null;
    preview: PreviewContextModel;
    showIcon: boolean;
}

export const PlaylistDeleteLink = ({ showIcon, playable, preview }: Props) => {
    const translations = useTranslations();
    const showDeletePlaylistDialog = useMemo(
        () => async () => {
            if (!playable) return;
            const res = await showDeletePlaylistModal();
            if (res === ModalOpenResult.Submit) {
                try {
                    const ok = await mutateDeletePlaylist({ id: playable.id });
                    if (ok) {
                        dispatch({
                            type: PLAYLIST_DELETED,
                            payload: {
                                playlistId: playable.id
                            }
                        });
                        openToast(PlaylistDeletedToast());
                        if (isOnPage(ContentType.Playlist, playable)) navigateForwardTo(ContentType.MyMusicPlaylists, null, preview);

                        return;
                    }
                } catch (e) {
                    // todo: consider logging
                }
                openToast(PlaylistNotDeletedToast());
            }
        },
        [playable, preview]
    );
    if (!playable) return <Link>{""}</Link>;
    if (!playable.owner) return null;

    const icon = showIcon ? IconName.Trash : undefined;
    return (
        <Link icon={icon} disabled={!playable} closeOnClick onClick={showDeletePlaylistDialog}>
            {translations.ContextOptionDeletePlaylist}
        </Link>
    );
};
