/* eslint-disable react/jsx-key */
import { Fragment, h } from "preact";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import "./Lyrics.scss";
import { toggleLyrics } from "./toggleLyrics";
import type { LyricLine } from "./useLyrics";
import { useLyrics } from "./useLyrics";
import { dispatch } from "global";
import { AUDIO_INPUT_AUDIO_PLAY_CHANGE, DISPLAYED_LYRICS, PLAYER_SEEK, PLAYER_UI_SEEKING, SET_DESKTOP_LYRICS_VISIBLE, SET_MAXIPLAYER_OPEN } from "global/actions";
import { store, translate } from "global/config";
import { useMessageBus } from "global/hooks";
import { getTrackPreview } from "services/backend";
import { getCurrentLaneFromState } from "services/player/inputs/inputs/playQueue/helpers";
import { SynchronizationType } from "generated/graphql-types";
import { MobileMaxiPlayerOpen, type RootModel } from "models/app";
import { AudioInputType } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { AudioInputItemPlayQueueModel } from "models/app/player/input";
import type { PlayableModel } from "models/domain";
import { getAudioPropertiesFromState, useRefUpdate, useSecondsPlayedFromState } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { PageLoadSpinner } from "components/atoms/spinner";

type LyricsInternalState = {
    enableAutoscroll: boolean;
    isAutoscrolling: boolean;
    autoscrollTimer: number | undefined;
}

export function Lyrics() {

    const internalState = useMemo<LyricsInternalState>(() => ({
        enableAutoscroll: true,
        isAutoscrolling: false,
        autoscrollTimer: undefined
    }), []);

    const lyricsTrackId = useSelector((root: RootModel) => root.ui.layout.lyricsTrackId);
    const lyricsStartingPoint = useSelector((root: RootModel) => root.ui.layout.lyricsStart);

    const [queueTrackId] = useState(store.getState().player.input === AudioInputType.PlayQueue ? getCurrentLaneFromState()?.track.id : undefined)

    const isLyricsForCurrentTrack = queueTrackId == lyricsTrackId;

    const { loading, lyricsId, lines, synchronizationType, author, publisher, provider } = useLyrics(lyricsTrackId);

    const { secondsPlayed } = useSecondsPlayedFromState();
    const [dragSecondsPlayer, setDragSecondsPlayerd] = useState<number | undefined>(undefined);

    const positionMs = (dragSecondsPlayer || secondsPlayed) * 100;
    const activeIndex = findActiveIndex(positionMs, lines);

    useEffect(() => {
        if (loading || !lyricsId || !lyricsTrackId || !synchronizationType || !lyricsStartingPoint) return;
        (async () => {

            const currentLane = getCurrentLaneFromState();
            const playable: PlayableModel | null = ((isLyricsForCurrentTrack && currentLane && currentLane.track.id == lyricsTrackId) ? currentLane : undefined) ?? (await getTrackPreview({ id: lyricsTrackId }))?.model ?? null;

            if (!playable) {
                // todo: consider logging
                return;
            }

            dispatch({
                type: DISPLAYED_LYRICS,
                payload: {
                    lyricsId,
                    playable,
                    startingPoint: lyricsStartingPoint,
                    sync: isLyricsForCurrentTrack ? synchronizationType : SynchronizationType.None
                }
            })

        })();
    }, [lyricsTrackId, loading, lyricsId, synchronizationType, lyricsStartingPoint, isLyricsForCurrentTrack])

    const onClick = useCallback((e: h.JSX.TargetedMouseEvent<HTMLParagraphElement>) => {
        if (!isLyricsForCurrentTrack) return;
        const index = Array.prototype.indexOf.call((e.target as HTMLParagraphElement).parentNode?.children, e.target)
        if (lines?.[index]?.fromMs) {
            const { duration } = getAudioPropertiesFromState();
            if (duration) {
                const percent = (lines[index]?.fromMs ?? 0) / duration
                dispatch({ type: PLAYER_SEEK, payload: { percent, context: { action: AudioContextAction.UserPlayerSeek, trace: null } } })
                internalState.isAutoscrolling = true;
                internalState.enableAutoscroll = true;
                if (internalState.autoscrollTimer) clearTimeout(internalState.autoscrollTimer);
                internalState.autoscrollTimer = window.setTimeout(() => {
                    internalState.isAutoscrolling = false;
                }, 4000);
            }
        }
    }, [lines, internalState, isLyricsForCurrentTrack]);

    useMessageBus(PLAYER_UI_SEEKING, msg => {
        if (!isLyricsForCurrentTrack) return;
        setDragSecondsPlayerd(msg.payload.seconds);
    });

    useMessageBus(PLAYER_SEEK, () => {
        if (!isLyricsForCurrentTrack) return;
        setDragSecondsPlayerd(undefined);
    });

    // close lyrics when the track changes
    useMessageBus(AUDIO_INPUT_AUDIO_PLAY_CHANGE, msg => {
        if (isLyricsForCurrentTrack && (msg.payload.audio as AudioInputItemPlayQueueModel).trackId !== lyricsTrackId) {
            if (store.getState().ui.layout.lyricsStart === "MaxiPlayer") {
                dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Open } });
            }
            dispatch({
                type: SET_DESKTOP_LYRICS_VISIBLE,
                payload: {
                    open: false
                }
            });
        }
    });

    // disable autoscroll when receiving a scroll event from the user
    const pageRef = useRefUpdate<HTMLDivElement>();
    useLayoutEffect(() => {
        const element = pageRef.current;
        if (!element) return undefined;
        const scrollListener = () => {
            // since scroll events dont indicate if they are triggered by the user or scrollIntoView(),
            // we have to use a timer to wait until we know scrollIntoView() is done.
            if (!internalState.isAutoscrolling) {
                internalState.enableAutoscroll = false;
            }
        };
        const wheelListener = () => {
            internalState.enableAutoscroll = false;
        };
        element.addEventListener("scroll", scrollListener)
        element.addEventListener("wheel", wheelListener)
        return () => {
            element.removeEventListener("scroll", scrollListener)
            element.removeEventListener("wheel", wheelListener)
        };
    });
    // autoscroll when new lyric is focused
    useLayoutEffect(() => {
        if (isLyricsForCurrentTrack && internalState.enableAutoscroll) {
            if (internalState.autoscrollTimer) clearTimeout(internalState.autoscrollTimer);
            internalState.isAutoscrolling = true;
            internalState.autoscrollTimer = window.setTimeout(() => {
                // wait 2 seconds until scrollIntoView() finishes, as it provides no event/callback.
                internalState.isAutoscrolling = false;
            }, 2000);

            document.getElementById("activelyric")?.scrollIntoView({ block: "center", behavior: "smooth" }); // todo: test on other browsers, and make a polyfill if they dont work
        }
    }, [isLyricsForCurrentTrack, activeIndex, internalState]);

    function getClassForLine(index: number, activeIndex: number | undefined, line: LyricLine) {
        let result = (synchronizationType == SynchronizationType.None)
            ? undefined
            : isLyricsForCurrentTrack
                ? (isLineActive(positionMs, line.fromMs, line.toMs) ? "active" : (index <= (activeIndex ?? 0) ? "past" : undefined))
                : undefined;
        if (!line?.text) {
            result = result ? (`${result} spacer`) : "spacer";
        }
        return result;
    }

    const onToggleLyrics = useCallback(() => toggleLyrics("MaxiPlayer"), [])

    // todo: when clicking a lyric, have a constant to seek to a fraction of a second after it? or to round up or something
    // todo: guard against momentarily highlighting a line after seeking; only activate it if there's more than a threshold of milliseconds left in it
    // todo: test scroll on other browsers, and make a polyfill if they dont work

    return (
        <div className="lyrics gil" ref={pageRef}>
            <div className="buttons">
                <Button design={ButtonDesign.LightBig} className="close" icon={IconName.Close} onClick={onToggleLyrics} />
            </div>
            <div className="gradient">
                <div />
            </div>
            <div className="column">
                {
                    !loading && lines &&
                    <div className={`lines${(!isLyricsForCurrentTrack || synchronizationType == SynchronizationType.None) ? " nosync" : ""}`}>
                        {
                            lines.map((line, index) => (
                                <p id={(isLyricsForCurrentTrack && (index === activeIndex || (index == 0 && activeIndex == -2))) ? "activelyric" : undefined} className={getClassForLine(index, activeIndex, line)} onClick={onClick}>{line.text || <Fragment />}</p>
                            ))
                        }
                    </div>
                }
            </div>
            {
                !loading && lines && (author || publisher || provider) &&
                <div className="column credits">
                    {
                        author &&
                        <div>{translate("LyricsWriter")?.replace("${Writer}", author)}</div>
                    }
                    {
                        publisher &&
                        <div>{translate("LyricsPublisher")?.replace("${Writer}", publisher)}</div>
                    }
                    {
                        provider &&
                        <div>{provider}</div>
                    }
                </div>
            }
            {
                !loading && !lines &&
                <div className="centered">
                    <p>&#9834;</p>
                    {translate("SearchNoResult") /* this is strictly not the right string, but its for a case that should never happen */}
                </div>
            }
            {
                loading &&
                <div className="centered">
                    <PageLoadSpinner />
                </div>
            }
        </div>
    );
}

function isLineActive(position: number | undefined, from: number | undefined, to: number | undefined) {
    if (position === undefined || from === undefined || to === undefined) return false;
    return position >= from && position < to;
}

function findActiveIndex(positionMs: number, lines: Array<LyricLine> | null) {
    if (!lines) return -1;
    let result = lines.findIndex(line => isLineActive(positionMs, line.fromMs, line.toMs));
    if (result == -1) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const last = (lines as any).findLast?.((n: LyricLine) => (n.fromMs ?? 0) > 0 && ((n.fromMs ?? 0) < positionMs)) as LyricLine | undefined;
        if (last) {
            if (positionMs > (last.fromMs || 0))
                result = lines.indexOf(last);
        }
    }
    if (result == -1) {
        const firstIndex = lines.findIndex?.((n: LyricLine) => (n.fromMs ?? 0) > positionMs);
        if (firstIndex !== -1) {
            result = -2; // special "first" value
        }
    }
    return result;
}
