import { StateUpdater, useEffect, useState } from "preact/hooks";
import { isEqual } from "services/arrayHelper";


export function useMemoAsync<T>(factory: () => Promise<T> | null, deps: unknown[] = [], refreshDeps: unknown[]|null = null) {
    /*
    const [refresh, setRefreshDeps] = useState<unknown[] | null>(refreshDeps);
    const [value, setValue] = useState<T | null>(null);

    useEffect(() => {
        if(refresh === null || refreshDeps === null || !isEqual(refresh, refreshDeps)) {
            setRefreshDeps(refreshDeps);
            setValue(null);
        }
        factory()?.then(setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
    */
    const [value] = useMemoAsync2(factory, deps, refreshDeps);
    return value;
}


export function useMemoAsync2<T>(factory: () => Promise<T> | null, deps: unknown[] = [], refreshDeps: unknown[]|null = null): [T|null, StateUpdater<T|null>] {
    const [refresh, setRefreshDeps] = useState<unknown[] | null>(refreshDeps);
    const [value, setValue] = useState<T | null>(null);

    useEffect(() => {
        if(refresh === null || refreshDeps === null || !isEqual(refresh, refreshDeps)) {
            setRefreshDeps(refreshDeps);
            setValue(null);
        }
        factory()?.then(setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return [value, setValue];
}
