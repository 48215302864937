import type { ComponentChild } from "preact";
import { h } from "preact";
import { useTranslations } from "global/config";
import { replaceStringsWithCompoenents } from "services/configCMS/configCMS";
import { isNullOrEmpty } from "services/stringHelper";
import { Button, ButtonDesign } from "components/atoms/controls/button";

let _hasPrivacyLabel: boolean | null;
export function useHasPrivacyLabel(): boolean {
    const translations = useTranslations();
    if (_hasPrivacyLabel == null) _hasPrivacyLabel = !isNullOrEmpty(translations.FeedbackModalPrivacyTextWeb);
    return _hasPrivacyLabel;
}

export const InAppFeedbackPrivacyLabel = () => {
    const translations = useTranslations();
    let components: ComponentChild[] = [translations.FeedbackModalPrivacyTextWeb];

    const link1 = (
        <Button design={ButtonDesign.TextBrandInline} externalUrl={translations.FeedbackModalPrivacyAction1UrlWeb}>
            {translations.FeedbackModalPrivacyAction1Web}
        </Button>
    );

    const link2 = (
        <Button design={ButtonDesign.TextBrandInline} externalUrl={translations.FeedbackModalPrivacyAction2UrlWeb}>
            {translations.FeedbackModalPrivacyAction2Web}
        </Button>
    );

    components = replaceStringsWithCompoenents(components, "{link1}", link1);
    components = replaceStringsWithCompoenents(components, "{link2}", link2);

    return <div>{components}</div>;
};
