import { h } from "preact";
import { Modal, modalButtonSubmitOK, openModal } from "..";
import { useTranslations } from "global/config";

export async function showErrorModal() {
    return openModal(<ErrorModal />);
}

export async function showErrorModal2(title: string, subtitle: string) {
    return openModal(<Modal title={title} text={subtitle} buttons={[modalButtonSubmitOK()]} />);
}

export function ErrorModal() {
    const translations = useTranslations();
    return <Modal title={translations.FeedbackFailedTitle} text={translations.DialogBoxErrorText} buttons={[modalButtonSubmitOK()]} />;
}
