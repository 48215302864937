import { h, Fragment } from "preact";
import { useState, useLayoutEffect, useEffect, useRef, useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import "./PlayerMaxi.scss";
import { dispatch } from "global";
import { PLAYER_SEEK, SET_MAXIPLAYER_OPEN, TOGGLE_MAXIPLAYER_QUEUE_OPEN } from "global/actions";
import { animations } from "global/constants";
import { useMessageBus } from "global/hooks";
import type { RootModel } from "models/app";
import { MobileMaxiPlayerOpen } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { ContentType } from "models/ModelType";
import {
    useDelay,
    DelayType,
    useCurrentAudioSecondaryResourceFromState,
    useCurrentLanePrimaryResourceFromState,
    useSectionContext,
    usePreviewContext
} from "components/shared/hooks";
import { CoverSize, ResourceCover } from "components/atoms/cover";
import { PlayableLoadSpinner } from "components/atoms/spinner";
import { MaxiPlayerTopBar } from "components/molecules/pageTopBar/mobile/topBars";
import { MaxiPlayerPrimaryControl } from "components/molecules/playerPrimaryControl";
import { MaxiPlayerSecondaryControl } from "components/molecules/playerSecondaryControl";
import { MaxiPlayerTrackControl } from "components/molecules/playerTrackControl";
import SeekBar from "components/molecules/seekBar";
import { Queue } from "components/organisms/queue";

// todo: move this one to something similar to bottom sheet
// todo this component should be simplified/refactored
export const PlayerMaxi = () => {
    // const preview = usePreviewContext(resource.contentType, resource, context, null);
    const openFromState = useSelector((root: RootModel) => root.ui.layout.mobileMaxiPlayerOpen);
    const [open, setOpen] = useState(false);
    const [showQueue, setShowQueue] = useState(false);
    const toggleQueue = useCallback(() => setShowQueue(!showQueue), [showQueue]);

    useLayoutEffect(() => {
        switch (openFromState) {
            case MobileMaxiPlayerOpen.Open: {
                setOpen(true);
                break;
            }
            case MobileMaxiPlayerOpen.Closed: {
                setOpen(false);
                setTimeout(() => setShowQueue(false), animations.durationLong);
                break;
            }
        }
    }, [openFromState]);

    useMessageBus("SET_DESKTOP_LYRICS_VISIBLE", msg => {
        if (msg.payload.open) {
            dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });
        }
    });

    const seekChange = (percent: number) => dispatch({ type: PLAYER_SEEK, payload: { percent, context: { action: AudioContextAction.UserPlayerSeek, trace: null } } });
    const closeMaxiPlayer = useCallback(() => {
        dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Closed } });
    }, []);
    const [closedDelay, setClosedDelay] = useState(false);

    useEffect(() => {
        setClosedDelay(false);
        if (open) return;
        const timeOutId = window.setTimeout(() => setClosedDelay(true), animations.durationLong);
        return () => window.clearTimeout(timeOutId);
    }, [open]);

    const closed = closedDelay && !open;
    const openDelay = useDelay({ delay: { type: DelayType.TimeOut, msec: 0 }, reset: closed }) && open;

    const containerRef = useRef<HTMLDivElement>(null);
    const playerRef = useRef<HTMLDivElement>(null);

    const { resource, resourceType } = useCurrentAudioSecondaryResourceFromState();
    const { resource: imageResource, type: imageType } = useCurrentLanePrimaryResourceFromState();

    const sectionContext = useSectionContext(ContentType.Player, null, null, null, null);
    const context = usePreviewContext(resourceType, resource, sectionContext, null);
    const imageContext = usePreviewContext(imageType, imageResource, sectionContext, null);

    useMessageBus(TOGGLE_MAXIPLAYER_QUEUE_OPEN, () => {
        toggleQueue();
    });

    if (closed) return null;

    return (
        <div ref={containerRef} className={`playerMaxi OnK  --show-${openDelay}`}>
            <div className="background" onClick={closeMaxiPlayer} />
            <div className="container">
                <div className={`player  --showQueue-${showQueue}`} ref={playerRef}>
                    <MaxiPlayerTopBar showQueue={showQueue} />
                    <div className={`playerContainer --showQueue-${showQueue}`}>
                        {!showQueue ? (
                            <>
                                <ResourceCover
                                    onClick={closeMaxiPlayer}
                                    size={CoverSize.Size512}
                                    dynamicSize
                                    disableLazyLoad
                                    rounding={undefined}
                                    context={imageContext ?? context}>
                                    <PlayableLoadSpinner />
                                </ResourceCover>
                                <MaxiPlayerTrackControl />
                                <SeekBar onChange={seekChange} />
                            </>
                        ) : (
                            <Queue onCurrentTrackClick={toggleQueue} queueOpen={showQueue} />
                        )}
                    </div>
                    <MaxiPlayerPrimaryControl showQueue={showQueue} />
                    <MaxiPlayerSecondaryControl showQueue={showQueue} />
                </div>
            </div>
        </div>
    );
};
