/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentChild, h } from "preact";
import "./Scroller.scss";
import type { MutableRef } from "preact/hooks";
import useScroller from "./hooks/useScroller";
import { getAjustedItemSize, getItemSize } from "./hooks/useScroller/index";
import { ScrollerAlign, ScrollerSize, ScrollerSnap, ScrollerPurpose, ScrollerEffect, ScrollerNavigateCallback, ScrollerNavigatePropetiesCallback } from "./options";
import { ScrollRestorationRef } from "components/shared/hooks";

export interface ScrollerProps {
    children?: ComponentChild[];
    size: ScrollerSize | number;
    align?: ScrollerAlign;
    scrollSnap?: ScrollerSnap;
    purpose?: ScrollerPurpose;
    effect?: ScrollerEffect;
    onSelectedChange?: (index: number) => void;
    index?: number;
    navigateRef?: MutableRef<ScrollerNavigateCallback | null>;
    onNavigatePropertiesChange?: ScrollerNavigatePropetiesCallback;
    restoration: ScrollRestorationRef;
}

export const Scroller = (props: ScrollerProps) => {
    const { restoration, children, scrollSnap, align = ScrollerAlign.Center, size } = props;

    const padding = align === ScrollerAlign.Center ? `calc(50% - (${getItemSize(size)}px/2))` : "";
    const olStyle = { paddingLeft: padding, paddingRight: padding };

    const { scrollIndexDecimal, containerRef, contentRef, scrollSnapFix, adjustSize, adjustTransform, selectedIndex, scrollIndex, scrollState, gap } = useScroller(props);

    return (
        <div
            className={`scroller aKT --size-${size} --align-${align} --scrollSnap-${scrollSnap} ${
                scrollSnap ? `--scrollSnapType-${scrollSnap.type} --scrollSnapStop-${scrollSnap.stop}` : ""
            } --scrollSnapFix-${scrollSnapFix} --scrollState-${scrollState} --gap-${gap}`}>
            <div
                ref={(r) => {
                    containerRef.current = r;
                    restoration.scrollRef.current = r;
                }}
                className="scroll">
                <ol
                    style={olStyle}
                    ref={(r) => {
                        contentRef.current = r;
                        restoration.contentRef.current = r;
                    }}>
                    {children &&
                        children.map((item, itemIndex) => {
                            const itemAjust1 = getAjustedItemSize(scrollIndexDecimal, itemIndex, adjustSize) / adjustSize;
                            const itemAjust2 = getAjustedItemSize(scrollIndexDecimal, itemIndex, adjustTransform) / adjustTransform;
                            const width = getItemSize(size) * itemAjust1;
                            const scale = itemAjust2;
                            return (
                                <li
                                    key={itemIndex}
                                    className={`${itemIndex === scrollIndex ? "active" : ""} ${itemIndex === selectedIndex ? "selected" : ""}`}
                                    style={{ width: `${width}px` }}>
                                    <div className="scale" style={{ transform: `scale(${scale})` }}>
                                        {item}
                                    </div>
                                </li>
                            );
                        })}
                </ol>
            </div>
        </div>
    );
};
