import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { getNavigationRoot, getNavigationRootPageAncestryLevel, getNavigationRootPreview } from "services/navigationStack/navigationStack";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { NavigationPageRootName } from "models/view/navigationStack";

export type AnalyticsRootPageProperties = {
    rootPageId?: string;
    rootPageType?: string;

    rootPageName?: NavigationPageRootName;
    rootPageAncestryLevel?: number;
};

export function getAnalyticsRootPageProperties(preview?: PreviewContextModel): AnalyticsRootPageProperties {
    let root = getNavigationRootPreview(preview)?.page ?? null;
    if (!root) root = getNavigationRoot()?.page ?? null;
    if (!root) return {};

    return {
        rootPageName: root.root ?? undefined,
        rootPageAncestryLevel: getRootPageAncestryLevel() ?? undefined,
        rootPageId: root.id ?? undefined,
        rootPageType: root.type
    };
}

function getRootPageAncestryLevel(): number | null {
    return getNavigationRootPageAncestryLevel();
}

export function convertRootPageProperties(properties: AnalyticsRootPageProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "RootPageName", convertRootPageName(properties.rootPageName ?? null));
    SetAnalyticsProp(data, "event", "RootPageAncestryLevel", properties.rootPageAncestryLevel, true);

    return { ...data, type: "AnalyticsRootPageProperties" };
}

function convertRootPageName(rootName: NavigationPageRootName | null): AnalyticsKey {
    switch (rootName) {
        case NavigationPageRootName.Radio:
            return "Stations";
        case NavigationPageRootName.Explore:
            return "Search";
        case null:
            return "Deeplink";
        case NavigationPageRootName.EndlessPlay:
        case NavigationPageRootName.NotificationFeed:
        case NavigationPageRootName.Search:
        case NavigationPageRootName.FrontPage:
        case NavigationPageRootName.Deeplink:
        case NavigationPageRootName.MyMusicAlbums:
        case NavigationPageRootName.MyMusicArtists:
        case NavigationPageRootName.MyMusicPlaylists:
        case NavigationPageRootName.MyMusicTracks:
            return rootName;
    }
}
