import { closeAllContextMenus } from "./closeAllContextMenus";
import { getContextMenuSheetChildrenLayers, isContextMenuTreeVisible } from "../helpers";
import type { OpenMenuResult } from "../open";
import { OpenMenuResultAction } from "../open";
import type { AppContextMenuModel, ContextMenuSheetModel } from "models/view/contextMenu";

export const openContextMenu = (appMenus: AppContextMenuModel, sheet: ContextMenuSheetModel, closeOnNavigation: boolean | undefined): AppContextMenuModel => {
    closeOnNavigation = appMenus.open ? (closeOnNavigation = appMenus.closeOnNavigation) : closeOnNavigation === undefined ? true : closeOnNavigation;

    const isOpen = isContextMenuTreeVisible(appMenus.open, sheet);
    const result = { action: OpenMenuResultAction.Cancel };

    if (isOpen) sheet = closeContextMenuChildren(sheet, result);
    else appMenus = closeAllContextMenus(appMenus, result);

    return { ...appMenus, open: sheet, closeOnNavigation };
};

function closeContextMenuChildren(sheet: ContextMenuSheetModel, result: OpenMenuResult): ContextMenuSheetModel {
    const layers = getContextMenuSheetChildrenLayers(sheet);
    layers.forEach((layer) => layer != sheet.layer && layer.onClose && layer.onClose(result));

    sheet.children = [];

    return sheet;
}
