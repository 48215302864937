import { isContextMenuSheetOpen } from "..";
import { OpenMenuResult } from "services/contextMenus/open";
import { AppContextMenuModel } from "models/view/contextMenu/AppContextMenuModel";
import { ContextMenuSheetModel } from "models/view/contextMenu/items/ContextMenuSheetModel";

export function closeContextMenuSheet(appMenus: AppContextMenuModel, sheet: ContextMenuSheetModel, result: OpenMenuResult): AppContextMenuModel {
    if (!isContextMenuSheetOpen(appMenus.open, sheet)) return appMenus;
    sheet.tree.layers.forEach((layer) => layer.onClose && layer.onClose(result));
    return { ...appMenus, open: null };
}
