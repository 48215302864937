import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "..";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { DefaultLogMessage, log } from "services/logger";
import { copySharingLinkToClipboard } from "services/sharing/sharing";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { IconName } from "components/atoms/icon";

interface Props {
    context: PreviewContextModel;
}

export const ShareUrlLink = ({ context }: Props) => {
    const translations = useTranslations();
    const resource = context.resource;
    const onClick = useCallback(async () => {
        if (!resource) {
            log.error({ code: "web-220224-1322", msg: DefaultLogMessage.UnexpectedNull, data: { type: context.type } });
            return;
        }
        copySharingLinkToClipboard(resource);
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    }, [resource, context.type]);

    return (
        <Link icon={IconName.ShareUrl} onClick={onClick}>
            {translations.ShareUrlAction}
        </Link>
    );
};
