import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castQueueInsertItems(request: chrome.cast.media.QueueInsertItemsRequest): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castQueueInsertItems", request, (request, success, error) => {
        media.queueInsertItems(request, success, error);
    });
}
