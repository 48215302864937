import type { ResourceModelInterface } from ".";
import type { ConnectionModel } from "./ConnectionModel";
import { createPlaylistPreviewConnectionModel } from "./ConnectionModel";
import type { PlaylistPreviewModel } from "./PlaylistModel";
import { translate } from "global/config";
import type { CombinedPlaylistsPaginationQuery } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type MyMusicPlaylistsPageData = CombinedPlaylistsPaginationQuery["me"]["playlists"];

export type MyMusicPlaylistsModel = MyMusicPlaylistsPageModel;
export type MyMusicPlaylistsPageModel = { type: DomainModelType.Page } & MyMusicPlaylists;

interface MyMusicPlaylists extends ResourceModelInterface {
    readonly title: string;
    readonly playlists: ConnectionModel<PlaylistPreviewModel> | null;
    readonly contentType: ContentType.MyMusicPlaylists;
}

export function createMyMusicPlaylistsPageModel(data: MyMusicPlaylistsPageData): MyMusicPlaylistsPageModel {
    return {
        graphType: data.__typename,
        id: "myMusicPlaylists",
        title: translate("MyMusicPlaylistsTitle"),
        cover: null,
        type: DomainModelType.Page,
        contentType: ContentType.MyMusicPlaylists,
        playlists: createPlaylistPreviewConnectionModel(data.combinedPlaylists)
    };
}
