import { useEffect } from "preact/hooks";

export enum KeyboardKeyName {
    Escape = "Escape"
}

export function usePageKey(callbackFn: (ev: KeyboardEvent) => void) {
    useEffect(() => {
        window.addEventListener("keydown", callbackFn);
        return () => window.removeEventListener("keydown", callbackFn);
    }, [callbackFn]);
}
