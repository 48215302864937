import type { AnalyticsData } from "./analyticsData";
import { combineAnalyticsData } from "./analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "./properties/app/appProperties";
import type { AnalyticsPlayerActionProperties } from "./properties/event/playerActionProperties";
import { getAnalyticsPlayerActionProperties } from "./properties/event/playerActionProperties";
import { getAnalyticsUserProperties } from "./properties/user";

export async function getAnalyticsPlayerActionEvent(props: AnalyticsPlayerActionProperties): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        getAnalyticsPlayerActionProperties(props)
    );
    return data;
}
