import { onNextMediaSessionUpdate } from "../castEvents/mediaSession/onMediaSessionUpdate";
import { castQueueJumpToItem } from "../castRequests/media/queueJumpToItem";
import { getCurrentAudioFromRemote, getCurrentAudioInSync, isCurrentAudioLastInQueue } from "../helpers";
import { getCastMediaSession, getCastPlayer, hasCastMediaSession } from "../setup";
import { getRemoteCastQueue } from "./remoteCastQueue";
import { setRemotePlayPause } from "./syncPlayState";
import { dispatch } from "global";
import { AUDIO_INPUT_PLAY_AUDIO, AUDIO_OUTPUT_AUDIO_ENDED } from "global/actions";
import { log, LogTag } from "services/logger";
import { AudioInputType } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";

export async function syncCurrentFromLocal(): Promise<boolean> {
    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: "syncCurrentFromLocal..." });

    const { currentSynced: inSync, currentLocal } = getCurrentAudioInSync();
    if (inSync || !currentLocal) return true;

    const jumpTo = currentLocal.chromecastId;
    const items = getRemoteCastQueue();

    if (items.indexOf(jumpTo) === -1) {
        log.info({ tags: [LogTag.Chromecast], code: "web-230214-1438", msg: "current not in remote queue" });
        return false;
    }

    let ok: boolean;

    ok = await setRemotePlayPause(false, null);
    if (!ok) log.error({ code: "web-230214-1438", msg: "can't pause before jump" });

    ok = await castQueueJumpToItem(jumpTo);
    if (!ok) return false;

    await onNextMediaSessionUpdate(true);

    ok = await setRemotePlayPause(false, null);
    if (!ok) log.error({ code: "web-230214-1438", msg: "can't pause after jump" });

    return true;
}

export async function syncCurrentFromRemote(context: AudioContextModel): Promise<boolean> {
    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: "syncCurrentFromRemote..." });

    if (!hasCastMediaSession()) {
        log.error({ code: "web-230208-1523", msg: "no media session" });
        return false;
    }

    const { currentSynced } = getCurrentAudioInSync();
    if (currentSynced) return true;

    const media = getCastMediaSession();
    if (!media) return false;

    const currentRemote = getCurrentAudioFromRemote();
    if (!currentRemote) {
        log.error({ code: "web-230208-1523", msg: "no current audio from remote" });
        return false;
    }

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `...syncCurrentFromRemote: ${currentRemote.logTitle}` });

    await dispatch({ type: AUDIO_INPUT_PLAY_AUDIO, payload: { audio: currentRemote, context } });
    return true;
}

export async function handleLastInQueueAndStopped(context: AudioContextModel): Promise<boolean> {
    const player = getCastPlayer();
    const stopped = player?.playerState === chrome.cast.media.PlayerState.IDLE;

    if (!stopped) return false;

    const { last, currentAudio } = isCurrentAudioLastInQueue();

    if (!last) return false;
    if (!currentAudio) return false;
    if (currentAudio.input !== AudioInputType.PlayQueue) return false;

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `current is last in queue and remote is idle` });

    await dispatch({ type: AUDIO_OUTPUT_AUDIO_ENDED, payload: { audioId: currentAudio.audioId, context } });

    return true;
}
