import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import type { AnalyticsPlayableErrorProperties, AnalyticsPlayableLoadProperties, AnalyticsPlayableProperties } from "../properties/event";
import {
    convertResourceParentProperties,
    getAnalyticsNavigationProperties,
    convertPlayableErrorProperties,
    convertPlayableLoadProperties,
    convertPlayableProperties
} from "../properties/event";
import type { AnalyticsQueueAddedPropertiesGroup } from "../properties/groups/queueAddedPropertiesGroup";
import { getAnalyticsUserProperties } from "../properties/user";
import { getAnalyticsUserPlayErrorProperties } from "../properties/user/userPlayErrorProperties";

export async function getAnalyticsPlayedContentErrorEvent(
    playableData: AnalyticsPlayableProperties,
    queueData: AnalyticsQueueAddedPropertiesGroup,
    loadData: AnalyticsPlayableLoadProperties,
    stopData: AnalyticsPlayableErrorProperties
): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertPlayableProperties(playableData),
        convertPlayableLoadProperties(loadData),
        convertPlayableErrorProperties(stopData),
        convertResourceParentProperties(queueData.resourceParentProperties),
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsNavigationProperties(queueData.pageProperties, queueData.rootPageProperties, queueData.rootSectionProperties),
        getAnalyticsUserProperties(),
        getAnalyticsUserPlayErrorProperties()
    );
    return data;
}
