import { getLanguageCodeFromState } from "services/app";

export function combineStrings(values: (string | null | undefined)[], seperator?: string): string {
    let str = "";
    values.forEach((value) => {
        str = addToStringWithSeperator(str, value, seperator);
    });
    return str;
}

export function addToStringWithSeperator(str: string, add: string | null | undefined, seperator?: string): string {
    if (str == null || str == "") return add ?? "";
    if (add == null || str == "") return str;

    if (seperator == undefined) return str + add;
    else return str + seperator + add;
}

export function toLocaleStringUsingLocalization(value: number): string {
    const locale = getLanguageCodeFromState();
    return value.toLocaleString(locale);
}

export function replaceAllBackwardsCompatible(value: string, search: string, replace: string) {
    return value.split(search).join(replace);
}

export function capitalizeString(value: string) {
    if (value == null || value.length == 0) return value;
    return value[0].toUpperCase() + value.slice(1);
}
