import type { AlbumLinkModel } from "./AlbumModel";
import { createAlbumLinkModel } from "./AlbumModel";
import type { ArtistLinkModel } from "./ArtistModel";
import { createArtistLinkModel, createFeaturedArtistOnTrackLinkModel } from "./ArtistModel";
import type { PlayQueuePlayableModelInterface, TrackParentModel } from "./PlayQueuePlayableModel";
import type { TrackRadioLinkModel } from "./TrackRadioModel";
import { createTrackRadioLinkModel } from "./TrackRadioModel";
import { getNextUUID } from "services/utils";
import type { TrackPreviewFragment, TrackWithoutAlbumPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

export type TrackPreviewData = TrackPreviewFragment;
export type TrackWithoutAlbumPreviewData = TrackWithoutAlbumPreviewFragment;

export type TrackModel = TrackPreviewModel;
export type TrackPreviewModel = TrackPreview;

interface Track extends PlayQueuePlayableModelInterface {
    readonly uniqueId: string;
    readonly contentType: ContentType.Track;
    readonly position: number;
    readonly title: string;
    readonly duration: number;
    readonly playbackReportContext: string;
    readonly availableInSubscription: boolean;
    readonly sharingUrl: string | null;
    readonly genre: string;
    readonly artist: ArtistLinkModel;
    readonly featuredArtists: ArtistLinkModel[] | null;
    readonly trackRadio: TrackRadioLinkModel;
    readonly album: AlbumLinkModel;
}

interface TrackPreview extends Track {
    type: DomainModelType.Preview;
}

function createTrack(data: TrackWithoutAlbumPreviewData, cover: string | null, position: number, album: AlbumLinkModel): Track {
    return {
        graphType: data.__typename,
        id: data.id,
        uniqueId: getNextUUID(),
        availableInSubscription: data.availableToStream,
        title: data.title,
        duration: data.duration,
        position,
        cover,
        playbackReportContext: data.playbackContext,
        trackCount: 1,
        sharingUrl: null,
        genre: data.genre,
        contentType: ContentType.Track,
        artist: createArtistLinkModel(data.artist),
        featuredArtists: createFeaturedArtistOnTrackLinkModel(data),
        trackRadio: createTrackRadioLinkModel(data),
        album
    };
}

export function createTrackPreviewModel(data: TrackPreviewData, position: number): TrackPreviewModel {
    const album = createAlbumLinkModel(data.album);
    return {
        ...createTrack(data, data.cover ?? null, position, album),
        type: DomainModelType.Preview
    };
}

export function createTrackNoAlbumPreviewModel(
    data: TrackWithoutAlbumPreviewData,
    cover: string | null,
    album: { id: string; title: string },
    position: number
): TrackPreviewModel {
    const _album = createAlbumLinkModel({ ...album, __typename: "CatalogAlbum" }); //TODO: is typename correct?
    return {
        ...createTrack(data, cover, position, _album),
        type: DomainModelType.Preview
    };
}

export function getComparableTrackPlayableId(track: TrackPreviewModel, parent: TrackParentModel) {
    return `${parent.contentType}_${parent.id}_${track.position}_${track.id}`;
}

export function getComparableTrackId(track: TrackPreviewModel) {
    return `${track.position}_${track.id}`;
}
