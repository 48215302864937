import { useSelector } from "react-redux";
import { store } from "global/config";
import { RootModel } from "models/app";

export function useQueueProperties() {
    const queue = useSelector((root: RootModel) => root.queue);
    const hasItems = queue.playQueue.length > 0;

    return { hasItems };
}
export function getQueueProperties() {
    const queue = store.getState().queue;
    const hasItems = queue.playQueue.length > 0;

    return { hasItems };
}
