import { ComponentChild } from "preact";

export function replaceStringsWithCompoenents(array: ComponentChild[], search: string, replace: ComponentChild): ComponentChild[] {
    return array
        .map((component) => {
            if (typeof component !== "string") return component;
            const split = component.split(search);
            if (split.length != 2) return component;
            return [split[0], replace, split[1]];
        })
        .flat();
}
