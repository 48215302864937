import { h } from "preact";
import { Modal, modalButtonSubmitOK, openModal } from "..";
import { WebAppBrand } from "shared/models";
import { environment } from "services/environment";
import { Button, ButtonDesign } from "components/atoms/controls/button";

export async function showChromecastNotAvailableOnPWA() {
    return openModal(<ChromecastNotAvailableOnPWA />);
}

export function ChromecastNotAvailableOnPWA() {
    const productName = `${environment.webAppBrand} Musik`;
    const url = environment.webAppBrand === WebAppBrand.YouSee ? "https://musik.yousee.dk" : "https://musik.telmore.dk";
    const urlName = environment.webAppBrand === WebAppBrand.YouSee ? "musik.yousee.dk" : "musik.telmore.dk";

    return (
        <Modal buttons={[modalButtonSubmitOK()]} className="chromecastNotAvailableOnPWA" title={"Chromecast ikke tilgængelig"}>
            <p>På nuværende tidspunkt er Chromecast ikke tilgængelig på {productName} desktop-app.</p>
            <p>
                Du kan benytte Chromecast på&nbsp;
                <Button design={ButtonDesign.TextBrandInline} externalUrl={url}>
                    {urlName}
                </Button>
            </p>
            <p>Beklager ulejligheden! Vi arbejder på at løse problemet.</p>
        </Modal>
    );
}
