import { createLiveRadioTrackPreviewConnectionModel } from "./ConnectionModel";
import type { LiveRadioTrackPreviewModel, LiveRadioPlayTracks } from "./LiveRadioModel";
import { createTrackPreviewModel } from "./TrackModel";
import type { LiveRadioNowAndRecentlyPlayedQuery, TrackPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type LiveRadioTracksData = NonNullable<LiveRadioNowAndRecentlyPlayedQuery["liveRadios"]["radio"]>;
type LiveRadioTrackData = TrackPreviewFragment;

export type LiveRadioTrackModel = LiveRadioTrackPreviewModel;

export function createLiveRadioPlayTracks(data: LiveRadioTracksData): LiveRadioPlayTracks {
    return {
        nowPlaying: data.tracks?.nowPlaying ? createLiveRadioTrackPreviewModel(data.tracks?.nowPlaying, 0) : null,
        recentlyPlayedTracks: data.tracks?.recentlyPlayed ? createLiveRadioTrackPreviewConnectionModel(data.tracks.recentlyPlayed) : null
    };
}
export function createLiveRadioTrackPreviewModel(data: LiveRadioTrackData, position: number): LiveRadioTrackPreviewModel {
    return {
        graphType: data.__typename,
        id: data.id,
        cover: data.cover ?? null,
        contentType: ContentType.LiveRadioTrack,
        type: DomainModelType.Preview,
        track: createTrackPreviewModel(data, position),
        trackCount: 1
    };
}
