import type { ActionSource } from "./ActionSource";
import { createAnalyticsData, SetAnalyticsProp } from "../../analyticsData";
import type { AnalyticsKeys } from "generated/analyticsKeys";

type Type = "Play" | "Pause" | "TrackSeek" | "Mute" | "Unmute" | "ExpandVolume" | "VolumeSeek";
export type PlayerActionType = keyof Pick<AnalyticsKeys, Type>;

export type AnalyticsPlayerActionProperties = {
    actionType: PlayerActionType;
    actionSource: ActionSource;
    IsViewingQueue: boolean;
    trackProgress: number;
    trackDuration: number;
    volumeLevel?: number;
};

export function getAnalyticsPlayerActionProperties({ actionType, actionSource, IsViewingQueue, trackProgress, volumeLevel, trackDuration }: AnalyticsPlayerActionProperties) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "ActionType", actionType, true);
    SetAnalyticsProp(data, "event", "ActionSource", actionSource, true);
    SetAnalyticsProp(data, "event", "IsViewingQueue", IsViewingQueue);
    SetAnalyticsProp(data, "event", "TrackProgress", trackProgress);
    SetAnalyticsProp(data, "event", "TrackDuration", trackDuration);
    if (volumeLevel) SetAnalyticsProp(data, "event", "VolumeLevel", volumeLevel);

    return data;
}
