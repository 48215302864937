import { mutateSetActivePlaybackStream } from "services/backend";

let _currentPlaybackStreamTicket: string | null = null;

export const currentPlaybackStreamTicket = () => _currentPlaybackStreamTicket;
export async function setActivePlayback() {
    _currentPlaybackStreamTicket = (await mutateSetActivePlaybackStream())?.ticket ?? null;
}
//TODO: maybe delete later?
// function getFriendlyDeviceName(): string {
//     const name = getBrowserName();
//     if (name == BrowserName.InternetExplorer) return translateWithReplacement("ConcurrencyBrowserNameWeb", "Name", "Internet Explorer");
//     if (name == BrowserName.Unknown) return translate("ConcurrencyBrowserNameUnknownWeb");
//     return translateWithReplacement("ConcurrencyBrowserNameWeb", "Name", name);
// }
