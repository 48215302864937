import type { AlbumPreviewModel } from "./AlbumModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import type { AlbumArtistAlbumsSectionFragment, AlbumArtistAlbumsPageFragment } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type AlbumArtistAlbumsSectionData = AlbumArtistAlbumsSectionFragment;
type AlbumArtistAlbumsPageData = AlbumArtistAlbumsPageFragment;

export type AlbumArtistAlbumsModel = AlbumArtistAlbumsSectionModel;
export type AlbumArtistAlbumsSectionModel = ({ type: DomainModelType.Section } & AlbumArtistAlbums) | AlbumArtistAlbumsPageModel;
export type AlbumArtistAlbumsPageModel = { type: DomainModelType.Page } & AlbumArtistAlbums;

interface AlbumArtistAlbums extends ResourceModelInterface {
    readonly artistName: string;
    readonly albums: ConnectionModel<AlbumPreviewModel> | null;
    readonly contentType: ContentType.AlbumArtistAlbums;
}

function createAlbumArtistAlbums(data: AlbumArtistAlbumsSectionData | AlbumArtistAlbumsPageData, removeSelf?: boolean): AlbumArtistAlbums {
    const albums = data.artist.albums ? createAlbumPreviewConnectionModel(data.artist.albums) : null;
    if (albums && removeSelf) {
        albums.items = albums.items.filter((item) => item.id !== data.id);
    }
    return {
        graphType: data.__typename,
        contentType: ContentType.AlbumArtistAlbums,
        id: data.id,
        cover: null,
        artistName: data.artist.title,
        albums
    };
}
export function createAlbumArtistAlbumsSectionModel(data: AlbumArtistAlbumsSectionData, removeSelf: boolean): AlbumArtistAlbumsSectionModel {
    return {
        ...createAlbumArtistAlbums(data, removeSelf),
        type: DomainModelType.Section
    };
}

export function createAlbumArtistAlbumsPageModel(data: AlbumArtistAlbumsPageData): AlbumArtistAlbumsPageModel {
    return {
        ...createAlbumArtistAlbums(data),
        type: DomainModelType.Page
    };
}
