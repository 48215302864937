import { getQueueTrackFromAudio } from "./queueTrack";
import { getTrackParent } from "./trackParents";
import { store } from "global";
import { DefaultLogMessage, log } from "services/logger";
import type { AudioInputItemPlayQueueModel, QueueTrackModel } from "models/app/player/input";
import type { TrackPlayablePreviewModel } from "models/domain";
import { createTrackPlayablePreview } from "models/domain";

export function getQueueTrackPlayable(queueTrack: QueueTrackModel): TrackPlayablePreviewModel | null {
    const trackParents = store.getState().queue.trackParents;
    if (trackParents.length === 0) {
        log.error({ code: "web-220218-1403", msg: DefaultLogMessage.UnexpectedValue });
        return null;
    }
    const parent = getTrackParent(queueTrack.parentId, trackParents);
    if (!parent) {
        log.error({ code: "web-210316-1957", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    return createTrackPlayablePreview(queueTrack.track, parent);
}

export function getQueueTrackPlayableFromAudio(audio: AudioInputItemPlayQueueModel): TrackPlayablePreviewModel | null {
    const track = getQueueTrackFromAudio(audio);
    if (!track) return null;

    const playable = getQueueTrackPlayable(track);
    return playable;
}
