import { h } from "preact";
import { Sheet } from "..";
import { FavoritesSortingLink } from "../links";
import { SortingMenuTop } from "./tops";
import { useTranslations } from "global/config";
import { FavoriteTracksSorting } from "models/domain";

export const MyMusicTracksSortingContextMenu = () => {
    const translations = useTranslations();
    return (
        <Sheet top={<SortingMenuTop />}>
            <FavoritesSortingLink title={translations.SortByLastAdded} type="tracksSorting" sorting={FavoriteTracksSorting.LatestAdded} />
            <FavoritesSortingLink title={translations.SortByNameTitle} type="tracksSorting" sorting={FavoriteTracksSorting.Alphabetically} />
            <FavoritesSortingLink title={translations.SortByArtist} type="tracksSorting" sorting={FavoriteTracksSorting.ArtistName} />
            <FavoritesSortingLink title={translations.SortByReleaseYear} type="tracksSorting" sorting={FavoriteTracksSorting.ReleaseDate} />
        </Sheet>
    );
};
