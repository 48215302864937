import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import "./VolumeRange.scss";
import { dispatch } from "global";
import { PLAYER_SET_MUTE, PLAYER_SET_VOLUME } from "global/actions";
import { getVolumeIcon } from "services/player/controllers/service/helpers/icons";
import type { RootModel } from "models/app";
import { BasicRange } from "components/atoms/controls/basicRange";
import { Button, ButtonDesign } from "components/atoms/controls/button";

interface Props {
    inline?: boolean;
}
export const VolumeRange = ({ inline }: Props) => {
    const volume = useSelector((root: RootModel) => root.player.volume);
    const muted = useSelector((root: RootModel) => root.player.muted);
    const volumeLevel = muted ? 0 : volume * 100;

    const toggleMute = useCallback(() => dispatch({ type: PLAYER_SET_MUTE, payload: {} }), []);
    const setVolume = useCallback((percent: number) => dispatch({ type: PLAYER_SET_VOLUME, payload: { volume: percent / 100 } }), []);

    return (
        <div className={`volumeRange dbe`}>
            <Button
                design={ButtonDesign.DefaultBig}
                onClick={toggleMute}
                icon={getVolumeIcon(volume, muted)}
                round
                inline={inline ? { left: true, top: true, bottom: true } : undefined}
            />
            <BasicRange value={volumeLevel} onChange={setVolume} />
        </div>
    );
};
