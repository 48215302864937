import { h } from "preact";
import "./Toast.scss";
import { closeToast } from "./AppToast";
import { translate } from "global/config";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { Icon, IconName } from "components/atoms/icon";

export enum ToastState {
    Success,
    Fail
}

export interface Props {
    state: ToastState;
    children: string;
    onCancel?: () => void;
    cancelText?: string;
}

export const Toast = ({ state, children, onCancel, cancelText }: Props) => {
    const iconName = (() => {
        switch (state) {
            case ToastState.Fail:
                return IconName.Error;
            case ToastState.Success:
                return IconName.Checkmark;
        }
    })();

    const onCancelFn =
        onCancel &&
        (() => {
            closeToast();
            onCancel();
        });

    return (
        <div className={`toast J25`}>
            <div>
                <Icon icon={iconName} />
                <div className="text">{children}</div>
                {onCancelFn && (
                    <Button design={ButtonDesign.LightMicro} onClick={onCancelFn}>
                        {cancelText ?? translate("ToastUndo")}
                    </Button>
                )}
            </div>
        </div>
    );
};
