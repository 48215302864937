import type { Action } from "../actions";
import { REHYDRATE, CAST_SET_AVAILABLE } from "global/actions";
import type { CastModel } from "models/app";

const initialState: CastModel = {
    isAvailable: false,
};

export function castReducer(state: CastModel = initialState, action: Action): CastModel {
    switch (action.type) {
        case CAST_SET_AVAILABLE: {
            const { isAvailable } = action.payload;
            return { ...state, isAvailable };
        }
        case REHYDRATE: {
            const cast = action.payload?.cast ?? null;
            if (cast == null) return initialState;
            return { ...cast, isAvailable: false };
        }
        default:
            return state;
    }
}
