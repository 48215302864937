import { createAlbumPreviewModel } from "./AlbumModel";
import { createArtistPreviewModel } from "./ArtistModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createExternalLinkModel } from "./ExternalLinkModel";
import { createLayoutPagePreviewModel } from "./layoutPage";
import { createLiveRadioPreviewModel } from "./LiveRadioModel";
import { createMixRadioCategoryPreviewModel } from "./MixRadioCategoryModel";
import { createMixRadioPreviewModel, MixRadioLinkParentType } from "./MixRadioModel";
import { createPlaylistCategoryPreviewModel } from "./PlaylistCategoryModel";
import { createPlaylistPreviewModel } from "./PlaylistModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { ResourceModelLinkInterface, ResourcePreviewModel } from "./ResourceModel";
import { createTrackPreviewModel, type TrackPreviewModel } from "./TrackModel";
import type {
    BannerLinkFragment,
    BannerPreviewFragment
} from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";
//
type BannerLinkData = BannerLinkFragment;
type BannerPreviewData = BannerPreviewFragment;

export type BannerModel = BannerLinkModel;
export type BannerLinkModel = ({ type: DomainModelType.Link } & BannerLink) | BannerPreviewModel;
export type BannerPreviewModel = ({ type: DomainModelType.Preview } & BannerPreview) | BannerPlayModel;
export type BannerPlayModel = ({ type: DomainModelType.Play } & BannerPlay) | BannerPageModel;
export type BannerPageModel = { type: DomainModelType.Page } & BannerPage;

interface BannerLink extends ResourceModelLinkInterface {
    readonly contentType: ContentType.BannerLayoutItemContent; // todo?
    readonly name: string;
}

interface Banner extends BannerLink, PlayQueuePlayableModelInterface {
    description: string;
    target: ResourcePreviewModel | null;
}

interface BannerPlay extends Banner {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

type BannerPage = BannerPlay

type BannerPreview = Banner;

export function createBannerPreviewModel(data: BannerPreviewData): BannerPreviewModel {
    return {
        ...createBanner(data),
        type: DomainModelType.Preview
    };
}

function createBanner(data: BannerPreviewData): Banner {
    return {
        contentType: ContentType.BannerLayoutItemContent,
        graphType: data.__typename,
        id: data.id,
        name: data.name,
        description: data.description,
        target: createBannerTarget(data),
        cover: data.cover ?? null,
        trackCount: null,
    };
}

function createBannerTarget(data: BannerPreviewData): ResourcePreviewModel | null {
    let target: ResourcePreviewModel | null = null;
    switch (data.target?.__typename) {
        case "LiveRadioBannerTarget":
            if (data.target.liveRadio) {
                target = createLiveRadioPreviewModel(data.target.liveRadio)
            }
            break;
        case "MixRadioBannerTarget":
            if (data.target.mixRadio) {
                target = createMixRadioPreviewModel(data.target.mixRadio, { type: MixRadioLinkParentType.MixRadios })
            }
            break;
        case "AlbumBannerTarget":
            if (data.target.album) {
                target = createAlbumPreviewModel(data.target.album);
            }
            break;
        case "ArtistBannerTarget":
            if (data.target.artist) {
                target = createArtistPreviewModel(data.target.artist);
            }
            break;
        case "LayoutPageBannerTarget":
            if (data.target.page) {
                target = createLayoutPagePreviewModel(data.target.page.id, data.target.page.title);
            }
            break;
        case "MixRadioCategoryBannerTarget":
            if (data.target.mixRadioCategory) {
                target = createMixRadioCategoryPreviewModel(data.target.mixRadioCategory);
            }
            break;
        case "PlaylistBannerTarget":
            if (data.target.playlist) {
                target = createPlaylistPreviewModel(data.target.playlist);
            }
            break;
        case "PlaylistCategoryBannerTarget":
            if (data.target.playlistCategory) {
                //
                target = createPlaylistCategoryPreviewModel(data.target.playlistCategory);
            }
            break;
        case "TrackBannerTarget":
            if (data.target.track) {
                //target = createTrackPreviewModel(data.target.track, 0);
                // todo: single-tracks not supported yet
            }
            break;
        case "ExternalBannerTarget":
            if (data.target.externalLink) {
                target = createExternalLinkModel(data.target.externalLink)
            }
            break;
    }
    return target;
}
