import { h } from "preact";
import { pageContent } from "global/constants/pageContent";
import { useArtistRadioPage } from "services/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { useAutoPlay, usePageContext, useSectionContext, useTrackPlayables } from "components/shared/hooks";
import { ResourceSection } from "components/organisms/resourceSection";
import { ProductPageTemplate } from "components/templates/productPage";

interface Props {
    artistId: string;
}

export const ArtistRadioPage = ({ artistId }: Props) => {
    const query = useArtistRadioPage({ id: artistId, first: pageContent.artistRadioPageItemsLimit });
    const product = query.model;

    const page = usePageContext({ type: ContentType.ArtistRadio, resource: product, root: null, done: query.success });
    const top = useSectionContext(ContentType.ArtistRadio, product, page, 0, ResourceDisplayType.Top);
    const tracks = useSectionContext(ContentType.ArtistRadio, product, page, 1, ResourceDisplayType.List);

    const playables = useTrackPlayables(product?.tracks?.items ?? null, product);

    useAutoPlay({ page, type: ContentType.ArtistRadio, resource: product ?? null });

    return (
        <ProductPageTemplate key={artistId} query={query} type={ContentType.ArtistRadio} section={top} isUserOwned={false}>
            <ResourceSection resources={playables} context={tracks} />
        </ProductPageTemplate>
    );
};
