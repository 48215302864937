import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { getTrackParent } from "services/player/inputs/inputs/playQueue/helpers";
import type { RootModel } from "models/app";
import type { TrackParentModel } from "models/domain";

export const useQueueLaneTrackParentFromState = (parentId: string | null): TrackParentModel | null => {
    const trackParents = useSelector((root: RootModel) => root.queue.trackParents);
    return useMemo(() => (parentId == null ? null : getTrackParent(parentId, trackParents)), [parentId, trackParents]);
};
