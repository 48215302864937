import { DefaultLogMessage, log } from ".";
import { dispatch, messageBus } from "global";
import { LOGGING_EVENT_PLAYBACK_REPORT_FINISHED, LOGGING_EVENT_PLAYBACK_REPORT_SENT, USER_LOGGED_IN } from "global/actions";
import { getSessionFinishedPlaybackReports } from "services/appSession/operations/finishedReports";
import { mutateReportPlayback } from "services/backend";
import type { ReportPlaybackInput } from "generated/graphql-types";

declare type OnPlaybackReportFunction = ((report: ReportPlaybackInput) => void) | null;

export const trySendPlaybackReports = async () => {
    const finished = getSessionFinishedPlaybackReports();
    finished.forEach((report) => {
        const reportSent = (success: boolean) => dispatch({ type: LOGGING_EVENT_PLAYBACK_REPORT_SENT, payload: { report, success } });

        if (!report.sendReport) {
            log.error({ code: "web-211010-1459", msg: DefaultLogMessage.UnexpectedNull, data: { report } });
            reportSent(false);
        }
        if (report.url == null) {
            log.error({ code: "web-211010-1458", msg: DefaultLogMessage.UnexpectedNull, data: { report } });
            reportSent(false);
            return;
        }
        if (report.playbackContext == null) {
            log.error({ code: "web-211010-1500", msg: DefaultLogMessage.UnexpectedNull, data: { report } });
            reportSent(false);
            return;
        }
        if (report.duration <= 0) {
            log.error({ code: "web-220524-1110", msg: DefaultLogMessage.UnexpectedValue, data: { report } });
            reportSent(false);
            return;
        }

        const input: ReportPlaybackInput = {
            playbackUrl: report.url ?? "",
            playedSeconds: report.duration,
            playbackContext: report.playbackContext,
            playedAt: new Date(report.playedAt).toISOString()
        };
        mutateReportPlayback({ report: input })
            .then((success) => {
                if (success) {
                    log.info({ code: "web-211103-1908", msg: "playback report send success", data: { report } });
                    reportSent(true);
                }
            })
            .catch((e) => {
                log.error({ code: "web-210420-1209", msg: "playback report send error", error: e });
            });
    });
};

export const initPlaybackReportSendService = () => {
    try {
        trySendPlaybackReports();
    } catch (e) {
        log.error({ code: "web-220221-1333", msg: "error in sending playpack reports on init", error: e });
    }

    messageBus.subscribeEvery(LOGGING_EVENT_PLAYBACK_REPORT_FINISHED, async (msg) => {
        if (!msg.payload.report.sendReport) return;

        await messageBus.sync();
        trySendPlaybackReports();
    });

    messageBus.subscribeEvery(USER_LOGGED_IN, async () => {
        await messageBus.sync();
        trySendPlaybackReports();
    });
};
