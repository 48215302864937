import { dispatch } from "global";
import { AUDIO_INPUT_AUDIO_END_REASON } from "global/actions";
import { isAudioLoaded, isAudioLoadChange } from "services/player/helpers";
import { PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";

export async function updateAudiosEndReason(newAudios: AudioInputItemModel[], oldAudios: AudioInputItemModel[], context: AudioContextModel) {
    for (const oldAudio of oldAudios) {
        const newAudio = newAudios.find((n) => n.audioId === oldAudio.audioId) ?? null;
        if (newAudio) continue;
        await updateAudioEndReason(oldAudio, PlayState.Stopped, oldAudio.playState, context);
    }

    for (const newAudio of newAudios) {
        const oldAudio = oldAudios.find((n) => n.audioId === newAudio.audioId) ?? null;
        await updateAudioEndReason(newAudio, newAudio.playState, oldAudio?.playState ?? PlayState.Stopped, context);
    }
}

export async function updateAudioEndReason(audio: AudioInputItemModel, playStateNow: PlayState, playStateBefore: PlayState, context: AudioContextModel) {
    const loaded = isAudioLoaded(playStateNow);
    if (loaded) return;

    const change = isAudioLoadChange(playStateNow, playStateBefore);
    if (!change) return;

    // console.warn("end reason fire: " + audio.playState + ", " + playStateNow + ", " + playStateBefore);

    await dispatch({ type: AUDIO_INPUT_AUDIO_END_REASON, payload: { audio, context } });
}
