import { useEffect } from "preact/hooks";
import { useShowNativeMobileAppPrompt } from "./useShowNativeMobileAppPrompt";
import { WebAppBrand } from "shared/models";
import { environment } from "services/environment";
import { log } from "services/logger";
import { formatUrlFromUTF8 } from "services/navigation";

export function useNativeMobileAppOpen() {
    const show = useShowNativeMobileAppPrompt();

    useEffect(() => {
        if (!show) return;
        try {
            const url = getNativeMobileAppUrl();
            log.info({ code: "web-220405-1636", msg: "redirecting to native protocol...", data: { url } });
            document.location.replace(url);
        } catch (e) {
            log.info({ code: "web-220405-1636", msg: "could not redirect", error: e });
        }
    }, [show]);
}

function getNativeMobileAppUrl(): string {
    let hash = window.location.hash;
    if (hash == "#") hash = "";

    let search = window.location.search;
    if (search == "?") search = "";

    let url = window.location.pathname + search + hash;

    url = formatUrlFromUTF8(url);
    // url = convertToXamarinLink(url);

    if (url.indexOf("/") === 0) url = url.slice(1);
    url = getNativeMobileAppProtocol() + url;

    return url;
}

function getNativeMobileAppProtocol(): string {
    switch (environment.webAppBrand) {
        case WebAppBrand.Telmore:
            return "telmoremusik://";
        case WebAppBrand.YouSee:
            return "youseemusic://";
    }
}

// function convertToXamarinLink(value: string): string {
//     const startsWith = (search: string) => value.indexOf(search) === 0;

//     if (startsWith("/album/")) return value.replace("/album/", "/alb/");
//     if (startsWith("/anbefaling/")) return value.replace("/anbefaling/", "/rc/");
//     if (startsWith("/historik/")) return value.replace("/historik/", "/hi/");
//     if (startsWith("/kunstner/")) return value.replace("/kunstner/", "/art/");
//     if (startsWith("/live-radio/")) return value.replace("/live-radio/", "/lrad/");
//     if (startsWith("/more-music-kategori/")) return value.replace("/more-music-kategori/", "/mix/cat/");
//     if (startsWith("/more-music/")) return value.replace("/more-music/", "/mix/");
//     if (startsWith("/playliste-kategori/")) return value.replace("/playliste-kategori/", "/pl/cat/");
//     if (startsWith("/playliste/")) return value.replace("/playliste/", "/pl/");
//     if (startsWith("/sang/")) return value.replace("/sang/", "/tr/");

//     return value;
// }
