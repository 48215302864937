import { createStaticPreviewConnectionModel } from "./ConnectionModel";
import type { ConnectionModel } from "./ConnectionModel";
import type { MixRadioPreviewModel } from "./MixRadioModel";
import { createMixRadioPreviewModel, MixRadioLinkParentType } from "./MixRadioModel";
import type { ResourceModelLinkInterface, ResourceModelInterface } from "./ResourceModel";
import { getNextUUID } from "services/utils";
import type { MixRadiosPageFragment } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type MixRadiosPageData = MixRadiosPageFragment;

export type MixRadiosModel = MixRadiosPageModel;
export type MixRadiosPageModel = { type: DomainModelType.Page } & MixRadios;

interface MixRadios extends ResourceModelLinkInterface, ResourceModelInterface {
    readonly radios: ConnectionModel<MixRadioPreviewModel> | null;
    readonly contentType: ContentType.MixRadios;
    readonly title: string | null;
}

export function createMixRadiosPageModel(data: MixRadiosPageData, radioId?: string): MixRadiosPageModel {
    const radios: Map<string, MixRadioPreviewModel> = new Map();

    // only include radios from the same category as the selected radio if available, otherwise from the top category
    const category = data.categories.items?.find(n => n.radios.items?.some(nn => nn.id == radioId)) ?? data.categories.items?.[0];
    category?.radios.items?.forEach((radio) => {
        if (radios.has(radio.id)) return;
        const preview = createMixRadioPreviewModel(radio, { type: MixRadioLinkParentType.MixRadios });
        radios.set(radio.id, preview);
    });

    const items = Array.from(radios, ([_, value]) => value);
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        type: DomainModelType.Page,
        contentType: ContentType.MixRadios,
        radios: createStaticPreviewConnectionModel(items, category?.radios.items?.length ?? data.categories.totalCount ?? null),
        title: category?.title ?? null
    };
}
