import { PlayState } from "models/app/player";

export function convertToPlayState(playerState: chrome.cast.media.PlayerState | null): PlayState | null {
    if (playerState == null) {
        console.error("playerstate undefined");
        return null;
    }

    switch (playerState) {
        case chrome.cast.media.PlayerState.IDLE:
            return PlayState.Stopped;
        case chrome.cast.media.PlayerState.BUFFERING:
            return PlayState.Buffering;
        case chrome.cast.media.PlayerState.PAUSED:
            return PlayState.Paused;
        case chrome.cast.media.PlayerState.PLAYING:
            return PlayState.Playing;
    }
}
