import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { FAVORITES_UPDATED, USER_LOGGED_IN } from "global/actions";
import { messageBus, useTranslations } from "global/config";
import { getPaginationInit } from "global/constants/pagination";
import { useMessageBus } from "global/hooks";
import { getFavoriteArtists, useFavoriteArtistsPage } from "services/backend";
import { useDisplayLoggedIn } from "services/user";
import type { RootModel } from "models/app";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useCoalesced } from "components/shared/hooks";
import { MyMusicArtistsSortingContextMenu } from "components/organisms/contextMenu/menus";
import { MyMusicPageTemplate } from "components/templates/myMusicPage";

export const MyMusicArtistsPage = () => {
    const appSize = useAppMediaSize();
    const displayType = appSize === MediaSize.Mobile ? ResourceDisplayType.ListLarge : ResourceDisplayType.Grid;
    const translations = useTranslations();

    const orderBy = useSelector((root: RootModel) => root.ui.artistsSorting);
    const [criterion, setCriterion] = useState<string | null>(null);

    const skip = !useDisplayLoggedIn();
    const query = useFavoriteArtistsPage({ first: getPaginationInit(), orderBy, criterion }, skip);
    const product = query.model;
    const fetchFn = useCallback(
        (first: number, after: string | null) => (product ? getFavoriteArtists({ first, after, orderBy, criterion }) : null),
        [criterion, orderBy, product]
    );

    messageBus.subscribeEvery(USER_LOGGED_IN, (msg) => {
        if (!msg.payload.isNewUser) return;
        query.refetch(false);
    });

    const artists = useCoalesced(product?.artists ?? null);

    useMessageBus(FAVORITES_UPDATED, (message) => {
        if (message?.payload?.changes.playables.find((n) => n.contentType === ContentType.Artist)) {
            // todo: consider editing-inplace as an optimization if this was an item being removed
            query.refetch(false);
        }
    });
    return (
        <MyMusicPageTemplate
            criterion={criterion}
            onFilterChanged={setCriterion}
            placeholderTitle={translations.MyMusicArtistPlaceholderTitle}
            placeholderSubtitle={translations.MyMusicArtistPlaceholderSubtitle}
            placeholderFilter={translations.FilterArtists}
            placeholderFilterNoResult={translations.FilterNoArtistsFound}
            query={query}
            placeholderFilterNoResultSubtitle={translations.SearchNoResultHint}
            productType={ContentType.MyMusicArtists}
            resources={artists}
            resourcesFetchFn={fetchFn}
            displayType={displayType}
            sortingMenu={<MyMusicArtistsSortingContextMenu />}
        />
    );
};
