import { getCastPlayer, getCastPlayerController } from "../../setup";
import { log, LogTag } from "services/logger";

export let castSetIsMutedTimeStamp = 0;

export function castSetIsMuted(isMuted: boolean) {
    const player = getCastPlayer();
    if (!player) return;

    const playerController = getCastPlayerController();
    if (!playerController) return;

    if (isMuted === player.isMuted) return;
    castSetIsMutedTimeStamp = new Date().getTime();

    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1205", msg: `castSetIsMuted` });

    playerController.muteOrUnmute();
}
