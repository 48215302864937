import { getCurrentInputAudioItemFromState } from "../helpers";
import { getAudioSendPlaybackReport, getAudioInputReportId } from "../helpers/reporting";
import { updateAudioEndReason } from "./endReason";
import { playChange } from "./playChange";
import { updateAudios } from "./updateAudios";
import { store } from "global";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";
import { isPlayStatePlayIntent } from "services/player/helpers";
import { PlayState, AudioInputType } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";

export function onControllerOrOutputChange(context: AudioContextModel) {
    updateAudiosEndReason(context);
    updateAudiosPlaybackReport(context);
    updatePlayState(context);
}

function updateAudiosEndReason(context: AudioContextModel) {
    const audios = store.getState().audioInput.audios;
    audios.forEach((audio) => updateAudioEndReason(audio, PlayState.Stopped, audio.playState, context));
}

function updateAudiosPlaybackReport(context: AudioContextModel) {
    let audios = store.getState().audioInput.audios;
    const output = store.getState().player.output;

    audios = audios.map((audio) => ({
        ...audio,
        sendReport: getAudioSendPlaybackReport(audio.input, output, audio.input === AudioInputType.PlayQueue && audio.sample),
        reportId: getAudioInputReportId(audio.audioId, output)
    }));
    updateAudios(audios, null, context);
}

function updatePlayState(context: AudioContextModel) {
    const isChromecast = isControllerChromecastFromState();

    const audio = getCurrentInputAudioItemFromState();
    if (!audio) return;

    const isPlaying = audio && isPlayStatePlayIntent(audio.playState);
    const shouldPlay = isChromecast;

    if (isPlaying == shouldPlay) return;
    const playState = shouldPlay ? PlayState.Buffering : PlayState.Paused;

    playChange(audio, playState, null, context);
}
