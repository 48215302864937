import { getNotificationFeedConfiguration } from "./configuration";
import { isNotificationFeedEnabled } from "./helpers";
import { pollNotificationsState } from "./update";
import { messageBus } from "global";
import { USER_LOGGED_IN } from "global/actions";

export function initNotificationFeedService() {
    if (!isNotificationFeedEnabled()) return;

    pollNotificationsState();

    messageBus.subscribeEvery(USER_LOGGED_IN, () => {
        pollNotificationsState();
    });

    const interval = getNotificationFeedConfiguration().generel.update_interval;
    setInterval(() => {
        pollNotificationsState();
    }, interval);
}
