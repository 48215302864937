import { dispatch, store } from "global";
import { serviceWorkerConfig } from "shared/services/serviceWorkerConfig";
import { ENABLE_LOGTYPE, NOTIFICATION_FEED_SET_NOTIFICATIONS, UPDATE_CONTROL_PANEL } from "global/actions/actions";
import { setEnvironmentPresetFromControlPanel, setEnvironmentPropertiesFromControlPanel } from "services/environment";
import { LogTag, log } from "services/logger";
import { getNotificationFeedFromState } from "services/notificationFeed";
import type { ControlPanelModel } from "models/app";
import type { NotificationPreviewModel } from "models/domain";

function update(controlPanel: Partial<ControlPanelModel>) {
    dispatch({ type: UPDATE_CONTROL_PANEL, payload: { controlPanel } });
    return { settings: controlPanel };
}
function enableLogType(type: LogTag, enable: boolean) {
    dispatch({ type: ENABLE_LOGTYPE, payload: { type, enable } });
}

const enableAudioCachePool = (value: boolean) => update({ enableAudioCachePool: value });

const enableAudioPreload = (value: boolean) => update({ enableAudioPreload: value });

const enableFeedbackResponse = (value: boolean) => update({ enableFeedbackResponse: value });

const enableNotificationFeed = (value: boolean) => update({ enableNotificationFeed: value });

const enableNotificationFeedTestMode = (value: boolean) => update({ enableNotificationFeedTestMode: value });

async function getCacheStats() {
    const cacheGraphqlLength = (await (await caches.open(serviceWorkerConfig.cacheNameGraphql)).keys()).length;
    const cacheHlsLength = (await (await caches.open(serviceWorkerConfig.cacheNameHls)).keys()).length;
    const cacheInstallationLength = (await (await caches.open(serviceWorkerConfig.cacheNameInstallation)).keys()).length;
    const cacheNavigateLength = (await (await caches.open(serviceWorkerConfig.cacheNameNavigate)).keys()).length;

    return { cacheGraphqlLength, cacheHlsLength, cacheInstallationLength, cacheNavigateLength };
}

const getArrayOfConditionalLogTypeEnum = () => {
    return Object.keys(LogTag).map((name) => {
        return LogTag[name as keyof typeof LogTag];
    });
};

const getReduxState = () => ({ ...store.getState() });
const getReduxDump = () => JSON.stringify(store.getState());
const getReduxDumpLength = () => getReduxDump().length;
const getSettings = () => ({ settings: store.getState().controlPanel });

// settings.setNotifications( settings.getReduxState().notificationFeed.feeds[0].notifications )
const setNotifications = (notifications: NotificationPreviewModel[]) => {
    dispatch({
        type: NOTIFICATION_FEED_SET_NOTIFICATIONS,
        payload: {
            notifications,
            notificationState: null,
            notSeenCount: 0
        }
    });
};

const logPlayback = (value: boolean) => enableLogType(LogTag.Playback, value);
const logChromecast = (value: boolean) => enableLogType(LogTag.Chromecast, value);
const logRemoteConfig = (value: boolean) => enableLogType(LogTag.RemoteConfig, value);
const logDispatches = (value: boolean) => enableLogType(LogTag.Dispatch, value);
const logInfo = (value: boolean) => enableLogType(LogTag.Info, value);

const logPlaylist = (value: boolean) => enableLogType(LogTag.Playlist, value);
const logAppSetting = (value: boolean) => enableLogType(LogTag.AppSetting, value);
const logNotifications = (value: boolean) => enableLogType(LogTag.Notifications, value);

const logNavigationStack = (value: boolean) => enableLogType(LogTag.NavigationStack, value);
const logUser = (value: boolean) => enableLogType(LogTag.User, value);
const logCollection = (value: boolean) => enableLogType(LogTag.Collection, value);
const logSubscriptions = (value: boolean) => enableLogType(LogTag.Subscriptions, value);

const logAll = (value?: boolean) => {
    if (value === undefined) {
        console.log("Usage: settings.logAll(true|false)");
        return;
    }
    update({ enabledLogTypes: value ? getArrayOfConditionalLogTypeEnum() : [] });
};

const sendLog = (type: "simple" | "info" | "warn" | "error") => {
    if (type === undefined) {
        console.log('Usage: settings.logAll("simple"|"info"|"warn"|"error")');
        return;
    }
    switch (type) {
        case "simple":
            log.simple({ code: "web-221208-1527", msg: "test simple message" });
            break;
        case "info":
            log.info({ code: "web-221208-1527", msg: "test info message" });
            break;
        case "warn":
            log.warn({ code: "web-221208-1527", msg: "test warn message" });
            break;
        case "error":
        default:
            log.error({ code: "web-221208-1527", msg: "test error message" });
            break;
    }
    return "Ok.";
};

export let showDropZone: boolean;

export function initControlPanelService() {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).settings = {
            enableAudioCachePool,
            enableAudioPreload,
            enableFeedbackResponse,
            enableNotificationFeed,
            enableNotificationFeedTestMode,
            getCacheStats,
            getReduxState,
            getReduxDump,
            getReduxDumpLength,
            getSettings,
            getNotifications: getNotificationFeedFromState,
            setNotifications,
            logAll,
            logChromecast,
            logDispatches,
            logRemoteConfig,
            logInfo,
            logPlayback,
            logPlaylist,
            logAppSetting,
            logNotifications,
            logNavigationStack,
            logUser,
            logCollection,
            logSubscriptions,
            sendLog,
            setEnvironmentPreset: setEnvironmentPresetFromControlPanel,
            setEnvironmentProperties: setEnvironmentPropertiesFromControlPanel,
            showDropZone: (value: boolean) => (showDropZone = value)
        };
    } catch (e) {
        log.error({ code: "web-220218-1424", msg: "init control panel service failed", error: e });
    }
}
