import { getAppSession } from "../appSessionService";
import { isMySession, parseData, getData, saveData } from "../helpers";
import { getFinishedPlaybackReportsStorageKey } from "../storageKeys";
import type { FinishedPlaybackReport } from "models/app/playbackReport";

let finishedReports: FinishedPlaybackReport[] | null;

export function getFinishedPlaybackReports(storageId: string) {
    const mySession = isMySession(storageId);
    if (mySession && finishedReports != null) return finishedReports;

    const key = getFinishedPlaybackReportsStorageKey(storageId);
    const reports = parseData<FinishedPlaybackReport[]>(getData(key));

    if (mySession) finishedReports = reports;
    return reports ?? [];
}

export function getSessionFinishedPlaybackReports() {
    return getFinishedPlaybackReports(getAppSession().storageId);
}

export function saveFinishedPlaybackReports(storageId: string, reports: FinishedPlaybackReport[]) {
    const mySession = isMySession(storageId);
    const key = getFinishedPlaybackReportsStorageKey(storageId);

    saveData(key, JSON.stringify(reports));
    if (mySession) finishedReports = reports;
}

export function saveSessionFinishedPlaybackReports(reports: FinishedPlaybackReport[]) {
    return saveFinishedPlaybackReports(getAppSession().storageId, reports);
}

export function addSessionFinishedReport(finished: FinishedPlaybackReport) {
    if (!finished.sendReport) return;
    const reports = getSessionFinishedPlaybackReports() ?? [];
    saveSessionFinishedPlaybackReports([...reports, finished]);
}

export function removeSessionFinishedReport(finished: FinishedPlaybackReport) {
    const reports = (getSessionFinishedPlaybackReports() ?? []).filter((report) => report.reportId !== finished.reportId);
    saveSessionFinishedPlaybackReports(reports);
}

export function restoreFinishedPlaybackReports() {
    saveSessionFinishedPlaybackReports(finishedReports ?? []);
}
