import { h } from "preact";
import { useCallback } from "preact/hooks";
import { pageContent } from "global/constants/pageContent";
import { getPaginationInit } from "global/constants/pagination";
import { getArtistTracksPagination, useArtistTracksPage } from "services/backend/backend";
import { convertToTracksSortOption } from "services/sort/sortService";
import type { SortOptionsModel } from "models/app";
import { SortOption } from "models/app";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { useSortOptions } from "components/shared/hooks";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    artistId: string;
    max?: number;
    sortOptions?: SortOptionsModel;
}

const ArtistTracksShowAllPage = ({ artistId, max, sortOptions }: Props) => {
    const orderBy = convertToTracksSortOption(sortOptions ? sortOptions.selected : SortOption.Popularity);
    const query = useArtistTracksPage({ id: artistId, first: getPaginationInit(max), orderBy });
    const resource = query.model;

    const fetchFn = useCallback((first: number, after: string, index: number) => getArtistTracksPagination({ first, after, id: artistId, orderBy }, index), [artistId, orderBy]);

    return (
        <ShowAllPageTemplate
            query={query}
            type={ContentType.ArtistTracks}
            items={resource?.tracks ?? null}
            fetchFn={fetchFn}
            display={ResourceDisplayType.List}
            max={max}
            sortOptions={sortOptions}
        />
    );
};

export const ArtistPopularTracksShowAllPage = ({ artistId }: Props) => {
    const max = pageContent.artistPopularTracksShowAllPageItemsLimit;
    return <ArtistTracksShowAllPage artistId={artistId} max={max} />;
};
export const ArtistAllTracksShowAllPage = ({ artistId }: Props) => {
    const sortOptions = useSortOptions({ context: ContentType.ArtistTracks });
    return <ArtistTracksShowAllPage artistId={artistId} sortOptions={sortOptions} />;
};
