import { updatePlayQueue } from "./updatePlayQueue";
import { store } from "global";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { PlayQueueModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";

export const clearPriorityQueue = async (context: AudioContextModel) => {
    const { queue } = store.getState();
    const newQueue = removePriorityLaneFromQueue(queue);

    await updatePlayQueue(newQueue, context, false, null);
};

export function removePriorityLaneFromQueue(queue: PlayQueueModel): PlayQueueModel {
    const newPlayQueue = queue.playQueue.filter((item) => item.lane !== QueueLane.Priority);
    return { ...queue, playQueue: newPlayQueue };
}
