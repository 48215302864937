import { AppContextMenuModel } from "models/view/contextMenu";

export const hoverContextMenuItem = (appMenus: AppContextMenuModel, menuId: string, on: boolean): AppContextMenuModel => {
    const index = appMenus.hovers.indexOf(menuId);
    const wasOn = index !== -1;

    if (on === wasOn) return appMenus;

    const hovers = [...appMenus.hovers];
    if (on) hovers.push(menuId);
    else hovers.splice(index, 1);

    return { ...appMenus, hovers };
};
