import { h } from "preact";
import "./Setting.scss";

interface Props {
    title?: string;
    subtitle?: string;
    button?: h.JSX.Element;
}
export const Setting = ({ title, subtitle, button }: Props) => {
    return (
        <div className="setting e32">
            <div className="info">
                {
                    title &&
                    <div className="title">{title} </div>
                }
                {
                    subtitle &&
                    <div className="description">{subtitle}</div>
                }
            </div>
            {button}
        </div>
    );
};
