import { togglePlay, tryUnmute } from "./service/actions";
import { messageBus } from "global";
import { PLAYER_SET_VOLUME, PLAYER_TOGGLE_PLAY } from "global/actions";

export function playerControllerService() {
    messageBus.subscribeEvery(PLAYER_TOGGLE_PLAY, async (msg) => {
        togglePlay(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYER_SET_VOLUME, async () => {
        tryUnmute();
    });
}
