import { h } from "preact";
import { useState } from "preact/hooks";
import "./SeekBar.scss";
import { getShortTimeSpanFromSecondsHHMMSS } from "services/formatters/timeFormatters";
import { usePlayRange } from "components/shared/hooks";
import { useSecondsPlayedFromState } from "components/shared/hooks/useSecondsPlayed";
import { PlayRange, RangeType } from "components/atoms/controls/playRange";

interface Props {
    onChange: (percent: number) => void;
}

export const SeekBar = ({ onChange }: Props) => {
    const [seekPosition, setSeekPosition] = useState<number | null>(null);
    const hasPlayRange = usePlayRange();

    const { secondsPlayed, duration } = useSecondsPlayedFromState();

    const seconds = seekPosition || secondsPlayed;

    return (
        <div className="seekBar GxI">
            <PlayRange onChange={onChange} onSeekPositionChange={setSeekPosition} type={RangeType.MobileMaxi} />
            {hasPlayRange && (
                <div className="duration">
                    <div className="seconds-played">{getShortTimeSpanFromSecondsHHMMSS(seconds)}</div>
                    <div className="seconds-remain">{`-${getShortTimeSpanFromSecondsHHMMSS(duration - seconds)}`}</div>
                </div>
            )}
        </div>
    );
};
