import { ScrollerSize, ScrollerAlign } from "../../options";
import { getAlignOffset } from "./getAlignOffset";
import { getItemSize } from "./getItemSize";

export const getActiveItemScrollLeft = (index: number, size: ScrollerSize | number, align: ScrollerAlign, ajust: number, margin: number) => {
    const childSize = getItemSize(size);
    const offset = getAlignOffset(size, align);
    const left = (childSize / ajust + margin) * index + offset;
    return left;
};
