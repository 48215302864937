import { h } from "preact";
import { forwardRef } from "preact/compat";
import { useSelector } from "react-redux";
import "./Modal.scss";
import { dispatch, messageBus, store } from "global";
import { MODAL_CLOSE, MODAL_OPEN } from "global/actions";
import type { ModalCloseAction } from "global/actions";
import { log } from "services/logger/initLoggerService";
import type { RootModel } from "models/app";
import { ModalOpenResult } from "models/view/AppModalModel";

interface Props {
    className?: string;
}

export const AppModal = forwardRef<HTMLDialogElement, Props>(({ className }, ref) => {
    const modal = useSelector((root: RootModel) => root.modal.open);
    const open = modal != null;

    return (
        <dialog tabIndex={-1} className={`appModal DtI ${className ?? ""} --open-${open}`} ref={ref}>
            {modal}
            <div className="background" />
        </dialog>
    );
});

export async function openModal(modal: h.JSX.Element) {
    const { result } = await openModal2(modal);
    return result;
}

export async function openModal2(modal: h.JSX.Element) {
    if (isModalOpen()) await dismissModal("AppModal.openModal");

    return new Promise<ModalCloseAction["payload"]>((resolve) => {
        messageBus.subscribeOne("MODAL_CLOSE", (msg) => {
            resolve(msg.payload);
        });
        dispatch({ type: MODAL_OPEN, payload: { modal } });
    });
}

export async function submitModal(from: string, data: string | null = null) {
    closeModal(ModalOpenResult.Submit, from, data);
}

export async function dismissModal(from: string, data: string | null = null) {
    closeModal(ModalOpenResult.Dismiss, from, data);
}

async function closeModal(result: ModalOpenResult, from: string, data: string | null) {
    if (!isModalOpen()) {
        log.error({ code: "web-210722-1411", msg: "no modal open", data: { from } });
        return;
    }
    dispatch({ type: MODAL_CLOSE, payload: { result, data } });
}

export function isModalOpen() {
    return store.getState().modal.open;
}
