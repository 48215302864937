import { h } from "preact";
import "./DesktopTopBar.scss";
import type { PageTopBarProps } from "..";
import { getLink } from "services/resource";

interface Props extends PageTopBarProps {
    showRight: boolean;
}

export const DesktopTopBar = ({ page, middle: left, right, error, showRight }: Props) => {
    if (error != null) return null;

    left ??= page && getLink(page.type, page.resource)?.text;
    left = typeof left === "string" ? <h1>{left}</h1> : left;

    return (
        <div className={"desktopTopBar KlM"}>
            {<div className="left">{left && left}</div>}
            {right && showRight && <div className="right">{right}</div>}
        </div>
    );
};
