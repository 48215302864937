import type { AlbumPreviewModel, ArtistPreviewModel, PlaylistPreviewModel } from "..";
import type { BannerPreviewModel } from "../BannerModel";
import type { ChartPreviewModel } from "../ChartModel";
import type { ConnectionModel } from "../ConnectionModel";
import {
    createAlbumPreviewConnectionModel,
    createArtistPreviewConnectionModel,
    createBannerPreviewConnectionModel,
    createChartPreviewConnectionModel,
    createLiveRadioCategoryPreviewConnectionModel,
    createLiveRadioPreviewConnectionModel,
    createMixRadioPreviewConnectionModel,
    createMixedContentPreviewConnectionModel,
    createPlaylistCategoryPreviewConnectionModel,
    createPlaylistPreviewConnectionModel,
    createRecommendationPreviewConnectionModel,
    createTrackPreviewConnectionModel
} from "../ConnectionModel";
import type { LiveRadioCategoryPreviewModel } from "../LiveRadioCategoryModel";
import type { LiveRadioPreviewModel } from "../LiveRadioModel";
import type { MixedContentPreviewModel } from "../MixedContentModel";
import type { MixRadioPreviewModel } from "../MixRadioModel";
import { MixRadioLinkParentType } from "../MixRadioModel";
import type { PlaylistCategoryPreviewModel } from "../PlaylistCategoryModel";
import type { RecommendationPreviewModel } from "../RecommendationModel";
import type { TrackPreviewModel } from "../TrackModel";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import type { LayoutItemContentFragment } from "generated/graphql-types";
import { ContentType } from "models/ModelType";

export type LayoutItemContentModel =
    | AlbumsContent
    | ArtistsContent
    | BannersContent
    | ChartsContent
    | LiveRadioCategoriesContent
    | LiveRadiosContent
    | MixRadiosContent
    | PlaylistCategoriesContent
    | MixedContentContent
    | PlaylistsContent
    | TracksContent
    | RecommendationsContent;

interface AlbumsContent {
    contentType: ContentType.AlbumsLayoutItemContent;
    readonly albums: ConnectionModel<AlbumPreviewModel> | null;
}

interface ArtistsContent {
    contentType: ContentType.ArtistsLayoutItemContent;
    readonly artists: ConnectionModel<ArtistPreviewModel> | null;
}

interface BannersContent {
    contentType: ContentType.BannerLayoutItemContent;
    readonly banners: ConnectionModel<BannerPreviewModel> | null;
}

interface ChartsContent {
    contentType: ContentType.ChartsLayoutItemContent;
    readonly charts: ConnectionModel<ChartPreviewModel> | null;
}

interface LiveRadioCategoriesContent {
    contentType: ContentType.LiveRadioCategoriesLayoutItemContent;
    readonly liveRadioCategories: ConnectionModel<LiveRadioCategoryPreviewModel> | null;
}

interface LiveRadiosContent {
    contentType: ContentType.LiveRadiosLayoutItemContent;
    readonly liveRadios: ConnectionModel<LiveRadioPreviewModel> | null;
}

interface MixRadiosContent {
    contentType: ContentType.MixRadiosLayoutItemContent;
    readonly mixRadios: ConnectionModel<MixRadioPreviewModel> | null;
}

interface PlaylistCategoriesContent {
    contentType: ContentType.PlaylistCategoriesLayoutItemContent;
    readonly playlistCategories: ConnectionModel<PlaylistCategoryPreviewModel> | null;
}

interface MixedContentContent {
    contentType: ContentType.MixedContentLayoutItemContent;
    readonly mixedContents: ConnectionModel<MixedContentPreviewModel> | null;
}

interface PlaylistsContent {
    contentType: ContentType.PlaylistsLayoutItemContent;
    readonly playlists: ConnectionModel<PlaylistPreviewModel> | null;
}

export interface TracksContent {
    contentType: ContentType.TracksLayoutItemContent;
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

interface RecommendationsContent {
    contentType: ContentType.RecommendationsLayoutItemContent;
    readonly userRecommendations: ConnectionModel<RecommendationPreviewModel> | null;
}

type LayoutItemContentContentData<T extends string> = LayoutItemContentFragment["content"] & { __typename: T };

export function createLayoutItemContentModel(data: LayoutItemContentContentData<string>, pageId: string, itemId: string): LayoutItemContentModel | null {
    switch (data?.__typename) {
        case "AlbumsLayoutItemContent":
            return createLayoutPageItemAlbumsSectionModel(data);
        case "ArtistsLayoutItemContent":
            return createLayoutPageItemArtistsSectionModel(data);
        case "ChartsLayoutItemContent":
            return createLayoutPageItemChartsSectionModel(data);
        case "LiveRadioCategoriesLayoutItemContent":
            return createLayoutPageItemLiveRadioCategoriesSectionModel(data);
        case "LiveRadiosLayoutItemContent":
            return createLayoutPageItemLiveRadiosSectionModel(data);
        case "MixedContentLayoutItemContent":
            return createLayoutPageItemMixedContentsSectionModel(data);
        case "PlaylistCategoriesLayoutItemContent":
            return createLayoutPageItemPlaylistCategoriesSectionModel(data);
        case "PlaylistsLayoutItemContent":
            return createLayoutPageItemPlaylistsSectionModel(data);
        case "MixRadiosLayoutItemContent":
            return createLayoutPageItemMixRadiosSectionModel(data, pageId, itemId);
        case "TracksLayoutItemContent":
            return createLayoutPageItemTracksSectionModel(data);
        case "RecommendationsLayoutItemContent":
            return createLayoutPageItemRecommendationsSectionModel(data);
        case "BannerContentLayoutItemContent":
            return createLayoutPageItemBannersSectionModel(data);
        default: {
            log.error({ code: "web-210505-2104", msg: DefaultLogMessage.NotImplemented, data: { type: data?.__typename } });
            return null;
        }
    }
}

function createLayoutPageItemAlbumsSectionModel(data: LayoutItemContentContentData<"AlbumsLayoutItemContent">): AlbumsContent {
    return {
        contentType: ContentType.AlbumsLayoutItemContent,
        albums: data.albums ? createAlbumPreviewConnectionModel(data.albums) : null
    };
}

function createLayoutPageItemArtistsSectionModel(data: LayoutItemContentContentData<"ArtistsLayoutItemContent">): ArtistsContent {
    return {
        contentType: ContentType.ArtistsLayoutItemContent,
        artists: data.artists ? createArtistPreviewConnectionModel(data.artists) : null
    };
}

function createLayoutPageItemBannersSectionModel(data: LayoutItemContentContentData<"BannerContentLayoutItemContent">): BannersContent {
    return {
        contentType: ContentType.BannerLayoutItemContent,
        banners: data.banners ? createBannerPreviewConnectionModel(data.banners) : null
    };
}

function createLayoutPageItemChartsSectionModel(data: LayoutItemContentContentData<"ChartsLayoutItemContent">): ChartsContent {
    return {
        contentType: ContentType.ChartsLayoutItemContent,
        charts: data.charts ? createChartPreviewConnectionModel(data.charts) : null
    };
}

function createLayoutPageItemLiveRadioCategoriesSectionModel(data: LayoutItemContentContentData<"LiveRadioCategoriesLayoutItemContent">): LiveRadioCategoriesContent {
    return {
        contentType: ContentType.LiveRadioCategoriesLayoutItemContent,
        liveRadioCategories: data.liveRadioCategories ? createLiveRadioCategoryPreviewConnectionModel(data.liveRadioCategories) : null
    };
}

function createLayoutPageItemLiveRadiosSectionModel(data: LayoutItemContentContentData<"LiveRadiosLayoutItemContent">): LiveRadiosContent {
    return {
        contentType: ContentType.LiveRadiosLayoutItemContent,
        liveRadios: data.liveRadios ? createLiveRadioPreviewConnectionModel(data.liveRadios) : null
    };
}

function createLayoutPageItemMixRadiosSectionModel(data: LayoutItemContentContentData<"MixRadiosLayoutItemContent">, pageId: string, itemId: string): MixRadiosContent {
    return {
        contentType: ContentType.MixRadiosLayoutItemContent,
        mixRadios: data.mixRadios ? createMixRadioPreviewConnectionModel(data.mixRadios, { type: MixRadioLinkParentType.LayoutPageItem, pageId, itemId }) : null
    };
}

function createLayoutPageItemPlaylistCategoriesSectionModel(data: LayoutItemContentContentData<"PlaylistCategoriesLayoutItemContent">): PlaylistCategoriesContent {
    return {
        contentType: ContentType.PlaylistCategoriesLayoutItemContent,
        playlistCategories: data.playlistCategories ? createPlaylistCategoryPreviewConnectionModel(data.playlistCategories) : null
    };
}

function createLayoutPageItemMixedContentsSectionModel(data: LayoutItemContentContentData<"MixedContentLayoutItemContent">): MixedContentContent {
    return {
        contentType: ContentType.MixedContentLayoutItemContent,
        mixedContents: data.mixedContents ? createMixedContentPreviewConnectionModel(data.mixedContents) : null
    };
}

function createLayoutPageItemPlaylistsSectionModel(data: LayoutItemContentContentData<"PlaylistsLayoutItemContent">): PlaylistsContent {
    return {
        contentType: ContentType.PlaylistsLayoutItemContent,
        playlists: data.playlists ? createPlaylistPreviewConnectionModel(data.playlists) : null
    };
}

export function createLayoutPageItemTracksSectionModel(data: LayoutItemContentContentData<"TracksLayoutItemContent">): TracksContent {
    return {
        contentType: ContentType.TracksLayoutItemContent,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0) : null
    };
}

function createLayoutPageItemRecommendationsSectionModel(data: LayoutItemContentContentData<"RecommendationsLayoutItemContent">): RecommendationsContent {
    return {
        contentType: ContentType.RecommendationsLayoutItemContent,
        userRecommendations: data.recommendations ? createRecommendationPreviewConnectionModel(data.recommendations, ContentType.UserRecommendations) : null
    };
}

export type LayoutItemContentContentPreviewModel =
    | AlbumPreviewModel
    | ArtistPreviewModel
    | BannerPreviewModel
    | ChartPreviewModel
    | LiveRadioCategoryPreviewModel
    | LiveRadioPreviewModel
    | MixRadioPreviewModel
    | MixedContentPreviewModel
    | PlaylistCategoryPreviewModel
    | PlaylistPreviewModel
    | RecommendationPreviewModel
    | TrackPreviewModel;

export type LayoutItemContentContentPreviewConnectionModel = ConnectionModel<LayoutItemContentContentPreviewModel>;
