import type { AnalyticsData } from "../../analyticsData";
import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";

export type AnalyticsChangeSettingtProperties = AnalyticsData & { type: "ChangeSettingtProperties" };

export function getAnalyticsChangeSettingProperties(preferenceType: AnalyticsKey, value: AnalyticsKey, shortcutThemeSettingWeb?: boolean) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "PreferenceType", preferenceType);
    SetAnalyticsProp(data, "event", "Value", value);
    if (shortcutThemeSettingWeb != undefined) SetAnalyticsProp(data, "event", "ShortcutThemeSettingWeb", shortcutThemeSettingWeb);

    return { ...data, type: "ChangeSettingtProperties" };
}
