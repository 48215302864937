import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getHistoryRecommendationsPagination, useHistoryRecommendationsPage } from "services/backend";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

export const UserHistoryPage = () => {
    const query = useHistoryRecommendationsPage({ first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => getHistoryRecommendationsPagination({ first, after }), []);

    return <ShowAllPageTemplate query={query} type={ContentType.UserHistory} items={product?.collections ?? null} fetchFn={fetchFn} display={ResourceDisplayType.Grid} />;
};
