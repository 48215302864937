import { h } from "preact";
import { useMemo } from "preact/hooks";
import { ResourceContextMenu } from ".";
import { getAlbumPreview, getArtistPreview, getTrackPreview, getPlaylistPreview, getTrackChartPreview, getLiveRadioPlay } from "services/backend";
import type { DomainQueryResult } from "services/backend/backendService";
import { useDomainQueryResult } from "services/backend/backendService";
import { DefaultLogMessage, log } from "services/logger";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import type { MixedContentPreviewModel } from "models/domain/MixedContentModel";
import { ContentType } from "models/ModelType";

interface Props {
    mixedContent: MixedContentPreviewModel;
    context: PreviewContextModel;
}

export const MixedContentContextMenu = ({ context, mixedContent }: Props) => {
    const query = useMemo(() => getMixedContentResource(mixedContent), [mixedContent]);
    const result = useDomainQueryResult(query);
    const resource = result?.model ?? null;

    return <ResourceContextMenu context={context} resource={resource} resourceType={mixedContent.mixedContentType} />;
};

async function getMixedContentResource(mixedContent: MixedContentPreviewModel | null): Promise<DomainQueryResult<ResourcePreviewModel> | null> {
    if (!mixedContent) return null;
    const id = mixedContent.mixedContentId;

    if (id == null) {
        log.error({ code: "web-211027-1830", msg: "id is null", data: { mixedContentType: mixedContent.mixedContentType } });
        return null;
    }

    switch (mixedContent.mixedContentType) {
        case ContentType.Album:
            return await getAlbumPreview({ id });
        case ContentType.Artist:
            return await getArtistPreview({ id });
        case ContentType.Playlist:
            return await getPlaylistPreview({ id });
        case ContentType.TrackPlayable:
            return await getTrackPreview({ id });
        case ContentType.TrackChart:
            return await getTrackChartPreview({ id });
        case ContentType.LiveRadio:
            return await getLiveRadioPlay({ id });
    }

    log.error({ code: "web-211027-1830", msg: DefaultLogMessage.NotImplemented, data: { mixedContentType: mixedContent.mixedContentType } });
    return null;
}
