import { h } from "preact";
import "./SearchResultArea.scss";
import type { PreviewAction } from "../preview";
import { useTranslations } from "global/config";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { SearchViewState } from "services/search";
import type { PageContextModel, PreviewContextModel } from "models/app/resourceContextModel";
import { ResourceDisplayType } from "models/domain";
import type { SearchResultModel } from "models/domain/SearchResultModel";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";
import { MediaSize } from "components/shared/hooks";
import { useFakePageContext, useSectionContext } from "components/shared/hooks/useContext";
import { useUserRecentSearches } from "components/shared/hooks/useSearchHistory";
import { ButtonClearSearchHistory } from "components/atoms/controls/button/buttons/ButtonClearSearchHistory";
import { ContentLoadSpinner } from "components/atoms/spinner";
import { ResourceSection } from "components/organisms/resourceSection";

export enum SearchResultAreaSource {
    Search,
    History
}

interface Props {
    search: SearchResultModel | null;
    state: SearchViewState;
    onAction: (action: PreviewAction, source: SearchResultAreaSource, context: PreviewContextModel) => void;
    size: MediaSize;
    context: PageContextModel;
}

export const SearchResultArea = (props: Props) => {
    const content: h.JSX.Element | null = (() => {
        switch (props.state) {
            case SearchViewState.Failed:
                return <SearchResultAreaFailed />;
            case SearchViewState.History:
                return <SearchResultAreaHistory {...props} />;
            case SearchViewState.Results:
                return <SearchResultAreaResults {...props} />;
            case SearchViewState.NoResults:
                return <SearchResultAreaNoResults />;
            case SearchViewState.Searching:
                return <SearchResultAreaSearching {...props} />;
            case SearchViewState.None:
                return <SearchResultAreaDefault />;
        }
    })();

    return <div className={`searchResultArea --size-${props.size}`}>{content}</div>;
};

const SearchResultAreaDefault = () => {
    const translations = useTranslations();
    return (
        <section className="default">
            <h1>{translations.SearchPlaceHolderTitle}</h1>
            <p>{translations.SearchPlaceholderText}.</p>
        </section>
    );
};

const SearchResultAreaFailed = () => {
    const translations = useTranslations();
    return (
        <section className="failed">
            <h1>{translations.ErrorStateErrorTitle}</h1>
            <p>{translations.ErrorStateErrorTryAgainText}</p>
        </section>
    );
};

const SearchResultAreaNoResults = () => {
    const translations = useTranslations();
    return (
        <section className="noResults">
            <h1>{translations.SearchNoResult}</h1>
            <p>{translations.SearchNoResultHint}</p>
        </section>
    );
};

const SearchResultAreaSearching = ({ size }: Props) => {
    const translations = useTranslations();
    if (size === MediaSize.Mobile) {
        return (
            <section className="searching">
                <ContentLoadSpinner />
            </section>
        );
    } else
        return (
            <section className="searching">
                <p>{translations.GlobalSearchingName}</p>
            </section>
        );
};

const SearchResultAreaHistory = ({ onAction, context: pageContext }: Props) => {
    const context = useSectionContext(ContentType.SearchRecentResults, null, pageContext, 0, ResourceDisplayType.List);

    const resources = useUserRecentSearches();
    if (resources.length == 0) log.error({ code: "web-210823-1452", msg: DefaultLogMessage.UnexpectedValue });

    const onActionFn = (action: PreviewAction, preview: PreviewContextModel) => onAction(action, SearchResultAreaSource.History, preview);

    return (
        <section className="history">
            <ResourceSection showTitle buttons={<ButtonClearSearchHistory />} resources={resources} context={context} onAction={onActionFn} />
        </section>
    );
};

const SearchResultAreaResults = ({ search, onAction }: Props) => {
    let mixedResults = search?.mixedResults ?? null;
    if (mixedResults?.mixedResults?.items.length == 0) mixedResults = null;

    let tracks = search?.tracks ?? null;
    if (tracks?.tracks?.items.length == 0) tracks = null;

    let artists = search?.artists ?? null;
    if (artists?.artists?.items.length == 0) artists = null;

    let albums = search?.albums ?? null;
    if (albums?.albums?.items.length == 0) albums = null;

    let playlists = search?.playlists ?? null;
    if (playlists?.playlists?.items.length == 0) playlists = null;

    const pageContext = useFakePageContext({ type: ContentType.Search, resource: null, root: NavigationPageRootName.Search, done: true });
    const mixedContext = useSectionContext(ContentType.SearchMixedResults, mixedResults, pageContext, 0, ResourceDisplayType.List);
    const tracksContext = useSectionContext(ContentType.SearchTrackResults, tracks, pageContext, 1, ResourceDisplayType.List);
    const artistsContext = useSectionContext(ContentType.SearchArtistResults, artists, pageContext, 2, ResourceDisplayType.List);
    const albumsContext = useSectionContext(ContentType.SearchAlbumResults, albums, pageContext, 3, ResourceDisplayType.List);
    const playlistsContext = useSectionContext(ContentType.SearchPlaylistResults, playlists, pageContext, 4, ResourceDisplayType.List);

    const onActionFn = (action: PreviewAction, previewContext: PreviewContextModel) => onAction(action, SearchResultAreaSource.Search, previewContext);

    return (
        <section className="results">
            {mixedResults && <ResourceSection showTitle resources={mixedResults.mixedResults?.items ?? null} context={mixedContext} onAction={onActionFn} />}
            {tracks && <ResourceSection showTitle resources={tracks.tracks?.items ?? null} context={tracksContext} showAll={tracks} onAction={onActionFn} />}
            {artists && <ResourceSection showTitle resources={artists.artists?.items ?? null} context={artistsContext} showAll={artists} onAction={onActionFn} />}
            {albums && <ResourceSection showTitle resources={albums.albums?.items ?? null} context={albumsContext} showAll={albums} onAction={onActionFn} />}
            {playlists && <ResourceSection showTitle resources={playlists.playlists?.items ?? null} context={playlistsContext} showAll={playlists} onAction={onActionFn} />}
        </section>
    );
};
