// locator names for Cypress. Format: {location}-{elementName}-{elementType}. Add on elements with data-cy attribute key. Use location "global" tf the locator has no specific location.

import type { SectionContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";

export type TestLocatorValue = TestLocator | string;

export enum TestLocator {
    AccountMenuLoginButton = "accountMenu-login-button",
    AccountMenuLogoutButton = "accountMenu-logout-button",
    AccountMenuProfileButton = "accountMenu-profile-button",
    AccountMenuAddProfileButton = "accountMenu-addprofile-button",
    NavigationAlbumsButton = "navigation-albums-button",
    NavigationArtistsButton = "navigation-artists-button",
    NavigationHomeButton = "navigation-home-button",
    NavigationMyMusicButton = "navigation-myMusic-button",
    NavigationPlaylistButton = "navigation-playlist-button",
    NavigationPlaylistsButton = "navigation-playlists-button",
    NavigationRadioButton = "navigation-radio-button",
    NavigationSearchButton = "navigation-search-button",
    NavigationTracksButton = "navigation-tracks-button",
    PageHeaderAccountButton = "pageHeader-account-button",
    PageHeaderLoginButton = "pageHeader-login-button",
    PageHeaderNotificationFeedButton = "pageHeader-notificationFeed-button",
    PlayerChromecastButton = "player-chromecast-button",
    PlayerFavoriteButton = "player-favorite-button",
    PlayerMoreButton = "player-more-button",
    PlayerNextButton = "player-next-button",
    PlayerPlayPauseButton = "player-playPause-button",
    PlayerPreviousButton = "player-previous-button",
    PlayerQueueToggleButton = "player-queueToggle-button",
    PlayerLyricsToggleButton = "player-lyricsToggle-button",
    PlayerRepeatButton = "player-repeat-button",
    PlayerShuffleButton = "player-shuffle-button",
    PreviewMoreButton = "preview-more-button",
    PreviewPlayButton = "preview-play-button",
    ProductPageFavoriteButton = "productPage-favorite-button",
    ProductPageMoreButton = "productPage-more-button",
    ProductPagePlayButton = "productPage-play-button",
    ProductPageShufflePlayButton = "productPage-shufflePlay-button",
    QueueClearButton = "queue-clear-button",
    QueueHistoryButton = "queue-history-button",
    QueueSaveButton = "queue-save-button",
    ResourceSectionBackwardButton = "resourceSection-backward-button",
    ResourceSectionForwardButton = "resourceSection-forward-button",
    ResourceSectionShowAllButton = "resourceSection-showAll-button",
    SearchClearHistory = "search-clearHistory-button"
}

export const CoverTestLocator = (type: ContentType | null) => `global-${type}-cover`;
export const PreviewRowTestLocator = (type: ContentType | null) => `global-${type}-previewRow`;
export const PreviewSingleTestLocator = (type: ContentType | null) => `global-${type}-previewSingle`;
export const PreviewSquareTestLocator = (type: ContentType | null) => `global-${type}-previewSquare`;
export const ResourceLinkGroupTestLocator = (name: number) => `global-${name}-resourceLinkGroup`;
export const ResourceLinkTestLocator = (name: number) => `global-${name}-resourceLink`;
export const ResourceSectionTestLocator = (model: SectionContextModel) => {
    const type = model.type === ContentType.LayoutItem && model.resource?.contentType === ContentType.LayoutItem
        ? `LayoutItem-${model.resource?.domainItemType}`
        : model.type;
    return `global-${type}-resourceSection`
};
export const EndlessPlaySwitchTestLocator = (on: boolean) => `queue-endlessPlay-button${on ? "On" : "Off"}`;
