import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { getVisibleLinks } from "./ResourcePreviewRow";
import { PreviewAction, PreviewDisplayType } from "..";
import { PreviewLinks } from "../PreviewLinks";
import { PreviewRow } from "../PreviewRow";
import { dispatch } from "global";
import { PLAYQUEUE_PLAY_TRACK, PLAYQUEUE_REMOVE_TRACK } from "global/actions";
import { getTitles } from "services/resource";
import type { DraggableItemModel, DragProps, DropProps } from "models/app";
import { createDraggablePlayableModel } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import type { SectionContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";
import { useContextMenu, usePreviewContext } from "components/shared/hooks";
import { useQueueTrackPlayableFromState } from "components/shared/hooks/useQueueTrackPlayable";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { ButtonMore } from "components/atoms/controls/button/buttons";
import { CoverSize, ResourceCover } from "components/atoms/cover";
import { IconName } from "components/atoms/icon";
import { PlayableSoundbars, SoundbarStyle } from "components/atoms/soundbars";
import { ResourceTextDesign, ResourceTextPart } from "components/molecules/resource";
import { ResourceContextMenu } from "components/organisms/contextMenu/menus";

interface Props {
    sectionContext: SectionContextModel;
    disabled?: boolean;
    dragProps: DragProps;
    dropProps: DropProps | null;
    onCurrentTrackClick?: () => void;
    onCurrentCoverClick?: () => void;
    queueTrack: QueueTrackModel;
    isCurrentLanePreview?: boolean;
    scrollOnTrackClickFn?: (id: string) => void;
    allowDrop?: (item: DraggableItemModel) => boolean;
}

export const QueueTrackRow = ({
    queueTrack,
    sectionContext,
    disabled,
    dragProps,
    dropProps,
    scrollOnTrackClickFn,
    allowDrop,
    onCurrentCoverClick,
    onCurrentTrackClick,
    isCurrentLanePreview
}: Props) => {
    const { queueId, lane } = queueTrack;
    const track = useQueueTrackPlayableFromState(queueTrack);

    const context = usePreviewContext(ContentType.TrackPlayable, track, sectionContext, null);

    const { title, subtitle } = getTitles(ContentType.TrackPlayable, track, context);
    const isCurrent = queueTrack.lane === QueueLane.Current || isCurrentLanePreview;

    const [moreOpen, setMoreOpen] = useState(false);

    const menuRightClick = useContextMenu(ResourceContextMenu({ resource: track, context, resourceType: ContentType.TrackPlayable }), false);

    const activated = moreOpen || menuRightClick.isOpen;
    const rightClick = useCallback((ev: MouseEvent) => menuRightClick.openByMouse(ev), [menuRightClick]);
    const playTrack = useCallback(
        () =>
            dispatch({
                type: PLAYQUEUE_PLAY_TRACK,
                payload: {
                    queueId,
                    isPreviewLane: lane === QueueLane.Preview,
                    context: { action: AudioContextAction.UserPreviewSetAndPlay, trace: null }
                }
            }),
        [queueId, lane]
    );

    const trackClickFn = useCallback(() => {
        if (isCurrent) {
            !onCurrentCoverClick && onCurrentTrackClick && onCurrentTrackClick();
        } else {
            playTrack();
            scrollOnTrackClickFn && scrollOnTrackClickFn(queueTrack.compareableTrackId);
        }
    }, [isCurrent, onCurrentCoverClick, onCurrentTrackClick, playTrack, scrollOnTrackClickFn, queueTrack.compareableTrackId]);

    const handleRemoveFromQueue = useCallback(
        () =>
            dispatch({
                type: PLAYQUEUE_REMOVE_TRACK,
                payload: {
                    queueId,
                    isPreviewLane: lane === QueueLane.Preview,
                    context: { action: AudioContextAction.UserPlayQueueRemove, trace: null }
                }
            }),
        [queueId, lane]
    );

    const togglePlay = useCallback(() => {
        if (isCurrent) {
            onCurrentCoverClick && onCurrentCoverClick();
        }
    }, [isCurrent, onCurrentCoverClick]);

    const image = (
        <ResourceCover size={isCurrentLanePreview ? CoverSize.Size64 : CoverSize.Size48} context={context} disabledAction={!isCurrent} onClick={togglePlay} disableLazyLoad={true}>
            {track && isCurrent && <PlayableSoundbars playable={track} style={SoundbarStyle.CoverOverlay} />}
        </ResourceCover>
    );
    const texts = [
        <ResourceTextPart key={1} design={ResourceTextDesign.PrimarySmall}>
            {title ?? ""}
        </ResourceTextPart>,
        <ResourceTextPart key={2} design={ResourceTextDesign.SecondarySmall}>
            {subtitle ?? ""}
        </ResourceTextPart>
    ];

    const allLinks = PreviewLinks({
        resourceType: ContentType.TrackPlayable,
        resource: track,
        context,
        displayType: PreviewDisplayType.Row
    });
    const links = getVisibleLinks(allLinks, 1, ContentType.TrackPlayable);

    const buttons = isCurrent ? (
        <ButtonMore
            resource={track}
            context={context}
            design={ButtonDesign.DefaultBig}
            icon={IconName.Meatballs}
            inline={{ right: true }}
            onOpen={setMoreOpen}
            closeContextMenuOnNavigation={false}
        />
    ) : (
        <Button disabled={disabled} className="delete" design={ButtonDesign.DefaultSmall} onClick={handleRemoveFromQueue} icon={IconName.Delete16} inline={{ right: true }} />
    );
    const draggableItem = useCallback(() => createDraggablePlayableModel(queueTrack, dragProps.dragSourceId, context), [queueTrack, dragProps.dragSourceId, context]);

    return (
        <PreviewRow
            id={track?.id}
            key={track?.id}
            activated={activated}
            buttons={buttons}
            columns={1}
            context={context}
            allowDrop={allowDrop}
            disabled={disabled}
            draggableItem={draggableItem}
            dropProps={isCurrent ? null : dropProps}
            image={image}
            links={isCurrent ? links : texts}
            mainAction={PreviewAction.None}
            onAction={trackClickFn}
            onContextMenu={rightClick}
            resourceType={ContentType.TrackPlayable}
        />
    );
};
