import { createHlsElement } from "../hls";
import { getBrowserAudioLog } from "../log";
import { inSkipDebounce } from "../skipDebounce";
import { log, LogTag } from "services/logger";
import { StreamUrlType } from "models/app/player/input";
import { BrowserAudioLoadState } from "models/app/player/output";
import type { BrowserAudioItemModel } from "models/app/player/output";

export const updateMedia = (browserAudio: BrowserAudioItemModel) => {
    if (inSkipDebounce) return;
    if (browserAudio.disposed) return;
    if (!browserAudio.loadNow) return;
    if (browserAudio.isMediaSet) return;
    if (!browserAudio.url) return;

    const { element, url } = browserAudio;

    browserAudio.state = BrowserAudioLoadState.Buffering;

    log.debug([LogTag.Playback], () => ({
        code: "web-221213-1453",
        msg: "browserAudio updateMedia, state: Buffering",
        data: {
            browserAudio: getBrowserAudioLog(browserAudio)
        }
    }));

    switch (url.urlType) {
        case StreamUrlType.Hls: {
            const hls = createHlsElement(browserAudio, url.url);
            if (hls != null) {
                browserAudio.hls = hls;
                browserAudio.isMediaSet = true;
            }
            break;
        }
        case StreamUrlType.Mp3: {
            element.src = url.url;
            browserAudio.isMediaSet = true;
        }
    }

    element.preload = "auto";
};
