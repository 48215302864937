import type { ComponentChild } from "preact";
import { h } from "preact";
import type { ResourceTextDesign } from ".";
import "./ResourceLinkGroup.scss";
import { ResourceLinkGroupTestLocator } from "global/constants";

interface Props {
    children: ComponentChild[];
    className?: string;
    design?: ResourceTextDesign;
    testLocatorName?: number;
}

export const ResourceLinkGroup = ({ children, className, testLocatorName, design }: Props) => {
    return (
        <div
            className={`resourceLinkGroup vU9 ${className ?? ""} ${design && `--design-${design}`}`}
            data-cy={testLocatorName ? ResourceLinkGroupTestLocator(testLocatorName) : undefined}>
            {children}
        </div>
    );
};
