import { getBrowserAudios, updateFlow } from "../flow";
import { playerConfig } from "global/constants/player";
import { log, LogTag } from "services/logger";

export let inSkipDebounce = false;

let lastPlayingId: string | null = null;
let lastPlayingChange = 0;
let timeOutId: number | null = null;

export const updateSkipDebounce = () => {
    updatePlayingChange();
    updateDebounce();
};

function updatePlayingChange() {
    const playingId = getBrowserAudios().find((item) => item.preload.order === 0)?.audioId ?? null;

    if (playingId == null || playingId != lastPlayingId) {
        lastPlayingId = playingId;
        lastPlayingChange = new Date().getTime();
    }
}

const updateDebounce = () => {
    if (timeOutId != null) {
        window.clearTimeout(timeOutId);
        timeOutId = null;
    }
    const now = new Date().getTime();
    const timeout = lastPlayingChange + playerConfig.playerMultiSkipTimeoutMs - now;

    if (timeout <= 0) {
        setDebounce(false);
    } else {
        setDebounce(true);
        timeOutId = window.setTimeout(() => updateDebounce(), timeout);
    }
};

function setDebounce(debounce: boolean) {
    if (debounce === inSkipDebounce) return;
    inSkipDebounce = debounce;

    log.debug([LogTag.Playback], () => ({
        code: "web-211213-1558",
        msg: `browserAudio debounce changed, inSkipDebound: ${debounce}`,
        data: { debounce }
    }));

    if (!debounce) updateFlow();
}
