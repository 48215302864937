import { isControllerChromecastFromState } from "../service/helpers";
import { syncCastFromLocalChange } from "./service/actions";
import { resumeTimeoutCheck } from "./service/actions/resumeTimeoutCheck";
import { playerRepeat, playerSetMute, playerSetVolume, userLoggedIn, userLoggedOut } from "./service/localEvents";
import { addCastApiListener } from "./service/setup";
import { messageBus } from "global";
import { AUDIO_INPUT_AUDIOS_CHANGE, AUDIO_INPUT_AUDIO_PLAY_CHANGE, PLAYER_REPEAT, PLAYER_SET_MUTE, PLAYER_SET_VOLUME, USER_LOGGED_IN, USER_LOGGED_OUT } from "global/actions";
import { log, LogTag } from "services/logger";

export const initCastService = async () => {
    addCastApiListener();
    resumeTimeoutCheck();

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIOS_CHANGE, async (msg) => {
        if (!isControllerChromecastFromState()) return;

        log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `AUDIO_INPUT_AUDIOS_CHANGE` });

        syncCastFromLocalChange(msg.payload.context);
    });

    messageBus.subscribeEvery(AUDIO_INPUT_AUDIO_PLAY_CHANGE, async (msg) => {
        if (!isControllerChromecastFromState()) return;

        log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `AUDIO_INPUT_AUDIO_PLAY_CHANGE` });

        syncCastFromLocalChange(msg.payload.context);
    });

    messageBus.subscribeEvery(PLAYER_SET_VOLUME, async () => {
        if (!isControllerChromecastFromState()) return;
        playerSetVolume();
    });

    messageBus.subscribeEvery(PLAYER_SET_MUTE, async () => {
        if (!isControllerChromecastFromState()) return;
        playerSetMute();
    });

    messageBus.subscribeEvery(PLAYER_REPEAT, async () => {
        if (!isControllerChromecastFromState()) return;
        playerRepeat();
    });

    messageBus.subscribeEvery(USER_LOGGED_IN, async () => {
        if (!isControllerChromecastFromState()) return;
        userLoggedIn();
    });

    messageBus.subscribeEvery(USER_LOGGED_OUT, async () => {
        if (!isControllerChromecastFromState()) return;
        userLoggedOut();
    });
};
