import type { ArtistPreviewModel } from "./ArtistModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createArtistPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import { getNextUUID } from "services/utils";
import type { SearchArtistsPaginationQuery } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type SearchResultData = SearchArtistsPaginationQuery["search"];

export type SearchArtistResultsModel = SearchArtistResultsSectionModel;
export type SearchArtistResultsSectionModel = ({ type: DomainModelType.Section } & SearchArtistResults) | SearchArtistResultsPageModel;
export type SearchArtistResultsPageModel = { type: DomainModelType.Page } & SearchArtistResults;

interface SearchArtistResults extends ResourceModelInterface {
    contentType: ContentType.SearchArtistResults;
    criterion: string;
    artists: ConnectionModel<ArtistPreviewModel> | null;
}
function createSearchArtistResults(data: SearchResultData, criterion: string): SearchArtistResults {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        contentType: ContentType.SearchArtistResults,
        criterion,
        artists: data.artists ? createArtistPreviewConnectionModel(data.artists) : null
    };
}
export function createSearchArtistResultsSectionModel(data: SearchResultData, criterion: string): SearchArtistResultsSectionModel {
    return {
        ...createSearchArtistResults(data, criterion),
        type: DomainModelType.Section
    };
}

export function createSearchArtistResultsPageModel(data: SearchResultData, criterion: string): SearchArtistResultsPageModel {
    return {
        ...createSearchArtistResults(data, criterion),
        type: DomainModelType.Page
    };
}
