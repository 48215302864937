import { dispatch } from "global";
import { AUDIO_INPUT_TYPE_CHANGE, LIVERADIO_SET_AND_PLAY, PLAYQUEUE_SET_AND_PLAY } from "global/actions";
import type { AudioInputSetAndPlayAction } from "global/actions/actionTypes";
import { AudioInputType } from "models/app/player";
import { ContentType } from "models/ModelType";

export async function setAndPlay({ playable, context, playableContext, shuffle }: AudioInputSetAndPlayAction["payload"]) {
    const input = playable.contentType === ContentType.LiveRadioPlayable ? AudioInputType.LiveRadio : AudioInputType.PlayQueue;

    await dispatch({
        type: AUDIO_INPUT_TYPE_CHANGE,
        payload: {
            input,
            context
        }
    });

    if (playable.contentType === ContentType.LiveRadioPlayable) {
        dispatch({
            type: LIVERADIO_SET_AND_PLAY,
            payload: {
                context,
                radio: playable,
                radioContext: playableContext
            }
        });
    } else {
        dispatch({
            type: PLAYQUEUE_SET_AND_PLAY,
            payload: {
                context,
                playable,
                playableContext,
                shuffle
            }
        });
    }
}
