import { setCastAvailable } from "../../actions/castAvailable";
import { log, LogTag } from "services/logger";

export function onCastApiAvailable(isAvailable: boolean) {
    log.info({ tags: [LogTag.Chromecast], code: "web-230105-1431", msg: `onCastApiAvailable: ${isAvailable}` });

    if (isAvailable && !chrome?.cast) {
        log.error({ code: "web-211013-1325", msg: "chrome.cast == null" });
        isAvailable = false;
    }

    setCastAvailable(isAvailable);
}
