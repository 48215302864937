import { getAppSession } from "services/appSession";
import { PlayerOutputType, AudioInputType } from "models/app/player";

export function getAudioInputReportId(audioId: string, output: PlayerOutputType): string {
    const outputStr = () => {
        switch (output) {
            case PlayerOutputType.Browser:
                return "browser";
            case PlayerOutputType.Chromecast:
                return "chromecast";
        }
    };
    return `${audioId}_${outputStr()}_${getAppSession().storageId}`;
}

export function getAudioSendPlaybackReport(input: AudioInputType, output: PlayerOutputType, sample: boolean): boolean {
    if (input === AudioInputType.LiveRadio) return false;
    if (output === PlayerOutputType.Chromecast) return false;
    if (sample) return false;

    return true;
}
