import { h } from "preact";
import type { IconName } from "components/atoms/icon";
import { Icon, IconSize } from "components/atoms/icon";

interface Props {
    icon: IconName;
    title: string;
}
export const Header = ({ icon, title }: Props) => {
    return (
        <div className="header">
            <Icon icon={icon} size={IconSize.Default} />
            <div className="title">{title}</div>
        </div>
    );
};
