import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { useAppMediaSize, MediaSize, getAppMediaSize } from ".";
import { getQueueProperties, useQueueProperties } from "./useQueueProperties";
import { store } from "global";
import type { RootModel } from "models/app";
import { DesktopQueueOpen } from "models/app";
import { QueueMode } from "models/app/player/input";

export function useDesktopQueueOpen(): boolean {
    const { hasItems } = useQueueProperties();
    const media = useAppMediaSize();
    const desktopQueueOpen = useSelector((root: RootModel) => root.ui.layout.desktopQueueOpen);
    const isQueueModeMixRadio = useSelector((root: RootModel) => root.queue.mode) === QueueMode.MixRadio;

    return useMemo(() => isDesktopQueueOpen(hasItems, media, desktopQueueOpen, isQueueModeMixRadio), [desktopQueueOpen, media, hasItems, isQueueModeMixRadio]);
}

export function getDesktopQueueOpen(): boolean {
    const { hasItems } = getQueueProperties();
    const media = getAppMediaSize();
    const { ui, queue } = store.getState();
    const isQueueModeMixRadio = queue.mode === QueueMode.MixRadio;

    return isDesktopQueueOpen(hasItems, media, ui.layout.desktopQueueOpen, isQueueModeMixRadio);
}

function isDesktopQueueOpen(hasItems: boolean, media: MediaSize, desktopQueueOpen: DesktopQueueOpen, isQueueModeMixRadio: boolean): boolean {
    if (isQueueModeMixRadio) return false;
    if (desktopQueueOpen === DesktopQueueOpen.Open) return true;
    if (desktopQueueOpen === DesktopQueueOpen.Default && hasItems && media === MediaSize.Desktop) return true;
    return false;
}
