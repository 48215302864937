import type { QueueTrackModel } from "./QueueTrackModel";
import type { AudioInputType } from "../..";
import type { AnalyticsQueueIntentPropertiesGroup } from "services/logger/analytics/properties/groups/queueIntentPropertiesGroup";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { TrackParentModel } from "models/domain";
import type { MixRadioPreviewModel } from "models/domain/MixRadioModel";

export enum QueueMode {
    Default = "Default",
    MixRadio = "MixRadio"
}

export interface MixRadioSession {
    queueIntentData2?: AnalyticsQueueIntentPropertiesGroup;
    mixRadio: MixRadioPreviewModel;
    connection: {
        endCursor: string | null;
        hasNextPage: boolean;
    } | null;
}

export interface PlayQueueModel {
    type: AudioInputType;
    playQueue: QueueTrackModel[];
    endlessPlay: QueueTrackModel[];
    isEndlessPlayOn: boolean;
    endlessPlayTrackId: string | null;
    context: PreviewContextModel | null;
    trackParent: TrackParentModel | null;
    trackParents: TrackParentModel[];
    mixRadioSession: MixRadioSession | null;
    mode: QueueMode;
    firstQueueSession: boolean;
    recentTracksLastUpdated: number;
    recentTrackIds: string[];
    version: 1 | null;
    historyOpen: boolean;
    priorityLaneWarning: boolean;
}
