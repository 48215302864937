import { h } from "preact";
import { Header } from "./Header";
import { Setting } from "./Setting";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { showInAppFeedbackModal } from "components/organisms/modal/modals";

export const FeedbackSettings = () => {
    const translations = useTranslations();

    const giveFeedback = async () => {
        showInAppFeedbackModal();
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    };
    const button = (
        <Button design={ButtonDesign.SecondarySmall} onClick={giveFeedback}>
            {translations.SettingsFeedbackTitle}
        </Button>
    );
    return (
        <div className="settingContainer">
            <Header icon={IconName.Feedback} title={translations.FeedBackTitle} />
            <Setting title={translations.SettingsDirectFeedback} subtitle={translations.SettingsFeedbackInfoText} button={button} />
        </div>
    );
};
