import { WebAppEnvironment, WebAppBrand } from "shared/models";
import { setTranslations } from "global/config";
import { getRuntimeEnvironment } from "services/app";
import { addQueryParameterToUrl } from "services/urlHelper";
import type { NuuApps } from "generated/localization";
import type { Language } from "models/view/Localization";

export enum LoginMode {
    OauthPopup = "oauthPopup",
    OauthNavigation = "oauthNavigation",
    OauthEmbedded = "oauthEmbedded"
}

export enum GraphqlEnvironmentName {
    Local = "local",
    Dev = "dev",
    Prod = "prod",
    Stage = "stage",
    Test = "test"
}

export enum EnvironmentPreset {
    Default = "default",
    Prod = "prod",
    Stage = "stage",
    Dev = "dev"
}

type ChromecastPortal = "yousee" | "telmore";

// const chromecastRecieverAppIds = { // old ones
//     telmoreDev: "6B9D8BAB",
//     telmoreProd: "9C16B8B7",
//     youSeeDev: "73E2CD23",
//     youSeeProd: "449E3874"
// };

const chromecastRecieverAppIds = {
    telmoreDev: "", // CC todo
    telmoreProd: "19269CB1",
    youSeeDev: "", // CC todo
    youSeeProd: "EDA3F5EA"
};

export interface EnvironmentConfig {
    graphqlEnvironmentName: GraphqlEnvironmentName;
    apiFrontPageId: string;
    apiMoreStationsCategoryId: string;
    apiPortalId: string;
    apiRadioPageId: string;
    chromecastPortal: ChromecastPortal;
    chromecastRecieverAppId: string;
    enableConcurrencyPolling: boolean;
    enableGraphQlSubscriptions: boolean;
    loginMode: LoginMode;
    buildEnvironment: WebAppEnvironment;
    runtimeEnvironment: WebAppEnvironment;
    contentEditorMode: boolean;
    sessionHackUrl: string | null;
}

export interface Environment extends EnvironmentConfig {
    webAppBrand: WebAppBrand;
}

export interface BuildEnvironment {
    environment: Environment;
    translations: { [key in Language]: NuuApps };
}

const environmentSessionStorageKey = "environment";

export let environment: Environment;

export function initEnvironment(env: BuildEnvironment) {
    try {
        environment = env.environment;
        setTranslations(env.translations);

        tryReadSessionEnvironment();
        readEnvironmentPresetFromUrl();
        readEnvironmentPropertiesFromUrl();
    } catch (e) {
        console.error({ code: "web-220217-1312", msg: "environment could not initiate", e });
        throw e;
    }
}

export function setEnvironmentPresetFromControlPanel(preset?: EnvironmentPreset): string {
    if (preset == null) return `possible environment presets: ${Object.values(EnvironmentPreset)}`;

    updateSessionEnvironmentFromPreset(preset);
    return logAndReload();
}

export function setEnvironmentPropertiesFromControlPanel(partial?: Partial<EnvironmentConfig>): string {
    if (partial == null) return `possible environment properties: ${Object.keys(getEnvironmentConfigDefault())}`;

    updateSessionEnvironment({ ...environment, ...partial });
    return logAndReload();
}

function logAndReload(): string {
    const status = `environment updated: ${JSON.stringify(environment)}`;
    // console.warn(status);

    setTimeout(() => {
        window.location.reload();
    }, 0);

    return status;
}

function tryReadSessionEnvironment() {
    const envStr = sessionStorage.getItem(environmentSessionStorageKey);
    if (envStr == null) return;

    const env: EnvironmentConfig = JSON.parse(envStr);
    // console.warn("custom session environment from storage", env);

    environment = { ...environment, ...env };
}

function readEnvironmentPresetFromUrl() {
    const params = new URLSearchParams(window.location.search);

    const preset = params.get("preset") as EnvironmentPreset | null;
    if (preset != null) {
        // console.warn(`custom session environment from url, preset: ${preset}`);
        updateSessionEnvironmentFromPreset(preset);
    }
}

function readEnvironmentPropertiesFromUrl() {
    const params = new URLSearchParams(window.location.search);
    const env = environment;
    let change = false;

    params.forEach((value, key) => {
        if (Object.keys(environment).indexOf(key) !== -1) {
            change = true;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (env as any)[key] = value;
        }
    });

    if (change) {
        // console.warn("custom session environment from url", env);
        updateSessionEnvironment(env);
    }
}

function updateSessionEnvironmentFromPreset(preset: EnvironmentPreset) {
    switch (preset) {
        case EnvironmentPreset.Default:
            resetSessionEnvironment();
            break;
        case EnvironmentPreset.Prod:
            updateSessionEnvironment(getEnvironmentConfigProd());
            break;
        case EnvironmentPreset.Stage:
            updateSessionEnvironment(getEnvironmentConfigStage());
            break;
        case EnvironmentPreset.Dev: {
            // console.error("preset not supported", preset);
            return;
        }
    }
}

function updateSessionEnvironment(env: EnvironmentConfig) {
    environment = { ...environment, ...env };
    sessionStorage.setItem(environmentSessionStorageKey, JSON.stringify(environment));
}

function resetSessionEnvironment() {
    environment = { ...environment, ...getEnvironmentConfigDefault() };
    sessionStorage.removeItem(environmentSessionStorageKey);
}

const isDevBuildEnvironment = () => process.env.NODE_ENV === "development";

function getEnvironmentConfigDefault(): EnvironmentConfig {
    switch (environment.webAppBrand) {
        case WebAppBrand.Telmore:
            return getEnvironmentConfigTelmoreDefault();
        case WebAppBrand.YouSee:
            return getEnvironmentConfigYouSeeDefault();
    }
}

function getEnvironmentConfigProd(): EnvironmentConfig {
    switch (environment.webAppBrand) {
        case WebAppBrand.Telmore:
            return getEnvironmentConfigTelmoreProd();
        case WebAppBrand.YouSee:
            return getEnvironmentConfigYouSeeProd();
    }
}

function getEnvironmentConfigStage(): EnvironmentConfig {
    switch (environment.webAppBrand) {
        case WebAppBrand.Telmore:
            return getEnvironmentConfigTelmoreStage();
        case WebAppBrand.YouSee:
            return getEnvironmentConfigYouSeeStage();
    }
}

export function getEnvironmentConfigTelmoreDefault(): EnvironmentConfig {
    const env = getEnvironmentConfigTelmoreProd();
    env.runtimeEnvironment = getRuntimeEnvironment();

    const isDevBuild = isDevBuildEnvironment();
    if (isDevBuild) {
        env.buildEnvironment = WebAppEnvironment.Development;
        // env.chromecastRecieverAppId = chromecastRecieverAppIds.telmoreDev;
    }

    return env;
}

export function getEnvironmentConfigTelmoreStage(): EnvironmentConfig {
    const env = getEnvironmentConfigTelmoreDefault();
    env.apiPortalId = "1787";
    env.apiFrontPageId = "5ac39715f65c4f244455d4ab";
    env.apiRadioPageId = "61ddf7389dd0ea0c04fcc958";

    return env;
}

export function getEnvironmentConfigYouSeeDefault(): EnvironmentConfig {
    const env = getEnvironmentConfigYouSeeProd();
    env.runtimeEnvironment = getRuntimeEnvironment();

    const isDevBuild = isDevBuildEnvironment();
    if (isDevBuild) {
        env.buildEnvironment = WebAppEnvironment.Development;
        // env.chromecastRecieverAppId = chromecastRecieverAppIds.youSeeDev;
    }

    return env;
}

export function getEnvironmentConfigYouSeeStage(): EnvironmentConfig {
    const env = getEnvironmentConfigYouSeeDefault();
    env.apiPortalId = "1858";
    env.apiFrontPageId = "5ac395f29dd0ea25e8ad26c1";
    env.apiRadioPageId = "61ddf552f65c4f09fc1a8089";

    return env;
}

function getEnvironmentConfigTelmoreProd(): EnvironmentConfig {
    return {
        apiPortalId: "1387",
        chromecastPortal: "telmore",
        chromecastRecieverAppId: chromecastRecieverAppIds.telmoreProd,
        enableConcurrencyPolling: true,
        enableGraphQlSubscriptions: false,
        apiFrontPageId: "615d6f939dd0ea1308e81609",
        apiMoreStationsCategoryId: "188722",
        graphqlEnvironmentName: GraphqlEnvironmentName.Prod,
        loginMode: LoginMode.OauthEmbedded,
        // apiRadioPageId: "63bbeb1521cd8e0afc409666", (mereRadio page)
        apiRadioPageId: "60c1f27e9dd0ec0d5c861165",
        buildEnvironment: WebAppEnvironment.Production,
        runtimeEnvironment: WebAppEnvironment.Production,
        contentEditorMode: false,
        sessionHackUrl: null
    };
}

function getEnvironmentConfigYouSeeProd(): EnvironmentConfig {
    return {
        apiPortalId: "1458",
        chromecastPortal: "yousee",
        chromecastRecieverAppId: chromecastRecieverAppIds.youSeeProd,
        enableConcurrencyPolling: true,
        enableGraphQlSubscriptions: false,
        apiFrontPageId: "615d6ce19dd0ea1308e81608",
        apiMoreStationsCategoryId: "188721",
        graphqlEnvironmentName: GraphqlEnvironmentName.Prod,
        loginMode: LoginMode.OauthNavigation,
        // apiRadioPageId: "63bbeaec21cd931724167157", (mereRadio page)
        apiRadioPageId: "60c1d9659dd0ec0d5c861164",
        buildEnvironment: WebAppEnvironment.Production,
        runtimeEnvironment: WebAppEnvironment.Production,
        contentEditorMode: false,
        sessionHackUrl: ""//https://login.yousee.dk/idp/startSLO.ping"
    };
}

export function addEnvironmentVariablesToUrl(url: string): string {
    const { graphqlEnvironmentName, apiPortalId } = environment;
    url = addQueryParameterToUrl(url, "graphqlEnvironmentName", graphqlEnvironmentName);
    url = addQueryParameterToUrl(url, "apiPortalId", apiPortalId);

    return url;
}
