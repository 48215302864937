import { h } from "preact";
import { useRef, useCallback, useState, useMemo, useEffect } from "preact/hooks";
import { useSelector } from "react-redux";
import type { SheetProps } from ".";
import "./BottomSheet.scss";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL, CONTEXT_MENU_SET_VISIBLE } from "global/actions";
import { isContextMenuSheetOpen } from "services/contextMenus";
import { OpenMenuResultAction } from "services/contextMenus/open";
import type { RootModel } from "models/app";
import { ScrollAxis } from "components/shared/hooks/useScrollPosition";
import { useScrollState, ScrollState } from "components/shared/hooks/useScrollState";
import { Button, ButtonDesign } from "components/atoms/controls/button";

interface BottomSheetProps extends SheetProps {
    openSmooth: boolean;
}

export const BottomSheet = ({ index, sheet, openSmooth }: BottomSheetProps) => {
    const scroll1Ref = useRef<HTMLDivElement | null>(null);
    const scroll2Ref = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const rootOpen = useSelector((root: RootModel) => root.menu.open);
    const open = useMemo(() => isContextMenuSheetOpen(rootOpen, sheet), [rootOpen, sheet]);

    const scrollState = useScrollState(scroll1Ref.current, scroll2Ref.current, ScrollAxis.y);

    const [scrollChanged, setScrollChanged] = useState(false);
    const [visibleNow, setVisibleNow] = useState(false);

    const close = useCallback(() => dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Cancel } } }), []);
    const setVisible = useCallback((visible: boolean) => dispatch({ type: CONTEXT_MENU_SET_VISIBLE, payload: { sheet, visible } }), [sheet]);

    useEffect(() => {
        const visibleNow = scrollState !== ScrollState.Start && scrollState != ScrollState.NoScroll;

        if (visibleNow && !scrollChanged) setScrollChanged(true);
        if (!visibleNow && scrollChanged) setVisibleNow(visibleNow);
    }, [scrollState, scrollChanged]);

    useEffect(() => {
        if (!visibleNow && scrollChanged) {
            setVisible(false);
            if (open) close();
        }
    }, [visibleNow, scrollChanged, open, close, setVisible]);

    useEffect(() => {
        return () => {
            setVisible(false);
        };
    }, [setVisible]);

    useEffect(() => {
        if (open) {
            setVisibleNow(true);
            setVisible(true);
            const to = scroll1Ref.current ? scroll1Ref.current.clientHeight - 64 : 0;
            scroll1Ref.current?.scrollTo({ top: to, behavior: "smooth" });
        } else {
            scroll1Ref.current?.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => setVisible(false), 1000);
        }
    }, [open, scroll1Ref, openSmooth, setVisible]);

    const prevent = useCallback((ev: MouseEvent) => {
        ev.stopPropagation();
    }, []);

    if (!visibleNow && !open) return null;

    return (
        <div className={`bottomSheet kyW test_contextMenu --open-${open} --scrollState-${scrollState}`} style={{ zIndex: index }}>
            <div className="background" onClick={close} />
            <div className="scroll1" ref={scroll1Ref}>
                <div className="scroll2" ref={scroll2Ref}>
                    <div className="top" />
                    <div className="sheet" onClick={prevent}>
                        <div className="content" ref={contentRef}>
                            {sheet.content}
                        </div>
                        <div className="bottom1">
                            <div className="bottom2">
                                <div className="menu">
                                    <Button className="close" design={ButtonDesign.ContextMenuBottom} onClick={close}>
                                        Luk vindue
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
