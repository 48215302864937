export interface ScrollingTextItem {
    stopTimeoutId: number | null;
    running: Date | false;
    start: () => Date;
    stop: () => void;
}

interface ScrollingTextGroup {
    startTimeoutId: number | null;
    waitTimeoutId: number | null;
    items: ScrollingTextItem[];
    add: (entry: ScrollingTextItem) => void;
    remove: (entry: ScrollingTextItem) => void;
}

const createScrollingTextGroup: () => ScrollingTextGroup = () => {
    const entries: ScrollingTextItem[] = [];

    const add = (item: ScrollingTextItem) => {
        entries.push(item);
        update();
    };

    const remove = (item: ScrollingTextItem) => {
        if (entries.indexOf(item) !== -1) entries.splice(entries.indexOf(item), 1);
        item.stop();
        update();
    };

    const stop = (item: ScrollingTextItem) => {
        if (item.stopTimeoutId != null) {
            clearTimeout(item.stopTimeoutId);
            item.stopTimeoutId = null;
        }
        item.running = false;
        item.stop();
    };

    const group: ScrollingTextGroup = {
        items: [],
        add,
        remove,
        waitTimeoutId: null,
        startTimeoutId: null
    };

    const tryReset = (): boolean => {
        if (entries.length == 0) {
            if (group.startTimeoutId != null) {
                clearTimeout(group.startTimeoutId);
                group.startTimeoutId = null;
            }
            return true;
        }
        return false;
    };

    const tryWait = (): boolean => {
        if (group.waitTimeoutId != null) {
            clearTimeout(group.waitTimeoutId);
            group.waitTimeoutId = null;
        }
        const next = entries.filter((entry) => entry.running).sort((a, b) => (b.running as Date).getTime() - (a.running as Date).getTime())[0]?.running ?? false;
        if (next != false) {
            const timeout = next.getTime() - new Date().getTime();
            if (timeout > 0) {
                group.waitTimeoutId = window.setTimeout(update, timeout);
                return true;
            }
        }
        return false;
    };

    const tryStart = (): boolean => {
        if (group.startTimeoutId != null) return false;
        group.startTimeoutId = window.setTimeout(() => {
            group.startTimeoutId = null;
            entries.forEach((entry) => {
                entry.running = entry.start();
                const timeout = entry.running.getTime() - new Date().getTime();
                entry.stopTimeoutId = window.setTimeout(() => {
                    stop(entry);
                }, timeout);
            });
            update();
        }, 4000);

        entries.forEach((entry) => {
            stop(entry);
        });
        return true;
    };

    const update = () => {
        if (tryReset()) return;
        if (tryWait()) return;
        tryStart();
    };
    return group;
};
export const scrollingTextGroup = createScrollingTextGroup();
