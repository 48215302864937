import { v4 as uuidv4 } from "uuid";
import { getLangFromState } from "components/app/hooks";
import { store } from "global";
import { WebAppEnvironment } from "shared/models";
import { getAppVersionString } from "shared/services/appSettings";
import { parseIntAsNormalPositiveNumberOrNull } from "shared/services/formatters";
import { environment } from "services/environment";
import { isAppRunAsPWA } from "services/pwa/initPWAService";
import { Language } from "models/view/Localization";

export function getNewInstallationId(): string {
    return uuidv4();
}

export function getNewCorrelationId(): string {
    return uuidv4();
}

export enum LanguageCode {
    enUs = "en-US",
    daDK = "da-DK"
}
enum ISOLanguageCode {
    en = "en",
    da = "da"
}

export function getLanguageCode(local: Language): LanguageCode {
    switch (local) {
        case Language.Danish:
            return LanguageCode.daDK;
        case Language.English:
            return LanguageCode.enUs;
    }
}
export function getISOLanguageCode(local: Language): ISOLanguageCode {
    switch (local) {
        case Language.Danish:
            return ISOLanguageCode.da;
        case Language.English:
            return ISOLanguageCode.en;
    }
}
export function getLanguageCodeFromState(): LanguageCode {
    const local = getLangFromState();
    return getLanguageCode(local);
}

export function getGraphQlLanguageCode(lang: LanguageCode): ISOLanguageCode {
    switch (lang) {
        case LanguageCode.daDK:
            return ISOLanguageCode.da;
        case LanguageCode.enUs:
            return ISOLanguageCode.en;
    }
}

export function getBrand() {
    return environment.webAppBrand;
}

export function getRuntimeEnvironment() {
    let origin = window.location.origin;
    origin = origin.replace("http://", "");
    origin = origin.replace("https://", "");

    if (origin === "musik.yousee.dk") return WebAppEnvironment.Production;
    if (origin === "musik.telmore.dk") return WebAppEnvironment.Production;

    return WebAppEnvironment.Development;
}

export enum Platform {
    Web = "Web",
    Desktop = "Desktop"
}

export function getPlatform(): Platform {
    return isAppRunAsPWA() ? Platform.Desktop : Platform.Web;
}

export function getInstallationId(): string {
    return store.getState().app.installationId;
}

export function getUserAgent(): string {
    return navigator.userAgent;
}

export function getAppProperties() {
    const appVersion = getAppVersionString();
    const installationId = getInstallationId();
    const language = getLanguageCodeFromState();
    const platform = getPlatform();
    const userAgent = getUserAgent();

    return {
        appVersion,
        installationId,
        language,
        platform,
        userAgent
    };
}

export enum NativeMobileAppStore {
    GooglePlayStore = "GooglePlayStore",
    AppleAppStore = "AppleAppStore"
}
export enum OperatingSystemName {
    Android = "Android",
    Linux = "Linux",
    Unknown = "Unknown",
    Windows = "Windows",
    iOS = "iOS",
    iPadOS = "iPadOS",
    iPodOS = "iPodOS",
    macOS = "macOS"
}

export function getOSName(): OperatingSystemName {
    const ua = navigator.userAgent;
    const contains = (keyword: string) => ua.indexOf(keyword) !== -1;

    if (contains("Android")) return OperatingSystemName.Android;
    if (contains("Linux")) return OperatingSystemName.Linux;
    if (contains("Windows")) return OperatingSystemName.Windows;
    if (contains("iPhone")) return OperatingSystemName.iOS;
    if (contains("iPad")) return OperatingSystemName.iPadOS;
    if (contains("iPod")) return OperatingSystemName.iPodOS;
    if (contains("Mac")) return OperatingSystemName.macOS;

    return OperatingSystemName.Unknown;
}

export const getNativeMobileAppStore = (): NativeMobileAppStore | null => {
    const os = getOSName();
    switch (os) {
        case OperatingSystemName.Android:
            return NativeMobileAppStore.GooglePlayStore;
        case OperatingSystemName.iOS:
        case OperatingSystemName.iPadOS:
        case OperatingSystemName.iPodOS:
            return NativeMobileAppStore.AppleAppStore;

        case OperatingSystemName.Linux:
        case OperatingSystemName.Unknown:
        case OperatingSystemName.Windows:
        case OperatingSystemName.macOS:
            return null;
    }
};
export const getShowNativeMobileAppPrompt = () => {
    return getNativeMobileAppStore() != null;
};

export enum BrowserName {
    Chrome = "Chrome",
    Chromium = "Chromium",
    Crawler = "Crawler",
    Edge = "Edge",
    Firefox = "Firefox",
    InternetExplorer = "InternetExplorer",
    Opera = "Opera",
    Safari = "Safari",
    Unknown = "Unknown"
}

interface BrowserDetection {
    must: RegExp;
    not?: RegExp[];
    name: BrowserName;
}

const browsers: BrowserDetection[] = [
    {
        name: BrowserName.Crawler,
        must: /bot|crawl|spider/i
    },
    {
        name: BrowserName.Opera,
        must: /OPR\/(\d+)/
    },
    {
        name: BrowserName.Opera,
        must: /Opera\/(\d+)/
    },
    {
        name: BrowserName.Edge,
        must: /Edg\/(\d+)/
    },
    {
        name: BrowserName.Firefox,
        must: /Firefox\/(\d+)/,
        not: [/Seamonkey\d+/]
    },
    {
        name: BrowserName.Chrome,
        must: /Chrome\/(\d+)/,
        not: [/Chromium\/\d+/]
    },
    {
        name: BrowserName.Chromium,
        must: /Chromium\/(\d+)/
    },
    {
        name: BrowserName.Safari,
        must: /Version\/(\d+).+Safari\/\d+/
    },
    {
        name: BrowserName.InternetExplorer,
        must: /; MSIE (\d+);/,
        not: []
    },
    {
        name: BrowserName.InternetExplorer,
        must: /Trident\/7.0; \.\*rv:(\d+)/
    }
];

export function getBrowserName(): BrowserName {
    return getBrowserNameAndVersion().name;
}

export function getBrowserNameAndVersion(): { name: BrowserName; version: number | null } {
    const ua = navigator.userAgent;

    const check = (browser: BrowserDetection): { name: BrowserName; version: number | null } | null => {
        const notMatch = browser.not?.some((value) => value.test(ua)) ?? false;
        if (notMatch) return null;

        const match = browser.must.exec(ua);
        if (!match || match[0] == null) return null;

        const versionStr = match[1];
        const version = parseIntAsNormalPositiveNumberOrNull(versionStr);

        return { name: browser.name, version };
    };

    for (const browser of browsers) {
        const detected = check(browser);
        if (detected != null) return detected;
    }

    return { name: BrowserName.Unknown, version: null };
}

export function getWindowInnerSizeString(): [number, number] {
    return [window.innerWidth, window.innerHeight];
}
