import { getLocalCastQueue } from "../actions";
import { getCastMediaSession, hasCastMediaSession } from "../setup";
import type { AudioInputItemModel } from "models/app/player/input";

export function getCurrentAudioFromRemote(): AudioInputItemModel | null {
    const currentRemoteId = getCurrentRemoteCastId();
    if (currentRemoteId == null) return null;

    const audio = getLocalCastQueue().audios.find((audio) => audio.chromecastId === currentRemoteId);
    return audio ?? null;
}

export function getCurrentAudioInSync(): { currentSynced: boolean; currentLocal: AudioInputItemModel | null } {
    const currentLocal = getLocalCastQueue().currentAudio;

    const currentLocalId = currentLocal?.chromecastId ?? null;
    const currentRemoteId = getCurrentRemoteCastId();

    const inSync = currentLocalId == currentRemoteId;
    return { currentSynced: inSync, currentLocal };
}

export function getCurrentRemoteCastId(): number | null {
    if (!hasCastMediaSession()) return null;

    const media = getCastMediaSession();
    if (!media) return null;

    const currentCastId = media.loadingItemId ?? media.currentItemId;
    return currentCastId;
}
