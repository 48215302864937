import type { ArtistPreviewModel, ArtistLinkModel } from "./ArtistModel";
import { createArtistLinkModel } from "./ArtistModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createArtistPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelLinkInterface, ResourceModelInterface } from "./ResourceModel";
import type { ArtistSimilarArtistsPageFragment, ArtistSimilarArtistsSectionFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type ArtistSimilarArtistsSectionData = ArtistSimilarArtistsSectionFragment;
type ArtistSimilarArtistsPageData = ArtistSimilarArtistsPageFragment;

export type ArtistSimilarArtistsModel = ArtistSimilarArtistsSectionModel;
export type ArtistSimilarArtistsSectionModel = ({ type: DomainModelType.Section } & ArtistSimilarArtistsSection) | ArtistSimilarArtistsPageModel;
export type ArtistSimilarArtistsPageModel = { type: DomainModelType.Page } & ArtistSimilarArtistsPage;

interface ArtistSimilarArtistsSection extends ResourceModelLinkInterface, ResourceModelInterface {
    readonly contentType: ContentType.ArtistSimilarArtists;
    readonly artists: ConnectionModel<ArtistPreviewModel> | null;
}
interface ArtistSimilarArtistsPage extends ArtistSimilarArtistsSection {
    readonly artist: ArtistLinkModel;
}

export function createArtistSimilarArtistsSectionModel(data: ArtistSimilarArtistsSectionData, id: string): ArtistSimilarArtistsSectionModel {
    return {
        type: DomainModelType.Section,
        contentType: ContentType.ArtistSimilarArtists,
        id,
        graphType: data.__typename,
        cover: null,
        artists: data.similarArtists ? createArtistPreviewConnectionModel(data.similarArtists) : null
    };
}

export function createArtistSimilarArtistsPageModel(data: ArtistSimilarArtistsPageData): ArtistSimilarArtistsPageModel {
    return {
        ...createArtistSimilarArtistsSectionModel(data, data.id),
        type: DomainModelType.Page,
        artist: createArtistLinkModel(data)
    };
}
