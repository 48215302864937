import { h } from "preact";
import { useCallback } from "preact/hooks";
import { isDraggableCurrentLane } from "./isDraggableCurrentLane";
import type { LaneProps } from "./LaneProps";
import { useTranslations } from "global/config";
import type { DraggablePlayableModel } from "models/app";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import { DropZone } from "components/molecules/dropZone";
import { QueueTrackRow } from "components/molecules/preview/rows";
import { QueueListView } from "components/organisms/recyclerView";

interface Props extends LaneProps {
    historyOpen: boolean;
}
export const PriorityLane = ({ items, dragProps, sectionContext, dragSourceId, onDrop, historyOpen, scrollOnTrackClickFn }: Props) => {
    const translations = useTranslations();
    const allowDrop = useCallback((item: DraggablePlayableModel) => !isDraggableCurrentLane(item), []);

    const mapper = useCallback(
        (track: QueueTrackModel) => (
            <QueueTrackRow
                scrollOnTrackClickFn={scrollOnTrackClickFn}
                dragProps={dragProps}
                dropProps={{ dragSourceId, onDrop: (item) => onDrop(item, track.lane, false, track) }}
                key={track.queueId}
                queueTrack={track}
                sectionContext={sectionContext}
                allowDrop={allowDrop}
            />
        ),
        [allowDrop, dragProps, dragSourceId, onDrop, scrollOnTrackClickFn, sectionContext]
    );

    return (
        <section className={`queueLane --size-${historyOpen ? "big" : "small"}`}>
            <div className="top">
                <h1 className="headline">{translations.QueuePriorityLaneTitle}</h1>
                <DropZone customClass="second" allowDrop={allowDrop} onDropFn={(item) => onDrop(item, QueueLane.Priority, false)} />
            </div>
            <QueueListView resources={items} mapper={mapper} />
            <div className="bottom">
                <DropZone allowDrop={allowDrop} onDropFn={(item) => onDrop(item, QueueLane.Priority, true)} />
            </div>
        </section>
    );
};
