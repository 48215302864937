import { ComponentChildren, h } from "preact";
import "./MessagePageTemplate.scss";
import { PageTopBar } from "components/molecules/pageTopBar";

interface Props {
    title: string;
    text?: string;
    buttons?: ComponentChildren;
    className: string;
}

export const MessagePageTemplate = ({ title, text, buttons, className }: Props) => {
    return (
        <div tabIndex={-1} className={`messagePageTemplate BVL ${className}`}>
            <PageTopBar back={true} />
            <div className="content">
                <div className="title">{title}</div>
                {text && <div className="text">{text}</div>}
                {buttons && <div className="buttons">{buttons}</div>}
            </div>
        </div>
    );
};
