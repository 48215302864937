import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { PreviewAction } from "../PreviewProps";
import { SmallPreviewPlayButton } from "../SmallPreviewPlayButton";
import { TestLocator } from "global/constants";
import type { OpenMenuResult } from "services/contextMenus";
import { isPlayable } from "services/playable";
import { getResourceAvailableInSubscription, getHasContextMenu } from "services/resource";
import type { DraggableItemModel } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { ButtonDesign } from "components/atoms/controls/button";
import { ButtonMore } from "components/atoms/controls/button/buttons";
import type { CoverRounding, CoverSize } from "components/atoms/cover";
import { ResourceCover } from "components/atoms/cover";
import { IconName } from "components/atoms/icon";

interface Props {
    available: boolean;
    context: PreviewContextModel;
    disableMoreButton: boolean;
    draggableItem?: () => DraggableItemModel | null;
    dynamicSize: boolean | undefined;
    mainAction: PreviewAction;
    moreOpen: boolean;
    onAction: (action: PreviewAction) => void;
    onContextMenu?: (ev: MouseEvent) => Promise<OpenMenuResult | undefined>;
    resource: ResourcePreviewModel;
    rounding: CoverRounding | undefined;
    setMoreOpen: (open: boolean) => void;
    size: CoverSize;
    disableLazyLoad: boolean;
    highlight?: boolean;
}

export const ResourcePreviewSquareCover = ({
    available,
    context,
    disableMoreButton,
    draggableItem,
    dynamicSize,
    mainAction,
    moreOpen,
    onAction,
    onContextMenu,
    resource,
    rounding,
    setMoreOpen,
    size,
    disableLazyLoad,
    highlight
}: Props) => {
    const onMainAction = useCallback(() => onAction(mainAction), [onAction, mainAction]);
    const hasTracks = useMemo(() => !(resource.contentType === ContentType.Playlist && resource.trackCount === 0), [resource]);

    const playButton = hasTracks && available && isPlayable(resource) && getResourceAvailableInSubscription(resource) && (
        <SmallPreviewPlayButton playable={resource} context={context} onAction={onAction} />
    );
    const moreButton = getHasContextMenu(resource.contentType, resource) && (
        <ButtonMore
            disabled={disableMoreButton}
            resource={resource}
            context={context}
            onOpen={setMoreOpen}
            design={ButtonDesign.LightSmall}
            icon={IconName.Meatballs16}
            testLocator={TestLocator.PreviewMoreButton}
        />
    );

    const hasButtons = playButton || moreButton;

    return (
        <ResourceCover
            className={`resourcePreviewSquareCover j6I --moreOpen-${moreOpen}`}
            context={context}
            disabled={!available}
            draggableItem={available ? draggableItem : undefined}
            dynamicSize={dynamicSize}
            navigate={mainAction === PreviewAction.Navigate}
            onClick={onMainAction}
            onContextMenu={onContextMenu}
            rounding={rounding}
            size={size}
            highlight={highlight}
            disableLazyLoad={disableLazyLoad}>
            {hasButtons && (
                <div className="buttons">
                    {playButton}
                    {moreButton}
                </div>
            )}
        </ResourceCover>
    );
};
