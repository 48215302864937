import { pagination } from "global/constants/pagination";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import type { ConnectionModel } from "models/domain";
import { ConnectionState } from "models/domain";
import type { ConnectionFetchFn } from "components/shared/hooks/useConnectionHandler";
import { openToast } from "components/organisms/toast";
import { LoadMorePageContentFailToast } from "components/organisms/toast/toasts";

export interface ConnectionHandler<T> {
    connection: ConnectionModel<T>;
    fetchFn?: ConnectionFetchFn<T>;
    more: (value?: number) => void;
    modify: (convert: (item: T[]) => T[]) => void;
    getMore: number;
    inCall: boolean;
    state: ConnectionState;
    onChange?: () => void;
    defaultMore?: number;
    dispose: () => void;
}

export interface Props<T> {
    connection: ConnectionModel<T>;
    fetchFn?: ConnectionFetchFn<T>;
    eagerLoadingCount: number;
    max?: number;
}

export function createConnectionHandler<T>({ connection, fetchFn, eagerLoadingCount, max }: Props<T>): ConnectionHandler<T> {
    let handler: ConnectionHandler<T> | null = null;

    const getDefaultMore = () => handler?.defaultMore ?? pagination.paginationDefaultMore;

    const run = async (): Promise<void> => {
        if (!fetchFn) return;
        if (!handler) {
            log.error({ code: "web-210218-0904", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }

        const items = handler.connection.items.length;
        if (handler.inCall) return;
        if (items >= handler.getMore && items >= eagerLoadingCount) return;
        if (max != null && items >= max) return;
        if (!handler.connection.pageInfo.hasNextPage) return;

        handler.inCall = true;
        handler.state = ConnectionState.Fetching;

        //const connection = handler.connection;

        const after = handler.connection.pageInfo.endCursor;
        const first = getDefaultMore();

        if (after == null) {
            log.error({ code: "web-210222-1407", msg: DefaultLogMessage.UnexpectedNull });
            handler.inCall = false;
            handler.state = ConnectionState.UnknownError;
            if (handler.onChange) handler.onChange();
            return;
        }

        // log.info({ code: "web-210225-1748", msg: "paginate fetch...", data: { first, after } });

        const newConnection = (await fetchFn(first, after, handler.connection.items.length))?.model ?? null;

        // log.info({
        //     code: "web-210225-1749",
        //     msg: "...paginate fetched",
        //     data: {
        //         fetched: newConnection?.items.length ?? 0,
        //         "has more": newConnection?.pageInfo.hasNextPage
        //     }
        // });

        if (!newConnection) {
            log.error({ code: "web-210218-1845", msg: "could not get connection" });
            handler.inCall = false;
            handler.state = ConnectionState.UnknownError; // todo

            openToast(LoadMorePageContentFailToast());

            if (handler.onChange) handler.onChange();
            return;
        }

        handler.connection.items = [...handler.connection.items, ...newConnection.items];
        handler.connection.pageInfo = newConnection.pageInfo;
        //handler.connection = connection;

        handler.inCall = false;
        handler.state = ConnectionState.Ok;

        if (handler.onChange) handler.onChange();
        run();
    };

    const more = (value?: number) => {
        if (!handler) {
            log.error({ code: "web-210218-0906", msg: DefaultLogMessage.UnexpectedNull });
            return false;
        }
        value = value ?? handler.connection.items.length + 50;

        if (handler.getMore === value) return;
        handler.getMore = value;

        if (value) run();
    };

    const modify = (convert: ((item: T[]) => T[]) | null) => {
        if (handler && convert) {
            const newItems = convert(handler.connection.items);
            if (newItems !== handler.connection.items) {
                handler.connection.items = newItems;
                if (handler.onChange) handler.onChange();
            }
        }
    };

    handler = {
        connection,
        fetchFn,
        more,
        modify,
        getMore: 0,
        inCall: false,
        state: ConnectionState.Ok,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        dispose: () => {}
    };

    return handler;
}
