import type { ConnectionModel } from "./ConnectionModel";
import { createLiveRadioPreviewConnectionModel } from "./ConnectionModel";
import type { LiveRadioPreviewModel } from "./LiveRadioModel";
import type { ResourceModelInterface, ResourceModelLinkInterface } from "./ResourceModel";
import type { LiveRadioCategoryPageFragment, LiveRadioCategoryPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type LiveRadioCategoryPreviewData = LiveRadioCategoryPreviewFragment;
type LiveRadioCategoryPageData = LiveRadioCategoryPageFragment;

export type LiveRadioCategoryModel = LiveRadioCategoryPreviewModel;
export type LiveRadioCategoryPreviewModel = ({ type: DomainModelType.Preview } & LiveRadioCategoryPreview) | LiveRadioCategoryPageModel;
export type LiveRadioCategoryPageModel = { type: DomainModelType.Page } & LiveRadioCategoryPage;

interface LiveRadioCategoryPreview extends ResourceModelLinkInterface, ResourceModelInterface {
    readonly contentType: ContentType.LiveRadioCategory;
    readonly title: string;
    readonly description: string;
}

interface LiveRadioCategoryPage extends LiveRadioCategoryPreview {
    radios: ConnectionModel<LiveRadioPreviewModel> | null;
}

export function createLiveRadioCategoryPreviewModel(data: LiveRadioCategoryPreviewData): LiveRadioCategoryPreviewModel {
    return {
        contentType: ContentType.LiveRadioCategory,
        graphType: data.__typename,
        id: data.id,
        title: data.title,
        cover: data.cover ?? null,
        type: DomainModelType.Preview,
        description: data.description
    };
}

export function createLiveRadioCategoryPageModel(data: LiveRadioCategoryPageData): LiveRadioCategoryPageModel {
    return {
        ...createLiveRadioCategoryPreviewModel(data),
        type: DomainModelType.Page,
        radios: data.radios ? createLiveRadioPreviewConnectionModel(data.radios) : null
    };
}
