import { serviceWorkerConfig } from "shared/services";
import { log } from "services/logger";

export function initCacheQuotaService() {
    const run = () => {
        setTimeout(async () => {
            await enforceCacheQuotas();
            run();
        }, 60_000);
    };
    run();
}

async function enforceCacheQuotas() {
    await tryEnforceCacheQuota(serviceWorkerConfig.cacheNameGraphql, serviceWorkerConfig.maxCacheEntriesGraphql);
    await tryEnforceCacheQuota(serviceWorkerConfig.cacheNameHls, serviceWorkerConfig.maxCacheEntriesHls);
    await tryEnforceCacheQuota(serviceWorkerConfig.cacheNameNavigate, serviceWorkerConfig.maxCacheEntriesNavigate);
}

async function tryEnforceCacheQuota(cacheName: string, max: number) {
    try {
        await enforceCacheQuota(cacheName, max);
    } catch (e) {
        log.error({ code: "web-220816-1147", msg: "error in enforceCacheQuotas", data: { cacheName }, error: e });

        try {
            caches.delete(cacheName);
        } catch (e) {
            log.info({ code: "web-230403-1023", msg: "cannot delete cache", data: { cacheName }, error: e });
        }
    }
}

async function enforceCacheQuota(cacheName: string, max: number) {
    const cache = await caches.open(cacheName);
    const keys = await cache.keys();

    if (keys.length <= max) return;

    const remove = keys.slice(0, keys.length - max);
    await Promise.all(
        remove.map((value) => {
            return cache.delete(value.url);
        })
    );
}
