import type { ComponentChildren, ComponentChild } from "preact";
import type { OpenMenuResult } from "services/contextMenus";
import type { DraggableItemModel, DropProps } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourceContentType } from "models/domain";

export enum PreviewAction {
    Expand = "Expand",
    Navigate = "Navigate",
    None = "None",
    Play = "Play"
}

export interface PreviewProps {
    activated?: boolean;
    available?: boolean;
    editable?: boolean;
    highlight?: boolean;
    className?: string;
    context: PreviewContextModel;
    draggableItem?: () => DraggableItemModel | null;
    dropProps: DropProps | null;
    image?: ComponentChildren;
    links: ComponentChild[];
    mainAction: PreviewAction;
    onAction: ((ev: MouseEvent) => void) | null;
    onContextMenu?: (ev: MouseEvent) => Promise<OpenMenuResult | undefined>;
    resourceType: ResourceContentType;
}
