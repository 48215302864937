import { h } from "preact";
import { Modal, modalButtonSubmitClose, openModal } from "..";
import { useTranslations } from "global/config";

export async function showInAppFeedbackSubmitModal() {
    return openModal(<InAppFeedbackSubmitModal />);
}

export function InAppFeedbackSubmitModal() {
    const translations = useTranslations();
    return <Modal title={translations.FeedbackSuccessModalTitleWeb} text={translations.FeedbackSuccessModalTextWeb} buttons={[modalButtonSubmitClose()]} />;
}
