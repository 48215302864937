import { gql } from "@apollo/client";
import type { Subscription } from "zen-observable-ts";
import { closeWebsocket, graphqlClient, ensureWebsocketRoutesToUser } from "./graphqlClient";
import { dispatch, messageBus } from "global";
import { FAVORITES_UPDATED, USER_LOGGED_IN, USER_LOGGING_OUT, USER_UPDATED_TOKENS } from "global/actions";
import { getTokens } from "services/appSession/operations/sessionTokens";
import { environment } from "services/environment";
import type { FavoritePreviewModel } from "services/favorites";
import { log, LogTag } from "services/logger";
import { getIsLoggedInFromState } from "services/user";
import { ContentType } from "models/ModelType";

const myMusicSubscription = gql`
    subscription myMusicUpdate($token: String!) {
        myMusicUpdates(token: $token) {
            updateType
            playlistId
            trackId
        }
    }
`;

let subscription1: Subscription | null = null;
let subscription2: Subscription | null = null;

export async function initGraphqlSubscriptions() {
    try {
        if (!environment.enableGraphQlSubscriptions) return;

        const isLoggedIn = await getIsLoggedInFromState();

        if (isLoggedIn) {
            subscribe();
        }

        messageBus.subscribeEvery(USER_LOGGED_IN, async (msg) => {
            ensureWebsocketRoutesToUser(msg.payload.id);
            subscribe();
        });

        messageBus.subscribeEvery(USER_LOGGING_OUT, async () => {
            unsubscribe();
            closeWebsocket();
        });

        messageBus.subscribeEvery(USER_UPDATED_TOKENS, (msg) => {
            // recreate the subscription if the user gets a new access token
            if (isSubscribed() && msg.payload.accessToken && subscribedAccessToken != msg.payload.accessToken) {
                resubscribe();
            }
        });
    } catch (e) {
        log.error({ code: "web-220221-1326", msg: "error in initiating graphql subscriptions", error: e });
    }
}

const isSubscribed = () => !!subscription1 || !!subscription2;

let subscribedAccessToken: string | null = null;

async function subscribe() {
    log.info({ code: "web-220926-1538", msg: "--- subscribing", tags: [LogTag.Subscriptions] });
    if (!isSubscribed()) {
        const { accessToken } = await getTokens();

        // mymusic subscription
        const observable1 = graphqlClient?.subscribe({
            query: myMusicSubscription,
            variables: {
                token: accessToken
            }
        });
        subscription1 =
            observable1?.subscribe(async (event) => {
                log.info({ code: "web-220926-1539", msg: "favorites observable", data: { data: event.data }, tags: [LogTag.Subscriptions] });

                if (event.data.favoritesUpdates?.updateType == "FAVORITED_TRACK" || event.data.favoritesUpdates?.updateType == "UNFAVORITED_TRACK") {
                    const fakeTrack = {
                        contentType: ContentType.TrackPlayable,
                        id: event.data.favoritesUpdates.trackId
                    } as FavoritePreviewModel;

                    const operation = event.data.favoritesUpdates?.updateType == "FAVORITED_TRACK" ? "add" : "remove";

                    dispatch({
                        type: FAVORITES_UPDATED,
                        payload: {
                            changes: {
                                playables: [fakeTrack],
                                operation
                            }
                        }
                    });
                }
            }) || null;

        if (accessToken == null) {
            log.error({ code: "web-220324-1153", msg: "accessToken is null" });
            return;
        }
        // concurrency subscription
        //TODO Ask Brian
        // const observable2 = graphqlClient?.subscribe({
        //     query: ActivePlaybackStreamChangedDocument,
        //     variables: {
        //         token: accessToken,
        //         deviceId: store.getState().app.installationId
        //     }
        // });
        // subscription2 =
        //     observable2?.subscribe((event) => {
        //         const data = event?.data?.activePlaybackStreamChanged;
        //         if (data) {
        //             dispatch({
        //                 type: PLAYER_CONCURRENCY_LOST,
        //                 payload: {
        //                     deviceId: data.deviceId ?? "",
        //                     friendlyDeviceName: data.friendlyDeviceName
        //                 }
        //             });
        //         }
        // }) || null;

        //subscription?.unsubscribe();
        log.info({ code: "web-220926-1540", msg: "GraphQL subscriptions active", tags: [LogTag.Subscriptions] });
    }
}

function unsubscribe() {
    if (subscription2) {
        subscription2.unsubscribe();
        subscription2 = null;
        log.info({ code: "web-220926-1541", msg: "GraphQL concurrency subscription terminated", tags: [LogTag.Subscriptions] });
    }
    if (subscription1) {
        subscription1.unsubscribe();
        subscription1 = null;
        log.info({ code: "web-220926-1542", msg: "GraphQL favorites subscription terminated", tags: [LogTag.Subscriptions] });
    }
    subscribedAccessToken = null;
}

function resubscribe() {
    unsubscribe();
    subscribe();
}
