import { addCastContextListeners, removeCastContextListeners } from "../setup";
import { setPlayerModeLocal } from "./setPlayerMode";
import { dispatch } from "global";
import { CAST_SET_AVAILABLE } from "global/actions";
import { environment } from "services/environment";
import { log, LogTag } from "services/logger";
import { AudioContextAction } from "models/app/player/AudioContext";

export function setCastAvailable(isAvailable: boolean) {
    const context = { action: AudioContextAction.ChromecastApiAvailable, trace: null };
    dispatch({ type: CAST_SET_AVAILABLE, payload: { isAvailable, context } });

    if (isAvailable) {
        log.info({ tags: [LogTag.Chromecast], code: "web-230106-1534", msg: `setting chromecastRecieverAppId: ${environment.chromecastRecieverAppId}` });

        cast.framework.CastContext.getInstance().setOptions({
            receiverApplicationId: environment.chromecastRecieverAppId,
            autoJoinPolicy: chrome.cast.AutoJoinPolicy.TAB_AND_ORIGIN_SCOPED
        });

        addCastContextListeners();
    } else {
        setPlayerModeLocal(context);
        removeCastContextListeners();
    }
}
