import { getNavigationRootPreview } from "services/navigationStack/navigationStack";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { LocationContentType } from "models/domain";

export type AnalyticsRootPreviewProperties = {
    rootPreviewName?: string;
    rootPreviewId?: string;
    rootPreviewRank?: number;
    rootPreviewType?: LocationContentType;
};

export function getAnalyticsRootPreviewProperties(preview: PreviewContextModel): AnalyticsRootPreviewProperties {
    const root = getNavigationRootPreview(preview);
    if (!root) return {};

    const rootPreviewName = root.preview?.name ?? undefined;
    const rootPreviewId = root.preview?.id ?? undefined;
    const rootPreviewRank = root.preview?.rank ?? undefined;
    const rootPreviewType = root.preview?.type ?? undefined;

    return {
        rootPreviewName,
        rootPreviewId,
        rootPreviewRank,
        rootPreviewType
    };
}
