import { BrowserName, getBrowserNameAndVersion } from "services/app/appService";
import { log } from "services/logger";

let elements: HTMLAudioElement[] | null = null;

const shouldUseElementPool = () => {
    const { name, version } = getBrowserNameAndVersion();
    return name === BrowserName.Safari && version != null && version >= 14;
};

export function initElementPool() {
    if (!shouldUseElementPool()) return;

    for (let i = 0; i < 10; i++) {
        const audio = new Audio();
        audio.preload = "none";
        audio.autoplay = false;
        audio.src = "https://nuuday.dk/file.mp3";
        if (!elements) elements = [];
        elements.push(audio);
    }
}

export function addElementToPool(element: HTMLAudioElement) {
    if (!elements) return;

    const invalid = "2de19c3a-0f10-4b32-9475-7e55668d6f00";

    element.preload = "none";
    element.autoplay = false;
    element.src = `https://${invalid}.com/${invalid}.mp3`;
    elements.push(element);
}

export function getElementFromPool(): HTMLAudioElement | null {
    if (!elements) return null;

    const element = elements.pop() ?? null;
    if (!element) {
        log.error({ code: "web-211216-13227", msg: "element is null from pool" });
    }
    return element;
}
