import { h } from "preact";
import { useCallback, useRef } from "preact/hooks";
import { useSelector } from "react-redux";
import { CastButton } from "../castButton";
import { LiveRadioTitle } from "../liveRadioTitle";
import { VolumeExpandButton, ExpandDirection } from "../volumeExpandButton";
import { Feature, isFeatureEnabled } from "global/config/featuretoggle";
import { TestLocator } from "global/constants";
import { getShortTimeSpanFromSecondsHHMMSS } from "services/formatters";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import { isQueueViewDisabled } from "services/player/inputs/inputs/playQueue/helpers";
import { useLoginState } from "services/user";
import { LoginState } from "models/app";
import type { RootModel } from "models/app";
import { AudioInputType } from "models/app/player";
import { useContextMenu } from "components/shared/hooks";
import { useMatchMedia } from "components/shared/hooks/useMatchMedia";
import { useSecondsPlayedFromState } from "components/shared/hooks/useSecondsPlayed";
import { Button } from "components/atoms/controls/button";
import { VolumeRange } from "components/atoms/controls/volumeRange";
import { IconName } from "components/atoms/icon";
import { PlayerDesktopContextMenu } from "components/organisms/contextMenu/menus";
import { toggleLyrics, useLyricsState } from "components/organisms/lyrics";

interface Props {
    showQueue: boolean;
    toggleQueue: () => void;
    seekPosition?: number | null;
}

export const DesktopPlayerSecondaryControls = ({ showQueue, toggleQueue, seekPosition }: Props) => {
    const { secondsPlayed, duration } = useSecondsPlayedFromState();
    const isTabletLarge = useMatchMedia({ maxWidth: 850 });
    const isDesktopSmall = useMatchMedia({ minWidth: 980 });
    const isDesktopLarge = useMatchMedia({ minWidth: 1280 });

    const { isLyricsEnabled, isLyricsAvailable, isLyricsOpen } = useLyricsState();

    const seconds = seekPosition || secondsPlayed;
    const buttonMoreRef = useRef<HTMLButtonElement>(null);
    const menuButtonMoreClick = useContextMenu(<PlayerDesktopContextMenu />, false);

    const onMoreButtonClick = () => {
        if (!buttonMoreRef.current) {
            log.error({ code: "web-210519-1224", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        menuButtonMoreClick.open(buttonMoreRef.current);
    };

    const onToggleLyrics = useCallback(() => toggleLyrics("DesktopPlayer"), [])

    const isInputLiveRadio = useSelector((root: RootModel) => root.player.input) === AudioInputType.LiveRadio;
    const isQueueDisabled = isQueueViewDisabled();


    return (
        <div className="secondaryControls">
            {isTabletLarge && <Button className="more" onClick={onMoreButtonClick} ref={buttonMoreRef} icon={IconName.Meatballs} activated={menuButtonMoreClick.isOpen} />}
            {isInputLiveRadio ? (
                <LiveRadioTitle />
            ) : (
                isDesktopSmall &&
                duration !== 0 && (
                    <div className="secondsPlayed">
                        {getShortTimeSpanFromSecondsHHMMSS(seconds)} / {getShortTimeSpanFromSecondsHHMMSS(duration)}
                    </div>
                )
            )}
            {isDesktopLarge ? <VolumeRange /> : <VolumeExpandButton direction={ExpandDirection.Up} />}
            {!isTabletLarge && <CastButton />}
            {
                !isTabletLarge && isLyricsEnabled && (isLyricsAvailable || isFeatureEnabled(Feature.LyricsIconWhenNotAvailable)) &&
                <Button
                    onClick={onToggleLyrics}
                    disabled={!isLyricsAvailable && !isLyricsOpen}
                    toggled={isLyricsOpen}
                    icon={IconName.Lyrics}
                    testLocator={TestLocator.PlayerLyricsToggleButton}
                />
            }
            <Button
                onClick={toggleQueue}
                disabled={isQueueDisabled}
                toggled={!isQueueDisabled && showQueue}
                icon={IconName.Queue}
                testLocator={TestLocator.PlayerQueueToggleButton}
            />
        </div>
    );
};
