import { authenticate } from "../../actions";
import type { LogProps } from "services/logger";
import { log, LogTag } from "services/logger";

let onAuthResolvers: ((authenticated: boolean) => void)[] = [];
export async function onNextAuthenticated(invoke: boolean): Promise<boolean> {
    const promise = new Promise<boolean>((resolve) => {
        onAuthResolvers.push(resolve);
    });

    if (invoke) await authenticate();

    return promise;
}

export function onCustomCastMessageAuthenticate(namespace: string, message: string) {
    const ok = (message as unknown) === true || message === "true" || message === '"true"' || message === "'true'";

    const logProps: LogProps = { tags: [LogTag.Chromecast], code: "web-210915-1538", msg: `onCustomCastMessageAuthenticate: ${ok}`, data: { namespace, message } };

    if (ok) log.info(logProps);
    else log.error(logProps);

    const resolvers = onAuthResolvers;
    onAuthResolvers = [];

    resolvers.forEach((resolve) => resolve(ok));
}
