import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "..";
import { DefaultLogMessage, log } from "services/logger";
import type { ContextResourceModel, PreviewContextModel } from "models/app/resourceContextModel";
import { openToast } from "components/organisms/toast";
import { CopyIdFailedToast, CopyIdSuccessToast } from "components/organisms/toast/toasts";

interface Props {
    context: PreviewContextModel;
}

async function copyTrackIdToClipboard(resource: ContextResourceModel) {
    const id = resource?.id;
    if (id == null) {
        log.error({ code: "web-220224-1322", msg: DefaultLogMessage.UnexpectedNull, data: { resource } });
        return;
    }

    try {
        await navigator.clipboard.writeText(id);
        openToast(CopyIdSuccessToast());
    } catch (e) {
        log.error({ code: "web-220224-1423", msg: "id could not be copied", data: { resource }, error: e });
        openToast(CopyIdFailedToast());
    }
}

export const CopyIdLink = ({ context }: Props) => {
    const resource = context.resource;

    const copyToClipBoard = useCallback(() => {
        if (!resource) {
            log.error({ code: "web-220719-1112", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        copyTrackIdToClipboard(resource);
    }, [resource]);

    return (
        <Link icon={undefined} onClick={copyToClipBoard}>
            Copy track ID
        </Link>
    );
};
