import { h } from "preact";
import type { PageContextModel } from "models/app/resourceContextModel";
import { getLayoutPageItemConnectionModelItems } from "models/domain/helpers";
import type { LayoutItemSectionModel } from "models/domain/layoutPage";
import { useSectionContext } from "components/shared/hooks";
import { useResourcesFromUnions } from "components/shared/hooks/useResources";
import { ResourceSection } from "components/organisms/resourceSection";

interface ItemProps {
    item: LayoutItemSectionModel;
    page: PageContextModel;
    rank: number;
}

export const LayoutPageItem = ({ item, page, rank }: ItemProps) => {
    const section = useSectionContext(item.contentType, item, page, rank, item.displayType, item.id);
    const connection = getLayoutPageItemConnectionModelItems(item.content);
    const items = useResourcesFromUnions(connection?.items ?? null, item);

    return <ResourceSection resources={items} context={section} key={item.id} showTitle showAll={item} skeletonItems />;
};
