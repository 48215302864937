import type { Action} from "../actions";
import { AUDIO_OUTPUT_AUDIO_DURATION_CHANGE ,
    AUDIO_INPUT_AUDIO_SRC_CHANGE,
    PLAYQUEUE_CLEAR,
    AUDIO_INPUT_AUDIOS_CHANGE,
    AUDIO_INPUT_AUDIO_PLAY_CHANGE,
    AUDIO_OUTPUT_AUDIO_FAILED,
    REHYDRATE
} from "../actions";
import { tryUpdateArray } from "services/arrayHelper";
import { updateNormalizedAnalyticsQueueAdded } from "services/normalizeData";
import { getNewCastItemId } from "services/player/controllers/chromeCast/service/helpers";
import { getLastPositionFromSessionStorage } from "services/player/inputs/service/actions";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioInputReducerModel } from "models/app/player/input";

const initialState: AudioInputReducerModel = {
    audios: [],
    analytics2: {},
    version: 1
};

export function audioInputReducer(state: AudioInputReducerModel = initialState, action: Action): AudioInputReducerModel {
    switch (action.type) {
        case AUDIO_OUTPUT_AUDIO_FAILED: {
            const { audio } = action.payload;

            audio.errorCount++;
            audio.url2 = null;

            const audios = tryUpdateArray(
                state.audios,
                (item) => item.audioId === audio.audioId,
                () => audio,
                "web-210916-1317"
            );
            return { ...state, audios };
        }
        case AUDIO_INPUT_AUDIO_PLAY_CHANGE: {
            const { audio } = action.payload;

            const audios = tryUpdateArray(
                state.audios,
                (item) => item.audioId === audio.audioId,
                () => audio,
                "web-210715-1155"
            );
            return { ...state, audios };
        }
        case AUDIO_OUTPUT_AUDIO_DURATION_CHANGE: {
            const { audioId, duration } = action.payload;

            const audios = tryUpdateArray(
                state.audios,
                (item) => item.audioId === audioId,
                (item) => ({ ...item, duration }),
                "web-210715-1157"
            );

            return { ...state, audios };
        }
        case AUDIO_INPUT_AUDIO_SRC_CHANGE: {
            const { audio } = action.payload;

            const audios = tryUpdateArray(
                state.audios,
                (item) => item.audioId === audio.audioId,
                () => audio,
                "web-210715-1157"
            );

            return { ...state, audios };
        }
        case AUDIO_INPUT_AUDIOS_CHANGE: {
            const { audios, queueAddData: queueAdded } = action.payload;
            const analytics2 = updateNormalizedAnalyticsQueueAdded(audios, state.analytics2, queueAdded);
            return { ...state, audios, analytics2 };
        }
        case PLAYQUEUE_CLEAR: {
            return { ...state, analytics2: {} };
        }
        case REHYDRATE: {
            let newState = action.payload?.audioInput;
            if (!newState) return initialState;
            if (newState.version === undefined) return initialState;
            if (!newState.analytics2) newState.analytics2 = {};
            newState = pauseAndRecoverPositionOnPageLoad(newState);
            newState = resetErrorCountOnPageLoad(newState);
            newState.audios.forEach((audio) => {
                if (audio.chromecastId == null) audio.chromecastId = getNewCastItemId();
            });

            return newState;
        }
        default:
            return state;
    }
}

// todo move this logic to player service
function pauseAndRecoverPositionOnPageLoad(state: AudioInputReducerModel) {
    if (state.audios.length === 0) return state;
    const lastPosition = getLastPositionFromSessionStorage();
    //todo: haci - should this happen when resuming cc session
    const audios = tryUpdateArray(
        state.audios,
        (item) => item.playState === PlayState.Buffering || item.playState === PlayState.Playing,
        (item) => {
            const newItem = { ...item, playState: PlayState.Paused };
            if (newItem.input === AudioInputType.PlayQueue) {
                let pausePosition = newItem.position.pausePosition;
                if (pausePosition == null) {
                    pausePosition = lastPosition?.audioId === item.audioId ? lastPosition.pausePosition : 0;
                }
                newItem.position = {
                    pausePosition
                };
            }
            return newItem;
        },
        "web-210715-1159"
    );
    return { ...state, audios };
}

function resetErrorCountOnPageLoad(state: AudioInputReducerModel) {
    const audios = state.audios.map((audio) => ({ ...audio, errorCount: 0 }));
    return { ...state, audios };
}
