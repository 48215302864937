import { isPlayPausePositionEqual } from "services/player/helpers";
import type { PlayPausePositionModel } from "models/app/player";
import { PlayState } from "models/app/player";
import type { BrowserAudioItemModel } from "models/app/player/output";

export const setPosition = (browserAudio: BrowserAudioItemModel, position: PlayPausePositionModel, playState: PlayState) => {
    const { element } = browserAudio;

    if (!browserAudio.isMediaSet) return;
    if (isPlayPausePositionEqual(position, browserAudio.position)) return;

    browserAudio.position = position;
    const playStartMs = position.playStartMs ?? null;
    const pausePosition = position.pausePosition ?? null;

    if (playStartMs == null && pausePosition == null) {
        element.currentTime = 0;
        return;
    }

    if (pausePosition != null && playState !== PlayState.Playing) {
        if (element.currentTime === pausePosition) return;
        element.currentTime = pausePosition;
    }
};
