import type { PlayQueuePlayableModelInterface, ConnectionModel, TrackPreviewModel } from ".";
import { createTrackPreviewConnectionModel } from ".";
import type { MixedContentModel } from "./MixedContentModel";
import type { ResourceModelLinkInterface } from "./ResourceModel";
import { DefaultLogMessage, log } from "services/logger";
import type { MixRadioPreviewFragment, MixRadioPlayFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type MixRadioData = MixRadioPreviewData;
type MixRadioPreviewData = MixRadioPreviewFragment;
type MixRadioPlayData = MixRadioPlayFragment;

export type MixRadioModel = MixRadioPreviewModel;
export type MixRadioPreviewModel = ({ type: DomainModelType.Preview } & MixRadioPreview) | MixRadioPlayModel;
export type MixRadioPlayModel = { type: DomainModelType.Play } & MixRadioPlay;

export enum MixRadioLinkParentType {
    LayoutPageItem = "LayoutPageItem",
    MixRadioCategory = "MixRadioCategory",
    MixRadios = "MixRadios",
    Notification = "Notification"
}

export type MixRadioLinkParentModel =
    | { type: MixRadioLinkParentType.LayoutPageItem; itemId: string; pageId: string }
    | { type: MixRadioLinkParentType.MixRadioCategory; categoryId: string }
    | { type: MixRadioLinkParentType.MixRadios }
    | { type: MixRadioLinkParentType.Notification };

interface MixRadio extends ResourceModelLinkInterface, PlayQueuePlayableModelInterface {
    readonly contentType: ContentType.MixRadio;
    readonly title: string;
    readonly parent: MixRadioLinkParentModel;
    readonly sharingUrl: string | null;
}

type MixRadioPreview = MixRadio;

interface MixRadioPlay extends MixRadioPreview {
    readonly tracks: ConnectionModel<TrackPreviewModel> | null;
}

function createMixRadio(data: MixRadioData, parent: MixRadioLinkParentModel): MixRadio {
    return {
        contentType: ContentType.MixRadio,
        graphType: data.__typename,
        id: data.id,
        title: data.title,
        cover: data.cover ?? null,
        trackCount: null,
        parent,
        sharingUrl: data.share ?? null
    };
}

export function createMixRadioPreviewModel(data: MixRadioPreviewData, parent: MixRadioLinkParentModel): MixRadioPreviewModel {
    return {
        ...createMixRadio(data, parent),
        type: DomainModelType.Preview
    };
}
export function createMixRadioPlayModel(data: MixRadioPlayData, parent: MixRadioLinkParentModel): MixRadioPlayModel {
    return {
        ...createMixRadio(data, parent),
        type: DomainModelType.Play,
        contentType: ContentType.MixRadio,
        tracks: data.tracks ? createTrackPreviewConnectionModel(data.tracks, 0) : null
    };
}

export function createMixRadioPreviewModelFromMixedContent(mixedContent: MixedContentModel): MixRadioPreviewModel | null {
    if (mixedContent.mixedContentId == null) {
        log.error({ code: "web-211027-1853", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    return {
        contentType: ContentType.MixRadio,
        graphType: mixedContent.graphType,
        id: mixedContent.mixedContentId,
        title: mixedContent.title,
        cover: mixedContent.cover,
        trackCount: null,
        parent: { type: MixRadioLinkParentType.MixRadios },
        type: DomainModelType.Preview,
        sharingUrl: null
    };
}

export function createMixRadioPreviewModelFromPlayModel(model: MixRadioPlayModel): MixRadioPreviewModel {
    return {
        contentType: ContentType.MixRadio,
        graphType: model.graphType,
        id: model.id,
        title: model.title,
        cover: model.cover,
        trackCount: null,
        parent: model.parent,
        type: DomainModelType.Preview,
        sharingUrl: model.sharingUrl
    };
}
