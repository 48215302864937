import { isEmptyResult } from "./searchService";
import type { SearchResultModel } from "models/domain/SearchResultModel";
import { SearchState } from "models/view";

export enum SearchViewState {
    Searching,
    NoResults,
    Results,
    Failed,
    History,
    None
}

export function getSearchViewState(search: SearchResultModel | null, state: SearchState, hasHistory: boolean): SearchViewState {
    const isEmpty = search != null ? isEmptyResult(search) : false;

    switch (state) {
        case SearchState.None:
            return hasHistory ? SearchViewState.History : SearchViewState.None;
        case SearchState.Fetching:
            return SearchViewState.Searching;
        case SearchState.Failed:
            return SearchViewState.Failed;
        case SearchState.Success:
            return isEmpty ? SearchViewState.NoResults : SearchViewState.Results;
    }
}
