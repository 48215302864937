import { h } from "preact";
import { PreviewContextModel } from "models/app/resourceContextModel";
import { CoverSize, ResourceCover } from "components/atoms/cover";

interface Props {
    context: PreviewContextModel;
    size: CoverSize;
    disableLazyLoad: boolean;
}

export const ResourcePreviewRowCover = ({ context, size, disableLazyLoad }: Props) => {
    return <ResourceCover size={size} context={context} disabledAction disableLazyLoad={disableLazyLoad} />;
};
