import { store, dispatch } from "global";
import { LIVERADIO_NOW_AND_RECENTLY_PLAYED_UPDATE } from "global/actions";
import { liveRadioConstant } from "global/constants/liveRadio";
import { getLiveRadioNowAndRecentlyPlayed } from "services/backend";
import { DefaultLogMessage, log } from "services/logger";
import { isInputLiveRadioFromState } from "services/player/inputs/service/helpers";

let intervalRef: number | null = null;

export function updateLiveRadioTracksSync() {
    if (isInputLiveRadioFromState()) {
        const id = store.getState().liveRadio.currentRadio?.id || null;
        if (id == null) {
            log.error({ code: "web-220228-1803", msg: DefaultLogMessage.UnexpectedNull });
            stop();
            return;
        }
        start(id);
        return;
    }
    stop();
}

function stop() {
    if (intervalRef) window.clearInterval(intervalRef);
}

function start(id: string) {
    fetch(id);
    if (intervalRef) window.clearInterval(intervalRef);
    intervalRef = window.setInterval(async () => fetch(id), liveRadioConstant.recentlyPlayedTracksPollInterval);
}

async function fetch(id: string) {
    const tracks = (await getLiveRadioNowAndRecentlyPlayed({ id })).model;
    dispatch({ type: LIVERADIO_NOW_AND_RECENTLY_PLAYED_UPDATE, payload: { tracks } });
}
