import type { AlbumPreviewModel } from "./AlbumModel";
import type { ConnectionModel } from "./ConnectionModel";
import { createAlbumPreviewConnectionModel } from "./ConnectionModel";
import type { ResourceModelInterface } from "./ResourceModel";
import type { ArtistAlbumsSectionFragment, ArtistAlbumsPageFragment } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type ArtistAlbumsSectionData = ArtistAlbumsSectionFragment;
type ArtistAlbumsPageData = ArtistAlbumsPageFragment;

export type ArtistAlbumsModel = ArtistAlbumsSectionModel;
export type ArtistAlbumsSectionModel = ({ type: DomainModelType.Section } & ArtistAlbumsSection) | ArtistAlbumsPageModel;
export type ArtistAlbumsPageModel = { type: DomainModelType.Page; contentType: ContentType.ArtistAlbums } & ArtistAlbumsPage;

interface ArtistAlbumsSection extends ResourceModelInterface {
    readonly contentType: ContentType.ArtistAlbums;
    readonly albums: ConnectionModel<AlbumPreviewModel> | null;
}
type ArtistAlbumsPage = ArtistAlbumsSection;

export function createArtistAlbumsSectionModel(data: ArtistAlbumsSectionData): ArtistAlbumsSectionModel {
    return {
        graphType: data.__typename,
        type: DomainModelType.Section,
        contentType: ContentType.ArtistAlbums,
        id: data.id,
        cover: null,
        albums: data.albums ? createAlbumPreviewConnectionModel(data.albums) : null
    };
}

export function createArtistAlbumsPageModel(data: ArtistAlbumsPageData): ArtistAlbumsPageModel {
    return {
        ...createArtistAlbumsSectionModel(data),
        type: DomainModelType.Page
    };
}
