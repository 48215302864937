import type { LinkModel } from "services/resource";
import { getLink } from "services/resource";
import type { LocationContentType, PlayableModel, ResourceDisplayType, ResourceModel, ResourcePageModel, ResourcePreviewModel, ResourceSectionModel } from "models/domain";
import type { NavigationPageRootName } from "models/view/navigationStack";

export interface PageContextModel {
    resource: ResourcePageModel | null;
    root: NavigationPageRootName | null;
    type: LocationContentType;
    sections: SectionContextModel[];
    extraInfo?: ExtraInfoModel;
    pageType?: LocationContentType;
}

export enum ErrorType {} //TODO: this ?
export enum ErrorPage {} //TODO: this ?

export enum ContentPlaylistType {
    Own = "Own",
    Follow = "Follow",
    Public = "Public"
}

export type ExtraInfoModel = {
    contentPlaylistType?: ContentPlaylistType;
    errorPage?: {
        errorType: ErrorType;
        error: boolean;
        errorText?: string;
        errorPage: ErrorPage;
    };
};

export interface SectionContextModel {
    page: PageContextModel | null;
    type: LocationContentType;
    resource: ContextResourceModel | null;
    rank: number | null;
    visibleRank: number | null;
    displayType: ResourceDisplayType | null;
    isHidden: boolean;
    id?: string;
    scrollId: string;
}

export interface PreviewContextModel {
    section: SectionContextModel;
    type: LocationContentType;
    resource: ContextResourceModel | null;
    rank: number | null;
}

export interface LinkContextModel extends LinkModel {
    preview?: PreviewContextModel;
}

export type ContextResourceModel = ResourcePreviewModel | ResourceSectionModel | ResourcePageModel | PlayableModel;

export interface CreatePageContextParams {
    type: LocationContentType;
    resource: ResourcePageModel | null;
    root: NavigationPageRootName | null;
    extraInfo?: ExtraInfoModel;
    done: boolean;
    pageType?: LocationContentType;
}

export function createPageContext({ type, pageType, resource: page, root, extraInfo }: CreatePageContextParams): PageContextModel {
    return { type, pageType, resource: page, root, sections: [], extraInfo };
}

export function createSectionContext(
    type: LocationContentType,
    resource: ContextResourceModel | null,
    page: PageContextModel | null,
    rank: number | null,
    displayType: ResourceDisplayType | null,
    alternateScrollId?: string
): SectionContextModel {
    const id = resource?.id ? `${type}_${resource?.id}` : undefined;
    return { type, resource, page, rank, visibleRank: null, displayType, isHidden: false, id, scrollId: alternateScrollId ?? type };
}

export function createPreviewContext(type: LocationContentType, resource: ContextResourceModel | null, section: SectionContextModel, rank: number | null): PreviewContextModel {
    return { resource, type, section, rank };
}

export function createLinkContext(type: LocationContentType, resource: ResourceModel | null, preview: PreviewContextModel): LinkContextModel {
    const link = getLink(type, resource);
    return { ...link, type, preview };
}

export function removePageSectionsFromPreviewContext(preview: PreviewContextModel) {
    const newPreview = { ...preview };
    newPreview.section = { ...newPreview.section };

    const page = newPreview.section.page;
    if (page) {
        newPreview.section.page = { ...page, sections: [] };
    }
    return newPreview;
}
