import { h } from "preact";
import { PageTemplate } from "../page/PageTemplate";

interface Props {
    className?: string;
    children?: preact.ComponentChildren;
}

export const BlankTemplate = ({ children, className }: Props) => {
    return <PageTemplate className={className}>{children}</PageTemplate>;
};
