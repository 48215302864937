export enum ContentType {
    Album = "Album",
    AlbumArtistAlbums = "AlbumArtistAlbums",
    AlbumChart = "AlbumChart",
    AlbumRecommendation = "AlbumRecommendation",
    AlbumsLayoutItemContent = "AlbumsLayoutItemContent",
    Artist = "Artist",
    ArtistAlbums = "ArtistAlbums",
    ArtistAppearsOn = "ArtistAppearsOn",
    ArtistRadio = "ArtistRadio",
    ArtistSimilarArtists = "ArtistSimilarArtists",
    ArtistSingles = "ArtistSingles",
    ArtistTracks = "ArtistTracks",
    ArtistsLayoutItemContent = "LayoutPageArtistsItem",
    BannerLayoutItemContent = "BannerLayoutItemContent",
    Chart = "Chart",
    ChartsLayoutItemContent = "ChartsLayoutItemContent",
    EndlessPlay = "EndlessPlay",
    ExternalLink = "ExternalLink",
    FrontPage = "FrontPage",
    FrontPageShowAll = "FrontPageShowAll",
    Genre = "Genre",
    InstallPWA = "InstallPWA",
    LayoutItem = "LayoutItem",
    LayoutPage = "LayoutPage",
    LayoutPageGroupConnection = "LayoutPageGroupConnection",
    LayoutPageItemConnection = "LayoutPageItemConnection",
    LayoutSkeletonItem = "LayoutPageItem",
    LiveRadio = "LiveRadio",
    LiveRadioCategoriesLayoutItemContent = "LiveRadioCategoriesLayoutItemContent",
    LiveRadioCategory = "LiveRadioCategory",
    LiveRadioPlayable = "LiveRadioPlayable",
    LiveRadioStream = "LiveRadioStream",
    LiveRadioTrack = "LiveRadioTrack",
    LiveRadiosLayoutItemContent = "LiveRadiosLayoutItemContent",
    MixRadio = "MixRadio",
    MixRadioCategory = "MixRadioCategory",
    MixRadios = "MixRadios",
    MixRadiosLayoutItemContent = "MixRadiosLayoutItemContent",
    MixedContent = "MixedContent",
    MixedContentLayoutItemContent = "MixedContentLayoutItemContent",
    MixedContentTrack = "MixedContentTrack",
    MyMusicAlbums = "MyMusicAlbums",
    MyMusicArtists = "MyMusicArtists",
    MyMusicPlaylists = "MyMusicPlaylists",
    MyMusicTracks = "MyMusicTracks",
    NavBar = "NavBar",
    NotFound = "NotFound",
    Notification = "Notification",
    NotificationFeed = "NotificationFeed",
    PageLoadError = "PageLoadError",
    PlayableContext = "PlayableContext",
    Player = "Player",
    Playlist = "Playlist",
    PlaylistCategories = "PlaylistCategories",
    PlaylistCategoriesLayoutItemContent = "PlaylistCategoriesLayoutItemContent",
    PlaylistCategory = "PlaylistCategory",
    PlaylistRecommendation = "PlaylistRecommendation",
    PlaylistsLayoutItemContent = "PlaylistsLayoutItemContent",
    ProfilesPage = "ProfilesPage",
    Queue = "Queue",
    QueueTrack = "QueueTrack",
    Radio = "Radio",
    RadioPageShowAll = "RadioPageShowAll",
    Recommendation = "Recommendation",
    RecommendationsLayoutItemContent = "RecommendationsLayoutItemContent",
    ResourceContext = "ResourceContext",
    Search = "Search",
    SearchAlbumResults = "SearchAlbumResults",
    SearchArtistResults = "SearchArtistResults",
    SearchMixedResults = "SearchMixedResults",
    SearchPlaylistResults = "SearchPlaylistResults",
    SearchRecentResults = "SearchRecentResults",
    SearchResult = "SearchResult",
    SearchResultTrack = "SearchResultTrack",
    SearchResults = "SearchResults",
    SearchTrackResults = "SearchTrackResults",
    SettingsPage = "SettingsPage",
    SideBar = "SideBar",
    Skeleton = "Skeleton",
    ThemePageShowAll = "ThemePageShowAll",
    Track = "Track",
    TrackChart = "TrackChart",
    TrackPlayable = "TrackPlayable",
    TrackRadio = "TrackRadio",
    TrackRecommendation = "TrackRecommendation",
    TrackSelection = "TrackSelection",
    TrackWithContext = "TrackWithContext",
    TracksLayoutItem = "TracksLayoutItem",
    TracksLayoutItemContent = "TracksLayoutItemContent",
    UserHistory = "UserHistory",
    UserNavigateTrace = "UserNavigateTrace",
    UserPlayTrace = "UserPlayTrace",
    UserRecommendations = "UserRecommendations",
    YourMixesPage = "YourMixesPage",
    Lyrics = "Lyrics"
}

export enum DomainModelType {
    Link = "Link",
    Page = "Page",
    Play = "Play",
    Preview = "Preview",
    Section = "Section"
}
