import { createAnalyticsData, SetAnalyticsProp } from "../../analyticsData";
import { getFavoritesStatisticsFromCache } from "services/favorites/favoritesCache";
import { getCurrentProfile, getIsLoggedInFromStateImmediate, hasPermissionFromToken } from "services/user";
import { getIsEndlessPlayOnFromState } from "components/shared/hooks";

export async function getAnalyticsUserProperties() {
    const data = createAnalyticsData();

    if (getIsLoggedInFromStateImmediate()) {
        const stats = await getFavoritesStatisticsFromCache();
        if (stats) {
            SetAnalyticsProp(data, "user", "NumberOfCurrentFavoriteArtists", stats.artistCount);
            SetAnalyticsProp(data, "user", "NumberOfCurrentFavouriteAlbums", stats.albumCount);
            SetAnalyticsProp(data, "user", "NumberOfCurrentFavouritePlaylists", stats.playlistCount);
            SetAnalyticsProp(data, "user", "NumberOfCurrentFavouriteTracks", stats.trackCount);
        }
    }
    //   addData_Set(map, "user",ananlyticsKeys["Number Of Current Downloaded Tracks"], undefined); // todo Brian implement
    //   addData_Set(map, "user",ananlyticsKeys["Number Of Current Favourite Playlist tracks"], undefined); // todo Brian implement
    //   addData_Set(map, "user",ananlyticsKeys["Number Of Current Private Playlists"], undefined); // todo Brian implement
    //   addData_Set(map, "user",ananlyticsKeys["Number Of Current Private Playlist Tracks"], undefined); // todo Brian implement
    //   addData_Set(map, "user",ananlyticsKeys["Number Of Current Private Public Playlists"], undefined); // todo Brian implement
    //   addData_Set(map, "user",ananlyticsKeys["Number Of Current Private Public Playlist Tracks"], undefined); // todo Brian implement
    SetAnalyticsProp(data, "user", "EndlessPlayWeb", getIsEndlessPlayOnFromState());
    SetAnalyticsProp(data, "user", "SoundQualityWeb", "High");

    const hasProfiles = hasPermissionFromToken("prof");
    SetAnalyticsProp(data, "user", "ProfileAccess", hasProfiles);
    SetAnalyticsProp(data, "user", "HifiAccess", hasPermissionFromToken("cd"));
    if (hasProfiles) {
        SetAnalyticsProp(data, "user", "MainProfile", getCurrentProfile()?.master ?? false);
    }

    return data;
}
