import { isTracksEmptyOrNotAvailableInSubscription } from "services/playable";
import type { AlbumPageModel, PlaylistPageModel, RecommendationPageModel, ArtistPageModel, ArtistRadioPageModel, TrackPreviewModel } from "models/domain";
import type { ChartPageModel } from "models/domain/ChartModel";
import type { TrackRadioPageModel } from "models/domain/TrackRadioModel";
import { ContentType } from "models/ModelType";

export type ProductPageModel = AlbumPageModel | PlaylistPageModel | ArtistPageModel | RecommendationPageModel | ChartPageModel | TrackRadioPageModel | ArtistRadioPageModel;
export type ProductPageType = ProductPageModel["contentType"] | ContentType.Chart | ContentType.Recommendation;

export function getCanPlay(product: ProductPageModel | null) {
    if (!product) return true;

    const getTracks = (): TrackPreviewModel[] | null => {
        switch (product.contentType) {
            case ContentType.AlbumRecommendation:
            case ContentType.AlbumChart:
            case ContentType.PlaylistRecommendation:
                return null;
        }

        if (product.contentType == ContentType.Artist) return product.tracksSection?.tracks?.items ?? null;
        else return product.tracks?.items ?? null;
    };

    const tracks = getTracks();
    if (!tracks) return false;

    const empty = isTracksEmptyOrNotAvailableInSubscription(tracks);
    if (empty) return false;

    return true;
}
