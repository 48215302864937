import { DefaultLogMessage, log } from "services/logger";
import type { PausePositionModel, PlayPausePositionModel, PlayPositionModel } from "models/app/player";
import { PlayState } from "models/app/player";

export function getPlayPausePosition(position: PlayPausePositionModel, playing: boolean): PlayPausePositionModel {
    if (playing) return getPlayPosition(position);
    else return getPausePosition(position);
}

export function getPlayPosition(position: PlayPausePositionModel): PlayPositionModel {
    if (position.playStartMs != undefined) return position;
    else if (position.pausePosition != undefined) return getPlayPositionFromPausePosition(position);
    else return { playStartMs: 0 };
}

export function getPausePosition(position: PlayPausePositionModel): PausePositionModel {
    if (position.pausePosition != undefined) return position;
    else if (position.playStartMs != undefined) return getPausePositonFromPlayPosition(position);
    else return { pausePosition: 0 };
}

export function getPositionFromCurrentTime(currentTime: number): PausePositionModel {
    return { pausePosition: currentTime };
}

export function isPlayPausePositionEqual(a: PlayPausePositionModel, b: PlayPausePositionModel) {
    const equal = getPlayPausePositionDiff(a, b) === 0;
    return equal;
}

export function isPlayPausePositionAlmostEqual(a: PlayPausePositionModel, b: PlayPausePositionModel) {
    const almostEqual = getPlayPausePositionDiff(a, b) < 1;
    return almostEqual;
}

function getPlayPausePositionDiff(a: PlayPausePositionModel, b: PlayPausePositionModel) {
    const aPos = getPausePosition(a);
    const bPos = getPausePosition(b);

    return Math.abs(aPos.pausePosition - bPos.pausePosition);
}

function getPlayPositionFromPausePosition(position: PausePositionModel): PlayPositionModel {
    return {
        playStartMs: new Date().getTime() - position.pausePosition * 1000
    };
}

function getPausePositonFromPlayPosition(position: PlayPositionModel): PausePositionModel {
    return {
        pausePosition: (new Date().getTime() - position.playStartMs) / 1000
    };
}

export const getSecondsPlayed = ({ playState, position }: { playState: PlayState; position: PlayPausePositionModel }): { seconds: number; next: number | null } => {
    const { playStartMs, pausePosition } = position;
    switch (playState) {
        case PlayState.Error:
        case PlayState.Stopped:
        case PlayState.Buffering:
        case PlayState.Paused: {
            if (pausePosition == null) return { seconds: 0, next: null };
            return { seconds: pausePosition, next: null };
        }
        case PlayState.Playing: {
            if (playStartMs == null) return { seconds: 0, next: null };

            const now = new Date().getTime();
            const seconds = (now - playStartMs) / 1000;
            let next = Math.floor(seconds) + 1 - seconds;

            if (next <= 0) {
                log.error({ code: "web-210519-1115", msg: DefaultLogMessage.UnexpectedValue });
                next = 1;
            }
            return {
                seconds: Math.floor(seconds),
                next
            };
        }
    }
};
