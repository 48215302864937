import type { AlbumPreviewModel, ArtistPreviewModel, PlaylistPreviewModel, ResourceModelInterface, TrackPreviewModel } from ".";
import type { AlbumRecommendationPageModel, AlbumRecommendationPreviewModel } from "./AlbumRecommendationModel";
import { createAlbumRecommendationsPageModel, createAlbumRecommendationsPreviewModel } from "./AlbumRecommendationModel";
import type { ConnectionModel } from "./ConnectionModel";
import type { PlaylistRecommendationPageModel, PlaylistRecommendationPreviewModel } from "./PlaylistRecommendationModel";
import { createPlaylistRecommendationsPageModel, createPlaylistRecommendationsPreviewModel } from "./PlaylistRecommendationModel";
import type { TrackRecommendationPreviewModel, TrackRecommendationPlayModel, TrackRecommendationPageModel } from "./TrackRecommendationModel";
import { createTrackRecommendationsPreviewModel, createTrackRecommendationsPlayModel, createTrackRecommendationsPageModel } from "./TrackRecommendationModel";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import type { RecommendationPageFragment, RecommendationPlayFragment, RecommendationPreviewFragment } from "generated/graphql-types";
import { ContentType } from "models/ModelType";

type RecommendationPreviewData = RecommendationPreviewFragment;
type RecommendationPlayData = RecommendationPlayFragment;

export type RecommendationPageData = RecommendationPageFragment;

//TODO: clean up ?
export type RecommendationModel = RecommendationPreviewModel | RecommendationPlayModel | RecommendationPageModel;
export type RecommendationPreviewModel = AlbumRecommendationPreviewModel | PlaylistRecommendationPreviewModel | TrackRecommendationPreviewModel;
export type RecommendationPlayModel = TrackRecommendationPlayModel;
export type RecommendationPageModel = AlbumRecommendationPageModel | PlaylistRecommendationPageModel | TrackRecommendationPageModel;

export type RecommenendationItemModel = RecommendationItemPreviewModel;
export type RecommendationItemPreviewModel = AlbumPreviewModel | ArtistPreviewModel | PlaylistPreviewModel | TrackPreviewModel;

export type RecommendationOwner = ContentType.UserHistory | ContentType.UserRecommendations;

export interface Recommendation extends ResourceModelInterface {
    readonly id: string;
    readonly title: string;
    readonly subtitle: string;
    readonly owner: RecommendationOwner;
}

export function createRecommendations(data: RecommendationPreviewData, owner: RecommendationOwner): Recommendation {
    return {
        graphType: data.__typename,
        id: data.id,
        title: data.title,
        subtitle: data.subtitle ?? "",
        cover: data.cover ?? null,
        owner
    };
}

export function createRecommendationPreviewModel(data: RecommendationPreviewData, owner: RecommendationOwner): RecommendationPreviewModel {
    switch (data.__typename) {
        case "AlbumsRecommendation":
            return createAlbumRecommendationsPreviewModel(data, owner);
        case "PlaylistsRecommendation":
            return createPlaylistRecommendationsPreviewModel(data, owner);
        case "TracksRecommendation":
            return createTrackRecommendationsPreviewModel(data, owner);
    }
}

export function createRecommendationPlayModel(data: RecommendationPlayData, owner: RecommendationOwner): RecommendationPlayModel | null {
    if (data.__typename === "TracksRecommendation") return createTrackRecommendationsPlayModel(data, owner);
    log.error({ code: "web-210405-1940", msg: DefaultLogMessage.NotImplemented, data: { type: data.__typename } });
    return null;
}

export function createRecommendationPageModel(data: RecommendationPageData, owner: RecommendationOwner): RecommendationPageModel {
    switch (data.__typename) {
        case "AlbumsRecommendation":
            return createAlbumRecommendationsPageModel(data, owner);
        case "PlaylistsRecommendation":
            return createPlaylistRecommendationsPageModel(data, owner);
        case "TracksRecommendation":
            return createTrackRecommendationsPageModel(data, owner);
    }
}

export function getItemsFromRecommendation(recommendation: RecommendationPageModel): ConnectionModel<AlbumPreviewModel | PlaylistPreviewModel | TrackPreviewModel> | null {
    switch (recommendation.contentType) {
        case ContentType.AlbumRecommendation:
            return recommendation.albums;
        case ContentType.PlaylistRecommendation:
            return recommendation.playlists;
        case ContentType.TrackRecommendation:
            return recommendation.tracks;
    }
}
