import { Fragment, h } from "preact";
import { useCallback, useMemo, useRef } from "preact/hooks";
import "./NotificationFeed.scss";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL, NOTIFICATION_FEED_NOTIFICATION_CONVERTED } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { log, LogTag } from "services/logger";
import { useCurrentNotifications } from "services/notificationFeed";
import { getNextUUID } from "services/utils";
import type { DragProps } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { NotificationConversion } from "models/domain/NotificationModel";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";
import { useFakePageContext, useResizeObserver, useSectionContext } from "components/shared/hooks";
import { CoverSize } from "components/atoms/cover";
import { PreviewAction } from "components/molecules/preview";
import { ResourcePreviewRow } from "components/molecules/preview/rows";

interface Props {
    showHeadline: boolean;
}

export const NotificationFeed = ({ showHeadline }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const translations = useTranslations();
    const width = useResizeObserver(containerRef.current)?.width ?? null;

    const dragProps: DragProps = useMemo(() => ({ dragSourceId: getNextUUID() }), []);

    const page = useFakePageContext({ type: ContentType.NotificationFeed, resource: null, root: NavigationPageRootName.NotificationFeed, done: true });
    const section = useSectionContext(ContentType.NotificationFeed, null, page, null, null);

    const { feed, newNotifications, lastWeeksNotifications, thisMonthsNotifications, lastMonthsNotifications, previousNotifications } = useCurrentNotifications();

    const hasNew = newNotifications.length != 0;
    const hasLastWeek = lastWeeksNotifications.length != 0;
    const hasThisMonth = thisMonthsNotifications.length != 0;
    const hasLastMonth = lastMonthsNotifications.length != 0;
    const hasPreviously = previousNotifications.length != 0;

    const onPreviewAction = useCallback(
        (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => {
            if (action === PreviewAction.Navigate) dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });

            if (!feed) return;
            const resource = preview.resource;
            if (resource?.contentType !== ContentType.Notification) return;

            if (action === PreviewAction.Play && conversion === null) conversion = NotificationConversion.Play;
            if (action === PreviewAction.Navigate && conversion === null) conversion = NotificationConversion.Navigate;
            if (conversion == null) return;

            log.info({ code: "web-220926-1511", msg: "notification", data: { converted: resource.title, conversion }, tags: [LogTag.Notifications] });

            dispatch({ type: NOTIFICATION_FEED_NOTIFICATION_CONVERTED, payload: { converted: resource, conversion } });
        },
        [feed]
    );

    // console.log(hasNew, hasLastWeek, hasThisMonth, hasLastMonth, hasPreviously)

    return (
        <div ref={containerRef} className={`notificationFeed R6O`}>
            {hasNew && (
                <Fragment>
                    <div className="new">
                        <h2>{translations.NotificationFeedNewNotificationsTitle}</h2>
                        {newNotifications.map((notification, i) => (
                            <ResourcePreviewRow
                                key={notification.id}
                                resource={notification}
                                context={section}
                                dragProps={dragProps}
                                rank={i}
                                dropProps={null}
                                size={CoverSize.Size64}
                                width={width}
                                disableLazyLoad={false}
                                onAction={onPreviewAction}
                            />
                        ))}
                    </div>
                </Fragment>
            )}
            {hasLastWeek && (
                <Fragment>
                    <div className="lastweek">
                        <h2>{translations.NotificationFeedLastWeekTitle}</h2>
                        {lastWeeksNotifications.map((notification, i) => (
                            <ResourcePreviewRow
                                key={notification.id}
                                resource={notification}
                                context={section}
                                dragProps={dragProps}
                                rank={i}
                                dropProps={null}
                                size={CoverSize.Size64}
                                width={width}
                                disableLazyLoad={false}
                                onAction={onPreviewAction}
                            />
                        ))}
                    </div>
                </Fragment>
            )}
            {hasThisMonth && (
                <Fragment>
                    <div className="thismonth">
                        <h2>{translations.NotificationFeedThisMonthTitle}</h2>
                        {thisMonthsNotifications.map((notification, i) => (
                            <ResourcePreviewRow
                                key={notification.id}
                                resource={notification}
                                context={section}
                                dragProps={dragProps}
                                rank={i}
                                dropProps={null}
                                size={CoverSize.Size64}
                                width={width}
                                disableLazyLoad={false}
                                onAction={onPreviewAction}
                            />
                        ))}
                    </div>
                </Fragment>
            )}
            {hasLastMonth && (
                <Fragment>
                    <div className="lastmonth">
                        <h2>{translations.NotificationFeedLastMonthTitle}</h2>
                        {lastMonthsNotifications.map((notification, i) => (
                            <ResourcePreviewRow
                                key={notification.id}
                                resource={notification}
                                context={section}
                                dragProps={dragProps}
                                rank={i}
                                dropProps={null}
                                size={CoverSize.Size64}
                                width={width}
                                disableLazyLoad={false}
                                onAction={onPreviewAction}
                            />
                        ))}
                    </div>
                </Fragment>
            )}
            {hasPreviously && (
                <Fragment>
                    <div className="previously">
                        <h2>{translations.NotificationFeedPreviousNotificationsTitle}</h2>
                        {previousNotifications.map((notification, i) => (
                            <ResourcePreviewRow
                                key={notification.id}
                                resource={notification}
                                context={section}
                                dragProps={dragProps}
                                rank={i}
                                dropProps={null}
                                size={CoverSize.Size64}
                                width={width}
                                disableLazyLoad={false}
                                onAction={onPreviewAction}
                            />
                        ))}
                    </div>
                </Fragment>
            )}
        </div>
    );
};
