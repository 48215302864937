import { SetAnalyticsProp, SetFirstAnalyticsProp, IncrementAnalyticsProp, createAnalyticsData } from "../../analyticsData";

export function getAnalyticsUserPlayProperties() {
    const data = createAnalyticsData();

    SetFirstAnalyticsProp(data, "user", "FirstPlayedContent", new Date());
    SetAnalyticsProp(data, "user", "LastPlayedContent", new Date());
    IncrementAnalyticsProp(data, "user", "NumberOfPlayedContent");

    return data;
}
