import type { RefObject } from "preact";
import { createRef, h } from "preact";
import { useState, useCallback, useRef } from "preact/hooks";
import { Modal, modalButtonDismissCancel, modalButtonEditPlaylistSave, openModal, submitModal } from "..";
import { dispatch } from "global";
import { PLAYLIST_CREATED } from "global/actions";
import { useTranslations } from "global/config";
import { catalog } from "global/constants/catalog";
import { mutateCreatePlaylist } from "services/backend";
import { PlaylistVisibility } from "generated/graphql-types";
import { FormState } from "components/atoms/controls/form";
import { TextInput, TextInputState } from "components/atoms/controls/textInput";
import { openToast } from "components/organisms/toast";
import { PlaylistCreatedToast, PlaylistNotCreatedToast } from "components/organisms/toast/toasts";

export function showCreatePlaylistModal() {
    return showCustomCreatePlaylistModal(null, null, null);
}

export async function showCustomCreatePlaylistModal(title: string | null, subtitle: string | null, initialName: string | null) {
    const playlistIdRef = createRef<string | null>();
    const modalResult = await openModal(<CreatePlaylistModal title={title} subtitle={subtitle} resultRef={playlistIdRef} initialName={initialName} />);
    return {
        result: modalResult,
        playlistId: playlistIdRef.current
    };
}

interface Props {
    title: string | null;
    subtitle: string | null;
    resultRef: RefObject<string | null>;
    initialName: string | null;
}

export function CreatePlaylistModal({ title, subtitle, resultRef, initialName }: Props) {
    const ref = useRef<HTMLInputElement>(null);
    const translations = useTranslations();
    const [name, setName] = useState(initialName ?? "");
    const [formState, setFormState] = useState(FormState.Default);
    const [errorMessage, setErrorMessage] = useState("");

    const doCreatePlaylist = useCallback(async () => {
        if (name.trim().length === 0) {
            setErrorMessage(translations.CreatePlaylistAlertText);
            ref.current?.focus();
            return false;
        }

        setFormState(FormState.Submiting);
        setErrorMessage("");
        try {
            const newId = await mutateCreatePlaylist({
                input: {
                    title: name.trim(),
                    description: "",
                    visibility: PlaylistVisibility.Public
                }
            });

            if (newId) {
                dispatch({
                    type: PLAYLIST_CREATED,
                    payload: {
                        playlistId: newId
                    }
                });
                openToast(PlaylistCreatedToast());

                resultRef.current = newId;

                return true;
            }
        } catch {
            /**/
        }

        setFormState(FormState.Default);
        openToast(PlaylistNotCreatedToast());
        return false;
    }, [name, resultRef, translations.CreatePlaylistAlertText]);

    const onKeyDown = useCallback(
        async (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                if (await doCreatePlaylist()) {
                    submitModal("CreatePlaylistModal.onKeyDown");
                }
            }
        },
        [doCreatePlaylist]
    );

    const onChange = useCallback((e: string) => {
        setName(e);
        if (e.trim()) {
            setErrorMessage("");
        }
    }, []);

    return (
        <Modal
            formState={formState}
            title={title || translations.CreatePlaylistTitle}
            text={subtitle || translations.CreatePlaylistSubtitle}
            buttons={[modalButtonDismissCancel(), modalButtonEditPlaylistSave(doCreatePlaylist)]}>
            <p onKeyDown={onKeyDown}>
                <TextInput
                    type="text"
                    maxLength={catalog.playlistTitleMaxLength}
                    ref={ref}
                    value={name}
                    placeholder={translations.TypePlaylistName}
                    autoComplete="off"
                    state={errorMessage ? TextInputState.Error : TextInputState.Default}
                    message={errorMessage}
                    onChange={onChange}
                    autoFocus
                    onFocus={() => {
                        /**/
                    }}
                />
            </p>
        </Modal>
    );
}
