import { ScrollerSize } from "../../options";

export const getItemSize = (size: ScrollerSize | number): number => {
    switch (size) {
        case ScrollerSize.Medium:
            return 144;
        case ScrollerSize.MediumLarge:
            return 208;
        case ScrollerSize.Large:
            return 256;
        default:
            return size;
    }
};
