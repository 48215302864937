import type { AppSession } from "../appSessionService";
import { getAppSession } from "../appSessionService";
import { isMySession } from "../helpers";
import { getStateDbName } from "../storageKeys";
import { transferReportsToSharedStorage } from "./reports";
import { copyDbState, deleteDbState, saveSessionState } from "global/config";
import { tryTransferAndFinishReportsInCurrentSession } from "services/logger";

export async function deleteSessionStorage(session: AppSession) {
    const storageId = session.storageId;
    const mySession = isMySession(storageId);

    // todo delete tokens
    // saveAccessToken(storageId, null);
    // saveRefreshToken(storageId, null);

    if (mySession) transferReportsToSharedStorage(storageId);
    else tryTransferAndFinishReportsInCurrentSession(session);

    const dbName = getStateDbName(storageId);
    await deleteDbState(dbName);
}

export async function copySessionStorage(from: AppSession, to: AppSession) {
    const storageIdFrom = from.storageId;
    const storageIdTo = to.storageId;

    // const accessTokenFrom = getAccessToken(storageIdFrom);
    // const refreshTokenFrom = getRefreshToken(storageIdFrom);

    // saveAccessToken(storageIdTo, accessTokenFrom);
    // saveRefreshToken(storageIdTo, refreshTokenFrom);

    const dbNameFrom = getStateDbName(storageIdFrom);
    const dbNameTo = getStateDbName(storageIdTo);

    await copyDbState(dbNameFrom, dbNameTo);
}

export async function restoreSessionStorage() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const storageId = getAppSession().storageId;

    // const accessToken = getAccessToken(storageId);
    // const refreshToken = getRefreshToken(storageId);

    // saveAccessToken(storageId, accessToken);
    // saveRefreshToken(storageId, refreshToken);

    // todo restore tokens

    await saveSessionState("restoreSession");
}
