import { dispatch, messageBus, store } from "global";
import type { Action } from "global/actions";
import { EVENT_MESSAGE, LOGGING_EVENT_MESSAGE } from "global/actions";
import { getAppProperties } from "services/app/appService";
import { throttle } from "services/cancellation/throttle";

export enum DefaultLogMessage {
    NotImplemented = "NotImplemented",
    UnexpectedNull = "UnexpectedNull",
    UnexpectedEmptyString = "UnexpectedEmptyString",
    UnexpectedValue = "UnexpectedValue"
}

export type LogCode = `web-${number}-${number}`;

export enum LogLevel {
    Debug = "Debug",
    Info = "Info",
    Warning = "Warning",
    Error = "Error"
}

export interface LogMessage {
    code: LogCode;
    correlationId?: string;
    msg: string | DefaultLogMessage;
    level: LogLevel;
    data?: Record<string, unknown>;
    error?: Error | unknown;
    report?: boolean;
    tags: LogTag[];
}

export interface LogMessageEntry {
    appVersion: string;
    messageCode: LogCode;
    correlationId?: string;
    data?: Record<string, unknown>;
    hostname: string;
    installationId: string;
    language: string;
    level: LogLevel;
    message: string;
    platform: string;
    stack?: string;
    time: number;
    timeFormatted: string;
    url: string;
    userAgent: string;
    userId: string | null;
}

const createLogMessageEntry = ({ msg, code, correlationId, error, level, data }: LogMessage): LogMessageEntry => {
    const now = new Date();

    const userId = store.getState().user.id ?? null;
    const timeFormatted = now.toString();
    const stack = (error as Error)?.stack ?? (JSON.stringify(new Error().stack) as string | undefined);
    const hostname = window.location.hostname;
    const url = window.location.href;

    const { appVersion, installationId, language, platform, userAgent } = getAppProperties();

    return {
        appVersion,
        messageCode: code,
        correlationId,
        data,
        hostname,
        installationId,
        language,
        level,
        message: msg,
        platform,
        stack,
        time: now.getTime(),
        timeFormatted,
        url,
        userAgent,
        userId
    };
};
export interface LogProps {
    code: LogCode;
    msg: string | DefaultLogMessage;
    data?: Record<string, unknown>;
    tags?: LogTag[];
    error?: Error | unknown;
    report?: boolean;
    correlationId?: string;
}

export const log = {
    simple: ({ code, msg, data, tags = [LogTag.Info], error }: LogProps) => {
        if (shoudLogTag(tags)) dispatch({ type: EVENT_MESSAGE, payload: { message: { code, msg, data, level: LogLevel.Debug, tags, error } } });
    },
    info: ({ code, msg, data, tags = [LogTag.Info], error, report }: LogProps) => {
        if (shoudLogTag(tags)) dispatch({ type: EVENT_MESSAGE, payload: { message: { code, msg, data, level: LogLevel.Info, tags, error, report } } });
    },
    warn: ({ code, msg, data, tags = [LogTag.Info], error }: LogProps) => {
        dispatch({ type: EVENT_MESSAGE, payload: { message: { code, msg, data, level: LogLevel.Warning, tags, error } } });
    },
    error: ({ code, msg, data, tags = [LogTag.Info], error, correlationId }: LogProps) => {
        dispatch({ type: EVENT_MESSAGE, payload: { message: { code, msg, data, level: LogLevel.Error, tags, error, correlationId } } });
    },
    debug: (tags: LogTag[] = [LogTag.Info], logPropsFn: () => LogProps, level: LogLevel = LogLevel.Info) => {
        if (!shoudLogTag(tags)) return;
        const logProps = logPropsFn();

        dispatch({ type: EVENT_MESSAGE, payload: { message: { ...logProps, level, tags } } });
    }
};

function shoudLogTag(tags: LogTag[]): boolean {
    if (tags.length === 0) return true;
    const enabledLogTypes = store.getState().controlPanel.enabledLogTypes;
    return tags.some((tag) => enabledLogTypes.includes(tag));
}

export enum LogTag {
    Dispatch = "Dispatch",
    Playback = "Playback",
    Chromecast = "Chromecast",
    RemoteConfig = "RemoteConfig",
    Info = "Info",
    Playlist = "Playlist",
    AppSetting = "AppSetting",
    Notifications = "Notifications",
    NavigationStack = "NavigationStack",
    User = "User",
    Collection = "Collection",
    Subscriptions = "Subscriptions"
}

export const initEventLogService = () => {
    messageBus.subscribeEvery(EVENT_MESSAGE, (msg) => {
        const { message } = msg.payload;

        if (!shouldReport(message.report, message.level)) return;
        if (throtteLog(message.code)) return;

        const loggerErrorEvent = createLogMessageEntry(message);
        dispatch({ type: LOGGING_EVENT_MESSAGE, payload: { message: loggerErrorEvent } });
    });

    messageBus.subscribeAll((message) => {
        const enabledLogTypes = store?.getState()?.controlPanel.enabledLogTypes ?? [];
        const log = enabledLogTypes.findIndex((item) => item === LogTag.Dispatch) !== -1;

        if (!log) return;

        const action = message as Action;
        console.info("Dispatch", action.type, "payload" in action ? action.payload : undefined);
    });
};

const throttle1 = throttle();
const throttle2 = throttle();
const overflowCode = "web-220322-1009";

function shouldReport(report: boolean | undefined, level: LogLevel) {
    if (report) return true;
    if (level != LogLevel.Warning && level != LogLevel.Error) return false;
    return true;
}

function throtteLog(code: string): boolean {
    if (code !== overflowCode) {
        if (throttle1.stop(10, 60 * 1000)) {
            // max 10 logs per minute
            log.error({ code: overflowCode, msg: "log overflow", data: { "log overflow code": code } });
            return true;
        }
        throttle1.register();
    } else {
        if (throttle2.stop(1, 60 * 1000)) return true; // max 1 overflow log per minute
        throttle2.register();
    }
    return false;
}
