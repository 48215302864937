export class CancellationToken {
    private _isCancelled = false;

    public get isCancelled() {
        return this._isCancelled;
    }

    cancel() {
        this._isCancelled = true;
    }

    throwIfCancelled() {
        if (this._isCancelled) {
            throw "Promise cancelled";
        }
    }
}
