import type { ConnectionModel } from "./ConnectionModel";
import { createPlaylistPreviewConnectionModel } from "./ConnectionModel";
import type { PlaylistPreviewModel } from "./PlaylistModel";
import type { Recommendation, RecommendationOwner } from "./RecommendationModel";
import { createRecommendations } from "./RecommendationModel";
import type { RecommendationPageFragment_PlaylistsRecommendation, RecommendationPreviewFragment_PlaylistsRecommendation } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

type PlaylistRecommendationPreviewData = RecommendationPreviewFragment_PlaylistsRecommendation;
type PlaylistRecommendationPageData = RecommendationPageFragment_PlaylistsRecommendation;

export type PlaylistRecommendationModel = PlaylistRecommendationPreviewModel;
export type PlaylistRecommendationPreviewModel = ({ type: DomainModelType.Preview } & PlaylistRecommendation) | PlaylistRecommendationPageModel;
export type PlaylistRecommendationPageModel = { type: DomainModelType.Page } & PlaylistRecommendationPage;

interface PlaylistRecommendation extends Recommendation {
    contentType: ContentType.PlaylistRecommendation;
}

interface PlaylistRecommendationPage extends PlaylistRecommendation {
    playlists: ConnectionModel<PlaylistPreviewModel> | null;
}

export function createPlaylistRecommendationsPreviewModel(data: PlaylistRecommendationPreviewData, owner: RecommendationOwner): PlaylistRecommendationPreviewModel {
    return {
        ...createRecommendations(data, owner),
        contentType: ContentType.PlaylistRecommendation,
        type: DomainModelType.Preview
    };
}
export function createPlaylistRecommendationsPageModel(data: PlaylistRecommendationPageData, owner: RecommendationOwner): PlaylistRecommendationPageModel {
    return {
        ...createRecommendations(data, owner),
        contentType: ContentType.PlaylistRecommendation,
        type: DomainModelType.Page,
        playlists: createPlaylistPreviewConnectionModel(data.playlists)
    };
}
