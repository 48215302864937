import { useEffect, useState } from "preact/hooks";

export const useScrollYPosition = (parent?: HTMLElement | null, fn?: (val: number) => number) => {
    const [scrollYPosition, setScrollYPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (parent) {
                const y = fn ? fn(parent?.scrollTop) : parent?.scrollTop;
                setScrollYPosition(y);
            }
        };

        parent?.addEventListener("scroll", handleScroll);

        return () => {
            parent?.removeEventListener("scroll", handleScroll);
        };
    }, [fn, parent]);

    return scrollYPosition;
};
