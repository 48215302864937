import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { Section } from "./Section";
import { MyPlaylistsSortingContextMenu, ResourceContextMenu } from "../contextMenu/menus";
import { store } from "global";
import { useTranslations } from "global/config";
import { sidebarConfig, TestLocator } from "global/constants";
import { DefaultLogMessage, log } from "services/logger";
import { addPlayableToPlaylist, useUserPlaylists } from "services/playlist";
import { getNextUUID } from "services/utils";
import type { DraggablePlayableModel, DragProps, RootModel } from "models/app";
import { createDraggablePlayableModel } from "models/app";
import type { SectionContextModel } from "models/app/resourceContextModel";
import type { PlaylistPreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { useContextMenu, useLinkAndPreviewContext, usePreviewContext, useRefUpdate } from "components/shared/hooks";
import { ButtonDesign, Button } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { DropZone } from "components/molecules/dropZone";

export const MyMusicPlaylists = ({ context }: { context: SectionContextModel }) => {
    const { buttonRef, sort, sorting } = useMyPlaylistsSortingButtonWithMenu("sidebarPlaylistsSorting");
    const playlists = useUserPlaylists(sorting);
    const dragProps: DragProps = useMemo(() => ({ dragSourceId: getNextUUID() }), []);
    const translations = useTranslations();

    return !playlists || playlists.length == 0 ? null : (
        <Section
            className="myMusicPlaylists"
            top={{
                title: translations.Playlists,
                button: <Button icon={IconName.Meatballs} onClick={sort} ref={buttonRef} inline={{ right: true }} />
            }}>
            {playlists?.slice(0, sidebarConfig.playlistShortcutLimit).map((playlist, index) => (
                <PlaylistSidebarButton key={playlist.id} playlist={playlist} context={context} dragProps={dragProps} index={index} />
            ))}
        </Section>
    );
};

const useMyPlaylistsSortingButtonWithMenu = (target: "playlistsSorting" | "sidebarPlaylistsSorting") => {
    const buttonRef = useRefUpdate<HTMLButtonElement>();
    const sortingMenuHook = useContextMenu(<MyPlaylistsSortingContextMenu target={target} />);
    const sorting = useSelector((root: RootModel) => root.ui[target]);
    const sort = () => {
        if (!buttonRef.current) {
            log.warn({ code: "web-220427-1114", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        sortingMenuHook.open(buttonRef.current);
    };
    return { buttonRef, sort, sorting };
};

const PlaylistSidebarButton = ({ playlist, context, dragProps, index }: { playlist: PlaylistPreviewModel; context: SectionContextModel; dragProps: DragProps; index: number }) => {
    const previewContext = usePreviewContext(ContentType.SideBar, playlist, context, index);
    const draggableItem = useCallback(() => createDraggablePlayableModel(playlist, dragProps.dragSourceId, previewContext), [playlist, dragProps.dragSourceId, previewContext]);
    const menu = useContextMenu(ResourceContextMenu({ resource: playlist, context: previewContext, resourceType: playlist.contentType }), true);

    const allowDrop = useCallback(
        (item: DraggablePlayableModel) => {
            const source = store.getState().ui.userDraggingItem;
            return item.playable.contentType !== ContentType.Playlist || source?.playable?.id != playlist.id;
        },
        [playlist]
    );
    const onDrop = useCallback(
        (model: DraggablePlayableModel) => {
            addPlayableToPlaylist(playlist.id, model.playable);
        },
        [playlist]
    );

    return (
        <div>
            <DropZone onDropFn={onDrop} allowDrop={allowDrop} customClass="--drop-fill" />
            <Button
                key={playlist.id}
                design={ButtonDesign.SideBarLinkMedium}
                link={useLinkAndPreviewContext(ContentType.Playlist, playlist, context, null)}
                draggableItem={draggableItem}
                onContextMenu={menu.openByMouse}
                testLocator={TestLocator.NavigationPlaylistButton}>
                {playlist.title}
            </Button>
        </div>
    );
};
