import { h } from "preact";
import { useCallback, useState } from "preact/hooks";
import { Link } from "../Link";
import { store, useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { LogoutTrigger } from "services/logger/analytics/properties/event";
import { logout, useDisplayLoggedIn } from "services/user";
import { IconName } from "components/atoms/icon";
import { showLoginUi } from "components/organisms/login";
import { useSelector } from "react-redux";
import { RootModel } from "models/app";

interface Props {
    showIcon: boolean;
}

export const LogInOutLink = ({ showIcon }: Props) => {
    const loggedIn = useDisplayLoggedIn();
    const username = useSelector((root: RootModel) => root.user.username);
    const logoutFn = useCallback(() => logout(LogoutTrigger.Manual), []);
    const translations = useTranslations();
    return loggedIn ? (
        <Link
            closeOnClick
            onClick={logoutFn}
            tooltip={translations.SettingsLogoutPrompTitle}
            icon={showIcon ? IconName.SignOut : undefined}
            testLocator={TestLocator.AccountMenuLogoutButton}>
            {
                username
                    ? <div>
                        {translations.SettingsLogoutPrompTitle}
                        <p>{username}</p>
                    </div>
                    : translations.SettingsLogoutPrompTitle
            }
        </Link>
    ) : (
        <Link closeOnClick onClick={showLoginUi} tooltip={translations.NavBarLogin} icon={showIcon ? IconName.SignIn : undefined} testLocator={TestLocator.AccountMenuLoginButton}>
            {translations.NavBarLogin}
        </Link>
    );
};
