import type { ComponentChildren } from "preact";
import { h } from "preact";
import type { ModalButton } from ".";
import { dismissModal } from ".";
import "./Modal.scss";
import { dispatch } from "global";
import { PAGE_CLICK } from "global/actions";
import { useTranslations } from "global/config";
import { KeyboardKeyName, useAppMediaSize, usePageKey } from "components/shared/hooks";
import { Button } from "components/atoms/controls/button";
import { FormState } from "components/atoms/controls/form/FormState";
import { IconName } from "components/atoms/icon";

export interface Props {
    buttons?: ModalButton[];
    canClose?: boolean;
    hasXCloseButton?: boolean;
    children?: ComponentChildren;
    className?: string;
    onRequestClose?: () => Promise<boolean>;
    text?: string;
    title?: string;
    formState?: FormState;
    bottom?: ComponentChildren;
}

export const Modal = ({
    formState: state = FormState.Default,
    buttons,
    canClose = true,
    hasXCloseButton = false,
    children,
    className,
    onRequestClose,
    text,
    title,
    bottom
}: Props) => {
    const size = useAppMediaSize();
    const translations = useTranslations();

    const stopPropagation = (ev: MouseEvent) => {
        ev.stopPropagation();
        dispatch({ type: PAGE_CLICK, payload: { ev } });
    };

    const close = async () => {
        if (!onRequestClose || (await onRequestClose())) dismissModal("Modal.close");
    };

    const backgroundClick = (ev: MouseEvent) => {
        stopPropagation(ev);
        if (canClose) close();
    };

    usePageKey((ev) => {
        if (!canClose) return;
        if (ev.key === KeyboardKeyName.Escape) {
            ev.stopPropagation();
            ev.preventDefault();
            close();
        }
    });

    return (
        <div className={`modal LQo --size-${size} --hasXCloseButton-${hasXCloseButton}`} onClick={backgroundClick}>
            <div className="scroll">
                <div className="outer">
                    <div className={`content`} onClick={stopPropagation}>
                        <div className={`inner ${className || ""}`}>
                            {hasXCloseButton && <Button className="close" icon={IconName.Close} onClick={close} />}
                            {title && <h1 className="title">{title}</h1>}
                            {text && <div className="text"> {text}</div>}
                            {children}
                            {buttons && (
                                <div className="buttons">
                                    {buttons.map((button) => (
                                        <div key={button.title} className="wrapper">
                                            <Button disabled={state === FormState.Submiting} design={button.design} onClick={button.action}>
                                                {translations[button.title] ?? button.title}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {bottom && <div className="bottom">{bottom}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
