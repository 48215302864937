import { useLayoutEffect, useState, useCallback } from "preact/hooks";

export enum DelayType {
    None,
    AnimationFrame,
    TimeOut
}

export type Delay = { type: DelayType.None } | { type: DelayType.AnimationFrame } | { type: DelayType.TimeOut; msec: number };

interface ValueDelayProps<T> {
    delay: Delay;
    value: T;
    initialValue?: T;
    reset?: unknown;
}

export const useDelayValue = <T>({ delay, value, initialValue = value, reset }: ValueDelayProps<T>) => {
    const [delayed, setDelayed] = useState(delay.type === DelayType.None ? value : initialValue);

    const msec = delay.type === DelayType.TimeOut ? delay.msec : 0;
    const timeOut = useCallback(() => {
        if (delay.type === DelayType.None) {
            setDelayed(value);
        } else {
            if (delay.type === DelayType.AnimationFrame) {
                const frameId = requestAnimationFrame(() => setDelayed(value));
                return () => cancelAnimationFrame(frameId);
            }
            if (delay.type === DelayType.TimeOut) {
                const timeoutId = window.setTimeout(() => setDelayed(value), msec);
                return () => clearTimeout(timeoutId);
            }
        }
    }, [delay.type, msec, value]);

    useLayoutEffect(() => {
        const clear = timeOut();
        return () => clear && clear();
    }, [timeOut]);

    useLayoutEffect(() => {
        if (delay.type === DelayType.None) return;
        if (value === initialValue) return;
        setDelayed(initialValue);
        const clear = timeOut();
        return () => clear && clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset, timeOut, delay.type, initialValue]);

    return delayed;
};
