import { h } from "preact";
import { LayoutPageItemShowAllPage } from "./LayoutPageItemShowAllPage";
import { environment } from "services/environment";
import { ContentType } from "models/ModelType";

interface Props {
    itemId: string;
    resourceId?: string;
}

export const RadioPageItemShowAllPage = ({ itemId, resourceId }: Props) => {
    return <LayoutPageItemShowAllPage pageType={ContentType.RadioPageShowAll} pageId={environment.apiRadioPageId} itemId={itemId} resourceId={resourceId} />;
};
