import type { ComponentChildren, RefObject } from "preact";
import { h } from "preact";
import { useCallback, useRef, useState } from "preact/hooks";
import "./MobileSearchPageView.scss";
import { getLink } from "services/resource";
import type { PageContextModel } from "models/app/resourceContextModel";
import { Button } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { PageTopBar } from "components/molecules/pageTopBar";
import { Search, SearchResultDisplay } from "components/organisms/search";

interface Props {
    children?: ComponentChildren;
    context: PageContextModel;
    scrollRef: RefObject<HTMLElement>;
    onScroll?: () => void;
}

export const MobileSearchPageView = ({ children, context, scrollRef, onScroll }: Props) => {
    const title = getLink(context.type, context.resource)?.text;

    const [inSearch, setInSearch] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const closeSeach = useCallback(() => {
        setInSearch(false);
    }, []);

    const onInputFocus = useCallback(() => {
        setInSearch(true);
    }, []);

    const [search] = Search({ context, onFocus: onInputFocus, resultsDisplay: inSearch ? SearchResultDisplay.Always : SearchResultDisplay.Hide });

    return (
        <div
            className={`mobileSearchPageView Xim --inSearch-${inSearch}`}
            onScroll={onScroll}
            ref={(r) => {
                scrollRef.current = r as HTMLElement;
            }}>
            {!inSearch && <PageTopBar />}
            <div className="content">
                <div className="search">
                    {!inSearch && <h1>{title}</h1>}
                    <div>
                        {inSearch && <Button ref={buttonRef} className="up" icon={IconName.ArrowLeftAndroid} onClick={closeSeach} inline={{ left: true }} />}
                        {search}
                    </div>
                </div>
                <hr />
                {children && <div className="content">{children}</div>}
            </div>
        </div>
    );
};
