import type { AppVersionModel } from "shared/services";
import { appReloadServiceConfig, getCurrentAppVersion, serviceWorkerConfig } from "shared/services";
import type { PostMessage } from "shared/services/postMessage";
import { PostMessageName } from "shared/services/postMessage";
import { AppVersionCompare, appVersionCompare } from "services/appVersion";
import { log } from "services/logger/initLoggerService";

const pageLoadMs = new Date().getTime();

export function listenToAppReloadMessages(serviceWorker: ServiceWorkerContainer) {
    if (!appReloadServiceConfig.enableAutoReload) return;

    serviceWorker.addEventListener("message", (event) => {
        const message = event.data as PostMessage | undefined;

        if (message?.type === PostMessageName.SwVersion) {
            reloadIfVersionChanged(message.version, message.reloadDelayMs);
        }
    });
}

export function pollForServiceWorkerUpdates(registration: ServiceWorkerRegistration) {
    if (!appReloadServiceConfig.enableAutoReload || !appReloadServiceConfig.pollUpdateEnabled) return;

    window.setInterval(() => {
        log.info({ code: "web-220223-1948", msg: "client: call serviceWorker update" });
        registration.update();
    }, appReloadServiceConfig.pollUpdateIntervalMs);
}

function reloadIfVersionChanged(newVersion: AppVersionModel, reloadTimeoutMs: number) {
    const currentVersion = getCurrentAppVersion();
    const change = appVersionCompare(newVersion, currentVersion);

    log.info({
        code: "web-220223-1949",
        msg: "client: reloadIfVersionChanged",
        data: {
            currentVersion,
            newVersion,
            reloadTimeoutMs,
            change
        }
    });

    switch (change) {
        case AppVersionCompare.Same:
            resetReloadTimeout();
            return;
        case AppVersionCompare.Higher:
            startReloadTimeout(reloadTimeoutMs);
            return;
        case AppVersionCompare.Lower:
            window.location.reload();
            return;
    }
}

function resetReloadTimeout() {
    if (reloadTimeout == null && reloadTimeoutBegin == null) return;

    log.warn({ code: "web-220223-1955", msg: "client: reset reload timeout" });

    reloadTimeoutBegin = null;
    if (reloadTimeout != null) clearTimeout(reloadTimeout);
}

let reloadTimeout: number | null = null;
let reloadTimeoutBegin: Date | null = null;

function startReloadTimeout(reloadTimeoutMs: number) {
    if (serviceWorkerConfig.enableInstallationCacheFirst) {
        const pageJustLoaded = new Date().getTime() - pageLoadMs <= 10 * 1000;
        if (pageJustLoaded) {
            log.warn({ code: "web-220223-1956", msg: "client: instant update reload" });

            window.location.reload();
            return;
        }
    }

    if (reloadTimeout == null || reloadTimeoutBegin == null) {
        reloadTimeoutBegin = new Date();
        setReloadTimeout(reloadTimeoutMs);
    } else {
        log.warn({ code: "web-220223-1956", msg: "client: update reload timeout" });

        const passed = new Date().getTime() - reloadTimeoutBegin.getTime();
        reloadTimeoutMs -= passed;
        setReloadTimeout(reloadTimeoutMs);
    }
}

function setReloadTimeout(reloadTimeoutMs: number) {
    log.info({ code: "web-220223-1950", msg: "client: set reload timout", data: { reloadTimeoutMs } });

    if (reloadTimeoutMs <= 0) {
        log.info({ code: "web-220223-1951", msg: "client: reload now..." });

        window.location.reload();
    } else {
        reloadTimeout = window.setTimeout(() => {
            log.info({ code: "web-220223-1952", msg: "client: reload from timeout" });

            window.location.reload();
        }, reloadTimeoutMs);
    }
}
