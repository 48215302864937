import type { ComponentChild } from "preact";
import { Fragment, h } from "preact";
import { useMemo, useRef, useState } from "preact/hooks";
import "./ResourceSection.scss";
import { Content } from "./Content";
import { useSkeletonItems } from "./useSkeletonItems";
import { useTranslations } from "global/config";
import { ResourceSectionTestLocator, TestLocator } from "global/constants";
import { getLinkFromContext } from "services/resource";
import type { Multiselect } from "services/selection";
import type { DropProps } from "models/app";
import type { PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { ResourceModel, ResourcePreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import type { NotificationConversion } from "models/domain/NotificationModel";
import { useLinkAndPreviewContext, useResizeObserver, useSectionIsHidden } from "components/shared/hooks";
import type { Pagination } from "components/shared/hooks/usePagination";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";
import { PreviewAction } from "components/molecules/preview";
import type { ScrollerNavigateCallback, ScrollerNavigatePropeties } from "components/organisms/scroller/options";

interface Props {
    context: SectionContextModel;
    showTitle?: boolean;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    totalItems?: number;
    resources: ResourcePreviewModel[] | null;
    showAll?: ResourceModel | null;
    buttons?: ComponentChild;
    customIcon?: IconName;
    customIconAction?: (resource: ResourcePreviewModel) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pagination?: Pagination<any>;
    paginationCallback?: () => void;
    skeletonItems?: true;
    getDropProps?: (model: ResourcePreviewModel, index: number) => DropProps;
    highlightedId?: string;
    multiselect?: Multiselect;
    locked?: boolean;
}

export const ResourceSection = ({
    showTitle,
    totalItems,
    resources,
    context,
    showAll,
    onAction,
    buttons,
    customIcon,
    customIconAction,
    pagination,
    paginationCallback,
    skeletonItems,
    getDropProps,
    highlightedId,
    multiselect,
    locked
}: Props) => {
    const navigateScrollerRef = useRef<ScrollerNavigateCallback>(null);
    const [navigateProperties, setNavigateProperties] = useState<ScrollerNavigatePropeties | null>(null);

    const isHidden = resources != null && resources.length === 0;
    useSectionIsHidden(context, isHidden);

    const translations = useTranslations();
    const ref = useRef<HTMLDivElement>(null);
    const width = useResizeObserver(ref.current)?.width ?? null;

    const displayType = context.displayType;
    const disableUi = displayType === ResourceDisplayType.PromoSingle || displayType === ResourceDisplayType.LargeSingle;

    const title = useMemo(() => (showTitle ? getLinkFromContext(context)?.text : null), [showTitle, context]);
    const showTop = displayType !== ResourceDisplayType.Single && displayType !== ResourceDisplayType.LargeSingle && (showTitle == true || showAll != null || buttons != null);
    const showAllLink = useLinkAndPreviewContext(context.type, context.resource, context, 0);

    resources = useSkeletonItems({ skeletonItems, displayType, resources, section: context });

    if (!displayType || isHidden) return null;

    return (
        <section ref={ref} className="resourceSection BUz" data-cy={ResourceSectionTestLocator(context)}>
            {showTop && (
                <div className="top">
                    {!disableUi && <h1>{title ?? ""}</h1>}
                    <div className="menu">
                        {buttons}
                        {showAll && !disableUi && (
                            <Button
                                className="showAll"
                                design={ButtonDesign.TextBrandSmall}
                                onClick={() => {
                                    onAction && showAllLink.preview && onAction(PreviewAction.Navigate, showAllLink.preview, null);
                                }}
                                link={showAllLink}
                                testLocator={TestLocator.ResourceSectionShowAllButton}>
                                {translations.ShowAll}
                            </Button>
                        )}
                        {navigateProperties != null && !disableUi && (
                            <Fragment>
                                <Button
                                    design={ButtonDesign.LightSmall}
                                    icon={IconName.ArrowLeft16}
                                    onClick={() => {
                                        navigateScrollerRef?.current && navigateScrollerRef.current(-1);
                                    }}
                                    disabled={!navigateProperties.left}
                                    testLocator={TestLocator.ResourceSectionBackwardButton}
                                />
                                <Button
                                    design={ButtonDesign.LightSmall}
                                    icon={IconName.ArrowRight16}
                                    onClick={() => {
                                        navigateScrollerRef?.current && navigateScrollerRef.current(1);
                                    }}
                                    disabled={!navigateProperties.right}
                                    testLocator={TestLocator.ResourceSectionForwardButton}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            )}
            <Content
                multiselect={multiselect}
                context={context}
                customIcon={customIcon}
                customIconAction={customIconAction}
                displayType={displayType}
                onAction={onAction}
                onScrollerNavigatePropertiesChange={setNavigateProperties}
                pagination={pagination ?? null}
                paginationCallback={paginationCallback}
                totalItems={totalItems}
                resources={resources}
                scrollerNavigateRef={navigateScrollerRef}
                width={width}
                getDropProps={getDropProps}
                highlightId={highlightedId}
                locked={locked}
            />
        </section>
    );
};
