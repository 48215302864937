import { onCastApiAvailable } from "../castEvents/api/onCastApiAvailable";

export const addCastApiListener = () => {
    const src = "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;

    document.head.appendChild(script);
    window["__onGCastApiAvailable"] = (isAvailable: boolean) => onCastApiAvailable(isAvailable);
};
