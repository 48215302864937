import type { ComponentChildren } from "preact";
import { h } from "preact";
import { useState } from "preact/hooks";
import "./Accordion.scss";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { Icon, IconName, IconSize } from "components/atoms/icon";

interface Props {
    children: ComponentChildren;
    title: string;
    icon: IconName;
}
export const Accordion = ({ children, title, icon }: Props) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = () => {
        setOpen(!open);
    };
    return (
        <div className={`accordion LsQ`}>
            <Button onClick={toggleOpen} icon={icon} design={ButtonDesign.SideBarLinkBig} inline={{ left: true }}>
                <div className="title">{title}</div>
                <Icon className={`chevron --open-${open}`} icon={IconName.ArrowRight} size={IconSize.Default} />
            </Button>

            <div className={`content  --show-${open}`}>{children}</div>
        </div>
    );
};
