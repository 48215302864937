import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getChartItems, useChartPage } from "services/backend";
import type { AlbumPreviewModel, TrackPreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import type { ChartPageModel } from "models/domain/ChartModel";
import { getChartModelItems } from "models/domain/ChartModel";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useAutoPlay, usePageContext, useResourcesFromUnions, useSectionContext } from "components/shared/hooks";
import { usePagination } from "components/shared/hooks/usePagination";
import { ResourceSection } from "components/organisms/resourceSection";
import { ProductPageTemplate } from "components/templates/productPage";

interface Props {
    chartId: string;
}

export const ChartPage = ({ chartId }: Props) => {
    const query = useChartPage({ id: chartId, first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string, index: number) => getChartItems({ first, after, id: chartId }, index), [chartId]);

    const type = product?.contentType ?? ContentType.Chart;
    const displayType = useDisplayType(type);

    const page = usePageContext({ type, resource: product, root: null, done: query.success });
    const top = useSectionContext(type, product, page, 0, ResourceDisplayType.Top);
    const content = useSectionContext(type, product, page, 1, displayType);

    const pagination = usePagination<AlbumPreviewModel | TrackPreviewModel>({ resource: product, connection: product ? getChartModelItems(product) : null, fetchFn });
    const items = useResourcesFromUnions(pagination.connection?.items ?? null, product);

    useAutoPlay({ page, type, resource: product });

    return (
        <ProductPageTemplate query={query} type={type} section={top} isUserOwned={false} scrollRef={pagination.scrollRef}>
            <ResourceSection pagination={pagination} resources={items ?? null} context={content} />
        </ProductPageTemplate>
    );
};

type ChartPageType = ChartPageModel["contentType"] | ContentType.Chart;

function useDisplayType(type: ChartPageType) {
    const appSize = useAppMediaSize();

    const displayType = useMemo(() => {
        switch (type) {
            case ContentType.AlbumChart: {
                if (appSize === MediaSize.Mobile) return ResourceDisplayType.ListLarge;
                else return ResourceDisplayType.List;
            }
            case ContentType.TrackChart:
                return ResourceDisplayType.List;
            case ContentType.Chart:
                return null;
        }
    }, [type, appSize]);

    return displayType;
}
