import { h } from "preact";
import { useTranslations } from "global/config";
import { IconName } from "components/atoms/icon";
import { AccordionLink } from "./AccordionLink";

export const HelpSettings = () => {
    const translations = useTranslations();
    const goToHelp = () => {
        window.open(translations.SettingsHelpUrl, "_blank", "noopener ");
    };
    return (
        <AccordionLink icon={IconName.GuidedHelp} title={translations.SettingsHelpButton} onClick={goToHelp} />
    );
};
