import type { TrackPlayablePreviewModel } from ".";
import { createTrackPlayablePreview } from ".";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { TrackPreviewModel } from "./TrackModel";
import { createTrackPreviewModel } from "./TrackModel";
import { getNextUUID } from "services/utils";
import type { TrackPreviewFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type MixedContentTrackPreviewData = TrackPreviewFragment;

export type MixedContentTrackModel = MixedContentTrackPlayModel;
export type MixedContentTrackPlayModel = { type: DomainModelType.Play } & MixedContentTrack;

interface MixedContentTrack extends PlayQueuePlayableModelInterface {
    contentType: ContentType.MixedContentTrack;
    track: TrackPreviewModel | null;
}

export function createMixedContentTrackPlayModel(data: MixedContentTrackPreviewData): MixedContentTrackPlayModel {
    return {
        contentType: ContentType.MixedContentTrack,
        graphType: data.__typename,
        id: getNextUUID(),
        cover: data.cover ?? null,
        trackCount: 1,
        track: createTrackPreviewModel(data, 0),
        type: DomainModelType.Play
    };
}

export function createTrackPlayablePreviewModelFromMixedContentTrack(data: MixedContentTrackPreviewData): TrackPlayablePreviewModel | null {
    const playContextTrack = createMixedContentTrackPlayModel(data);
    if (playContextTrack.track == null) return null;
    return createTrackPlayablePreview(playContextTrack.track, playContextTrack);
}
