import { h } from "preact";
import { Link } from "..";
import { useTranslations } from "global/config";
import { IconName } from "components/atoms/icon";

interface Props {
    onClick: () => void;
    showIcon: boolean;
    disabled?: boolean;
}

export const CreatePlaylistLink = ({ showIcon, onClick, disabled }: Props) => {
    const translations = useTranslations();
    return (
        <Link disabled={disabled} onClick={onClick} icon={showIcon ? IconName.Playlist : undefined} closeOnClick>
            {translations.CreatePlaylist}
        </Link>
    );
};
