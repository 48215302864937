import { getData, saveData } from "../helpers";
import { getSharedAccessTokenStorageKey, getSharedRefreshTokenStorageKey } from "../storageKeys";

export function getSharedTokens(): { refreshToken: string | null; accessToken: string | null } {
    const refreshToken = getSharedRefreshToken();
    const accessToken = getSharedAccessToken();
    return { refreshToken, accessToken };
}

export function removeSharedTokens(): void {
    saveSharedRefreshToken(null);
    saveSharedAccessToken(null);
}

function getSharedAccessToken() {
    const key = getSharedAccessTokenStorageKey();
    return getData(key);
}

function saveSharedAccessToken(token: string | null) {
    const key = getSharedAccessTokenStorageKey();
    saveData(key, token);
}

function getSharedRefreshToken() {
    const key = getSharedRefreshTokenStorageKey();
    return getData(key);
}

function saveSharedRefreshToken(token: string | null) {
    const key = getSharedRefreshTokenStorageKey();
    saveData(key, token);
}
