import type { AnalyticsData } from "../../analyticsData";
import { SetAnalyticsProp, createAnalyticsData } from "../../analyticsData";
import type { AnalyticsKey } from "global/config";
import { getNotificationRank, getNotificationIndex, getNotificationFeedFromState } from "services/notificationFeed/helpers";
import type { NotificationConversion, NotificationModel } from "models/domain/NotificationModel";

function addSingleNotificationProperties(data: AnalyticsData, notification: NotificationModel) {
    const feed = getNotificationFeedFromState();

    let rank = feed ? getNotificationRank(feed, notification) : null;
    let index = feed ? getNotificationIndex(feed, notification) : null;

    if (index != null) index++;
    if (rank != null) rank++;

    SetAnalyticsProp(data, "event", "NotificationID", notification.id as AnalyticsKey);
    SetAnalyticsProp(data, "event", "NotificationKind", notification.notificationKind);
    SetAnalyticsProp(data, "event", "NotificationType", notification.notificationType);

    if (notification.eventType) {
        SetAnalyticsProp(data, "event", "NotificationEventType", notification.eventType as AnalyticsKey);
    }

    if (notification.eligibilityReasons) {
        SetAnalyticsProp(data, "event", "EligibilityReasons", notification.eligibilityReasons);
    }

    SetAnalyticsProp(data, "event", "Number", index);
    SetAnalyticsProp(data, "event", "Rank", rank);
    SetAnalyticsProp(data, "event", "Score", notification.score, true);

    SetAnalyticsProp(data, "event", "PlayableID", (notification.playContextId as AnalyticsKey) ?? null, true);
    SetAnalyticsProp(data, "event", "PlayableType", (notification.playable?.contentType as AnalyticsKey) ?? null, true);

    return data;
}

export function getAnalyticsSingleNotificationAdded(notification: NotificationModel) {
    const data = createAnalyticsData();

    addSingleNotificationProperties(data, notification);

    return data;
}

export function getAnalyticsSingleNotificationSeen(notification: NotificationModel) {
    const data = createAnalyticsData();

    addSingleNotificationProperties(data, notification);

    return data;
}

export function getAnalyticsSingleNotificationConverted(notification: NotificationModel, conversion: NotificationConversion) {
    const data = createAnalyticsData();

    SetAnalyticsProp(data, "event", "Conversion", conversion);
    addSingleNotificationProperties(data, notification);

    return data;
}
