import type { ApolloError, QueryResult, ApolloQueryResult, OperationVariables } from "@apollo/client";
import { useQuery } from "@apollo/client";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { DocumentNode } from "graphql";
import { useState, useEffect, useCallback, useMemo, useRef } from "preact/hooks";
import { graphqlClient } from ".";
import { useLocalization } from "components/app/hooks";
import { createdIntervalCallbackGroup } from "services/cancellation/intervalCallbackGroup";
import { log } from "services/logger";
import { useCoalesced } from "components/shared/hooks";

interface MapDomainResult<DomainModel> {
    model: DomainModel | null;
    error: QueryError.MapError | QueryError.ContentNotFoundError | null;
    errorReason: string | null;
}

export interface UseDomainQueryResult<Model> {
    model: Model | null;
    error: QueryError | null;
    errorReason: string | null;
    refetch: (user: boolean) => void;
    refetching: boolean;
    loading: boolean;
    essential?: boolean;
    success: boolean;
    coalesced?: boolean;
}

export interface UseCombinedDomainQueryResult<Model> extends UseDomainQueryResult<Model> {
    queries: UseDomainQueryResult<unknown>[];
}

export interface DomainQueryResult<Model> {
    model: Model | null;
    error: QueryError | null;
    errorReason: string | null;
    loading: boolean;
    success: boolean;
}

export enum QueryError {
    BrowserOfflineError = "BrowserOfflineError",
    NetworkClientError = "NetworkClientError",
    NetworkServerDownError = "NetworkServerDownError",
    NetworkServerError = "NetworkServerError",
    NetworkUnknownError = "NetworkUnknownError",
    MapError = "MapError",
    UnknownError = "UnknownError",
    ContentNotFoundError = "ContentNotFoundError",
    MultipleErrors = "MultipleErrors",
    NotAvailableInSubscriptionError = "NotAvailableInSubscription"
}

function getQueryError(error: ApolloError | null): QueryError | null {
    try {
        if (error == null) return null;
        const { networkError, graphQLErrors } = error;

        if (networkError) {
            const statusCode = "statusCode" in networkError ? networkError.statusCode : null;

            if (statusCode != null) {
                if (statusCode === 429) return QueryError.NetworkServerDownError;
                if (statusCode >= 400 && statusCode < 500) return QueryError.NetworkClientError;
                if (statusCode >= 500 && statusCode < 600) return QueryError.NetworkServerError;
            }
            if (!window.navigator.onLine) return QueryError.BrowserOfflineError;
            return QueryError.NetworkUnknownError;
        }

        // todo: maybe we should be able to parse resource not found from graphQLErrors?
        if (graphQLErrors.length > 0) return QueryError.UnknownError;

        return QueryError.UnknownError;
    } catch (e) {
        log.error({ code: "web-220216-1522", msg: "error in getQueryError", error: e });
        return QueryError.UnknownError;
    }
}

function useQueryError<Data, Variables extends OperationVariables>(result: QueryResult<Data, Variables>): QueryError | null {
    const [error, setError] = useState<QueryError | null>(null);

    useEffect(() => {
        setError(getQueryError(result.error ?? null));
    }, [result]);

    return error;
}

function mapDomainModel<Data, Model>(data: Data | null, map: (data: Data) => Model | null | undefined): MapDomainResult<Model> {
    if (data == null) {
        return { error: null, errorReason: null, model: null };
    }

    try {
        const model = map(data) ?? null;
        if (model == null) {
            log.error({ code: "web-211015-1914", msg: "model is null when map model", data: { map, data } });
            const errorReason = `model is null when map model: ${JSON.stringify(data)}`;
            return { error: QueryError.ContentNotFoundError, errorReason, model: null };
        }

        return { error: null, errorReason: null, model };
    } catch (e) {
        log.error({ code: "web-211015-1919", msg: "exception when map model", data: { map, data }, error: e });
        const errorReason = `exception when map model: ${JSON.stringify(data)}`;
        return { error: QueryError.MapError, errorReason, model: null };
    }
}

function useMapDomainModel<Data, Model>(data: Data | null, map: (data: Data) => Model | null | undefined): MapDomainResult<Model> {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => mapDomainModel(data, map), [data]);
}

export function useModify<Model>(model: Model | null) {
    const [state, setState] = useState(model);

    const modify = useCallback((model: Model) => {
        setState(model);
    }, []);

    useEffect(() => setState(model), [model]);
    return { model: state, modify };
}

export function useModifyQuery<Model>(result: UseDomainQueryResult<Model>) {
    const [modified, setModified] = useState(() => result);

    const modify = useCallback((model: Model) => {
        setModified((state) => ({ ...state, model }));
    }, []);
    useEffect(() => setModified(result), [result]);
    return { modified, modify };
}

function useRefetch(refetch: () => void) {
    const [refetching, setRefetching] = useState(false);

    const refetchFn = useCallback(
        async (manual: boolean) => {
            if (manual) setRefetching(true);
            try {
                await refetch();
            } catch {
                //
            }
            const timeoutId = setTimeout(() => {
                setRefetching(false);
            }, 0);
            return () => clearTimeout(timeoutId);
        },
        [refetch]
    );

    return { refetch: refetchFn, refetching };
}

async function query<Data, Variables extends OperationVariables>(operation: TypedDocumentNode<Data, Variables>, variables: Variables): Promise<ApolloQueryResult<Data>> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const query = await graphqlClient!.query({ query: operation, variables, errorPolicy: "all" });
    return query;
}

function logQueryError(
    operation: DocumentNode,
    variables: OperationVariables,
    result: QueryResult | ApolloQueryResult<unknown> | null,
    error: QueryError | null,
    errorReason: string | null
) {
    if (error) {
        log.error({
            code: "web-211015-1913",
            msg: "query error",
            data: {
                error,
                result,
                operation,
                variables,
                errorReason
            }
        });
    }
}

export async function queryDomainModel<Data, Variables extends OperationVariables, Model>(
    operation: TypedDocumentNode<Data, Variables>,
    variables: Variables,
    map: (data: Data) => Model | null | undefined,
    callback?: (query: DomainQueryResult<Model>) => void
): Promise<DomainQueryResult<Model>> {
    callback?.({ error: null, errorReason: null, model: null, loading: true, success: false });

    let queryResult: ApolloQueryResult<Data> | null = null;
    let model: Model | null = null;

    let error: QueryError | null = null;
    let errorReason: string | null;

    try {
        queryResult = await query(operation, variables);
        error = getQueryError(queryResult?.error ?? null);
        errorReason = error ? JSON.stringify(queryResult?.error) : null;
    } catch (e) {
        if (!window.navigator.onLine) error = QueryError.BrowserOfflineError;
        else error = QueryError.NetworkUnknownError;
        errorReason = JSON.stringify(e);
    }

    if (queryResult && !error) {
        const mapModel = mapDomainModel(queryResult.data, map);
        error = mapModel?.error ?? null;
        errorReason = mapModel.errorReason;
        model = mapModel?.model ?? null;
    }

    logQueryError(operation, variables, queryResult, error, errorReason);

    const success = model !== null;

    const result = { error, errorReason, model, loading: false, success };
    callback?.(result);
    return result;
}

export function useQueryDomainModel<Data, Variables extends OperationVariables, Model>(
    operation: TypedDocumentNode<Data, Variables>,
    variables: Variables,
    map: (data: Data) => Model | null | undefined,
    skip?: boolean,
    reloadOnLanguageChange = false,
    coalesce = false
): UseDomainQueryResult<Model> {
    const queryResult = useQuery(operation, { variables, skip });
    let error: QueryError | null = null;
    let errorReason: string | null = null;

    const queryError = useQueryError(queryResult);
    if (queryError) {
        error = queryError;
        errorReason = JSON.stringify(queryResult?.error);
    }

    const { model, error: parseError, errorReason: parserErrorReason } = useMapDomainModel(!error ? queryResult.data ?? null : null, map);
    if (parseError) {
        error = parseError;
        errorReason = parserErrorReason;
    }
    const local = useLocalization();
    const { refetch, refetching } = useRefetch(queryResult.refetch);
    const loading = queryResult.loading;
    const success = model !== null;

    logQueryError(operation, variables, queryResult, error, errorReason);

    useEffect(() => {
        if (reloadOnLanguageChange) refetch(false);
    }, [local, refetch, reloadOnLanguageChange]);

    const coalescedModel = useCoalesced(model, coalesce);

    return useMemo(() => ({ model: coalescedModel, error, errorReason, refetch, refetching, loading, success, coalesced: (coalescedModel !== model) }), [coalescedModel, model, error, errorReason, refetch, refetching, loading, success]);
}

export interface QueryParams<Model, Data extends DomainQueryResult<Data>> {
    fetch: () => Promise<DomainQueryResult<Data | null>>;
    map: (model: Model, data: Data) => Model;
    next?: number;
    essential?: boolean;
}

export interface CombinedQueryParams<Model> {
    model: Model;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: QueryParams<Model, any>[];
}

export function useDomainQueryResult<Model>(query: Promise<DomainQueryResult<Model> | null>) {
    const [result, setResult] = useState<DomainQueryResult<Model> | null>(null);
    const local = useLocalization();
    useEffect(() => {
        query
            .then((result) => {
                if (result == null) setResult({ model: null, loading: false, error: QueryError.UnknownError, errorReason: "result is null", success: false });
                else setResult(result);
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => { });
    }, [query, local]);

    return result;
}

export function useCombinedQuery<Model>(combine: CombinedQueryParams<Model> | null) {
    const { model: initial, params } = useMemo(() => combine ?? { model: null, params: [] }, [combine]);

    const ref = useRef<Model | null>(initial);
    const [model, setModel] = useState(initial);
    const [queries, setQueries] = useState<UseDomainQueryResult<unknown>[]>([]);
    const combined = useCombinedQueryResult(model, queries);

    const callbacks = useMemo(() => createdIntervalCallbackGroup(), []);
    const [autoUpdate, setAutoUpdate] = useState(false);

    useEffect(() => {
        autoUpdate ? callbacks.resume() : callbacks.pause();
    }, [callbacks, autoUpdate]);

    useEffect(() => {
        ref.current = initial;
        setModel(initial);
        setQueries([]);
        if (initial == null) return;

        params.forEach((param) => {
            const query: UseDomainQueryResult<unknown> = {
                loading: true,
                error: null,
                errorReason: null,
                model: null,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                refetch: null!,
                refetching: false,
                essential: param.essential,
                success: false
            };

            const fetch = (refetching: boolean) => {
                if (refetching) {
                    query.refetching = refetching;
                    setQueries((queries) => [...queries]);
                }

                param.fetch().then((data) => {
                    if (ref.current == null) return;

                    if (data.model != null) {
                        const updatedModel = param.map(ref.current, data.model);
                        ref.current = updatedModel;
                        setModel(updatedModel);
                    }

                    if (param.next != undefined) {
                        callback.updateInterval(param.next);
                    }

                    query.error = data.error;
                    query.errorReason = data.errorReason;
                    query.loading = data.loading;
                    query.success = data.success;
                    query.model = data.model;
                    query.refetching = false;

                    setQueries((queries) => [...queries]);
                });
            };
            const callback = callbacks.add(() => fetch(false));
            query.refetch = (user: boolean) => fetch(user);
            setQueries((queries) => [...queries, query]);
        });

        callbacks.begin();
        return () => callbacks.removeAll();
    }, [initial, params, callbacks]);

    return { query: combined, setAutoUpdate, callbacks };
}

export function useCombinedQueryResult<Model>(model: Model, queries: UseDomainQueryResult<unknown>[]) {
    return useMemo(() => getCombinedQueryResult(model, queries), [model, queries]);
}

function getCombinedQueryResult<Model>(model: Model, queries: UseDomainQueryResult<unknown>[]): UseCombinedDomainQueryResult<Model> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (queries.length == 0) return { model, error: null, errorReason: null, loading: false, success: false, refetch: () => { }, refetching: false, queries };

    const errorQueries = queries.filter((q) => q.error != null);
    const essentialQueries = queries.filter((q) => q.essential);

    const errorFirst = errorQueries[0]?.error ?? null;
    const errorEqual = errorQueries.every((q) => q.error === errorFirst);
    const errorType = errorEqual ? errorFirst : QueryError.MultipleErrors;
    const errorAll = queries.every((q) => !!q.error);
    const errorEssential = essentialQueries.some((q) => q.error);

    const error: QueryError | null = errorAll || errorEssential ? errorType : null;
    const errorReason = errorQueries.length === 0 ? null : errorQueries.map((n) => n.errorReason).join(", ");
    const refetching = queries.every((q) => q.refetching) || essentialQueries.some((q) => q.refetching);
    const loading = queries.every((q) => q.loading) || essentialQueries.some((q) => q.loading);
    const refetch = (user: boolean) => queries.forEach((query) => query.refetch(user));
    const success = essentialQueries.length > 0 ? essentialQueries.every((q) => q.success) : queries.every((q) => q.success);

    return { model, error, errorReason, refetching, refetch, loading, queries, success };
}
