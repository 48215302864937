import * as Sentry from "@sentry/react";
import { render } from "preact";
import { unloadStoreAndPersist } from ".";
import { configcmsInit } from "./configcms";
import { deleteSessionDbState, initStoreAndPersist } from "./configureStore";
import { App } from "components/app";
import { WebAppBrand, WebAppEnvironment } from "shared/models";
import { getAppVersionString } from "shared/services";
import { initAppSession, unloadAppSesssion } from "services/appSession";
import { unloadTokenLock } from "services/appSession/operations/sessionTokens";
import { initAppVersionService } from "services/appVersion";
import { initGraphQLClient as initGraphqlClient, initGraphqlSubscriptions } from "services/backend";
import { initGraphqlCacheService } from "services/cache";
import { initCacheQuotaService } from "services/cache/cacheQuota";
import { initConcurrency } from "services/concurrency";
import { initConcurrencyPolling } from "services/concurrency/polling";
import { initControlPanelService } from "services/controlPanel/initControlPanelService";
import type { BuildEnvironment, Environment } from "services/environment";
import { initEnvironment } from "services/environment";
import { initFavorites, initializeCachedFavoritesStatistics } from "services/favorites";
import { initLanguageService } from "services/language/";
import {
    initKibanaLoggerService,
    initLyricsReportService,
    initAnalyticsService,
    initConsoleLoggerService,
    initFbPerformance,
    initEventLogService,
    log, initMpLoggerService,
    initPlaybackReportSendService,
    initPlaybackReportService,
    startPlaybackReporting
} from "services/logger";
import { initNavigationStack } from "services/navigationStack/navigationStack";
import { initNotificationFeedService } from "services/notificationFeed";
import { initCastService } from "services/player/controllers/chromeCast/initChromeCastPlayerControllerService";
import { playerControllerService } from "services/player/controllers/initPlayerControllerService";
import { initLocalPlayerControllerService } from "services/player/controllers/local/initLocalPlayerControllerService";
import { initPlayerDebug } from "services/player/helpers/debug";
import { initInputAudioService } from "services/player/inputs";
import { initLiveRadioAudioInputService } from "services/player/inputs/inputs/liveRadio";
import { initPlayQueueAudioInputService } from "services/player/inputs/inputs/playQueue/initPlayQueueAudioInputService";
import { initBrowserAudio } from "services/player/outputs/browserAudio/browserAudioOutputService";
import { initLiveRadioHeartbeatService } from "services/player/relatedServices";
import { initMediaSessionService } from "services/player/relatedServices/initMediaSessionService";
import { initRecentTracksService } from "services/player/relatedServices/initRecentTracksService";
import { initCachedUserPlaylists } from "services/playlist";
import { initPWAService } from "services/pwa/initPWAService";
import { initRemoteConfigService } from "services/remoteConfig";
import { initServiceWorker } from "services/serviceWorker/initServiceWorker";
import { initShortcutService } from "services/shortcuts";
import { initTabActiveService } from "services/tabActive/initTabActiveService";
import { initUserService, initLoginFromPageLoad } from "services/user";
import { initUserDebug } from "services/user/debug";
import { initDelegatedLoginListener } from "components/organisms/login";

export const appInit = async (environment: BuildEnvironment) => {
    await appInit1(environment);
    render(App(), document.body);
    await appInit2();
};

export let isUnloading = false;
window.addEventListener("beforeunload", () => {
    isUnloading = true;
});

function dsnFromBrand(brand: WebAppBrand): string {
    switch (brand) {
        case WebAppBrand.Telmore:
            return "https://44fc53efe92242e28a32ea75055d4e8c@o252631.ingest.sentry.io/4505346557739008";
        case WebAppBrand.YouSee:
            return "https://8849cdbfc28b4a8192ecc0f09ae1edf1@o252631.ingest.sentry.io/4505346556624896";
    }
}
function getTraceTargets(brand: WebAppBrand) {
    switch (brand) {
        case WebAppBrand.Telmore:
            return ["localhost", "musik.telmore.dk"];
        case WebAppBrand.YouSee:
            return ["localhost", "musik.yousee.dk"];
    }
}
const initSentry = (environment: Environment) => {
    const isBuildDevelopment = environment.buildEnvironment === WebAppEnvironment.Development;

    const appVersion = getAppVersionString();
    const release = `${environment.webAppBrand.toLowerCase}@${appVersion}`;
    const dsn = dsnFromBrand(environment.webAppBrand);
    const targets = getTraceTargets(environment.webAppBrand);

    Sentry.init({
        dsn,
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: targets
            }),
            new Sentry.Replay()
        ],
        release,
        environment: environment.buildEnvironment,
        debug: false, // isBuildDevelopment,
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
        // Performance Monitoring
        tracesSampleRate: isBuildDevelopment ? 1.0 : 0.1, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: isBuildDevelopment ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};

const appInit1 = async (environment: BuildEnvironment) => {
    try {
        initEnvironment(environment);
        initSentry(environment.environment);

        const sessionTask = initAppSession();

        initNavigationStack();

        initEventLogService();
        initAnalyticsService();
        initConsoleLoggerService();

        // initFbLoggerService(); disbaled because ghostery extention crashes site when logging to firebase/google analytics
        initMpLoggerService();

        await sessionTask;
        await initStoreAndPersist();
    } catch (e) {
        console.error("web-220927-1237", e);
    }
    try {
        // log.info({
        //     code: "web-210219-1345",
        //     msg: "app init 1",
        //     data: {
        //         environment: JSON.stringify(environment),
        //         browser: JSON.stringify(getBrowserNameAndVersion())
        //     }
        // });

        initKibanaLoggerService();
        initRemoteConfigService();
        initAppVersionService();

        initControlPanelService();

        initGraphqlCacheService();
        initGraphqlClient();

        initFbPerformance();

        await configcmsInit();
        initServiceWorker();
        initPWAService();

        window.addEventListener("beforeunload", () => {
            unloadTokenLock();
        });

        initTabActiveService(); // todo: should be after login?
        initLanguageService();
    } catch (e) {
        log.error({ code: "web-210518-1536", msg: "App init failed", error: e });
        deleteSessionDbState();

        // setTimeout(() => window.location.reload(), 3000);
    }
};

const appInit2 = async () => {
    try {
        // log.info({ code: "web-210219-1345", msg: "app init 2" });
        await initLoginFromPageLoad();
        await initUserService();

        initGraphqlSubscriptions();

        initConcurrencyPolling();
        initConcurrency();
        initRecentTracksService();

        initFavorites();
        initializeCachedFavoritesStatistics();
        initCachedUserPlaylists();

        //initPlayer;
        initMediaSessionService();
        initShortcutService();
        playerControllerService();
        initLocalPlayerControllerService();
        initLiveRadioHeartbeatService();

        //initPlayQueue;
        initPlayerDebug();
        initInputAudioService();
        initPlayQueueAudioInputService();

        initLiveRadioAudioInputService();

        //initBrowserAudio;
        initNotificationFeedService();
        initPlaybackReportService();
        initPlaybackReportSendService();
        initLyricsReportService();
        initBrowserAudio();

        initCastService();

        // initEndlessPlay(); TODO remove if it works...
        startPlaybackReporting();

        // Delegated login
        initDelegatedLoginListener();

        initCacheQuotaService();
        initUserDebug();
    } catch (e) {
        log.error({ code: "web-210518-1537", msg: "App init 2 failed", error: e });
        deleteSessionDbState();

        // setTimeout(() => window.location.reload(), 3000);
    }

    window.addEventListener("beforeunload", () => {
        unloadStoreAndPersist();
        unloadAppSesssion();
    });
};
