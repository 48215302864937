import { castSendMessage } from "../castRequests/session/sendMessage";
import type { CastAuthenticateMessageDataModel } from "../models";
import { SessionMessage } from "../models";
import { store } from "global";
import { getTokens } from "services/appSession/operations/sessionTokens";
import { environment } from "services/environment";
import { log } from "services/logger";
import { LoginState } from "models/app";

export async function authenticate(): Promise<boolean> {
    
    const loggedIn = store.getState().user.state === LoginState.LoggedIn;
    const { accessToken } = await getTokens();

    if (loggedIn && !accessToken) {
        log.error({ code: "web-230106-1355", msg: "user is logged in but has no access token" });
        return false;
    }

    const data: CastAuthenticateMessageDataModel = {
        platform: "web",
        portal: environment.chromecastPortal,
        portalToken: accessToken
    };

    return castSendMessage(SessionMessage.Authenticate, data);
}