export enum InputEmailValidation {
    Empty = "Empty",
    Invalid = "Invalid",
    OK = "OK"
}

export function validateEmail(email: string): InputEmailValidation {
    if (email.length === 0) return InputEmailValidation.Empty;
    if (email.length > 320) return InputEmailValidation.Invalid;
    if (!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) return InputEmailValidation.Invalid;

    return InputEmailValidation.OK;
}

export enum FeedbackTextValidation {
    Empty,
    OK,
    TooLong,
    TooShort
}

export function validateFeedbackText(text: string, min: number, max: number): FeedbackTextValidation {
    if (text.length === 0) return FeedbackTextValidation.Empty;
    if (text.length < min) return FeedbackTextValidation.TooShort;
    if (text.length > max) return FeedbackTextValidation.TooLong;

    return FeedbackTextValidation.OK;
}

export function formatInputText(text: string): string {
    return text.trim();
}
