import type { AlbumPreviewModel } from "./AlbumModel";
import { createAlbumPreviewModel } from "./AlbumModel";
import type { ArtistPreviewModel } from "./ArtistModel";
import { createArtistPreviewModel } from "./ArtistModel";
import { createBannerPreviewModel, type BannerPreviewModel } from "./BannerModel";
import type { ChartPreviewModel } from "./ChartModel";
import { createChartPreviewModel } from "./ChartModel";
import { ResourceDisplayType } from "./Enums";
import type { LayoutItemGroupModel, LayoutItemSectionModel } from "./layoutPage";
import { createLayoutItemSkeletonSectionModel, createLayoutItemGroupModel } from "./layoutPage";
import type { LiveRadioCategoryPreviewModel } from "./LiveRadioCategoryModel";
import { createLiveRadioCategoryPreviewModel } from "./LiveRadioCategoryModel";
import type { LiveRadioPreviewModel, LiveRadioTrackPreviewModel } from "./LiveRadioModel";
import { createLiveRadioPreviewModel } from "./LiveRadioModel";
import { createLiveRadioTrackPreviewModel } from "./LiveRadioTrackModel";
import type { MixedContentPreviewModel } from "./MixedContentModel";
import { createMixedContentPreviewModel } from "./MixedContentModel";
import type { MixRadioLinkParentModel, MixRadioPreviewModel } from "./MixRadioModel";
import { createMixRadioPreviewModel } from "./MixRadioModel";
import type { NotificationPreviewModel } from "./NotificationModel";
import { createNotificationPreviewModel } from "./NotificationModel";
import type { PlaylistCategoryPreviewModel } from "./PlaylistCategoryModel";
import { createPlaylistCategoryPreviewModel } from "./PlaylistCategoryModel";
import type { PlaylistPreviewModel } from "./PlaylistModel";
import { createPlaylistPreviewModel } from "./PlaylistModel";
import type { RecommendationItemPreviewModel, RecommendationOwner, RecommendationPreviewModel } from "./RecommendationModel";
import { createRecommendationPreviewModel } from "./RecommendationModel";
import type { ResourcePreviewModel } from "./ResourceModel";
import type { SearchMixedResultPreviewModel } from "./SearchMixedResultModel";
import { createSearchMixedResultPreviewModel } from "./SearchMixedResultModel";
import { createTrackPlayablePreviewModelFromSearchTrackResult } from "./SearchTrackResultModel";
import type { TrackPreviewModel } from "./TrackModel";
import { createTrackNoAlbumPreviewModel, createTrackPreviewModel } from "./TrackModel";
import type { TrackPlayablePreviewModel } from "./TrackPlayableModel";
import type {
    AlbumPreviewConnectionFragment,
    ArtistPreviewConnectionFragment,
    LayoutPageFragment,
    PlaylistPreviewConnectionFragment,
    RecommendationPreviewConnectionFragment,
    ChartPreviewConnectionFragment,
    MixedSearchResultConnectionFragment,
    PlaylistCategoryPreviewConnectionFragment,
    FavouriteArtistPreviewConnectionFragment,
    LiveRadioPreviewConnectionFragment,
    LiveRadioCategoryPreviewConnectionFragment,
    FavouriteAlbumPreviewConnectionFragment,
    FavoriteTrackPreviewConnectionFragment,
    MixRadioPreviewConnectionFragment,
    TrackPreviewConnectionFragment,
    TrackWithoutAlbumPreviewConnectionFragment,
    MixedContentPreviewConnectionFragment,
    PlaylistTrackPreviewConnectionFragment,
    NotificationPreviewConnectionFragment,
    PageInfoFragment,
    RecommendationContentPaginationQuery,
    BannerPreviewConnectionFragment
} from "generated/graphql-types";
import { DomainModelType } from "models/ModelType";

type ConnectionData = PageInfoFragment;

type AlbumPreviewConnectionData = AlbumPreviewConnectionFragment;

type ArtistPreviewConnectionData = ArtistPreviewConnectionFragment;
type BannerPreviewConnectionData = BannerPreviewConnectionFragment;
type ChartPreviewConnectionData = ChartPreviewConnectionFragment;
type FavoriteAlbumPreviewConnectionData = FavouriteAlbumPreviewConnectionFragment;
type FavoriteArtistPreviewConnectionData = FavouriteArtistPreviewConnectionFragment;
type FavoriteTrackPreviewConnectionData = FavoriteTrackPreviewConnectionFragment;
type LiveRadioCategoryPreviewConnectionData = LiveRadioCategoryPreviewConnectionFragment;
type LiveRadioPreviewConnectionData = LiveRadioPreviewConnectionFragment;
type MixRadioPreviewConnectionData = MixRadioPreviewConnectionFragment;
type NotificationPreviewConnectionData = NotificationPreviewConnectionFragment;
type PlaylistCategoryPreviewConnectionData = PlaylistCategoryPreviewConnectionFragment;
type MixedContentConnectionData = MixedContentPreviewConnectionFragment;
type PlaylistPreviewConnectionData = PlaylistPreviewConnectionFragment;
type SearchMixedResultPreviewConnectionData = MixedSearchResultConnectionFragment;
type SearchTrackResultPreviewConnectionData = TrackPreviewConnectionFragment;

type RecommendationPreviewConnectionData = RecommendationPreviewConnectionFragment;

type TrackPreviewConnectionData = TrackPreviewConnectionFragment;
type LiveRadioTrackPreviewConnectionData = TrackPreviewConnectionFragment;
type TrackNoAlbumPreviewConnectionData = TrackWithoutAlbumPreviewConnectionFragment;
type PlaylistTrackPreviewConnectionData = PlaylistTrackPreviewConnectionFragment;

export type ConnectionModel<T> = { type: DomainModelType } & Connection<T>;
export type ResourcePreviewConnectionModel = Connection<ResourcePreviewModel>;

export type LayoutItemGroupConnectionModel = Connection<LayoutItemGroupModel>;

export type LayoutItemSectionConnectionModel = Connection<LayoutItemSectionModel>;

interface Connection<T> {
    totalCount: number | null;
    items: T[];
    pageInfo: {
        endCursor: string | null;
        hasNextPage: boolean;
        endCursorIndex: number | null;
    };
}

function createConnection<T>(data: ConnectionData, items: T[], totalCount: number | null, first = 50): Connection<T> {
    const connection: Connection<T> = {
        totalCount: totalCount ?? (!data.hasNextPage ? items.length : null),
        items,
        pageInfo: {
            hasNextPage: data.hasNextPage,
            endCursor: data.endCursor ?? null,
            endCursorIndex: items.length
        }
    };
    //TODO: ugly hack until backend gives correct totalCount
    if (first > items.length) {
        connection.totalCount = items.length;
        //connection.pageInfo.hasNextPage = false;
    }
    return connection;
}

function createEmptyConnection<T>(): Connection<T> {
    const connection: Connection<T> = {
        totalCount: 0,
        items: [],
        pageInfo: {
            hasNextPage: false,
            endCursor: null,
            endCursorIndex: null
        }
    };
    return connection;
}
export function createStaticConnection<T>(items: T[], totalCount: number | null): Connection<T> {
    const connection: Connection<T> = {
        totalCount,
        items,
        pageInfo: {
            hasNextPage: false,
            endCursor: null,
            endCursorIndex: null
        }
    };
    return connection;
}

export function createMultiselectTrackPreviewConnectionModel(tracks: TrackPreviewModel[]): ConnectionModel<TrackPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createEmptyConnection(),
        items: tracks // todo: should the tracks be recreated with a new playbackcontext that points to the selection instad of where they came from?
    };
}

export function createMyMusicTrackPreviewConnectionModel(data: FavoriteTrackPreviewConnectionData): ConnectionModel<TrackPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item, i) => createTrackPreviewModel(item, i)),
            data.totalCount ?? null
        )
    };
}

export function createEmptyTrackPreviewConnectionModel(): ConnectionModel<TrackPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createEmptyConnection()
    };
}
//TODO: ugly hack until backend gives correct totalCount
export function createTrackPreviewConnectionModel(data: TrackPreviewConnectionData, index: number, first?: number): ConnectionModel<TrackPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item, i) => createTrackPreviewModel(item, index + i)),
            data.totalCount ?? null,
            first
        )
    };
}

export function createPlaylistTrackPreviewConnectionModel(data: PlaylistTrackPreviewConnectionData): ConnectionModel<TrackPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createTrackPreviewModel(item, item.position)),
            data.totalCount ?? null
        )
    };
}

export function createStaticPreviewConnectionModel<T>(data: T[], totalCount: number | null): ConnectionModel<T> {
    return {
        type: DomainModelType.Preview,
        ...createStaticConnection(data, totalCount)
    };
}
export function createLiveRadioTrackPreviewConnectionModel(data: LiveRadioTrackPreviewConnectionData): ConnectionModel<LiveRadioTrackPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item, index) => createLiveRadioTrackPreviewModel(item, index)),
            data.totalCount ?? null
        )
    };
}

export function createTrackNoAlbumPreviewConnectionModel(
    data: TrackNoAlbumPreviewConnectionData,
    cover: string | null,
    album: { id: string; title: string }
): ConnectionModel<TrackPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item, index) => createTrackNoAlbumPreviewModel(item, cover, album, index)),
            data.totalCount ?? null
        )
    };
}

export function createAlbumPreviewConnectionModel(data: AlbumPreviewConnectionData | FavoriteAlbumPreviewConnectionData): ConnectionModel<AlbumPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createAlbumPreviewModel(item)),
            data.totalCount ?? null
        )
    };
}

export function createPlaylistCategoryPreviewConnectionModel(data: PlaylistCategoryPreviewConnectionData): ConnectionModel<PlaylistCategoryPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createPlaylistCategoryPreviewModel(item)),
            data.totalCount ?? null
        )
    };
}

export function createMixedContentPreviewConnectionModel(data: MixedContentConnectionData): ConnectionModel<MixedContentPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => (item ? createMixedContentPreviewModel(item) : null)).filter((n) => n != null) as MixedContentPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createPlaylistPreviewConnectionModel(data: PlaylistPreviewConnectionData): ConnectionModel<PlaylistPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createPlaylistPreviewModel(item)),
            data.totalCount ?? null
        )
    };
}

export function createArtistPreviewConnectionModel(data: ArtistPreviewConnectionData | FavoriteArtistPreviewConnectionData): ConnectionModel<ArtistPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createArtistPreviewModel(item)),
            data.totalCount ?? null
        )
    };
}

export function createBannerPreviewConnectionModel(data: BannerPreviewConnectionData): ConnectionModel<BannerPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createBannerPreviewModel(item)),
            data.totalCount ?? null
        )
    };
}

export function createChartPreviewConnectionModel(data: ChartPreviewConnectionData): ConnectionModel<ChartPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => (item ? createChartPreviewModel(item) : null)).filter((n) => n != null) as ChartPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createLiveRadioCategoryPreviewConnectionModel(data: LiveRadioCategoryPreviewConnectionData): ConnectionModel<LiveRadioCategoryPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => (item ? createLiveRadioCategoryPreviewModel(item) : null)).filter((n) => n != null) as LiveRadioCategoryPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createLiveRadioPreviewConnectionModel(data: LiveRadioPreviewConnectionData): ConnectionModel<LiveRadioPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => (item ? createLiveRadioPreviewModel(item) : null)).filter((n) => n != null) as LiveRadioPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createMixRadioPreviewConnectionModel(data: MixRadioPreviewConnectionData, parent: MixRadioLinkParentModel): ConnectionModel<MixRadioPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => (item ? createMixRadioPreviewModel(item, parent) : null)).filter((n) => n != null) as MixRadioPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createNotificationPreviewConnectionModel(data: NotificationPreviewConnectionData): ConnectionModel<NotificationPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => (item ? createNotificationPreviewModel(item) : null)).filter((n) => n != null) as NotificationPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createRecommendationPreviewConnectionModel(data: RecommendationPreviewConnectionData, owner: RecommendationOwner): ConnectionModel<RecommendationPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => (item ? createRecommendationPreviewModel(item, owner) : null)).filter((n) => n != null) as RecommendationPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createRecommendationItemPreviewConnectionModel(
    data: NonNullable<RecommendationContentPaginationQuery["me"]["recommendations"]["recommendation"]>,
    index: number
): ConnectionModel<RecommendationItemPreviewModel> {
    switch (data.__typename) {
        case "AlbumsRecommendation":
            return createAlbumPreviewConnectionModel(data.albums);
        case "PlaylistsRecommendation":
            return createPlaylistPreviewConnectionModel(data.playlists);
        case "TracksRecommendation":
            return createTrackPreviewConnectionModel(data.tracks, index);
    }
}

export function createSearchMixedResultPreviewConnectionModel(data: SearchMixedResultPreviewConnectionData): ConnectionModel<SearchMixedResultPreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createSearchMixedResultPreviewModel(item)).filter((n) => n != null) as SearchMixedResultPreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createTrackPlayablePreviewConnectionModelFromSearchTrackResult(data: SearchTrackResultPreviewConnectionData): ConnectionModel<TrackPlayablePreviewModel> {
    return {
        type: DomainModelType.Preview,
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createTrackPlayablePreviewModelFromSearchTrackResult(item)).filter((n) => n != null) as TrackPlayablePreviewModel[],
            data.totalCount ?? null
        )
    };
}

export function createLayoutPageGroupConnectionModel(data: LayoutPageFragment["groups"], pageId: string): LayoutItemGroupConnectionModel {
    return {
        ...createConnection(
            data?.pageInfo,
            (data.items ?? []).map((item) => createLayoutItemGroupModel(item, pageId)),
            data.totalCount ?? null
        )
    };
}

export function createLayoutItemConnectionModel(data: NonNullable<LayoutPageFragment["groups"]["items"]>[number]["items"], pageId: string): LayoutItemSectionConnectionModel {
    return {
        ...createConnection(
            data.pageInfo,
            (data.items ?? []).map((item) => createLayoutItemSkeletonSectionModel(item, pageId)).filter((n) => n != null && n.displayType !== ResourceDisplayType.Unknown),
            data.totalCount ?? null
        )
    };
}
