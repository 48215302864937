import { h } from "preact";
import { useSelector } from "react-redux";
import type { RootModel } from "models/app";

export const VersionSettings = () => {
    const { major, minor, release, build } = useSelector((state: RootModel) => state.app.version);
    return (
        <p className="version-number">
            version: {major}.{minor}.{release}.{build}
        </p>
    );
};
