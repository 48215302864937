import { getPausePosition } from "services/player/helpers";
import { tryGetAudioPosition } from "services/player/inputs/service/helpers";
import type { PlayPausePositionModel } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";

export function getCurrentTimeFromAudio(audio: AudioInputItemModel): number | null {
    const position = tryGetAudioPosition(audio);
    if (!position) return null;

    const currentTime = getCurrentTimeFromPosition(position);
    return currentTime;
}

export function getCurrentTimeFromPosition(position: PlayPausePositionModel): number {
    const pausePos = getPausePosition(position);
    const currentTime = pausePos.pausePosition;

    return currentTime;
}
