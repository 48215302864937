import { h } from "preact";
import { useMemo } from "preact/hooks";
import { Button, ButtonDesign } from "../controls/button";
import { WebAppBrand } from "shared/models";
import { assetPaths } from "global/constants";
import { getNativeMobileAppStore, NativeMobileAppStore } from "services/app";
import { environment } from "services/environment";
import { DefaultLogMessage, log } from "services/logger";

export const DownloadAppButton = () => {
    const store = useMemo(() => getNativeMobileAppStore(), []);
    if (store == null) {
        log.error({ code: "web-220215-1621", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    const brand = environment.webAppBrand;
    const appLink = getAppLink(store, brand);
    const imgUrl = getStoreUrl(store);

    if (appLink == null || imgUrl == null) return null;
    return (
        <Button className={`downloadApp ho5`} externalUrl={appLink} design={ButtonDesign.None}>
            <img src={imgUrl} />
        </Button>
    );
};

export function getStoreUrl(store: NativeMobileAppStore): string | null {
    switch (store) {
        case NativeMobileAppStore.AppleAppStore:
            return assetPaths.appStore;
        case NativeMobileAppStore.GooglePlayStore:
            return assetPaths.googlePlay;
        default:
            return null;
    }
}
export function getAppLink(store: NativeMobileAppStore, brand: WebAppBrand): string {
    switch (store) {
        case NativeMobileAppStore.AppleAppStore:
            switch (brand) {
                case WebAppBrand.Telmore:
                    return "https://apps.apple.com/dk/app/telmore-musik/id636503787";
                case WebAppBrand.YouSee:
                    return "https://apps.apple.com/dk/app/yousee-musik/id1108892163";
            }
            break;
        case NativeMobileAppStore.GooglePlayStore:
            switch (brand) {
                case WebAppBrand.Telmore:
                    return "https://play.google.com/store/apps/details?id=com.e247.telmore&gl=DK";
                case WebAppBrand.YouSee:
                    return "https://play.google.com/store/apps/details?id=dk.yousee.musik&gl=DK";
            }
            break;
    }
}
