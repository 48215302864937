import type { AnalyticsKeys } from "generated/analyticsKeys";
import type { LocationContentType } from "models/domain";
import { ContentType } from "models/ModelType";

type ViewPageType =
    | "Album Artist Albums Show All"
    | "AlbumChart"
    | "AlbumDownloads"
    | "AlbumRecommendation"
    | "Album"
    | "ArtistAlbumsShowAll"
    | "ArtistAppearsOnShowAll"
    | "ArtistPopularTracksShowAll"
    | "ArtistRadio"
    | "ArtistSimilarArtistsShowAll"
    | "ArtistSinglesShowAll"
    | "Artist"
    | "DownloadQueue"
    | "Downloads"
    | "FrontPageItemShowAll"
    | "FrontPage"
    | "History"
    | "LayoutPageItemShowAll"
    | "LiveRadioCategory"
    | "LiveRadioHistory"
    | "MaxiPlayer"
    | "MixRadioCategory"
    | "MyAlbums"
    | "MyArtists"
    | "MyMusic"
    | "MyPlaylists"
    | "MyRecentTracks"
    | "MyTracks"
    | "PlaylistCategories"
    | "PlaylistCategory"
    | "PlaylistDownloads"
    | "PlaylistRecommendation"
    | "Playlist"
    | "Queue"
    | "RadioPageItemShowAll"
    | "Radio"
    | "RecommendationCategory"
    | "Recommendations"
    | "SearchAlbumResultsShowAll"
    | "SearchArtistResultsShowAll"
    | "SearchPlaylistResultsShowAll"
    | "SearchTrackResultsShowAll"
    | "Search"
    | "SettingsAbout"
    | "SettingsAppearance"
    | "SettingsBattery"
    | "SettingsDownload"
    | "SettingsFeedback"
    | "SettingsHelp"
    | "SettingsSoundQuality"
    | "Settings"
    | "ShowAll"
    | "TrackChart"
    | "TrackDownloads"
    | "TrackRadio"
    | "TrackRecommendation";

export type MixpanelViewPageType = Pick<AnalyticsKeys, ViewPageType>;
export const getMixpanelViewPageType = (type: LocationContentType | undefined): keyof MixpanelViewPageType | undefined => {
    if (type == undefined) return undefined;

    switch (type) {
        case ContentType.AlbumArtistAlbums:
            return "Album Artist Albums Show All";
        case ContentType.ArtistAlbums:
            return "ArtistAlbumsShowAll";
        case ContentType.ArtistAppearsOn:
            return "ArtistAppearsOnShowAll";
        case ContentType.ArtistSimilarArtists:
            return "ArtistSimilarArtistsShowAll";
        case ContentType.ArtistSingles:
            return "ArtistSinglesShowAll";
        case ContentType.MyMusicAlbums:
            return "MyAlbums";
        case ContentType.MyMusicArtists:
            return "MyArtists";
        case ContentType.MyMusicPlaylists:
            return "MyPlaylists";
        case ContentType.MyMusicTracks:
            return "MyTracks";
        case ContentType.Recommendation:
            return "Recommendations";
        case ContentType.SearchAlbumResults:
            return "SearchAlbumResultsShowAll";
        case ContentType.SearchArtistResults:
            return "SearchArtistResultsShowAll";
        case ContentType.SearchPlaylistResults:
            return "SearchPlaylistResultsShowAll";
        case ContentType.SearchTrackResults:
            return "SearchTrackResultsShowAll";
        case ContentType.UserHistory:
            return "History";
        case ContentType.UserRecommendations:
            return "RecommendationCategory";
        case ContentType.FrontPageShowAll:
            return "FrontPageItemShowAll";
        case ContentType.ThemePageShowAll:
            return "LayoutPageItemShowAll"; //todo is this correct?
        case ContentType.RadioPageShowAll:
            return "RadioPageItemShowAll";
        case ContentType.SettingsPage:
            return "Settings";
        case ContentType.Album:
        case ContentType.AlbumChart:
        case ContentType.AlbumRecommendation:
        case ContentType.Artist:
        case ContentType.ArtistRadio:
        case ContentType.FrontPage:
        case ContentType.LiveRadioCategory:
        case ContentType.MixRadioCategory:
        case ContentType.Playlist:
        case ContentType.PlaylistCategories:
        case ContentType.PlaylistCategory:
        case ContentType.PlaylistRecommendation:
        case ContentType.Queue:
        case ContentType.Radio:
        case ContentType.Search:
        case ContentType.TrackChart:
        case ContentType.TrackRadio:
        case ContentType.TrackRecommendation:
            return type;
        // case ContentType : return MixpanelViewPageType.ArtistPopularTracksShowAll
    }
};
