import { useState, useLayoutEffect } from "preact/hooks";
import { useLocalization } from "components/app/hooks";
import { CONTEXT_MENU_REPOSITION } from "global/actions";
import { useMessageBus } from "global/hooks";
import type { ContextMenuPosition } from "models/view/contextMenu";
import { ContextMenuAlign, ContextMenuPositionType } from "models/view/contextMenu";
import { useAppResize } from "components/shared/hooks";

export class ContextMenuPositionBox {
    top = 0;
    right = 0;
    bottom = 0;
    left = 0;
}

interface ContextMenuPlacement {
    fitWidth: boolean;
    fitHeight: boolean;
    top: number;
    left?: number;
    right?: number;
    width: number;
    height: number;
}

enum ContextMenuSide {
    Top,
    Right,
    Bottom,
    Left
}

interface Props {
    sheetPos: ContextMenuPosition | null;
    parentSheetPos: ContextMenuPosition | null;
    align: ContextMenuAlign;
    gap: boolean;
}

function getPlacement(viewMargin: number, box1: ContextMenuPositionBox, box2: ContextMenuPositionBox, side: ContextMenuSide, gap: boolean): ContextMenuPlacement {
    const menuGap = gap ? 1 : 0;
    const menuTopPadding = 16;

    const vw = window.innerWidth;
    const vh = window.innerHeight;

    let availableHeight;

    switch (side) {
        case ContextMenuSide.Top:
            availableHeight = box1.top - viewMargin;
            break;
        case ContextMenuSide.Bottom:
            availableHeight = vh - box1.bottom - viewMargin;
            break;
        case ContextMenuSide.Right:
        case ContextMenuSide.Left:
            availableHeight = vh - 2 * viewMargin;
            break;
    }

    let availableWidth: number;

    switch (side) {
        case ContextMenuSide.Top:
        case ContextMenuSide.Bottom:
            availableWidth = vw - 2 * viewMargin;
            break;
        case ContextMenuSide.Right:
            availableWidth = vw - box1.right - viewMargin;
            break;
        case ContextMenuSide.Left:
            availableWidth = box1.left - viewMargin;
            break;
    }

    let width = box2.right - box2.left;
    let height = box2.bottom - box2.top;

    const fitWidth = width <= availableWidth;
    const fitHeight = height <= availableHeight;

    if (!fitWidth) width = availableWidth;
    if (!fitHeight) height = availableHeight;

    let top: number;

    switch (side) {
        case ContextMenuSide.Top:
            top = box1.top - height - menuGap;
            break;
        case ContextMenuSide.Bottom:
            top = box1.bottom + menuGap;
            break;
        case ContextMenuSide.Right:
        case ContextMenuSide.Left:
            top = box1.top - menuTopPadding;
            if (height + top > availableHeight + viewMargin) top = availableHeight + viewMargin - height;
            break;
    }

    let left: number;

    switch (side) {
        case ContextMenuSide.Top:
        case ContextMenuSide.Bottom:
            left = box1.left;
            if (width + left > availableWidth + viewMargin) left = availableWidth + viewMargin - width;
            break;
        case ContextMenuSide.Right:
            left = box1.right + menuGap;
            break;
        case ContextMenuSide.Left:
            left = box1.left - width - menuGap;
            break;
    }

    const placement: ContextMenuPlacement = {
        fitWidth,
        fitHeight,
        top,
        left,
        width,
        height
    };

    return placement;
}

const getBestPlacement = (
    viewMargin: number,
    sheetPos: ContextMenuPosition | null,
    parentSheetPos: ContextMenuPosition | null,
    align: ContextMenuAlign,
    gap: boolean
): ContextMenuPlacement | null => {
    if (!sheetPos) return null;
    if (!parentSheetPos) return null;

    const box1 = getPosBox(parentSheetPos);
    const box2 = getPosBox(sheetPos);

    switch (align) {
        case ContextMenuAlign.Right: {
            const p1 = getPlacement(viewMargin, box1, box2, ContextMenuSide.Right, gap);
            if (p1.fitWidth) return p1;

            const p2 = getPlacement(viewMargin, box1, box2, ContextMenuSide.Left, gap);
            if (p2.fitWidth) return p2;

            return p1.width >= p2.width ? p1 : p2;
        }
        case ContextMenuAlign.Below: {
            const p1 = getPlacement(viewMargin, box1, box2, ContextMenuSide.Bottom, gap);
            if (p1.fitHeight) return p1;

            const p2 = getPlacement(viewMargin, box1, box2, ContextMenuSide.Top, gap);
            if (p2.fitHeight) return p2;

            return p1.height >= p2.height ? p1 : p2;
        }
        case ContextMenuAlign.UpperRight: {
            const p2 = getPlacement(viewMargin, box1, box2, ContextMenuSide.Left, gap);
            p2.top = 8;
            return p2;
        }
    }
};

export const useContextMenuPlacement = ({ sheetPos, parentSheetPos, align, gap }: Props) => {
    const [placement, setPlacement] = useState<ContextMenuPlacement | null>(null);
    const local = useLocalization();
    const size = useAppResize();
    const margin = 8;

    useMessageBus(CONTEXT_MENU_REPOSITION, () => {
        setPlacement(() => getBestPlacement(margin, sheetPos, parentSheetPos, align, gap));
    });

    useLayoutEffect(() => {
        setPlacement(() => getBestPlacement(margin, sheetPos, parentSheetPos, align, gap));
    }, [margin, sheetPos, parentSheetPos, align, gap, size]);

    useLayoutEffect(() => {
        setTimeout(() => {
            setPlacement(() => getBestPlacement(margin, sheetPos, parentSheetPos, align, gap));
        });
    }, [margin, sheetPos, parentSheetPos, align, gap, size, local]);

    return placement;
};

function getPosBox(position: ContextMenuPosition): ContextMenuPositionBox {
    const pos = new ContextMenuPositionBox();

    switch (position.type) {
        case ContextMenuPositionType.ScreenCorner: {
            const { x, y } = position.screenPosition;
            pos.top = y;
            pos.bottom = y;
            pos.right = window.innerWidth - x;
            pos.left = window.innerWidth - x;
            return pos;
        }
        case ContextMenuPositionType.ScreenPosition: {
            const { x, y } = position.screenPosition;
            pos.top = y;
            pos.bottom = y;
            pos.right = x;
            pos.left = x;
            return pos;
        }
        case ContextMenuPositionType.Anchor: {
            const { top, right, bottom, left } = position.element.getBoundingClientRect();
            pos.top = top;
            pos.right = right;
            pos.bottom = bottom;
            pos.left = left;
            return pos;
        }
    }
}
