/* eslint-disable @typescript-eslint/no-unused-vars */
import { h } from "preact";
import { useContext } from "preact/hooks";
import { ContextMenuManagerContext, ContextMenuMode, Sheet } from "..";
import { ShareUrlLink } from "../links/ShareUrlLink";
import { ResourceMenuTop } from "./tops";
import type { PreviewContextModel } from "models/app/resourceContextModel";

interface Props {
    context: PreviewContextModel;
}

export const ShareContextMenu = ({ context }: Props) => {
    return (
        <Sheet top={<ResourceMenuTop context={context} />}>
            <ShareUrlLink context={context} />
        </Sheet>
    );
};
