import type { Action } from "../actions";
import { USER_CHANGED, USER_HAS_BAD_TOKENS, USER_PROFILES_CHANGED } from "../actions";
import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_UPDATED_TOKENS, USER_LOGGING_OUT, USER_ADD_SEARCH_HISTORY, USER_CLEAR_SEARCH_HISTORY, REHYDRATE } from "global/actions";
import { pageContent } from "global/constants/pageContent";
import { isResourceEqual } from "services/resource";
import { LoginState } from "models/app/LoginState";
import type { UserModel } from "models/app/UserModel";

const initialState: UserModel = {
    state: LoginState.Unknown,
    id: null,
    username: null,
    trackingId: null,
    hasBadToken: false,
    recentSearches: [],
    profiles: [],
    profileDefaultColors: null,
    profilesNumberLimit: 0
};

export function userReducer(state: UserModel = initialState, action: Action): UserModel {
    switch (action.type) {
        case USER_ADD_SEARCH_HISTORY: {
            const newItem = action.payload.item;
            let recentSearches = state.recentSearches.filter((item) => !isResourceEqual(item, newItem));
            recentSearches.unshift(newItem);

            const max = pageContent.searchHistoryItemLength;
            if (recentSearches.length > max) recentSearches = recentSearches.slice(0, max);
            return {
                ...state,
                recentSearches
            };
        }
        case USER_CHANGED:
        case USER_CLEAR_SEARCH_HISTORY: {
            return {
                ...state,
                recentSearches: []
            };
        }
        case USER_LOGGING_OUT:
            return {
                ...state,
                state: LoginState.LoggingOut
            };
        case USER_LOGGED_OUT:
            return {
                ...initialState,
                state: LoginState.LoggedOut
            };
        case USER_LOGGED_IN: {
            return {
                ...state,
                id: action.payload.id,
                username: action.payload.username,
                trackingId: action.payload.trackingId,
                profiles: action.payload.profiles,
                profileDefaultColors: action.payload.profileDefaultColors,
                profilesNumberLimit: action.payload.profilesNumberLimit,
                state: LoginState.LoggedIn,
                recentSearches: action.payload.isNewUser ? [] : state.recentSearches
            };
        }
        case USER_PROFILES_CHANGED: {
            return {
                ...state,
                profiles: action.payload.profiles,
                profileDefaultColors: action.payload.profileDefaultColors,
                profilesNumberLimit: action.payload.profilesNumberLimit,
            };
        }
        case USER_HAS_BAD_TOKENS:
            return {
                ...state,
                hasBadToken: true,
                state: LoginState.TemporaryError
            };
        case USER_UPDATED_TOKENS:
            return {
                ...state,
                hasBadToken: false
            };
        case REHYDRATE: {
            const user = action.payload?.user ?? null;
            if (!user) return initialState;
            return {
                ...user,
                username: user.username ?? null,
                profiles: user.profiles ?? [],
                profileDefaultColors: user.profileDefaultColors ?? null,
                profilesNumberLimit: user.profilesNumberLimit ?? null,
                state: LoginState.Unknown,
                hasBadToken: false
            };
        }
        default:
            return state;
    }
}
