// TODO merge these two throtle functions

export function throttle() {
    let registrations: number[] = [];
    let lookBackMs = 0;

    const shouldStop = (max: number, timeMs: number) => {
        const now = new Date().getTime();
        const count = registrations.filter((value) => value > now - timeMs).length;
        return count >= max;
    };

    const clean = () => {
        const now = new Date().getTime();
        registrations = registrations.filter((value) => value > now - lookBackMs);
    };

    const stop = (max: number, timeMs: number): boolean => {
        if (timeMs > lookBackMs) lookBackMs = timeMs;
        clean();
        return shouldStop(max, timeMs);
    };

    const register = () => {
        registrations.unshift(new Date().getTime());
    };

    return { stop, register };
}

export function throttle2() {
    let timer: number | null;
    let lastTime: Date = new Date(0);

    const clearTimer = () => {
        if (timer != null) {
            clearTimeout(timer);
            timer = null;
        }
    };

    const execute = () => {
        lastTime = new Date();
        clearTimer();
    };

    const func = (ms: number): Promise<void> =>
        new Promise((resolve) => {
            clearTimer();
            const next = lastTime.getTime() - new Date().getTime() + ms;

            if (next <= 0) {
                execute();
                resolve();
            } else {
                timer = window.setTimeout(() => {
                    execute();
                    resolve();
                }, ms);
            }
        });

    return func;
}
