import { syncVolumeFromRemote } from "../../actions/syncSettings";
import { castSetVolumeTimeStamp } from "../../castRequests/playerController";
import { log, LogTag } from "services/logger";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";

export function onVolumeLevelChanged({ value: volume }: cast.framework.RemotePlayerChangedEvent<number>) {
    if (!isControllerChromecastFromState()) return;

    log.debug([LogTag.Chromecast], () => ({ code: "web-211029-1312", msg: `onVolumeLevelChanged: ${volume}` }));

    if (new Date().getTime() - castSetVolumeTimeStamp < 1000) return;

    syncVolumeFromRemote();
}
