import { Store, Action, MiddlewareAPI } from "redux";
import { Dispatch, store } from "global";
import { RootModel } from "models/app";
const equal = require("fast-deep-equal");

const fallback = (cb: IdleRequestCallback) => setTimeout(cb, 0);
const requestIdleCallbackFn = typeof requestIdleCallback === "undefined" ? fallback : requestIdleCallback;

let firstLoad = true;
let timerId: number | null = null;
let dbCompareState: Record<string, unknown> | null = null;

export async function saveCurrentState(cacheFn: (entries: [IDBValidKey, unknown][]) => Promise<void | null>, blacklist: string[], initiator: string) {
    if (dbCompareState == null) return;
    const currentState = convertToObjectFromState();
    await saveStateChanges(currentState, blacklist, cacheFn, initiator);
}

export function persistMiddleware<S extends Store<RootModel>, A extends Action>(cacheFn: (entries: [IDBValidKey, unknown][]) => Promise<void | null>, blacklist: string[]) {
    return (store: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => (action: A) => {
        if (dbCompareState == null) {
            dbCompareState = removeBlacklistedReducers(convertToObject(store.getState()), blacklist);
        }

        const res = next(action);

        requestIdleCallbackFn(
            () => {
                if (timerId != null) window.clearTimeout(timerId);
                timerId = window.setTimeout(() => {
                    timerId = null;

                    if (dbCompareState == null) return;
                    const newState = convertToObject(store.getState());

                    saveStateChanges(newState, blacklist, cacheFn, action.type);
                }, 100);
            },
            { timeout: 0 }
        );

        return res;
    };
}

async function saveStateChanges(
    currentState: Record<string, unknown>,
    blacklist: string[],
    cacheFn: (entries: [IDBValidKey, unknown][]) => Promise<void | null>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initiator: string
) {
    if (dbCompareState == null) return;
    const compareState = dbCompareState;
    dbCompareState = null;
    const newState = removeBlacklistedReducers(currentState, blacklist);
    const reducersToUpdate = firstLoad ? newState : getReducersWithChanges(newState, compareState);
    firstLoad = false;

    const reducersToSave = convertToDbKeyValues(reducersToUpdate);
    if (reducersToSave.length == 0) return;

    await cacheFn(reducersToSave);

    // log.info({code: "web-261121-1728", `saved reducers in idb`, [
    //     { key: "initiated by", value: initiator },
    //     { key: "reducers updated", value: reducersToSave }
    // ]);
}

function convertToObjectFromState(): Record<string, unknown> {
    const state: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(store.getState())) {
        state[key] = value;
    }
    return state;
}
function convertToObject<S extends Store<RootModel>>(state: S): Record<string, unknown> {
    const reducers: Record<string, unknown> = {};

    for (const reducerKey in state) {
        reducers[reducerKey] = state[reducerKey];
    }
    return reducers;
}

function convertToDbKeyValues(state: Record<string, unknown>): [IDBValidKey, unknown][] {
    const reducers: [IDBValidKey, unknown][] = [];
    for (const reducerKey in state) {
        const item: [IDBValidKey, unknown] = [reducerKey as IDBValidKey, state[reducerKey]];

        reducers.push(item);
    }
    return reducers;
}

function removeBlacklistedReducers(state: Record<string, unknown>, blacklist: string[]): Record<string, unknown> {
    const reducers: Record<string, unknown> = {};

    for (const reducerKey in state) {
        const isBlacklisted = blacklist.indexOf(reducerKey) !== -1;

        if (!isBlacklisted) {
            reducers[reducerKey] = state[reducerKey];
        }
    }

    return reducers;
}

function getReducersWithChanges(state: Record<string, unknown>, compareState: Record<string, unknown>): Record<string, unknown> {
    const reducers: Record<string, unknown> = {};
    for (const reducerKey in state) {
        const isReducerEqual = equal(compareState[reducerKey], state[reducerKey]);
        if (!isReducerEqual) {
            reducers[reducerKey] = state[reducerKey];
        }
    }
    return reducers;
}
