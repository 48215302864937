export enum IconName {
    AddToPlaylist = "add-to-playlist",
    Airplay = "airplay",
    Albums = "albums",
    Appearance = "appearance",
    ArrowDown = "arrow-down",
    ArrowDownAndroid = "arrow-down-android",
    ArrowDownSmall = "arrow-down-small",
    ArrowLeft = "arrow-left",
    ArrowLeft16 = "arrow-left_16",
    ArrowLeftAndroid = "arrow-left-android",
    ArrowRight = "arrow-right",
    ArrowRight16 = "arrow-right_16",
    ArrowRightAndroid = "arrow-right-android",
    ArrowRightSmall = "arrow-right-small",
    ArrowUp = "arrow-up",
    ArrowUpAndroid = "arrow-up-android",
    ArrowUpSmall = "arrow-up-small",
    Artist = "artist",
    ArtistRadio = "artist-radio",
    Bluetooth = "bluetooth",
    Calendar = "calendar",
    Cast = "cast",
    Checkmark = "checkmark",
    Checkmark16 = "checkmark_16",
    Checkmark40 = "checkmark_40",
    Chromecast = "chromecast",
    Close = "close",
    Delete = "delete",
    Delete16 = "delete_16",
    DotNotification = "dot-notification",
    Download = "download",
    EditPlaylist = "edit-playlist",
    EditProfile40 = "edit-profile_40",
    Error = "error",
    Error40 = "error_40",
    Favorites = "favorites",
    FavoritesActive = "favorites-active",
    Feedback = "feedback",
    FollowArtist = "follow-artist",
    GuidedHelp = "guided-help",
    HidePass = "hidepass",
    Home = "home",
    Kebab = "kebab",
    KebabRight = "kebab-right",
    Language = "language",
    Location = "location",
    Meatballs = "meatballs",
    Meatballs16 = "meatballs_16",
    MyMusic = "my-music",
    Next = "next",
    None = "none", // doesn't exist, use to take up space
    Note = "note",
    Notification = "notification",
    Pause = "pause",
    Pause16 = "pause_16",
    Pause40 = "pause_40",
    PauseDownload = "pause-download",
    Phone = "phone",
    Play = "play",
    Play16 = "play_16",
    Play40 = "play_40",
    PlayAsLast = "play-as-last",
    PlayAsNext = "play-as-next",
    PlaySmall = "play-small",
    Playback = "playback",
    Playlist = "playlist",
    Plus16 = "plus_16",
    Plus40 = "plus_40",
    Previous = "previous",
    Private = "private",
    Profile = "profile",
    Profile2 = "profile2",
    Public = "public",
    Queue = "queue",
    Radio = "radio",
    RenamePlaylist = "rename-playlist",
    Repeat = "repeat",
    RepeatActive = "repeat-active",
    RepeatTrack = "repeat-track",
    Search = "search",
    Search16 = "search_16",
    Settings = "settings",
    ShareAndroid = "share-android",
    ShareFacebook = "share-facebook",
    ShareIos = "share-ios",
    ShareMessenger = "share-messenger",
    ShareTwitter = "share-twitter",
    ShareUrl = "share-url",
    ShowPass = "showpass",
    Shuffle = "shuffle",
    ShuffleActive = "shuffle-active",
    SignIn = "sign-in",
    SignOut = "sign-out",
    Songs = "songs",
    Sort = "sort",
    Sound = "sound",
    Star = "star",
    Stop = "stop",
    Stop16 = "stop_16",
    Stop40 = "stop_40",
    Trash = "trash",
    VolumeDown = "volume-down",
    VolumeMute = "volume-mute",
    VolumeUp = "volume-up",
    Lyrics = "lyrics"
}
