import type { AnalyticsData } from "../analyticsData";
import { combineAnalyticsData } from "../analyticsData";
import { convertAnalyticsAppProperties, getAnalyticsAppProperties } from "../properties/app/appProperties";
import { convertPlayableProperties, getAnalyticsPlayableProperties } from "../properties/event";
import { convertAnalyticsSearchContentProperties, getAnalyticsSearchContentProperties } from "../properties/event/searchContentProperties";
import { getAnalyticsUserProperties } from "../properties/user";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { SearchResultAreaSource } from "components/molecules/searchResultArea";

export async function getAnalyticsSearchContentEvent(criterion: string, source: SearchResultAreaSource, preview: PreviewContextModel): Promise<AnalyticsData> {
    const data = await combineAnalyticsData(
        convertAnalyticsAppProperties(getAnalyticsAppProperties()),
        getAnalyticsUserProperties(),
        convertAnalyticsSearchContentProperties(getAnalyticsSearchContentProperties(criterion, source, preview)),
        convertPlayableProperties(getAnalyticsPlayableProperties(preview.resource as any))
    );
    return data;
}
