import { store } from "global";
import { PlayState, AudioInputType } from "models/app/player";
import { ShuffleState, RepeatStatus } from "models/view";
import { IconName } from "components/atoms/icon";

export const getShuffleIcon = () => {
    const shuffle = store.getState().player.shuffle;
    switch (shuffle) {
        case ShuffleState.Heavy:
        case ShuffleState.Light:
            return IconName.ShuffleActive;
        case ShuffleState.Off:
            return IconName.Shuffle;
    }
};

const getLiveRadioPlayIcon = (playState: PlayState) => {
    switch (playState) {
        case PlayState.Playing:
        case PlayState.Buffering:
            return IconName.Stop40;
        case PlayState.Error:
        case PlayState.Stopped:
        case PlayState.Paused:
            return IconName.Play40;
    }
};

const getPlayQueuePlayIcon = (playState: PlayState) => {
    switch (playState) {
        case PlayState.Playing:
        case PlayState.Buffering:
            return IconName.Pause40;
        case PlayState.Error:
        case PlayState.Stopped:
        case PlayState.Paused:
            return IconName.Play40;
    }
};

export const getPlayIcon = (playState: PlayState, input: AudioInputType) => {
    if (input === AudioInputType.LiveRadio) return getLiveRadioPlayIcon(playState);
    return getPlayQueuePlayIcon(playState);
};

const getLiveRadioPlayIconMobileMini = (playState: PlayState) => {
    switch (playState) {
        case PlayState.Playing:
        case PlayState.Buffering:
            return IconName.Stop;
        case PlayState.Error:
        case PlayState.Stopped:
        case PlayState.Paused:
            return IconName.Play;
    }
};

const getPlayQueuePlayIconMobileMini = (playState: PlayState) => {
    switch (playState) {
        case PlayState.Playing:
        case PlayState.Buffering:
            return IconName.Pause;
        case PlayState.Error:
        case PlayState.Stopped:
        case PlayState.Paused:
            return IconName.Play;
    }
};

export const getPlayIconMobileMini = (playState: PlayState, input: AudioInputType) => {
    if (input === AudioInputType.LiveRadio) return getLiveRadioPlayIconMobileMini(playState);
    return getPlayQueuePlayIconMobileMini(playState);
};

export const getVolumeIcon = (volume: number, muted: boolean) => {
    if (muted || volume === 0) return IconName.VolumeMute;
    else if (volume < 0.4) return IconName.VolumeDown;
    else return IconName.VolumeUp;
};

export const getRepeatIcon = (repeat: RepeatStatus) => {
    switch (repeat) {
        case RepeatStatus.One:
            return IconName.RepeatTrack;
        case RepeatStatus.All:
            return IconName.RepeatActive;
        case RepeatStatus.None:
            return IconName.Repeat;
    }
};
