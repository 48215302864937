import { h } from "preact";
import { LiveRadioContextMenu, MixRadioContextMenu } from ".";
import { AlbumContextMenu } from "./AlbumContextMenu";
import { ArtistContextMenu } from "./ArtistContextMenu";
import { MixedContentContextMenu } from "./MixedContentContextMenu";
import { NotificationPlayableContextMenu } from "./NotificationPlayableContextMenu";
import { PlaylistContextMenu } from "./PlaylistContextMenu";
import { TrackChartContextMenu } from "./TrackChartContextMenu";
import { TrackContextMenu } from "./TrackContextMenu";
import { TrackRecommendationContextMenu } from "./TrackRecommendationContextMenu";
import { TracksContextMenu } from "./TracksContextMenu";
import { DefaultLogMessage, log } from "services/logger";
import type { Multiselect } from "services/selection";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";

interface Props {
    resource: ResourcePreviewModel | null;
    context: PreviewContextModel;
    resourceType: ContentType;
    multiselect?: Multiselect;
}

export const ResourceContextMenu = ({ context, resource, resourceType, multiselect }: Props) => {
    if (resource != null && resource.contentType !== resourceType) {
        log.error({
            code: "web-220331-1056",
            msg: DefaultLogMessage.UnexpectedNull,
            data: {
                "received resourceType": resource.contentType,
                "intended resourceType": resourceType
            }
        });
        return null;
    }

    if (multiselect && multiselect.state.selectedItems.length > 1) {
        switch (resourceType) {
            case ContentType.TrackPlayable:
                return <TracksContextMenu multiselect={multiselect} context={context} />;
        }
    }

    switch (resourceType) {
        case ContentType.Album: {
            if (resource != null && resource.contentType !== ContentType.Album) throw Error();
            return <AlbumContextMenu album={resource} context={context} />;
        }
        case ContentType.Artist: {
            if (resource != null && resource.contentType !== ContentType.Artist) throw Error();
            return <ArtistContextMenu artist={resource} context={context} />;
        }
        case ContentType.LiveRadioPlayable: {
            if (resource != null && resource.contentType !== ContentType.LiveRadioPlayable) throw Error();
            return <LiveRadioContextMenu liveRadio={resource} context={context} />;
        }
        case ContentType.MixRadio: {
            if (resource != null && resource.contentType !== ContentType.MixRadio) throw Error();
            return <MixRadioContextMenu mixRadio={resource} context={context} />;
        }

        case ContentType.Playlist: {
            if (resource != null && resource.contentType !== ContentType.Playlist) throw Error();
            return <PlaylistContextMenu playlist={resource} preview={context} assumedFavorite={resource?.owner} />;
        }
        case ContentType.MixedContent: {
            if (resource != null && resource.contentType !== ContentType.MixedContent) throw Error();
            if (!resource) return null;
            return <MixedContentContextMenu mixedContent={resource} context={context} />;
        }
        case ContentType.TrackChart: {
            if (resource != null && resource.contentType !== ContentType.TrackChart) throw Error();
            return <TrackChartContextMenu trackChart={resource} context={context} />;
        }
        case ContentType.TrackPlayable: {
            if (resource != null && resource.contentType !== ContentType.TrackPlayable) throw Error();
            return <TrackContextMenu track={resource} context={context} />;
        }
        case ContentType.TrackRecommendation: {
            if (resource != null && resource.contentType !== ContentType.TrackRecommendation) throw Error();
            return <TrackRecommendationContextMenu trackRecommendation={resource} context={context} />;
        }
        case ContentType.LiveRadio: {
            if (resource != null && resource.contentType !== ContentType.LiveRadio) throw Error();

            if (context.section.type === ContentType.Queue || context.section.type === ContentType.Player) {
                const track = resource?.tracks?.nowPlaying ?? null;
                if (track) return <TrackContextMenu track={track} context={context} />;
            }
            return <LiveRadioContextMenu liveRadio={resource} context={context} />;
        }
        case ContentType.LiveRadioTrack: {
            if (resource != null && resource.contentType !== ContentType.LiveRadioTrack) throw Error();
            return <TrackContextMenu track={resource} context={context} />;
        }
        case ContentType.Notification: {
            if (resource != null && resource.contentType !== ContentType.Notification) throw Error();
            return <NotificationPlayableContextMenu context={context} notification={resource} />;
        }
        default:
            log.error({
                code: "web-210616-1700",
                msg: DefaultLogMessage.NotImplemented,
                data: {
                    "received resourceType": resource?.contentType,
                    "intended resourceType": resourceType,
                    resource
                }
            });
            return null;
    }
};
