import { createAudioElement, audioMediaChange, MediaChangeEvent, disposeAudioElement } from "../element";
import { audioElementError } from "../error";
import { updatePreloadState } from "../flow";
import { getCacheId } from "../hls";
import { dispatch } from "global";
import { AUDIO_OUTPUT_AUDIO_ENDED, AUDIO_OUTPUT_AUDIO_DURATION_CHANGE } from "global/actions";
import { preloadOrderConfig } from "global/constants";
import { AudioInputType, PlayState } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";
import { StreamUrlType } from "models/app/player/input";
import type { BrowserAudioItemModel, BrowserAudioItemPreload } from "models/app/player/output";
import { BrowserAudioItemPreloadSize, BrowserAudioLoadState } from "models/app/player/output";

export function disposeBrowserAudio(browserAudio: BrowserAudioItemModel) {
    browserAudio.disposed = true;
    browserAudio.loadNow = false;
    browserAudio.url = null;

    browserAudio.removeListeners();
    disposeAudioElement(browserAudio.element);

    if (browserAudio.hls) {
        browserAudio.hls.destroy();
        browserAudio.hls = null;
    }
}

export function updateBrowserAudio(audio: AudioInputItemModel, browserAudio: BrowserAudioItemModel) {
    const index = audio.index;
    const { order, size } = getPreloadConfigFromIndex(index);

    browserAudio.preload.index = index;
    browserAudio.preload.order = order;
    browserAudio.preload.size = size;

    return browserAudio;
}

export const createBrowserAudio = (audio: AudioInputItemModel) => {
    const element = createAudioElement();

    const browserAudio: BrowserAudioItemModel = {
        audioCacheId: getCacheId(audio),
        audioId: audio.audioId,
        playState: PlayState.Paused,
        canPlayThrough: false,
        disposed: false,
        element,
        hls: null,
        hlsRetryLock: false,
        input: audio.input,
        intendedPlayState: PlayState.Paused,
        isMediaSet: false,
        loadNow: false,
        logTitle: audio.logTitle,
        playTrace: null,
        position: { pausePosition: 0 },
        mediaChangeLock: false,
        preload: getPreload(audio.index),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        removeListeners: null!,
        state: BrowserAudioLoadState.None,
        url: audio.input === AudioInputType.LiveRadio ? audio.url2 : null,
        urlLock: false
    };

    browserAudio.removeListeners = addEventListeners(audio, browserAudio).removeListeners;

    return browserAudio;
};

function addEventListeners(audio: AudioInputItemModel, browserAudio: BrowserAudioItemModel): { removeListeners: () => void } {
    const element = browserAudio.element;

    const canPlayThroughFn = () => {
        browserAudio.canPlayThrough = true;
    };

    const durationChangeFn = () => {
        const duration = isNaN(element.duration) ? null : element.duration;
        if (duration == null) return;
        if (duration <= 0) return;

        dispatch({ type: AUDIO_OUTPUT_AUDIO_DURATION_CHANGE, payload: { audioId: audio.audioId, duration } });
    };

    const endedFn = () => dispatch({ type: AUDIO_OUTPUT_AUDIO_ENDED, payload: { audioId: audio.audioId, context: { action: AudioContextAction.BrowserAudioEnded, trace: null } } });

    const errorFn = (e: ErrorEvent) => {
        audioElementError(browserAudio, e, e.message);
    };

    const pauseFn = () => audioMediaChange(browserAudio, MediaChangeEvent.Pause);
    const playingFn = () => audioMediaChange(browserAudio, MediaChangeEvent.Playing);

    const progressFn = () => {
        browserAudio.url?.urlType === StreamUrlType.Mp3 && updatePreloadState(browserAudio);
    };

    const seekedFn = () => audioMediaChange(browserAudio, MediaChangeEvent.Seeked);
    const seekingFn = () => audioMediaChange(browserAudio, MediaChangeEvent.Seeking);
    const waitingFn = () => audioMediaChange(browserAudio, MediaChangeEvent.Waiting);

    element.addEventListener("canplaythrough", canPlayThroughFn);
    element.addEventListener("durationchange", durationChangeFn);
    element.addEventListener("ended", endedFn);
    element.addEventListener("error", errorFn);
    element.addEventListener("pause", pauseFn);
    element.addEventListener("playing", playingFn);
    element.addEventListener("progress", progressFn);
    element.addEventListener("seeked", seekedFn);
    element.addEventListener("seeking", seekingFn);
    element.addEventListener("waiting", waitingFn);

    const removeListeners = () => {
        element.removeEventListener("canplaythrough", canPlayThroughFn);
        element.removeEventListener("durationchange", durationChangeFn);
        element.removeEventListener("ended", endedFn);
        element.removeEventListener("error", errorFn);
        element.removeEventListener("pause", pauseFn);
        element.removeEventListener("playing", playingFn);
        element.removeEventListener("progress", progressFn);
        element.removeEventListener("seeked", seekedFn);
        element.removeEventListener("seeking", seekingFn);
        element.removeEventListener("waiting", waitingFn);
    };
    return { removeListeners };
}

function getPreload(index: number): BrowserAudioItemPreload {
    const { order, size } = getPreloadConfigFromIndex(index);
    const preload: BrowserAudioItemPreload = {
        index,
        order,
        size,
        hlsPreload: null
    };

    return preload;
}

function getPreloadConfigFromIndex(index: number): { order: number; size: BrowserAudioItemPreloadSize } {
    let itemPreloadSetting = preloadOrderConfig.find((value) => value.index === index) ?? { order: Number.MAX_SAFE_INTEGER, size: BrowserAudioItemPreloadSize.Small };
    itemPreloadSetting = { ...itemPreloadSetting };

    return itemPreloadSetting;
}
