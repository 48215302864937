import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import { Link } from "../Link";
import { dispatch } from "global";
import { PLAYER_TOGGLE_FAVORITE } from "global/actions";
import { useTranslations } from "global/config";
import type { FavoritePreviewModel } from "services/favorites";
import { useFavoriteStatus } from "services/favorites";
import { useDisplayLoggedIn } from "services/user";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";
import { IconName } from "components/atoms/icon";

interface Props {
    playable: FavoritePreviewModel | null;
    showIcon: boolean;
    assumedFavorite?: boolean;
    context: PreviewContextModel;
}

export const AddToMyMusicLink = ({ showIcon, playable, assumedFavorite, context }: Props) => {
    const loggedIn = useDisplayLoggedIn();
    let isFavorite = useFavoriteStatus(playable);
    const translations = useTranslations();
    const { section } = context;
    if (isFavorite === null && assumedFavorite !== undefined) {
        isFavorite = assumedFavorite;
    }

    const icon = useMemo(() => {
        if (!showIcon) return undefined;
        if (isFavorite) return IconName.FavoritesActive;
        return IconName.Favorites;
    }, [isFavorite, showIcon]);

    const addFavoritesOrLogin = useCallback(
        () =>
            dispatch({
                type: PLAYER_TOGGLE_FAVORITE,
                payload: {
                    section,
                    playables: playable ? [playable] : null,
                    operation: "add"
                }
            }),
        [playable, section]
    );

    const removeFavorites = useCallback(
        () =>
            dispatch({
                type: PLAYER_TOGGLE_FAVORITE,
                payload: {
                    section,
                    playables: playable ? [playable] : null,
                    operation: "remove"
                }
            }),
        [playable, section]
    );

    if (playable == null) {
        return <Link icon={icon}>{` `}</Link>;
    }

    if (isFavorite != true) {
        if (playable.contentType === ContentType.LiveRadioTrack && !playable.track.availableInSubscription) {
            return null;
        }
        if (playable.contentType === ContentType.TrackPlayable && !playable.track.availableInSubscription) {
            return null;
        }
        if (playable.contentType === ContentType.Album && !playable.availableInSubscription) {
            return null;
        }
    }

    if (isFavorite == null && loggedIn) {
        return <Link icon={icon}>{` `}</Link>;
    }

    return isFavorite ? (
        <Link icon={icon} toggled closeOnClick onClick={removeFavorites}>
            {translations.ContextOptionRemoveFromMyMusic}
        </Link>
    ) : (
        <Link icon={icon} closeOnClick onClick={addFavoritesOrLogin}>
            {translations.ContextOptionAddToMyMusic}
        </Link>
    );
};
