import { h } from "preact";
import { PreviewContextModel } from "models/app/resourceContextModel";
import { CoverSize } from "components/atoms/cover";
import { ResourceCard } from "components/molecules/resource";

interface Props {
    context: PreviewContextModel;
}

export const ResourceMenuTop = ({ context }: Props) => {
    return <ResourceCard context={context} size={CoverSize.Size64} scrollingText={true} disabledAction={true} disableLazyLoad={true} />;
};
