import type { Action } from "global/actions";
import { REHYDRATE, UPDATE_CACHED_PLAYLISTS } from "global/actions";
import type { CacheModel } from "models/app";

const initialState: CacheModel = {
    userPlaylistPreviews: null,
    version: 1
};

export function cacheReducer(state: CacheModel = initialState, action: Action): CacheModel {
    switch (action.type) {
        case UPDATE_CACHED_PLAYLISTS: {
            return { ...state, userPlaylistPreviews: action.payload.playlists };
        }
        case REHYDRATE: {
            const cache = action.payload?.cache ?? null;
            if (cache == null) return initialState;
            if (cache.version === undefined) return initialState;
            return cache;
        }
    }
    return state;
}
