import { h } from "preact";
import { useSelector } from "react-redux";
import { TestLocator } from "global/constants";
import { userToggleCastSession } from "services/player/controllers/chromeCast/service/actions/toggleCastSession";
import type { RootModel } from "models/app";
import { AudioControllerType } from "models/app/player";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";

export const CastButton = () => {
    const isAvailable = useSelector((root: RootModel) => root.cast.isAvailable);
    const isControllerChromecast = useSelector((root: RootModel) => root.player.controller) === AudioControllerType.Chromecast;

    return (
        <Button
            disabled={!isAvailable}
            onClick={userToggleCastSession}
            toggled={isControllerChromecast}
            icon={IconName.Chromecast}
            design={ButtonDesign.PlayerSecondary}
            testLocator={TestLocator.PlayerChromecastButton}
        />
    );
};
