import type { SearchPlaylistsPaginationQuery } from "./../../generated/graphql-types";
import type { ConnectionModel } from "./ConnectionModel";
import { createPlaylistPreviewConnectionModel } from "./ConnectionModel";
import type { PlaylistPreviewModel } from "./PlaylistModel";
import type { ResourceModelInterface } from "./ResourceModel";
import { getNextUUID } from "services/utils";
import { DomainModelType, ContentType } from "models/ModelType";

type SearchResultData = SearchPlaylistsPaginationQuery["search"];

export type SearchPlaylistResultsModel = SearchPlaylistResultsSectionModel;
export type SearchPlaylistResultsSectionModel = ({ type: DomainModelType.Section } & SearchPlaylistResults) | SearchPlaylistResultsPageModel;
export type SearchPlaylistResultsPageModel = { type: DomainModelType.Page } & SearchPlaylistResults;

interface SearchPlaylistResults extends ResourceModelInterface {
    contentType: ContentType.SearchPlaylistResults;
    criterion: string;
    playlists: ConnectionModel<PlaylistPreviewModel> | null;
}
function createSearchPlaylistResults(data: SearchResultData, criterion: string): SearchPlaylistResults {
    return {
        graphType: data.__typename,
        id: getNextUUID(),
        cover: null,
        contentType: ContentType.SearchPlaylistResults,
        criterion,
        playlists: data.playlists ? createPlaylistPreviewConnectionModel(data.playlists) : null
    };
}
export function createSearchPlaylistResultsSectionModel(data: SearchResultData, criterion: string): SearchPlaylistResultsSectionModel {
    return {
        ...createSearchPlaylistResults(data, criterion),
        type: DomainModelType.Section
    };
}

export function createSearchPlaylistResultsPageModel(data: SearchResultData, criterion: string): SearchPlaylistResultsPageModel {
    return {
        ...createSearchPlaylistResults(data, criterion),
        type: DomainModelType.Page
    };
}
