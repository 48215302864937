import { h } from "preact";
import { useMemo, useRef, useCallback } from "preact/hooks";
import { Button } from "../Button";
import { TestLocator } from "global/constants";
import { log, DefaultLogMessage } from "services/logger";
import { isNotificationFeedEnabled, useCurrentUnseenNotificationsExist } from "services/notificationFeed";
import { fetchAndMarkNotifications } from "services/notificationFeed/update";
import { useDisplayLoggedIn } from "services/user";
import { ContextMenuSheetWidth } from "models/view/contextMenu";
import { useContextMenu } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";
import { NotificationFeedContextMenu } from "components/organisms/contextMenu/menus/NotificationFeedContextMenu";

export function ButtonNotificationFeed() {
    const loggedIn = useDisplayLoggedIn();

    const notificationFeedEnabled = useMemo(() => loggedIn && isNotificationFeedEnabled(), [loggedIn]);
    const notificationFeedButtonRef = useRef<HTMLButtonElement>(null);

    const notificationFeed = useContextMenu(<NotificationFeedContextMenu />, false, ContextMenuSheetWidth.Wide);

    const currentUnseen = useCurrentUnseenNotificationsExist(false);

    const openNotificationFeed = useCallback(async () => {
        if (!notificationFeedButtonRef.current) {
            log.error({ code: "web-210519-1100", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        fetchAndMarkNotifications()
        notificationFeed.openAtPosition(60, 8);
    }, [notificationFeed]);

    if (!notificationFeedEnabled) return null;

    return (
        <Button
            activated={notificationFeed.isOpen}
            ref={notificationFeedButtonRef}
            icon={currentUnseen ? IconName.DotNotification : IconName.Notification}
            onClick={openNotificationFeed}
            testLocator={TestLocator.PageHeaderNotificationFeedButton}
        />
    );
}
