import type { AnalyticsQueueIntentPropertiesGroup } from "./queueIntentPropertiesGroup";
import type { AnalyticsResourceParentProperties } from "../event/resourceParentProperties";
import { getAnalyticsResourceParentProperties } from "../event/resourceParentProperties";
import type { PlayableParentModel } from "models/domain";

export interface AnalyticsQueueAddedPropertiesGroup extends AnalyticsQueueIntentPropertiesGroup {
    resourceParentProperties: AnalyticsResourceParentProperties;
}

export function createQueueAddedAnalyticsData(queueIntentData: AnalyticsQueueIntentPropertiesGroup, parent: PlayableParentModel): AnalyticsQueueAddedPropertiesGroup {
    return {
        ...queueIntentData,
        resourceParentProperties: getAnalyticsResourceParentProperties(parent)
    };
}
