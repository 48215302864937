import { h } from "preact";
import type { ModalButton } from "..";
import { Modal, dismissModal, modalButtonSubmitOK, openModal } from "..";
import { dispatch } from "global";
import { USER_LOGIN_ERROR } from "global/actions";
import { useTranslations } from "global/config";
import { ButtonDesign } from "components/atoms/controls/button";
import { showLoginUi } from "components/organisms/login";

export function showLoginErrorModal(errorcode: number) {
    dispatch({
        type: USER_LOGIN_ERROR,
        payload: {
            error: errorcode
        }
    });
    return openModal(
        errorcode === 3
            ? <NoMusicRightsModal />
            : <GeneralLoginErrorModal />
    );
}

function GeneralLoginErrorModal() {
    const translations = useTranslations();
    return <Modal hasXCloseButton title={translations.LoginErrorHeader} text={translations.LoginErrorTextSomethingWentWrong} buttons={[modalButtonSubmitOK()]} />;
}

function NoMusicRightsModal() {
    const noMusicRightsButtons: ModalButton[] = [
        {
            title: "LoginWithAnotherLogin",
            action: () => {
                dismissModal("LoginWithAnotherLogin");
                showLoginUi();
            },
            design: ButtonDesign.TextBrandMedium
        }
    ];
    const translations = useTranslations();
    return <Modal hasXCloseButton title={translations.LoginNoMusicRights} text={translations.LoginNoMusicRightsSubTitle} buttons={noMusicRightsButtons} />;
}
