import { h } from "preact";
import { forwardRef, useEffect, useState } from "preact/compat";
import "./Toast.scss";
import type { OpenToastModel } from "./toasts";
import { dispatch, messageBus } from "global";
import { TOAST_CLOSE, TOAST_OPEN } from "global/actions";
import { animations } from "global/constants";

export enum ToastDuration {
    Short,
    Long
}

function getDurationMs(duration: ToastDuration) {
    switch (duration) {
        case ToastDuration.Short:
            return animations.toastDurationShort;
        case ToastDuration.Long:
            return animations.toastDurationLong;
    }
}

interface Props {
    className?: string;
}

export const AppToast = forwardRef<HTMLDialogElement, Props>(({ className }, ref) => {
    const [toast, setToast] = useState<h.JSX.Element | null>(null);
    const open = toast != null;

    useEffect(() => {
        let timeout: number | null;

        const unsubscribe1 = messageBus.subscribeEvery(TOAST_OPEN, (action) => {
            const { toast: newToast, duration } = action.payload.toast;

            if (timeout != null) clearTimeout(timeout);
            setToast(newToast);

            timeout = window.setTimeout(() => {
                if (timeout != null) clearTimeout(timeout);
                setToast(null);
            }, getDurationMs(duration));
        });

        const unsubscribe2 = messageBus.subscribeEvery(TOAST_CLOSE, (action) => {
            if (timeout != null) clearTimeout(timeout);
            setToast(null);
        });

        return () => {
            unsubscribe1();
            unsubscribe2();
            if (timeout != null) clearTimeout(timeout);
            setToast(null);
        };
    }, []);

    return (
        <dialog className={`appToast dtn ${className ?? ""} --open-${open}`} ref={ref}>
            {toast}
        </dialog>
    );
});

export function openToast(toast: OpenToastModel) {
    dispatch({ type: TOAST_OPEN, payload: { toast } });
}

export function closeToast() {
    dispatch({ type: TOAST_CLOSE });
}
