import { getAudioByInputItemId } from "services/player/inputs/service/helpers";
import { PlayState } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";

export function isAudioItemPlaying(audios: AudioInputItemModel[], queueId: string) {
    const item = getAudioByInputItemId(audios, queueId);

    if (item?.playState === PlayState.Buffering) return true;
    if (item?.playState === PlayState.Playing) return true;
    return false;
}
