import { store } from "global";
import { log } from "services/logger";
import { RepeatStatus } from "models/view";

export function getLocalRepeat() {
    return store.getState().player.repeat;
}

export function convertToRepeatMode(repeat: RepeatStatus): chrome.cast.media.RepeatMode {
    switch (repeat) {
        case RepeatStatus.All:
            return chrome.cast.media.RepeatMode.ALL;
        case RepeatStatus.None:
            return chrome.cast.media.RepeatMode.OFF;
        case RepeatStatus.One:
            return chrome.cast.media.RepeatMode.SINGLE;
    }
}

export function convertToRepeatStatus(repeat: chrome.cast.media.RepeatMode): RepeatStatus {
    if (repeat === chrome.cast.media.RepeatMode.ALL_AND_SHUFFLE) {
        log.error({ code: "web-230224-1255", msg: `cast repeat mode not supported: ${repeat}` });
        return RepeatStatus.None;
    }

    switch (repeat) {
        case chrome.cast.media.RepeatMode.ALL:
            return RepeatStatus.All;
        case chrome.cast.media.RepeatMode.OFF:
            return RepeatStatus.None;
        case chrome.cast.media.RepeatMode.SINGLE:
            return RepeatStatus.One;
    }
}
