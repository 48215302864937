import { h } from "preact";
import { useCallback, useEffect, useState } from "preact/compat";
import { useSelector } from "react-redux";
import "./PlayerMini.scss";
import { dispatch } from "global";
import { PLAYER_TOGGLE_PLAY, SET_DESKTOP_LYRICS_VISIBLE, SET_MAXIPLAYER_OPEN, SET_MINIPLAYER_OPEN } from "global/actions";
import { animations, TestLocator } from "global/constants";
import { useMessageBus } from "global/hooks";
import { getPlayIconMobileMini } from "services/player/controllers/service/helpers/icons";
import type { RootModel } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { MobileMaxiPlayerOpen } from "models/app/UiModel";
import { ContentType } from "models/ModelType";
import {
    useAudioProperties,
    DelayType,
    useDelay,
    useCurrentLanePrimaryResourceFromState,
    useSectionContext,
    usePreviewContext,
    useCurrentAudioSecondaryResourceFromState,
    useOnRightClickContextMenu
} from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { PlayRange, RangeType } from "components/atoms/controls/playRange";
import { CoverRounding, CoverSize } from "components/atoms/cover";
import { PlayableLoadSpinner } from "components/atoms/spinner";
import { ResourceCard } from "components/molecules/resource";

interface Props {
    className?: string;
}

export const PlayerMini = ({ className }: Props) => {
    const { resource, resourceType } = useCurrentAudioSecondaryResourceFromState();
    const { resource: imageResource, type: imageType } = useCurrentLanePrimaryResourceFromState();

    const sectionContext = useSectionContext(ContentType.Player, null, null, null, null);
    const context = usePreviewContext(resourceType, resource, sectionContext, null);
    const imageContext = usePreviewContext(imageType, imageResource, sectionContext, null);

    const [open, setOpen] = useState(true);

    useEffect(() => {
        setOpen(resource != null);
    }, [resource]);

    const { playState, canPlay, canPause } = useAudioProperties();

    const handlePlayerClick = useCallback(() => {
        dispatch({ type: SET_MAXIPLAYER_OPEN, payload: { open: MobileMaxiPlayerOpen.Open } });
        dispatch({ type: SET_DESKTOP_LYRICS_VISIBLE, payload: { open: false } });
    }, []);
    useMessageBus(SET_MINIPLAYER_OPEN, (message) => setOpen(message.payload.open));
    const [closedDelay, setClosedDelay] = useState(false);

    useEffect(() => {
        setClosedDelay(false);
        if (open) return;
        const timeOutId = window.setTimeout(() => setClosedDelay(true), animations.durationLong);
        return () => window.clearTimeout(timeOutId);
    }, [open]);

    const closed = closedDelay && !open;
    const openDelay = useDelay({ delay: { type: DelayType.TimeOut, msec: 0 }, reset: closed }) && open;

    const input = useSelector((root: RootModel) => root.player.input);

    const playerTogglePLay = useCallback(() => dispatch({ type: PLAYER_TOGGLE_PLAY, payload: { context: { action: AudioContextAction.UserPlayerTogglePlay, trace: null } } }), []);

    const { onContextMenu } = useOnRightClickContextMenu(context, resource, sectionContext);

    if (closed) return null;
    return (
        <Button className={`playerMini Jo1 ${className ?? ""} --show-${openDelay} `} design={ButtonDesign.PlayableRow} onClick={handlePlayerClick} onContextMenu={onContextMenu}>
            <PlayRange type={RangeType.MobileMini} />
            <div className="track">
                <ResourceCard size={CoverSize.Size64} rounding={CoverRounding.None} context={context} imageContext={imageContext} scrollingText={true} disableLazyLoad>
                    <PlayableLoadSpinner />
                </ResourceCard>
                <Button
                    className={"play"}
                    disabled={!canPlay && !canPause}
                    onClick={playerTogglePLay}
                    icon={getPlayIconMobileMini(playState, input)}
                    testLocator={TestLocator.PlayerPlayPauseButton}
                />
            </div>
        </Button>
    );
};
