import { log, LogTag } from "services/logger";

export function requestHelper<T>(name: string, request: T, fn: (request: T, success: () => void, error: (error: unknown) => void) => void) {
    let _resolve: (success: boolean) => void;
    let timeout: number | undefined;

    const execute = (success: boolean) => {
        if (timeout != undefined) {
            clearTimeout(timeout);
            timeout = undefined;
        }
        _resolve(success);
    };

    const success = () => {
        log.info({ code: "web-230109-1310", msg: `...${name} success`, data: { request } });
        execute(true);
    };

    const error = (error: unknown) => {
        log.error({ code: "web-230109-1310", msg: `...${name} error`, data: { request, error } });
        execute(false);
    };

    timeout = window.setTimeout(() => {
        log.error({ code: "web-230109-1310", msg: `...${name} timeout`, data: { request } });
        execute(false);
    }, 2000);

    const done = new Promise<boolean>((resolve) => {
        _resolve = resolve;
    });

    log.info({ tags: [LogTag.Chromecast], code: "web-230109-1310", msg: `${name}...`, data: { request } });
    fn(request, success, error);

    return done;
}
