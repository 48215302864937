import type { TrackUrlResult } from "../hls";
import { getPlaybackFull, getPlaybackSample } from "services/backend/backend";
import { log, LogTag } from "services/logger/initLoggerService";
import { simulateInvalidUrl } from "services/player/helpers/debug";
import { StreamQuality } from "generated/graphql-types";
import { StreamUrlType } from "models/app/player/input";

export const fetchTrackUrl = async (trackId: string, sample: boolean, logTitle: string): Promise<TrackUrlResult> => {
    const invalidUrl = simulateInvalidUrl(sample ? StreamUrlType.Hls : StreamUrlType.Mp3);
    if (invalidUrl.simulate) return { url: invalidUrl.url ?? null, message: "simulating invalid url" };

    const getTrackStream = sample ? getPlaybackSample : getPlaybackFull;
    const result = await getTrackStream({ id: trackId, quality: StreamQuality.Kbps_320 });

    const data = { trackId, sample, result };
    if (result == null) {
        log.error({ code: "web-210330-1041", msg: "backend could not return trackStream", data });
        return { url: null, message: "backend could not return trackStream" };
    }
    if (result.error) {
        log.error({ code: "web-210330-1041", msg: `error: ${result.errorReason}`, data });
        return { url: null, message: `error: ${result.errorReason}` };
    }
    const url = result.model;

    if (url) {
        log.debug([LogTag.Playback], () => ({
            code: "web-230102-1128",
            msg: `fetched new track url, title: ${logTitle}`
        }));

        return { url, message: "fetched new track url" };
    }

    log.error({ code: "web-210330-1042", msg: "backend returned trackStream with no urls", data });
    return { url: null, message: "backend returned trackStream with no urls" };
};
