import { requestHelper } from "../../helpers";
import { getCastMediaSession } from "../../setup";

export async function castQueueRemoveItems(itemIds: number[]): Promise<boolean> {
    for (const itemId of itemIds) {
        const ok = await castQueueRemoveItem(itemId);
        if (!ok) return false;
    }
    return true;
}

async function castQueueRemoveItem(itemId: number): Promise<boolean> {
    const media = getCastMediaSession();
    if (!media) return false;

    return requestHelper("castQueueRemoveItem", itemId, (request, success, error) => {
        media.queueRemoveItem(request, success, error);
    });
}
