import { h } from "preact";
import { useSelector } from "react-redux";
import "./Queue.scss";
import { LiveRadioQueue } from "./liveRadioQueue";
import { TrackQueue } from "./trackQueue";
import { useLoginState } from "services/user";
import { LoginState, type RootModel } from "models/app";
import { AudioInputType } from "models/app/player";

interface Props {
    onCurrentTrackClick?: () => void;
    onCurrentCoverClick?: () => void;
    queueOpen: boolean;
    disableFade?: boolean;
}

export const Queue = (props: Props) => {
    const isInputLiveRadio = useSelector((root: RootModel) => root.player.input) === AudioInputType.LiveRadio;
    const loginStateIsKnown = useLoginState() !== LoginState.Unknown;
    if (!loginStateIsKnown) return null;
    if (isInputLiveRadio) return <LiveRadioQueue disableFade={props.disableFade} />;
    return <TrackQueue {...props} />;
};
