import { h } from "preact";
import { Link } from "..";
import { dispatch } from "global";
import { SET_DESKTOP_LYRICS_VISIBLE, SET_MAXIPLAYER_OPEN } from "global/actions";
import { Feature, isFeatureEnabled, useTranslations } from "global/config";
import { useTrackLyricsId } from "services/backend";
import { useLoginState } from "services/user";
import { LoginState, MobileMaxiPlayerOpen } from "models/app";
import type { PlayQueuePlayableModel } from "models/domain/PlayQueuePlayableModel";
import { IconName } from "components/atoms/icon";

interface Props {
    playable: PlayQueuePlayableModel | null;
    showIcon: boolean;
}

export const ShowLyricsLink = ({ playable, showIcon }: Props) => {
    const translations = useTranslations();
    const lyricsId = useTrackLyricsId({ id: playable?.id ?? "0" })?.model;
    const isLoggedIn = useLoginState() === LoginState.LoggedIn;
    const isLyricsEnabled = isFeatureEnabled(Feature.Lyrics);

    if (!isLoggedIn || !isLyricsEnabled || !lyricsId) return null;

    const showLyrics = async () => {
        if (!playable || !lyricsId) return;
        dispatch({
            type: SET_DESKTOP_LYRICS_VISIBLE,
            payload: {
                open: true,
                startingPoint: "ContextMenu",
                trackId: playable.id,
                lyricsId
            }
        });
    };

    return (
        <Link disabled={!isLoggedIn || !isLyricsEnabled || !lyricsId} icon={showIcon ? IconName.Lyrics : undefined} onClick={showLyrics} closeOnClick>
            {translations.ContextOptionShowLyrics}
        </Link>
    );
};
