import { h } from "preact";
import { useRef } from "preact/compat";
import "./PreviewSingle.scss";
import { PreviewDisplayType, usePreviewScrollToHighlight } from "..";
import { PreviewLinks } from "../PreviewLinks";
import { PreviewSingleTestLocator } from "global/constants";
import type { SectionContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel, ResourceContentType } from "models/domain";
import { useLinkContext, useOnRightClickContextMenu, usePreviewContext, useResizeObserver } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { ResourceCover, CoverSize } from "components/atoms/cover";

interface Props {
    children: ResourcePreviewModel | null;
    resourceType: ResourceContentType;
    highlight: boolean;
    section: SectionContextModel;
}

export const PreviewSingle = ({ children, resourceType, section, highlight }: Props) => {
    const preview = usePreviewContext(resourceType, children, section, 0);

    const { onContextMenu, available, moreOpen } = useOnRightClickContextMenu(preview, children, section, false, undefined);

    const ref = useRef<HTMLDivElement>(null);
    usePreviewScrollToHighlight({ ref, highlight });

    const width = useResizeObserver(ref.current)?.width ?? 0;
    const size = width >= 520 ? "big" : "small";

    const image = <ResourceCover disabled={false} size={size === "small" ? CoverSize.Size80 : CoverSize.Size144} context={preview} disabledAction={true} disableLazyLoad={false} />;
    const primaryLink = useLinkContext(resourceType, children, preview);

    const links = PreviewLinks({
        resourceType,
        resource: children,
        context: preview,
        displayType: PreviewDisplayType.Single
    });

    return (
        <Button
            ref={ref}
            activated={moreOpen}
            onContextMenu={onContextMenu}
            link={primaryLink ?? undefined}
            design={ButtonDesign.PlayableRow}
            disabled={false}
            className={`previewSingle c9f --type-${resourceType} --size-${size} --available-${available} --highlight-${highlight}`}
            data-cy={PreviewSingleTestLocator(resourceType)}>
            <figure>
                {image}
                {links.length > 0 && (
                    <figcaption>
                        {links.map((link, index) => (
                            <div key={index}>{link}</div>
                        ))}
                    </figcaption>
                )}
            </figure>
        </Button>
    );
};
