import { Fragment, h } from "preact";
import { useMemo } from "preact/hooks";
import "./ShowAllPageTemplate.scss";
import { isFeatureEnabled, Feature } from "global/config";
import type { UseDomainQueryResult } from "services/backend/backendService";
import { useFilter } from "services/filter";
import type { ContextResourceModel, PreviewContextModel } from "models/app/resourceContextModel";
import type { SortOptionsModel } from "models/app/SortOption";
import type {
    ConnectionModel,
    LiveRadioPreviewModel,
    LocationContentType,
    ResourceContentType,
    ResourceDisplayType,
    ResourcePageModel,
    ResourcePreviewModel,
    TrackPreviewModel
} from "models/domain";
import type { NotificationConversion } from "models/domain/NotificationModel";
import type { NavigationPageRootName } from "models/view/navigationStack";
import { usePageContext, usePageLoadState, useSectionContextFromPage, useAutoPlay } from "components/shared/hooks";
import type { ConnectionFetchFn } from "components/shared/hooks/useConnectionHandler";
import { usePagination } from "components/shared/hooks/usePagination";
import { useResourcesFromUnions } from "components/shared/hooks/useResources";
import { usePageScrollRestoration } from "components/shared/hooks/useScrollRestoration";
import { ButtonSort } from "components/atoms/controls/button/buttons";
import { PageTopBar } from "components/molecules/pageTopBar";
import type { PreviewAction } from "components/molecules/preview";
import { FilterUi } from "components/organisms/filter";
import { ResourceSection } from "components/organisms/resourceSection";

interface Props<TPage extends ResourcePageModel, TItem extends ResourcePreviewModel | TrackPreviewModel | LiveRadioPreviewModel> {
    className?: string;
    display: ResourceDisplayType | null;
    fetchFn?: ConnectionFetchFn<TItem>;
    items: ConnectionModel<TItem> | null;
    max?: number;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    query: UseDomainQueryResult<TPage>;
    // alternateAutoPlay?: ContextResourceModel | null;
    alternateAutoPlayFn?: AlternateAutoPlayFn<TItem>;
    root?: NavigationPageRootName;
    sortOptions?: SortOptionsModel;
    type: ResourceContentType;
    pageType?: LocationContentType;
    highlightedId?: string;
    disableFiltering?: boolean;
}
export type AlternateAutoPlayFn<T extends ResourcePreviewModel | TrackPreviewModel | LiveRadioPreviewModel> = (items: ConnectionModel<T> | null) => {
    type: LocationContentType | null;
    resource: ContextResourceModel | null;
};

export const ShowAllPageTemplate = <TPage extends ResourcePageModel, TItem extends ResourcePreviewModel | TrackPreviewModel | LiveRadioPreviewModel>({
    query,
    type,
    pageType,
    display,
    items,
    onAction,
    fetchFn,
    max,
    sortOptions,
    root,
    alternateAutoPlayFn,
    highlightedId,
    disableFiltering
}: Props<TPage, TItem>) => {
    const page = usePageContext({ type: pageType ?? type, resource: query.model, root: root ?? null, done: query.success });
    const section = useSectionContextFromPage(page, display);
    const resource = section.resource;

    const pagination = usePagination({ resource, connection: items, fetchFn, max });
    const restoration = usePageScrollRestoration({ page });
    const unfiltererResourceItems = useResourcesFromUnions(pagination.connection?.items ?? null, resource);

    const { filteredItems: resourceItems, setFilter, filterIsExpanded, setFilterIsExpanded, hasLoadedOnce } = useFilter(unfiltererResourceItems, pagination);

    const alternateAutoPlay = useMemo(() => (alternateAutoPlayFn ? alternateAutoPlayFn(pagination.connection) : null), [alternateAutoPlayFn, pagination.connection]);
    const autoPlayType = alternateAutoPlay?.type ?? type;
    const autoPlayResource = alternateAutoPlay?.resource ?? query.model;

    useAutoPlay({ page, type: autoPlayType, resource: autoPlayResource });
    const { error, spinner, showContent } = usePageLoadState(page, query);
    if (error) return error;

    return (
        <div
            className={`showAllPageTemplate etf`}
            tabIndex={-1}
            ref={(r) => {
                pagination.scrollRef.current = r;
                restoration.scrollRef.current = r;
            }}>
            <div
                className="content"
                ref={(r) => {
                    restoration.contentRef.current = r;
                }}>
                {
                    (error || resource) &&
                    <PageTopBar
                        error={query.error}
                        page={page}
                        back={true}
                        disableMobileTitle={filterIsExpanded}
                        right={
                            <Fragment>
                                {hasLoadedOnce && !disableFiltering && isFeatureEnabled(Feature.ClientsideFiltering) && (
                                    <FilterUi onChange={setFilter} onExpandedChanged={setFilterIsExpanded} placeholder="" />
                                )}
                                {sortOptions && !query.error && <ButtonSort sortOptions={sortOptions} disabled={!resource} />}
                            </Fragment>
                        }
                    />
                }

                {spinner || showContent && <ResourceSection resources={resourceItems} context={section} pagination={pagination} onAction={onAction} highlightedId={highlightedId} />}
            </div>
        </div>
    );
};
