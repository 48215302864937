import { h } from "preact";
import { useCallback, useMemo } from "preact/hooks";
import "./BasicRange.scss";

interface Props {
    onChange?: (percent: number) => void;
    step?: number;
    max?: number;
    value: number;
    disabled?: boolean;
}

export const BasicRange = ({ onChange, step = 0, max = 100, value, disabled }: Props) => {
    const handleOnInput = useCallback(
        (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => {
            const value = Number(e.currentTarget.value);
            onChange && onChange(value);
        },
        [onChange]
    );

    const min = 0;
    const progressStyle = useMemo(() => ({ width: `${(value * 100) / max}%` }), [max, value]);

    return (
        <div className={`range o2d`} disabled={disabled}>
            <input type="range" className="hiddenRange" min={min} max={max} value={value} step={step} disabled={disabled} onInput={handleOnInput} />
            <div className={`visualRange`}>
                <div className={`progress`} style={progressStyle} />
            </div>
        </div>
    );
};
