import { addTracksToQueueTracks } from "./tracks";
import { getPlayablesTracks } from "services/playable";
import type { QueueTrackModel, QueueLane } from "models/app/player/input";
import type { PlayQueuePlayableModel } from "models/domain";

export const addPlayablesToQueue = async (
    playQueue: QueueTrackModel[],
    playables: PlayQueuePlayableModel[],
    lane: QueueLane.Memory | QueueLane.Current | QueueLane.Priority | QueueLane.Flow,
    atEnd: boolean,
    insert: number | null,
    setCurrentLane: boolean,
    shuffleOn: boolean,
    referenceId: string
): Promise<QueueTrackModel[]> => {
    const tracks = await getPlayablesTracks(playables);
    const queueTracks = addTracksToQueueTracks(playQueue, tracks, lane, atEnd, insert, setCurrentLane, shuffleOn, referenceId, "web-210716-1552");

    return queueTracks;
};