import { dispatch, store } from "global";
import { serviceWorkerConfig } from "shared/services";
import { AUDIO_INPUT_AUDIO_SRC_CHANGE } from "global/actions";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { StreamUrl } from "models/app/player/input";
import { StreamUrlType } from "models/app/player/input";

const invalidHlsUrl =
    "https://ahls.cdn.247e.com/ahlsaes/assets/mp4-320kbps/02/3B/20/b362d6c9-53ce-40c0-8950-f528d57a8d35 .mp4/index.m3u8?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vYWhscy5jZG4uMjQ3ZS5jb20vYWhsc2Flcy9hc3NldHMvbXA0LTMyMGticHMvMDIvM0IvMjAvNzRiMTExOTUtMzA4Zi00YWFlLTkwMGUtNDY1MWRlZjhiYWU3Lm1wNC8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNjU2MzQ5NjQ5fX19XX0_&Signature=YLz0sIhVx7SUfigLAzQh0SBpUdnn6kmorB41c43hs0M9KzrNpzI6ZvpY1JS6FRVyeAh4HKitFays44T1qxkD~x2vEnZIxtMx8nzNEqD1GTZIhf1APksNIh5pfFT3eLpkMrpYKdCU9-ij9v9XtMc7e35nenqNrKQnO5fmnn-EHNcO7GAGJZM8sZtqDmxKs-URDIfPsCabbKH5PDY6U5ZU7Djd-HXKwKDiDGgEGfZHb1eTx8OWiAss6gTs80Y3yx4fUSom7DMXg2HENU70vqie8cfMtXwoGtQAVERzHbBw2Fs-KvKAyJbuim2K-grySG0vc7PW9FaRVj3-f6hLZZdcNw__&Key-Pair-Id=APKAJY2HPBFJRRPPSOTQ&t=252305110&w=334&u=93391504&p=1458&c=nuumusicapp-web&s=hMQVOz0zyLVk23OfJhcCXFPhza0bUuMtE_GIfg8IQ4M";
const invalidMp3Url = "https://amp4samples.cdn.247e.com/sample/assets/mp4-320kbps/01/02/37/b362d6c9-53ce-40c0-8950-f528d57a8d35.mp4";

let _simulateFailGetTrackStreamUrls = 0;
let _simulateInvalidTrackStreamUrls = 0;

export function initPlayerDebug() {
    const clearCachePool = () => _clearCachePool();
    const invalidateQueueTrackStreamUrls = () => _invalidateQueueTrackStreamUrls();
    const simulateFailGetTrackStreamUrls = (number: number) => (_simulateFailGetTrackStreamUrls = number);
    const simulateInvalidTrackStreamUrls = (number: number) => (_simulateInvalidTrackStreamUrls = number);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).playerDebug = {
        clearCachePool,
        invalidateQueueTrackStreamUrls,
        simulateFailGetTrackStreamUrls,
        simulateInvalidTrackStreamUrls
    };
}

async function _clearCachePool() {
    await caches.delete(serviceWorkerConfig.cacheNameHls);
}

export function simulateFailGetTrackStreamUrls(): boolean {
    const should = _simulateFailGetTrackStreamUrls > 0;
    _simulateFailGetTrackStreamUrls--;
    return should;
}

export function simulateInvalidTrackStreamUrls(): boolean {
    const should = _simulateInvalidTrackStreamUrls > 0;
    _simulateInvalidTrackStreamUrls--;
    return should;
}

function _invalidateQueueTrackStreamUrls() {
    const audios = store.getState().audioInput.audios;
    const context: AudioContextModel = { action: AudioContextAction.Unknown, trace: null };

    audios.forEach(async (audio) => {
        if (!audio.url2) return;
        audio.url2 = getInvalidUrl(audio.url2.urlType);
        await dispatch({
            type: AUDIO_INPUT_AUDIO_SRC_CHANGE,
            payload: {
                audio,
                context
            }
        });
    });
}

function getInvalidUrl(urlType: StreamUrlType): StreamUrl {
    switch (urlType) {
        case StreamUrlType.Hls:
            return { urlType, url: invalidHlsUrl, fetchedTime: new Date().getTime() };
        case StreamUrlType.Mp3:
            return { urlType, url: invalidMp3Url, fetchedTime: new Date().getTime() };
    }
}

export function simulateInvalidUrl(urlType: StreamUrlType): { simulate: boolean; url?: StreamUrl } {
    if (simulateFailGetTrackStreamUrls()) return { simulate: true };
    if (simulateInvalidTrackStreamUrls()) return { simulate: true, url: getInvalidUrl(urlType) };

    return { simulate: false };
}
