import { h } from "preact";
import { useCallback } from "preact/hooks";
import { useSelector } from "react-redux";
import { Accordion } from "./Accordion";
import { Setting } from "./Setting";
import { dispatch } from "global";
import { SET_SOUNDQUALITY_SETTING } from "global/actions";
import { Feature, isFeatureEnabled, useTranslations } from "global/config";
import type { RootModel } from "models/app";
import { SoundQualitySetting } from "models/view/Localization";
import { RadioButton } from "components/atoms/controls/radioButton";
import { IconName } from "components/atoms/icon";
import { hasPermissionFromToken } from "services/user";

export const SoundQualitySettings = () => {
    const translations = useTranslations();

    if (!isFeatureEnabled(Feature.SoundQuality) || !hasPermissionFromToken("cd")) return null;

    const buttonHigh = <SoundQualityButton setting={SoundQualitySetting.High} />;
    const buttonNormal = <SoundQualityButton setting={SoundQualitySetting.Normal} />;
    const buttonLow = <SoundQualityButton setting={SoundQualitySetting.Low} />;
    return (
        <Accordion icon={IconName.VolumeUp} title={translations.SettingsSoundQualityTitle}>
            <Setting title={translations.SettingsSoundQualityHighTitle} subtitle={translations.SettingsSoundQualityHighDescription} button={buttonHigh} />
            <Setting title={translations.SettingsSoundQualityNormalTitle} subtitle={translations.SettingsSoundQualityNormalDescription} button={buttonNormal} />
            <Setting title={translations.SettingsSoundQualityLowTitle} subtitle={translations.SettingsSoundQualityLowDescription} button={buttonLow} />
        </Accordion>
    );
};

const SoundQualityButton = ({ setting }: { setting: SoundQualitySetting }) => {
    const soundQualitySetting = useSelector((root: RootModel) => root.ui.soundQualitySetting);
    const change = useCallback(() => {
        dispatch({ type: SET_SOUNDQUALITY_SETTING, payload: { soundQualitySetting: setting } });
    }, [setting]);

    return <RadioButton checked={soundQualitySetting === setting} onChange={change} name={setting} />;
};
