import type { QueryError } from "services/backend/backendService";

export interface PageLoadError {
    type: PageLoadErrorType;
    message: string | null;
}

export enum ErrorPageType {
    ContentNotFoundErrorPage = "ContentNotFoundPage",
    NoConnectionErrorPage = "NoConnectionErrorPage",
    BackendDownErrorPage = "BackendDownErrorPage",
    DefaultErrorPage = "DefaultErrorPage"
}

export enum PageLoadAppErrorType {
    UrlSchemaNotRecognizedError = "UrlSchemaNotRecognizedError"
}

export type PageLoadErrorType = PageLoadAppErrorType | QueryError;
