import { h } from "preact";
import { useContext } from "preact/hooks";
import { ContextMenuMode, ContextMenuManagerContext, Sheet } from "..";
import { ResourceMenuTop } from "./tops";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlayQueuePlayablePreviewModel } from "models/domain";

interface Props {
    playable: PlayQueuePlayablePreviewModel;
    context: PreviewContextModel;
}

export const AddToMyMusicContextMenu = ({ context }: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const showIcon = useContext(ContextMenuManagerContext).mode === ContextMenuMode.BottomSheet;

    return <Sheet top={<ResourceMenuTop context={context} />}>not implemented</Sheet>;
};
