import type { Ref } from "preact/hooks";
import { useEffect, useState } from "preact/hooks";
import { hasNavigated } from "services/navigationStack/navigationStack";

interface Props {
    ref: Ref<HTMLElement>;
    highlight?: boolean;
}

export function usePreviewScrollToHighlight<T extends HTMLElement>({ ref, highlight }: Props) {
    const [done, setDone] = useState(false);

    useEffect(() => {
        if (hasNavigated() || !highlight || done || !ref.current) return;
        setDone(true);
        ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, [ref, highlight, done]);
}
