import type { ComponentChildren } from "preact";
import { h } from "preact";
import { ResourceTextDesign } from ".";
import { ItemGroup } from "../itemGroup/ItemGroup";
import { ResourceLink } from "./ResourceLink";
import type { OpenMenuResult } from "services/contextMenus";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { useLinkContextsFromPreview } from "components/shared/hooks";
import type { CoverRounding, CoverSize } from "components/atoms/cover";
import { ResourceCover } from "components/atoms/cover";

interface Props {
    children?: ComponentChildren;
    disabledAction?: boolean;
    context: PreviewContextModel;
    imageContext?: PreviewContextModel;
    rounding?: CoverRounding;
    scrollingText?: boolean;
    size: CoverSize;
    disableLazyLoad: boolean;
    onContextMenu?: (ev: MouseEvent) => Promise<OpenMenuResult | undefined>;
}

export const ResourceCard = ({ context, size, rounding, children, scrollingText, disabledAction, imageContext, disableLazyLoad, onContextMenu }: Props) => {
    const linkModels = useLinkContextsFromPreview(context);
    //if (linkModels.length > 2) log.error({code: "web-210304-1638", msg: DefaultLogMessage.UnexpectedValue, [{ key: "resourceType", value: context.type }]);

    const links = linkModels.map((group, groupIndex) => {
        return (
            <ResourceLink
                links={group}
                key={groupIndex}
                design={groupIndex === 0 ? ResourceTextDesign.PrimarySmall : ResourceTextDesign.SecondarySmall}
                testLocatorName={groupIndex}
            />
        );
    });

    return (
        <ItemGroup title={links[0]} subtitle={links[1]} scrollingText={scrollingText}>
            <ResourceCover
                size={size}
                rounding={rounding}
                context={imageContext ?? context}
                onContextMenu={onContextMenu}
                disabledAction={disabledAction}
                disableLazyLoad={disableLazyLoad}>
                {children}
            </ResourceCover>
        </ItemGroup>
    );
};
