import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { getContextMenuAppSheets } from "services/contextMenus";
import { RootModel } from "models/app";

export const useContextMenus = () => {
    const menu = useSelector((root: RootModel) => root.menu);
    const open = useMemo(() => getContextMenuAppSheets(menu), [menu]);

    const visible = useSelector((root: RootModel) => root.menu.visible);
    const isOpen = open.length > 0 || visible.length > 0;

    return { open, visible, isOpen };
};
