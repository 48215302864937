import type Hls from "hls.js";
import type { HlsPreloadAudioModel } from "..";
import type { AudioInputType, PlayPausePositionModel, PlayState } from "../..";
import type { TraceModel } from "models/app";
import type { StreamUrl } from "models/app/player/input";

export enum BrowserAudioItemPreloadSize {
    Full = "Full",
    Small = "Small"
}

export enum BrowserAudioLoadState {
    None = "None",
    LoadUrl = "LoadUrl",
    Buffering = "Buffering",
    PartialDone = "PartialDone",
    Done = "Done"
}

export interface BrowserAudioItemPreload {
    hlsPreload: HlsPreloadAudioModel | null;
    index: number;
    order: number;
    size: BrowserAudioItemPreloadSize | null;
}

export interface BrowserAudioItemModel {
    audioCacheId: string;
    audioId: string;
    canPlayThrough: boolean;
    disposed: boolean;
    element: HTMLAudioElement;
    hls: Hls | null;
    hlsRetryLock: boolean;
    input: AudioInputType;
    intendedPlayState: PlayState;
    isMediaSet: boolean;
    loadNow: boolean;
    logTitle: string;
    mediaChangeLock: boolean;
    playState: PlayState;
    playTrace: TraceModel | null;
    position: PlayPausePositionModel;
    preload: BrowserAudioItemPreload;
    removeListeners: () => void;
    state: BrowserAudioLoadState;
    url: StreamUrl | null;
    urlLock: boolean;
}

// export enum BrowserAudioPlayState {
//     Playing = "playing",
//     Paused = "paused",
//     Buffering = "buffering"
// }
