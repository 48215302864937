import { addElementToPool, getElementFromPool } from "./elementPool";
import { linearToLogarithmicVolume } from "./setVolume";
import { store } from "global";

export function createAudioElement() {
    const player = store.getState().player;
    const element = getElementFromPool() ?? new Audio();

    element.volume = linearToLogarithmicVolume(player.volume);
    element.muted = player.muted;
    element.autoplay = false;

    return element;
}

export function disposeAudioElement(element: HTMLAudioElement) {
    element.pause();
    element.removeAttribute("src");
    element.load();

    addElementToPool(element);
}
