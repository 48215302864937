import { updatePlayQueue } from "./updatePlayQueue";
import { getCurrentLane, isAudioItemPlaying, movePreviewLaneToFlowLane, updateQueueLanes } from "../helpers";
import { store } from "global";
import { log } from "services/logger";
import { startPlay } from "services/player/inputs/service/actions";
import { putAttributePlayUserTrace } from "models/app";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemModel, PlayQueueModel, QueueTrackModel } from "models/app/player/input";

export const playQueuePlayTrack = async (uniqueId: string, isPreviewLane: boolean, context: AudioContextModel) => {
    let { queue } = store.getState();

    if (isPreviewLane) queue.playQueue = movePreviewLaneToFlowLane(queue);
    queue = playQueueTrack(queue, uniqueId);
    await updatePlayQueue(queue, context, false, null);
    startPlay(uniqueId, context);
};

const playQueueTrack = (queue: PlayQueueModel, queueId: string) => {
    let playQueue: QueueTrackModel[] = queue.playQueue;

    const newCurrentIndex = playQueue.findIndex((item) => item.queueId === queueId);
    if (newCurrentIndex === -1) {
        log.error({ code: "web-210519-1154", msg: "newCurrentIndex === -1" });
        return queue;
    }
    playQueue = updateQueueLanes(playQueue, newCurrentIndex);
    return { ...queue, playQueue };
};

export async function tryStartPlayCurrentLane(queue: PlayQueueModel, audios: AudioInputItemModel[], context: AudioContextModel) {
    const currentLane = getCurrentLane(queue.playQueue);
    if (!currentLane) {
        log.error({ code: "web-210218-1129", msg: "nothing in currentLane" });
        return;
    }

    if (isAudioItemPlaying(audios, currentLane.queueId)) return;
    const { title, id } = currentLane.track;

    if (context.trace) putAttributePlayUserTrace(context.trace, { type: "title_trackId", value: `${title} | ${id}` });
    await startPlay(currentLane.queueId, context);
}
