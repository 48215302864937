import { translate } from "global/config";
import { SortOption } from "models/app/SortOption";

export function getSortOptionName(sort: SortOption): string {
    switch (sort) {
        case SortOption.Name:
            return translate("SortByNameTitle");
        case SortOption.Popularity:
            return translate("SortByPopularity");
        case SortOption.ReleaseDate:
            return translate("SortByReleaseYear");
        case SortOption.UserDefined:
            return translate("SortByUserDefined");
        case SortOption.ArtistName:
            return translate("SortByArtist");
        case SortOption.LatestAdded:
            return translate("SortByLastAdded");
    }
}
