import { h } from "preact";
import "./DropPin.scss";

interface Props {
    hover: boolean;
    customClass?: string;
}
export const DropPin = ({ hover, customClass }: Props) => {
    return <div className={`dropPin GtR --hover-${hover} ${customClass ?? ""}`} />;
};
