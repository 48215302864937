import type { LayoutItemContentModel, TracksContent } from "./LayoutItemContentModel";
import { createLayoutPageItemTracksSectionModel, createLayoutItemContentModel } from "./LayoutItemContentModel";
import type { ResourceModelInterface, LocationContentType, PlayQueuePlayableModelInterface } from "..";
import { ResourceDisplayType } from "..";
import { log, DefaultLogMessage } from "services/logger/initLoggerService";
import type { LayoutItemSectionFragment, LayoutItemPageFragment, LayoutItemTracksPlayFragment } from "generated/graphql-types";
import { PageItemDisplayType } from "generated/graphql-types";
import { DomainModelType, ContentType } from "models/ModelType";

export type LayoutItemModel = LayoutItemSectionModel | LayoutItemPageModel | TracksLayoutItemPlayModel;

interface Item extends ResourceModelInterface {
    readonly name: string | null;
    contentType: ContentType.LayoutItem;
    readonly id: string;
    readonly pageId: string;
    domainItemType: LocationContentType | null;
}

export interface LayoutItemSectionModel extends Item {
    type: DomainModelType.Section;
    displayType: ResourceDisplayType;
    refreshFrequency: number | null;
    content: LayoutItemContentModel | null;
}

export interface TracksLayoutItemSectionModel extends LayoutItemSectionModel, PlayQueuePlayableModelInterface {
    content: TracksContent;
}

export interface TracksLayoutItemPlayModel extends Item, PlayQueuePlayableModelInterface {
    content: TracksContent;
    type: DomainModelType.Play;
}

export interface LayoutItemPageModel extends Item {
    type: DomainModelType.Page;
    displayType: ResourceDisplayType;
    content: LayoutItemContentModel | null;
}

function createItem(data: LayoutItemSectionFragment | LayoutItemPageFragment | LayoutItemTracksPlayFragment, pageId: string): Item {
    return {
        graphType: data.__typename,
        contentType: ContentType.LayoutItem,
        cover: null,
        id: data.id,
        domainItemType: getDomainItemType(data),
        name: data.title ?? null,
        pageId
    };
}

export function createLayoutItemSkeletonSectionModel(data: LayoutItemSectionFragment, pageId: string): LayoutItemSectionModel {
    return {
        ...createItem(data, pageId),
        type: DomainModelType.Section,
        displayType: data?.displayType ? getResourceDisplayTypeFromItemDisplayType(data.displayType) : ResourceDisplayType.Unknown,
        refreshFrequency: data.refreshFrequency ?? null,
        content: null
    };
}

export function createTracksLayoutItemPlayModel(data: LayoutItemTracksPlayFragment, pageId: string): TracksLayoutItemPlayModel | null {
    if (data.content?.__typename !== "TracksLayoutItemContent") return null;

    return {
        ...createItem(data, pageId),
        type: DomainModelType.Play,
        contentType: ContentType.LayoutItem,
        content: createLayoutPageItemTracksSectionModel(data.content), //TODO: v2.
        trackCount: null
    };
}

export function createLayoutItemPageModel(data: LayoutItemPageFragment, pageId: string, displayType?: ResourceDisplayType): LayoutItemPageModel {
    const content = data.content ? createLayoutItemContentModel(data.content, pageId, data.id) : null;
    return {
        ...createItem(data, pageId),
        type: DomainModelType.Page,
        contentType: ContentType.LayoutItem,
        displayType: displayType ?? getDisplayType(content?.contentType ?? null),
        content
    };
}
const getDisplayType = (type: NonNullable<LayoutItemSectionModel["content"]>["contentType"] | null) => {
    switch (type) {
        case ContentType.AlbumsLayoutItemContent:
        case ContentType.ArtistsLayoutItemContent:
        case ContentType.ChartsLayoutItemContent:
        case ContentType.LiveRadioCategoriesLayoutItemContent:
        case ContentType.LiveRadiosLayoutItemContent:
        case ContentType.MixRadiosLayoutItemContent:
        case ContentType.MixedContentLayoutItemContent:
        case ContentType.PlaylistCategoriesLayoutItemContent:
        case ContentType.PlaylistsLayoutItemContent:
        case ContentType.RecommendationsLayoutItemContent:
        case ContentType.BannerLayoutItemContent: // todo?
        case null:
            return ResourceDisplayType.Grid;
        case ContentType.TracksLayoutItemContent:
            return ResourceDisplayType.List;
    }
};

function getResourceDisplayTypeFromItemDisplayType(displayType: PageItemDisplayType | null): ResourceDisplayType {
    switch (displayType) {
        case PageItemDisplayType.Scroller:
            return ResourceDisplayType.Scroller;
        case PageItemDisplayType.Grid:
            return ResourceDisplayType.Grid;
        case PageItemDisplayType.LargeScroller:
            return ResourceDisplayType.LargeScroller;
        case PageItemDisplayType.List:
            return ResourceDisplayType.List;
        case PageItemDisplayType.Single:
            return ResourceDisplayType.Single;
        case PageItemDisplayType.LargeSingle:
            return ResourceDisplayType.LargeSingle;
        case PageItemDisplayType.PromoSingle:
            return ResourceDisplayType.PromoSingle;
        default:
            log.warn({ code: "web-210505-1946", msg: DefaultLogMessage.NotImplemented, data: { displayType } });
            return ResourceDisplayType.Unknown;
    }
}

function getDomainItemType(item: LayoutItemSectionFragment | LayoutItemPageFragment | LayoutItemTracksPlayFragment): LocationContentType | null {
    switch (item.content?.__typename) {
        case "AlbumsLayoutItemContent":
            return ContentType.Album;
        case "ArtistsLayoutItemContent":
            return ContentType.Artist;
        case "ChartsLayoutItemContent":
            return ContentType.Chart;
        case "LiveRadiosLayoutItemContent":
            return ContentType.LiveRadioPlayable;
        case "LiveRadioCategoriesLayoutItemContent":
            return ContentType.LiveRadioCategory;
        case "MixedContentLayoutItemContent":
            return ContentType.MixedContent;
        case "PlaylistCategoriesLayoutItemContent":
            return ContentType.PlaylistCategory;
        case "PlaylistsLayoutItemContent":
            return ContentType.Playlist;
        case "MixRadiosLayoutItemContent":
            return ContentType.MixRadio;
        case "TracksLayoutItemContent":
            return ContentType.TrackPlayable;
        case "RecommendationsLayoutItemContent":
            return ContentType.Recommendation;
        case "BannerContentLayoutItemContent":
            return ContentType.FrontPage; // todo: is this right?
        default:
            return null;
    }
}
