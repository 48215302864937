import type { AlbumPreviewModel } from ".";
import { createAlbumPreviewModel } from "./AlbumModel";
import type { ArtistPreviewModel } from "./ArtistModel";
import { createArtistPreviewModel } from "./ArtistModel";
import type { PlaylistPreviewModel } from "./PlaylistModel";
import { createTrackPlayablePreviewModelFromSearchTrackResult } from "./SearchTrackResultModel";
import type { TrackPlayablePreviewModel } from "./TrackPlayableModel";
import type { MixedSearchResultConnectionFragment } from "generated/graphql-types";

type SearchMixedResultPreviewData = NonNullable<MixedSearchResultConnectionFragment["items"]>[number];

export type SearchMixedResultModel = SearchMixedResultPreviewModel;
export type SearchMixedResultPreviewModel = TrackPlayablePreviewModel | AlbumPreviewModel | ArtistPreviewModel | PlaylistPreviewModel;

export function createSearchMixedResultPreviewModel(data: SearchMixedResultPreviewData): SearchMixedResultPreviewModel {
    switch (data.__typename) {
        case "AlbumMixedSearchResult":
            return createAlbumPreviewModel(data.album);
        case "ArtistMixedSearchResult":
            return createArtistPreviewModel(data.artist);
        case "TrackMixedSearchResult": {
            return createTrackPlayablePreviewModelFromSearchTrackResult(data.track);
        }
    }
}
