import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { getNotificationsByPeriod, NotificationPeriod } from "./helpers";
import type { RootModel } from "models/app";
import type { NotificationPreviewModel } from "models/domain/NotificationModel";

function useNotificationsByPeriod(notifications: NotificationPreviewModel[], period: NotificationPeriod) {
    const periodNotifications = useMemo(() => getNotificationsByPeriod(notifications, period), [notifications, period]);
    return periodNotifications;
}

export function useCurrentNotifications(log = true) {
    // todo: this ought to share the getCurrentNotifications() from helpers

    const feed = useSelector((root: RootModel) => root.notificationFeed);
    const allNotifications = feed.notifications;

    const newNotifications = useNotificationsByPeriod(allNotifications, NotificationPeriod.New);
    const lastWeeksNotifications = useNotificationsByPeriod(allNotifications, NotificationPeriod.LastWeek);
    const thisMonthsNotifications = useNotificationsByPeriod(allNotifications, NotificationPeriod.ThisMonth);
    const lastMonthsNotifications = useNotificationsByPeriod(allNotifications, NotificationPeriod.LastMonth);
    const previousNotifications = useNotificationsByPeriod(allNotifications, NotificationPeriod.Previous);

    const currentNotifications = useMemo(() => [...newNotifications, ...lastWeeksNotifications, ...thisMonthsNotifications, ...lastMonthsNotifications], [newNotifications, lastWeeksNotifications, thisMonthsNotifications, lastMonthsNotifications]);

    return {
        feed,
        allNotifications,
        currentNotifications,
        newNotifications,
        lastWeeksNotifications,
        thisMonthsNotifications,
        lastMonthsNotifications,
        previousNotifications
    };
}

export function useCurrentUnseenNotificationsExist(log: boolean): boolean {
    const feed = useSelector((root: RootModel) => root.notificationFeed);
    return (feed?.notSeenCount ?? 0) > 0;
}
