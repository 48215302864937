export function CustomResizeObserver(target: HTMLElement | null, callbackFn: (height: number, width: number) => void) {
    if (!target) {
        return null;
    }
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        // requestAnimationFrame workaround to prevent "loop limit exceeded" issues
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        window.requestAnimationFrame(() => {
            entries.forEach((entry) => {
                if (!entry || entry.target !== target) return;
                const { height, width } = entry.contentRect;
                callbackFn(height, width);
            });
        });
    });

    resizeObserver.observe(target);

    return () => {
        resizeObserver.disconnect();
    };
}
