import { useEffect, useState } from "preact/hooks";

export function useOnline(onChange?: (online: boolean) => void) {
    const [online, setOnline] = useState(true);

    useEffect(() => {
        setOnline(window.navigator.onLine);

        const setTrue = () => {
            setOnline(true);
            onChange && onChange(true);
        };

        const setFalse = () => {
            setOnline(false);
            onChange && onChange(false);
        };

        window.addEventListener("online", setTrue, { passive: true });
        window.addEventListener("offline", setFalse, { passive: true });

        return () => {
            window.removeEventListener("online", setTrue);
            window.removeEventListener("offline", setFalse);
        };
    }, [onChange]);
    return online;
}
