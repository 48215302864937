import { ScrollerEffect } from "../../options";

export const getEffectAjust = (effect: ScrollerEffect) => {
    switch (effect) {
        case ScrollerEffect.None:
            return 1;
        case ScrollerEffect.ActiveGrow:
            return 275 / 227;
    }
};
