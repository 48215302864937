import type { ComponentChildren } from "preact";
import { h } from "preact";
import { useState } from "preact/hooks";
import "./Accordion.scss";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { Icon, IconName, IconSize } from "components/atoms/icon";

interface Props {
    onClick: () => void;
    title: string;
    icon: IconName;
}
export const AccordionLink = ({ onClick, title, icon }: Props) => {
    return (
        <div className={`accordion LsQ`}>
            <Button onClick={onClick} icon={icon} design={ButtonDesign.SideBarLinkBig} inline={{ left: true }}>
                <div className="title">{title}</div>
                <Icon className={`--open-${open}`} icon={IconName.ArrowRight} size={IconSize.Default} />
            </Button>
        </div>
    );
};
