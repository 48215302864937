import { useEffect } from "preact/hooks";
import { dispatch } from "global";
import { NOTIFICATION_BANNER_TOGGLE } from "global/actions";
import { BannerWarning } from "models/app";
import { useOnline } from "components/shared/hooks/useOnline";

export function useToggleNoConnectionBanner() {
    const toggleNoConnectionBanner = (show: boolean) => dispatch({ type: NOTIFICATION_BANNER_TOGGLE, payload: { warning: BannerWarning.NoConnection, show } });

    const online = useOnline();
    useEffect(() => {
        toggleNoConnectionBanner(!online);
    }, [online]);
}
