import { navigateToDelegatedLogin, showDelegatedLoginModal, openDelegatedLoginWindow } from "./delegatedLogin/DelegatedLogin";
import { dispatch } from "global";
import { USER_LOGIN_ERROR } from "global/actions";
import { environment, LoginMode } from "services/environment";
import { log, LogTag } from "services/logger";
import { establishSessionFromLogin, getIsLoggedInFromState } from "services/user";

export async function promptLoginIfRequired() {
    if (await getIsLoggedInFromState()) return true;

    try {
        await showLoginUi();
    } catch (e) {
        log.info({ code: "web-210721-1919", msg: "user closed the loin modal" });
    }
    return await getIsLoggedInFromState();
}

export function showLoginUi() {
    switch (environment?.loginMode) {
        default:
        case LoginMode.OauthPopup:
            return openDelegatedLoginWindow();
        case LoginMode.OauthEmbedded:
            return showDelegatedLoginModal();
        case LoginMode.OauthNavigation:
            return navigateToDelegatedLogin();
    }
}

export function initDelegatedLoginListener() {
    window.addEventListener(
        "message",
        async (event) => {
            //const delegatedLoginOrigin = `${ document.location.protocol }//${ document.location.host }`; // todo: consider supporting the page on a different domain
            if (event && event.data && event.data.type == "tokens" /* && event.origin === delegatedLoginOrigin */) {
                log.info({ code: "web-220926-1437", msg: "received tokens from delegated login", data: { event }, tags: [LogTag.User] });
                await establishSessionFromLogin(event.data.refreshToken, event.data.accessToken);
            }

            if (event && event.data && event.data.type == "delegatedloginerror") {
                log.info({ code: "web-220926-1438", msg: "received error from delegated login", data: { event }, tags: [LogTag.User] });
                dispatch({
                    type: USER_LOGIN_ERROR,
                    payload: {
                        error: event.data.errorcode
                    }
                });
            }
        },
        false
    );
}
