import { ComponentChildren, h } from "preact";
import "./ControlGroup.scss";

interface Props {
    title?: string;
    children: ComponentChildren;
    className?: string;
}

export const ControlGroup = ({ title, children, className }: Props) => {
    
    return (
        <section className={`controlGroup ${className ?? ""}`}>
            {title && <h1>{title}</h1>}
            <div>{children}</div>
        </section>
    );
};
