import { getAnalyticsQueueActionEvent } from "../events/queueAction";
import type { QueueActionType } from "../properties/event/queueActionProperties";
import { dispatch } from "global";
import { LOGGING_EVENT_ANALYTICS } from "global/actions";
import { getDesktopQueueOpen } from "components/shared/hooks";

export async function logQueueActionEvent(type: QueueActionType, queueOpen?: boolean) {
    if (queueOpen == undefined) queueOpen = getDesktopQueueOpen();
    const data = await getAnalyticsQueueActionEvent(type, queueOpen);
    dispatch({ type: LOGGING_EVENT_ANALYTICS, payload: { name: "QueueAction", data } });
}
