import { dispatch, store } from "global";
import { PLAYQUEUE_UPDATE } from "global/actions";
import { isEqualByPredicate } from "services/arrayHelper";
import { log, LogTag } from "services/logger";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import type { NormalizedData } from "services/normalizeData";
import { getTrackAndParentFromPlayable } from "services/playable";
import { getComparableId } from "services/resource";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { QueueLane } from "models/app/player/input";
import type { PlayQueueModel } from "models/app/player/input";
import type { TrackParentModel, PlayQueuePlayableModel } from "models/domain";

export const updatePlayQueue = async (
    queue: PlayQueueModel,
    context: AudioContextModel,
    blockEndlessPlayUpdate: boolean,
    queueAdded: NormalizedData<AnalyticsQueueAddedPropertiesGroup> | null,
    add?: PlayQueuePlayableModel[]
): Promise<PlayQueueModel> => {
    const trackParents = resolveReferencedTrackParents(queue, add ? add?.map((value) => getTrackAndParentFromPlayable(value).parent) : undefined);

    log.debug([LogTag.Playback], () => ({
        code: "web-210212-0955",
        msg: "Queue has been updated",
        data: {
            MemoryLane: queue.playQueue.filter((item) => item.lane === QueueLane.Memory),
            CurrentLane: queue.playQueue.filter((item) => item.lane === QueueLane.Current),
            QueueLane: queue.playQueue.filter((item) => item.lane === QueueLane.Priority),
            FlowLane: queue.playQueue.filter((item) => item.lane === QueueLane.Flow),
            TrackParents: queue.trackParents.length
        }
    }));

    await dispatch({
        type: PLAYQUEUE_UPDATE,
        payload: {
            playQueue: queue.playQueue,
            endlessPlay: queue.endlessPlay,
            trackParent: queue.trackParent,
            trackParents,
            context,
            blockEndlessPlayUpdate,
            queueAdded
        }
    });

    return store.getState().queue;
};

const resolveReferencedCollection = <T>(referenceIds: string[], collection: T[], referenceIdFn: (value: T) => string, add?: T[]): T[] => {
    const map = new Map<string, T>(collection.map((value) => [referenceIdFn(value), value]));
    if (add) add.forEach((value) => map.set(referenceIdFn(value), value));
    const newCollection = [...map.values()].filter((value) => referenceIds.indexOf(referenceIdFn(value)) !== -1);

    if (isEqualByPredicate(collection, newCollection, (a, b) => referenceIdFn(a) === referenceIdFn(b))) return collection;
    return newCollection;
};

const resolveReferencedTrackParents = (queue: PlayQueueModel, add?: TrackParentModel[]): TrackParentModel[] => {
    const trackParents = queue.trackParents;
    const trackParentReferences = [...queue.playQueue.map((value) => value.parentId), ...queue.endlessPlay.map((value) => value.parentId)];
    const newTrackParents = resolveReferencedCollection(trackParentReferences, trackParents, (value) => getComparableId(value), add);

    if (newTrackParents === trackParents) return trackParents;
    return newTrackParents;
};
