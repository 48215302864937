/* eslint-disable @typescript-eslint/no-explicit-any */

import { composeWithDevTools } from "@redux-devtools/extension";
import type { Dispatch, Store } from "redux";
import { createStore, applyMiddleware } from "redux";
import { getConfiguredCache } from "./dbService";
import { persistMiddleware, saveCurrentState } from "./persistMiddleWare";
import type { Action } from "../actions";
import { REHYDRATE } from "global/actions";
import { rootReducer } from "global/reducers";
import { getSessionDbKey } from "services/appSession/helpers";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { createMessageBusMiddleware, TypedMessageBus } from "services/messagebus";
import type { RootModel } from "models/app";

export const messageBus = new TypedMessageBus<Action>();
export const preLoadedStore: Store<RootModel> | null = null;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export let store: Store<RootModel> = null!;
const blacklist = ["menu", "modal", "cache"];

export async function initStoreAndPersist(retry = true) {
    try {
        store = await createNewStore();
    } catch (e) {
        log.error({ code: "web-220217-1331", msg: "redux store could not initiate", error: e });
        if (retry) {
            await deleteSessionDbState();
            initStoreAndPersist(false);
        } else throw e;
    }
}

export async function unloadStoreAndPersist() {
    saveSessionState("beforeUnload");
}

export async function createNewStore() {
    const cache = getSessionCache();
    const data = await cache.getAll();
    const state = convertDbKeyValuesToState(data) as unknown as RootModel;

    const newStore = createStore(
        rootReducer,
        state as unknown as any,
        composeWithDevTools(applyMiddleware(persistMiddleware(cache.setMany, blacklist), createMessageBusMiddleware(messageBus)))
    );

    const storeDispatch = newStore.dispatch;
    newStore.dispatch = function (action: Action) {
        try {
            storeDispatch(action);
        } catch (e) {
            window.setTimeout(() => log.error({ code: "web-210217-1436", msg: "dispatch error", data: { action }, error: e }), 0);
        }
    } as Dispatch<Action>;

    await new Promise<void>((resolve) => {
        window.setTimeout(() => {
            newStore.dispatch({ type: REHYDRATE, payload: state });
            resolve();
        }, 0);
    });

    return newStore;
}

export async function saveSessionState(initiator: string) {
    const cache = getSessionCache();
    await saveCurrentState(cache.setMany, blacklist, initiator);
}

export async function copyDbState(fromDatabaseName: string, toDatabaseName: string) {
    const from = getConfiguredCache(fromDatabaseName);
    const to = getConfiguredCache(toDatabaseName);

    const data = await from.getAll();
    await to.setMany(data);
}

export async function deleteDbState(databaseName: string) {
    const cache = getConfiguredCache(databaseName);
    await cache.deleteDatabase();
}

export async function deleteSessionDbState() {
    const databaseName = getSessionDbKey();

    const cache = getConfiguredCache(databaseName);
    await cache.deleteDatabase();
}

function convertDbKeyValuesToState(keyValues: [IDBValidKey, unknown][]): Record<string, unknown> {
    const object: Record<string, unknown> = {};
    keyValues.forEach((keyValues) => {
        const [key, value] = keyValues;
        if (typeof key !== "string") {
            log.error({ code: "web-211129-1705", msg: DefaultLogMessage.UnexpectedValue });
            return;
        }
        object[key] = value;
    });
    return object;
}

function getSessionCache() {
    const databaseName = getSessionDbKey();
    return getConfiguredCache(databaseName);
}
