import { route } from "preact-router";
import { dispatch, messageBus } from "global";
import { NAVIGATION_NAVIGATED, NAVIGATION_PAGECONTEXT_UPDATE } from "global/actions";
import type { NavigatePageContextUpdateAction } from "global/actions/actionTypes";
import { translate } from "global/config";
import { throttle2 } from "services/cancellation/throttle";
import { DefaultLogMessage, log, LogTag } from "services/logger";
import { getLink, getTitles } from "services/resource";
import type { LinkContextModel, PageContextModel, PreviewContextModel, SectionContextModel } from "models/app/resourceContextModel";
import { createLinkContext } from "models/app/resourceContextModel";
import type { LocationContentType, ResourceModel } from "models/domain";
import { ContentType } from "models/ModelType";
import type {
    NavigationLinkModel,
    NavigationModel,
    NavigationPageModel,
    NavigationPreviewModel,
    NavigationSectionModel,
    NavigationStackModel,
    ScrollStateModel
} from "models/view/navigationStack";

let stack: NavigationStackModel = {
    navigations: [],
    hasForward: false,
    hasBackward: false
};

let activePage: PageContextModel | null = null;

export function initNavigationStack() {
    const pageContextDoneTimeout = setTimeout(() => {
        log.error({
            code: "web-220817-1516",
            msg: "page context not done loading",
            data: {
                "page type": activePage?.type,
                "page id": activePage?.resource?.id
            }
        });
    }, 10_000);

    messageBus.subscribeEvery(NAVIGATION_PAGECONTEXT_UPDATE, (msg) => {
        if (msg.payload.done) {
            clearTimeout(pageContextDoneTimeout);
        }
        setActivePageContext(msg.payload.context);
    });

    try {
        if ("scrollRestoration" in history) {
            history.scrollRestoration = "manual";
        }

        readStack();
        logStack();

        window.addEventListener("popstate", () => {
            const count1 = stack.navigations.length;
            readStack();
            const count2 = stack.navigations.length;

            const hasBackward = count2 > 1;
            const hasForward = count1 > count2;

            if (hasForward) stack.hasForward = hasForward;
            if (hasBackward) stack.hasBackward = hasBackward;

            saveStack();
            logStack();

            dispatch({
                type: NAVIGATION_NAVIGATED,
                payload: {
                    state: stack.navigations[0],
                    hasForward,
                    hasBackward
                }
            });
        });
    } catch (e) {
        console.error({ code: "web-220217-1314", msg: "navigation stack could not initiate", e });
    }
}

export function hasNavigated(): boolean {
    return stack.hasBackward || stack.hasForward;
}

function setActivePageContext(page: PageContextModel) {
    const current = stack.navigations[0];
    current.page = createNavigationPage(page);

    saveStack();
    logStack();

    activePage = page;
}

export function getActivePageContext() {
    return activePage;
}

export function getPreviousPageContext() {
    return stack.navigations.length > 1 ? stack.navigations[1].page : null;
}

export function isOnPage(location: LocationContentType, resource?: ResourceModel): boolean {
    if (activePage == null) {
        log.error({ code: "web-220226-1152", msg: "no active page in navigationStack" });
        return false;
    }

    if (resource && activePage.resource?.id == null) {
        log.error({ code: "web-220226-1153", msg: "no active page resource in navigationStack", data: { location } });
        return false;
    }

    if (activePage.type != location) return false;
    if (resource && resource.id != activePage.resource?.id) return false;

    return true;
}

let updateActivePageContextTimeoutId: number | null;
export function updateActivePageContext(page: PageContextModel) {
    //TODO: refactor to pr page instead of item
    const update = () => {
        if (activePage !== page) return;
        setActivePageContext(page);
    };

    if (updateActivePageContextTimeoutId != null) {
        clearTimeout(updateActivePageContextTimeoutId);
        updateActivePageContextTimeoutId = null;
    }

    updateActivePageContextTimeoutId = window.setTimeout(update, 100);
}

export function navigateForwardTo(type: LocationContentType, resource: ResourceModel | null, preview: PreviewContextModel) {
    const link = createLinkContext(type, resource, preview);
    navigateForward(link);
}

export function navigateForward(link: LinkContextModel) {
    if (link.url == null) {
        log.error({ code: "web-210926-1647", msg: DefaultLogMessage.UnexpectedNull });
        return;
    }

    if (link.url == window.location.pathname) {
        log.info({ code: "web-211107-2004", msg: "navigated to same page" });
        return;
    }

    if (link.target) {
        window.open(link.url, link.target)?.focus();
        return;
    }

    route(link.url);

    const navigation = createForwardNavigation(link);
    activePage = null;

    if (!navigation) return;

    stack.navigations.unshift(navigation);
    stack.hasForward = false;
    stack.hasBackward = true;

    saveStack();
    logStack();

    dispatch({
        type: NAVIGATION_NAVIGATED,
        payload: {
            state: navigation,
            hasForward: false,
            hasBackward: true
        }
    });
}

function createNavigationPage(page: PageContextModel): NavigationPageModel {
    return {
        id: page.resource?.id ?? "",
        name: getLink(page.type, page.resource)?.text ?? null,
        windowTitle: getPageTitle(page),
        root: page.root,
        sections: page.sections.map((section) => createNavigationSection(section)),
        type: page.type
    };
}

function createNavigationSection(section: SectionContextModel): NavigationSectionModel {
    return {
        visibleRank: section.visibleRank,
        displayType: section.displayType,
        id: section.id,
        isHidden: section.isHidden,
        name: getLink(section.type, section.resource)?.text ?? null,
        rank: section.rank,
        type: section.type
    };
}

function createNavigationPreview(preview: PreviewContextModel): NavigationPreviewModel {
    return {
        id: preview.resource?.id ?? null,
        type: preview.type,
        name: getLink(preview.type, preview.resource)?.text ?? null,
        rank: preview.rank
    };
}

function createNavigationLink(link: LinkContextModel): NavigationLinkModel {
    return {
        id: link.resource?.id ?? null,
        type: link.type,
        name: link.type ? getLink(link.type, link.resource)?.text ?? null : null
    };
}

function createForwardNavigation(link: LinkContextModel): NavigationModel | null {
    const preview = link?.preview;
    const section = preview?.section;
    const page = section?.page ?? activePage;

    if (!page || !section || !preview) {
        log.error({ code: "web-211229-1313", msg: "page, section or preview is null", data: { page, section, preview } });
        return null;
    }

    return {
        page: null,
        from: {
            page: createNavigationPage(page),
            section: createNavigationSection(section),
            preview: createNavigationPreview(preview),
            link: createNavigationLink(link)
        }
    };
}

function readStack() {
    if (window.history.state) {
        stack = JSON.parse(JSON.stringify(window.history.state));
    }

    if (stack.navigations.length === 0) {
        stack.navigations[0] = { page: null, from: null };
        history.replaceState(stack, "");
    }
}

function saveStack() {
    try {
        const title = getCurrentPageTitle();

        if (title && title != document.title) document.title = title;
        history.replaceState(stack, "");
    } catch (e) {
        log.error({ code: "web-210924-1904", msg: "saveStack fail", tags: [LogTag.NavigationStack], error: e });
        resetStack();
    }
}

function resetStack() {
    stack = {
        navigations: [],
        hasForward: false,
        hasBackward: false
    };

    try {
        history.replaceState(stack, "");
    } catch (e) {
        log.error({ code: "web-210924-1906", msg: "resetStack fail", tags: [LogTag.NavigationStack], error: e });
    }
}

export function getStack() {
    return stack;
}

function logStack() {
    //  console.log("code:", "web-220927-1053", "navigationStack", { stack });
}

export function getNavigationRoot(): NavigationModel | null {
    const root = stack.navigations.find((navigation) => navigation?.page?.root != null) ?? null;
    return root;
}

export function getNavigationRootPageAncestryLevel(): number | null {
    const root = getNavigationRoot();
    if (!root) return null;

    const rootIndex = stack.navigations.indexOf(root);
    if (rootIndex === -1) return null;
    return rootIndex;
}

function getNavigationRootNextPage(): NavigationModel | null {
    const root = stack.navigations.find((navigation) => navigation.from?.page?.root !== null) ?? null;
    return root;
}

export function getNavigationRootPreview(current?: PreviewContextModel): { page: NavigationPageModel; section: NavigationSectionModel; preview: NavigationPreviewModel } | null {
    if (current?.section.page?.root != null) {
        return {
            page: createNavigationPage(current.section.page),
            section: createNavigationSection(current.section),
            preview: createNavigationPreview(current)
        };
    }

    const next = getNavigationRootNextPage();
    if (!next) return null;

    const page = next.from?.page ?? null;
    const section = next.from?.section ?? null;
    const preview = next.from?.preview ?? null;

    if (page && section && preview) return { page, section, preview };
    return null;
}

function getCurrentPageTitle(): string | null {
    const page = stack.navigations[0].page;
    if (!page) return null;

    return page.windowTitle;
}

function getPageTitle(page: PageContextModel): string {
    const appName = translate("AppName");
    if (page.type === ContentType.FrontPage) return appName;

    const { title, subtitle } = getTitles(page.type, page.resource);

    let pageTitle = appName;
    if (title) pageTitle += ` - ${title}`;
    if (subtitle) pageTitle += ` - ${subtitle}`;

    return pageTitle;
}

const debouncePageScroll = throttle2();
const debounceSectionScroll = throttle2();

export async function savePageScrollState(state: ScrollStateModel) {
    await debouncePageScroll(200);

    const navigation = stack.navigations[0];
    if (!navigation) return;
    if (!navigation.scrollStates) navigation.scrollStates = {};

    navigation.scrollStates["page"] = formatScrollState(state);

    saveStack();
}

export async function saveSectionScrollState(section: SectionContextModel, state: ScrollStateModel) {
    await debounceSectionScroll(200);

    const id = section.scrollId;
    if (!id) {
        log.error({ code: "web-220318-1013", msg: "no scroll id on section in save" });
        return;
    }

    const navigation = stack.navigations[0];
    if (!navigation) return;
    if (!navigation.scrollStates) navigation.scrollStates = {};

    navigation.scrollStates[id] = formatScrollState(state);

    saveStack();
}

export function readPageScrollState(): ScrollStateModel | null {
    const states = stack.navigations[0]?.scrollStates;
    if (!states) return null;

    const state = states["page"] ?? null;
    if (!state) return null;

    return state;
}

export function readSectionScrollState(section: SectionContextModel): ScrollStateModel | null {
    const id = section.scrollId;

    if (!id) {
        return null;
    }

    const states = stack.navigations[0]?.scrollStates;
    if (!states) return null;

    const state = states[id] ?? null;
    if (!state) return null;

    return state;
}

function formatScrollState(state: ScrollStateModel): ScrollStateModel | null {
    if (state.x != null && state.x < 1) state.x = null;
    if (state.y != null && state.y < 1) state.y = null;
    if (state.x == null && state.y == null) return null;
    return state;
}

export function onPageContextReady(fn: (payload: NavigatePageContextUpdateAction["payload"]) => void) {
    const send = (unsubscribe: boolean, payload: NavigatePageContextUpdateAction["payload"]) => {
        unsubscribe && unsubscribeFn();
        fn(payload);
    };

    const timeout = setTimeout(() => {
        log.error({ code: "web-220817-1548", msg: "page context was not ready" });
    }, 10_000);

    const unsubscribeFn = messageBus.subscribeEvery(NAVIGATION_PAGECONTEXT_UPDATE, (msg) => {
        if (!msg.payload.done) return;

        clearTimeout(timeout);
        send(!msg.payload.error, msg.payload);
    });
}
