import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "../Link";
import { useTranslations } from "global/config";
import type { PlaylistPageModel, PlaylistPreviewModel } from "models/domain";
import { IconName } from "components/atoms/icon";
import { showRenamePlaylistModal } from "components/organisms/modal/modals/RenamePlaylistModal";

interface Props {
    playable: PlaylistPreviewModel | PlaylistPageModel | null;
    showIcon: boolean;
}

export const PlaylistRenameLink = ({ showIcon, playable }: Props) => {
    const callback = useCallback(() => playable && showRenamePlaylistModal(playable), [playable]);
    const translations = useTranslations();
    if (playable && !playable.owner) return null;

    const icon = showIcon ? IconName.RenamePlaylist : undefined;
    return (
        <Link icon={icon} disabled={!playable} closeOnClick onClick={callback}>
            {translations.ContextOptionRenamePlaylist}
        </Link>
    );
};
