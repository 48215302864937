import { useState, useEffect } from "preact/hooks";
import { PlayState } from "models/app/player";
import { useAudioProperties } from "components/shared/hooks";

export enum SpinnerState {
    None = "none",
    In = "in",
    Out = "out"
}

export enum SpinnerSize {
    Size24 = "Size24",
    Size48 = "Size48"
}

export const useBufferingSpinner = () => {
    const should = useAudioProperties().playState === PlayState.Buffering;
    const [state, setState] = useState<SpinnerState>(SpinnerState.None);

    useEffect(() => {
        if (!should && state === SpinnerState.None) return;
        if (should && state === SpinnerState.In) return;

        let timeOutId: number | null = null;
        if (should && state === SpinnerState.None) {
            timeOutId = window.setTimeout(() => {
                timeOutId = null;
                setState(SpinnerState.In);
            }, 2000); // todo replace with standardized animation constants
        }

        if (!should && state === SpinnerState.In) {
            timeOutId = null;
            setState(SpinnerState.Out);
            timeOutId = window.setTimeout(() => {
                setState(SpinnerState.None);
            }, 400); // todo replace with standardized animation constants
        }

        return () => {
            timeOutId != null && window.clearTimeout(timeOutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [should, state]);

    return state;
};
