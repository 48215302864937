import { h } from "preact";
import { useSelector } from "react-redux";
import { Link } from "..";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL, PLAYQUEUE_ADD } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import type { Multiselect } from "services/selection";
import { getMultiselectPlayModel } from "services/selection";
import type { RootModel } from "models/app";
import { createAndStartUserPlayTraceModel, TraceName } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import { QueueLane } from "models/app/player/input";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { IconName } from "components/atoms/icon";

interface Props {
    multiselect: Multiselect;
    context: PreviewContextModel;
    showIcon: boolean;
}

export const AddMultipleToQueueLink = ({ multiselect, context, showIcon }: Props) => {
    const translations = useTranslations();
    const addToQueue = async () => {
        const playable = getMultiselectPlayModel(multiselect);
        const trace = await createAndStartUserPlayTraceModel(TraceName.ClickToSound);
        dispatch({ type: PLAYQUEUE_ADD, payload: { playable, playableContext: context, context: { action: AudioContextAction.UserContextMenuAddToQueue, trace } } });
        dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    };
    const playQueue = useSelector((root: RootModel) => root.queue.playQueue);
    const isPriorityLaneEmpty = playQueue.findIndex((item) => item.lane === QueueLane.Priority) === -1;

    return (
        <Link icon={showIcon ? IconName.PlayAsNext : undefined} onClick={addToQueue}>
            {isPriorityLaneEmpty ? translations.QueuePlayAsNext : translations.QueuePlayAsPriority}
        </Link>
    );
};
