import { dispatch, messageBus } from "global";
import { AUDIO_OUTPUT_AUDIO_ENDED, AUDIO_OUTPUT_AUDIO_PLAY_CHANGE } from "global/actions";
import { log, LogTag } from "services/logger";
import { getPositionFromCurrentTime } from "services/player/helpers";
import { getAudioByIdFromState } from "services/player/inputs/service/helpers";
import { putAttributePlayUserTrace, stopPlayUserTrace } from "models/app";
import { PlayState } from "models/app/player";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { BrowserAudioItemModel } from "models/app/player/output";

export enum MediaChangeEvent {
    Pause = "Pause",
    Playing = "Playing",
    Prevented = "Prevented",
    Seeked = "Seeked",
    Seeking = "Seeking",
    Waiting = "Waiting"
}

export const audioMediaChange = async (browserAudio: BrowserAudioItemModel, change: MediaChangeEvent) => {
    if (browserAudio.disposed) return;
    if (browserAudio.url == null) return;

    const { element, audioId } = browserAudio;
    if (element.ended) return;

    if (handleSongEndedAtUnexpectedDuration(browserAudio, change)) return;

    await messageBus.sync();
    const isPlaying = !element.paused && element.src != null && element.src !== "" && change !== MediaChangeEvent.Waiting && !element.seeking;

    let playState: PlayState;
    const position = getPositionFromCurrentTime(element.currentTime);

    if (isPlaying) {
        if (browserAudio.playTrace) {
            putAttributePlayUserTrace(browserAudio.playTrace, { type: "error", value: "NoPlaybackError" });
            stopPlayUserTrace(browserAudio.playTrace);
            browserAudio.playTrace = null;
        }
        playState = PlayState.Playing;
    } else {
        const newPlayState = element.paused ? PlayState.Paused : PlayState.Buffering;
        playState = newPlayState;
    }

    if (browserAudio.mediaChangeLock) return;
    browserAudio.mediaChangeLock = true;

    log.debug([LogTag.Playback], () => ({
        code: "web-210215-1630",
        msg: `audioMediaChange: ${browserAudio.logTitle}`,
        data: {
            isPlaying,
            title: browserAudio.logTitle,
            audioId: browserAudio.audioId,
            duration: element.duration,
            "currentTime (seconds played)": element.currentTime
        }
    }));

    // const playStateChange = browserAudio.playState !== playState;
    browserAudio.playState = playState;

    const audio = getAudioByIdFromState(audioId);
    if (!audio) return;

    // const positionChange = audio.input === AudioInputType.PlayQueue ? !isPlayPositionEqual(audio.position, position) : false;

    // if (playStateChange || positionChange) {
    dispatch({
        type: AUDIO_OUTPUT_AUDIO_PLAY_CHANGE,
        payload: {
            audio,
            allowPlayChange: false,
            playState,
            mediaChangeEvent: change,
            context: { action: getAudioContextAction(change), trace: null },
            position
        }
    });
    // }

    // await messageBus.sync();

    // if (change === MediaChangeEvent.Seeked) {
    //     dispatch({ type: AUDIO_OUTPUT_AUDIO_SEEK_CHANGE, payload });
    // }

    browserAudio.mediaChangeLock = false;
};

function handleSongEndedAtUnexpectedDuration(browserAudio: BrowserAudioItemModel, change: MediaChangeEvent): boolean {
    if (change !== MediaChangeEvent.Waiting) return false;
    const { audioId, element } = browserAudio;

    const { duration, currentTime } = element;
    if (Math.sign(duration) !== 1 || Math.sign(currentTime) !== 1) return false;

    const left = duration - currentTime;
    if (left > 0.5) return false;

    log.error({
        code: "web-220425-1625",
        msg: "unexpected loading very close to end of audio",
        data: {
            logTitle: browserAudio.logTitle,
            left,
            duration,
            currentTime
        }
    });

    dispatch({
        type: AUDIO_OUTPUT_AUDIO_ENDED,
        payload: {
            audioId,
            context: {
                action: AudioContextAction.BrowserAudioEnded,
                trace: null
            }
        }
    });

    return true;
}

function getAudioContextAction(event: MediaChangeEvent): AudioContextAction {
    switch (event) {
        case MediaChangeEvent.Pause:
            return AudioContextAction.BrowserAudioPause;
        case MediaChangeEvent.Playing:
            return AudioContextAction.BrowserAudioPlaying;
        case MediaChangeEvent.Seeked:
            return AudioContextAction.BrowserAudioSeeked;
        case MediaChangeEvent.Seeking:
            return AudioContextAction.BrowserAudioSeeking;
        case MediaChangeEvent.Waiting:
            return AudioContextAction.BrowserAudioWaiting;
        case MediaChangeEvent.Prevented:
            return AudioContextAction.BrowserAudioPlayPrevented;
    }
}
