import type { h } from "preact";
import { dispatch, store } from "global";
import { CONTEXT_MENU_OPEN } from "global/actions";
import { createContextMenuSheet, createContextMenuLink } from "services/contextMenus";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ContextMenuLinkModel, ContextMenuTreeModel, ContextMenuPosition, ContextMenuLayerModel } from "models/view/contextMenu";
import { ContextMenuPositionType, ContextMenuAlign, ContextMenuSheetWidth } from "models/view/contextMenu";

export interface OpenMenuResult {
    action: OpenMenuResultAction;
}

export enum OpenMenuResultAction {
    Click,
    Cancel
}

export function openMenuByElement(
    menu: h.JSX.Element,
    element: HTMLElement,
    closeOnNavigation = true,
    width: ContextMenuSheetWidth = ContextMenuSheetWidth.Default,
    context?: PreviewContextModel
): Promise<OpenMenuResult> {
    return openMenu(menu, { type: ContextMenuPositionType.Anchor, element }, closeOnNavigation, width, context);
}

export function openMenuByMouse(
    menu: h.JSX.Element,
    event: MouseEvent,
    closeOnNavigation = true,
    width: ContextMenuSheetWidth = ContextMenuSheetWidth.Default,
    context?: PreviewContextModel
): Promise<OpenMenuResult> {
    return openMenu(menu, { type: ContextMenuPositionType.ScreenPosition, screenPosition: { x: event.x, y: event.y } }, closeOnNavigation, width, context);
}

export function openMenuAtPosition(
    menu: h.JSX.Element,
    x: number,
    y: number,
    closeOnNavigation = true,
    width: ContextMenuSheetWidth = ContextMenuSheetWidth.Default,
    context?: PreviewContextModel,
): Promise<OpenMenuResult> {
    return openMenu(menu, { type: ContextMenuPositionType.ScreenCorner, screenPosition: { x, y } }, closeOnNavigation, width, context, ContextMenuAlign.UpperRight);
}

export function openMenu(
    menu: h.JSX.Element,
    position: ContextMenuPosition,
    closeOnNavigation = true,
    width: ContextMenuSheetWidth = ContextMenuSheetWidth.Default,
    context?: PreviewContextModel,
    align?: ContextMenuAlign
): Promise<OpenMenuResult> {
    const parent = store.getState().menu.open ?? undefined;

    const tree: ContextMenuTreeModel = parent?.tree ?? { layers: [] };
    const layer: ContextMenuLayerModel = {};
    tree.layers.push(layer);

    const link = createContextMenuLink({ parent, tree, layer, position });

    const promise = new Promise<OpenMenuResult>((resolve) => {
        link.layer.onClose = resolve;
        const sheet = createContextMenuSheet({ parent: link, menu, tree, layer, align: align ?? ContextMenuAlign.Below, gap: false, width });
        dispatch({ type: CONTEXT_MENU_OPEN, payload: { sheet, closeOnNavigation, context } });
    });

    return promise;
}

interface OpenSubmenuParams {
    parent: ContextMenuLinkModel;
    menu: h.JSX.Element;
}

export function openSubmenu({ parent, menu }: OpenSubmenuParams) {
    const sheet = createContextMenuSheet({ parent, menu, tree: parent.tree, layer: parent.layer, align: ContextMenuAlign.Right, gap: true, width: ContextMenuSheetWidth.Default });
    dispatch({ type: CONTEXT_MENU_OPEN, payload: { sheet } });

    return sheet;
}
