import { h } from "preact";
import { ResourceTextPart, ResourceTextDesign } from "./ResourceTextPart";
import { ResourceLinkTestLocator } from "global/constants";
import { LinkContextModel } from "models/app/resourceContextModel";
import { ButtonDesign, Button } from "components/atoms/controls/button";

interface Props {
    link: LinkContextModel;
    className?: string;
    clickable?: boolean;
    disabled?: boolean;
    design?: ResourceTextDesign;
    onNavigate?: () => void;
    scrollingText?: boolean;
    testLocatorName: number;
}

export const ResourceLinkPart = ({ link, className, clickable = true, disabled, design, testLocatorName, onNavigate }: Props) => {
    if (link.text == null) return null;

    if (clickable && link.url != null) {
        const buttonDesign: ButtonDesign = (() => {
            switch (design) {
                case ResourceTextDesign.PrimarySmall:
                    return ButtonDesign.PlayableLinkPrimary;
                case ResourceTextDesign.PrimarySmall2Lines:
                    return ButtonDesign.PlayableLinkPrimary2Lines;
                case ResourceTextDesign.SecondarySmall:
                    return ButtonDesign.PlayableLinkSecondary;
                case ResourceTextDesign.MaxiPlayerPrimary:
                    return ButtonDesign.PlayableLinkMaxiPrimary;
                case ResourceTextDesign.MaxiPlayerSecondary:
                    return ButtonDesign.PlayableLinkMaxiSecondary;
                default:
                    return ButtonDesign.TextInline;
            }
        })();

        return (
            <Button
                disabled={disabled}
                className={`resourceLinkPart RAs ${className ?? ""}`}
                link={link}
                useText
                onClick={onNavigate}
                design={buttonDesign}
                inline={{ right: true, left: true, top: true, bottom: true }}
                testLocator={ResourceLinkTestLocator(testLocatorName)}
            />
        );
    } else
        return (
            <ResourceTextPart disabled={disabled} className={className} design={design} testLocatorName={testLocatorName}>
                {link.text}
            </ResourceTextPart>
        );
};
