import { h } from "preact";
import type { DragProps } from "models/app";
import type { QueueTrackModel } from "models/app/player/input";
import type { SectionContextModel } from "models/app/resourceContextModel";
import { QueueTrackRow } from "components/molecules/preview/rows";

interface Props {
    dragProps: DragProps;
    currentLane: QueueTrackModel;
    sectionContext: SectionContextModel;
    onCurrentTrackClick?: () => void;
    onCurrentCoverClick?: () => void;
}

export function CurrentLanePreview({ dragProps, currentLane, sectionContext, onCurrentTrackClick, onCurrentCoverClick }: Props) {
    return (
        <section className="currentLanePreview">
            <QueueTrackRow
                dragProps={dragProps}
                dropProps={null}
                queueTrack={currentLane}
                sectionContext={sectionContext}
                onCurrentTrackClick={onCurrentTrackClick}
                onCurrentCoverClick={onCurrentCoverClick}
            />
        </section>
    );
}
