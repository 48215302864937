import { messageBus, store } from "global";
import { headerNames, CacheType, serviceWorkerConfig } from "shared/services";
import { USER_LOGGING_OUT } from "global/actions";
import { log, DefaultLogMessage } from "services/logger";

export function initGraphqlCacheService() {
    messageBus.subscribeEvery(USER_LOGGING_OUT, async () => {
        await clearPersonalizedCache();
    });
}

export async function fetchGraphqlContent(input: RequestInfo, init: RequestInit): Promise<Response> {
    const request = new Request(input, init);
    const userId = store.getState().user.id ?? "null";

    request.headers.append(headerNames.cacheType, CacheType.GraphQL);
    request.headers.append(headerNames.user, userId);

    return await fetch(request);
}

async function clearPersonalizedCache() {
    log.info({ code: "web-210602-2001", msg: "clear graphql cache" });

    const cache = await caches.open(serviceWorkerConfig.cacheNameGraphql);
    const userId = store.getState().user.id ?? "null";
    const remove: Promise<boolean>[] = [];

    (await cache.keys()).forEach((entry) => {
        const entryUserId = entry.headers.get(headerNames.user) ?? null;

        if (entryUserId == null) {
            log.error({ code: "web-210610-1216", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }

        if (entryUserId != "null" && entryUserId !== userId) {
            remove.push(cache.delete(entry));
            // log.info({code: "web-210610-1244", "deleted personalized cache for user", [{ key: "userId", entryUserId }]);
        }
    });

    await Promise.all(remove);
}
