export function getStateDbName(storageId: string): string {
    return `state_${storageId}`;
}

export function getRefreshTokenStorageKey(storageId: string): string {
    return `refreshToken_${storageId}`;
}

export function getAccessTokenStorageKey(storageId: string): string {
    return `accessToken_${storageId}`;
}

export function getSharedRefreshTokenStorageKey(): string {
    return `refreshtoken`;
}

export function getSharedAccessTokenStorageKey(): string {
    return `accesstoken`;
}

export function getLoadedPlaybackReportsStorageKey(storageId: string): string {
    return `loadedPlaybackReports_${storageId}`;
}

export function getFinishedPlaybackReportsStorageKey(storageId: string): string {
    return `finishedPlaybackReports_${storageId}`;
}

export function getSharedLoadedPlaybackReportsStorageKey(): string {
    return `loadedPlaybackReports`;
}

export function getSharedFinishedPlaybackReportsStorageKey(): string {
    return `finishedPlaybackReports`;
}

export function getAppSessionHandlerKey() {
    return "AppSessions";
}

export function getPendingLyricsReportsStorageKey(storageId: string): string {
    return `lyricsReports_${storageId}`;
}
