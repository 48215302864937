import type { TrackPreviewModel } from ".";
import type { ConnectionModel } from "./ConnectionModel";
import { createPlaylistTrackPreviewConnectionModel } from "./ConnectionModel";
import type { PlayQueuePlayableModelInterface } from "./PlayQueuePlayableModel";
import type { ResourceModelLinkInterface } from "./ResourceModel";
import type { PlaylistPageFragment, PlaylistPlayFragment, PlaylistPreviewFragment } from "generated/graphql-types";
import { PlaylistVisibility } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type PlaylistPreviewData = PlaylistPreviewFragment;
type PlaylistPlayData = PlaylistPlayFragment;
type PlaylistPageData = PlaylistPageFragment;

export type PlaylistModel = PlaylistLinkModel;
export type PlaylistLinkModel = ({ type: DomainModelType.Link } & PlaylistLink) | PlaylistPreviewModel;
export type PlaylistPreviewModel = ({ type: DomainModelType.Preview } & PlaylistPreview) | PlaylistPlayModel;
export type PlaylistPlayModel = ({ type: DomainModelType.Play } & PlaylistPlay) | PlaylistPageModel;
export type PlaylistPageModel = { type: DomainModelType.Page } & PlaylistPage;

interface PlaylistLink extends ResourceModelLinkInterface {
    readonly contentType: ContentType.Playlist;
    readonly title: string;
}

export interface PlaylistPreview extends PlaylistLink, PlayQueuePlayableModelInterface {
    createdAt: Date | null;
    duration: number | null;
    sharingUrl: string | null;
    owner: boolean;
    visibility: PlaylistVisibility;
}

interface PlaylistPlay extends PlaylistPreview {
    tracks: ConnectionModel<TrackPreviewModel> | null;
}

interface PlaylistPage extends PlaylistPlay {
    readonly followersCount: number;
    lastModified?: Date;
}

export function createPlaylistPreviewModel(data: PlaylistPreviewData): PlaylistPreviewModel {
    return {
        graphType: data.__typename,
        contentType: ContentType.Playlist,
        id: data.id,
        title: data.title,
        cover: data.cover ?? null,
        createdAt: new Date(data.createdAt),
        trackCount: data.tracksCount ?? null,
        duration: data.duration ?? 0,
        sharingUrl: data.share ?? null,
        owner: data.isOwned,
        visibility: data.visibility,
        type: DomainModelType.Preview
    };
}

export function createPlaylistPlayModel(data: PlaylistPlayData): PlaylistPlayModel {
    return {
        ...createPlaylistPreviewModel(data),
        type: DomainModelType.Play,
        tracks: createPlaylistTrackPreviewConnectionModel(data.tracks)
    };
}

export function createPlaylistPageModel(data: PlaylistPageData): PlaylistPageModel {
    return {
        ...createPlaylistPlayModel(data),
        type: DomainModelType.Page,
        followersCount: data.followersCount,
        lastModified: new Date(Date.parse(data.modifiedAt))
    };
}

export function isPlaylistPublic(playlist: PlaylistPreviewModel | PlaylistPlayModel | PlaylistPageModel | null): boolean {
    if (!playlist) return true;
    return playlist.visibility === PlaylistVisibility.Public;
}
