import { h } from "preact";
import type { PreviewAction } from "..";
import { SmallPreviewPlayButton } from "..";
import { isPlayable } from "services/playable";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlayablePreviewModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { usePlayablePropertiesFromState } from "components/shared/hooks";
import type { CoverSize } from "components/atoms/cover";
import { ResourceCover } from "components/atoms/cover";
import { PlayableSoundbars, SoundbarStyle } from "components/atoms/soundbars";

interface Props {
    context: PreviewContextModel;
    dynamicSize?: boolean;
    onAction: (action: PreviewAction) => void;
    playable: PlayablePreviewModel;
    size: CoverSize;
    available: boolean;
    disableLazyLoad: boolean;
}

export const PlayablePreviewRowCover = ({ playable, context, size, onAction, dynamicSize, available, disableLazyLoad }: Props) => {
    const isLoaded = usePlayablePropertiesFromState(playable).isLoaded;
    const showSoundbars = isLoaded && (playable.contentType === ContentType.TrackPlayable || playable.contentType === ContentType.LiveRadio);

    const soundbars = showSoundbars && <PlayableSoundbars playable={playable} style={SoundbarStyle.CoverOverlay} />;
    const playButton = available && isPlayable(playable) && <SmallPreviewPlayButton playable={playable} context={context} onAction={onAction} />;
    const hasButtons = playButton;

    return (
        <ResourceCover disabled={!available} size={size} dynamicSize={dynamicSize} context={context} disabledAction={true} disableLazyLoad={disableLazyLoad}>
            {soundbars}
            {hasButtons && <div className={`buttons`}>{playButton}</div>}
        </ResourceCover>
    );
};
