import { listenToAppReloadMessages, pollForServiceWorkerUpdates } from "./appReloadService";
import { serviceWorkerConfig } from "shared/services/serviceWorkerConfig";
import { log } from "services/logger/initLoggerService";

export let serviceWorkerRegistered = false;
export async function initServiceWorker() {
    try {
        if (!("serviceWorker" in navigator)) {
            log.error({ code: "web-210414-1220", msg: "client: no serviceWorker in navigator" });
            return;
        }

        if (!serviceWorkerConfig.enabled) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            registrations.forEach((registration) => registration.unregister());
            return;
        }

        listenToAppReloadMessages(navigator.serviceWorker);

        let registration: ServiceWorkerRegistration;
        try {
            registration = await navigator.serviceWorker.register(`/serviceWorker.js`);
        } catch {
            log.error({ code: "web-220223-1947", msg: "client: serviceWorker registration failed" });
            return;
        }

        serviceWorkerRegistered = true;

        navigator.serviceWorker.addEventListener("controllerchange", () => {
            // if (process.env.NODE_ENV === "development") return;
            log.info({ code: "web-211101-1835", msg: "client: serviceWorker controller change" });
        });

        pollForServiceWorkerUpdates(registration);
    } catch (e) {
        log.error({ code: "web-220221-1320", msg: "error when initiating serviceWorker service" });
    }
}
