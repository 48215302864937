import { store } from "global";
import { DefaultLogMessage, log } from "services/logger";
import type { AnalyticsQueueAddedPropertiesGroup } from "services/logger/analytics/properties/groups";
import { getNextUUID } from "services/utils";
import type { AudioInputItemModel } from "models/app/player/input";

export interface NormalizedData<T> {
    data: T;
    referenceId: string;
}

export function createNormalizedData<T>(data: T): NormalizedData<T> {
    return {
        referenceId: getNextUUID(),
        data
    };
}

export function getNormalizedDataAnalyticsQueueAdded(referenceId: string | null): AnalyticsQueueAddedPropertiesGroup | null {
    if (referenceId == null) {
        log.error({ code: "web-220629-2107", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }
    const referenceData = store.getState().audioInput.analytics2[referenceId] ?? null;
    if (referenceData == null) {
        // TODO re-enable this log later
        // log.error({code: "web-220629-2106", "referenceData == null", [
        //     { key: "referenceId", value: referenceId },
        //     { key: "analytics", value: store.getState().audioInput.analytics2 }
        // ]);
        return null;
    }

    return referenceData;
}

export function updateNormalizedAnalyticsQueueAdded(
    audios: AudioInputItemModel[],
    analytics: Record<string, AnalyticsQueueAddedPropertiesGroup | unknown>,
    added: NormalizedData<AnalyticsQueueAddedPropertiesGroup> | null
): Record<string, AnalyticsQueueAddedPropertiesGroup> {
    if (added) analytics[added.referenceId] = added.data;

    const references = audios.map((audio) => audio.analytics2?.addedDataId).filter((reference) => reference != null) as string[];
    const unique = new Set(references);
    const newAnalytics: Record<string, AnalyticsQueueAddedPropertiesGroup> = {};

    unique.forEach((reference) => {
        const data = (analytics[reference] ?? null) as AnalyticsQueueAddedPropertiesGroup | null;
        if (!data) {
            // TODO re-enable this log later
            // const analyticsLog = { ...analytics };
            // setTimeout(() => {
            //     log.error({code: "web-20220329-2122", "data is null", [
            //         { key: "reference", value: reference },
            //         { key: "analytics", value: analyticsLog }
            //     ]);
            // }, 0);
            return;
        }
        newAnalytics[reference] = data;
    });
    return newAnalytics;
}
