import { playChange } from ".";
import { isInputPlayQueueFromState } from "../helpers";
import { dispatch } from "global";
import { ANALYTICS_PLAYED_ERROR_EVENT } from "global/actions";
import type { LogCode } from "services/logger";
import { getLoadedPlaybackReportFromStorage, getPlayedDurationFromReport, log } from "services/logger";
import { getAnalyticsPlayableErrorProperties } from "services/logger/analytics/properties/event";
import { stopPlayUserTrace } from "models/app";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";
import { openToast } from "components/organisms/toast";
import { PlaybackFailToast } from "components/organisms/toast/toasts";

export function shouldTryReplayAudio(audio: AudioInputItemModel) {
    return audio.errorCount <= 1;
}

export function onAudioError(code: LogCode, audio: AudioInputItemModel, context: AudioContextModel, message: string, trackUrlCacheError?: string, trackUrlFetchError?: string) {
    if (audio.playState === PlayState.Stopped || audio.playState === PlayState.Error) return;

    if (shouldTryReplayAudio(audio)) replayAudio(audio, context);
    else endAudioWithError(code, audio, context, message, trackUrlCacheError, trackUrlFetchError);
}

function replayAudio(audio: AudioInputItemModel, context: AudioContextModel) {
    log.info({ code: "web-230524-1456", msg: "re-trying to play audio", data: { logTitle: audio.logTitle } });

    const playState = audio.playState === PlayState.Paused ? PlayState.Paused : PlayState.Buffering;
    playChange(audio, playState, null, context);
}

function endAudioWithError(code: LogCode, audio: AudioInputItemModel, context: AudioContextModel, message: string, trackUrlCacheError?: string, trackUrlFetchError?: string) {
    if (context.trace) {
        stopPlayUserTrace(context.trace);
        context.trace = null;
    }

    const id = audio.input === AudioInputType.PlayQueue ? audio.trackId : audio.radioId;
    log.error({
        code,
        msg: "audio could not be played (and message has been show to user)",
        data: {
            logTitle: audio.logTitle,
            inputType: audio.input,
            id,
            message,
            trackUrlCacheError,
            trackUrlFetchError
        }
    });

    isInputPlayQueueFromState() && openToast(PlaybackFailToast());
    sendErrorEvent(audio, context);

    playChange(audio, PlayState.Error, null, context);
}

function sendErrorEvent(audio: AudioInputItemModel, context: AudioContextModel) {
    const report = getLoadedPlaybackReportFromStorage(audio.reportId);
    if (!report) {
        log.error({ code: "web-220712-1539", msg: "report missing" });
        return;
    }

    const analytics = report?.analytics2;
    if (!analytics) {
        log.error({ code: "web-220712-1511", msg: "report analytics missing" });
        return;
    }

    const duration = report ? getPlayedDurationFromReport(report) : 0;
    const errorData = getAnalyticsPlayableErrorProperties({
        playDuration: duration,
        endReason: context.action
    });

    dispatch({
        type: ANALYTICS_PLAYED_ERROR_EVENT,
        payload: {
            queueData: analytics.queueData,
            loadData: analytics.loadData,
            playableData: analytics.playableData,
            errorData
        }
    });
}
