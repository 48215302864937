import { playNext, PlayNextSource } from "./playNext";
import { log } from "services/logger";
import { getCurrentInputAudioItemFromState } from "services/player/inputs/service/helpers";
import type { AudioContextModel } from "models/app/player/AudioContext";

export function audioEnded(audioId: string, context: AudioContextModel) {
    const current = getCurrentInputAudioItemFromState();
    if (current?.audioId !== audioId) {
        log.error({ code: "web-211003-1232", msg: "ended song is not current lane" });
        return;
    }

    playNext(true, PlayNextSource.SongEnded, context);
}
