import { getHlsPreloadState, preloadHls } from "../hls";
import { getBrowserAudioLog } from "../log";
import { updateFlow } from "./updateFlow";
import { playerConfig } from "global/constants";
import { LogTag, LogLevel, DefaultLogMessage, log } from "services/logger";
import { getAudioByIdFromState, getAudioItemPositionSeconds } from "services/player/inputs/service/helpers";
import { AudioInputType } from "models/app/player";
import { StreamUrlType } from "models/app/player/input";
import type { BrowserAudioItemModel } from "models/app/player/output";
import { BrowserAudioLoadState, BrowserAudioItemPreloadSize } from "models/app/player/output";

interface LoadedChunk {
    position: number;
    duration: number;
}

export function updatePreloadState(browserAudio: BrowserAudioItemModel) {
    const state = getPreloadState(browserAudio);
    if (state == null) return null;

    if (state == browserAudio.state) {
        updateTryPreload(browserAudio);
        return;
    }

    browserAudio.state = state;

    log.debug([LogTag.Playback], () => ({
        code: "web-221213-1452",
        msg: `browserAudio state updated: ${browserAudio.state}`,
        data: {
            browserAudio: getBrowserAudioLog(browserAudio)
        }
    }));

    if (state === BrowserAudioLoadState.PartialDone || state === BrowserAudioLoadState.Done) {
        preloadDone(browserAudio);
    }
}

export function updateTryPreload(browserAudio: BrowserAudioItemModel) {
    if (browserAudio.state !== BrowserAudioLoadState.Buffering) return;

    // log.debug([LogTag.Playback], () => ({
    //     code: "web-221213-1553",
    //     msg: "updating browserAudio preload",
    //     data: {
    //         browserAudio: getBrowserAudioLog(browserAudio)
    //     }
    // }));

    preloadHls(browserAudio);
}

function preloadDone(browserAudio: BrowserAudioItemModel) {
    log.debug([LogTag.Playback], () => ({
        code: "web-221213-1554",
        msg: "browserAudio preloadDone",
        data: {
            browserAudio: getBrowserAudioLog(browserAudio)
        }
    }));

    updateFlow();
}

export function calculatePreloadState(
    browserAudio: BrowserAudioItemModel,
    duration: number,
    loadedDuration: number
): BrowserAudioLoadState.Buffering | BrowserAudioLoadState.PartialDone | BrowserAudioLoadState.Done {
    const size = browserAudio.preload.size;
    const canPlayThrough = browserAudio.canPlayThrough;
    if (size === BrowserAudioItemPreloadSize.Full && !canPlayThrough) return BrowserAudioLoadState.Buffering;

    const loadedMin = getPreloadDurationSeconds(size);

    const done = loadedDuration + 1 >= duration;
    const partial = duration > 0 && loadedDuration >= loadedMin;

    const newState = done ? BrowserAudioLoadState.Done : partial ? BrowserAudioLoadState.PartialDone : BrowserAudioLoadState.Buffering;
    const smallLog = newState === BrowserAudioLoadState.Buffering;
    const bigLog = newState !== browserAudio.state;
    const loadedPercent = duration > 0 ? (loadedDuration / duration) * 100 : null;

    smallLog &&
        log.debug(
            [LogTag.Playback],
            () => ({
                code: "web-210217-1352",
                msg: `preload progress, title: "${browserAudio.logTitle}, percent: "${loadedPercent}"`
            }),
            LogLevel.Info
        );
    bigLog &&
        log.debug(
            [LogTag.Playback],
            () => {
                return {
                    code: "web-210217-1352",
                    msg: `preload state change, title: "${browserAudio.logTitle}", state: ${newState}, percent: ${loadedPercent}`,
                    data: {
                        title: browserAudio.logTitle,
                        audioId: browserAudio.audioId,
                        duration,
                        url: browserAudio.url,
                        "currentTime (seconds played)": browserAudio.element.currentTime,
                        loadedDuration,
                        loadNow: browserAudio.loadNow,
                        loadedDone: done
                    }
                };
            },
            LogLevel.Info
        );
    return newState;
}

function getPreloadState(browserAudio: BrowserAudioItemModel): BrowserAudioLoadState | null {
    if (browserAudio.disposed) return null;

    switch (browserAudio.url?.urlType) {
        case StreamUrlType.Hls:
            return getHlsPreloadState(browserAudio);
        case StreamUrlType.Mp3:
            return getPreloadStateFromAudioElement(browserAudio);
        case undefined:
            return null;
    }
}

function getPreloadStateFromAudioElement(browserAudio: BrowserAudioItemModel): BrowserAudioLoadState | null {
    if (browserAudio.state !== BrowserAudioLoadState.PartialDone && browserAudio.state !== BrowserAudioLoadState.Buffering) return null;

    const item = getAudioByIdFromState(browserAudio.audioId);
    if (!item) {
        log.error({ code: "web-210205-1700", msg: DefaultLogMessage.UnexpectedNull });
        return null;
    }

    if (item.input !== AudioInputType.PlayQueue) {
        // log.error({code: "web-210922-1619", msg: DefaultLogMessage.UnexpectedNull});
        return null;
    }

    const cursor = item ? getAudioItemPositionSeconds(item.playState, item.position) : 0;
    const chunks: { position: number; duration: number }[] = [];
    const { element } = browserAudio;
    for (let i = 0; i < element.buffered.length; i++) {
        const start = element.buffered.start(i);
        const end = element.buffered.end(i);
        chunks.push({ position: start, duration: end - start });
    }

    const duration: number = browserAudio.element.duration ?? 0;
    const loaded = getLoadedDuration(chunks, duration, cursor);
    const newState = calculatePreloadState(browserAudio, duration, loaded);
    return newState;
}

export function getLoadedDuration(chunks: LoadedChunk[], duration: number, cursor: number): number {
    const getPosition = (chunks: LoadedChunk[], cursor: number) => {
        let position: number = cursor;
        for (const chunk of chunks) {
            const start = chunk.position;
            const end = chunk.position + chunk.duration;

            if (start <= position && end > position) {
                position = end;
            } else break;
        }
        return position;
    };

    const after: LoadedChunk[] = [];
    const before: LoadedChunk[] = [];

    chunks.forEach((chunk) => {
        const start = chunk.position;
        const end = chunk.position + chunk.duration;
        if (cursor <= end) after.push(chunk);
        if (cursor > start) before.push(chunk);
    });

    const position1 = getPosition(after, cursor);
    let duration1 = position1 - cursor;

    if (position1 >= duration) {
        const position2 = getPosition(before, 0);
        const duration2 = position2 > cursor ? cursor : position2;

        duration1 += duration2;
    }

    return duration1;
}

const getPreloadDurationSeconds = (preload: BrowserAudioItemPreloadSize | null): number => {
    switch (preload) {
        case BrowserAudioItemPreloadSize.Small:
            return playerConfig.smallPreloadDurationSec;
        case BrowserAudioItemPreloadSize.Full:
            return playerConfig.maxPreloadDuratioSec;
        case null:
            return 0;
    }
};
