import { ComponentChildren, h } from "preact";
import "./ScrollingText.scss";
import { useScrollingText } from "../../shared/hooks/useScrollingText";

interface Props {
    children: ComponentChildren;
}

export const ScrollingText = ({ children }: Props) => {
    const { ref, contentRef, scroll, running, duration1, duration2 } = useScrollingText();

    const move = duration1 ? `move ${duration1}s forwards 1 linear 0s` : null;
    const fade = duration2 ? `fade ${duration2}s forwards 1 linear 0s` : null;

    return (
        <div ref={ref} className={`scrollingText pEd --scroll-${scroll} --running-${running}`}>
            <div className="a" style={{ animation: fade }} />
            <div className="b" />
            <div className="content" style={{ animation: move }}>
                <div ref={contentRef}>{children}</div>
                {scroll && <div>{children}</div>}
            </div>
        </div>
    );
};
