import { useMemo } from "preact/hooks";
import { useSelector } from "react-redux";
import { RootModel } from "models/app";
import { AudioInputType } from "models/app/player";

export const usePlayRange = () => {
    const input = useSelector((root: RootModel) => root.player.input);
    return useMemo(() => {
        switch (input) {
            case AudioInputType.LiveRadio: {
                return false;
            }
            case AudioInputType.PlayQueue:
                return true;
        }
    }, [input]);
};
