import { dispatch, store } from "global";
import type { AppVersionModel } from "shared/services";
import { getCurrentAppVersion } from "shared/services";
import { UPDATE_APP_VERSION } from "global/actions";
import { log } from "services/logger";

export enum AppVersionCompare {
    Higher = "Higher",
    Same = "Same",
    Lower = "Lower"
}

export function appVersionCompare(version: AppVersionModel, compareTo: AppVersionModel, ignoreBuildVersion?: boolean): AppVersionCompare {
    const compare = appVersionCompareFn(version, compareTo, ignoreBuildVersion);
    if (compare < 0) return AppVersionCompare.Lower;
    if (compare > 0) return AppVersionCompare.Higher;
    return AppVersionCompare.Same;
}

function appVersionCompareFn(version: AppVersionModel, compareTo: AppVersionModel, ignoreBuildVersion?: boolean): number {
    let r: number;

    r = version.major - compareTo.major;
    if (r != 0) return r;

    r = version.minor - compareTo.minor;
    if (r != 0) return r;

    r = version.release - compareTo.release;
    if (r != 0) return r;

    if (!ignoreBuildVersion) {
        r = version.build - compareTo.build;
        if (r != 0) return r;
    }
    return 0;
}

export function initAppVersionService() {
    const from = store.getState().app.version;
    const to = getCurrentAppVersion();

    if (appVersionCompare(to, from) === AppVersionCompare.Higher) {
        log.info({
            code: "web-210315-1625",
            msg: "updated app version",
            data: {
                "from version": JSON.stringify(from),
                "to version": JSON.stringify(to)
            }
        });
        dispatch({ type: UPDATE_APP_VERSION, payload: { version: getCurrentAppVersion() } });
    } else {
        // log.info({ code: "web-210315-1625", msg: "app version", data: { version: JSON.stringify(to) } });
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function updateAppData(name: string, after: AppVersionModel, from: AppVersionModel, to: AppVersionModel, updateFn: () => void) {
    const version = appVersionCompare(after, from, true);
    const run = version === AppVersionCompare.Higher;
    if (!run) return;

    const data = {
        "update name": name,
        "after version": JSON.stringify(after),
        "from version": JSON.stringify(from),
        "to version": JSON.stringify(to)
    };

    try {
        updateFn();
    } catch (e) {
        log.error({ code: "web-210315-1623", msg: "app data update fail", data, error: e });
        return;
    }
    log.info({ code: "web-210315-1624", msg: "app update success", data });
}
