import { useEffect } from "preact/hooks";
import type { Action } from "global/actions";
import { messageBus } from "global/config/configureStore";
import type { Message, MessageListener } from "services/messagebus";

export function useMessageBus<K extends Action["type"]>(message: K, listener: (payload: Extract<Action, { type: K }>) => void): void;
export function useMessageBus<T extends Message>(message: string, listener: (message: T) => void): void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useMessageBus(message: any, listener: any): void {
    useEffect(() => {
        return messageBus.subscribeEvery(message, listener as MessageListener);
    }, [message, listener]);
}

export function useMessageBusOne<K extends Action["type"]>(message: K, listener: (payload: Extract<Action, { type: K }>) => void): void;
export function useMessageBusOne<T extends Message>(message: string, listener: (message: T) => void): void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useMessageBusOne(message: any, listener: any): void {
    useEffect(() => {
        return messageBus.subscribeOne(message, listener as MessageListener);
    }, [message, listener]);
}

export function useMessageBusAll(listener: (message: Action) => void): void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useMessageBusAll(listener: any): void {
    useEffect(() => {
        return messageBus.subscribeAll(listener as MessageListener);
    }, [listener]);
}
