import type { Action } from "../actions";
import { ENABLE_LOGTYPE } from "../actions";
import { UPDATE_CONTROL_PANEL, REHYDRATE } from "global/actions";
import { arrayDifference } from "services/arrayHelper";
import { LogTag } from "services/logger";
import type { ControlPanelModel } from "models/app";

const initialState: ControlPanelModel = {
    enabledLogTypes: [LogTag.Info],
    enableAudioCachePool: false,
    enableAudioPreload: true,
    enableFeedbackResponse: true,
    enableNotificationFeed: true,
    enableNotificationFeedTestMode: false
};

export function controlPanelReducer(state: ControlPanelModel = initialState, action: Action): ControlPanelModel {
    switch (action.type) {
        case UPDATE_CONTROL_PANEL: {
            const { controlPanel } = action.payload;
            return { ...state, ...controlPanel };
        }
        case ENABLE_LOGTYPE: {
            const { type, enable } = action.payload;
            const enabledLogTypes = enable ? [...state.enabledLogTypes, type] : state.enabledLogTypes.filter((item) => item !== type);

            return { ...state, enabledLogTypes };
        }
        case REHYDRATE: {
            const controlPanelState = action.payload?.controlPanel;
            if (!controlPanelState) return initialState;

            const cleanedControlPanelState = cleanHydrateState(initialState, controlPanelState);

            return {
                ...initialState,
                ...cleanedControlPanelState,
                enableAudioCachePool: false,
                enableAudioPreload: true,
                enableFeedbackResponse: true,
                enableNotificationFeed: true
            };
        }
    }
    return state;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findKeysToDiscard = (keep: any, discard: any) => {
    const keysToKeep = Object.keys(keep);
    const keysToDiscard = Object.keys(discard);
    return arrayDifference(keysToKeep, keysToDiscard);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cleanHydrateState = (initialState: any, rehydrateState: any) => {
    const diff = findKeysToDiscard(initialState, rehydrateState);
    diff.forEach((key) => delete rehydrateState[key]);
    return rehydrateState;
};
