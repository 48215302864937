import { Fragment, h } from "preact";
import { useCallback, useRef } from "preact/hooks";
import { useSelector } from "react-redux";
import { AccountContextMenu } from "../contextMenu/menus";
import { showLoginUi } from "../login";
import { Search, SearchResultDisplay } from "../search/Search";
import { Feature, isFeatureEnabled, useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { useNavigation } from "services/navigationStack/useNavigation";
import { hasPermissionFromToken, shouldDisplayAsLoggedIn, useLoginState } from "services/user";
import { LoginState, type RootModel } from "models/app";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";
import { useContextMenu, useFakePageContext, useResizeObserver } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { ButtonNotificationFeed } from "components/atoms/controls/button/buttons";
import { IconName } from "components/atoms/icon";
import "./DesktopHeader.scss";

interface Props {
    className?: string;
}

export function DesktopHeader({ className }: Props) {
    const context = useFakePageContext({ type: ContentType.Search, resource: null, root: NavigationPageRootName.Search, done: true });
    const translations = useTranslations();
    const loginStateIsKnown = useLoginState() !== LoginState.Unknown;

    const { hasForward, hasBackward } = useNavigation();

    const searchRef = useRef<HTMLDivElement>(null);

    const accountMenuButtonRef = useRef<HTMLButtonElement>(null);
    const accountMenu = useContextMenu(<AccountContextMenu />, false);

    const loginState = useSelector((root: RootModel) => root.user.state);
    const loggedOut = !shouldDisplayAsLoggedIn(loginState);

    const profile = useSelector((root: RootModel) => root.user.profiles?.find(n => n.current));
    const showProfiles = loginState === LoginState.LoggedIn && profile && hasPermissionFromToken("prof") && isFeatureEnabled(Feature.Profiles);

    const openAccountMenu = useCallback(() => {
        if (!accountMenuButtonRef.current) {
            log.error({ code: "web-210519-1100", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        accountMenu.openAtPosition(8, 8);
    }, [accountMenu]);

    const [search, searchBackground] = Search({ context, resultsDisplay: SearchResultDisplay.WhenFocus });

    const documentWidth = useResizeObserver(document.body ?? null)?.width ?? 0;
    const searchRect = useResizeObserver(searchRef?.current ?? null);

    const searchWidth = searchRect?.width ?? 0;
    const searchLeftOptimal = documentWidth / 2 - searchWidth / 2;
    const searchLeft = searchRef.current ? searchRef.current?.getBoundingClientRect().left : 0;
    const searchLeftOffset = `${searchLeftOptimal - searchLeft}px`;

    return (
        <div tabIndex={-1} className={`desktopHeader kPv ${className ?? ""}`}>
            <div className="content">
                <div className="navigation">
                    <Button
                        icon={IconName.ArrowLeftAndroid}
                        disabled={!hasBackward}
                        design={ButtonDesign.DefaultBig}
                        inline={{ left: true }}
                        onClick={() => window.history.back()}
                    />
                    <Button
                        icon={IconName.ArrowRightAndroid}
                        disabled={!hasForward}
                        design={ButtonDesign.DefaultBig}
                        inline={{ right: true }}
                        onClick={() => window.history.forward()}
                    />
                </div>
                <div className="search">
                    <div ref={searchRef} style={{ paddingLeft: searchLeftOffset }}>
                        {search}
                    </div>
                </div>
                <div className="menu">
                    {loginStateIsKnown && loggedOut && (
                        <Button design={ButtonDesign.PrimarySmall} onClick={showLoginUi} testLocator={TestLocator.PageHeaderLoginButton}>
                            {translations.NavBarLogin}
                        </Button>
                    )}
                    {loginStateIsKnown && <Fragment>
                        <ButtonNotificationFeed />
                        {
                            showProfiles &&
                            <div class="headeravatar" style={{ "--colorHighlight": profile.color }}>
                                <Button
                                    inline={{ right: true }}
                                    activated={accountMenu.isOpen}
                                    design={ButtonDesign.LightSmall}
                                    ref={accountMenuButtonRef}
                                    onClick={openAccountMenu}
                                    testLocator={TestLocator.PageHeaderAccountButton}
                                ><span>{profile.title.substring(0, 1).toUpperCase()}</span></Button>
                            </div>
                        }
                        {
                            !showProfiles &&
                            <Button
                                inline={{ right: true }}
                                activated={accountMenu.isOpen}
                                ref={accountMenuButtonRef}
                                icon={IconName.Profile}
                                onClick={openAccountMenu}
                                testLocator={TestLocator.PageHeaderAccountButton}
                            />
                        }
                    </Fragment>
                    }
                </div>
            </div>
            {searchBackground}
        </div>
    );
}
