import { h } from "preact";
import { ResourceLinkPart, ResourceLinkGroup, ResourceTextDesign } from ".";
import { LinkContextModel } from "models/app/resourceContextModel";

interface Props {
    design?: ResourceTextDesign;
    disabled?: boolean;
    links: LinkContextModel[];
    onNavigate?: () => void;
    testLocatorName?: number;
    className?: string;
}

export const ResourceLink = ({ className, design, links, onNavigate, disabled, testLocatorName }: Props) => {
    return (
        <ResourceLinkGroup design={design} testLocatorName={testLocatorName} className={className}>
            {links.map((link, linkIndex) => {
                return <ResourceLinkPart disabled={disabled} link={link} onNavigate={onNavigate} key={linkIndex} design={design} testLocatorName={linkIndex} />;
            })}
        </ResourceLinkGroup>
    );
};
