import type { Dispatch as ReduxDispatch } from "redux";
import type { Action } from "global/actions";
import { messageBus, store } from "global/config/configureStore";

export { store, messageBus } from "./config/configureStore";

// eslint-disable-next-line prefer-const
export let dispatch = async (action: Action) => {
    if (!store) {
        console.error("store not ready", action);
        return;
    }

    messageBus.setStoreSync();
    store.dispatch(action);
    await messageBus.sync();
};
export type Dispatch = ReduxDispatch<Action>;

export function waitForState(evaluate: (reduxStore: typeof store) => boolean): Promise<void> {
    return new Promise<void>((resolve) => {
        if (evaluate(store)) {
            resolve();
        } else {
            const unsubscribe = store.subscribe(() => {
                if (evaluate(store)) {
                    unsubscribe();
                    resolve();
                }
            });
        }
    });
}
