import { h } from "preact";
import type { ResourceTextDesign } from ".";
import { ResourceLinkGroup, ResourceTextPart } from ".";
import type { LinkContextModel } from "models/app/resourceContextModel";

interface Props {
    onNavigate?: () => void;
    links: LinkContextModel[];
    design?: ResourceTextDesign;
}

export const ResourceText = ({ design, links }: Props) => {
    return (
        <ResourceLinkGroup design={design}>
            {links.map((link, linkIndex) => {
                return (
                    <ResourceTextPart key={linkIndex} design={design} testLocatorName={linkIndex}>
                        {link.text}
                    </ResourceTextPart>
                );
            })}
        </ResourceLinkGroup>
    );
};
