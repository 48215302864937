import type { RecommendationPreviewModel, ResourceModelInterface } from ".";
import { createRecommendationPreviewConnectionModel } from ".";
import type { ConnectionModel } from "./ConnectionModel";
import { getNextUUID } from "services/utils";
import type { HistoryRecommendationsPageFragment } from "generated/graphql-types";
import { ContentType, DomainModelType } from "models/ModelType";

type HistoryRecommendationsPageData = HistoryRecommendationsPageFragment;

export interface HistoryRecommendationsPageModel extends ResourceModelInterface {
    type: DomainModelType.Page;
    contentType: ContentType.UserHistory;
    collections: ConnectionModel<RecommendationPreviewModel> | null;
}

export function createHistoryRecommendationsPage(data: HistoryRecommendationsPageData): HistoryRecommendationsPageModel {
    return {
        type: DomainModelType.Page,
        contentType: ContentType.UserHistory,
        id: getNextUUID(),
        graphType: data.__typename,
        cover: null,
        collections: data.recommendations ? createRecommendationPreviewConnectionModel(data.recommendations, ContentType.UserHistory) : null
    };
}
