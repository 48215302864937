export { FavoriteContentType } from "generated/graphql-types";
export { FavoriteAlbumsSorting, FavoriteArtistsSorting, FavoritePlaylistsSorting, FavoriteTracksSorting } from "generated/graphql-types";
export { PageItemDisplayType } from "generated/graphql-types";
export { FeedbackCategory } from "generated/graphql-types";

export enum ResourceDisplayType {
    Deeplink = "Deeplink",
    Grid = "Grid",
    LargeScroller = "LargeScroller",
    LargeSingle = "LargeSingle",
    PromoSingle = "PromoSingle",
    List = "List",
    ListLarge = "ListLarge",
    ListMedium = "ListMedium",
    Scroller = "Scroller",
    Single = "Single",
    Top = "Top",
    Unknown = "Unknown"
}

export enum ConnectionState {
    Fetching = "Fetching",
    NetworkError = "NetworkError",
    Ok = "Ok",
    Unknown = "Unknown",
    UnknownError = "UnknownError"
}
