import { tryStartPlayCurrentLane } from "./playTrack";
import { updatePlayQueue } from "./updatePlayQueue";
import { addTracksToQueueTracks, addPlayablesToQueue } from "../helpers";
import { store } from "global";
import { log, LogTag } from "services/logger";
import { getAnalyticsQueueIntentPropertiesGroup, createQueueAddedAnalyticsData } from "services/logger/analytics/properties/groups";
import { createNormalizedData } from "services/normalizeData";
import { getTrackAndParentFromPlayable } from "services/playable";
import { putAttributePlayUserTrace } from "models/app";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { QueueTrackModel, PlayQueueModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { PlayQueuePlayableModel } from "models/domain";
import { ContentType } from "models/ModelType";
import { ShuffleState } from "models/view";
import { openToast } from "components/organisms/toast";
import { AddMusicToQueueSuccessToast } from "components/organisms/toast/toasts";

export const addToQueue = async (
    playable: PlayQueuePlayableModel,
    index: number | null,
    lane: QueueLane,
    atEnd: boolean,
    previewContext: PreviewContextModel,
    context: AudioContextModel
) => {
    const { queue, player, audioInput } = store.getState();
    const isPlayQueueEmpty = queue.playQueue.length === 0;
    const isFlowLaneEmpty = queue.playQueue.findIndex((item) => item.lane === QueueLane.Flow) === -1;

    const shuffleOn = player.shuffle !== ShuffleState.Off;
    let newPlayQueue: QueueTrackModel[];
    let newLane: QueueLane;
    if (lane === QueueLane.Preview) {
        if (isFlowLaneEmpty) {
            newLane = QueueLane.Priority;
        } else {
            newLane = QueueLane.Flow;
        }
    } else {
        newLane = lane;
    }
    const queueIntentData = getAnalyticsQueueIntentPropertiesGroup(previewContext);
    const parent = getTrackAndParentFromPlayable(playable).parent;
    const addData = createQueueAddedAnalyticsData(queueIntentData, parent);
    const queueAddData = createNormalizedData(addData);
    if (playable.contentType === ContentType.TrackPlayable) {
        newPlayQueue = addTracksToQueueTracks(queue.playQueue, [playable], newLane, atEnd, index, isPlayQueueEmpty, shuffleOn, queueAddData.referenceId, "web-210716-1555");
    } else {
        newPlayQueue = await addPlayablesToQueue(queue.playQueue, [playable], newLane, atEnd, index, isPlayQueueEmpty, shuffleOn, queueAddData.referenceId);
    }

    log.debug([LogTag.Playback], () => ({
        code: "web-210212-0956",
        msg: "playable has been added to queue",
        data: { playable }
    }));
    let newQueue: PlayQueueModel = { ...queue, playQueue: newPlayQueue };
    newQueue = await updatePlayQueue(newQueue, context, false, queueAddData, [getTrackAndParentFromPlayable(playable).parent]);

    openToast(AddMusicToQueueSuccessToast());

    if (!isPlayQueueEmpty) return;

    if (context.trace) putAttributePlayUserTrace(context.trace, { type: "clickOnType", value: playable.contentType });
    tryStartPlayCurrentLane(newQueue, audioInput.audios, context);
};
