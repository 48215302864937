import { ScrollerSize, ScrollerAlign } from "../../options";
import { getAlignOffset } from "./getAlignOffset";
import { getItemSize } from "./getItemSize";

export const getActiveItemIndex = (container: HTMLElement, size: ScrollerSize | number, align: ScrollerAlign, ajust: number, gap: number) => {
    const left = container.scrollLeft;
    const childSize = getItemSize(size);
    const offset = getAlignOffset(size, align);

    let index = (left - offset) / (childSize / ajust + gap);
    if (index < -0.5) index = -0.5;
    return index;
};
