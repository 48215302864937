import type { LogMessageEntry } from ".";
import { LogLevel } from ".";
import { getAppVersionString, headerNames } from "../../../shared/services";
import { messageBus } from "global";
import { LOGGING_EVENT_MESSAGE } from "global/actions";
import { getInstallationId } from "services/app";
import { getTokensNow } from "services/appSession/operations/sessionTokens";
import { apiClientlogPath, getBackendBaseUrl } from "services/backend";

const maxDataFieldSize = 4096;
const maxDataEntries = 16;

export const initKibanaLoggerService = () => {
    messageBus.subscribeEvery(LOGGING_EVENT_MESSAGE, (msg) => {
        try {
            const message = msg.payload.message;

            if (message.level !== LogLevel.Warning && message.level !== LogLevel.Error) return;

            const fields = getMessageLogFields(message, "client", true).reduce((prev, current) => {
                prev[current.key] = current.value;
                return prev;
            }, {} as { [key: string]: string });

            const { accessToken } = getTokensNow();
            const url = getBackendBaseUrl(apiClientlogPath);

            fetch(url, {
                method: "POST",
                headers: {
                    ...(accessToken ? { [headerNames.authorization]: `Bearer ${accessToken}` } : {}),
                    [headerNames.contentType]: "application/json",
                    [headerNames.appVersion]: getAppVersionString(),
                    [headerNames.installationId]: getInstallationId()
                },
                body: JSON.stringify({
                    message: msg.payload.message.message,
                    loglevel: msg.payload.message.level,
                    fields
                })
            }).catch(() => {
                // catch async error from promise that does not get handled by the try/catch
            });
        } catch (e) {
            //not logging, to avoid possible infinite loops
        }
    });
};

export function getMessageLogFields(message: LogMessageEntry, prefix: string, kibanaSpecific = false): { key: string; value: string }[] {
    const fields = [
        { key: `${prefix}.language`, value: message.language },
        { key: `${prefix}.message-code`, value: message.messageCode },
        { key: `${prefix}.platform`, value: message.platform },
        { key: `${prefix}.stack`, value: message.stack ?? "undefined" },
        { key: `${prefix}.url`, value: message.url }
    ];
    if (kibanaSpecific) {
        if (message.userId) {
            fields.push({ key: `${prefix}.userid`, value: message.userId });
        }
    } else {
        fields.push({ key: `${prefix}.hostname`, value: message.hostname });
        fields.push({ key: `${prefix}.user-agent`, value: message.userAgent });
        fields.push({ key: `${prefix}.app-version`, value: message.appVersion });
        fields.push({ key: `${prefix}.installation-id`, value: message.installationId });
    }
    if (message.data !== undefined) {
        for (const [key, val] of Object.entries(message.data).slice(0, maxDataEntries)) {
            let value = typeof val === "string" ? val : (JSON.stringify(val) as string | undefined);

            if (value == undefined) value = "undefined";
            if (value.length > maxDataFieldSize) {
                value = `${value.substring(0, maxDataFieldSize - 3)}...`;
            }

            fields.push({
                key: `${prefix}.${key}`,
                value
            });
        }
    }

    return fields;
}
