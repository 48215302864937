import { useSelector } from "react-redux";
import { useArrayMemo } from "./useArrayMemo";
import { store } from "global";
import type { RootModel } from "models/app";
import { RepeatStatus } from "models/view";

export const useIsEndlessPlayOnFromState = () => {
    const repeat = useSelector((root: RootModel) => root.player.repeat);
    const isEndlessPlayOn = useSelector((root: RootModel) => root.queue.isEndlessPlayOn);
    return getIsEndlessPlayOn(isEndlessPlayOn, repeat);
};
export const useEndlessPlayFromState = () => {
    const endlessPlayFromState = useSelector((root: RootModel) => root.queue.endlessPlay);
    const isEndlessPlayOn = useIsEndlessPlayOnFromState();
    const endlessPlay = useArrayMemo(() => (!isEndlessPlayOn ? endlessPlayFromState?.slice(0, 5) : endlessPlayFromState), [isEndlessPlayOn, endlessPlayFromState]);
    const isEndlessPlayEmpty = endlessPlay.length === 0;
    return {
        isEndlessPlayEmpty,
        endlessPlay
    };
};
export const getIsEndlessPlayOnFromState = () => {
    const { player, queue } = store.getState();
    const repeat = player.repeat;
    const isEndlessPlayOn = queue.isEndlessPlayOn;
    return getIsEndlessPlayOn(isEndlessPlayOn, repeat);
};
export const getIsEndlessPlayOn = (isEndlessPlayOn: boolean, repeat: RepeatStatus) => {
    return isEndlessPlayOn && repeat === RepeatStatus.None;
};
