import { useCallback } from "preact/hooks";
import { PreviewAction, playPlayablePreview } from ".";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import type { NotificationConversion } from "models/domain/NotificationModel";

interface Props {
    preview: PreviewContextModel;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
    resource: ResourcePreviewModel;
    mainAction: PreviewAction;
    expandAction?: () => void;
}

export function usePreviewAction({ preview, onAction: onPropAction, resource, mainAction, expandAction }: Props) {
    const onAction = useCallback(
        (action: PreviewAction) => {
            if (action === PreviewAction.Play) playPlayablePreview(resource, preview);
            if (action === PreviewAction.Expand) expandAction && expandAction();
            onPropAction && onPropAction(action, preview, null);
        },
        [onPropAction, preview, resource, expandAction]
    );

    const onMainAction = useCallback(() => onAction(mainAction), [onAction, mainAction]);

    return { onAction, onMainAction };
}
