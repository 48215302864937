import { useMemo, useEffect, useState } from "preact/hooks";
import { useSectionContext, usePreviewContext } from ".";
import { dispatch } from "global";
import { AUDIO_INPUT_SET_AND_PLAY } from "global/actions";
import { log } from "services/logger";
import { hasNavigated } from "services/navigationStack/navigationStack";
import { isPlayable } from "services/playable";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { ContextResourceModel, PageContextModel } from "models/app/resourceContextModel";
import type { LocationContentType, PlayableModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";

export interface AutoPlayProps {
    type: LocationContentType;
    resource: ContextResourceModel | null;
    loading?: boolean;
    error?: boolean;
    page: PageContextModel;
}

let hasDoneAutoPlay = false;

export function useAutoPlay({ type, resource, loading, page, error }: AutoPlayProps) {
    const playable = useMemo(() => (resource && isPlayable(resource) ? resource : null), [resource]);

    const autoplay = useMemo(() => shouldAutoPlay(), []);
    const section = useSectionContext(type, playable, page, null, ResourceDisplayType.Deeplink);
    const preview = usePreviewContext(section.type, playable, section, null);

    useEffect(() => {
        if (loading || error || !playable || !autoplay) return;
        hasDoneAutoPlay = true;

        log.info({ code: "web-220105-1330", msg: "autoplay from url" });

        dispatch({ type: AUDIO_INPUT_SET_AND_PLAY, payload: { playable, playableContext: preview, context: { action: AudioContextAction.DeeplinkPlay, trace: null } } });
    }, [playable, loading, error, preview, autoplay]);
}

interface AutoPlayFetchResourceProps {
    getFn: (() => Promise<PlayableModel | null>) | null;
}

export function useGetAutoPlayResource({ getFn: fetchFn }: AutoPlayFetchResourceProps): ContextResourceModel | null {
    const [playable, setPlayable] = useState<PlayableModel | null>(null);

    useEffect(() => {
        let cancelled = false;
        setPlayable(null);

        if (!shouldAutoPlay()) return;
        if (fetchFn == null) return;

        const run = async () => {
            const playable = await fetchFn();
            if (cancelled || !playable) return;
            setPlayable(playable);
        };
        run();
        return () => (cancelled = true);
    }, [fetchFn]);

    return playable;
}

function shouldAutoPlay() {
    if (hasDoneAutoPlay) return false;
    if (hasNavigated()) return false;

    const hash = location.hash.substring(1);
    if (hash !== "afspil") return false;

    return true;
}
