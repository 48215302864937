import { h } from "preact";
import { useCallback, useEffect } from "preact/hooks";
import type { SheetProps } from ".";
import "./FloatingSheet.scss";
import { useContextMenuPlacement } from "./hooks";
import { dispatch } from "global";
import { CONTEXT_MENU_HOVER, CONTEXT_MENU_OPEN, CONTEXT_MENU_UPDATE_POSITION } from "global/actions";
import { isContextMenuChildOpenWithAnotherLayer } from "services/contextMenus";
import type { ContextMenuPosition } from "models/view/contextMenu";
import { ContextMenuPositionType } from "models/view/contextMenu";
import { useRefUpdate } from "components/shared/hooks";

export const FloatingSheet = ({ index, sheet }: SheetProps) => {
    const placement = useContextMenuPlacement({ sheetPos: sheet.position, parentSheetPos: sheet.parent?.position ?? null, align: sheet.align, gap: sheet.gap });
    const { top, left, right } = placement ?? { top: 0, left: 0, right: undefined };
    const sheetRef = useRefUpdate<HTMLDivElement>();
    const inBackground = isContextMenuChildOpenWithAnotherLayer(sheet);

    useEffect(() => {
        if (!sheetRef.current) return;
        const position: ContextMenuPosition = { type: ContextMenuPositionType.Anchor, element: sheetRef.current };
        dispatch({ type: CONTEXT_MENU_UPDATE_POSITION, payload: { item: sheet, position } });
    }, [sheet, sheetRef]);

    useEffect(() => {
        if (!inBackground) return;
        dispatch({ type: CONTEXT_MENU_HOVER, payload: { itemId: sheet.id, on: false } });
    }, [sheet.id, inBackground]);

    const hoverOn = useCallback(() => {
        if (inBackground) return;
        dispatch({ type: CONTEXT_MENU_HOVER, payload: { itemId: sheet.id, on: true } });
    }, [sheet.id, inBackground]);

    const hoverOff = useCallback(() => {
        if (inBackground) return;
        dispatch({ type: CONTEXT_MENU_HOVER, payload: { itemId: sheet.id, on: false } });
    }, [sheet.id, inBackground]);

    const prevent = useCallback((ev: MouseEvent) => {
        ev.stopPropagation();
    }, []);

    const open = useCallback(() => {
        dispatch({ type: CONTEXT_MENU_OPEN, payload: { sheet } });
    }, [sheet]);

    return (
        <div
            className={`floatingSheet test_contextMenu HR4 --placement-${placement != null} --width-${sheet.width} --inBackground-${inBackground}`}
            style={{ zIndex: index, top, left, right }}
            onClick={prevent}
            onPointerEnter={hoverOn}
            onPointerLeave={hoverOff}
            ref={sheetRef}>
            <div className="content">{sheet.content}</div>
            <div className="foreground" onClick={open} />
        </div>
    );
};
