import { ComponentChildren, h, Fragment } from "preact";
import { useContext } from "preact/hooks";
import { ContextMenuManagerContext, ContextMenuMode } from ".";

export enum ShowSheetTop {
    InBottonSheet,
    Always
}

interface SheetProps {
    top: h.JSX.Element;
    showTop?: ShowSheetTop;
    children?: ComponentChildren;
}

export const Sheet = ({ top, showTop = ShowSheetTop.InBottonSheet, children }: SheetProps) => {
    const mode = useContext(ContextMenuManagerContext).mode as ContextMenuMode;
    const renderTop = showTop === ShowSheetTop.Always || mode === ContextMenuMode.BottomSheet;

    return (
        <Fragment>
            {renderTop && <div className="header">{top}</div>}
            {children}
        </Fragment>
    );
};
