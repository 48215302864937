import { h } from "preact";
import "./Avatar.scss";

interface Props {
    text: string;
    color?: string;
    class?: string;
}

export function Avatar(props: Props) {
    const styles = props.color ? { backgroundColor: props.color } : undefined;
    return (
        <div className={"avatar r39" + (props.class ? " " + props.class : "")} style={styles}>
            {props.text.substring(0, 1).toUpperCase()}
        </div>
    );
}
