import { h } from "preact";
import { Link } from "..";
import { dispatch } from "global";
import { CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { useTranslations } from "global/config";
import { OpenMenuResultAction } from "services/contextMenus";
import { promptUserForPWAInstall, getPWAInstallOption, PWAInstallOption } from "services/pwa/initPWAService";
import { IconName } from "components/atoms/icon";

interface Props {
    showIcon: boolean;
}

export const GetDesktopAppLink = ({ showIcon }: Props) => {
    const option = getPWAInstallOption();
    const translations = useTranslations();

    if (option === PWAInstallOption.None || option === PWAInstallOption.Installed) return null;

    const activated = option === PWAInstallOption.InstallPromptOpen;
    const prompt = async () => {
        const installed = await promptUserForPWAInstall();
        if (installed) dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
    };

    return (
        <Link icon={showIcon ? IconName.Download : undefined} onClick={() => prompt()} activated={activated}>
            {translations.AccountMenuGetDeskopAppActionWeb}
        </Link>
    );
};
