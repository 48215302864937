import { h } from "preact";
import { LayoutPage } from "..";
import { environment } from "services/environment";
import { ContentType } from "models/ModelType";
import { NavigationPageRootName } from "models/view/navigationStack";

export const FrontPage = () => {
    const pageId = environment.apiFrontPageId;
    return <LayoutPage key={pageId} pageId={pageId} type={ContentType.FrontPage} root={NavigationPageRootName.FrontPage} />;
};
