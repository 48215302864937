import { AppContextMenuModel, ContextMenuSheetModel } from "models/view/contextMenu";

export const setVisibleContextMenu = (appMenu: AppContextMenuModel, sheet: ContextMenuSheetModel, on: boolean): AppContextMenuModel => {
    const index = appMenu.visible.indexOf(sheet);
    const wasOn = index !== -1;

    if (on === wasOn) return appMenu;

    const visible = [...appMenu.visible];
    if (on) visible.push(sheet);
    else visible.splice(index, 1);

    return { ...appMenu, visible };
};
