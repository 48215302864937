import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getLiveRadioCategoryByLiveRadioLiveRadiosPagination, useLiveRadioCategoryPageByLiveRadio } from "services/backend/backend";
import type { LiveRadioPreviewModel } from "models/domain";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import type { AlternateAutoPlayFn } from "components/templates/showAllPage";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    liveRadioId: string;
}

export const LiveRadioPage = ({ liveRadioId }: Props) => {
    const query = useLiveRadioCategoryPageByLiveRadio({ id: liveRadioId, first: getPaginationInit() });
    const product = query.model;
    const fetchFn = useCallback((first: number, after: string) => getLiveRadioCategoryByLiveRadioLiveRadiosPagination({ first, after, id: liveRadioId }), [liveRadioId]);

    const getAutoPlayRadio: AlternateAutoPlayFn<LiveRadioPreviewModel> = useCallback(
        (items) => {
            const radio = items?.items.find((radio) => radio.id === liveRadioId) ?? null;
            return { type: ContentType.LiveRadio, resource: radio };
        },
        [liveRadioId]
    );

    return (
        <ShowAllPageTemplate
            query={query}
            type={ContentType.LiveRadioCategory}
            items={product?.radios ?? null}
            fetchFn={fetchFn}
            display={ResourceDisplayType.Grid}
            alternateAutoPlayFn={getAutoPlayRadio}
            highlightedId={liveRadioId}
        />
    );
};
