import { ScrollerSize, ScrollerAlign } from "../../options";
import { getItemSize } from "./getItemSize";
import { useResizeObserver } from "components/shared/hooks";

export function useScrollZone(container: HTMLElement | null, content: HTMLElement | null, size: ScrollerSize | number, align: ScrollerAlign, ajust: number, gap: number) {
    const canvasSize = useResizeObserver(container)?.width ?? 0 - getPadding(content);
    const childSize = getItemSize(size);
    const canvasCountDecimal = (canvasSize + gap) / (childSize + gap);
    const canvasCount = Math.floor(canvasCountDecimal);

    let zoneCount = Math.round((canvasCountDecimal * 50) / 100);
    if (zoneCount < 1) zoneCount = 1;
    else if (zoneCount > canvasCount) zoneCount = canvasCount;

    if (zoneCount < 1) zoneCount = 1;

    return { zoneCount, canvasCount };
}

function getPadding(element: HTMLElement | null) {
    if (!element) return 0;
    const computed = getComputedStyle(element);
    let padding = parseInt(computed.paddingLeft, 10) + parseInt(computed.paddingRight, 10);
    if (isNaN(padding)) padding = 0;
    return padding;
}
