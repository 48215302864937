import { onNextMediaSessionUpdate } from "../castEvents/mediaSession/onMediaSessionUpdate";
import { getCurrentAudioInSync, getRemoteCastState } from "../helpers";
import { isOperationModifyingQueue } from "../helpers/operation";
import { isLocalCastQueueEmpty } from "../helpers/queue";
import { hasCastMediaSession, hasCastSession } from "../setup";
import { inOperation, OperationSource, tryOperation } from "./operation";
import { assumeRemoteCastQueueSynched } from "./remoteCastQueue";
import { stopCastSession } from "./stopCastSession";
import { stopQueueFromLocal } from "./stopQueue";
import { handleLastInQueueAndStopped, syncCurrentFromRemote } from "./syncCurrent";
import { syncNewQueueFromLocal } from "./syncNewQueue";
import { syncPlayStateFromRemote, syncPlayStateFromLocal } from "./syncPlayState";
import { syncResumeSettingsFromRemote } from "./syncSettings";
import { log, LogTag } from "services/logger";
import type { AudioContextModel } from "models/app/player/AudioContext";
import { AudioContextAction } from "models/app/player/AudioContext";

export async function syncCastFromLocalChange(context: AudioContextModel): Promise<boolean> {
    const castState = getRemoteCastState();
    const connected = castState === cast.framework.CastState.CONNECTING || castState === cast.framework.CastState.CONNECTED;

    if (!connected) {
        log.warn({ code: "web-230208-1529", msg: "user performed local change while cast state offline - we must disconnect and play locally" });
        stopCastSession(context);
        return true;
    }

    if (!hasCastSession()) return true;

    const ok = await tryOperation(() => _syncCastFromLocalChange(context), OperationSource.Local);

    if (ok && !inOperation()) {
        await tryOperation(async () => {
            if (hasCastMediaSession()) await onNextMediaSessionUpdate(true);
            return await syncMediaLoadedFromRemote({ action: AudioContextAction.ChromecastLocalSync, trace: null });
        }, OperationSource.Chromecast);
    }

    return ok;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function _syncCastFromLocalChange(context: AudioContextModel): Promise<boolean> {
    if (!hasCastSession()) return true;

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `syncCastFromLocalChange...` });

    let ok: boolean;

    if (!hasCastMediaSession()) {
        if (isLocalCastQueueEmpty()) return true;

        ok = await syncNewQueueFromLocal();
        return ok;
    }

    if (isLocalCastQueueEmpty()) {
        ok = await stopQueueFromLocal();
        return ok;
    }

    if (isOperationModifyingQueue()) {
        ok = await syncNewQueueFromLocal(true);
        return ok;
    }

    const { currentSynced } = getCurrentAudioInSync();
    if (!currentSynced) {
        ok = await syncNewQueueFromLocal(true);
        return ok;
    }

    // ok = await syncExistingQueueFromLocal();
    // if (!ok) return false;

    // ok = await syncCurrentFromLocal();
    // if (!ok) return false;

    ok = await syncPlayStateFromLocal();
    if (!ok) return false;

    return true;
}

export async function syncResumeFromRemote(context: AudioContextModel): Promise<boolean> {
    if (hasCastMediaSession()) await onNextMediaSessionUpdate(true);

    await syncResumeSettingsFromRemote();
    assumeRemoteCastQueueSynched();

    const last = await handleLastInQueueAndStopped(context);
    if (last) return true;

    if (!hasCastMediaSession() && isLocalCastQueueEmpty()) return true;

    let ok: boolean;

    ok = await syncCurrentFromRemote(context);
    if (!ok) return false;

    ok = await syncPlayStateFromRemote(context);
    if (!ok) return false;

    return true;
}

export async function syncMediaLoadedFromRemote(context: AudioContextModel): Promise<boolean> {
    const last = await handleLastInQueueAndStopped(context);
    if (last) return true;

    if (!hasCastMediaSession()) return true;

    let ok: boolean;

    ok = await syncCurrentFromRemote(context);
    if (!ok) return false;

    ok = await syncPlayStateFromRemote(context);
    if (!ok) return false;

    return true;
}
