import type { ComponentChildren } from "preact";
import { h } from "preact";
import { CoverRounding } from ".";
import { PlayableLoadSpinner } from "../spinner";
import type { CoverSize } from "./Cover";
import { Cover } from "./Cover";
import type { OpenMenuResult } from "services/contextMenus";
import { getIsCoverRounded } from "services/resource";
import type { DraggableItemModel } from "models/app";
import type { PreviewContextModel } from "models/app/resourceContextModel";

interface Props {
    children?: ComponentChildren;
    className?: string;
    context: PreviewContextModel;
    disabled?: boolean;
    disabledAction?: boolean;
    draggableItem?: () => DraggableItemModel | null;
    dynamicSize?: boolean;
    navigate?: boolean;
    onClick?: () => void;
    onContextMenu?: (ev: MouseEvent) => Promise<OpenMenuResult | undefined>;
    rounding?: CoverRounding;
    size: CoverSize;
    spinner?: boolean;
    disableLazyLoad: boolean;
    highlight?: boolean;
}

export const ResourceCover = ({
    children,
    className,
    context,
    disabled,
    disabledAction,
    draggableItem,
    dynamicSize,
    navigate = true,
    onClick,
    onContextMenu,
    rounding,
    size,
    spinner,
    disableLazyLoad,
    highlight
}: Props) => {
    if (getIsCoverRounded(context.type, context.resource)) rounding = CoverRounding.Circle;

    return (
        <Cover
            className={className}
            context={context}
            disabled={disabled}
            highlight={highlight}
            disabledAction={disabledAction}
            draggableItem={draggableItem}
            dynamicSize={dynamicSize}
            navigate={navigate}
            onClick={onClick}
            onContextMenu={onContextMenu}
            rounding={rounding}
            size={size}
            disableLazyLoad={disableLazyLoad}>
            {children}
            {spinner && <PlayableLoadSpinner />}
        </Cover>
    );
};
