import { h, ComponentChildren } from "preact";
import { useMemo, useState, useContext, useCallback, useEffect } from "preact/hooks";
import { useSelector } from "react-redux";
import "./Link.scss";
import { ContextMenuMode, ContextMenuSheetContext, ContextMenuManagerContext, ContextMenuLinkContext as LinkContext } from ".";
import { dispatch } from "global";
import { CONTEXT_MENU_OPEN, CONTEXT_MENU_UPDATE_POSITION, CONTEXT_MENU_HOVER, CONTEXT_MENU_CLOSE_ALL } from "global/actions";
import { TestLocator } from "global/constants/testLocator";
import { createContextMenuLink, isContextMenuLinkActive, isContextMenuSheetOpenOrVisible, OpenMenuResultAction } from "services/contextMenus";
import { openSubmenu } from "services/contextMenus/open";
import { RootModel } from "models/app";
import { LinkContextModel } from "models/app/resourceContextModel";
import { ContextMenuPositionType, ContextMenuPosition, ContextMenuSheetModel } from "models/view/contextMenu";
import { useRefUpdate } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { Icon, IconName } from "components/atoms/icon";

interface LinkProps {
    activated?: boolean;
    children?: ComponentChildren;
    className?: string;
    closeOnClick?: boolean;
    disabled?: boolean;
    icon?: IconName;
    link?: LinkContextModel;
    onClick?: (ev: MouseEvent) => void;
    testLocator?: TestLocator;
    tooltip?: string;
    toggled?: boolean;
}

export const Link = ({ icon, children, className, closeOnClick, onClick, link, activated, disabled, toggled }: LinkProps) => {
    const innerOnClick = !(closeOnClick || onClick)
        ? undefined
        : (ev: MouseEvent) => {
              if (onClick) {
                  onClick(ev);
              }
              if (closeOnClick) {
                  dispatch({ type: CONTEXT_MENU_CLOSE_ALL, payload: { result: { action: OpenMenuResultAction.Click } } });
              }
          };
    return (
        <Button
            activated={activated}
            design={ButtonDesign.ContextMenu}
            className={`link A7m ${className ?? ""}`}
            icon={icon}
            onClick={innerOnClick}
            link={link}
            toggled={toggled}
            disabled={disabled}>
            {children}
        </Button>
    );
};

interface ContextLinkWithMenuProps {
    icon?: IconName;
    children?: ComponentChildren;
    submenu: h.JSX.Element;
    disabled?: boolean;
}

export const LinkWithSubmenu = ({ icon, submenu, children, disabled }: ContextLinkWithMenuProps) => {
    const parent = useContext(ContextMenuSheetContext).sheet as ContextMenuSheetModel;
    const mode = useContext(ContextMenuManagerContext).mode as ContextMenuMode;
    const linkRef = useRefUpdate<HTMLButtonElement>();
    const appMenu = useSelector((model: RootModel) => model.menu);

    const link = useMemo(() => createContextMenuLink({ parent, tree: parent.tree, layer: parent.layer, position: null }), [parent]);
    const [sheet, setSheet] = useState<ContextMenuSheetModel | null>(null);

    const isActive = useMemo(() => isContextMenuLinkActive(appMenu.open?.id ?? null, appMenu.hovers, link), [link, appMenu.open, appMenu.hovers]);
    const isSubOpen = useMemo(() => sheet != null && isContextMenuSheetOpenOrVisible(appMenu.open, appMenu.visible, sheet), [appMenu.open, appMenu.visible, sheet]);

    const openSub = useCallback(() => {
        if (sheet) dispatch({ type: CONTEXT_MENU_OPEN, payload: { sheet } });
        else {
            const newSheet = openSubmenu({ parent: link, menu: submenu });
            setSheet(newSheet);
        }
    }, [sheet, setSheet, link, submenu]);
    const openParent = useCallback(() => dispatch({ type: CONTEXT_MENU_OPEN, payload: { sheet: parent } }), [parent]);

    useEffect(() => {
        if (!linkRef.current) return;
        const position: ContextMenuPosition = { type: ContextMenuPositionType.Anchor, element: linkRef.current };
        dispatch({ type: CONTEXT_MENU_UPDATE_POSITION, payload: { item: link, position } });
    }, [link, linkRef]);

    const hover = (on: boolean) => (!on || !disabled) && dispatch({ type: CONTEXT_MENU_HOVER, payload: { itemId: link.id, on } });

    useEffect(() => {
        if (mode === ContextMenuMode.Floating && isActive !== isSubOpen) {
            const fn = () => {
                if (isActive) openSub();
                else if (isSubOpen) openParent();
            };

            const timeOut = isActive ? 150 : 170;
            const timeOutId = window.setTimeout(() => fn(), timeOut);
            return () => clearTimeout(timeOutId);
        }
    }, [mode, isActive, isSubOpen, openParent, openSub]);

    return (
        <LinkContext.Provider value={{ link }}>
            <Button
                disabled={disabled}
                design={ButtonDesign.ContextMenu}
                className={`link A7m`}
                icon={icon}
                onClick={openSub}
                hover={isActive}
                ref={linkRef}
                onPointerEnter={() => hover(true)}
                onPointerLeave={() => hover(false)}>
                {children}
                <Icon className="submenu" icon={IconName.ArrowRightSmall} />
            </Button>
        </LinkContext.Provider>
    );
};
