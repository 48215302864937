import type { RecentTracksQuery } from "generated/graphql-types";

export type RecentTracksData = RecentTracksQuery["me"];

export interface RecentTracksModel {
    readonly trackIds: string[];
}

export function createRecentTracksModel(data: RecentTracksData): RecentTracksModel {
    return { trackIds: (data.history.recentTracks.items ?? []).map((item) => item.id) };
}
