import { hasCastSession, removeCastSession } from "../setup";
import { resetLocalCastQueue } from "./localCastQueue";
import { resetOperation } from "./operation";
import { resetRemoteCastQueue } from "./remoteCastQueue";
import { setPlayerModeLocal } from "./setPlayerMode";
import type { AudioContextModel } from "models/app/player/AudioContext";

export function castSessionStopped(context: AudioContextModel) {
    setPlayerModeLocal(context);
    
    resetOperation();
    resetLocalCastQueue();
    resetRemoteCastQueue();
    if (hasCastSession()) removeCastSession();
}
