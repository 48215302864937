import { h } from "preact";
import { useCallback, useMemo, useState } from "preact/hooks";
import type { ControlStyle } from "../controlGroup/Control";
import { Control } from "../controlGroup/Control";
import { ControlGroup } from "../controlGroup/ControlGroup";
import { getSortOptionName } from "services/sort/getSortOptionName";
import { getRandomUUID } from "services/utils";
import type { SortOption, SortOptionsModel } from "models/app/SortOption";
import { useCallbackMap } from "components/shared/hooks";
import { CheckMark } from "components/atoms/controls/checkMark";

interface Props {
    className?: string;
    style: ControlStyle;
    sortOptions: SortOptionsModel;
    onChange: (option: SortOption) => void;
}

export const SortControl = ({ className, style, sortOptions, onChange: onChangeProp }: Props) => {
    const name = useMemo(() => getRandomUUID(), []);
    const [selected, setSelected] = useState(sortOptions.selected);
    const onChange = useCallback(
        (option: SortOption) => {
            setSelected(option);
            sortOptions.onChange(option);
            if (onChangeProp) onChangeProp(option);
        },
        [sortOptions, onChangeProp]
    );
    const options = useCallbackMap(sortOptions.options, onChange);

    return (
        <ControlGroup className={`sortControl ${className ?? ""}`}>
            {options.map((option) => (
                <Control
                    key={option.key}
                    title={getSortOptionName(option.key)}
                    onClick={option.callback}
                    right={<CheckMark type="radio" checked={option.key === selected} onChange={option.callback} name={name} />}
                    style={style}
                />
            ))}
        </ControlGroup>
    );
};
