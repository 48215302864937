import { useEffect, useState } from "preact/hooks";
import { useSelector } from "react-redux";
import { dispatch, messageBus } from "global";
import { FAVORITES_UPDATED, PLAYER_TOGGLE_FAVORITE } from "global/actions";
import { useMessageBus } from "global/hooks";
import {
    clearTemporaryFavoriteCache,
    getIsMeFavoriteWithCustomCache,
    mutateAddAlbumToFavorites,
    mutateAddArtistToFavorites,
    mutateAddPlaylistToFavorites,
    mutateAddTrackToFavorites,
    mutateRemoveAlbumFromFavorites,
    mutateRemoveArtistFromFavorites,
    mutateRemovePlaylistFromFavorites,
    mutateRemoveTrackFromFavorites
} from "services/backend";
import type { RootModel } from "models/app";
import { LoginState } from "models/app/LoginState";
import type {
    LocationContentType,
    ResourceModel,
    AlbumModel,
    PlaylistModel,
    AlbumPreviewModel,
    PlaylistPreviewModel,
    ArtistPreviewModel,
    AlbumPageModel,
    ArtistPageModel,
    PlaylistPageModel,
    LiveRadioTrackPreviewModel,
    LiveRadioTrackModel,
    PlayQueuePlayableModel,
    ArtistModel,
    TrackPreviewModel
} from "models/domain";
import { FavoriteContentType } from "models/domain";
import type { TrackPlayableModel, TrackPlayablePreviewModel } from "models/domain/TrackPlayableModel";
import { ContentType } from "models/ModelType";
import { promptLoginIfRequired } from "components/organisms/login";
import { openToast } from "components/organisms/toast";
import { AddMusicToMyMusicFailToast, AddMusicToMyMusicLimitExceededToast, AddMusicToMyMusicSuccessToast, RemoveMusicFromMyMusicFailToast, RemoveMusicFromMyMusicSuccessToast } from "components/organisms/toast/toasts";

export type FavoriteModel = TrackPlayableModel | AlbumModel | ArtistModel | PlaylistModel | LiveRadioTrackModel | FavoritePreviewModel;
export type FavoritePreviewModel = TrackPlayablePreviewModel | AlbumPreviewModel | ArtistPreviewModel | PlaylistPreviewModel | LiveRadioTrackPreviewModel | FavoritePageModel;
export type FavoritePageModel = AlbumPageModel | ArtistPageModel | PlaylistPageModel;

export type FavoriteModelType = FavoriteModel["contentType"];

export function initFavorites() {
    messageBus.subscribeEvery(PLAYER_TOGGLE_FAVORITE, async (msg) => {
        const { operation, playables } = msg.payload;
        if (playables && (await promptLoginIfRequired())) {
            operation === "remove" ? removeFavorites(playables) : addFavorites(playables);
        }
    });
}
export function isFavoriteContentType(type: LocationContentType): type is FavoriteModelType {
    switch (type) {
        case ContentType.Album:
        case ContentType.TrackPlayable:
        case ContentType.Artist:
        case ContentType.Playlist:
            return true;
    }
    return false;
}

export function isFavorite(resource: ResourceModel): resource is FavoriteModel {
    return isFavoriteContentType(resource.contentType);
}

export function useFavoriteStatus(playable: FavoriteModel | null) {
    const [result, setResult] = useState<boolean | null>(null);
    const userState = useSelector((root: RootModel) => root.user.state);
    useEffect(() => {
        if (userState === LoginState.LoggedIn) {
            const type = getFavoriteType(playable);
            if (playable?.id && type != null) {
                const id = playable.id;
                getIsMeFavoriteWithCustomCache(type, id).then((result) => {
                    setResult(result);
                });
            }
        }
    }, [playable, userState]);

    useMessageBus(FAVORITES_UPDATED, (message) => {
        if (playable) {
            // FAVORITES TODO: reimplent this

            const type = getFavoriteType(playable);
            let ids: string[] = [];
            let operation: "add" | "remove" | null = null;
            const payload = message.payload;
            switch (type) {
                case FavoriteContentType.Album:
                    ids = payload.changes.playables.filter((n) => n.contentType == ContentType.Album).map((n) => n.id);
                    operation = payload.changes.operation;
                    break;
                case FavoriteContentType.Artist:
                    ids = payload.changes.playables.filter((n) => n.contentType == ContentType.Artist).map((n) => n.id);
                    operation = payload.changes.operation;
                    break;
                case FavoriteContentType.Playlist:
                    ids = payload.changes.playables.filter((n) => n.contentType == ContentType.Playlist).map((n) => n.id);
                    operation = payload.changes.operation;
                    break;
                case FavoriteContentType.Track:
                    ids = payload.changes.playables.filter((n) => n.contentType == ContentType.TrackPlayable).map((n) => n.id);
                    operation = payload.changes.operation;
                    break;
            }
            const id = playable.id;
            if (operation === "add" && ids?.includes(id)) {
                setResult(true);
            }
            if (operation === "remove" && ids?.includes(id)) {
                setResult(false);
            }
        }
    });

    return userState === LoginState.LoggedIn ? result : null;
}

async function addFavorites(playables: PlayQueuePlayableModel[] | TrackPreviewModel[]): Promise<boolean> {
    // todo: instead get graphql support for adding multiples in a single call
    const addedPlayables: PlayQueuePlayableModel[] = [];
    let notAddedCount = 0;
    let limitExceeded = false;
    let playable: PlayQueuePlayableModel | TrackPreviewModel | undefined;
    for (let i = 0; i < playables.length && !limitExceeded; i++) {
        playable = playables[i];
        let ok: boolean;
        switch (playable.contentType) {
            case ContentType.Track:
            case ContentType.TrackPlayable:
                ({ ok, limitExceeded } = await mutateAddTrackToFavorites({ id: playable.id }));
                break;
            case ContentType.Album:
                ({ ok, limitExceeded } = await mutateAddAlbumToFavorites({ id: playable.id }));
                break;
            case ContentType.Artist:
                ({ ok, limitExceeded } = await mutateAddArtistToFavorites({ id: playable.id }));
                break;
            case ContentType.Playlist:
                ({ ok, limitExceeded } = await mutateAddPlaylistToFavorites({ id: playable.id }));
                break;
            default:
                continue;
        }
        if (ok && !limitExceeded) {
            addedPlayables.push(playable as unknown as PlayQueuePlayableModel); // todo
        } else {
            notAddedCount++;
        }
    }
    if (limitExceeded && playable) {
        openToast(AddMusicToMyMusicLimitExceededToast(playable.contentType));
    }
    else if (notAddedCount && !addedPlayables.length) {
        // todo: also different new toast if some were added and some werent?
        openToast(AddMusicToMyMusicFailToast());
    } else if (addedPlayables.length) {
        clearTemporaryFavoriteCache();
        openToast(AddMusicToMyMusicSuccessToast());
        dispatch({
            type: FAVORITES_UPDATED,
            payload: {
                changes: {
                    playables: addedPlayables,
                    operation: "add"
                }
            }
        });
    }
    return true;
}

async function removeFavorites(playables: FavoritePreviewModel[] | TrackPreviewModel[]): Promise<boolean> {
    // todo: instead get graphql support for removing multiple in a single call
    const removedPlayables: PlayQueuePlayableModel[] = [];
    let notRemovedCount = 0;
    for (let i = 0; i < playables.length; i++) {
        const playable = playables[i];
        let result: boolean;
        switch (playable.contentType) {
            case ContentType.Track:
            case ContentType.TrackPlayable:
                result = (await mutateRemoveTrackFromFavorites({ id: playable.id })) ?? false;
                break;
            case ContentType.Album:
                result = (await mutateRemoveAlbumFromFavorites({ id: playable.id })) ?? false;
                break;
            case ContentType.Artist:
                result = (await mutateRemoveArtistFromFavorites({ id: playable.id })) ?? false;
                break;
            case ContentType.Playlist:
                result = (await mutateRemovePlaylistFromFavorites({ id: playable.id })) ?? false;
                break;
            default:
                continue;
        }
        if (result) {
            removedPlayables.push(playable as unknown as PlayQueuePlayableModel); // todo
        } else {
            notRemovedCount++;
        }
    }
    if (notRemovedCount && !removedPlayables.length) {
        // todo: also different new toast if some were removed and some werent
        openToast(RemoveMusicFromMyMusicFailToast());
    } else if (removedPlayables.length) {
        clearTemporaryFavoriteCache();
        openToast(RemoveMusicFromMyMusicSuccessToast(() => addFavorites(playables as unknown as PlayQueuePlayableModel[]))); // todo
        dispatch({
            type: FAVORITES_UPDATED,
            payload: {
                changes: {
                    playables: removedPlayables,
                    operation: "remove"
                }
            }
        });
    }
    return true;
}
//TODO: remove?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getFavoriteIdsOfType(playables: FavoriteModel[], type: FavoriteContentType): string[] | null {
    const filtered = playables
        .filter((p) => getFavoriteType(p) == type)
        .map((n) => {
            return n.id;
        });
    return filtered.length === 0 ? null : filtered;
}

function getFavoriteType(playable: FavoriteModel | null): FavoriteContentType | null {
    if (playable) {
        switch (playable.contentType) {
            case ContentType.Album:
                return FavoriteContentType.Album;
            case ContentType.Artist:
                return FavoriteContentType.Artist;
            case ContentType.Playlist:
                return FavoriteContentType.Playlist;
            case ContentType.LiveRadioTrack:
            case ContentType.TrackPlayable:
                return FavoriteContentType.Track;
        }
    }
    return null;
}
