import { logPlayerActionEvent } from "./logPlayerActionEvent";
import { store } from "global";
import { debounce } from "services/cancellation/debounce";

const debounceMs = 200;

function logVolumeSeek() {
    const { volume } = store.getState().player;
    logPlayerActionEvent("VolumeSeek", undefined, volume * 100);
}

export const tryLogVolumeSeekEvent = debounce(logVolumeSeek, debounceMs)[0];
