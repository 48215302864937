import { h } from "preact";
import type { ComponentChildren } from "preact";
import { forwardRef } from "preact/compat";
import "./ScrollerWithFade.scss";

interface Props {
    children: ComponentChildren;
    tabIndex?: number;
    disableFade?: boolean;
}
export const ScrollerWithFade = forwardRef<HTMLDivElement, Props>(({ children, tabIndex, disableFade }, ref) => {
    return (
        <div className="scroll e2d" ref={ref} tabIndex={tabIndex}>
            {children}
            {!disableFade && <div className="fadeOut" />}
        </div>
    );
});
