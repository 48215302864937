import { h } from "preact";
import { useRef } from "preact/hooks";
import type { ButtonInline } from "../Button";
import { Button, ButtonDesign } from "../Button";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { useContextMenu } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";
import { MyMusicPlaylistsContextMenu } from "components/organisms/contextMenu/menus";

interface Props {
    design?: ButtonDesign;
    inline?: ButtonInline;
    icon?: IconName;
    onOpen?: (open: boolean) => void;
}

export const ButtonMoreMyMusicPlaylists = ({ design = ButtonDesign.LightBig, inline, icon = IconName.Meatballs, onOpen }: Props) => {
    const ref = useRef<HTMLButtonElement>(null);
    const menu = useContextMenu(MyMusicPlaylistsContextMenu());

    const onMoreButtonClick = async () => {
        if (!ref.current) {
            log.error({ code: "web-210517-1414", msg: DefaultLogMessage.UnexpectedNull });
            return;
        }
        if (onOpen) onOpen(true);
        await menu.open(ref.current);
        if (onOpen) onOpen(false);
    };

    return <Button onClick={onMoreButtonClick} ref={ref} icon={icon} design={design} inline={inline} activated={menu.isOpen} />;
};
