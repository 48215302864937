import { getTrackIndexInQueueLane, updateQueueLanes } from "../helpers";
import { updatePlayQueue } from "./updatePlayQueue";
import { store } from "global";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";
import { ShuffleState } from "models/view";

export const moveTrack = async (playable: QueueTrackModel, lane: QueueLane, atEnd: boolean, index: number | null, context: AudioContextModel) => {
    const state = store.getState();
    let { queue } = state;
    const { player } = state;
    let playQueue = [...queue.playQueue];
    const shuffleOn = player.shuffle !== ShuffleState.Off;
    const oldIndex = playQueue.findIndex((item) => item.queueId === playable.queueId);

    if (playable.lane === QueueLane.Preview) {
        const endlessPlay = queue.endlessPlay.filter((item) => item.queueId !== playable.queueId);
        queue = { ...queue, endlessPlay };
    } else {
        playQueue.splice(oldIndex, 1);
        if (index != null && oldIndex < index) index--;
    }
    let newLane: QueueLane;
    if (lane === QueueLane.Preview) {
        const indexOfFlowLaneItem = playQueue.findIndex((item) => item.lane === QueueLane.Flow);
        newLane = indexOfFlowLaneItem === -1 ? QueueLane.Priority : QueueLane.Flow;
    } else {
        newLane = lane;
    }

    const updatedTrack = { ...playable, lane: newLane, originalIndex: shuffleOn ? playQueue.length + 1 : null };

    if (index == null) index = getTrackIndexInQueueLane(playQueue, lane, atEnd);
    playQueue.splice(index, 0, updatedTrack);
    if (playable.lane === QueueLane.Current) {
        playQueue = updateQueueLanes(playQueue, oldIndex);
    }
    queue = { ...queue, playQueue };
    await updatePlayQueue(queue, context, false, null);
};
