import type { AppSession } from "../appSessionService";
import { getFinishedPlaybackReports, restoreFinishedPlaybackReports, saveFinishedPlaybackReports } from "./finishedReports";
import { getLoadedPlaybackReports, restoreLoadedPlaybackReports, saveLoadedPlaybackReports } from "./loadedReports";
import { getSharedFinishedPlaybackReports, saveSharedFinishedPlaybackReports } from "./sharedFinishedReports";
import { getSharedLoadedPlaybackReports, saveSharedLoadedPlaybackReports } from "./sharedLoadedReports";

export function getAndDeleteSharedReports() {
    const loaded = getSharedLoadedPlaybackReports() ?? [];
    const finished = getSharedFinishedPlaybackReports() ?? [];

    if (loaded.length > 0) saveSharedLoadedPlaybackReports([]);
    if (finished.length > 0) saveSharedFinishedPlaybackReports([]);

    return { loaded, finished };
}

export function getAndDeleteReports(session: AppSession) {
    const storageId = session.storageId;

    const loaded = getLoadedPlaybackReports(storageId) ?? [];
    const finished = getFinishedPlaybackReports(storageId) ?? [];

    if (loaded.length > 0) saveLoadedPlaybackReports(storageId, []);
    if (finished.length > 0) saveFinishedPlaybackReports(storageId, []);

    return { loaded, finished };
}

export function transferReportsToSharedStorage(storageId: string) {
    const loaded = getLoadedPlaybackReports(storageId) ?? [];
    if (loaded.length > 0) {
        const loadedShared = getSharedLoadedPlaybackReports() ?? [];
        const loadedAll = [...loadedShared, ...loaded];

        saveLoadedPlaybackReports(storageId, []);
        saveSharedLoadedPlaybackReports(loadedAll);
    }

    const finished = getFinishedPlaybackReports(storageId) ?? [];
    if (finished.length > 0) {
        const finishedShared = getSharedFinishedPlaybackReports() ?? [];
        const finishedAll = [...finishedShared, ...finished];

        saveFinishedPlaybackReports(storageId, []);
        saveSharedFinishedPlaybackReports(finishedAll);
    }
}

export function restorePlaybackReports() {
    restoreLoadedPlaybackReports();
    restoreFinishedPlaybackReports();
}
