import { ScrollerProps } from "./Scroller";
import { ScrollRestorationRef } from "components/shared/hooks";

export enum ScrollerSize {
    Medium = "medium",
    MediumLarge = "mediumLarge",
    Large = "large"
}

export enum ScrollerPurpose {
    Selection = "selection",
    Scroll = "scroll"
}

export enum ScrollerAlign {
    Left = "left",
    Center = "center"
}

export interface ScrollerSnap {
    type: ScrollerSnapType;
    stop: ScrollerSnapStop;
}

export enum ScrollerSnapType {
    Proximity = "proximity",
    Mandatory = "mandatory"
}

export enum ScrollerSnapStop {
    Normal = "normal",
    Always = "always"
}

export enum ScrollerEffect {
    None = "none",
    ActiveGrow = "activeGrow"
}

export enum ScrollerState {
    None = "none",
    ComponentLoad = "componentLoad",
    ExternalChange = "externalChange",
    ButtonClick = "buttonClick",
    UserScroll = "userScroll",
    UserScrollStop = "userScrollStop"
}

export type ScrollerNavigateCallback = (direction: -1 | 1) => void;
export type ScrollerNavigatePropeties = { left: boolean; right: boolean };
export type ScrollerNavigatePropetiesCallback = (properties: ScrollerNavigatePropeties) => void;

export const maxiPlayerScrollerOptions = (
    size: ScrollerSize | number,
    restoration: ScrollRestorationRef,
    onSelectedChange: (index: number) => void,
    index?: number
): ScrollerProps => {
    const props: ScrollerProps = {
        size,
        restoration,
        align: ScrollerAlign.Center,
        scrollSnap: { type: ScrollerSnapType.Mandatory, stop: ScrollerSnapStop.Always },
        purpose: ScrollerPurpose.Selection,
        effect: ScrollerEffect.ActiveGrow,
        onSelectedChange,
        index
    };
    return props;
};
