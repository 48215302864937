import { h } from "preact";
import { useCallback } from "preact/hooks";
import { getPaginationInit } from "global/constants/pagination";
import { getArtistAppearsOnAlbumsPagination, useArtistAppearsOnPage } from "services/backend/backend";
import { convertToAlbumSortOption } from "services/sort/sortService";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import { MediaSize, useAppMediaSize, useSortOptions } from "components/shared/hooks";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    artistId: string;
}

export const ArtistAppearsOnShowAllPage = ({ artistId }: Props) => {
    const sortOptions = useSortOptions({ context: ContentType.ArtistAppearsOn });
    const orderBy = convertToAlbumSortOption(sortOptions.selected);

    const query = useArtistAppearsOnPage({ id: artistId, first: getPaginationInit(), orderBy });
    const resource = query.model;

    const fetchFn = useCallback((first: number, after: string) => getArtistAppearsOnAlbumsPagination({ first, after, id: artistId, orderBy }), [artistId, orderBy]);

    const display = useAppMediaSize() === MediaSize.Mobile ? ResourceDisplayType.ListLarge : ResourceDisplayType.Grid;

    return <ShowAllPageTemplate query={query} type={ContentType.ArtistAppearsOn} items={resource?.albums ?? null} fetchFn={fetchFn} display={display} sortOptions={sortOptions} />;
};
