import { updatePlayQueue } from "./updatePlayQueue";
import { store } from "global";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { PlayQueueModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";

export const clearQueue = async (context: AudioContextModel) => {
    const { queue } = store.getState();
    const newQueue: PlayQueueModel = {
        ...queue,
        playQueue: [],
        context: null,
        trackParent: null
    };
    await updatePlayQueue(newQueue, context, false, null);
};

export function getClearedQueue(queue: PlayQueueModel, keepQueueLane?: boolean): PlayQueueModel {
    const newPlayQueue = keepQueueLane ? queue.playQueue.filter((item) => item.lane === QueueLane.Priority) : [];
    return { ...queue, playQueue: newPlayQueue };
}
