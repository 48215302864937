import { findLastIndex } from "services/arrayHelper";
import type { PlayQueueModel, QueueTrackModel } from "models/app/player/input";
import { QueueLane } from "models/app/player/input";

export const getPlayQueueCurrentLane = (playQueue: QueueTrackModel[]) => playQueue.find((item) => item.lane === QueueLane.Current);
export const getPlayQueuePriorityLane = (playQueue: QueueTrackModel[]) => playQueue.filter((item) => item.lane === QueueLane.Priority);
export const getPlayQueueFlowLane = (playQueue: QueueTrackModel[]) => playQueue.filter((item) => item.lane === QueueLane.Flow);
export const getPlayQueueMemoryLane = (playQueue: QueueTrackModel[]) => playQueue.filter((item) => item.lane === QueueLane.Memory);

let _previousLaneMap: Map<QueueLane, QueueLane>;
const previousLaneMap = () => {
    if (!_previousLaneMap) {
        _previousLaneMap = new Map<QueueLane, QueueLane>([
            [QueueLane.Preview, QueueLane.Flow],
            [QueueLane.Flow, QueueLane.Priority],
            [QueueLane.Priority, QueueLane.Current],
            [QueueLane.Current, QueueLane.Memory]
        ]);
    }
    return _previousLaneMap;
};

export const getTrackIndexInQueueLane = (playQueue: QueueTrackModel[], lane: QueueLane, atEnd: boolean): number => {
    if (atEnd) {
        const index = findLastIndex(playQueue, (value) => value.lane === lane);
        if (index !== -1) return index + 1;
    } else {
        const index = playQueue.findIndex((value) => value.lane === lane);
        if (index !== -1) return index;
    }

    const previous: QueueLane | null = previousLaneMap().get(lane) ?? null;
    if (previous == null) return 0;

    return getTrackIndexInQueueLane(playQueue, previous, atEnd);
};

export const updateQueueLanes = (playQueue: QueueTrackModel[], newCurrentIndex: number) => {
    const newCurrent = playQueue[newCurrentIndex];

    const newPlayQueue = playQueue.map((item, index) => {
        let newLane: QueueLane;

        if (index < newCurrentIndex) {
            if (item.lane === QueueLane.Priority) {
                if (newCurrent.lane === QueueLane.Priority) {
                    newLane = QueueLane.Memory;
                } else {
                    newLane = QueueLane.Priority;
                }
            } else {
                newLane = QueueLane.Memory;
            }
        } else if (index > newCurrentIndex) {
            if (item.lane === QueueLane.Priority) {
                newLane = QueueLane.Priority;
            } else {
                newLane = QueueLane.Flow;
            }
        } else {
            newLane = QueueLane.Current;
        }
        return { ...item, lane: newLane };
    });

    const getQueueLanePriority = (queueLane: QueueLane): number => {
        switch (queueLane) {
            case QueueLane.Memory:
                return 0;
            case QueueLane.Current:
                return 1;
            case QueueLane.Priority:
                return 2;
            case QueueLane.Flow:
                return 3;
            case QueueLane.Preview:
                return 4;
        }
    };
    newPlayQueue.sort((a, b) => {
        const aLane = getQueueLanePriority(a.lane);
        const bLane = getQueueLanePriority(b.lane);
        return aLane - bLane;
    });
    return newPlayQueue;
};

export const movePreviewLaneToFlowLane = (queue: PlayQueueModel) => {
    let playQueue: QueueTrackModel[] = queue.playQueue;

    if (!queue.endlessPlay.length) return playQueue;

    const endlessPlay = queue.endlessPlay.map((item) => ({ ...item, lane: QueueLane.Flow }));
    playQueue = [...playQueue, ...endlessPlay];

    return playQueue;
};
