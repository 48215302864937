import type { ComponentChildren } from "preact";
import { Fragment, h } from "preact";
import { useCallback, useState } from "preact/hooks";
import "./ResourcePreviewRowExpandContent.scss";
import { PreviewAction } from "../PreviewProps";
import { getPWAInstallOption, promptUserForPWAInstall, PWAInstallOption } from "services/pwa/initPWAService";
import type { ExpandResourceModel } from "services/resource";
import { getIsExpandResource } from "services/resource";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import type { ResourcePreviewModel } from "models/domain";
import { getNavigationConversionResourceType, NotificationConversion } from "models/domain/NotificationModel";
import type { NotificationContentModel, NotificationContentWrapperModel } from "models/domain/NotificationModel";
import { useOptionalLinkContext } from "components/shared/hooks";
import { Button, ButtonDesign } from "components/atoms/controls/button";
import { IconName } from "components/atoms/icon";

interface UseResourcePreviewExpandContentProps {
    resource: ResourcePreviewModel;
    preview: PreviewContextModel;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
}

export function useResourcePreviewExpandContent({ resource, preview, onAction }: UseResourcePreviewExpandContentProps) {
    const [open, setOpen] = useState(false);

    const hasExpandContent = getIsExpandResource(resource);
    const content = hasExpandContent && ResourcePreviewRowExpandContent({ open, resource, preview, onAction });

    const toggle = useCallback(() => setOpen(!open), [open]);
    const iconName = open ? IconName.ArrowUpSmall : IconName.ArrowDownSmall;

    const button = hasExpandContent && <Button icon={iconName} inline={{ right: true }} onClick={toggle} />;

    return { toggle, button, content };
}

interface ResourcePreviewRowExpandContentProps {
    resource: ExpandResourceModel;
    open: boolean;
    preview: PreviewContextModel;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
}

const ResourcePreviewRowExpandContent = ({ open, resource, preview, onAction }: ResourcePreviewRowExpandContentProps) => {
    if (!open) return null;
    return (
        <div className="resourcePreviewRowExpandContent eIE">
            <Content preview={preview} onAction={onAction}>
                {resource.content}
            </Content>
        </div>
    );
};

interface WrapperProps {
    element: NotificationContentWrapperModel;
    children: ComponentChildren;
    preview: PreviewContextModel;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
}

const Wrapper = ({ element, children, preview, onAction }: WrapperProps) => {
    switch (element.type) {
        case "Bullet":
            return <li>{children}</li>;
        case "Headline":
            return <h1>{children}</h1>;
        case "Link":
            return (
                <NotificationAction preview={preview} conversion={element.action} onAction={onAction}>
                    {children}
                </NotificationAction>
            );
        case "Paragraph":
            return <p>{children}</p>;
        case "Section":
            return <section>{children}</section>;
        case "BulletGroup":
            return <ul>{children}</ul>;
    }
};

interface ContentProps {
    children: NotificationContentModel;
    preview: PreviewContextModel;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
}

const Content = ({ children, preview, onAction }: ContentProps) => {
    if (children.type === "Text") return <Fragment>{children.text}</Fragment>;

    return (
        <Wrapper element={children} preview={preview} onAction={onAction}>
            {children.children.map((child, i) => (
                <Content key={i} preview={preview} onAction={onAction}>
                    {child}
                </Content>
            ))}
        </Wrapper>
    );
};

interface NotificationLinkProps {
    children: ComponentChildren;
    preview: PreviewContextModel;
    conversion: NotificationConversion;
    onAction?: (action: PreviewAction, preview: PreviewContextModel, conversion: NotificationConversion | null) => void;
}

const NotificationAction = (props: NotificationLinkProps) => {
    if (props.conversion === NotificationConversion.InstallPWA) return <NotificationDirectAction {...props} />;
    else return <NotificationLink {...props} />;
};

const NotificationLink = ({ preview, conversion, children, onAction: onActionProp }: NotificationLinkProps) => {
    const type = getNavigationConversionResourceType(conversion);
    const link = useOptionalLinkContext(type, null, preview);

    const onClick = useCallback(async () => {
        if (!preview.resource) return;
        onActionProp && onActionProp(PreviewAction.Navigate, preview, conversion);
    }, [conversion, preview, onActionProp]);

    if (!link) return null;

    return (
        <Button design={ButtonDesign.TextBrandInline} link={link} onClick={onClick}>
            {children}
        </Button>
    );
};

const NotificationDirectAction = ({ conversion, preview, children, onAction: onActionProp }: NotificationLinkProps) => {
    const onClick = useCallback(async () => {
        if (conversion !== NotificationConversion.InstallPWA) return;

        if (!preview.resource) return;

        onActionProp && onActionProp(PreviewAction.Navigate, preview, conversion);
        promptUserForPWAInstall();
    }, [conversion, preview, onActionProp]);

    if (conversion === NotificationConversion.InstallPWA) {
        const option = getPWAInstallOption();

        if (option === PWAInstallOption.Installed) return <p disabled>Allerede installeret</p>;
        if (option === PWAInstallOption.None) return <p disabled>Kan ikke installeres lige nu</p>;
    }

    return (
        <Button design={ButtonDesign.TextBrandInline} onClick={onClick}>
            {children}
        </Button>
    );
};
