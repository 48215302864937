import type { PlayQueuePlayableModelInterface, TrackParentModel, TrackPreviewModel } from ".";
import { ContentType, DomainModelType } from "models/ModelType";

export type TrackPlayableModel = TrackPlayablePreviewModel;
export type TrackPlayablePreviewModel = { type: DomainModelType.Preview; contentType: ContentType.TrackPlayable } & TrackPlayablePreview;

interface TrackPlayablePreview extends PlayQueuePlayableModelInterface {
    readonly id: string;
    readonly track: TrackPreviewModel;
    readonly parent: TrackParentModel;
}

export function createTrackPlayablePreview(track: TrackPreviewModel, parent: TrackParentModel): TrackPlayablePreviewModel {
    return {
        graphType: track.graphType,
        id: track.id,
        type: DomainModelType.Preview,
        cover: track.cover,
        contentType: ContentType.TrackPlayable,
        track,
        parent,
        trackCount: 1
    };
}
