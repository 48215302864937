import { getAnalyticsSettingChangedEvent } from "../events";
import { getAnalyticsChangeSettingProperties } from "../properties/event";
import type { AnalyticsKey } from "global/config";
import { getIsEndlessPlayOnFromState } from "components/shared/hooks";

export async function getChangeSettingEndlessPlayData() {
    const value: AnalyticsKey = getIsEndlessPlayOnFromState() ? "On" : "Off";
    const endlessPlayData = getAnalyticsChangeSettingProperties("EndlessPlay", value);
    const data = await getAnalyticsSettingChangedEvent(endlessPlayData);
    return data;
}
