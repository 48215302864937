import { store } from "global";
import { playerConfig } from "global/constants";
import { getCurrentAudio } from "services/player/inputs/service/helpers";
import { AudioInputType } from "models/app/player";
import type { AudioInputItemModel } from "models/app/player/input";

interface LocalCastQueue {
    audios: AudioInputItemModel[];
    audiosCastIds: number[];
    currentIndex: number | null;
    currentAudio: AudioInputItemModel | null;
}

const initial: LocalCastQueue = {
    audios: [],
    currentIndex: null,
    currentAudio: null,
    audiosCastIds: []
};

let queue = initial;

export function getLocalCastQueue() {
    return queue;
}

export function updateLocalCastQueue() {
    queue = _getLocalCastQueue();
}

export function resetLocalCastQueue() {
    queue = initial;
}

function _getLocalCastQueue(): LocalCastQueue {
    let inputAudios = store.getState().audioInput.audios;
    inputAudios = inputAudios.filter((audio) => (audio.input === AudioInputType.LiveRadio ? !!audio.url2 : true));
    if (inputAudios.length == 0) return { audios: [], audiosCastIds: [], currentIndex: null, currentAudio: null };

    const current = getCurrentAudio(inputAudios);

    let currentIndex = current.currentIndex;
    const currentAudio = current.currentAudio;

    if (currentIndex == null || currentAudio == null) return { audios: [], audiosCastIds: [], currentIndex: null, currentAudio: null };

    let sliceIndex = currentIndex - playerConfig.chromecastQueueMaxLookbackCount;
    if (sliceIndex < 0) sliceIndex = 0;

    const audios = inputAudios.slice(sliceIndex, sliceIndex + playerConfig.chromecastQueueMaxCount);
    currentIndex = currentIndex - sliceIndex;

    const audiosCastIds = audios.map((audio) => audio.chromecastId);
    // console.warn({ audios, audiosCastIds, currentIndex, currentAudio });

    return { audios, audiosCastIds, currentIndex, currentAudio };
}
