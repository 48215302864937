import { useSelector } from "react-redux";
import { getAudioPropertiesFromState, useAudioProperties } from "./useAudioProperties";
import { useCurrentLiveRadioFromState } from "./useCurrentLiveRadio";
import { useQueueCurrentLaneFromState, useQueueCurrentLaneParent } from "./useQueueCurrentLane";
import { store } from "global";
import { DefaultLogMessage, log } from "services/logger";
import { getCurrentLiveRadioFromState } from "services/player/inputs/inputs/liveRadio/service";
import { getCurrentLaneFromState, getCurrentLaneTrackParentFromState } from "services/player/inputs/inputs/playQueue/helpers";
import { isResourceEqual } from "services/resource";
import type { RootModel } from "models/app";
import { AudioInputType, PlayState } from "models/app/player";
import type { QueueTrackModel } from "models/app/player/input";
import type { LiveRadioModel, PlayableModel, TrackParentModel, ResourceModel } from "models/domain";
import type { LiveRadioPlayableModel } from "models/domain/LiveRadioPlayableModel";
import { ContentType } from "models/ModelType";

export const usePlayablePropertiesFromState = (playable: PlayableModel) => {
    const input = useSelector((root: RootModel) => root.player.input);
    const liveRadio = useCurrentLiveRadioFromState();
    const currentLane = useQueueCurrentLaneFromState();
    const currentLaneParent = useQueueCurrentLaneParent(currentLane);
    const playState = useAudioProperties().playState;
    return getPlayableProperties(playable, input, liveRadio, playState, currentLane, currentLaneParent);
};

export function getPlayablePropertiesFromState(playable: PlayableModel) {
    const input = store.getState().player.input;
    const liveRadio = getCurrentLiveRadioFromState();
    const currentLane = getCurrentLaneFromState();
    const currentLaneParent = getCurrentLaneTrackParentFromState();
    const playState = getAudioPropertiesFromState().playState;
    return getPlayableProperties(playable, input, liveRadio, playState, currentLane, currentLaneParent);
}

function getPlayableProperties(
    playable: PlayableModel,
    input: AudioInputType,
    liveRadio: LiveRadioPlayableModel | null,
    playState: PlayState,
    currentLane: QueueTrackModel | null,
    currentLaneParent: TrackParentModel | null
) {
    if (playable.contentType === ContentType.LiveRadio) {
        return getLiveRadioProperties(playable, liveRadio?.liveRadio ?? null, playState, input);
    }
    if (playable.contentType === ContentType.LiveRadioPlayable) {
        return getLiveRadioPlayableProperties(playable, liveRadio, playState, input);
    }
    return getPlayQueuePlayableProperties(playable, currentLane, currentLaneParent, playState, input);
}

function getPlayQueuePlayableProperties(
    playable: PlayableModel,
    currentLane: QueueTrackModel | null,
    currentLaneParent: TrackParentModel | null,
    playState: PlayState,
    input: AudioInputType
) {
    let isLoaded: boolean;

    const isInputePlayQueue = input === AudioInputType.PlayQueue;
    if (!isInputePlayQueue) return { isLoaded: false, isPlayingOrBuffering: false, isPlaying: false };

    if (playable.contentType === ContentType.TrackPlayable) {
        const isLoadedTrack = currentLane != null && isResourceEqual(playable.track, currentLane.track);
        const isLoadedContext = currentLaneParent != null && isResourceSimilar(playable.parent, currentLaneParent);
        isLoaded = isLoadedTrack && isLoadedContext;
    } else {
        const isLoadedContext = currentLaneParent != null && isResourceSimilar(playable, currentLaneParent);
        isLoaded = isLoadedContext;
    }

    const isPlayingOrBuffering = isLoaded && (playState === PlayState.Buffering || playState === PlayState.Playing);
    const isPlaying = isLoaded && playState === PlayState.Playing;
    return { isLoaded, isPlayingOrBuffering, isPlaying };
}

function isArtistSimilar(resource1: ResourceModel, resource2: ResourceModel) {
    const isArtist = (parent: ResourceModel) => parent.contentType === ContentType.Artist || parent.contentType === ContentType.ArtistTracks;
    if (isArtist(resource1) && isArtist(resource2) && resource1.id === resource2.id) return true;

    return false;
}

function isResourceSimilar(resource1: ResourceModel, resource2: ResourceModel) {
    if (resource1.contentType === ContentType.Notification && resource1.playable && isResourceEqual(resource1.playable, resource2)) return true;
    if (resource2.contentType === ContentType.Notification && resource2.playable && isResourceEqual(resource2.playable, resource1)) return true;
    if (isResourceEqual(resource1, resource2)) return true;
    if (isArtistSimilar(resource1, resource2)) return true;
    return false;
}

function getLiveRadioPlayableProperties(playable: PlayableModel, liveRadio: LiveRadioPlayableModel | null, playState: PlayState, input: AudioInputType) {
    if (playable.contentType !== ContentType.LiveRadioPlayable) {
        log.error({ code: "web-210929-1507", msg: DefaultLogMessage.UnexpectedValue });
        return { isLoaded: false, isPlayingOrBuffering: false, isPlaying: false };
    }

    if (input !== AudioInputType.LiveRadio) return { isLoaded: false, isPlayingOrBuffering: false, isPlaying: false };

    const isLoaded = liveRadio != null && isResourceEqual(playable, liveRadio);
    const isPlayingOrBuffering = isLoaded && (playState === PlayState.Buffering || playState === PlayState.Playing);
    const isPlaying = isLoaded && playState === PlayState.Playing;

    return { isLoaded, isPlayingOrBuffering, isPlaying };
}
function getLiveRadioProperties(playable: PlayableModel, radio: LiveRadioModel | null, playState: PlayState, input: AudioInputType) {
    if (playable.contentType !== ContentType.LiveRadio) {
        log.error({ code: "web-220302-1507", msg: DefaultLogMessage.UnexpectedValue });
        return { isLoaded: false, isPlayingOrBuffering: false, isPlaying: false };
    }

    if (input !== AudioInputType.LiveRadio) return { isLoaded: false, isPlayingOrBuffering: false, isPlaying: false };

    const isLoaded = radio != null && isResourceEqual(playable, radio);
    const isPlayingOrBuffering = isLoaded && (playState === PlayState.Buffering || playState === PlayState.Playing);
    const isPlaying = isLoaded && playState === PlayState.Playing;

    return { isLoaded, isPlayingOrBuffering, isPlaying };
}
