import { h } from "preact";
import { ErrorPage } from "./ErrorPage";
import type { PageLoadError } from "models/app/ViewPageError";
import { PageLoadAppErrorType } from "models/app/ViewPageError";
import { ContentType } from "models/ModelType";
import { usePageContext } from "components/shared/hooks";

export const NotFoundPage = () => {
    const page = usePageContext({ type: ContentType.NotFound, resource: null, root: null, done: false });

    const error: PageLoadError = {
        type: PageLoadAppErrorType.UrlSchemaNotRecognizedError,
        message: `url schema not recognized, url:${window.location.href}`
    };

    return <ErrorPage page={page} error={error} />;
};
