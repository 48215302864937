import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import type { CustomRef } from ".";
import { useRefUpdate } from ".";
import { useResizeObserver } from "./useResizeObserver";
import { readPageScrollState, readSectionScrollState, savePageScrollState, saveSectionScrollState } from "services/navigationStack/navigationStack";
import type { PageContextModel, SectionContextModel } from "models/app/resourceContextModel";
import type { ScrollStateModel } from "models/view/navigationStack";

interface PageScrollRestorationProps {
    page: PageContextModel | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function usePageScrollRestoration({ page }: PageScrollRestorationProps) {
    const onChange = useCallback((state: ScrollStateModel) => savePageScrollState(state), []);
    const state = useMemo(() => readPageScrollState(), []);

    return useScrollRestoration({ state, onChange });
}

interface SectionScrollRestorationProps {
    section: SectionContextModel | null;
}

export function useSectionScrollRestoration({ section }: SectionScrollRestorationProps) {
    const onChange = useCallback((state: ScrollStateModel) => (section ? saveSectionScrollState(section, state) : null), [section]);
    const state = useMemo(() => (section ? readSectionScrollState(section) : null), [section]);

    return useScrollRestoration({ state, onChange });
}

interface ScrollRestorationProps {
    onChange: (state: ScrollStateModel) => void;
    state: ScrollStateModel | null;
}

export interface ScrollRestorationRef {
    scrollRef: CustomRef<HTMLElement>;
    contentRef: CustomRef<HTMLElement>;
}

export function useScrollRestoration({ state, onChange }: ScrollRestorationProps): ScrollRestorationRef {
    const scrollRef = useRefUpdate<HTMLElement>();
    const contentRef = useRefUpdate<HTMLElement>();
    const scroll = scrollRef.current;
    const content = contentRef.current;

    const scrollSize = useResizeObserver(scroll);
    const contentSize = useResizeObserver(content);

    const [restore, setRestore] = useState(true);

    useEffect(() => {
        if (!state || !restore || !scroll || !scrollSize || !contentSize) return;
        if (!canScrollToContent(state, scrollSize, contentSize)) return;

        setRestore(false);
        const to: ScrollToOptions = { left: state.x ?? undefined, top: state.y ?? undefined, behavior: "auto" };
        scroll.scrollTo(to);
    }, [state, scroll, scrollSize, contentSize, restore]);

    useEffect(() => {
        if (!scroll) return;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onScroll = (e: any) => {
            const y: number | null = e.target?.scrollTop ?? null;
            const x: number | null = e.target?.scrollLeft ?? null;
            if (y == null && x == null) return;

            setRestore(false);
            onChange({ x, y });
        };

        scroll.addEventListener("scroll", onScroll, { passive: true });
        return () => scroll.removeEventListener("scroll", onScroll);
    }, [scroll, onChange]);

    return { scrollRef, contentRef };
}

function canScrollToContentOnAxis(scroll: number, containerSize: number, contentSize: number) {
    const maxScrollHeight = contentSize - containerSize;
    return scroll <= maxScrollHeight;
}

function canScrollToContent(scroll: ScrollStateModel, container: DOMRectReadOnly, content: DOMRectReadOnly) {
    if (scroll.x && !canScrollToContentOnAxis(scroll.x, container.width, content.width)) return false;
    if (scroll.y && !canScrollToContentOnAxis(scroll.y, container.height, content.height)) return false;
    return true;
}
