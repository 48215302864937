import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Link } from "..";
import { useTranslations } from "global/config";
import { ContentType } from "models/ModelType";
import { useLinkAndPreviewContext, useSectionContext } from "components/shared/hooks";
import { IconName } from "components/atoms/icon";

interface Props {
    showIcon: boolean;
}

export const GoToProfilesLink = ({ showIcon }: Props) => {
    const section = useSectionContext(ContentType.ProfilesPage, null, null, null, null);
    const link = useLinkAndPreviewContext(ContentType.ProfilesPage, null, section, null);
    const translations = useTranslations();

    const click = useCallback(() => {
        // todo: dispatch event?
    }, []);

    return (
        <Link closeOnClick icon={showIcon ? IconName.Settings : undefined} onClick={click} link={link}>
            {translations.ManageProfiles}
        </Link>
    );
};
