import { h } from "preact";
import { useMemo, useCallback, useEffect } from "preact/hooks";
import { useSelector } from "react-redux";
import { CurrentLane } from "./CurrentLane";
import { CurrentLanePreview } from "./CurrentLanePreview";
import { EmptyQueue } from "./EmptyQueue";
import { FlowLane } from "./FlowLane";
import { isDraggableCurrentLane } from "./isDraggableCurrentLane";
import { MemoryLane } from "./MemoryLane";
import { PreviewLane } from "./PreviewLane";
import { PriorityLane } from "./PriorityLane";
import { TrackQueueActions } from "./TrackQueueActions";
import { dispatch } from "global";
import { PLAYQUEUE_DROP, PLAYQUEUE_HISTORY_TOGGLE } from "global/actions";
import { animations } from "global/constants";
import { getNextUUID } from "services/utils";
import type { DragProps, DraggableItemModel, RootModel } from "models/app";
import { createAndStartUserPlayTraceModel, TraceName } from "models/app";
import { AudioContextAction } from "models/app/player/AudioContext";
import type { QueueTrackModel, QueueLane } from "models/app/player/input";
import { ContentType } from "models/ModelType";
import { useSectionContext, useRefUpdate, useQueueLanes, useEndlessPlayFromState } from "components/shared/hooks";
import { ScrollerWithFade } from "components/organisms/scrollerWithFade";

interface Props {
    onCurrentTrackClick?: () => void;
    onCurrentCoverClick?: () => void;
    disableFade?: boolean;
}

export const TrackQueue = ({ onCurrentTrackClick, onCurrentCoverClick, disableFade }: Props) => {
    const { isQueueEmpty, memory, current, priority, isPriorityLaneEmpty, flow, isFlowLaneEmpty, isMemoryLaneEmpty, playQueue } = useQueueLanes();
    const { endlessPlay, isEndlessPlayEmpty } = useEndlessPlayFromState();
    const { historyOpen } = useSelector((root: RootModel) => root.queue);
    const sectionContext = useSectionContext(ContentType.Queue, null, null, null, null);

    const dragProps: DragProps = useMemo(() => ({ dragSourceId: getNextUUID() }), []);
    const toggleHistory = useCallback(() => dispatch({ type: PLAYQUEUE_HISTORY_TOGGLE }), []);
    const { dragSourceId } = dragProps;

    const scrollRef = useRefUpdate<HTMLDivElement>();

    const historyScrollLength = useCallback((length: number) => {
        const itemsToShow = 2.5;
        const headerHeight = 56;
        const queueTrackHeight = 64;
        if (length < itemsToShow) return 0;
        const scrollLength = (length - itemsToShow) * queueTrackHeight + headerHeight;
        return scrollLength;
    }, []);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = historyOpen ? historyScrollLength(memory.length) : 0;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyOpen]);

    const onDrop = useCallback(
        async (item: DraggableItemModel, lane: QueueLane, end: boolean, queueTrack?: QueueTrackModel) => {
            if (isDraggableCurrentLane(item)) return;
            const trace = await createAndStartUserPlayTraceModel(TraceName.ClickToSound);
            const action = item.dragSourceId === dragSourceId ? "move" : "add";
            dispatch({
                type: PLAYQUEUE_DROP,
                payload: {
                    playable: item.playable,
                    queueTrack,
                    lane,
                    atEnd: end,
                    context: { action: AudioContextAction.UserPreviewDragDrop, trace },
                    action,
                    playableContext: item.context
                }
            });
        },
        [dragSourceId]
    );
    const scrollOnTrackClickFn = useCallback(
        (id: string) => {
            if (!scrollRef.current) return;
            const queueIndex = playQueue.findIndex((item) => item.compareableTrackId === id);
            const scrollTo = historyOpen ? historyScrollLength(queueIndex) : 0;
            scrollRef.current.scrollTo({ top: scrollTo, behavior: "smooth" });
        },
        [scrollRef, playQueue, historyOpen, historyScrollLength]
    );

    return (
        <div className={`queue LYy --animate-${animations.animateQueue}`}>
            <TrackQueueActions isQueueEmpty={isQueueEmpty} disableHistoryButton={isMemoryLaneEmpty && !current} historyOpen={historyOpen} historyClick={toggleHistory} />
            {current && (
                <CurrentLanePreview
                    dragProps={dragProps}
                    currentLane={current}
                    sectionContext={sectionContext}
                    onCurrentTrackClick={onCurrentTrackClick}
                    onCurrentCoverClick={onCurrentCoverClick}
                />
            )}
            {isQueueEmpty ? (
                <EmptyQueue onDrop={onDrop} />
            ) : (
                <ScrollerWithFade tabIndex={-1} ref={scrollRef} disableFade={disableFade}>
                    {!isMemoryLaneEmpty && historyOpen && (
                        <MemoryLane
                            items={memory}
                            scrollRef={scrollRef}
                            scrollOnTrackClickFn={scrollOnTrackClickFn}
                            dragProps={dragProps}
                            sectionContext={sectionContext}
                            dragSourceId={dragSourceId}
                            onDrop={onDrop}
                        />
                    )}
                    {historyOpen && current && (
                        <CurrentLane
                            items={[current]}
                            scrollRef={scrollRef}
                            scrollOnTrackClickFn={scrollOnTrackClickFn}
                            disableHeaderDrop={!isMemoryLaneEmpty}
                            dragProps={dragProps}
                            sectionContext={sectionContext}
                            onDrop={onDrop}
                        />
                    )}
                    {!isPriorityLaneEmpty && (
                        <PriorityLane
                            items={priority}
                            scrollRef={scrollRef}
                            scrollOnTrackClickFn={scrollOnTrackClickFn}
                            dragProps={dragProps}
                            sectionContext={sectionContext}
                            dragSourceId={dragSourceId}
                            onDrop={onDrop}
                            historyOpen={historyOpen}
                        />
                    )}
                    {!isFlowLaneEmpty && (
                        <FlowLane
                            items={flow}
                            scrollRef={scrollRef}
                            scrollOnTrackClickFn={scrollOnTrackClickFn}
                            dragProps={dragProps}
                            sectionContext={sectionContext}
                            dragSourceId={dragSourceId}
                            onDrop={onDrop}
                            isPriorityLaneEmpty={isPriorityLaneEmpty}
                        />
                    )}
                    {!isEndlessPlayEmpty && (
                        <PreviewLane
                            items={endlessPlay}
                            scrollRef={scrollRef}
                            scrollOnTrackClickFn={scrollOnTrackClickFn}
                            dragProps={dragProps}
                            sectionContext={sectionContext}
                            onDrop={onDrop}
                            expandDropZone={isPriorityLaneEmpty && isFlowLaneEmpty}
                        />
                    )}
                </ScrollerWithFade>
            )}
        </div>
    );
};
