import { castPause, castPlay } from "../castRequests/media";
import { castQueueSeek } from "../castRequests/media/seek";
import { getCurrentTimeFromPosition, convertToPlayState, getCurrentAudioInSync } from "../helpers";
import { getCastMediaSession, getCastPlayer, tryGetCastMediaSession } from "../setup";
import { handleLastInQueueAndStopped } from "./syncCurrent";
import { dispatch } from "global";
import { AUDIO_OUTPUT_AUDIO_DURATION_CHANGE, AUDIO_OUTPUT_AUDIO_PLAY_CHANGE } from "global/actions";
import { log, LogTag } from "services/logger";
import { getPositionFromCurrentTime, isPlayPausePositionAlmostEqual, isPlayStatePlayIntent } from "services/player/helpers";
import { tryGetAudioPosition } from "services/player/inputs/service/helpers";
import type { PlayPausePositionModel } from "models/app/player";
import { AudioInputType, PlayState } from "models/app/player";
import type { AudioContextModel } from "models/app/player/AudioContext";
import type { AudioInputItemModel } from "models/app/player/input";

export async function syncPlayStateFromLocal(): Promise<boolean> {
    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `syncPlayStateFromLocal...` });

    const { currentSynced, currentLocal } = getCurrentAudioInSync();
    if (!currentSynced || !currentLocal) return true;

    const media = tryGetCastMediaSession();
    if (!media) return true;

    const stateLocal = currentLocal.playState;
    const stateRemote = convertToPlayState(media.playerState ?? chrome.cast.media.PlayerState.IDLE) ?? PlayState.Stopped;

    const playLocal = isPlayStatePlayIntent(stateLocal);
    const playRemote = isPlayStatePlayIntent(stateRemote);
    const playSame = playLocal === playRemote;

    const positionLocal = tryGetAudioPosition(currentLocal);
    const positionRemote = getPositionFromCurrentTime(media.getEstimatedTime());
    const posAlmostEqual = positionLocal ? isPlayPausePositionAlmostEqual(positionLocal, positionRemote) : true;

    if (playSame && posAlmostEqual) {
        log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `...syncPlayStateFromLocal, play intent is same and position almost equal` });
        return true;
    }

    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: `...syncPlayStateFromLocal, local: ${stateLocal}, remote: ${stateRemote}` });

    return setRemotePlayPause(playLocal, positionLocal);
}

export async function syncPlayStateFromRemote(context: AudioContextModel): Promise<boolean> {
    log.info({ tags: [LogTag.Chromecast], code: "web-230226-1819", msg: "syncPlayStateFromRemote..." });

    const last = await handleLastInQueueAndStopped(context);
    if (last) return true;

    const { currentSynced, currentLocal } = getCurrentAudioInSync();
    if (!currentSynced || !currentLocal) return true;

    const media = getCastMediaSession();
    if (!media) return false;

    const stateLocal = currentLocal.playState;
    const stateRemote = convertToPlayState(media.playerState);
    if (!stateRemote) return false;

    const player = getCastPlayer();
    if (!player) return false;

    log.info({ tags: [LogTag.Chromecast], code: "web-230210-1222", msg: `...syncPlayStateFromRemote, local: ${stateLocal}, remote: ${stateRemote}` });

    const currentTime = player.currentTime;
    const position: PlayPausePositionModel = { pausePosition: currentTime };

    await syncDuration(currentLocal);
    await dispatch({ type: AUDIO_OUTPUT_AUDIO_PLAY_CHANGE, payload: { audio: currentLocal, allowPlayChange: true, playState: stateRemote, position, context } });

    return true;
}

async function syncDuration(audio: AudioInputItemModel): Promise<boolean> {
    const player = getCastPlayer();
    if (!player) return false;

    if (audio.input === AudioInputType.PlayQueue) {
        const durationRemote = player.duration;
        const durationLocal = audio.duration;

        if (durationRemote > 0 && durationLocal != durationRemote) {
            await dispatch({ type: AUDIO_OUTPUT_AUDIO_DURATION_CHANGE, payload: { audioId: audio.audioId, duration: durationRemote } });
        }
    }

    return true;
}

export async function setRemotePlayPause(play: boolean, position: PlayPausePositionModel | null): Promise<boolean> {
    if (!position) return play ? castPlay() : castPause();

    const seek = new chrome.cast.media.SeekRequest();
    seek.currentTime = getCurrentTimeFromPosition(position);
    seek.resumeState = play ? chrome.cast.media.ResumeState.PLAYBACK_START : chrome.cast.media.ResumeState.PLAYBACK_PAUSE;

    return castQueueSeek(seek);
}
