import { h } from "preact";
import { LinkWithSubmenu } from "..";
import { ShareContextMenu } from "../menus";
import { useTranslations } from "global/config";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { isPlaylistPublic } from "models/domain";
import { ContentType } from "models/ModelType";
import { IconName } from "components/atoms/icon";

interface Props {
    context: PreviewContextModel;
    showIcon: boolean;
}

export const ShareLink = ({ context, showIcon }: Props) => {
    const resource = context.resource;
    const disabled = resource?.contentType === ContentType.Playlist && !isPlaylistPublic(resource);
    const translations = useTranslations();
    return (
        <LinkWithSubmenu disabled={disabled} icon={showIcon ? IconName.ShareAndroid : undefined} submenu={<ShareContextMenu context={context} />}>
            {translations.ContextOptionShare}
        </LinkWithSubmenu>
    );
};
