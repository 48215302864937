import { syncIsMutedFromRemote } from "../../actions/syncSettings";
import { castSetIsMutedTimeStamp } from "../../castRequests/playerController";
import { log, LogTag } from "services/logger";
import { isControllerChromecastFromState } from "services/player/controllers/service/helpers";

export function onIsMutedChanged({ value: isMuted }: cast.framework.RemotePlayerChangedEvent<boolean>) {
    if (!isControllerChromecastFromState()) return;

    log.debug([LogTag.Chromecast], () => ({ code: "web-211029-1312", msg: `onIsMutedChanged: ${isMuted}` }));

    if (new Date().getTime() - castSetIsMutedTimeStamp < 1000) return;

    syncIsMutedFromRemote();
}
