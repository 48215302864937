import { h } from "preact";
import { useCallback } from "preact/hooks";
import { dispatch } from "global";
import { USER_ADD_SEARCH_HISTORY } from "global/actions";
import { pageContent } from "global/constants/pageContent";
import { getPaginationInit } from "global/constants/pagination";
import { getSearchTracksPagination, useSearchTracksPage } from "services/backend/backend";
import { isSearchMixedResultPreviewModel } from "services/playable";
import { decodeUrlSearchString } from "services/search/searchService";
import type { PreviewContextModel } from "models/app/resourceContextModel";
import { ResourceDisplayType } from "models/domain";
import { ContentType } from "models/ModelType";
import type { PreviewAction } from "components/molecules/preview";
import { ShowAllPageTemplate } from "components/templates/showAllPage";

interface Props {
    criterion?: string;
}

export const SearchTrackResultsShowAllPage = ({ criterion: encodedCriterion }: Props) => {
    const criterion = decodeUrlSearchString(encodedCriterion ?? null) ?? "";
    const criterianOK = criterion != "";
    const max = pageContent.searchTracksPageItemsLimit;

    const query = useSearchTracksPage({ criterion, first: getPaginationInit(max) }, !criterianOK);
    const resource = query.model;
    const fetchFn = useCallback((first: number, after: string) => (criterion != null ? getSearchTracksPagination({ criterion, first, after }) : null), [criterion]);

    const display = ResourceDisplayType.List;

    const onAction = useCallback((action: PreviewAction, preview: PreviewContextModel) => {
        if (!preview.resource) return;
        if (isSearchMixedResultPreviewModel(preview.resource)) dispatch({ type: USER_ADD_SEARCH_HISTORY, payload: { item: preview.resource } });
    }, []);

    return (
        <ShowAllPageTemplate
            display={display}
            fetchFn={fetchFn}
            items={resource?.tracks ?? null}
            max={max}
            onAction={onAction}
            query={query}
            type={ContentType.SearchTrackResults}
        />
    );
};
