import { h } from "preact";
import { useCallback } from "preact/hooks";
import { Section } from "./Section";
import { dispatch } from "global";
import { PLAYER_TOGGLE_FAVORITE } from "global/actions";
import { useTranslations } from "global/config";
import { TestLocator } from "global/constants";
import type { FavoritePreviewModel } from "services/favorites";
import { getQueueTrackPlayable } from "services/player/inputs/inputs/playQueue/helpers";
import { isFavorite } from "generated/graphql-types";
import type { DraggablePlayableModel } from "models/app";
import type { SectionContextModel } from "models/app/resourceContextModel";
import { ContentType } from "models/ModelType";
import { useLinkAndPreviewContext } from "components/shared/hooks";
import { ButtonDesign, Button } from "components/atoms/controls/button";
import { DropZone } from "components/molecules/dropZone";

export const MyMusic = ({ section, minimal }: { section: SectionContextModel; minimal: boolean }) => {
    const addFavorites = useCallback(
        (playable: FavoritePreviewModel) => {
            dispatch({ type: PLAYER_TOGGLE_FAVORITE, payload: { section, playables: [playable], operation: isFavorite ? "remove" : "add" } });
        },
        [section]
    );
    const design = minimal ? ButtonDesign.SideBarLinkBigMinimal : ButtonDesign.SideBarLinkBig;
    const translations = useTranslations();

    const allowDropToPlaylists = useCallback((item: DraggablePlayableModel) => {
        return (
            item.playable.contentType == ContentType.Playlist &&
            item.context.section.type !== ContentType.MyMusicPlaylists &&
            item.context.section.type !== ContentType.SideBar &&
            !item.playable.owner
        );
    }, []);

    const onDropToPlaylists = useCallback(
        async (model: DraggablePlayableModel) => {
            if (model.playable.contentType == ContentType.Playlist) {
                addFavorites(model.playable);
            }
        },
        [addFavorites]
    );

    const allowDropToArtists = useCallback((item: DraggablePlayableModel) => {
        return item.playable.contentType == ContentType.Artist && item.context.section.type !== ContentType.MyMusicArtists;
    }, []);

    const onDropToArtists = useCallback(
        async (model: DraggablePlayableModel) => {
            if (model.playable.contentType == ContentType.Artist) {
                addFavorites(model.playable);
            }
        },
        [addFavorites]
    );

    const allowDropToAlbums = useCallback((item: DraggablePlayableModel) => {
        return item.playable.contentType == ContentType.Album && item.context.section.type !== ContentType.MyMusicAlbums;
    }, []);

    const onDropToAlbums = useCallback(
        async (model: DraggablePlayableModel) => {
            if (model.playable.contentType == ContentType.Album) {
                addFavorites(model.playable);
            }
        },
        [addFavorites]
    );

    const allowDropToTracks = useCallback((item: DraggablePlayableModel) => {
        return (
            (item.playable.contentType == ContentType.QueueTrack || item.playable.contentType == ContentType.TrackPlayable) &&
            item.context.section.type !== ContentType.MyMusicTracks
        );
    }, []);

    const onDropToTracks = useCallback(
        async (model: DraggablePlayableModel) => {
            switch (model.playable.contentType) {
                case ContentType.QueueTrack:
                    {
                        const track = getQueueTrackPlayable(model.playable);
                        if (track) addFavorites(track);
                    }
                    break;
                case ContentType.TrackPlayable:
                    addFavorites(model.playable);
                    break;
            }
        },
        [addFavorites]
    );

    return (
        <Section className="myMusic" top={minimal ? undefined : { title: translations.NavBarMyMusic }}>
            <div>
                <DropZone onDropFn={onDropToPlaylists} allowDrop={allowDropToPlaylists} customClass="--drop-fill" />
                <Button
                    link={useLinkAndPreviewContext(ContentType.MyMusicPlaylists, null, section, null)}
                    useText
                    useIcon
                    design={design}
                    testLocator={TestLocator.NavigationPlaylistsButton}
                />
            </div>
            <div>
                <DropZone onDropFn={onDropToArtists} allowDrop={allowDropToArtists} customClass="--drop-fill" />
                <Button
                    link={useLinkAndPreviewContext(ContentType.MyMusicArtists, null, section, null)}
                    useText
                    useIcon
                    design={design}
                    testLocator={TestLocator.NavigationArtistsButton}
                />
            </div>
            <div>
                <DropZone onDropFn={onDropToAlbums} allowDrop={allowDropToAlbums} customClass="--drop-fill" />
                <Button
                    link={useLinkAndPreviewContext(ContentType.MyMusicAlbums, null, section, null)}
                    useText
                    useIcon
                    design={design}
                    testLocator={TestLocator.NavigationAlbumsButton}
                />
            </div>
            <div>
                <DropZone onDropFn={onDropToTracks} allowDrop={allowDropToTracks} customClass="--drop-fill" />
                <Button
                    link={useLinkAndPreviewContext(ContentType.MyMusicTracks, null, section, null)}
                    useText
                    useIcon
                    design={design}
                    testLocator={TestLocator.NavigationTracksButton}
                />
            </div>
        </Section>
    );
};
