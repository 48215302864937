import type { ComponentChildren, RefObject } from "preact";
import { h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import "./TextInput.scss";
import { Button, ButtonDesign } from "../button";
import { DefaultLogMessage, log } from "services/logger/initLoggerService";
import { useResizeObserver } from "components/shared/hooks";
import { Icon, IconName } from "components/atoms/icon";

export type TextInputType = "email" | "number" | "password" | "search" | "tel" | "text" | "url";

export type AutoComplete =
    | "additional-name"
    | "address-level1"
    | "address-level2"
    | "address-level3"
    | "address-level4"
    | "address-line1"
    | "address-line2"
    | "address-line3"
    | "bday"
    | "bday-day"
    | "bday-month"
    | "bday-year"
    | "cc-additional-name"
    | "cc-csc"
    | "cc-exp"
    | "cc-exp-month"
    | "cc-exp-year"
    | "cc-family-name"
    | "cc-given-name"
    | "cc-name"
    | "cc-number"
    | "cc-type"
    | "country"
    | "country-name"
    | "current-password"
    | "email"
    | "family-name"
    | "given-name"
    | "honorific-prefix"
    | "honorific-suffix"
    | "impp"
    | "language"
    | "name"
    | "new-password"
    | "nickname"
    | "off"
    | "on"
    | "one-time-code"
    | "organization"
    | "organization-title"
    | "photo"
    | "postal-code"
    | "sex"
    | "street-address"
    | "tel"
    | "tel-area-code"
    | "tel-country-code"
    | "tel-extension"
    | "tel-local"
    | "tel-national"
    | "transaction-amount"
    | "transaction-currency"
    | "url"
    | "username";

export enum TextInputState {
    Default = "Default",
    Error = "Error"
}

interface Props {
    autoComplete: AutoComplete;
    autoFocus?: boolean;
    className?: string;
    disabled?: boolean;
    left?: IconName;
    maxLength?: number;
    message?: string;
    onBlur?: (ev: Event) => boolean;
    onChange: (value: string) => void;
    onFocus?: () => void;
    onKeyDown?: (e: h.JSX.TargetedKeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    ref?: RefObject<HTMLInputElement>;
    right?: ComponentChildren;
    spellcheck?: boolean;
    state?: TextInputState;
    type: TextInputType;
    value: string;
}

export const TextInput = ({
    autoComplete,
    autoFocus,
    className,
    disabled,
    left,
    maxLength,
    message,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    placeholder,
    ref,
    right,
    spellcheck = false,
    state = TextInputState.Default,
    type,
    value: propVal
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);

    if (ref) {
        if (inputRef.current) ref.current = inputRef.current;
    }

    const rightWidth = useResizeObserver(rightRef.current ?? null)?.width ?? 0;
    if (rightWidth > 100) log.error({ code: "web-210616-0919", msg: DefaultLogMessage.UnexpectedValue });

    const [value, setValue] = useState(propVal ?? "");
    useEffect(() => setValue(propVal), [propVal]);

    useEffect(() => {
        if (autoFocus) {
            inputRef.current?.focus();
        }
    }, [autoFocus]);

    const clearFn = () => {
        inputRef.current?.focus();
        setValue("");
        onChange("");
    };

    const input = (value: string) => {
        setValue(value);
        onChange(value);
    };

    const onBlurFn = (ev: Event) => {
        if (onBlur) {
            const blur = onBlur(ev);
            if (!blur && inputRef.current) inputRef.current.focus();
        }
    };

    const clear = value != "" ? <Button className="clear" design={ButtonDesign.DefaultSmall} icon={IconName.Delete16} onClick={clearFn} /> : null;

    const paddingLeft = `${left != null ? 24 + 24 + 16 : 24}px`;
    const paddingRight = `${rightWidth + 8 + 8}px`;

    return (
        <div className={`textInput ${className ?? ""} --state-${state}`}>
            <input
                disabled={disabled}
                style={{ paddingLeft, paddingRight }}
                width={0}
                ref={inputRef}
                type={type}
                autoComplete={autoComplete}
                value={value}
                placeholder={placeholder}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onInput={(ev) => input((ev as any).target.value)}
                onFocus={onFocus}
                onBlur={onBlurFn}
                onKeyDown={onKeyDown}
                // eslint-disable-next-line react/no-unknown-property
                spellcheck={spellcheck}
                size={1}
                maxLength={maxLength}
            />
            {left && <Icon className="left" icon={left} />}
            <div ref={rightRef} className="right">
                {right}
                {clear}
            </div>
            {message && <div className="error">{message}</div>}
        </div>
    );
};
