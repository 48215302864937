import { getPaginationInit } from "global/constants/pagination";
import { getPlaylistPage, getPlaylistTracksPagination } from "services/backend";
import { PlaylistTracksSorting } from "generated/graphql-types";
import type { PlaylistPageModel, TrackPreviewModel } from "models/domain";

// first step towards replacing the old Pagination type and assorted hooks.

// the next steps for this will be to make it generic, either by providing the two backend-methods as lambdas or via inheritance.
// it would also make sense for it to be aware of then the tracklist is 100% loader and then switch to clientside filtering.
// it would also make sense for it to cache what it has partially loaded of the unfiltered collection, so its' available when/if the filter is removed.

// this also either needs to be passed around instead of the old Pagination type, or maybe they could share a common interface for some of the properties and methods

// this can also do the conversion that otherwise is done later by useTrackPlayables(), so to avoid re-coverting every time the array is modified

export class ExperimentalProductLoader {
    model: PlaylistPageModel | null = null;
    key: string | null = null;
    playlistId: string | null = null;
    orderBy: PlaylistTracksSorting | null = null;
    criterion: string | null = null;
    callback?: (val: string) => void;

    isLoadingProduct = false;
    isLoadingTracks = false;

    private onChange() {
        this.callback?.(new Date().getTime().toString());
    }

    get success() {
        return !!this.model;
    }

    get canLoadMore() {
        return this.model?.tracks?.pageInfo?.hasNextPage ?? false;
    }

    get itemCount() {
        return (this.canLoadMore ? this.model?.trackCount : this.model?.tracks?.items?.length) ?? undefined;
    }

    setProduct(playlistId: string, orderBy: PlaylistTracksSorting, criterion: string) {
        if (this.playlistId == playlistId && this.orderBy == orderBy && this.criterion == criterion) return;

        this.playlistId = playlistId;
        this.orderBy = orderBy;
        this.criterion = criterion;

        this.key = `${playlistId}_${orderBy}_${criterion}`;
        this.reload();
    }

    reload = async (clearModel = false, clearTracks = true) => {
        const lastKey = this.key;
        try {
            if (clearModel || this.model?.id !== this.playlistId) {
                this.model = null;
            }
            if (clearTracks && this.model?.tracks) {
                this.model.tracks.items = [];
            }

            this.isLoadingProduct = !this.model;
            this.isLoadingTracks = true;

            this.onChange();

            if (!this.playlistId) return;

            const result = await getPlaylistPage({
                id: this.playlistId,
                first: getPaginationInit(),
                criterion: this.criterion,
                orderBy: this.orderBy ?? PlaylistTracksSorting.TrackPosition
            });

            if (lastKey !== this.key) return;

            if (result.error) {
                // todo:
                return;
            }

            this.model = result.model;
            this.onChange();
        }
        catch {
            //
        }
        finally {
            if (lastKey === this.key) {
                this.isLoadingProduct = false;
                this.isLoadingTracks = false;
            }
        }
    };

    more = async () => {
        if (!this.playlistId || this.isLoadingProduct || this.isLoadingTracks || !this.model?.tracks?.pageInfo?.hasNextPage) {
            return;
        }
        this.isLoadingTracks = true;
        const lastKey = this.key;
        try {
            const result = await getPlaylistTracksPagination({
                after: this.model?.tracks?.pageInfo.endCursor,
                id: this.playlistId,
                first: getPaginationInit(),
                criterion: this.criterion,
                orderBy: this.orderBy ?? PlaylistTracksSorting.TrackPosition
            });

            if (lastKey === this.key && result && !result.error && result.model?.items) {
                this.model = {
                    ... this.model,
                    tracks: {
                        ... this.model.tracks,
                        pageInfo: result.model.pageInfo,
                        items: [...this.model.tracks.items, ...result.model.items]
                    }
                };
                this.onChange();
            }
        }
        catch {
            //
        }
        if (lastKey === this.key) {
            this.isLoadingTracks = false;
        }
    };

    modify = (model: Partial<PlaylistPageModel>) => {
        // todo: check if its actually a change
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.model = { ...this.model, ...model } as any as PlaylistPageModel;
        this.onChange();
    };

    modifyTracks = (filter?: (tracks: TrackPreviewModel[]) => TrackPreviewModel[]) => {
        if (filter && this.model?.tracks?.items) {
            const tracks = filter(this.model.tracks?.items)
            this.model.tracks.items = tracks;
            this.onChange();
            // todo?
        }
    };
}

